<div *ngIf="(document | async)">
  <div class="row">
    <document-version-edit *ngIf="!showOverview()"></document-version-edit>
    <document-tab-version-overview *ngIf="showOverview()" ></document-tab-version-overview>
  </div>
  <div class="row" *ngIf="!showOverview()">
    <div class="col-12 align-right">
      <icon-button id="version-cancel"
                   buttonType="cancel"
                   (buttonClick)="cancelVersion()"></icon-button>
      <icon-button id="version-save"
                   *ngIf="!!((versionOrigBehaviorSubject | async)?.file?.id) && isDocumentVersionPublished(versionOrigBehaviorSubject | async) && !(isReadOnlyUser | async)"
                   buttonType="save version"
                   (buttonClick)="saveVersion()"></icon-button>
      <icon-button id="version-add-as-not-published"
                   *ngIf="!((versionOrigBehaviorSubject | async)?.file?.id) && !(isReadOnlyUser | async)"
                   buttonType="add as not published"
                   (buttonClick)="saveVersion()"></icon-button>
      <icon-button id="version-save-version"
                   *ngIf="!!((versionOrigBehaviorSubject | async)?.file?.id) && !isDocumentVersionPublished(versionOrigBehaviorSubject | async) && !(isReadOnlyUser | async)"
                   buttonType="save version"
                   (buttonClick)="saveVersion()"></icon-button>
      <icon-button id="version-save-and-publish"
                   *ngIf="!!((versionOrigBehaviorSubject | async)?.file?.id) && !isDocumentVersionPublished(versionOrigBehaviorSubject | async) && !(isReadOnlyUser | async)"
                   buttonType="save and publish"
                   (buttonClick)="saveAndPublish()"></icon-button>
      <icon-button id="version-add-and-publish"
                   *ngIf="!((versionOrigBehaviorSubject | async)?.file?.id) && !isDocumentVersionPublished(versionOrigBehaviorSubject | async) && !(isReadOnlyUser | async)"
                   buttonType="add and publish"
                   (buttonClick)="saveAndPublish()"></icon-button>
    </div>
  </div>
</div>
