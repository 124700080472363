import { Component, OnInit } from '@angular/core';
import { CategoryMaterial } from '../../../../model/category-material';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';

@Component({
  selector: 'material-tab-attributes.component',
  templateUrl: './material-tab-attributes.component.html'
})
export class MaterialTabAttributesComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  ngOnInit(): void {
  }

  public filterNonBasisCategoryMaterials = (categoryMaterial: CategoryMaterial): boolean =>
    categoryMaterial.category.id !== environment.basisCategoryId && categoryMaterial.category.id !== environment.spassCategoryId;

}
