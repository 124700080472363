<table class="table">
  <thead class="table-header">
    <tr>
      <th *ngFor="let column of columns" [width]="column.width">
        <div class="table-header-cell">
          <span class="th-wrapper" >{{column.name}}</span>
          <span class="th-wrapper" ><app-table-facet *ngIf="column.isFilterable" [column]="column" (facetsChange)="facetsChange($event)"></app-table-facet></span>
          <span class="width-spacer"></span>
          <span class="th-wrapper" >
            <fa-icon id="sort-{{ column?.name }}" *ngIf="column.isSortable && getSortIconName(column) === 'sort'" icon="sort" (click)="toggleSort(column)"></fa-icon>
            <fa-icon id="sort-up-{{ column?.name }}" *ngIf="column.isSortable && getSortIconName(column) === 'sort-up'" icon="sort-up" (click)="toggleSort(column)"></fa-icon>
            <fa-icon id="sort-down-{{ column?.name }}" *ngIf="column.isSortable && getSortIconName(column) === 'sort-down'" icon="sort-down" (click)="toggleSort(column)"></fa-icon>
          </span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="page && calculatePageRange()">
    <tr *ngFor="let row of page.content; let odd=odd; let even=even;" id="tr-{{ !!row?.id ? row.id : '' }}" (click)="doClick(row)" [ngClass]="{ odd: odd, even: even }" class="table-row">
      <td *ngFor="let column of columns">
        {{column.template ? '' : column.columnValue(row)}}
        <ng-container *ngTemplateOutlet="column.template, context: { $implicit: row }"></ng-container>
        <fa-icon *ngIf="column.displayLinkIcon" [styles]="" icon="external-link-alt" class="fa-sg"></fa-icon>
      </td>
    </tr>
    <tr *ngIf="!page.hasContent">
      <td [attr.colspan]="columns.length">{{'table.message.noitems'|translate}}</td>
    </tr>
  </tbody>
  <tfoot *ngIf="page && calculatePageRange()">
    <tr>
      <td [attr.colspan]="columns.length">
        <div class="relative">
          <div class="left all-items">{{page.totalElements}} {{ 'table.label.elements' | translate }}</div>
          <span class="right">
            <div id="goToFirstPage" class="page-button" *ngIf="getCurrentPage()>0" (click)="goToPage(0)">
              <span class="td-icon-wrapper"><fa-icon icon="angle-double-left"></fa-icon></span>
            </div>
            <div id="goToPreviousPage"  class="page-button" *ngIf="getCurrentPage()>0" (click)="goToPage(getCurrentPage()-1)">
              <span class="td-icon-wrapper"><fa-icon icon="angle-left"></fa-icon></span>
            </div>
            <div id="goToNthPage"  class="page-button" [ngClass]="{'active': getCurrentPage()==p}" *ngFor="let p of getPages()" (click)="goToPage(p)">{{p+1}}</div>
            <div id="goToNextPage" class="page-button" *ngIf="page.hasNext && (page.number < getMaxPages())" (click)="goToPage(getCurrentPage()+1)">
              <span class="td-icon-wrapper"><fa-icon icon="angle-right"></fa-icon></span>
            </div>
            <div id="goToLastPage"  class="page-button" *ngIf="page.hasNext && (page.number < getMaxPages())" (click)="goToPage(page.totalPages-1)">
              <span class="td-icon-wrapper"><fa-icon icon="angle-double-right"></fa-icon></span>
            </div>
          </span>
        </div>
      </td>
    </tr>
  </tfoot>
</table>
