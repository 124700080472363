import { Pageable } from './../model/pageable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Unit } from '../model/unit';
import { environment } from '../../environments/environment';
import { Page } from '../model/pim-response/page';
import { LocalizedStringService } from './localized-string.service';
import { isEqual } from 'lodash';

@Injectable()
export class UnitService {

  constructor(
    private httpClient: HttpClient,
    private localizedStringService: LocalizedStringService
  ) { }

  public save(unit: Unit) {
    return this.httpClient.post<Unit>(environment.restUrl + '/unit/save', unit);
  }

  public search(searchString: string, pageable: Pageable): Observable<Page<Unit>> {
    return this.httpClient.get<Page<Unit>>(
      environment.restUrl
      + '/unit/search'
      + ((!!searchString && searchString.length > 0) ? '/' + btoa(searchString) : '')
      + '?'
      + pageable.toUrlString());
  }

  public relevantFormulationSearch(): Observable<Unit[]> {
    return this.httpClient.get<Unit[]>(environment.restUrl + '/unit/search/formulation');
  }

  public load(id: string) {
    return this.httpClient.get<Unit>(environment.restUrl + '/unit/' + id);
  }

  private getUnitObj(unit: Unit): any {
    let unitBaseUnit = unit.baseUnit ? unit.baseUnit.id : null;
    let unitName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(unit.name);
    let unitUnitRelevance = unit.unitRelevance ? unit.unitRelevance.sort() : null;
    return {
      id: unit.id,
      baseUnit: unitBaseUnit,
      name: unitName,
      numerator: unit.numerator,
      denominator: unit.denominator,
      exp10: unit.exp10,
      mabasCode: unit.mabasCode,
      symbol: unit.symbol,
      unitRelevance: unitUnitRelevance
    }
  }

  public isEqual(unitOrig: Unit, unit: Unit): boolean {
    if (unitOrig != null && unit != null) {
      let unitOrigObj = this.getUnitObj(unitOrig);
      let unitObj = this.getUnitObj(unit);
      return isEqual(unitOrigObj, unitObj);
    }
    return false;
  }

}
