import { AttributeParameterLocalizedEnumeration } from '../attribute-parameter-localized-enumeration';
import { AttributeParameterType } from '../attribute-parameter-type';
import { LocalizedEnumerationValue } from '../localized-enumeration-value';
import { AttributeParameterValue } from './attribute-parameter-value';

export class AttributeParameterLocalizedEnumerationValue implements AttributeParameterValue {
  public id: string = null;
  public parameterType: AttributeParameterType;
  public attributeParameter: AttributeParameterLocalizedEnumeration;
  public localizedEnumerationValue: LocalizedEnumerationValue;

  constructor(obj: any) {
    const apnv = obj as AttributeParameterLocalizedEnumerationValue;

    this.id = apnv.id;
    this.parameterType = AttributeParameterType.LOCALIZED_ENUMERATION;
    this.attributeParameter = apnv.attributeParameter;
    this.localizedEnumerationValue = apnv.localizedEnumerationValue;
  }
}
