import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmService } from '../confirm.service';
import { DocumentEditDataService } from './document-edit-data.service';
import { DocumentVersionFormValidationService } from '../form-validation/document-version-form-validation.service';
import { DocumentVersionFile } from '../../model/document-version/document-version-file';
import { Document } from '../../model/document/document';
import { DocumentVersion } from '../../model/document-version/document-version';
import { DocumentVersionStatus } from '../../model/document-version/document-version-status';
import { DocumentVersionInputSettings } from '../../model/document-version/document-version-input-settings';
import { CategoryAttributeAttributeValue } from '../../model/category-attribute-attribute-value';
import { extendMoment } from 'moment-range';
import * as Moment from 'moment/moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DocumentBackendService } from '../document-backend.service';
import { DocumentProcessingService } from '../document-processing.service';
import { map } from 'rxjs/operators';
import { DocumentEditContext } from '../../model/document/document-edit-context';
import { Category } from '../../model/category';
import { CategoryType } from '../../model/category-type';
import { CategoryService } from '../../service/category.service';
import { LocalizedString } from '../../model/localized-string';
import { TranslateService } from '@ngx-translate/core';

const moment = extendMoment(Moment);

@Injectable({
  providedIn: 'root',
})
export class DocumentVersionDataService implements OnInit {
  public inputSettings: BehaviorSubject<DocumentVersionInputSettings> = new BehaviorSubject(new DocumentVersionInputSettings());
  public documentVersionBehaviorSubject: BehaviorSubject<DocumentVersion> = new BehaviorSubject(null);
  private readonly subscriptions: Subscription = new Subscription();
  public versionOrig: DocumentVersion = new DocumentVersion();
  public versionOrigBehaviorSubject: BehaviorSubject<DocumentVersion> = new BehaviorSubject(null);
  private shouldBePublished: boolean = false;
  public fileToUpload: File = null;
  public readonly _addUrlPath = 'add';
  public context: string = DocumentEditContext.DOCUMENT_EDIT;

  private draftVersion: DocumentVersion = null;

  public changeReasonClassifications: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);

  constructor(
    private readonly router: Router,
    private readonly confirmService: ConfirmService,
    private readonly documentProcessingService: DocumentProcessingService,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly versionFormValidation: DocumentVersionFormValidationService,
    private readonly documentBackendService: DocumentBackendService,
    private readonly categoryService: CategoryService,
    private readonly translateService: TranslateService,
  ) {  }

  ngOnInit(): void {
  }

  public initializeChangeReasonClassifications() {
    if (this.changeReasonClassifications.value.length > 0) {
      return;
    }
    this.categoryService.findByTypeCached(CategoryType.DOCUMENT_CHANGE_REASON).subscribe((list: Category[]) => {
      this.changeReasonClassifications.next(list);
    });
  }

  public initDocumentVersionWithExistingId(versionId: string) {
    this.initializeChangeReasonClassifications();
    this.documentVersionBehaviorSubject.next(this.documentEditDataService.documentBehaviorSubject.value.versions.find((version) => version.id === versionId));
  }

  public initDocumentVersion(context: string) {
    this.initializeChangeReasonClassifications();
    this.context = context;
    if (!this.documentVersionBehaviorSubject.getValue()) {
      const newDocumentVersion = new DocumentVersion();
      newDocumentVersion.validFrom = new Date();
      this.documentVersionBehaviorSubject.next(newDocumentVersion);
    }
    if (this.documentVersionBehaviorSubject.value.id !== null && this.documentVersionBehaviorSubject.value.status === DocumentVersionStatus.DRAFT) {
      if (this.draftVersion == null || this.draftVersion.id !== this.documentVersionBehaviorSubject.value.id) {
        this.draftVersion = _.cloneDeep(this.documentVersionBehaviorSubject.value);
        this.createVersionFromDraft();
      }
      
    }
    else {
      this.draftVersion = null;
    }
    this.versionOrig = _.cloneDeep(this.draftVersion == null ? this.documentVersionBehaviorSubject.value: this.draftVersion);
    this.versionOrigBehaviorSubject.next(this.versionOrig);
    
    if (this.context === DocumentEditContext.DOCUMENT_EDIT) {
      this.checkInputsToBeDisabled();
    }
  }

  public getChangeReasonName(id: string): LocalizedString[] {
    return this.changeReasonClassifications.value.find((category) => category.id === id)?.name;
  }

  public createVersionFromDraft(){
    // Create version from draft version needs to remove all ids
    let clonedVersion = _.cloneDeep(this.documentVersionBehaviorSubject.value);
    clonedVersion.id = "draft";
    clonedVersion.file.id = this.draftVersion.file.id;
    this.documentVersionBehaviorSubject.next(clonedVersion);
  }

  public prepareVersionSaveIfDraft() {
    if (!this.isDocumentVersionFromDraft()){
      return;
    }
    this.documentVersionBehaviorSubject.value.id = null;
    this.documentVersionBehaviorSubject.value.file = null;
    this.documentVersionBehaviorSubject.value.categoryAttributeAttributeValues.forEach((categoryAttributeAttributeValue: CategoryAttributeAttributeValue) => {
      categoryAttributeAttributeValue.attributeValues.forEach((attributeValue) => {
        attributeValue.id = null;
        attributeValue.relationship.id = null;
      } );
    });
    this.documentVersionBehaviorSubject.value.changeComment.forEach((localizedString) => {
      localizedString.id = null;
    });
    this.versionOrig = new DocumentVersion();
    this.versionOrigBehaviorSubject.next(this.versionOrig);
  }

  private isDocumentVersionFromDraft() {
    return this.documentVersionBehaviorSubject.value.id === "draft";
  }

  public getDraftVersionId() {
    return this.isDocumentVersionFromDraft() ? this.draftVersion.id : null;
  } 

  public isDocumentVersionNew() {
    return this.documentVersionBehaviorSubject.value.status === DocumentVersionStatus.NOT_PUBLISHED && this.documentVersionBehaviorSubject.value.file === undefined;
  }

  private checkInputsToBeDisabled() {
    const version = this.documentVersionBehaviorSubject.value;
    if (!!version?.id && version.status === DocumentVersionStatus.PUBLISHED) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const validFrom = new Date(version.validFrom);
      validFrom.setHours(0, 0, 0, 0);
      const validUntil = new Date(version.validUntil);
      validUntil.setHours(0, 0, 0, 0);

      if (validFrom < now && validUntil < now) {
        this.disableVersionInputs(true, true, true, true, true);
      } else if (validFrom <= now && validUntil > now) {
        this.disableVersionInputs(true, true, true, false, true);
      } else {
        this.disableVersionInputs(false, false, false, false, false);
      }
    }
  }

  public disableVersionInputs(disableLanguage: boolean, disableVersion: boolean, disablePublishDate: boolean, disableExpirationDate: boolean, disableFile: boolean) {
    const inputSettings = this.inputSettings.getValue();
    inputSettings.disableLanguage = disableLanguage;
    inputSettings.disableVersion = disableVersion;
    inputSettings.disablePublishDate = disablePublishDate;
    inputSettings.disableExpirationDate = disableExpirationDate;
    inputSettings.disableFile = disableFile;
    this.inputSettings.next(inputSettings);
  }

  public cancelVersionChanges = (): void => {
    this.cleanUp();
  }

  public restoreValuesToVersion(originalVersion: DocumentVersion, targetVersion: DocumentVersion) {
    targetVersion.file = originalVersion.file;
    targetVersion.validFrom = originalVersion.validFrom;
    targetVersion.validUntil = originalVersion.validUntil;
    targetVersion.languages = originalVersion.languages;
    targetVersion.changeReason = originalVersion.changeReason;
    targetVersion.changeComment = originalVersion.changeComment;
    targetVersion.version = originalVersion.version;
    targetVersion.categoryAttributeAttributeValues = originalVersion.categoryAttributeAttributeValues;
    this.documentEditDataService.documentBehaviorSubject.next(this.documentEditDataService.documentBehaviorSubject.value);
  }

  public removeDraftVersion(draftVersionId: string) {
    const draftVersion = this.documentEditDataService.documentBehaviorSubject.value.versions.find((version) => version.id === draftVersionId);
    if (draftVersion != null && draftVersion.status === DocumentVersionStatus.DRAFT){
      this.documentEditDataService.deleteVersion(draftVersion);
    }
  }

  public cleanUp = (): void => {
    this.subscriptions.unsubscribe();
    this.documentVersionBehaviorSubject.next(null);
    this.inputSettings.next(new DocumentVersionInputSettings());
    this.shouldBePublished = false;
    this.fileToUpload = null;
    this.draftVersion = null;
  }

  public cancelVersion() {
    if (this.hasChanged()) {
      this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no').then(confirmed => {
        if (confirmed) {
          return this.cancelLocalVersion();
        }
      });
    } else {
      return this.cancelLocalVersion();
    }
  }

  private cancelLocalVersion() {
    this.cancelVersionChanges();
    return this.navigateToVersionOverview();
  }

  public saveVersion() {
    // Check contains Valid Until
    if (!this.documentVersionBehaviorSubject.value?.validUntil) {
      this.confirmService.confirm('title.expiredate.empty', 'text.expiredate.empty.message', 'button.yes', 'button.no').then(changeExpireDateToMax => {
        if (changeExpireDateToMax) {
          this.documentVersionBehaviorSubject.value.validUntil = new Date('9999-12-31');
        }
        this.callPublishModal();
      });
      return;
    }

    this.callPublishModal();
  }

  public saveAndPublish() {
    this.shouldBePublished = true;

    // Check contains Valid From
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const validFrom = new Date(this.documentVersionBehaviorSubject.value?.validFrom);
    validFrom.setHours(0, 0, 0, 0);
    if (validFrom < now) {
      this.confirmService.confirm('title.publishdate.atleast.today', 'text.publishdate.atleast.today.message', 'button.yes', 'button.no').then(changePublishDateToNow => {
        if (changePublishDateToNow) {
          this.documentVersionBehaviorSubject.value.validFrom = now;
        }
        this.callPublishModal();
      });
      return;
    }

    this.saveVersion();
  }

  callPublishModal() {
    if (this.shouldBePublished && this.documentVersionBehaviorSubject.value.status == DocumentVersionStatus.NOT_PUBLISHED) {
      // Validate first
      if (!this.collectDocumentVersionValidationErrors()) {
        return;
      }
      // If valid, display the confirm message to publish it
      this.confirmService.confirm('title.confirm.publish.document', 'message.confirm.publish.document', 'button.yes', 'button.no').then(confirmed => {
        if (!confirmed) {
          this.shouldBePublished = false;
          return;
        }
        this.saveDocumentVersion();
      });
    } else {
      this.collectDocumentVersionValidationErrorsAndSave();
    }
  }

  private collectDocumentVersionValidationErrorsAndSave() {
    if (!this.collectDocumentVersionValidationErrors()) {
      return;
    }
    this.saveDocumentVersion();
  }

  private collectDocumentVersionValidationErrors(): boolean {
    const documentVersion = this.documentVersionBehaviorSubject.value;

    if (!this.checkFutureVersions(this.documentEditDataService.documentBehaviorSubject.value, documentVersion)) {
      this.shouldBePublished = false;
      return false;
    }

    const isValid = this.versionFormValidation.validate(
      {
        document: this.documentEditDataService.documentBehaviorSubject.value,
        documentVersion,
        fileToUpload: this.fileToUpload,
        disabledFields: this.inputSettings.value,
      });

    if (!isValid) {
      this.shouldBePublished = false;
    }
    this.versionFormValidation.renderErrors();
    return isValid;
  }

  private saveDocumentVersion() {
    const documentVersion = this.documentVersionBehaviorSubject.value;
    this.updateExistingVersions(this.documentEditDataService.documentBehaviorSubject.value, documentVersion);

    if (documentVersion.status == DocumentVersionStatus.NOT_PUBLISHED && this.shouldBePublished) {
      this.documentVersionBehaviorSubject.value.status = DocumentVersionStatus.PUBLISHED;
    }

    this.uploadFiletoS3Bucket(this.fileToUpload).subscribe(uploaded => {
      if (uploaded) {
        return this.mergeVersionIntoDocument(!this.versionOrig?.file);
      }
    });
    this.versionFormValidation.renderErrors(); // just in case uploadFile fails
  }

  private checkUploadResult(documentVersionFile: DocumentVersionFile): boolean {
    if (documentVersionFile == null) {
      this.versionFormValidation.addError('label.document.error_while_uploading_file');
      this.versionFormValidation.renderErrors();
      return false;
    } else {
      this.documentVersionBehaviorSubject.value.file = documentVersionFile;
      return true;
    }
  }

  public uploadFiletoS3Bucket(fileToUpload: File): Observable<boolean> {
    if (!fileToUpload) {
      return of(true);
    }
    return this.uploadFile(fileToUpload).pipe(map(
      documentVersionFile => this.checkUploadResult(documentVersionFile)),
    );
  }

  public uploadFile(documentVersionFile: File): Observable<DocumentVersionFile> {
    return this.documentBackendService.uploadFile(documentVersionFile);
  }

  /* Merge changed Document Version object into document */
  public mergeVersionIntoDocument(isNewVersion: boolean = false) {
    if (this.versionFormValidation.getErrorList().length > 0) {
      return;
    }
    if (isNewVersion) {
      if (this.documentVersionBehaviorSubject.value.changeReason?.id != null){
        this.documentVersionBehaviorSubject.value.changeReason.name = this.getChangeReasonName(this.documentVersionBehaviorSubject.value.changeReason.id); 
      }
      this.documentEditDataService.documentBehaviorSubject.value.versions.push(this.documentVersionBehaviorSubject.value);
    }
    this.cleanUp();
    return this.navigateToVersionOverview();
  }

  public navigateToVersionOverview() {
    return this.router.navigate([], {queryParams: {version: null}, queryParamsHandling: 'merge'});
  }

  public hasChanged(): boolean {
    if (!!this.documentVersionBehaviorSubject.value && !!this.versionOrig) {
      return !this.documentProcessingService.areVersionsEqual(this.documentVersionBehaviorSubject.value, this.versionOrig);
    }
    return false;
  }

  public expireVersion(documentVersion: DocumentVersion): void {
    this.documentEditDataService.documentBehaviorSubject.value.versions
      .filter(vers => vers.id === documentVersion.id)
      .forEach(found => {
        found.validUntil = new Date();
        found.validUntil.setUTCHours(0, 0, 0, 0);
      });
    this.documentEditDataService.expiredDocumentVersions.push(documentVersion);
  }

  private checkFutureVersions(document: Document, newDocumentVersion: DocumentVersion) {
    // Two future versions of the same language are not allowed
    const today = moment().startOf('day');
    // New version (not edited) trying to be published is in Not Published status, and shouldBePublished is true
    // Edited published versions changing the date happen when shouldBePublished is false
    if (moment(newDocumentVersion.validFrom).isAfter(today, 'day') && (this.shouldBePublished || newDocumentVersion.status == DocumentVersionStatus.PUBLISHED)) {
      const futureVersions = document.versions.filter(dv => dv.status == DocumentVersionStatus.PUBLISHED && moment(dv.validFrom).isAfter(today, 'day'));

      for (const newDocumentVersionLanguage of newDocumentVersion.languages) {
        for (const existingFutureDocumentVersion of futureVersions) {
          if (!this.areVersionsEqual(newDocumentVersion, existingFutureDocumentVersion)) {

            const sameLanguage = existingFutureDocumentVersion.languages.find(i => i.isoCode === newDocumentVersionLanguage.isoCode);
            if (sameLanguage) {
              this.versionFormValidation.addError('label.document.error_future_version_already_exists');
              this.versionFormValidation.renderErrors();
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  // Needed public for DocumentTabVersionOverviewComponent
  public areVersionsEqual = (firstDocVersion: DocumentVersion, secondDocVersion: DocumentVersion): boolean =>
    this.versionFormValidation.areVersionsEqual(firstDocVersion, secondDocVersion);

  public updateExistingVersions(document: Document, newDocumentVersion: DocumentVersion) {
    // if two versions of the same language and overlaping time range are future or active versions
    // the valid until date of the existing version should be set to the starting date of the new version
    if (newDocumentVersion.validUntil && newDocumentVersion.validFrom && this.shouldBePublished) {
      const newDocumentVersionRange = moment.range(newDocumentVersion.validFrom, newDocumentVersion.validUntil);

      for (const newDocumentVersionLanguage of newDocumentVersion.languages) {
        for (const existingDocumentVersion of document.versions) {
          if (!this.areVersionsEqual(newDocumentVersion, existingDocumentVersion)) {

            const sameLanguage = existingDocumentVersion.languages.find(i => i.isoCode === newDocumentVersionLanguage.isoCode);

            if (sameLanguage) {
              const existingDocumentVersionRange = moment.range(existingDocumentVersion.validFrom, existingDocumentVersion.validUntil);

              // if existing version is active and new version overlaps dates
              const today = moment().startOf('day');
              if (existingDocumentVersionRange.overlaps(newDocumentVersionRange, {adjacent: false})) {
                if (moment(existingDocumentVersion.validFrom).isSameOrBefore(today, 'day') && moment(existingDocumentVersion.validUntil).isAfter(today, 'day')) {
                  existingDocumentVersion.validUntil = newDocumentVersion.validFrom;
                  if (moment(newDocumentVersion.validFrom).isSame(today, 'day')) {
                    this.expireVersion(existingDocumentVersion);
                  }
                } else {  // Future version
                  newDocumentVersion.validUntil = existingDocumentVersion.validFrom;
                }
              }

            }
          }
        }
      }
    }
  }

  public handleSaveErrorIfDraftId(draftVersionId: string){
    if (draftVersionId == null){
      return;
    }
    this.documentVersionBehaviorSubject.value.id = "draft";
    this.documentVersionBehaviorSubject.value.status = DocumentVersionStatus.DRAFT;
    this.documentVersionBehaviorSubject.value.file = this.draftVersion.file;
    this.versionOrig = _.cloneDeep(this.draftVersion);
    this.versionOrigBehaviorSubject.next(this.versionOrig);
  }

  public handleSaveSuccessIfDraftId(data: DocumentVersion, draftVersionId: string){
    if (draftVersionId == null){
      return;
    }
    // We need draftVersionId because the cleanup already happen before going to the overview page
    this.askToRemoveDraftVersion(data.differences).then((confirmed) => {
      if (confirmed) {
        this.removeDraftVersion(draftVersionId);
      }
      this.draftVersion = null;
    });
  }

  private async askToRemoveDraftVersion(differences: string[]) {
    const changesDetected = differences?.length > 0;
    const confirmMessage = changesDetected ? this.getChangesMessage(differences) : 'message.confirm.publish.document.version.remove.draft.lower_text.no_changes';
    let confirmed: boolean;
    if (changesDetected) {
      const copyText = this.getUnformattedChangesMessage(differences);
      confirmed = await this.confirmService.confirmWithCopy('message.confirm.publish.document.version.remove.draft.title', confirmMessage, 'button.yes', 'button.no', 
        'button.copy.to.clipboard', copyText);
    } else {
      confirmed = await this.confirmService.confirm('message.confirm.publish.document.version.remove.draft.title', confirmMessage, 'button.yes', 'button.no');
    }
    return confirmed;
  }

  private getChangesMessage(differences: string[]): string {
    const formattedDifferences = "<ul><li>" + differences.join("</li><li>") + "</li></ul>";
    return this.translateService.instant('message.confirm.publish.document.version.remove.draft.lower_text.changes', { differences: formattedDifferences});
  }

  private getUnformattedChangesMessage(differences: string[]): string {
    const unformattedDifferences = "-" + differences.join("\r\n- ");
    return this.translateService.instant('message.confirm.publish.document.version.remove.draft.lower_text.changes_unformatted', { differences: unformattedDifferences});
  }
}
