import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ProductHierarchyObject } from '../../model/product-hierarchy-object/product-hierarchy-object';
import { LocalizedStringService } from '../localized-string.service';
import { CommonComparisonService } from './common-comparison.service';
import { ComparisonService } from './comparison-service';

@Injectable({
  providedIn: 'root'
})
export class ProductHierarchyObjectComparisonService implements ComparisonService<ProductHierarchyObject> {

  constructor(
    private commonComparisonService: CommonComparisonService,
    private localizedStringService: LocalizedStringService
  ) { }

  public createComparisonObject(model: ProductHierarchyObject): any {
    const comparisonObject: any = {
          id: model.id,
          internalName: model.internalName,
          marketingName: this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(model.marketingName),
          synonyms: this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(model.synonyms),
          type: model.type,
          materials: _(model.materials).map("id").uniq().sort().value(),
          children: _(model.children).map("id").uniq().sort().value(),
          owner: model.owner ? model.owner.id : null,
          realm: model.realm ? model.realm.id : null,
          parent: model.parent ? model.parent.id : null
        }
    
        this.commonComparisonService.validateComparisonObject(model, comparisonObject, ["isReadOnly", "highlights"]);
    
        return comparisonObject;
  }

}
