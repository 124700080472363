<div class="row paddig-top" >
  <div class="col-4"> <!-- First column -->
    <div>
      <h3>{{'label.administration.category.usage.materials'|translate}}</h3>
      <app-table [page]="materials" (rowClick)="navigateToMaterial($event)" (pageableHandler)="switchMaterialPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{'label.administration.category.usage.documents'|translate}}</h3>
      <app-table [page]="documents" (rowClick)="navigateToDocument($event)" (pageableHandler)="switchDocumentPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
  </div>
  <div class="col-4"> <!-- Second column -->
    <div>
      <h3>{{'label.administration.category.usage.formulations'|translate}}</h3>
      <app-table [page]="formulations" (rowClick)="navigateToFormulation($event)" (pageableHandler)="switchFormulationPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{'label.administration.category.usage.categories'|translate}}</h3>
      <app-table [page]="categories" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchCategoryPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
  </div>
  <div class="col-4"> <!-- Third column -->
    <h3>{{'label.administration.category.usage.phos'|translate}}</h3>
    <app-table [page]="phos" (rowClick)="navigateToPHO($event)" (pageableHandler)="switchPHOPage($event)">
      <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
      </app-table-column>
      <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="internalName"
        displayLinkIcon="true"></app-table-column>
    </app-table>
  </div>
</div>
