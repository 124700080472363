import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LocalizedString } from '../../../../model/localized-string';
import { FormGroup } from '@angular/forms';
import { Attribute } from '../../../../model/attribute';
import { AttributeValue } from '../../../../model/attribute-value';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'localized-string-attribute-edit',
  templateUrl: './localized-string-attribute-edit.component.html',
  styleUrls: ['./localized-string-attribute-edit.component.less']
})
export class LocalizedStringAttributeEditComponent implements OnInit {
  get attributeValue(): AttributeValue {
    return this._attributeValue;
  }

  @Input()
  set attributeValue(value: AttributeValue) {
    this._attributeValue = value;
  }

  @Input()
  public inputType = 'input';

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  private _attributeValue: AttributeValue;

  @Input()
  public identifier: string = '';

  @Input()
  public readonly: boolean = false;

  public localizedStringEditComponentId: string = '';

  public readonly environment = environment;

  public set localizedStrings(localizedStrings: LocalizedString[]) {
    if(!!this._attributeValue) {
      this._attributeValue.localizedString = localizedStrings;
    }
  }

  public get localizedStrings(): LocalizedString[] {
    return this.attributeValue.localizedString || [];
  }

  constructor(
    private readonly me: ElementRef
  ) {  }

  public ngOnInit(): void {
    this.initializeLocalizedStrings();

    this.localizedStringEditComponentId = `${this.me.nativeElement.id}`;
  }

  public updateAttributeValue(localizedString: LocalizedString[]) {
    this.localizedStrings = localizedString;
  }

  private initializeLocalizedStrings() {
    if (!!this.attribute) {
      if (!!this._attributeValue && !!this._attributeValue.localizedString && this._attributeValue.localizedString.length > 0) {
            this.localizedStrings = this._attributeValue.localizedString;
      }
    }
  }
}
