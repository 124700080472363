import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {cloneDeep} from 'lodash';
import {Observable} from 'rxjs';
import {Attribute} from '../../../../model/attribute';
import {AttributeAttributeParameter} from '../../../../model/attribute-attribute-parameter';
import {AttributeCluster} from '../../../../model/attribute-cluster';
import {AttributeParameter} from '../../../../model/attribute-parameter';
import {AttributeRelevance} from '../../../../model/attribute-relevance';
import {AttributeType} from '../../../../model/attribute-type';
import {Category} from '../../../../model/category';
import {CategoryType} from '../../../../model/category-type';
import {SingleChoiceListValue} from '../../../../model/single-choice-list-value';
import {LocalizedEnumerationValue} from '../../../../model/localized-enumeration-value';
import {Pageable} from '../../../../model/pageable';
import {Unit} from '../../../../model/unit';
import {ConfirmService} from '../../../../service/confirm.service';
import {AttributeService} from '../../../../service/attribute.service';
import {CategoryService} from '../../../../service/category.service';
import {AttributeFormValidationService} from '../../../../service/form-validation/attribute-form-validation.service';
import {NotificationService} from '../../../../service/notification.service';
import {AttributeClusterTypeaheadService} from '../../../../service/typeahead/attribute-cluster-typeahead.service';
import {AttributeParameterTypeaheadService} from '../../../../service/typeahead/attribute-parameter-typeahead.service';
import {UnitTypeaheadService} from '../../../../service/typeahead/unit-typeahead.service';
import {environment} from '../../../../../environments/environment';
import {AttributeUsageService} from '../../../../service/attribute-usage.service';
import {HasUnsavedChangesGuard} from '../../../../guards/has-unsaved-changes.guard';

@Component({
  selector: 'attribute-administration-edit',
  templateUrl: './attribute-administration-edit.component.html',
  styleUrls: ['./attribute-administration-edit.component.less']
})
export class AttributeAdministrationEditComponent implements OnInit, HasUnsavedChangesGuard {

  public attribute: Attribute = new Attribute();
  public attributeOrig: Attribute = null;
  public attributeTypes = AttributeType;
  public attributeRelevance = AttributeRelevance;
  public idToDefaultVisibilityMapping: Map<string, Category> = new Map();
  public defaultVisibilityIds: string[];
  public readonly environment = environment;
  public attributeInUse: boolean = false;
  public showUsages: boolean = false;
  private unitBackup: Unit;
  private selectedDefaultVisibilityId: string;

  set attributeRangeMax(rangeMax) {
    this.attribute.rangeMax = rangeMax;
  }

  get attributeRangeMax() {
    if(!this.attribute.rangeMax) {
      this.attribute.rangeMax = 5;
    }
    return this.attribute.rangeMax;
  }

  set attributeTextLength(textLength) {
    this.attribute.textLength = (textLength == environment.localizedStringMaxLength) ? null : textLength;
  }

  get attributeTextLength() {
    if(!this.attribute.textLength) {
      return environment.localizedStringMaxLength;
    }
    return this.attribute.textLength;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly attributeService: AttributeService,
    private readonly unitTypeaheadService: UnitTypeaheadService,
    private readonly attributeClusterTypeaheadService: AttributeClusterTypeaheadService,
    private readonly attributeParameterTypeaheadService: AttributeParameterTypeaheadService,
    private readonly notificationService: NotificationService,
    private readonly formValidation: AttributeFormValidationService,
    private readonly confirmService: ConfirmService,
    private readonly categoryService: CategoryService,
    private readonly attributeUsageService: AttributeUsageService
  ) {
    this.route.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'newAttribute') {
          this.attribute = new Attribute();
        } else {
          this.attributeService.load(params.id).subscribe((attribute: Attribute) => {
            this.showUsages = false;
            this.loadAttribute(attribute);
            this.attributeUsageService.isAttributeInUse(attribute).subscribe(result => this.attributeInUse = result);
          });
        }
      }
    });
  }

  ngOnInit() {
    this.categoryService.findByTypeCached(CategoryType.VISIBILITY).subscribe((categories: Category[]) => this.updateDefaultVisibility(categories));
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.attributeChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  updateDefaultVisibility(categories: Category[]): void {
    this.idToDefaultVisibilityMapping = new Map(categories.map(cat => [cat.id, cat]));
    this.defaultVisibilityIds = Array.from(this.idToDefaultVisibilityMapping.keys());
  }

  public unitTypeahead = (searchTerm: string, pageable: Pageable) => this.unitTypeaheadService.typeaheadSearch(searchTerm, pageable);

  public unitTypeaheadFormatter = (model: Unit) => this.unitTypeaheadService.typeaheadFormatter(model);

  public clusterTypeahead = (searchTerm: string, pageable: Pageable) => this.attributeClusterTypeaheadService.typeaheadSearch(searchTerm, pageable, []);

  public clusterTypeaheadFormatter = (model: AttributeCluster) => this.attributeClusterTypeaheadService.typeaheadFormatter(model);

  getDefaultVisibilityId(): any {
    return this.selectedDefaultVisibilityId;
  }

  setDefaultVisibilityId(value: string): void {
    this.attribute.defaultVisibility = this.idToDefaultVisibilityMapping.get(value);
  }

  public parameterTypeahead = (searchTerm: string, pageable: Pageable) => this.attributeParameterTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.findAssignedAttributeParameters());

  public parameterTypeaheadFormatter = (model: AttributeParameter) => this.attributeParameterTypeaheadService.typeaheadFormatter(model);

  attributeChanges(): boolean {
    if (!this.attributeService.isEqual(this.attributeOrig, this.attribute)) {
      return true;
    } else {
      return false;
    }
  }

  public editItem(attribute: Attribute) {
    this.router.navigateByUrl('adminstration/attribute/' + attribute.id);
  }

  public save() {
    if (this.formValidation.validate(this.attribute)) {
      this.preventEnumerationValuesRepeated(this.attribute);
      this.attributeService.save(this.attribute).subscribe((attribute: Attribute) => {
        this.notificationService.addSuccessNotification('label.successfully.saved');
        this.loadAttribute(attribute);
        this.editItem(this.attribute);
      });
    } else {
      this.formValidation.renderErrors();
    }
  }

  public cancel() {
    this.router.navigateByUrl('adminstration/attribute');
  }

  public isEnumSelected(value) {
    return this.attribute.attributeRelevance.find((item) => item === value);
  }

  public onChangeRelevance(event, relevance: AttributeRelevance) {
    if (event.target.checked) {
      this.attribute.attributeRelevance.push(relevance);
    } else {
      this.attribute.attributeRelevance.splice(this.attribute.attributeRelevance.indexOf(relevance), 1);
    }
  }

  public enumerationValuesChange(enumerationValues: string[], attribute: Attribute): void {
    attribute.enumerationValues = enumerationValues;
  }

  public localizedEnumerationValuesChange(enumerationValues: LocalizedEnumerationValue[], attribute: Attribute): void {
    attribute.localizedEnumerationValues = enumerationValues;
  }

  private preventEnumerationValuesRepeated(attribute: Attribute): void {
    if (attribute.type === 'ENUMERATION_MULTIPLE') {
      const enumerationValueNamesNotRepeated: string[] = [];
      const enumerationValuesOrderedWithoutRepetitions: SingleChoiceListValue[] = [];
      for (const singleChoiceListValue of attribute.singleChoiceListValues) {
        let enumValueAlreadyIncluded = false;
        if (enumerationValueNamesNotRepeated.some(enumValue => enumValue === singleChoiceListValue.value)) {
          enumValueAlreadyIncluded = true;
        }
        if (!enumValueAlreadyIncluded) {
          enumerationValueNamesNotRepeated.push(singleChoiceListValue.value);
          enumerationValuesOrderedWithoutRepetitions.push(singleChoiceListValue);
        }
      }
      attribute.singleChoiceListValues = [...enumerationValuesOrderedWithoutRepetitions];
    }
  }

  public singleChoiceListValueChange(singleChoiceListValues: SingleChoiceListValue[], attribute: Attribute): void {
    attribute.singleChoiceListValues = singleChoiceListValues;
  }

  public attributeTypeSelectionChanged(selectedType: string): void {
    if (selectedType === AttributeType.RELATIONSHIP) {
      this.unitBackup = this.attribute.unit;
      this.attribute.unit = null;
    } else if (!!this.unitBackup) {
      this.attribute.unit = this.unitBackup;
    }
  }

  public disableUnit(): boolean {
    let disable = false;

    switch (this.attribute.type) {
      case AttributeType.RELATIONSHIP:
      case AttributeType.STAR_RATING:
      case AttributeType.ENUMERATION:
        disable = true;
        break;
    }

    return disable;
  }

  public visibilityChange(event: Category): void {
    this.attribute.defaultVisibility = event;
  }

  public enableAttributeParameter(): boolean {
    let enable = false;

    switch (this.attribute.type) {
      case AttributeType.NUMBER:
      case AttributeType.BOOL:
      case AttributeType.STAR_RATING:
      case AttributeType.ENUMERATION:
      case AttributeType.LOCALIZED_ENUMERATION:
      case AttributeType.LOCALIZED_STRING:
      case AttributeType.STRING:
      case AttributeType.RANGE:
        enable = true;
        break;
    }

    return enable;
  }

  public findAssignedAttributeParameters(): string[] {
    return this.attribute.attributeParameters.map(aap => aap.attributeParameter.id) || [];
  }

  public addAttributeParameter = (value: AttributeParameter) => {
    let aap = new AttributeAttributeParameter();

    aap.attribute = new Attribute();
    aap.attribute.id = this.attribute.id;

    aap.attributeParameter = value;
    aap.order = Math.max(...this.attribute.attributeParameters.map(o => o.order + 1), 1);

    return aap;
  }

  public recalculateAttributeParametersOrder = (valueSet: any[], obj: any, id: string) => {
    valueSet.forEach((step, index) => step.order = index + 1);
  }

  public drop = (event: CdkDragDrop<AttributeAttributeParameter[]>) => {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.attribute.attributeParameters.forEach((step, index) => step.order = index + 1);
  }

  private loadAttribute = (attribute: Attribute) => {
    this.attribute = attribute;
    this.selectedDefaultVisibilityId = "";
    if(!!this.attribute.defaultVisibility){
      this.selectedDefaultVisibilityId = this.attribute.defaultVisibility.id;
    }
    this.attributeOrig = cloneDeep(this.attribute);
    this.sortAttributeParameters();
  }

  private sortAttributeParameters = () => {
    if (this.attribute.attributeParameters?.length > 0) {
      this.attribute.attributeParameters.sort((a, b) => (a.order < b.order) ? -1 : 1);
    }
  }

}
