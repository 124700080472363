import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Page } from '../../../model/pim-response/page';
import { FacetSelection } from '../../../model/facet-selection';
import { SortType } from '../../../model/sort-type';
import { OverviewService } from '../../../service/overview.service';

@Component({
  selector: 'app-table-column',
  templateUrl: './table-column.component.html'
})
export class TableColumnComponent {

  constructor(private readonly overviewService: OverviewService) {}

  @Input()
  public name: string;

  @Input()
  public field: string;

  @Input()
  public value: string;

  @Input()
  public template: TemplateRef<any>;

  @Input()
  public width = '';

  @Input()
  public isSortable = false;

  @Input()
  public facetFormatting : boolean;

  @Input()
  public displayLinkIcon = false;

  set sortDirection(sortType: SortType) {
    this.overviewService.sortDirection.set(this.field, !!sortType ? sortType : SortType.NONE);
  }

  get sortDirection() : SortType{
    return !!this.overviewService.sortDirection.get(this.field) ? this.overviewService.sortDirection.get(this.field) : SortType.NONE;
  }

  @Output()
  public sortChange = new EventEmitter<TableColumnComponent>();

  public get facets(): FacetSelection[] {
    return this.overviewService.facets.get(this.field);
  }

  public set facets(value: FacetSelection[]) {
    this.overviewService.facets.set(this.field, value);
  }

  @Output()
  public facetsChange = new EventEmitter<FacetSelection[]>();

  @Output()
  public facetsInitialize = new EventEmitter<FacetSelection[]>();

  @Input()
  public isFilterable = false;

  @Input()
  public page: Page<any>;

  @Input()
  public valueFunction: (value: any) => string = (value: string) => value;

  @Input()
  public facetFormattingFunction: (value: any) => string = (value: string) => value;

  public columnValue(row: any): any {

    if (this.field) {
      return this.valueFunction(row[this.field]);
    } else {
      return this.value;
    }
  }

  public hasFacetsSelected(): boolean{
    return this.facets.some(facet => facet.selected);
  }
}
