import { Component, Input } from '@angular/core';
import { Attribute } from '../../../model/attribute';
import { AttributeValue } from '../../../model/attribute-value';
import { CategoryMaterial } from '../../../model/category-material';
import { CategoryFormulation } from '../../../model/category-formulation';
import { CategoryProductHierarchyObject } from '../../../model/category-product-hierarchy-object';
import { CategoryAttributeAttributeValue } from '../../../model/category-attribute-attribute-value';

@Component({
  selector: 'app-attribute-values',
  templateUrl: './attribute-values.component.html'
})
export class AttributeValuesComponent {
  @Input()
  public attribute: Attribute;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public attributeValues: AttributeValue[];

  @Input()
  public categoryRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue;

  @Input()
  public showInfoButton: boolean = false;

  @Input()
  public readonly: boolean = false;

  public updateCategoryRelationAttributeValue(
      attributeValues: AttributeValue[],
      categoryRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, attribute: Attribute): void {

    if(!!attribute) {
      if(attributeValues.length < 1) {
        attributeValues = [];
      }
      categoryRelation.attributeValues = [...categoryRelation.attributeValues.filter(av => av.attribute.id !== attribute.id), ...attributeValues];
    }
  }

  getId(): string {
    if(this.categoryRelation instanceof CategoryAttributeAttributeValue) {
      return this.categoryRelation?.categoryAttribute?.id;
    }
    return this.categoryRelation?.id;
  }
}
