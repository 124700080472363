import { Component, Input, OnInit } from '@angular/core';
import { CategoryMaterial } from '../../../../model/category-material';
import { Category } from '../../../../model/category';
import { AttributeRelevance } from '../../../../model/attribute-relevance';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import { CategoryService } from '../../../../service/category.service';
import { Attribute } from '../../../../model/attribute';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'material-tab-attribute-view',
  templateUrl: './material-tab-attribute-view.component.html',
  styleUrls: ['./material-tab-attribute-view.component.less']
})
export class MaterialTabAttributeViewComponent implements OnInit {

  public preFilteredCategoryMaterials = [];

  @Input()
  public readonly: boolean = false;

  @Input()
  public readonlyVisibility: boolean = false;

  @Input()
  public preFilterCategoryMaterials = (categoryMaterial: CategoryMaterial) => true;

  @Input()
  public preFilterAttributes: (categoryMaterial: CategoryMaterial[]) => Attribute[] = null;

  get categoryMaterials(): BehaviorSubject<CategoryMaterial[]> {
    return this.materialEditDataService.categoryMaterialsBehaviourSubject;
  }

  constructor(
    private readonly categoryService: CategoryService,
    private readonly materialEditDataService: MaterialEditDataService
  ) { }

  ngOnInit(): void {
  }

  public filterByRelevance(categoryMaterial: CategoryMaterial): boolean {
    return this.categoryService.hasCategoryAttributesByRelevance(categoryMaterial.category, AttributeRelevance.MATERIAL);
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    if (category.parent) {
      nodeList = this.createParentList(category.parent, nodeList);
    }
    nodeList.push(category);
    return nodeList;
  }

  public getAttributesToBeShown = (categoryMaterial: CategoryMaterial): [CategoryMaterial, Attribute][] => {
    let attributes: Attribute[];
    if(!!this.preFilterAttributes) {
      attributes = _.uniqBy(this.preFilterAttributes(this.preFilteredCategoryMaterials), 'id');
    } else {
      attributes = _.uniqBy(categoryMaterial.category.categoryAttributes.map(categoryAttribute => categoryAttribute.attribute), 'id')
        .filter(attribute => attribute.attributeRelevance.includes(AttributeRelevance.MATERIAL));
    }

    return attributes.map(attribute => [categoryMaterial, attribute]);
  }

  getPreFilteredCategoryMaterials = (categoryMaterials: CategoryMaterial[] | null): CategoryMaterial[] => {
    if (!!categoryMaterials) {
      this.preFilteredCategoryMaterials = categoryMaterials.filter(cm => this.filterByRelevance(cm));
      if (!!this.preFilterCategoryMaterials) {
        this.preFilteredCategoryMaterials = this.preFilteredCategoryMaterials.filter(cm => this.preFilterCategoryMaterials(cm) && this.filterByRelevance(cm));
      }
      return this.preFilteredCategoryMaterials;
    }
  }
}
