import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { catchError, Observable, throwError, timeout } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';
import { UserFilterSaveRequest } from '../../model/user-filter/user-filter-save-request';
import { UserFilterRenameRequest } from '../../model/user-filter/user-filter-rename-request';
import { BackendValidationService } from '../form-validation/backend-validation.service';
import { NotificationService } from '../notification.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class UserFilterBackendService {

  private restUrl: string = `${environment.restUrl}/overviewFilter`;
  private _requestTimeout: number = 10_000;

  constructor(
    private httpClient: HttpClient,
    private backendValidationService: BackendValidationService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) { }

  public listFiltersRequest(filterType: string): Observable<UserFilterResponse[]> {
    const url: string = `${this.restUrl}/listFilters`;
    const params: HttpParams = new HttpParams().set("filterType", filterType);
    return this.httpGetRequestRenderingErrorMessages(url, params);
  }

  public saveFilterRequest(userFilterSaveRequest: UserFilterSaveRequest): Observable<UserFilterResponse> {
    const url: string = `${this.restUrl}/saveFilter`;
    const successMessage: string = this.translateService.instant("user.filter.save.success.message");
    return this.httpPostRequestRenderingMessages(url, userFilterSaveRequest, successMessage);
  }

  public renameFilterRequest(userFilterRenameRequest: UserFilterRenameRequest): Observable<UserFilterResponse> {
    const url: string = `${this.restUrl}/renameFilter`;
    const successMessage: string = this.translateService.instant("user.filter.rename.success.message");
    return this.httpPostRequestRenderingMessages(url, userFilterRenameRequest, successMessage);
  }

  public deleteFilterRequest(userFilterResponse: UserFilterResponse): Observable<UserFilterResponse> {
    const url: string = `${this.restUrl}/deleteFilter`;
    const successMessage: string = this.translateService.instant("user.filter.delete.success.message");
    return this.httpPostRequestRenderingMessages(url, userFilterResponse, successMessage);
  }

  public setAsDefaultFilterRequest(filterId: string): Observable<UserFilterResponse> {
    const url: string = `${this.restUrl}/setAsDefaultFilter`;
    const successMessage: string = this.translateService.instant("user.filter.default.success.message");
    const params: HttpParams = new HttpParams().set("filterId", filterId);
    return this.httpGetRequestRenderingErrorMessages(url, params)
      .pipe(this.backendValidationService.renderMessages(successMessage));
  }

  private httpGetRequestRenderingErrorMessages(url: string, params: HttpParams): Observable<UserFilterResponse[]> {
    return this.httpClient.get<UserFilterResponse[]>(url, { params: params })
      .pipe(timeout(this._requestTimeout))
      .pipe(this.renderErrorMessages());
  }

  private httpPostRequestRenderingMessages(url: string, userFilterRenameRequest: UserFilterRenameRequest, successMessage: string): Observable<UserFilterResponse> {
    return this.httpClient.post<UserFilterResponse>(url, userFilterRenameRequest)
      .pipe(this.renderErrorMessages())
      .pipe(this.backendValidationService.renderMessages(successMessage))
      .pipe(timeout(this._requestTimeout));
  }

  private renderErrorMessages(): any {
    return catchError((response: HttpErrorResponse, caught: Observable<any>) => this.renderErrorNotifications(response, caught));
  }

  private renderErrorNotifications(response: HttpErrorResponse, caught: Observable<any>): any {
    this.addUserFilterErrorNotification();
    return throwError(() => caught);
  }

  private addUserFilterErrorNotification(): void {
    this.notificationService.addErrorNotification('user.filter.error');
  }

}

