import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Attribute} from '../../../../model/attribute';
import {Category} from '../../../../model/category';
import {CategoryType} from '../../../../model/category-type';
import {Pageable} from '../../../../model/pageable';
import {CategoryAttribute} from '../../../../model/category-attribute';
import {ConfirmService} from '../../../../service/confirm.service';
import {NotificationService} from '../../../../service/notification.service';
import {AttributeTypeaheadService} from '../../../../service/typeahead/attribute-typeahead.service';
import {CategoryByTypeTypeaheadService} from '../../../../service/typeahead/category-by-type-typeahead.service';
import {CategoryUsageService} from '../../../../service/category-usage.service';
import {CategoryService} from '../../../../service/category.service';
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import {HasUnsavedChangesGuard} from "../../../../guards/has-unsaved-changes.guard";


@Component({
  selector: 'category-administration-edit',
  templateUrl: './category-administration-edit.component.html',
  styleUrls: ['./category-administration-edit.component.less']
})
export class CategoryAdministrationEditComponent implements OnInit, HasUnsavedChangesGuard {

  public category: Category = new Category();
  public categoryOrig: Category = null;
  public categoryType = CategoryType;
  public newAttribute: Attribute;
  public regionValiditySet: Set<Category> = new Set<Category>();
  public usageCount: number;
  public categoryInUse: boolean;
  public showUsages = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly categoryService: CategoryService,
    private readonly categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private readonly attributeTypeaheadService: AttributeTypeaheadService,
    private readonly notificationService: NotificationService,
    private readonly categoryUsageService: CategoryUsageService,
    private readonly confirmService: ConfirmService
  ) {
  }

  ngOnInit(): void {

    this.category = new Category();
    this.route.params.subscribe(params => {
      if (params.id !== 'add') {
        this.showUsages = false;
        this.categoryService.load(params.id).subscribe((category: Category) => {
          this.category = category;
          this.categoryUsageService.findOverallUsageCount(category).subscribe(result => this.categoryInUse = result);
          this.categoryOrig = cloneDeep(this.category);
        });
      }

      if (!this.category) {
        this.cancel();
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.categoryChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public categoryParentTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
      this.getCategoryType(), (this.category.id ? [this.category.id] : []));

  public regionalValidityTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
      [CategoryType.REGION], this.category.regionalValidity.map(m => m.id));

  public catalogValidityTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
      [CategoryType.CATALOG], this.category.catalogValidity.map(m => m.id));

  public categoryTypeaheadFormatter = (model: Category) => this.categoryByTypeTypeaheadService.typeaheadFormatterParentPath(model);

  public attributeTypeahead = (searchTerm: string, pageable: Pageable) => this.attributeTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.category.categoryAttributes.map(categoryAttribute => categoryAttribute.attribute?.id));
  public attributeTypeaheadFormatter = (model: Attribute) => this.attributeTypeaheadService.typeaheadFormatterWithType(model);
  public resultFormatter = (model: Attribute) => this.attributeTypeaheadService.typeaheadFormatter(model);
  public typeFormatter = (model: Attribute) => model?.type;

  public categoryChanges(): boolean {
    if (this.categoryService.isEqual(this.categoryOrig, this.category)) {
      return false;
    } else {
      return true;
    }
  }

  public save() {
      this.categoryService.save(this.category).subscribe((category: Category) => {
        this.notificationService.addSuccessNotification('label.successfully.saved');
        this.category = category;
        this.categoryOrig = cloneDeep(this.category);
        this.editItem(this.category.id);
      });
  }

  editItem(id: string) {
    this.router.navigateByUrl('adminstration/category/' + id);
  }

  public cancel() {
    this.router.navigateByUrl('adminstration/category');
  }

  public addAttribute() {

    if(this.newAttribute) {
      let categoryValueForNewCategoryAttribute = null;

      if(!!this.category.id) {
        categoryValueForNewCategoryAttribute = _.cloneDeep(this.category);
      }

      this.category.categoryAttributes.push(new CategoryAttribute(null, categoryValueForNewCategoryAttribute , _.cloneDeep(this.newAttribute)));
      this.newAttribute=null;

    }
  }

  public removeAttribute(attribute: Attribute) {
    this.category.categoryAttributes = this.category.categoryAttributes.filter(categoryAttribute => categoryAttribute.attribute.id !== attribute.id);
  }

  public isCatalog() {
    return this.category.type == CategoryType.CATALOG;
  }

  public disableParent() {
    return !this.category.type || this.isCatalog()
  }

  public onCatagoryTypeChange() {
    this.category.parent = null;

    if(this.isCatalog()) {
      this.category.catalogValidity = [];
      this.category.regionalValidity = [];
    }
  }

  private getCategoryType(): Array<any> {

    if (this.category.type) {

      switch(this.category.type) {
        case CategoryType.APPLICATION: {
          return ['INDUSTRY', 'APPLICATION'];
        }
        case CategoryType.SOLUTION: {
          return ['INDUSTRY', 'SOLUTION'];
        }
        case CategoryType.CATALOG: {
          return []; // Catalog Catagories never have parents
        }
        default: {
          return [this.category.type];
        }
      }
    }

    return Object.keys(CategoryType).filter(k => typeof CategoryType[k as any] === 'number');
  }

  public delete() {
    this.confirmService.confirm('title.confirm.delete', 'text.confirm.category.deletion', 'button.yes', 'button.no').then(confirmResult => {
      if (confirmResult) {
        this.categoryService.delete(this.category).subscribe(() => {
          this.notificationService.addSuccessNotification('label.successfully.deleted');
          this.router.navigateByUrl('adminstration/category');
        });
      }
    });
  }

  public transformToCategoryAttribute = (value: Attribute) => {
    if (!!value){
      let categoryValueForNewCategoryAttribute = null;

      if(!!this.category.id) {
        categoryValueForNewCategoryAttribute = _.cloneDeep(this.category);
      }

      return new CategoryAttribute(null, categoryValueForNewCategoryAttribute , _.cloneDeep(value));
    }
    return null;
  }

  public getAdminLink = (value: CategoryAttribute) => {
    return '/adminstration/attribute/' + value.attribute?.id;
  }
}
