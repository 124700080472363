import { Injectable } from "@angular/core";
import { concatMap } from "rxjs";
import { Formulation } from "../../model/formulation";
import { CategoryFormulation } from "../../model/category-formulation";
import { CategoryFormulationService } from '../category-formulation.service';
import { FormulationService } from '../formulation.service';
import { FormulationFormValidationService } from "../form-validation/formulation-form-validation.service";
import { CategoryFormulationFormValidationService } from "../form-validation/category-formulation-form-validation.service";
import { NotificationService } from "../notification.service";
import { cloneDeep } from 'lodash';
import { Category } from "../../model/category";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class FormulationEditDataService {

  public formulation: Formulation = new Formulation();
  public formulationOrig: Formulation = null;
  public categoryFormulations: CategoryFormulation[] = [];
  public categoryFormulationsOrig: CategoryFormulation[] = [];
  public formulationLoaded: boolean = false;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly formulationService: FormulationService,
    private readonly categoryFormulationService: CategoryFormulationService,
    private readonly formulationFormValidation: FormulationFormValidationService,
    private readonly categoryFormulationFormValidation: CategoryFormulationFormValidationService,
    private readonly router: Router
  ) { }

  public init(id: string) {

    if(id == 'add') {
      this.formulation = new Formulation();
      this.formulationOrig = null;
      this.categoryFormulations = [];
      this.categoryFormulationsOrig = [];
      this.formulationLoaded = true;
      return;
    }

    this.formulationService.load(id).subscribe((formulation: Formulation) => {
      if (formulation !== null) {
        this.initiateFormulation(formulation);
        this.categoryFormulationService.loadByFormulationIdAndCreateAttributeValuesIfNotExist(formulation.id).subscribe((categoryFormulations: CategoryFormulation[]) => {

          this.categoryFormulations = categoryFormulations;
          this.categoryFormulationsOrig = cloneDeep(this.categoryFormulations);
          this.formulationLoaded = true;
        });
      }
    });
  }

  public saveFormulation(selectedTab: string): void {
    let formulationId = 'add';
    if (this.formulationFormValidation.validate(this.formulation) && this.categoryFormulationFormValidation.validate(this.categoryFormulations)) {
      this.formulationService.save(this.formulation).pipe(
        concatMap((savedFormulation: Formulation) => {
          formulationId = savedFormulation.id;
          this.initiateFormulation(savedFormulation);
          this.categoryFormulations.forEach((categoryFormulation: CategoryFormulation) => {
            categoryFormulation.formulation = savedFormulation;
          });
          this.categoryFormulationsOrig = cloneDeep(this.categoryFormulations);
          return this.categoryFormulationService.save(savedFormulation.id, this.categoryFormulations);
        })
      )
      .subscribe(categoryFormulations => {
        this.notificationService.addSuccessNotification('label.successfully.saved');
        this.router.navigateByUrl(`formulations/${formulationId}/${selectedTab}`);
      });
    } else {
      this.formulationFormValidation.renderErrors();
      this.categoryFormulationFormValidation.renderErrors();
    }
  }

  private initiateFormulation(formulation: Formulation) {
    this.formulation = formulation;
    this.formulation.parts = this.formulation.parts.sort((a, b) => (a.order < b.order) ? -1 : 1);
    this.formulation.parts.forEach(part => { 
      part.steps = part.steps.sort((a, b) => (a.order < b.order) ? -1 : 1); 
    });
    this.formulationOrig = cloneDeep(this.formulation);
  }

  public formulationChanged(): boolean {
    let formulationEquals = this.formulationService.isEqual(this.formulationOrig, this.formulation);
    let categoryFormulationsEquals = this.categoryFormulationService.isEqual(this.categoryFormulationsOrig, this.categoryFormulations);
    return !(formulationEquals && categoryFormulationsEquals);
  }

  public removeCategoryFormulations(category: Category) {
    this.removeCategoryFormulationIfNotInUse(category, category);
  }

  private removeCategoryFormulationIfNotInUse(childCategory: Category, categoryToRemove: Category) {
    if (!this.isCategoryFormulationNeedByAnotherCategory(childCategory, categoryToRemove)) {
      this.categoryFormulations = this.categoryFormulations.filter(categoryFormulation => categoryFormulation.category.id != categoryToRemove.id);
      if (!!categoryToRemove.parent) {
        this.removeCategoryFormulationIfNotInUse(categoryToRemove, categoryToRemove.parent);
      }
    }
  }

  private isCategoryFormulationNeedByAnotherCategory(childCategory: Category, categoryToRemove: Category): boolean {
    return this.categoryFormulations.some(
      categoryFormulation => categoryFormulation.category.id !== childCategory.id
      && !!categoryFormulation.category.parent
      && categoryFormulation.category.parent.id === categoryToRemove.id);
  }

  public findFormulation = (): Formulation => this.formulation;
  public findCategoryFormulations = (): CategoryFormulation[] => this.categoryFormulations;
  public isFormulationLoaded = (): boolean => this.formulationLoaded;
}
