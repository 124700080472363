import { Component, OnInit } from '@angular/core';
import InjectIsNotAdvancedUser from '../../../../../decorator/inject-is-not-advanced-user.decorator';
import { Observable } from 'rxjs';
import { DocumentCreationDataService } from '../../../../../service/data-service/document-creation-data.service';
import { Material } from '../../../../../model/material';

@Component({
  selector: 'app-tab-document-relations',
  templateUrl: './tab-document-relations.component.html',
  styleUrls: ['./tab-document-relations.component.less']
})
export class TabDocumentRelationsComponent implements OnInit {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  public material: Material;

  constructor(
    private readonly documentCreationDataService: DocumentCreationDataService,
  ) { }

  ngOnInit(): void {
    this.material = this.documentCreationDataService.templateMaterialBehaviorSubject.getValue().material;
  }

}
