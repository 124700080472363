import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { Slice } from '../../model/pim-response/slice';
import { Material } from '../../model/material';
import { Pageable } from '../../model/pageable';
import { TypeaheadTypeAndExcludeIdRequest } from '../../model/request/typeahead-type-and-exclude-id-request';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';
import { MaterialType } from '../../model/material-type';


@Injectable({
  providedIn: 'root', deps: [HttpClient]
})
export class EnrichedMaterialTypeaheadService implements AbstractTypeaheadService<Material> {

  constructor(
    private readonly baseTypeaheadService: BaseTypeaheadService,
    private readonly translate: TranslateService
  ) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, types: MaterialType[], exclude: string[]): Observable<Slice<Material>> {

    let body = new TypeaheadTypeAndExcludeIdRequest<MaterialType>(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      types,
      exclude
    );

    return this.baseTypeaheadService.createCall('/material/typeahead/v2/enriched', body, pageable);
  }

  public typeaheadFormatter(model: Material): string {

    let materialName = this.baseTypeaheadService.localizedStringFormatter(model.name);

    if (model.type.toString()  === MaterialType[MaterialType.PRD]) {
      materialName += ' (' + model.id + ')';
    }

    materialName +=  ' - ' + this.translate.instant('formulationstep.label.' + model.type.toString().toLowerCase()) + ' ';

    if (model.type.toString()  === MaterialType[MaterialType.THIRD_PARTY_PRODUCT]) {
      materialName +=  ' - ' + this.translate.instant('label.material.search.supplier') + ': ' + (model.supplier ? model.supplier : '');
    }

    return materialName;
  }
}
