import {CustomViewType} from './custom-view-type';
import {CustomView} from './custom-view';
import {LocalizedString} from '../localized-string';
import {Realm} from '../realm/realm';
import {CategoryAttribute} from '../category-attribute';
import {User} from '../user';

export class BasicView implements CustomView {
  constructor(
    public id: string = null,
    public name: LocalizedString[] = [],
    public realm: Realm = null,
    public description: string = null,
    public customViewCategoryAttributes: CategoryAttribute[] = [],
    public users: User[] = [],
    public type: CustomViewType = CustomViewType.BASIC_VIEW) {
  }
}
