<div class="modal-body" autoClose=false (openChange)="openChange($event, false)" ngbDropdown #myDrop="ngbDropdown" role="group">
  <fa-icon id="facet-{{ column?.name }}" ngbDropdownToggle icon="filter" [ngClass]="{'filtered': column.hasFacetsSelected()}"></fa-icon>
  <div class="dropdown-menu" ngbDropdownMenu>

    <div class="input-wrapper">
      <input type="text"
        class="form-control"
        [placeholder]=" 'placeholder.facet.search' | translate "
        [(ngModel)]="searchString"
        [ngModelOptions]="{standalone: true}">
    </div>

    <h6 *ngIf="getSelectedFacets().length > 0" class="dropdown-header">Selected Facets</h6>
    <div *ngIf="getSelectedFacets().length > 0" class="selected-facet-list">
      <div class="facet-element" *ngFor="let facet of getSelectedFacets()">
        <div *ngIf="column.facetFormatting">
          {{column.facetFormattingFunction(facet.value)}} ({{facet.count}})
        </div>
        <div *ngIf="!column.facetFormatting">
          {{facet.value}} ({{facet.count}})
        </div>
        <fa-icon icon="trash" (click)="removeFacet(facet)"></fa-icon>
      </div>
    </div>

    <h6 class="dropdown-header">Selectable Facets</h6>
    <div class="selectable-facet-list">
      <div *ngIf="column.facetFormatting">
        <div class="facet-element"
          *ngFor="let facet of getSelectableFacets()"
          (click)="selectFacet(facet)"
          [innerHTML]="column.facetFormattingFunction(facet.value) | highlightTerm : searchString : ' (' + facet.count + ')'">
        </div>
      </div>
      <div *ngIf="!column.facetFormatting">
        <div class="facet-element"
          *ngFor="let facet of getSelectableFacets()"
          (click)="selectFacet(facet)"
          [innerHTML]="facet.value | highlightTerm : searchString : ' (' + facet.count + ')'">
        </div>
      </div>
    </div>

    <div class="dropdown-divider"></div>

    <icon-button buttonType="confirm" (click)="openChange(false, true); $event.stopPropagation(); myDrop.close();"></icon-button>

  </div>
</div>
