import { Component } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom, tap } from 'rxjs';
import { UserFilterResponse } from '../../../model/user-filter/user-filter-response';
import { UserFilterDataService } from '../../../service/user-filter/user-filter-data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from '../../../service/confirm.service';
import { UserFilterSaveRequest } from '../../../model/user-filter/user-filter-save-request';
import { UserFilterParameter } from '../../../model/user-filter/user-filter-parameter';

@Component({
  selector: 'app-user-filter-save',
  templateUrl: './user-filter-save.component.html',
  styleUrl: './user-filter-save.component.less'
})
export class UserFilterSaveComponent {

  public filterName: string;

  constructor(
    private modal: NgbActiveModal,
    private confirmService: ConfirmService,
    private userFilterDataService: UserFilterDataService
  ) { }

  async clickOk() {
    const filters: UserFilterResponse[] = await this.getFilters();
    const existingFilters: UserFilterResponse[] = _(filters).value();
    const existingFilterNames: string[] = _(existingFilters).map(f => f.filterName.toLowerCase()).value();
    const filterName: string = this.filterName;

    const matchingFilterCount: number = existingFilterNames.filter(f => filterName.toLowerCase() === f).length;
    const filterExists: boolean = matchingFilterCount >= 1;
    if (filterExists) {
      const overrideFilter = await this.askToConfirmOverwriting();
      if (!overrideFilter) {
        this.modal.close();
        return;
      }
    }

    const filterId: string = _(existingFilters).find(f => f.filterName === filterName.toLowerCase())?.filterId;
    const filterType: string = this.userFilterDataService.filterTypeBehaviourSubject.getValue();
    const defaultFilter: boolean = false;
    
    const userFilterParameters: UserFilterParameter[] = this.userFilterDataService.getUserFilterParameters();
    const request: UserFilterSaveRequest = new UserFilterSaveRequest(filterId, filterName, filterType, userFilterParameters, defaultFilter);

    lastValueFrom(this.userFilterDataService.saveFilter(request).pipe(tap(() => {
      this.modal.close();
    })));
  }

  clickCancel(): void {
    this.modal.close();
  }

  private async getFilters(): Promise<UserFilterResponse[]> {
    return this.userFilterDataService.userFiltersBehaviourSubject.getValue();
  }


  private async askToConfirmOverwriting() {
    return await this.confirmService.confirm('user.filter.overwrite.dialog.title', 'user.filter.overwrite.dialog.message', 'button.yes', 'button.cancel');

  }
}
