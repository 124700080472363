export default function ConditionalRequired(args: any) {

  return function(target: any, prop: any) {

    const NG_ON_ONIT_NAME = 'ngOnInit';

    const ngOnInitClone: Function|null = target[NG_ON_ONIT_NAME];

    if(!ngOnInitClone){
      throw new Error("The class using the '@ConditionalRequired' decorator must implement angular onInit.");
    }

    Object.defineProperty(target, NG_ON_ONIT_NAME, {
      value: function() {
        
        if ( this[prop] == null && this[args.field] == null && !this[args.validationEnabled] ) {
          throw new Error(`${target.constructor.name}: The [${prop}] or the [${args.field}] must be set. `);
        }

        if ( this[prop] != null && this[args.field] != null && this[args.validationEnabled] ) {
          throw new Error(`${target.constructor.name}: Only one of the following properties may be set: [${prop}], [${args.field}].`);
        }
        
        if( ngOnInitClone ) {
          ngOnInitClone.call(this);
        }
      }
    });
  };
}
