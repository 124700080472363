import { Component, Input } from '@angular/core';
import { AttributeValue } from '../../../../model/attribute-value';

@Component({
  selector: 'boolean-attribute-edit',
  templateUrl: './boolean-attribute-edit.component.html',
  styleUrls: ['./boolean-attribute-edit.component.less']
})
export class BooleanAttributeEditComponent {

  @Input()
  public label: string;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public readonly: boolean = false;

  public identifier: string; // used for input.id and label.for

  constructor() {
    this.identifier = 'id' + Math.random();
  }
}
