import { Injectable } from '@angular/core';
import { AttributeValue } from '../model/attribute-value';

@Injectable({
  providedIn: 'root'
})
export class IsAttributeMaintainedService {

  constructor() { }

  public isSingleAttributeValueMaintained(attributeValue: AttributeValue): boolean {
    switch (attributeValue.attribute.type) {
      case 'NUMBER': {
        return this.isAvNumberMaintained(attributeValue);
      }
      case 'RANGE': {
        return this.isAvNumberMaintained(attributeValue);
      }
      case 'STAR_RATING': {
        return this.isAvStarRatingMaintained(attributeValue);
      }
      case 'STRING': {
        return this.isAvStringMaintained(attributeValue);
      }
      case 'LOCALIZED_STRING': {
        return this.isAvLocalizedStringMaintained(attributeValue);
      }
      case 'BOOL': {
        return this.isAvBoolMaintained(attributeValue);
      }
      case 'ENUMERATION': {
        return this.isAvStringMaintained(attributeValue) || this.isAvSingleChoiceListValueMaintained(attributeValue);
      }
      case 'LOCALIZED_ENUMERATION': {
        return this.isAvLocalizedEnumerationMaintained(attributeValue);
      }
      case 'RELATIONSHIP': {
        return this.isAvRelationshipMaintained(attributeValue);
      }
      case 'LOCALIZED_ENUMERATION_MULTIPLE': {
        return this.isAvLocalizedEnumerationMaintained(attributeValue);
      }
      case 'ENUMERATION_MULTIPLE': {
        return this.isAvStringMaintained(attributeValue);
      }
      case 'STRING_LIST': {
        return this.isAvStringMaintained(attributeValue);
      }
      case 'LOCALIZED_STRING_LIST': {
        return this.isAvLocalizedStringMaintained(attributeValue);
      }
      case 'CUSTOM': {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  public isMultiValueAttributeValueMaintained(attributeValue: AttributeValue[]): boolean {
    return attributeValue.some(av => {
      return this.isSingleAttributeValueMaintained(av) && av.active === true;
    });
  }

  public isAvNumberMaintained(attributeValue: AttributeValue): boolean {
    return (!!attributeValue.number || !!attributeValue.upperValue)
  }

  public isAvStarRatingMaintained(attributeValue: AttributeValue): boolean {
    return !!attributeValue.number;
  }

  public isAvStringMaintained(attributeValue: AttributeValue): boolean {
    return this.isStringNotBlank(attributeValue.string);
  }

  public isAvSingleChoiceListValueMaintained(attributeValue: AttributeValue): boolean {
    return !!attributeValue && !!attributeValue.singleChoiceListValue && this.isStringNotBlank(attributeValue.singleChoiceListValue.id);
  }

  public isAvLocalizedStringMaintained(attributeValue: AttributeValue): boolean {
    if(!!attributeValue.localizedString) {
      return attributeValue.localizedString.some(ls => {
        return this.isStringNotBlank(ls.value);
      });
    }
    return false;
  }

  public isAvBoolMaintained(attributeValue: AttributeValue): boolean {
    return !(attributeValue.bool === undefined || attributeValue.bool === null);
  }

  public isAvLocalizedEnumerationMaintained(attributeValue: AttributeValue): boolean {
    return !!attributeValue.localizedEnumerationValue?.id;
  }

  public isAvRelationshipMaintained(attributeValue: AttributeValue): boolean {
    return !!attributeValue.relationship?.material?.id;
  }

  private isStringNotBlank(value: string) {
    return !!value && value.trim() !== '';
  }
}
