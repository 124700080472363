<div *ngIf="node.display">

  <div (click)="toggle()" class="node-line" [ngClass]="{ 'has-children' : hasChildren() }">
    <span class="icon-placeholder" [ngClass]="{ 'opacity-100' : hasChildren(), 'opacity-0' : !hasChildren() }">
      <fa-icon icon="caret-right"
        class="fa-lg"
        *ngIf="!node.displayChildren"></fa-icon>
      <fa-icon icon="caret-down"
        class="fa-lg caret-down"
        *ngIf="!!node.displayChildren"></fa-icon>
    </span>
    <span
      [ngClass]="{ 'clickable' : !!isNodeClickable }"
      (click)="onNodeClick(node.model.id)">
      <ng-container *ngTemplateOutlet="treeViewLineTemplate, context: { 'result': node.model }"></ng-container>
    </span>
    <span *ngIf="!!isNodeClickable" class="externalLink">
      <a (click)="onNodeNewTabClick(node.model.id)" href="javascript:void(0);"><fa-icon icon="external-link-alt" class="fa-sg"></fa-icon></a>
    </span>
  </div>
  <div *ngIf="node.displayChildren" class="indent-line">
    <div class="indent">
      <app-tree-view-node *ngFor="let child of node.children"
        (loadChildren)="onLoadChildren($event)"
        (nodeClick)="onNodeClick($event)"
        (nodeNewTabClick)="onNodeNewTabClick($event)"
        [node]="child"
        [isNodeClickable]="!!isNodeClickable"
        [treeViewLineTemplate]="treeViewLineTemplate"
        [formatNodeLabel]="formatNodeLabel">
      </app-tree-view-node>
      <div *ngIf="hasUnshownChildren() && hasShownChildren()">
        <span><fa-icon class="opacity-0 fa-lg caret-spacer" icon="caret-right"></fa-icon></span>
        <fa-icon icon="ellipsis-h" class="three-dots" (click)="onLoadChildren(node)"></fa-icon>
      </div>
    </div>
  </div>

</div>
