<pim-popover class="tooltip-target-element"
	[(isActive)]="infoButtonClicked">
	<div class="popover-container">
    <typeahead-template-category-relatives [model]="getCategory()" [showCategoryLink]="false"></typeahead-template-category-relatives>
	</div>
</pim-popover>

<div>
  <div>
    <div [className]="isActive() ? 'attribute-value active' : 'attribute-value inactive'" (click)="activate()">
      <div class="inner-attribute-value-container"
           [class.green-border]="areAttributeValuesMandatory && !isDisabled(attributeValueRelation, attribute) && isMaintained()"
           [class.red-border]="areAttributeValuesMandatory && !isDisabled(attributeValueRelation, attribute) && !(isMaintained())"
           (click)="setInfoButtonClicked(false)">

        <div class="attribute-value-header mx-3 py-2">
          <div class="header-label" *ngIf="!!attribute">
            <fa-icon class="fa-tooltip-info" icon="info" *ngIf="attribute.tooltip?.length > 0"
              pimtooltip="{{ attribute.tooltip | localizedStringsFilter }}" [closingDelay]="900"></fa-icon>
            <span class="label-bold" id="attribute-value-label-{{ attribute.name | localizedStringsFilter }}"
              ngbPopover="{{ attribute.name | localizedStringsFilter }}{{ (attribute.unit?.symbol != null) ? ' (' + attribute.unit?.symbol + ')' : '' }}"
              triggers="mouseenter:mouseleave"
              [openDelay]="900">
              {{ attribute.name | localizedStringsFilter }} <span class="unit" *ngIf="attribute.unit?.symbol != null">({{ attribute.unit.symbol }})</span>
            </span>
          </div>

          <div class="iconlist">

            <app-visibility *ngIf="showVisibility"
              class="visibility-list"
              [(visibility)]="visibility"
              [noValuePersisted]="isVisibilityCalculated()"
              [defaultVisibility]="attribute.defaultVisibility"
              (visibilityChange)="visibilityChange($event)"
              [readonly]="readonlyVisibility">
            </app-visibility>

            <fa-icon *ngIf="showInfoButton"
              [ngClass]="infoButtonClicked ? 'fa-icon-info clicked' : 'fa-icon-info'"
              icon="sitemap"
              (click)="$event.stopPropagation();setInfoButtonClicked(!infoButtonClicked)"></fa-icon>

            <fa-icon class="fa-icon-inactivate"
              id="fa-icon-inactivate-attribute-value-{{ attribute.name | localizedStringsFilter }}"
              *ngIf="isActive() && !readonly" (click)="inactivateAttributeValue();$event.stopPropagation();setInfoButtonClicked(false)"
              icon="trash"></fa-icon>

          </div>
        </div>

        <div class="attribute-value-content m-3">

          <app-attribute-values-parameterized class="parameter-values-row" *ngIf="isAttributeWithParameters(attribute)"
                                              [attribute]="attribute"
                                              [attributeValues]="attributeValues"
                                              [categoryRelation]="attributeValueRelation"
                                              [showInfoButton]="showInfoButton"
                                              [readonly]="readonly">
          </app-attribute-values-parameterized>

          <app-attribute-values class="attribute-values-row"  *ngIf="!isAttributeWithParameters(attribute)"
                                [attribute]="attribute"
                                [attributeValue]="attributeValue"
                                [attributeValues]="attributeValues"
                                [categoryRelation]="attributeValueRelation"
                                [showInfoButton]="showInfoButton"
                                [readonly]="readonly">
          </app-attribute-values>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #categoryTreeTemplate let-result="result">
  <typeahead-template-category-relatives [model]="result" [showCategoryLink]="false"></typeahead-template-category-relatives>
</ng-template>
