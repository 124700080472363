import { Component, OnDestroy, OnInit } from "@angular/core";
import { CustomViewEditDataService } from "../../../../../service/data-service/customview-edit-data.service";
import { Subscription } from "rxjs";
import { LocalizedString } from "../../../../../model/localized-string";
import { DynamicTemplateView } from "../../../../../model/custom-view/dynamic-template-view";
import { DynamicTemplateDocumentContent } from "../../../../../model/custom-view/dynamic-template-document-content";

@Component({
  selector: 'customview-tab-dynamic-template',
  templateUrl: './customview-tab-dynamic-template.component.html',
  styleUrls: ['./customview-tab-dynamic-template.component.less'],
})
export class CustomViewTabDynamicTemplateComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  dynamicTtemplateDocumentContentInputString: string = '';

  constructor(private readonly customViewEditDataService: CustomViewEditDataService) {
  }

  get dynamicTemplateDocumentContentSubject() {
    return this.customViewEditDataService.dynamicTemplateDocumentContentSubject;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getDynamicTemplateView = (): DynamicTemplateView => this.customViewEditDataService.getDynamicTemplateView();

  public isCustomViewTypeDynamicTemplateView = (): boolean => this.customViewEditDataService.isDynamicTemplateView();

  public getTemplateFileNames(): LocalizedString[] {
    if (!this.customViewEditDataService.getDynamicTemplateView().templateFileNames) {
      this.customViewEditDataService.getDynamicTemplateView().templateFileNames = [];
    }
    return this.customViewEditDataService.getDynamicTemplateView().templateFileNames;
  }

  deleteValueFortemplateViewList = (templateViewList) => {
    this.customViewEditDataService.deleteDynamicTemplateViewList(templateViewList);
  }

  addDynamicTemplateDocumentContent = (input: string) => {
    if (input.trim() !== '') {
      const dynamicTemplateDocumentContent = new DynamicTemplateDocumentContent();
      dynamicTemplateDocumentContent.name = input;
      this.customViewEditDataService.addDynamicTemplateDocumentContent(dynamicTemplateDocumentContent);
    }
    this.dynamicTtemplateDocumentContentInputString = '';
  }
}
