import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Formulation } from '../../../../model/formulation';
import { FormulationStep } from '../../../../model/formulation-step';
import { Material } from '../../../../model/material';
import { CategoryType } from '../../../../model/category-type';
import { Category } from '../../../../model/category';
import { Pageable } from '../../../../model/pageable';
import { MaterialType } from '../../../../model/material-type';
import { FormulationPart } from '../../../../model/formulation-part';
import { Unit } from '../../../../model/unit';
import { LocalizedStringService } from '../../../../service/localized-string.service';
import { EnrichedMaterialTypeaheadService } from '../../../../service/typeahead/enriched-material-typeahead.service';
import { UnitService } from '../../../../service/unit.service';
import { FormulationStepFormValidationService } from '../../../../service/form-validation/formulation-step-form-validation.service';
import { ConfirmService } from '../../../../service/confirm.service';
import { CategoryOnLeafLevelTypeaheadService } from '../../../../service/typeahead/category-on-leaf-level-typeahead.service';
import { FormulationEditDataService } from '../../../../service/data-service/formulation-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable, share } from 'rxjs';

@Component({
  selector: 'formulation-tab-steps',
  templateUrl: './formulation-tab-steps.component.html',
  styleUrls: ['./formulation-tab-steps.component.less']
})
export class FormulationTabStepsComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public formulationUnits: Unit[] = [];
  public newStepFlag: string = '';
  public editStepFlag: string = '';
  public editPartFlag: string = '';
  public disableEdit = false;
  public disablePartEdit = false;
  public showNewPart = false;
  public newFormulationStep: FormulationStep;
  public part: FormulationPart;
  public stepCopyForEdit: FormulationStep;
  public partCopyForEdit: FormulationPart;

  private currentUnit: Unit;

  constructor(
    private readonly localizedStringService: LocalizedStringService,
    private readonly enrichedMaterialTypeaheadService: EnrichedMaterialTypeaheadService,
    private readonly categoryTypeaheadService: CategoryOnLeafLevelTypeaheadService,
    private readonly unitService: UnitService,
    private readonly confirmService: ConfirmService,
    private readonly formulationStepformValidationService: FormulationStepFormValidationService,
    private readonly formulationEditDataService: FormulationEditDataService
  ) {}

  ngOnInit() {
    this.unitService.relevantFormulationSearch().subscribe(units => {
      if (units && units.length > 0) {
        for (const unit of units) {
          this.formulationUnits.push(unit);
        }
        if (this.formulationUnits.length > 1) {
          this.sortFormulationUnitsAsc();
        }
      }
    });
  }

  public materialTypeahead = (searchString: string, pageable: Pageable) => this.enrichedMaterialTypeaheadService.typeaheadSearch(searchString, pageable, [MaterialType.PRD, MaterialType.THIRD_PARTY_PRODUCT, MaterialType.BASE_INGREDIENT], []);
  public materialTypeaheadFormatter = (model: Material) => this.enrichedMaterialTypeaheadService.typeaheadFormatter(model);

  public productGroupTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryTypeaheadService.typeaheadSearch(searchTerm, pageable, [CategoryType.PRODUCT_GROUP], []);
  public productGroupTypeaheadFormatter = (model: Category) => this.categoryTypeaheadService.typeaheadFormatter(model);

  public findFormulation = (): Formulation => this.formulationEditDataService.findFormulation();

  private establishCurrentUnit() {
    this.currentUnit = null;
    if (this.formulationUnits.length === 1) {
      this.currentUnit = this.formulationUnits[0];
    }

    if (this.findFormulation() && this.findFormulation().parts.length > 0 ) {
      for(const part of this.findFormulation().parts) {
        if (part.steps.length > 0) {
          const firstStep = part.steps[0];
          this.currentUnit = firstStep.unit;
          break;
        }
      }
    }
  }

  private sortFormulationUnitsAsc() {
    this.formulationUnits.sort((a, b) => {
      const aInEnglish = this.localizedStringService.getLocalizedStringsValue(a.name);
      const bInEnglish = this.localizedStringService.getLocalizedStringsValue(b.name);
      return aInEnglish.localeCompare(bInEnglish);
    });
  }

  /* Flags to show new/edit components */
  public generateNewStepFlag(partIndex: string): string {
    return partIndex !== '' ? 'newStep' + partIndex : partIndex;
  }

  public generateEditStepFlag(partIndex: string, stepIndex: string): string {
    return  partIndex !== '' ? 'step' + partIndex + stepIndex : partIndex;
  }

  public generateEditPartFlag(partIndex: string): string {
    return  partIndex !== '' ? 'part' + partIndex : partIndex;
  }

  public isNewStep(partIndex: string): boolean {
    return this.newStepFlag === this.generateNewStepFlag(partIndex);
  }

  public isEditStep(partIndex: string, stepIndex: string): boolean {
    return this.editStepFlag === this.generateEditStepFlag(partIndex, stepIndex);
  }

  public isEditPart(partIndex: string): boolean {
    return this.editPartFlag === this.generateEditPartFlag(partIndex);
  }

  public isCurrentUnitDefined(): boolean {
    if(this.currentUnit === null) {
      return false;
    } else {
      return true;
    }
  }

  public compareUnitById(UnitOne: Unit, UnitTwo: Unit): boolean {
    return UnitOne && UnitTwo && UnitOne.id === UnitTwo.id;
  }

  public toggleEditMode() {
    this.disableEdit = !this.disableEdit;
  }

  public toggleEditPartMode() {
    this.disablePartEdit = !this.disablePartEdit;
  }

  private toggleNewPart(): void {
    this.showNewPart = !this.showNewPart;
  }

  /* Parts logic */
  public generateNewPart(): void {
    this.part = new FormulationPart();
    this.toggleNewPart();
  }

  public confirmNewPart(): void {
    this.findFormulation().parts.push(this.part);
    this.findFormulation().parts.forEach((part, index) => part.order = index + 1);
    this.toggleNewPart();
  }

  public cancelNewPart(): void {
    this.generateNewPart();
  }

  public editPart(part: FormulationPart, partIndex: string): void {
    this.editPartFlag = this.generateEditPartFlag(partIndex);
    this.toggleEditPartMode();
    this.partCopyForEdit = JSON.parse(JSON.stringify(part));
  }

  public confirmEditPart(): void {
    this.partCopyForEdit = null;
    this.toggleEditPartMode();
    this.editPartFlag = this.generateEditPartFlag('');
  }

  public cancelEditPart(part: FormulationPart): void {
    part = JSON.parse(JSON.stringify(this.partCopyForEdit));
    this.updatePartsIndex(this.findFormulation().parts);
    this.partCopyForEdit = null;
    this.toggleEditPartMode();
    this.editPartFlag = this.generateEditPartFlag('');
  }

  public removePart(part: FormulationPart): void {
    const index: number = this.findFormulation().parts.indexOf(part);
    if (index !== -1) {
      this.findFormulation().parts.splice(index, 1);
      this.updatePartsIndex(this.findFormulation().parts);
    }
  }

  /* Steps logic */
  private generateNewStep() {
    this.newFormulationStep = new FormulationStep();
    this.establishCurrentUnit();
    this.newFormulationStep.unit = this.currentUnit;
  }

  public newStep(partIndex: string): void {
    this.generateNewStep();
    this.newStepFlag = this.generateNewStepFlag(partIndex);
  }

  public editStep(step: FormulationStep, partIndex: string, stepIndex: string): void {
    this.editStepFlag = this.generateEditStepFlag(partIndex, stepIndex);
    this.toggleEditMode();
    this.stepCopyForEdit = JSON.parse(JSON.stringify(step));
  }

  public confirmNewStep(part: FormulationPart) {
    if (this.formulationStepformValidationService.validate(this.newFormulationStep)) {
      this.establishCurrentUnit();
      part.steps.push(this.newFormulationStep);
      this.updateStepsIndex(part.steps);
      this.newStepFlag = this.generateNewStepFlag('');
    } else {
      this.formulationStepformValidationService.renderErrors();
    }
  }

  public cancelNewStep() {
    this.generateNewStep();
    this.newStepFlag = this.generateNewStepFlag('');
  }

  public confirmEditStep(part: FormulationPart, step: FormulationStep): void {
    if (this.formulationStepformValidationService.validate(step)) {
      this.stepCopyForEdit = null;
      this.toggleEditMode();
      this.editStepFlag = this.generateEditStepFlag('', '');
      this.currentUnit = step.unit;
    } else {
      this.formulationStepformValidationService.renderErrors();
    }
  }

  public cancelEditStep(part: FormulationPart, step: FormulationStep): void {
    const index: number = part.steps.indexOf(step);
    if (index !== -1) {
        part.steps.splice(index, 1, JSON.parse(JSON.stringify(this.stepCopyForEdit)));
        this.updateStepsIndex(part.steps);
    }
    this.stepCopyForEdit = null;
    this.toggleEditMode();
    this.editStepFlag = this.generateEditStepFlag('', '');
  }

  public removeStep(part: FormulationPart, step: FormulationStep): void {
    const index: number = part.steps.indexOf(step);
    if (index !== -1) {
        part.steps.splice(index, 1);
        this.updateStepsIndex(part.steps);
    }
    this.establishCurrentUnit();
  }

  public thereIsOnlyOneStep(): boolean {
    if (this.stepsNumber() === 1) {
      return true;
    } else {
      return false;
    }
  }

  private stepsNumber(): number {
    let numSteps = 0;
    if (this.findFormulation() !== undefined && this.findFormulation().parts.length > 0 ) {
      for(const part of this.findFormulation().parts) {
        for(const step of part.steps) {
          numSteps++;
        }
      }
      if(this.newStepFlag !== '') {
        numSteps++;
      }
    }

    return numSteps;
  }

  /* Update indexes logic */
  public updatePartsIndex(parts: FormulationPart[]): void {
    parts.forEach((part, index) => part.order = index + 1);
  }

  public updateStepsIndex(steps: FormulationStep[]): void {
    steps.forEach((step, index) => step.order = index + 1);
  }

  /* Logic when dropping from drag and drop */
  drop(event: CdkDragDrop<FormulationStep[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.updateStepsIndex(event.container.data);
  }

  /* Part deletion confirmation */
  public openPartDeletionDialog(part: FormulationPart): void {
    this.confirmService.confirm('title.formulation.part.deletion', 'text.formulation.part.deletion', 'button.yes', 'button.no').then(deletePart => {
      if (deletePart) {
        this.removePart(part);
      }
    });
  }

  /* Field Validator to reset fields in case they do not contain objects */
  public validateFunctionField(step: FormulationStep) {
    if (typeof step.function === 'string') {
      step.function = null;
    }
  }

  public getPartName(part: FormulationPart): string {
      return this.localizedStringService.getLocalizedStringsValue(!!part ? part.name : null);
  }
}
