import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateViewList } from '../../../../../../model/custom-view/template-view-list/template-view-list';

@Component({
  selector: 'app-template-view-list-value-template',
  templateUrl: './template-view-list-value-template.component.html',
  styleUrl: './template-view-list-value-template.component.less'
})
export class TemplateViewListValueTemplateComponent {

  @Input()
  public templateViewList: TemplateViewList;

  @Output()
  public deleteTemplateViewList: EventEmitter<TemplateViewList> = new EventEmitter<TemplateViewList>();

  public showParametername: boolean = false;

  changeParametername() {
    this.showParametername = !this.showParametername;
    if(this.templateViewList.parametername === '') {
      this.templateViewList.parametername = null;
    }
  }
}
