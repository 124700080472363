import { Injectable } from '@angular/core';
import { Document } from '../../model/document/document';
import { DocumentVersion } from '../../model/document-version/document-version';
import { DocumentVersionStatus } from '../../model/document-version/document-version-status';
import { FormValidationService } from '../form-validation.service';
import { NotificationService } from '../notification.service';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DocumentVersionInputSettings } from '../../model/document-version/document-version-input-settings';

const moment = extendMoment(Moment);

interface ValidationObjectType {
  document: Document;
  documentVersion: DocumentVersion;
  fileToUpload: File;
  disabledFields: DocumentVersionInputSettings;
}

@Injectable()
export class DocumentVersionFormValidationService extends FormValidationService {

  constructor(
    protected notificationService: NotificationService
  ) {
    super(notificationService);

    this.addValidation(this.verifyLanguageIsSelected);
    this.addValidation(this.verifyPublishDateNotEmpty);
    this.addValidation(this.verifyExpirationDateNotEmpty);
    this.addValidation(this.verifyPublishDateNotInThePast);
    this.addValidation(this.verifyExpirationDateNotInThePast);
    this.addValidation(this.isPublishBeforeExpirationDate);
    this.addValidation(this.checkOverlappingDateRanges);
  }

  // Check if at least one language is selected
  private verifyLanguageIsSelected(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    if (!obj.disabledFields.disableLanguage) {
      if (obj.documentVersion.languages.length === 0) {
        validation.addError('label.document.language_is_required');
        return false;
      }
    }
    return true;
  }

  private verifyPublishDateNotInThePast(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    let isValid = true;

    if (!obj.disabledFields.disablePublishDate) {

      const currentDate: Date = new Date(moment(new Date()).format('YYYY-MM-DD'));

      if (obj.documentVersion.validFrom < currentDate) {
        validation.addError('label.document.publish_date_is_in_the_past');
        isValid = false;
      }
    }

    return isValid;
  }

  private verifyPublishDateNotEmpty(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    let isValid = true;

    if (!obj.documentVersion.validFrom) {
      validation.addError('label.document.publish_date_is_required');
      isValid = false;
    } else {
      obj.documentVersion.validFrom = new Date(moment(obj.documentVersion.validFrom).format('YYYY-MM-DD'));
    }

    return isValid;
  }

  private verifyExpirationDateNotEmpty(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    let isValid = true;

    if (!obj.documentVersion.validUntil) {
      validation.addError('label.document.expiration_date_is_required');
      isValid = false;
    } else {
      obj.documentVersion.validUntil = new Date(moment(obj.documentVersion.validUntil).format('YYYY-MM-DD'));
    }

    return isValid;
  }

  private verifyExpirationDateNotInThePast(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    let isValid = true;

    const currentDate: Date = new Date(moment(new Date()).format('YYYY-MM-DD'));

    if (obj.documentVersion.validUntil < currentDate) {
      validation.addError('label.document.expiration_date_is_in_the_past');
      isValid = false;
    }

    return isValid;
  }

  private isPublishBeforeExpirationDate(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    let isValid = true;

    if (obj.documentVersion.validUntil && obj.documentVersion.validFrom) {
      if (obj.documentVersion.validUntil < obj.documentVersion.validFrom) {
        validation.addError('label.document.expiration_date_before_publish_date');
        isValid = false;
      }
    }

    return isValid;
  }

  public areVersionsEqual(firstDocVersion: DocumentVersion, secondDocVersion: DocumentVersion): boolean {
    // Saved versions (in db) have id
    if (!!firstDocVersion.id){
      return firstDocVersion.id === secondDocVersion.id;
    }
    else {
      // Newer versions have undefined id (not in db). Some checks happen before FormValidation, so maybe the file was not set
      return !secondDocVersion.id && secondDocVersion.file?.id === firstDocVersion.file?.id;
    }
  }

  /* checks that Dateranges are not overlapping */
  private checkOverlappingDateRanges(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {
    // Not Published versions should not be considered in overlaping dates
    if (obj.documentVersion.status == DocumentVersionStatus.NOT_PUBLISHED) {
      return true;
    }

    const today = moment().startOf('day');
    // Not Published versions should not be considered in overlaping dates
    const publishedNotExpiredVersions = obj.document.versions.filter(dv => dv.status == DocumentVersionStatus.PUBLISHED && moment(dv.validUntil).isAfter(today, 'day'));

    let isValid = true;
    if (obj.documentVersion.validUntil && obj.documentVersion.validFrom && publishedNotExpiredVersions.length > 0) {
      const currentRange = moment.range(obj.documentVersion.validFrom, obj.documentVersion.validUntil);

      for (const language of obj.documentVersion.languages) {
        for (const version of publishedNotExpiredVersions) {
          if (!validation.areVersionsEqual(obj.documentVersion, version)) {

            const item = version.languages.find(i => i.isoCode === language.isoCode);

            if (item) {
              const versionRange = moment.range(version.validFrom, version.validUntil);

              if (versionRange.overlaps(currentRange, { adjacent: false })) {
                validation.addError('label.document.validity_range_overlaps_other_version');
                isValid = false;
              }
            }
          }
        }
      }
    }

    return isValid;
  }

  private verifyUploadFileSelected(validation: DocumentVersionFormValidationService, obj: ValidationObjectType): boolean {

    if (!obj.disabledFields.disableFile) {
      if (!obj.fileToUpload && !obj.documentVersion.file) {
        validation.addError('label.document.file_is_required');
        return false;
      }
    }
    return true;
  }

  public validate(obj: ValidationObjectType, verifyFile: boolean = true): boolean {

    let valid = true;

    if (!super.validate(obj)) {
      valid = false;
    }

    if (verifyFile && !this.verifyUploadFileSelected(this, obj)) {
      valid = false;
    }

    return valid;
  }
}
