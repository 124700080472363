import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Guid } from 'guid-typescript';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Attribute } from '../../../../model/attribute';
import { AttributeValue } from '../../../../model/attribute-value';
import { Material } from '../../../../model/material';
import { Pageable } from '../../../../model/pageable';
import { AttributeValueService } from '../../../../service/attribute-value.service';
import { MaterialTypeaheadService } from '../../../../service/typeahead/material-typeahead.service';
import * as _ from 'lodash';

@Component({
  selector: 'relationship-attribute-edit',
  templateUrl: './relationship-attribute-edit.component.html'
})
export class RelationshipAttributeEditComponent implements OnInit {
  get attributeValues(): AttributeValue[] {
    return this._attributeValues;
  }

  @Input()
  set attributeValues(value: AttributeValue[]) {
    if(!_.isEqual(this._attributeValues, value) && value.length > 0) {
      this._attributeValues = value;
      this.filteredAttributeValues = [];
      if (!!this.attribute) {
        this.findAttributeValues();
      }
    }
  }

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  private _attributeValues: AttributeValue[];

  @Input()
  public readonly: boolean = false;

  @Output()
  public activated = new EventEmitter<AttributeValue[]>();

  @Output()
  public updateAttributeValueChange = new EventEmitter<AttributeValue[]>();

  public filteredAttributeValues: AttributeValue[] = [];

  public trashIcon = faTrash;

  constructor(
    private attributeValueService: AttributeValueService,
    private materialTypeaheadService: MaterialTypeaheadService
  ) {}

  ngOnInit(): void {
    this.filteredAttributeValues = [];
    this.findAttributeValues();
  }

  public materialTypeahead = (searchTerm: string, pageable: Pageable) => this.materialTypeaheadService.typeaheadSearch(searchTerm, pageable, []);
  public materialTypeaheadFormatter = (model: Material) => this.materialTypeaheadService.typeaheadFormatter(model);

  public findAttributeValues() {
    if(!this._attributeValues || this._attributeValues.length < 1){
      this.filteredAttributeValues.push(this.createAttributeValue());
    }
    if (this._attributeValues && this._attributeValues.length > 0) {
      this.filteredAttributeValues.push(...this._attributeValues);
    }
  }

  public getMaterialForTypeahead(attributeValue: AttributeValue): Material {
    if (attributeValue && attributeValue.relationship) {
      return attributeValue.relationship.material;
    }
    return null;
  }

  public setMaterialFromTypeahead = (selectedMaterial: Material, attributeValue: AttributeValue): void => {
    attributeValue.relationship.material = selectedMaterial;
    this.updateAttributeValueChange.emit(this.filteredAttributeValues.filter(av => !!av.relationship.material));
  }

  public addAttributeValue(): void {
    this.filteredAttributeValues.push(this.createAttributeValue());
    this.updateAttributeValueChange.emit(this.filteredAttributeValues.filter(av => !!av.relationship.material));
  }

  public removeAttributeValue(attributeValueRemove: AttributeValue): void {
    this.filteredAttributeValues = this.filteredAttributeValues.filter(attributeValueOrig => attributeValueRemove != attributeValueOrig);
      this.updateAttributeValueChange.emit(this.filteredAttributeValues.filter(av => !!av.relationship.material));
  }

  public enumerationChanged(newValue: string, attributeValue: AttributeValue): void {
    attributeValue.relationship.type = newValue;
    this.updateAttributeValueChange.emit(this.filteredAttributeValues.filter(av => !!av.relationship.material));
  }

  public getSelectedValue(attributeValue: AttributeValue): string {
    if (attributeValue && attributeValue.relationship) {
      return attributeValue.relationship.type;
    }
    return '';
  }

  private createAttributeValue(): AttributeValue {
    const attributeValue: AttributeValue = this.attributeValueService.createNewAttributeValue(this.attribute);
    return attributeValue;
  }
}
