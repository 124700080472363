import { Injectable } from '@angular/core';
import { LocalizedString } from '../model/localized-string';
import { LocalizedStringWeb } from '../model/localized-string-web';
import { omit, orderBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStringsFilterPipe } from '../pipes/localized-strings-filter.pipe';
import { UserService } from './user.service';

@Injectable()
export class LocalizedStringService {

  constructor(private readonly translate: TranslateService, private readonly userService: UserService) {}

  public getLocalizedStringsValue(localizedStrings: LocalizedString[], showNameNotFound: boolean = false): string {
    const localizedStringsFilterPipe = new LocalizedStringsFilterPipe(this.translate, this.userService);
    return localizedStringsFilterPipe.transform(localizedStrings, showNameNotFound);
  }

  public getLocalizedStringsValueWithFallback(localizedStrings: LocalizedString[], showNameNotFound: boolean = false): string {
    const localizedStringsFilterPipe = new LocalizedStringsFilterPipe(this.translate, this.userService);
    return localizedStringsFilterPipe.transformEnglishOrFirst(localizedStrings, showNameNotFound);
  }

  public getOrderedLocalizedStringsWithoutLanguageName(localizedStrings: LocalizedString[]): any[] {
    return orderBy(localizedStrings.map(localizedString => omit(
      localizedString, ['language.name'])).filter(item => item.id != null || (item.id == null && item.value !== '')), ['value', 'id']
    );
  }

  public getEnglishValue(localizedStrings: LocalizedStringWeb[]): string {
    return localizedStrings.find((value: LocalizedStringWeb) => value.lang == 'en')?.value;
  }

  public getLocalizedStringEnglishValue(localizedStrings: LocalizedString[]): string {
    return localizedStrings.find((value: LocalizedString) => value.language.isoCode == 'en')?.value;
  }

}
