import { Injectable } from '@angular/core';
import { FormulationStep } from '../model/formulation-step';
import { LocalizedStringService } from './localized-string.service';
import { orderBy } from 'lodash';

@Injectable()
export class FormulationStepService {
    public currentFormulationStep: FormulationStep;

    constructor(
      private localizedStringService: LocalizedStringService
    ){}

    public getOrderedFormulationSteps(formulationSteps: FormulationStep[]): any[] {
      return orderBy(formulationSteps.map(formulationStep => {
        const instructions = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(formulationStep.instruction);
        const formulationStepUnit = formulationStep.unit ? formulationStep.unit.id : null;
        const formulationStepFunction = formulationStep.function ? formulationStep.function.id : null;
        const formulationStepMaterial = formulationStep.material ? formulationStep.material.id : null;
        return {
          quantity: formulationStep.quantity,
          unit: formulationStepUnit,
          order: formulationStep.order,
          instruction: instructions,
          function: formulationStepFunction,
          material: formulationStepMaterial
        };
      }), ['order']);
    }
}
