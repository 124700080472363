import { ConnectionService } from '../../service/connection.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.less']
})
export class LoadingIndicatorComponent {

    constructor(private loadingIndicatorService: ConnectionService) { }

    public display(): boolean {
      return this.loadingIndicatorService.displayLoadingIndicator();
    }

}
