<div class="container-fluid pb-3">
  <h2 class="display-6 fw-normal">{{ 'dashboard.headline' | translate }}</h2>
</div>
<div *ngIf="getDashboard()" class="container-fluid pb-3">
  <div class="d-grid gap-4" style="grid-template-columns: 5fr 2fr;">

    <div>

      <div *ngFor="let news of getDashboard().news" class="bg-light border p-3 rounded-3 mb-3">
        <div class="mb-2">
          <h5>{{ news.headline }}</h5>
          <small class="opacity-50 text-nowrap">{{ news.publishDate }}</small>
        </div>
        <div [innerHTML]="news.content"></div>
      </div>

    </div>
    <div>

      <div *ngIf="!!getDashboard().feature && getDashboard().feature.length > 0" class="list-group w-auto mb-3">
        <a class="list-group-item d-flex gap-3 py-3 active disabled">
          <h6 class="mb-0">{{ 'dashboard.headline.features' | translate }}</h6>
        </a>
        <a *ngFor="let feature of getDashboard().feature" href="{{ feature.link }}" target="_blank" class="list-group-item list-group-item-action d-flex gap-3 py-3">
          <h6 class="mb-0">{{ feature.label }}</h6>
        </a>
      </div>

      <div *ngIf="!!getDashboard().howto && getDashboard().howto.length > 0" class="list-group w-auto mb-3">
        <a class="list-group-item d-flex gap-3 py-3 active disabled">
          <h6 class="mb-0">{{ 'dashboard.headline.howtos' | translate }}</h6>
        </a>
        <a *ngFor="let howto of getDashboard().howto" href="{{ howto.link }}" target="_blank" class="list-group-item list-group-item-action d-flex gap-3 py-3">
          <h6 class="mb-0">{{ howto.label }}</h6>
        </a>
      </div>

      <div class="d-grid gap-3" style="grid-template-columns: 1fr 1fr;">
        <div class="list-group w-auto ">
          <a href="https://confluence.basf.net/display/PIM/Glossary" target="_blank" class="list-group-item list-group-item-action p-0 glossary">
            <div class="text-center">
              <div class="m-4">
                <fa-icon [icon]="['fas', 'book']" size="6x"></fa-icon>
              </div>
              <h6 class="mb-0 p-3 pt-0">{{ 'dashboard.label.glossary' | translate}}</h6>
            </div>
          </a>
        </div>
        <div class="list-group w-auto">
          <a href="mailto:PIM-DevOps-Team@basf.com" class="list-group-item list-group-item-action p-0 contact">
            <div class="text-center">
              <div class="m-4">
                <fa-icon [icon]="['fas', 'envelope']" size="6x"></fa-icon>
              </div>
              <h6 class="mb-0 p-3 pt-0">{{ 'dashboard.label.technical_issues' | translate}}</h6>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>