<div>
  <label *ngIf="!!label" class="localized-string-edit-label">
    {{label}}
  </label>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
    <li *ngFor="let item of findUsedLanguages()" [ngbNavItem]="item.id">
      <a ngbNavLink>{{ item.name | localizedStringsFilter }}</a>
      <ng-template ngbNavContent>
        <div *ngFor="let localizedString of getLocalizedStringListByLanguage(item);" class="d-flex mb-2">
          <input class="form-control" type="text" [id]="'stringlist_' + localizedString.id" [(ngModel)]="localizedString.value" (ngModelChange)="changeLocalizedStrings()" [disabled]="readonly">
          <icon-button *ngIf="!isDefault(item) && !readonly" buttonType="delete" (buttonClick)="removeLocalizedString(localizedString)" buttonHideLabel="true"></icon-button>
        </div>
        <icon-button *ngIf="!readonly" buttonType="add" (buttonClick)="addLocalizedString()"></icon-button>
      </ng-template>
    </li>
    <li *ngIf="findAvaliableLanguages().length && !readonly" class="nav-item">
      <form [formGroup]="addLanguageForm">
        <select formControlName="languageControl" class="form-select" (change)="addTranslation()" selectFirstOption>   
          <option style="display: none" [ngValue]="emptyLanguage">{{ 'button.add.translation' | translate }}</option>
          <option class="select-option" *ngFor="let item of findAvaliableLanguages()" [ngValue]="item">{{ item.name | localizedStringsFilter }}</option>
        </select>
      </form>
    </li>
    <li *ngIf="findRemovableLanguages().length && !readonly" ngbDropdown class="nav-item">
      <button (click)="false" class="btn btn-primary" ngbDropdownToggle>{{ 'button.remove.translation' | translate }}</button>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let item of findRemovableLanguages()">
          <button *ngIf="!readonly" ngbDropdownItem (click)="removeTranslation(item)">{{ item.name | localizedStringsFilter }}</button>
        </ng-container>
      </div>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>