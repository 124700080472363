import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Document } from '../model/document/document';
import { BackendValidationService } from './form-validation/backend-validation.service';
import { DocumentStatus } from '../model/document/document-status';
import { DocumentVersionFile } from '../model/document-version/document-version-file';

@Injectable({
  providedIn: 'root'
})
export class DocumentBackendService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly backendValidationService: BackendValidationService) { }

  public load(id: string): Observable<Document> {
    return this.httpClient.get<Document>(`${environment.restUrl}/document/${id}`)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public save(document: Document): Observable<Document> {
    return this.httpClient.post<Document>(environment.restUrl + '/document/save', document)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public deleteVersionById(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.restUrl}/document/version/${id}`);
  }

  public uploadFile(document: File): Observable<DocumentVersionFile> {
    const formData: FormData = new FormData();
    formData.append('file', document);
    return this.httpClient.post(environment.restUrl + '/document/upload', formData, { headers: {} }).pipe(map((resp: any) => resp));
  }

  public getTemplateViewMaterial(documentId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.restUrl}/documentbridge/templatematerial/searchByDocument/${documentId}`).pipe(
      catchError((response: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
        this.backendValidationService.renderErrorMessages()
        return of();
      })
    );
  }

  public isDocBridgeDocument(documentId: string): Observable<boolean> {
    return this.httpClient.get<HttpResponse<boolean>>(`${environment.restUrl}/documentbridge/templatematerial/document/${documentId}`)
        .pipe(
          catchError((response: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
            this.backendValidationService.renderErrorMessages()
            return of();
          })
        );
  }

  public getDocumentStatus(id: string): Observable<DocumentStatus> {
    return this.httpClient.get<DocumentStatus>(`${environment.restUrl}/document/status/${id}`)
      .pipe(
        this.backendValidationService.renderErrorMessages()
    );
  }
}
