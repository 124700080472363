import { Component } from "@angular/core";
import { environment } from '../../../../../environments/environment';
import { Attribute } from '../../../../model/attribute';
import { CategoryMaterial } from '../../../../model/category-material';

@Component({
  selector: 'malabel.login.userterial-tab-spass.component',
  templateUrl: './material-tab-spass.component.html'
})
export class MaterialTabSpassComponent {

  public filterSpassCategorieMaterials = (categoryMaterial: CategoryMaterial): boolean => categoryMaterial.category.id === environment.spassCategoryId;

  public preFilterAttributes = (categoryMaterials: CategoryMaterial[]): Attribute[] => {
    return categoryMaterials.at(0).attributeValues.filter(av => av.active).map(av => av.attribute);
  }

}