<div>

  <h2 *ngIf="!(customViewSubject | async)?.id; else maintainHeadline">{{ 'headline.administration.addView' | translate }}</h2>

  <ng-template #maintainHeadline>
    <h2 class="display-6 fw-normal">{{ 'headline.administration.maintainView' | translate }} - {{ getEnglishName(customViewSubject | async) }}</h2>
  </ng-template>
  <ul #nav="ngbNav" [(activeId)]="selectedTab" class="nav-tabs" ngbNav>
    <li [ngbNavItem]="'maintenance'" [routerLink]="'maintenance'" id="maintenance">
      <a ngbNavLink>{{ 'label.administration.customview.tab.maintenance' | translate }}</a>
    </li>
    <li [ngbNavItem]="'assignments'" [routerLink]="'assignments'" id="assignments">
      <a ngbNavLink>{{ 'label.administration.customview.tab.assignments' | translate }}</a>
    </li>
    <li *ngIf="isCustomViewTypeDynamicTemplateView()" [ngbNavItem]="'dynamicTemplate'" [routerLink]="'dynamicTemplate'" id="dynamicTemplate">
      <a ngbNavLink>{{ 'label.administration.customview.tab.template' | translate }}
        <div *ngIf="!( dynamicTemplateDocumentContentSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </a>
    </li>
    <li *ngIf="isCustomViewTypeTemplateView()" [ngbNavItem]="'template'" [routerLink]="'template'" id="template">
      <a ngbNavLink>{{ 'label.administration.customview.tab.template' | translate }}</a>
    </li>
    <li *ngIf="isCustomViewTypeTemplateView() || isCustomViewTypeDynamicTemplateView()"
        [ngbNavItem]="'documentDefaults'" [routerLink]="'documentDefaults'" id="documentDefaults">
      <a ngbNavLink>{{ 'label.administration.customview.tab.document.defaults' | translate }}</a>
    </li>
    <li *ngIf="isCustomViewTypeTemplateView()" [ngbNavItem]="'templateViewAttributes'" [routerLink]="'templateViewAttributes'" id="templateViewAttributes">
      <a ngbNavLink>{{ 'label.administration.customview.tab.template-view-attributes' | translate }}</a>
    </li>
    <li *ngIf="isCustomViewTypeTemplateView()" [ngbNavItem]="'selectionMapping'" [routerLink]="'selectionMapping'" id="selectionMapping">
      <a ngbNavLink>{{ 'label.administration.customview.tab.selection.mapping' | translate }}</a>
    </li>
    <li *ngIf="isCustomViewTypeTemplateView()" [ngbNavItem]="'templateViewLists'" [routerLink]="'templateViewLists'" id="templateViewLists">
      <a ngbNavLink>{{ 'label.administration.customview.tab.template-view-lists' | translate }}
        <div *ngIf="!( templateViewListsSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </a>
    </li>
  </ul>

  <div class="border-router-outlet">
    <router-outlet *ngIf="!!(customViewSubject | async)"></router-outlet>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-12 align-right">
      <icon-button (buttonClick)="cancel()" buttonType="cancel"></icon-button>
      <icon-button (buttonClick)="delete()" [buttonDisabled]="!(canBeDeleted | async) || isCustomViewTypeDynamicTemplateView()"
                   buttonType="delete"></icon-button>
      <icon-button (buttonClick)="save()" *ngIf="openConnections.length < 1 && !!(customViewSubject | async)?.id" buttonType="save"></icon-button>
      <icon-button (buttonClick)="save()" *ngIf="openConnections.length < 1 && !!(customViewSubject | async) && !(customViewSubject | async)?.id"
                   buttonType="add"></icon-button>
    </div>
  </div>
</div>

