import { AfterContentChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Document } from '../../../../model/document/document';
import { DocumentStatus } from '../../../../model/document/document-status';
import { RenderingOption } from '../../../../model/document-creation/rendering-option';
import { DocumentCreationDataService } from '../../../../service/data-service/document-creation-data.service';
import { DocumentEditDataService } from '../../../../service/data-service/document-edit-data.service';
import { ConfirmService } from '../../../../service/confirm.service';
import { DocumentVersionDataService } from '../../../../service/data-service/document-version-data.service';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import InjectIsNotAdvancedUser from '../../../../decorator/inject-is-not-advanced-user.decorator';
import { HasUnsavedChangesWithStateGuard } from '../../../../guards/has-unsaved-changes-with-state.guard';



@Component({
  selector: 'document-creation-administration-edit',
  templateUrl: './document-creation-administration-edit.component.html',
  styleUrls: ['./document-creation-administration-edit.component.less']
})
export class DocumentCreationAdministrationEditComponent implements OnDestroy, OnInit, AfterContentChecked, HasUnsavedChangesWithStateGuard {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  private readonly DOCUMENT_CREATION_BASE_URL = '/adminstration/document-creation';
  private readonly DOCUMENT_CREATION_SELECT_URL = this.DOCUMENT_CREATION_BASE_URL + '/select';

  public params: Params;
  public selectedTab: string = 'properties';
  private paramsSubscription: Subscription = new Subscription();

  public get templateMaterialComposition() {
    return this.documentCreationDataService.templateMaterialBehaviorSubject;
  }

  public get documentVersionToBeEdited() {
    return this.documentVersionDataService.documentVersionBehaviorSubject;
  }

  public get documentBehaviorSubject(): BehaviorSubject<Document> {
    return this.documentEditDataService.documentBehaviorSubject;
  }

  public get renderingOptionsBehaviorSubject(): BehaviorSubject<RenderingOption[]> {
    return this.documentCreationDataService.renderingOptionsBehaviorSubject;
  }

  public get catMatsBehaviorSubject() {
    return this.materialEditDataService.categoryMaterialsBehaviourSubject;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly documentCreationDataService: DocumentCreationDataService,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly materialEditDataService: MaterialEditDataService,
    private readonly confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  ngAfterContentChecked(): void{
    this.paramsSubscription.add(this.route.firstChild?.url.subscribe((url) => {
      if (url[url.length - 1].path !== this.selectedTab){
        this.selectedTab = url[url.length - 1].path;
      }
    }));
  }

  ngOnDestroy(): void {
    this.documentCreationDataService.cleanUp();
    this.paramsSubscription.unsubscribe();
  }

  hasUnsavedChangesWithState(
    currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {

    if (!!nextState && nextState.url.startsWith(this.DOCUMENT_CREATION_BASE_URL) && !nextState.url.startsWith(this.DOCUMENT_CREATION_SELECT_URL)) {
      return true;
    }

    if (this.hasUnsavedChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public getStatusClass(document: Document): string{
    if (document.status === DocumentStatus.PUBLISHED && !this.documentEditDataService.hasPublishedVersions()){
      return 'expired';
    }
    return document.status?.toLocaleLowerCase();
  }

  public getDocumentTag(document: Document): string {
    if (document.status === DocumentStatus.PUBLISHED && !this.documentEditDataService.hasPublishedVersions()){
      return 'document.expired';
    }
    return 'document.' + document.status;
  }

  private hasUnsavedChanges() {
    return this.documentCreationDataService.templateMaterialChanged();
  }

  public isNotDocumentLoaded = (): boolean => !this.documentCreationDataService.documentLoadedBehaviorSubject.getValue();

  public initialize(): void {
    this.paramsSubscription.add(this.route.queryParams.subscribe((params: Params) => {
      if(!params.material || !params.templateView) {
        this.documentCreationDataService.navigateToOverviewUrlWithError();
        return;
      }
      return this.checkForUnchangedChangesAndNavigateToNewTemplateViewMaterial(params);
    }));
  }

  private checkForUnchangedChangesAndNavigateToNewTemplateViewMaterial(params: Params) {
    if (params.material !== this.params?.material || params.templateView !== this.params?.templateView) {
      if (!!this.documentCreationDataService.templateMaterialBehaviorSubject.getValue() && !this.documentCreationDataService.isNewTemplateMaterial()) {
        return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no').then((result) => {
          if (result) {
            this.navigateToNewTemplateViewMaterial(params);
          } else {
            this.changingQueryParamsBack(this.params);
          }
        });
      } else {
        this.navigateToNewTemplateViewMaterial(params);
      }
    } else {
      this.params = params;
    }
  }

  private navigateToNewTemplateViewMaterial = (params: Params) => {
    this.params = params;
    this.documentCreationDataService.init(params.material, params.templateView);
  }

  public changingQueryParamsBack = (queryParams: Params) => {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams
      }).then(() => null);
  }

  public cancel(): void {
    if (this.hasUnsavedChanges()) {
      this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no').then((result) => {
        if (result) {
          this.documentCreationDataService.navigateToOverviewUrlWithError();
        } else {return;}
      });
    } else {
      this.documentCreationDataService.navigateToOverviewUrlWithError();
    }
  }

  public save(): void {
    this.documentCreationDataService.saveTemplateMaterial().subscribe(() => {
      return;
    });
  }

  public isDocumentPublished(document: Document): boolean{
    return document.status === DocumentStatus.PUBLISHED;
  }
}
