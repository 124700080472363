import { Component, Input, TemplateRef } from '@angular/core';
import { AttributeValue } from '../../../model/attribute-value';
import { Attribute } from '../../../model/attribute';
import { CategoryMaterial } from '../../../model/category-material';
import { CategoryFormulation } from '../../../model/category-formulation';
import { Category } from '../../../model/category';
import { CategoryProductHierarchyObject } from '../../../model/category-product-hierarchy-object';
import { LocalizedStringService } from '../../../service/localized-string.service';
import { AttributeValueEditDataService } from '../../../service/data-service/attribute-value-edit-data.service';
import { IsAttributeMaintainedService } from '../../../service/is-attribute-maintained.service';
import { CategoryAttributeAttributeValue } from '../../../model/category-attribute-attribute-value';

@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.less']
})
export class AttributeComponent {

  public infoButtonClicked: boolean = false;
  public isAttributeValueContainerActive = false;
  private _attributeValues: AttributeValue[];
  private _attributeValue: AttributeValue;
  public visibilityManuallyMaintained = false;

  @Input()
  public attribute: Attribute;

  @Input()
  public attributeValueRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue;

  @Input()
  public attributeValueTemplate: TemplateRef<any>;

  @Input()
  public leafCategoryWithPotentialParents: Category;

  @Input()
  public areAttributeValuesMandatory = false;

  @Input()
  public showInfoButton: boolean = false;

  @Input()
  public readonly: boolean = false;

  @Input()
  public readonlyVisibility: boolean = false;

  @Input()
  public showVisibility: boolean = true;

  @Input()
  public isDisabled = (categoryRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, attribute: Attribute): boolean => false;

  @Input()
  public set attributeValue(attributeValue: AttributeValue) {
    this._attributeValue = attributeValue;
    this.calculateSingleAttributeValueVisibility(attributeValue);
  }
  public get attributeValue(): AttributeValue {
    return this._attributeValue;
  }

  @Input()
  public set attributeValues(attributeValues: AttributeValue[]) {
    this._attributeValues = attributeValues;
    this.calculateVisibility(attributeValues);
  }
  public get attributeValues(): AttributeValue[] {
    return this._attributeValues;
  }

  @Input()
  public set visibility(visibility: Category) {
    this.setVisibility(visibility);
  }
  public get visibility() {
    return this.getVisibility();
  }

  constructor(private readonly localizedStringService: LocalizedStringService,
    private readonly attributeValueEditDataService: AttributeValueEditDataService,
    private readonly isAttributeMaintainedService: IsAttributeMaintainedService) {}

  private setVisibility(visibility: Category) {
    if (!!this.attributeValue) {
      this.attributeValue.visibility = visibility;
    }
    if (!!this.attributeValues && this.attributeValues.length > 0) {
      this.attributeValues.forEach((attributeValue: AttributeValue) => {
        attributeValue.visibility = visibility;
      });
    }
    this.visibilityManuallyMaintained = true;
  }

  private getVisibility(): Category {
    const allAttributeValues: AttributeValue [] = [];
    if(!!this.attributeValue) {
      allAttributeValues.push(this.attributeValue);
      if(!!this.attributeValue.visibility) {
        return this.attributeValue.visibility;
      }
    }

    if(!!this.attributeValues && this.attributeValues.length > 0) {
      allAttributeValues.push(...this.attributeValues);
      if(this.attributeValues.some(av => !!av.visibility)) {
        return this.attributeValues.find(av => !!av.visibility).visibility;
      }
    }

    if (this.visibilityManuallyMaintained) {
      return null;
    }

    if(!!this.attribute.defaultVisibility) {
      allAttributeValues.forEach(av => {
        this.attributeValueEditDataService.isAttributeValueVisibilityCalculatedMap.set(av, true)
      })
      return this.attribute.defaultVisibility;
    }

    return null;
  }

  public isVisibilityCalculated(): boolean {
    if (this.visibilityManuallyMaintained) {
      return false;
    }
    const allAttributeValues: AttributeValue [] = [];
    if(!!this.attributeValue) {
      allAttributeValues.push(this.attributeValue);
    }
    if(!!this.attributeValues && this.attributeValues.length > 0) {
      allAttributeValues.push(...this.attributeValues);
    }
    return this.attributeValueEditDataService.isAttributeValueVisibilityCalculated(allAttributeValues);
  }

  public visibilityChange(event: Category) {
    this.setVisibility(event);
  }

  private calculateVisibility(attributeValues: AttributeValue[]) {
    if (!!attributeValues && attributeValues.length > 0) {
      if (attributeValues.some(av => !av.visibility)) {
        attributeValues.forEach(av => {
          av.visibility = this.getVisibility()
          this.attributeValueEditDataService.setAttributeValueVisibilityCalculated(av, attributeValues);
        });
      }
    }
  }

  private calculateSingleAttributeValueVisibility(attributeValue: AttributeValue) {
    if (!!attributeValue) {
      if (!attributeValue.visibility) {
        attributeValue.visibility = this.getVisibility();
      }
    }
  }

  public isActive = () => {
    if(!!this.attributeValue) {
      return this.attributeValue.active;
    }
    else if(!!this.attributeValues && this.attributeValues.length > 0 ) {
      const av = this.attributeValues.find((attributeValue: AttributeValue) => attributeValue.active);
      return av && av.active;
    } else {
      return this.isAttributeValueContainerActive;
    }
  }

  public inactivateAttributeValue = () => {
    this.isAttributeValueContainerActive = false;
    if(this.attributeValue) {
      this.attributeValue.active = false;
    }
    else if(!!this.attributeValues && this.attributeValues.length > 0 ) {
      this.attributeValues.forEach(attributeValue => {
        attributeValue.active = false
      });
    }
  }

  public setInfoButtonClicked = (infoActive: boolean) => {
    this.infoButtonClicked = infoActive;
  }

  public activate() {
    this.isAttributeValueContainerActive = true;
    if (this.attributeValue) {
      this.attributeValue.active = true;
    } else if (!!this.attributeValues && this.attributeValues.length > 0) {
      this.attributeValues.forEach(attributeValue => {
        attributeValue.active = true
      });
    }
  }

  isMaintained(): boolean {
    return this.isAttributeMaintainedService.isMultiValueAttributeValueMaintained(!!this.attributeValue ? [this.attributeValue] : this.attributeValues);
  }

  getCategory() {
    if(this.attributeValueRelation instanceof CategoryAttributeAttributeValue) {
      return this.attributeValueRelation.categoryAttribute.category;
    }
    return !!this.attributeValueRelation ? this.attributeValueRelation.category : null;
  }

  public isAttributeWithParameters(attribute: Attribute): boolean {
    return attribute.attributeParameters?.length > 0;
  }
}
