import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Formulation } from '../model/formulation';
import { Page } from '../model/pim-response/page';
import { EditMenuService } from './edit-menu-service';
import { Pageable } from '../model/pageable';
import { environment } from '../../environments/environment';
import { isEqual } from 'lodash';
import { LocalizedStringService } from './localized-string.service';
import { CategoryService } from './category.service';
import { FormulationPartService } from './formulation-part.service';

@Injectable()
export class FormulationService implements EditMenuService<Formulation> {

    public currentFormulation: Formulation = new Formulation();

    constructor(
      private httpClient: HttpClient,
      private localizedStringService: LocalizedStringService,
      private categoryService: CategoryService,
      private formulationPartService: FormulationPartService) {
    }

    public save(formulation: Formulation): Observable<Formulation> {
        return this.httpClient.post<Formulation>(environment.restUrl + '/formulation/save', formulation);
    }

    public search(searchString: string, pageable: Pageable): Observable<Page<Formulation>> {
        return this.httpClient.get<Page<Formulation>>(environment.restUrl + '/formulation/find'
            + ((!!searchString && searchString.length > 0) ? '/' + searchString : '')
            + '?'
            + pageable.toUrlString());
    }

    public load(id: string): Observable<Formulation> {
        return this.httpClient.get<Formulation>(environment.restUrl + '/formulation/' + id);
    }

    private getFormulationObj(formulation: Formulation): any {
      const formulationName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(formulation.name);
      const formulationIndustries = this.categoryService.getOrderedCategoryIds(formulation.industries);
      const formulationDescription = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(formulation.description);
      const formulationParts = this.formulationPartService.getOrderedFormulationParts(formulation.parts);
      const formulationRealmId = formulation.realm ? formulation.realm.id : null;
      return {id: formulation.id, formulationReference: formulation.formulationReference,
        name: formulationName, industries: formulationIndustries, marketingtype: formulation.marketingType,
        description: formulationDescription, parts: formulationParts, realm: formulationRealmId};
    }

    public isEqual(formulationOrig: Formulation, formulation: Formulation): boolean {
      if (formulationOrig != null && formulation != null) {
        let formulationOrigObj = this.getFormulationObj(formulationOrig);
        let formulationObj = this.getFormulationObj(formulation);
        return isEqual(formulationOrigObj, formulationObj);
      }
      return false;
    }

}
