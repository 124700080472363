import { LocalizedStringWeb } from "../localized-string-web";

export class BusinessHierarchyNode {
  id: string;
  name: LocalizedStringWeb[];
  children: BusinessHierarchyNode[];

  constructor(id, name = [], children = []) {
    this.id = id;
    this.name = name;
    this.children = children;
  }

}