import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { environment } from '../../environments/environment';

@Directive({
  selector: '[maxAllowedNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxNumericInputValidatorDirective, multi: true}]
})
export class MaxNumericInputValidatorDirective implements Validator {
  @Input('maxAllowedNumber') maxAllowedNumber = environment.localizedStringMaxLength;

  validate (control: AbstractControl): ValidationErrors | null {
    const value: number = parseInt(control.value);
    return value > this.maxAllowedNumber ? {maxNumber: {value: control.value}} : null;
  }
  
}
