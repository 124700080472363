import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Language } from '../../../../../model/language';
import { DocumentVersion } from '../../../../../model/document-version/document-version';
import { Pageable } from '../../../../../model/pageable';
import { Category } from '../../../../../model/category';
import { LanguageTypeaheadService } from '../../../../../service/typeahead/language-typeahead.service';
import InjectIsReadonlyUser from '../../../../../decorator/inject-is-readonly-user.decorator';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DocumentEditDataService } from '../../../../../service/data-service/document-edit-data.service';
import { DocumentVersionDataService } from '../../../../../service/data-service/document-version-data.service';
import { DocumentVersionInputSettings } from '../../../../../model/document-version/document-version-input-settings';
import { DocumentVersionStatus } from '../../../../../model/document-version/document-version-status';
import { DocumentEditContext } from '../../../../../model/document/document-edit-context';
import * as moment from 'moment';



@Component({
  selector: 'document-version-edit',
  templateUrl: './document-version-edit.component.html',
  styleUrls: ['./document-version-edit.component.less']
})
export class DocumentVersionEditComponent implements OnInit, OnDestroy {

  protected readonly documentEditContext = DocumentEditContext;
  protected readonly documentVersionStatus = DocumentVersionStatus;

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @ViewChild('labelFileUpload', { static: false })
  public labelFileUpload: ElementRef;

  @Input()
  public context: string = DocumentEditContext.DOCUMENT_EDIT;

  @Input()
  public availableLanguages:  Language[] = [];

  private subscriptions: Subscription = new Subscription();
  public datePickerConfig: Partial<BsDatepickerConfig>;
  public faCalendar = faCalendar;
  public changeReasonClassifications = (): BehaviorSubject<Category[]> => this.documentVersionDataService.changeReasonClassifications;
  public docVersionLanguage: { required: boolean } = { required: false };

  public get documentVersionBehaviorSubject(): BehaviorSubject<DocumentVersion> {
    return this.documentVersionDataService.documentVersionBehaviorSubject;
  }

  public get inputSettings(): BehaviorSubject<DocumentVersionInputSettings> {
    return this.documentVersionDataService.inputSettings;
  }

  constructor(
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly languageTypeaheadService: LanguageTypeaheadService,
    private readonly documentVersionDataService: DocumentVersionDataService,
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.documentEditDataService.documentBehaviorSubject.subscribe(document => {
      if (!!document) {
        return this.documentVersionDataService.initDocumentVersion(this.context);
      }
    }));

    this.datePickerConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: 'YYYY-MM-DD',
      adaptivePosition: true,
      selectWeek: true,
      selectFromOtherMonth: true,
      isAnimated: true
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isDocumentVersionNew(){
    return this.documentVersionDataService.isDocumentVersionNew();
  }

  public getHeadlineText(): string {
    if (this.isDocumentVersionDraft()){
      return 'headline.documentversion.edit.draft';
    }

    if (this.isDocumentVersionNew()){
      if (this.context === DocumentEditContext.DOCUMENT_EDIT){
        return 'headline.documentversion.add';
      }
      else {
        return 'headline.documentversion.create';
      }
    }

    return 'headline.documentversion.edit';
  }

  public getVersionStatusClass(documentVersion: DocumentVersion): string{
    if (this.isDocumentVersionNew()){
      return '';
    }
    const today = moment().startOf('day');
    if (moment(documentVersion.validUntil).isSameOrBefore(today, 'day')) {
      return 'expired';
    }
    return documentVersion.status.toLocaleLowerCase();
  }

  public getDocumentVersionTag(documentVersion: DocumentVersion): string {
    if (this.isDocumentVersionNew()){
      return '';
    }
    const today = moment().startOf('day');
    if (moment(documentVersion.validUntil).isSameOrBefore(today, 'day')) {
      return 'document.version.expired';
    }
    return 'document.version.' + documentVersion.status;
  }

  public languageTypeahead = (searchTerm: string, pageable: Pageable) => this.languageTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.documentVersionDataService.documentVersionBehaviorSubject.getValue().languages.map(m => m.id), this.availableLanguages.map(m => m.id));
  public languageTypeaheadFormatter = (model: Language) => this.languageTypeaheadService.typeaheadFormatter(model);

  onValueChange($event, ngModelRef) {
    if (!!$event) {
      const d: Date = $event;
      ngModelRef.model = d;
    }
  }

  public onFileChange(files: FileList) {
    this.documentVersionDataService.fileToUpload = files.item(0);
  }

  public removeVersionFile() {
    this.documentVersionBehaviorSubject.value.file = null;
  }

  /* Compare method for Security classification (default selection) */
  public changeReasonComparator(object1: { id: string }, object2: { id: string }): boolean {
    if (!object1 || !object2) {
      return false;
    }
    return object1.id === object2.id;
  }

  public isDocumentVersionPublished(documentVersion: DocumentVersion): boolean{
    return documentVersion.status === DocumentVersionStatus.PUBLISHED;
  }

  public isDocumentVersionDraft(): boolean{
    return this.documentVersionBehaviorSubject.value.status === DocumentVersionStatus.DRAFT;
  }
}
