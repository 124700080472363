import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { OverviewService } from '../../service/overview.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html'
})
export class SearchbarComponent<T> implements OnInit {

  @Input()
  public disableTypeAhead: boolean = false;

  @Input()
  public placeholder: string = "";

  @Input()
  public typeAheadSearchFunction: (value: string[]) => Observable<T[]>;

  @Input()
  public typeAheadOutputFormatFunction: (value: T) => string;

  @Input()
  public typeAheadInputFormatFunction: (value: T) => string;

  @Input()
  public initSearchInputFunction: () => T;

  @Input()
  public valueTemplate: TemplateRef<ResultTemplateContext>;

  @Output()
  public searchTokensChange = new EventEmitter<string[]>();

  @Output()
  public buttonClick = new EventEmitter<string[]>();

  @Output()
  public typeaheadResultSelected = new EventEmitter<T>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly overviewService: OverviewService
  ) {}

  ngOnInit(): void {

    if(this.placeholder === "") {
      this.placeholder = this.translateService.instant("placeholder.search.text");
    }

    if (!this.disableTypeAhead && !this.typeAheadSearchFunction) {
      throw new Error('The search function must be defined!');
    }

    if (!!this.valueTemplate && !!this.typeAheadOutputFormatFunction) {
      throw new Error("Either the typeAheadOutputFormatFunction or the valueTemplate must.");
    }

  }

  public get searchObject() {
    return this.overviewService.initSearchObject;
  }

  public set searchObject(searchObject: any){
    this.overviewService.initSearchObject = searchObject;
  }

  public selectItem(event: NgbTypeaheadSelectItemEvent) {
    const searchString: string = this.typeAheadInputFormatFunction(event.item);
    const searchTokens: string[] = this.splitSearchString(searchString)
    this.searchTokensChange.emit(searchTokens);
    this.typeaheadResultSelected.emit(event.item);
  }

  private convertToString(input: T | string): string {
    if (!input) {
      return "";
    }

    if (typeof (input) === "string") {
      return input;
    }
    return this.typeAheadInputFormatFunction(input);
  }

  public searchButtonClick(): void {

    let searchTokens: string[];
    if (typeof (this.overviewService.initSearchObject) === "string") {
      searchTokens = this.splitSearchString(this.overviewService.initSearchObject.replace(/[^a-zA-Z0-9&() ]/g, " "));
    } else {
      searchTokens = this.splitSearchString(this.convertToString(this.overviewService.initSearchObject).replace(/[^a-zA-Z0-9&() ]/g, " "));
    }
    this.buttonClick.emit(searchTokens);
  }

  public resetSearchInput(): void {
    this.overviewService.initSearchObject = "";
  }

  private splitSearchString(searchString: string): string[] {
    return searchString.replace(/[\%]/gi, '').trim().split(" ").filter(element => element !== '');
  }

  public typeahead = (searchString: Observable<string>) =>
    searchString.pipe(
      filter((value: string) => !!value && value.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) =>
        this.typeAheadSearchFunction(this.splitSearchString(term.replace(/[^a-zA-Z0-9 ]/g, " ") ))
          .pipe(
            catchError(() => {
              return of([]);
            })
          )
      )
    )

  closeTypeaheadResults(event: KeyboardEvent, ngbTypeahead: NgbTypeahead): void {
    event.stopImmediatePropagation();
    if (!!ngbTypeahead) {
      ngbTypeahead.dismissPopup();
    }
  }
}
