<form *ngIf="!!(documentVersionBehaviorSubject | async)" #fVersion="ngForm">
  <h4>
    <span>{{ getHeadlineText() | translate }}</span>
    <span></span>
    <div *ngIf="context===documentEditContext.DOCUMENT_EDIT || (context === documentEditContext.DOCUMENT_CREATION_BRIDGE && !!(documentVersionBehaviorSubject | async).id )" id="version-status" 
      class="document-version-status" [ngClass]="getVersionStatusClass(documentVersionBehaviorSubject.asObservable() | async)">
      {{ getDocumentVersionTag(documentVersionBehaviorSubject.asObservable() | async) | lowercase | translate }}
    </div>
  </h4>

  <div class="row">

    <multiple-value-selector class="form-group col-4"
        [(values)]="documentVersionBehaviorSubject.value.languages"
        [inputLabel]="'label.documentversion.language' | translate"
        [inputName]="'document_language'"
        [inputRequired]="true"
        [inputDisabled]="(inputSettings | async)?.disableLanguage || (isReadOnlyUser | async)"
        [validationError]="docVersionLanguage"
        [typeahead]="languageTypeahead"
        [valueFormatter]="languageTypeaheadFormatter"
        [resultFormatter]="languageTypeaheadFormatter">
    </multiple-value-selector>

    <div class="form-group col-4">
      <label for="version-text" class="document-edit-label">{{ 'label.documentversion.version' | translate }}</label>
      <input id="version-text" name="version-text"
             [disabled]="(inputSettings | async)?.disableVersion || (isReadOnlyUser | async)"
             [(ngModel)]="documentVersionBehaviorSubject.value.version"
             type="text" class="form-control">
    </div>

  </div>

  <div class="row">

    <div class="form-group col-4">
      <label for="version_change_reason">{{ 'label.documentversion.change_reason' | translate }}</label>
      <select id="version_change_reason" class="form-select"
              [disabled]="(inputSettings | async)?.disableExpirationDate || (isReadOnlyUser | async)"
              [(ngModel)]="documentVersionBehaviorSubject.value.changeReason"
              [compareWith]='changeReasonComparator' name="version_change_reason">
        <option *ngFor="let item of (changeReasonClassifications() | async); let i = index" [ngValue]="item">{{item.name | localizedStringsFilter}}</option>
      </select>
    </div>

    <div class="form-group col-4">
      <localized-string-edit [localizedStrings]="documentVersionBehaviorSubject.value.changeComment" [readonly]="(inputSettings | async)?.disableExpirationDate || (isReadOnlyUser | async)" inputType="textarea"
          [label]="'label.documentversion.change_comment' | translate"></localized-string-edit>
    </div>

  </div>

  <div class="row">

    <div class="form-group col-4">
      <label for="version-publish" class="document-edit-label">{{ 'label.documentversion.publish' | translate }}</label>
      <div class="input-group mb-2">
        <div class="input-group-text">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </div>
        <input id="version-publish--{{ (documentVersionBehaviorSubject.asObservable() | async).validFrom }}" #docVersionPublish="ngModel" placeholder="{{datePickerConfig.dateInputFormat}}" name="version-publish"
            [disabled]="(inputSettings | async)?.disablePublishDate || (isReadOnlyUser | async)" [(ngModel)]="documentVersionBehaviorSubject.value.validFrom" type="text" bsDatepicker [bsConfig]="datePickerConfig"
            (bsValueChange)="onValueChange($event, docVersionPublish)" required class="form-control" autocomplete="off">
      </div>
    </div>

    <div class="form-group col-4">
      <label for="version-expiration"
        class="document-edit-label">{{ 'label.documentversion.expiration' | translate }}</label>
      <div class="input-group mb-2">
        <div class="input-group-text">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </div>
        <input id="version-expiration--{{ (documentVersionBehaviorSubject | async).validUntil }}" #docVersionExpiration="ngModel" placeholder="{{datePickerConfig.dateInputFormat}}"
            name="version-expiration" [disabled]="(inputSettings | async)?.disableExpirationDate || (isReadOnlyUser | async)" [(ngModel)]="documentVersionBehaviorSubject.value.validUntil" type="text" bsDatepicker
            [bsConfig]="datePickerConfig" (bsValueChange)="onValueChange($event, docVersionExpiration)" required class="form-control" autocomplete="off">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-4">
      <div *ngIf="!(documentVersionBehaviorSubject | async)?.file && context === documentEditContext.DOCUMENT_CREATION_BRIDGE">
        <label class="document-edit-label">{{ 'label.documentversion.filename' | translate }}</label>
        <div class="file-name-input-appendix-background"><div class="file-name-input-appendix">.pdf</div></div>
        <input class="form-control"
               [(ngModel)]="documentVersionBehaviorSubject.value.fileName"
               id="fileNameInput"
               required
               name="fileNameInput">
      </div>
      <label *ngIf="(documentVersionBehaviorSubject.asObservable() | async)?.file || context === documentEditContext.DOCUMENT_EDIT"
             class="document-edit-label">{{ 'label.documentversion.file' | translate }}</label>
      <div *ngIf="!(documentVersionBehaviorSubject.asObservable() | async)?.file && context === documentEditContext.DOCUMENT_EDIT">
        <input type="file" class="form-control" [disabled]="(inputSettings | async)?.disableFile || (isReadOnlyUser | async)"
             id="importFileInput" (change)="onFileChange($event.target.files)"
             name="importFileInput">
      </div>
      <div #importFileName *ngIf="!!(documentVersionBehaviorSubject | async)?.file">
        <input class="form-control" [disabled]="true"
               id="importFileName"
               [ngModel]="(documentVersionBehaviorSubject | async)?.file.name"
               name="something">

        <div *ngIf="!(inputSettings | async)?.disableFile" class="document-file-name-x-icon"
             [style.left.px]="importFileName.offsetWidth-23">
          <fa-icon id="importFileName-clear-selected-value-fa-icon" icon="x" (click)="removeVersionFile()"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</form>
