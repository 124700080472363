import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pageable } from '../../../../model/pageable';
import { ProductHierarchyObject } from '../../../../model/product-hierarchy-object/product-hierarchy-object';
import { ProductHierarchyObjectService } from '../../../../service/product-hierarchy-object.service';
import { MaterialTypeaheadService } from '../../../../service/typeahead/material-typeahead.service';
import { PhoChildrenTypeaheadService } from '../../../../service/typeahead/pho/pho-children-typeahead.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'product-hierarchy-object-tab-relation',
  templateUrl: './product-hierarchy-object-tab-relation.component.html',
  styleUrls: ['./product-hierarchy-object-tab-relation.component.less']
})
export class ProductHierarchyObjectTabRelationComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public isAddView: boolean = false;

  @Input() public productHierarchyObject: ProductHierarchyObject;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly phoChildrenTypeaheadService: PhoChildrenTypeaheadService,
    private readonly productHierarchyObjectService: ProductHierarchyObjectService,
    private readonly materialTypeaheadService: MaterialTypeaheadService
  ) { }

  ngOnInit(): void {
    this.isAddView =  this.route.snapshot.paramMap.get('id') === "add";
  }

  public materialTypeahead = (searchTerm: string, pageable: Pageable) =>
    this.materialTypeaheadService.typeaheadSearch(searchTerm, pageable, this.productHierarchyObjectService.currentProductHierarchyObject.materials.map(m => m.id));

  public phoChildTypeahead = (searchTerm: string, pageable: Pageable) =>
    this.phoChildrenTypeaheadService.typeaheadSearch(searchTerm, pageable, this.productHierarchyObjectService.currentProductHierarchyObject);

  public phoChildTypeaheadFormatter = (model: ProductHierarchyObject) => this.phoChildrenTypeaheadService.typeaheadFormatter(model);

  public formatNodeLabel: (productHierarchyObject: ProductHierarchyObject) => string = (productHierarchyObject: ProductHierarchyObject) =>
    this.productHierarchyObjectService.treeviewFormatter(productHierarchyObject);

  public childrenTypeaheadValuesChange(children: ProductHierarchyObject[]) {
    this.productHierarchyObjectService.currentProductHierarchyObject.children = children;
  }

  public getProductHierarchyObject() {
    return this.productHierarchyObjectService.currentProductHierarchyObject ? this.productHierarchyObjectService.currentProductHierarchyObject : null;
  }

  public getProductHierarchyObjectService() {
    return this.productHierarchyObjectService;
  }
}
