import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import ConditionalRequired from '../../decorator/conditional-required.decorator';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'multiple-value-selector',
  templateUrl: './multiple-value-selector.component.html',
  styleUrls: ['./multiple-value-selector.component.less']
})
export class MultipleValueSelectorComponent implements OnInit {

  faLink = faLink;

  @Input()
  public values: any;

  @Input()
  public idPrefix: string = "multiple-value-selector";

  @Output()
  public valuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  public valueAdded: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public valueRemoved: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public validationError: { required: boolean } = { required: false };

  @Input()
  public inputLabel: string = null;

  @Input()
  public inputName: string;

  @Input()
  public inputPlaceholder: string = "";

  @Input()
  public inputRequired = false;

  @Input()
  public inputShown = false;

  @Input()
  public inputDisabled = false;

  @Input()
  public identifierField: string = "id";

  @ConditionalRequired({ field: 'inputShown', validationEnabled: "inputShown" })
  @Input()
  public typeahead: any;

  @ConditionalRequired({ field: 'resultTemplate', validationEnabled: "inputShown" })
  @Input()
  public resultFormatter: any;

  @ConditionalRequired({ field: 'resultFormatter', validationEnabled: "inputShown" })
  @Input()
  public resultTemplate: TemplateRef<any>;

  @ConditionalRequired({ field: 'valueTemplate' })
  @Input()
  public valueFormatter: any;

  @ConditionalRequired({ field: 'valueFormatter' })
  @Input()
  public valueTemplate: TemplateRef<any>;

  @Input() public cssClass = 'mv-list';
  @Input() public deleteConfirmTitle: string;
  @Input() public deleteConfirmText: string;
  @Input() public allowDuplicateEntries: boolean = false;

  @Input()
  public executeBeforeDelete: (obj: any) => any = (obj: any) => obj;

  @Input()
  public executeBeforeAdd: (value: any) => any = (value: any) => value;

  @Input()
  public executeAfterDelete: (valueSet: any[], obj: any, id: string) => any = (obj: any) => obj;

  @Input()
  public executeAfterAdd: (objToBeAdded: any) => any = (obj: any) => obj;

  @Input()
  public itemVisibilityCriteria: (value: any) => boolean = (value: any) => (value ? true : false);

  @Input()
  public activateSorting: boolean = false;

  @Input()
  public dropMethod: (event: CdkDragDrop<any>) => any = (obj: any) => obj;

  @Input()
  public enabledField: string;

  constructor() { }

  ngOnInit() {
    // Needed for @Required and @ConditionalRequired decorator
  }

  public verifyValueSet() {
    if (this.inputRequired && this.values.length === 0) {
      this.validationError.required = true;
    } else {
      this.validationError.required = false;
    }
  }

  public addValue($event: any): void {

    if ($event && $event.item) {

      $event.preventDefault();
      const item = this.executeBeforeAdd($event.item);

      if (item) {
        let found = false;

        if (!this.allowDuplicateEntries) {
          let obj = this.values.find(x => this.getValueUsingIdentifier(x) === this.getValueUsingIdentifier(item));
          found = !!obj;
        }

        if (this.allowDuplicateEntries || !found) {
          this.valueAdded.emit(item);
          this.values.push(item);
        }
      }

      this.executeAfterAdd(item);
    }

    this.valuesChange.emit(this.values);
    this.verifyValueSet();
  }

  private getValueUsingIdentifier(item: any) {
    return item[this.identifierField];
  }
}
