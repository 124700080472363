import {CanDeactivateFn} from "@angular/router";
import {Observable} from "rxjs";

export interface HasUnsavedChangesGuard {
  hasUnsavedChanges: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const hasUnsavedChangesGuard: CanDeactivateFn<HasUnsavedChangesGuard> = (component: HasUnsavedChangesGuard): Observable<boolean> | Promise<boolean> | boolean => {
  return component.hasUnsavedChanges();
};
