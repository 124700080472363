<div class="row">
  <div class="form-group col-12">
    <label>{{'label.administration.customview.selection.mapping.single_choice_lists' | translate}}</label>
    <div *ngIf="getSingleChoiceListAttributesWithCategories()">
        <typeahead
            [typeaheadFunction]="singleChoiceListsTypeahead"
            [inputPlaceholder]="'label.select.single_choice_lists' | translate"
            [resultTemplate]="singleChoiceListsTypeaheadResultTemplate"
            [inputFormatter]="singleChoiceListsTypeaheadFormatter"
            [(model)]="singleChoiceListAttributesWithCategoriesSelected"
            [additionalDebounceTime]="0">
        </typeahead>
    </div>
  </div>
</div>

<div *ngIf="singleChoiceListAttributesWithCategoriesSelected != null && singleChoiceListAttributesWithCategoriesSelected.attribute.type == 'ENUMERATION'" class="row">  
  <div *ngIf="singleChoiceListAttributesWithCategoriesSelected.attribute.singleChoiceListValues?.length !== 0" class="col-12 form-group">
    <table class="table mb-0">
      <thead>
        <tr>
          <th scope="col">Option</th>
          <th scope="col">Content</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attributeValue of getSelectedSingleChoiceList()">
          <td class="form-group col-4">{{ attributeValue.value }}</td>
          <td> 
            <div class="form-group col-8">
              <localized-string-edit [englishRequired]="true" [inputType]="inputType"
                  [localizedStrings]="getMappedOption(attributeValue.id)"></localized-string-edit>
            </div>  
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="singleChoiceListAttributesWithCategoriesSelected != null && singleChoiceListAttributesWithCategoriesSelected.attribute.type == 'LOCALIZED_ENUMERATION'" class="row">
  <div *ngIf="singleChoiceListAttributesWithCategoriesSelected.attribute.localizedEnumerationValues?.length !== 0" class="col-12 form-group">
    <table class="table mb-0">
      <thead>
        <tr>
          <th scope="col">Option</th>
          <th scope="col">Content</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attributeValue of getSelectedSingleChoiceListLocalized()">
          <td class="form-group col-4">{{ getLocalizedStringEnglishValue(attributeValue.name) }}</td>
          <td> 
            <div class="form-group col-8">
              <localized-string-edit [englishRequired]="true" [inputType]="inputType"
                  [localizedStrings]="getMappedLocalizedOption(attributeValue.id)"></localized-string-edit>
            </div>  
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #singleChoiceListsTypeaheadResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-attribute-relatives [model]="result" [term]="term?.trim().split(' ')" [showAttributeLink]="false"></typeahead-template-category-attribute-relatives>
</ng-template>
