import { LocalizedString } from '../localized-string';
import { Realm } from '../realm/realm';
import { CategoryAttribute } from '../category-attribute';
import { User } from '../user';
import { CustomView } from './custom-view';
import { CustomViewType } from "./custom-view-type";

export class DynamicTemplateView implements CustomView {
  // Tab: Maintenance
  public id: string = null;
  public name: LocalizedString[] = [];
  public description: string = null;
  public realm: Realm = null;
  public type: CustomViewType = CustomViewType.DYNAMIC_TEMPLATE_VIEW;
  public customViewCategoryAttributes: CategoryAttribute[] = [];

  // Tab: Assignments
  public users: User[] = [];

  // Tab: Template
  public templateFileNames: LocalizedString[] = [];
}
