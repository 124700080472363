import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Attribute } from '../../model/attribute';
import { Pageable } from '../../model/pageable';
import { TypeaheadExcludeIdRequest } from '../../model/request/typeahead-exclude-id-request';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';

@Injectable({
  providedIn: 'root', deps: [HttpClient]
})
export class AttributeTypeaheadService implements AbstractTypeaheadService<Attribute> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<Attribute>> {

    let body = new TypeaheadExcludeIdRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      exclude
    );

    return this.baseTypeaheadService.createCall('/attribute/typeahead/v2', body, pageable);
  }

  public typeaheadFormatter(model: Attribute): string {
    if (model){
      return this.baseTypeaheadService.localizedStringFormatter(model.name);
    }
  }

  public typeaheadFormatterWithType(model: Attribute): string {
    if (model){
      return this.baseTypeaheadService.localizedStringFormatter(model.name) + " [" + model.type + "]";
    }
  }
}
