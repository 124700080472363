import { Component, Input } from '@angular/core';
import { AttributeParameterRangeValue } from '../../../../model/attributeparametervalue/attribute-parameter-range-value';
import { AttributeValue } from '../../../../model/attribute-value';

@Component({
  selector: 'range-edit',
  templateUrl: './range-edit.component.html',
  styleUrls: ['./range-edit.component.less']
})
export class RangeEditComponent {

  @Input()
  public readonly: boolean;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public parameterValue: AttributeParameterRangeValue;

  public get lowerValue(): number {
    return !!this.attributeValue ? this.attributeValue.number : this.parameterValue?.lowerValue;
  }

  public set lowerValue(value: number) {
    if(!!this.attributeValue) {
      this.attributeValue.number = value;
    } else {
      this.parameterValue.lowerValue = value;
    }
  }

  public get upperValue(): number {
    return !!this.attributeValue ? this.attributeValue.upperValue : this.parameterValue?.upperValue;
  }

  public set upperValue(value: number) {
    if(!!this.attributeValue) {
      this.attributeValue.upperValue = value;
    } else {
      this.parameterValue.upperValue = value;
    }
  }

  public getId = () => !!this.attributeValue ? this.attributeValue.id : this.parameterValue?.id;
}
