<div>
  <div (click)="toggle()" class="pointer">
    <span class="icon-placeholder">
      <fa-icon icon="caret-right" class="fa-lg" *ngIf="!node.displayChildren"></fa-icon>
      <fa-icon icon="caret-down" class="fa-lg" *ngIf="node.displayChildren && node.children"></fa-icon>
    </span>
    <a (click)="nodeDetail(node.id)">
      <span [innerHTML]="node.name | localizedStringsFilter | highlightTerm:searchTerm"></span>
      <small>
          <b *ngIf="node.regionalValidity.length > 0"> (<span *ngFor="let validity of node.regionalValidity; let isLast=last">{{validity.name | localizedStringsFilter}}{{isLast ? '' : ', '}}</span>)</b>
          <i> [{{'label.type.' + node.type | lowercase | camelCase | translate }}]</i>
      </small>
    </a>
  </div>
  <div *ngIf="node.displayChildren" class="indent-30">
    <node *ngFor="let child of node.children" [node]="child" (load)="loadChildren($event)" (nodeClick)="nodeDetail($event)" [searchTerm]="searchTerm"></node>
  </div>
</div>
