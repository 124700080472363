import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'enumeration-edit',
  templateUrl: './enumeration-edit.component.html',
  styleUrls: ['./enumeration-edit.component.less']
})
export class EnumEditComponent {

  private _selectedValue: string;

  @Input()
  public label: string;

  @Input()
  public readonly: boolean = false;

  @Input()
  public localizationPrefix: string = "";

  @Input()
  public enumerationValues: string[];

  @Input()
  public enumerationTooltips: string[];

  @Input()
  public enumerationValueTemplate: ElementRef;

  @Input()
  public required = false;

  @Input()
  public convertEmptyStringToNull = false;

  @Output()
  public selectedValueChange: EventEmitter<string> = new EventEmitter();

  @Input()
  public get selectedValue(): string {
    return this._selectedValue;
  }

  public set selectedValue(value: string) {
    if (!!this.convertEmptyStringToNull && value === "") {
      this._selectedValue = null;
    } else {
      this._selectedValue = value;
    }
  }

  public setSelectedValue(value: string) {
    this.selectedValue = value;
    this.selectedValueChange.emit(this.selectedValue);
  }

  public getEnumerationTooltip(index: number) {
    if (!!this.enumerationTooltips && this.enumerationTooltips.length >= index - 1) {
      return this.enumerationTooltips[index];
    }
    return "";
  }

  public getSelectedValue(): string {
    return this.selectedValue;
  }
}
