import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../model/user';
import {map} from 'rxjs/operators';
import {Language} from '../model/language';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserService {

  private user: User;
  private loading: boolean = false;
  private userBehaviorSubject: Subject<User> = new Subject<User>();

  constructor(
    private httpClient: HttpClient
  ) { }

  public unsetCurrentUser() {
    this.user = null;
    this.userBehaviorSubject.next(this.user);
  }

  public getCurrentUser(): Observable<User> {
    if (!this.user) {
      if (!this.loading) {
        this.loading = true;
        this.httpClient.get(environment.currentUserUrl, {observe: 'response', withCredentials: true}).subscribe(
          (response => {
            this.user = response.body as User;
            this.userBehaviorSubject.next(this.user);
            this.loading = false;
          })
        );
      }
    }
    return this.userBehaviorSubject.asObservable();
  }

  public getCurrentUserRoles(): Observable<string[]> {
    return this.getCurrentUser().pipe(
      map(user => {
        const roleNames: Array<string> = [];
        if (!!user) {
          for (const role of user.roles) {
            roleNames.push(role.name);
          }
        }
        return roleNames;
      }));
  }

  public getCurrentUserLanguageIsocode(): Observable<string> {
    return this.getCurrentUser()
      .pipe(
        map(user => {
          if (!!user) {
            return user.language.isoCode;
          } else {
            return 'en';
          }
        })
      );
  }

  public saveCurrentUserLanguage(language: Language) {
    return this.httpClient.post<User>(environment.currentUserUrl + '/saveLanguage', language, { withCredentials: true });
  }

  public saveCurrentUserLanguageByIsoCode(isoCode: string) {
    return this.httpClient.post<User>(environment.currentUserUrl + '/saveDefaultLanguage', isoCode, { withCredentials: true });
  }
}
