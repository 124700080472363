import {AfterContentChecked, Component, DoCheck, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {cloneDeep} from 'lodash';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ConfirmService} from '../../../service/confirm.service';
import InjectIsReadonlyUser from '../../../decorator/inject-is-readonly-user.decorator';
import {Document} from '../../../model/document/document';
import {DocumentStatus} from '../../../model/document/document-status';
import {NotificationService} from './../../../service/notification.service';
import {NavigateService} from '../../../service/navigate.service';
import {MaterialService} from '../../../service/material.service';
import {DocumentEditDataService} from '../../../service/data-service/document-edit-data.service';
import {DocumentVersionDataService} from '../../../service/data-service/document-version-data.service';
import {HasUnsavedChangesWithStateGuard} from "../../../guards/has-unsaved-changes-with-state.guard";

@Component({
  selector: 'document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.less']
})
export class DocumentEditComponent implements DoCheck, OnDestroy, OnInit, AfterContentChecked, HasUnsavedChangesWithStateGuard {

  private readonly DOCUMENT_OVERVIEW_URL = '/documents';
  private readonly DOCUMENT_EDIT_URL = '/document/';

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public cancelUrl: string;

  @ViewChild('nav') nav;

  public params: Params;
  public editVersion = false;
  public selectedTab: string = 'properties';
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly notificationService: NotificationService,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly confirmService: ConfirmService,
    private readonly translate: TranslateService,
    private readonly navigateService: NavigateService,
    private readonly materialService: MaterialService,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    const documentId = this.route.snapshot.paramMap.get('id');
    this.cancelUrl = this.route.snapshot.queryParamMap.get('cancelUrl');

    this.subscriptions.add(this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
    }));

    this.documentEditDataService.checkDocBridgeExists(documentId);

    if (documentId === 'add') {
      this.documentEditDataService.resetDocument();

      if(!!this.params?.materialId && this.params.materialId.length > 1 ) {
        this.materialService.load(this.params.materialId).subscribe(material => this.document.value.materials.push(material));
      }
    }

    if (documentId !== 'add') {
      this.documentEditDataService.loadDocument(documentId).subscribe(result => this.handleDocumentLoad(result), error => this.handleDocumentLoadError());
    }
  }

  ngDoCheck() {
    if ((!!this.documentVersionDataService.documentVersionBehaviorSubject.getValue() && this.selectedTab === 'versions')
        || (this.route.snapshot.queryParamMap.get('version') && this.selectedTab === 'versions')) {
      this.editVersion = true;
    } else {
      this.editVersion = false;
    }
  }

  ngOnDestroy(): void {
    this.documentEditDataService.cleanUp();
    this.documentVersionDataService.cleanUp();
    this.subscriptions.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.subscriptions.add(this.route.firstChild.url.subscribe((url) => {
      if (url[url.length - 1].path !== this.selectedTab) {
        this.selectedTab = url[url.length - 1].path;
      }
    }));
  }

  hasUnsavedChangesWithState (currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) :  Observable<boolean> | Promise<boolean> | boolean {

    if (!!nextState && nextState.url.startsWith(this.DOCUMENT_EDIT_URL)){
      return true;
    }
    const hasVersionChanged = this.documentVersionDataService.hasChanged();
    const hasChanges = this.documentEditDataService.hasChanges();

    if (hasChanges || hasVersionChanged) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  private handleDocumentLoad(result: boolean) {
    if (!result) {
      this.router.navigate([this.DOCUMENT_OVERVIEW_URL]);
    } else {
      this.documentEditDataService.documentOrig = cloneDeep(this.documentEditDataService.documentBehaviorSubject.value);
    }
  }

  public isDocumentPublished(document: Document): boolean{
    return document.status === DocumentStatus.PUBLISHED;
  }

  public getStatusClass(document: Document): string{
    if (document.status === DocumentStatus.PUBLISHED && !this.documentEditDataService.hasPublishedVersions()){
      return 'expired';
    }
    return document.status?.toLocaleLowerCase();
  }


  public getDocumentTag(document: Document): string {
    if (document.status === DocumentStatus.PUBLISHED && !this.documentEditDataService.hasPublishedVersions()){
      return 'document.expired';
    }
    return 'document.' + document.status;
  }

  public onSaveButtonClicked() {
    const userClickedTheExpiredNowButton: boolean = this.documentEditDataService.expiredDocumentVersions.length > 0;
    if (userClickedTheExpiredNowButton) {
      this.callExpiredDocumentVersionsModal();

    } else {
      this.callPublishModal();
    }
  }

  public callExpiredDocumentVersionsModal() {
    this.confirmService.confirm('title.confirm.expire.document.versions', this.createExpireVersionsMessage(), 'button.yes', 'button.no').then(confirmed => {
      if (!confirmed) {
        return;
      } else {
        this.callPublishModal();
      }
    });
  }

  public callPublishModal() {
    if (this.shouldBePublished()) {
      this.confirmService.confirm('title.confirm.publish.document', 'message.confirm.publish.document', 'button.yes', 'button.no').then(confirmed => {
        if (!confirmed) {
          this.documentEditDataService.documentBehaviorSubject.value.status = this.documentEditDataService.documentOrig.status;
        }
        this.doSave();
      });
    } else {
      this.doSave();
    }
  }

  public saveAndPublish() {
    this.documentEditDataService.documentBehaviorSubject.value.status = DocumentStatus.PUBLISHED;
    this.onSaveButtonClicked();
  }

  public cancel() {
    if(this.cancelUrl){
      this.router.navigate([this.cancelUrl]);
    } else {
      this.router.navigate([this.DOCUMENT_OVERVIEW_URL]);
    }
  }

  public get document(): BehaviorSubject<Document> {
    return this.documentEditDataService.documentBehaviorSubject;
  }

  private handleDocumentLoadError(): void {
    this.documentEditDataService.cleanUp();
    this.router.navigateByUrl(this.navigateService.previousUrl);
  }

  private shouldBePublished() {
    return this.documentEditDataService.documentOrig.status == DocumentStatus.NOT_PUBLISHED
        && this.documentEditDataService.documentBehaviorSubject.value.status == DocumentStatus.PUBLISHED;
  }

  private createExpireVersionsMessage() {
    const confirmationMessageKey = 'text.confirm.expire.document.versions';
    const expiredLanguages = this.documentEditDataService.expiredDocumentVersions
      .map(version => version.languages)
      .reduce((previousLanguages, currentLanguages) => previousLanguages.concat(currentLanguages), []);
    const expiredLanguageNames = expiredLanguages
      .map(language => language.name.find(localizedString => localizedString.language.isoCode === 'en'))
      .map(lsLanguage => lsLanguage.value);
    return this.translate.instant(confirmationMessageKey, { languages: expiredLanguageNames.join(', ') });
  }

  private doSave() {
    this.documentEditDataService.save()
      .subscribe({next: savedDocument => {
        this.notificationService.addSuccessNotification('label.document.successfully.saved');
        this.location.replaceState(`${this.DOCUMENT_EDIT_URL}${savedDocument.id}/${this.selectedTab}`);
        if (this.route.snapshot.paramMap.get('id') === 'add'){
          // Navigating to the same url updates the params (needed to change to other tab) but there is no call to the server to reload the page
          this.router.navigateByUrl(`${this.DOCUMENT_EDIT_URL}${savedDocument.id}/${this.selectedTab}`);
        }
      }, error: e => {
        this.documentEditDataService.documentBehaviorSubject.value.status = this.documentEditDataService.documentOrig.status;
      }});
  }
}
