import {AttributeRelevance} from './attribute-relevance';
import {AttributeValue} from './attribute-value';
import {Category} from './category';
import {MultipleValueSelectorElement} from './multiple-value-selector/multiple-value-selector-element';
import {ProductHierarchyObject} from './product-hierarchy-object/product-hierarchy-object';
import {AttributeValueRelation} from './attribute-value-relation';

export class CategoryProductHierarchyObject implements AttributeValueRelation, MultipleValueSelectorElement {
  id: string;
  productHierarchyObject: ProductHierarchyObject;
  category: Category;
  attributeValues: AttributeValue[] = [];
  isReadOnly: boolean;
  manuallyMaintained: boolean;
  readonly relevance: AttributeRelevance = AttributeRelevance.PRODUCT_HIERARCHY_OBJECT;
}
