import { LocalizedString } from './localized-string';
import { UnitRelevance } from './unit-relevance';

export class Unit {
    id: string;
    baseUnit: Unit;
    name: LocalizedString[] = [];
    numerator: number;
    denominator: number;
    exp10: number;
    mabasCode: string;
    symbol: string;
    unitRelevance: UnitRelevance[] = [];
}
