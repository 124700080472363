export class MaterialStatusConversion {
  static readonly CONSISTENT = new MaterialStatusConversion("CONSISTENT", "", "consistent/initial");
  static readonly BLOCKED_01 = new MaterialStatusConversion("BLOCKED_01", "01", "Blocked for procmnt/whse");
  static readonly BLOCKED_02 = new MaterialStatusConversion("BLOCKED_02", "02", "Blocked for task list/BOM");
  static readonly Y2 = new MaterialStatusConversion("Y2", "Y2", "Sellout inconsistent");
  static readonly Y3 = new MaterialStatusConversion("Y3", "Y3", "Sellout no wait - incons.");
  static readonly Y4 = new MaterialStatusConversion("Y4", "Y4", "Sellout inconsistent");
  static readonly Y6 = new MaterialStatusConversion("Y6", "Y6", "Deleted inconsistent");
  static readonly YC = new MaterialStatusConversion("YC", "YC", "consistent/distr. locked");
  static readonly YD = new MaterialStatusConversion("YD", "YD", "");
  static readonly YF = new MaterialStatusConversion("YF", "YF", "not for external sourcing");
  static readonly YK = new MaterialStatusConversion("YK", "YK", "Lite: Deleted incons.");
  static readonly YL = new MaterialStatusConversion("YL", "YL", "Lite: initial incons.");
  static readonly YN = new MaterialStatusConversion("YN", "YN", "inkonsistent/no distribut");
  static readonly YO = new MaterialStatusConversion("YO", "YO", "Lite: Sellout incons.");
  static readonly YT = new MaterialStatusConversion("YT", "YT", "Blocked for procurm incon");
  static readonly YW = new MaterialStatusConversion("YW", "YW", "L:Sellout w/o wait. p inc");
  static readonly YX = new MaterialStatusConversion("YX", "YX", "Destroy mat, inc.");
  static readonly Z2 = new MaterialStatusConversion("Z2", "Z2", "sellout planned");
  static readonly Z3 = new MaterialStatusConversion("Z3", "Z3", "Sellout no wait");
  static readonly Z4 = new MaterialStatusConversion("Z4", "Z4", "Sellout");
  static readonly Z6 = new MaterialStatusConversion("Z6", "Z6", "Deleted");
  static readonly ZC = new MaterialStatusConversion("ZC", "ZC", "Locked for procurement");
  static readonly ZD = new MaterialStatusConversion("ZD", "ZD", "Locked for disposal");
  static readonly ZF = new MaterialStatusConversion("ZF", "ZF", "not for external sourcing");
  static readonly ZK = new MaterialStatusConversion("ZK", "ZK", "Lite: Deleted");
  static readonly ZL = new MaterialStatusConversion("ZL", "ZL", "Lite: initial");
  static readonly ZO = new MaterialStatusConversion("ZO", "ZO", "Lite: Sellout");
  static readonly ZT = new MaterialStatusConversion("ZT", "ZT", "Blocked for procurement");
  static readonly ZW = new MaterialStatusConversion("ZW", "ZW", "Lite: Sellout w/o wait. p");
  static readonly ZX = new MaterialStatusConversion("ZX", "ZX", "Destroy material");
  static readonly ZS = new MaterialStatusConversion("ZS", "ZS", "");

  // New statuses for EC_S4
  static readonly C1 = new MaterialStatusConversion("C1","C1", "Phase-In");
  static readonly C2 = new MaterialStatusConversion("C2","C2", "Normal use");
  static readonly C3 = new MaterialStatusConversion("C3","C3", "Phase-Out");
  static readonly C4 = new MaterialStatusConversion("C4","C4", "Set for deletion");

  private name: string;
  private type: string;
  private text: string;

  constructor(name: string, type: string = "", text: string = "") {
    this.name = name;
    this.type = type;
    this.text = text;
  }

  public getText(): string {
    return this.text;
  }

  public getType(): string {
    return this.type;
  }

  public getName(): string {
    return this.name;
  }

  public valueOf(name: string): MaterialStatusConversion {
    return MaterialStatusConversion[name];
  }
}
