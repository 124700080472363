import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import * as _ from 'lodash';
import { FacetPage } from '../../model/pim-response/facet-page';
import { SortType } from '../../model/sort-type';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {

  private _initSearchObject: any;

  public defaultQueryParams: Map<string, Params> = new Map([
      ["material", {typeFacet: 'PRD', facets: 'type,status,goo,productType,sdsVisibility,categoriesAssigned,documentsAssigned', page:0}],
      ["adminstration/material", {typeFacet: 'THIRD_PARTY_PRODUCT,BASE_INGREDIENT', facets: 'type,status,goo,productType,sdsVisibility,categoriesAssigned,documentsAssigned', page:0}]
  ]);
  
  public sortDirection: Map<string, SortType> = new Map<string, SortType>();
  public page = new FacetPage();

  public get initSearchObject(): any {
    return this._initSearchObject;
  }

  public set initSearchObject(value: any) {
    if (!!value && (typeof (value) === 'string')) {
      value = _.replace(value, /,/g, ' ')
    }
    this._initSearchObject = value;
  }

  resetSorting() {
    this.sortDirection.forEach((sortType, key, map) => map.set(key, SortType.NONE))
  }

  public resetPage() {
    this.page = new FacetPage();
  }

}
