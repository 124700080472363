<div *ngIf="!!(templateMaterialComposition.asObservable() | async)" class="container-fluid pb-3">
  <div>
    <h2 class="display-6 fw-normal">{{ (templateMaterialComposition | async)?.material?.name | localizedStringsFilter }}
      <span class="fw-light">({{ (templateMaterialComposition | async)?.material?.type}} - No. : {{ (templateMaterialComposition | async)?.material?.id }})</span>
       - {{ (templateMaterialComposition | async)?.templateView?.name | localizedStringsFilter }}
    </h2>
    <div *ngIf="(documentBehaviorSubject | async)"
         id="document-not-published-or-published"
         class="not-published-or-published"
         [ngClass]="getStatusClass(documentBehaviorSubject.asObservable() | async)">
      {{ !!(documentBehaviorSubject | async) && getDocumentTag(documentBehaviorSubject.asObservable() | async) | lowercase | translate }}
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="selectedTab">
    <li [ngbNavItem]="'properties'" [routerLink]="'properties'" [queryParams]="params" id="properties">
      <a ngbNavLink>{{ 'tab.documentcreation.properties' | translate }}</a>
    </li>
    <li [ngbNavItem]="'documentrelations'" [routerLink]="'documentrelations'" [queryParams]="params" id="documentRelations">
      <a ngbNavLink>{{ 'tab.documentcreation.documentrelations' | translate }}</a>
    </li>
    <li [ngbNavItem]="'renderingoptions'" [routerLink]="'renderingoptions'" [queryParams]="params" id="renderingOptions">
      <a ngbNavLink>{{ 'tab.documentcreation.renderingoptions' | translate }}
        <div *ngIf="!( renderingOptionsBehaviorSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </a>
    </li>
    <li [ngbNavItem]="'materialattributes'" [routerLink]="'materialattributes'" [queryParams]="params" id="materialAttributes">
      <a ngbNavLink>{{ 'tab.documentcreation.materialattributes' | translate }}
        <div *ngIf="!( catMatsBehaviorSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </a>
    </li>
    <li [ngbNavItem]="'documentversions'" [routerLink]="'documentversions'" [queryParams]="params" id="documentVersions">
      <a ngbNavLink>{{ 'tab.documentcreation.documentversions' | translate }}</a>
    </li>
  </ul>

  <div class="border-router-outlet">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="!(documentVersionToBeEdited | async)" class="row mb-3 mt-3">
    <div class="col-12 align-right">
      <icon-button id="document-cancel" buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button *ngIf="!(isNotAdvancedUser | async)"
                   [buttonDisabled]="!(renderingOptionsBehaviorSubject | async) || !(catMatsBehaviorSubject | async) || isNotDocumentLoaded()"
                   id="document-creation-save"
                   buttonType="save"
                   (buttonClick)="save()"></icon-button>
    </div>
  </div>
</div>
