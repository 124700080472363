import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Pageable } from '../../model/pageable';
import { BaseTypeaheadService } from './base-typeahead.service';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { CategoryAttribute } from '../../model/category-attribute';
import { TypeaheadTypeAndExcludeIdRequest } from '../../model/request/typeahead-type-and-exclude-id-request';
import { AttributeRelevance } from '../../model/attribute-relevance';

@Injectable({
  providedIn: 'root'
})
export class CategoryAttributeByRelevanceTypeaheadService implements AbstractTypeaheadService<CategoryAttribute> {

  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[], relevance: AttributeRelevance[]): Observable<Slice<CategoryAttribute>> {
    const body = new TypeaheadTypeAndExcludeIdRequest<AttributeRelevance>(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      relevance || [],
      exclude || []
    );

    return this.baseTypeaheadService.createCall('/category/attribute/typeahead/enriched', body, pageable);
  }

  typeaheadFormatter(model: CategoryAttribute) {
    return this.baseTypeaheadService.localizedStringFormatter(model.attribute.name);
  }
}