import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Language } from '../../../model/language';
import { LocalizedString } from '../../../model/localized-string';
import { LanguageService } from '../../../service/language.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'localized-string-list-edit',
  templateUrl: './localized-string-list-edit.component.html',
  styleUrls: ['./localized-string-list-edit.component.less']
})
export class LocalizedStringListEditComponent implements OnInit {

  @Output()
  public localizedStringsChanged: EventEmitter<LocalizedString[]> = new EventEmitter<LocalizedString[]>()

  @Input()
  isEditableOnClick = true;

  @Input()
  public englishRequired = false;

  @Input()
  public readonly = false;

  @Input()
  public inputType = 'input';

  @Input()
  public label: string;

  @Input()
  public set localizedStrings(ls: LocalizedString[]) {
    this._localizedStrings = ls || [];
    this.calculateLocalizedStringMap();
  }

  public get localizedStrings(): LocalizedString[] {
    if (!this._localizedStrings) {
      return this._localizedStrings;
    }
    return this._localizedStrings.sort((a, b) => (a.language.id < b.language.id) ? -1 : 1);
  }

  private allLanguages: Language[] = [];
  public addLanguageForm: FormGroup = new FormGroup({});
  private _localizedStrings: LocalizedString[] = [];
  public localizedStringMap = new Map<String, LocalizedString[]>;

  public emptyLanguage: Language = new Language();
  public active: string;

  constructor(
    private languageService: LanguageService,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit() {
    this.languageService.getAllLanguages().subscribe(languages => {
      this.init(languages);
      this.initiateEnglishInput();
      this.updateAvailableLanguagesForm();
    });
  }

  private init(languages: Language[]) {

    this.allLanguages = languages;

    this.emptyLanguage.id = null;
    this.emptyLanguage.name = null;
    this.emptyLanguage.isoCode = null;

    const english = this.findEnglishLanguage();
    this.active = english.id;
  }

  public changeLocalizedStrings = (): void => {
    this.localizedStringsChanged.emit(this.localizedStrings);
  }

  private calculateLocalizedStringMap() {
    this.languageService.getAllLanguages().subscribe(languages => {
      this.localizedStringMap.clear();
      this._localizedStrings.forEach(ls => {
        const language = languages.find(lang => lang.id === ls.language.id);

        if(!this.localizedStringMap.has(language.id)) {
          this.localizedStringMap.set(language.id, [])
        }
        
        this.localizedStringMap.get(language.id).push(ls);
      });
    });
  }

  private updateAvailableLanguagesForm(): void {
    this.addLanguageForm = this.formBuilder.group({
      languageControl: [this.emptyLanguage]
    });
  }

  private isLanguageUsed(language: Language): boolean{
    return this.localizedStringMap.has(language.id);
  }

  private findEnglishLanguage(): Language{
    return this.allLanguages.find(lang => this.isEnglish(lang));
  }

  private initiateEnglishInput() {
    const englishLang = this.findEnglishLanguage();

    if(!this.isLanguageUsed(englishLang)) {
      this.createEmptyString(englishLang);
    }
  }

  private createEmptyString(language: Language) {
    const localizedString = new LocalizedString();
    localizedString.id = null;
    localizedString.language = language;
    localizedString.value = '';
    this.localizedStrings.push(localizedString);
    this.calculateLocalizedStringMap();
  }

  private isEnglish(language: Language): boolean {
    return language.isoCode === 'en' ? true : false;
  }

  public findUsedLanguages(): Language[] {
    return this.allLanguages.filter(language => this.localizedStringMap.has(language.id));
  }

  public findRemovableLanguages(): Language[] {
    return this.findUsedLanguages().filter(usedLanguage => !this.isEnglish(usedLanguage));
  }

  public findAvaliableLanguages(): Language[] {
    return this.allLanguages.filter(lang => !this.isLanguageUsed(lang));
  }
  
  public addLocalizedString(): void {
    const language: Language = this.findUsedLanguages().find(lang => lang.id === this.active);
    this.createEmptyString(language);
  }

  public removeLocalizedString(localizedString: LocalizedString): void {
    const i = this.localizedStrings.indexOf(localizedString);
    this.localizedStrings.splice(i, 1);
    this.calculateLocalizedStringMap();
  }

  public addTranslation(): void {
    const language = this.addLanguageForm.get('languageControl').value;
    this.createEmptyString(language);
    this.active = language.id;
    this.updateAvailableLanguagesForm();
  }

  public removeTranslation(language: Language): void {
    this.localizedStrings.filter(ls => ls.language.id == language.id).forEach(ls => {
      const i = this.localizedStrings.indexOf(ls);
      this.localizedStrings.splice(i, 1);
    });
    if(this.active === language.id) {
      const english = this.findEnglishLanguage();
      this.active = english.id;  
    }
    this.calculateLocalizedStringMap();
  }

  public isDefault(item: Language): boolean {
    const english = this.findEnglishLanguage();
    return item === english && this.localizedStringMap.get(english.id).length === 1;
  }

  public getLocalizedStringListByLanguage(language: Language): LocalizedString[] {
    return this.localizedStringMap.get(language.id);
  }
}
