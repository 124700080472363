import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CategoryWithChildren } from '../../model/category-with-children';

@Component({
  selector: 'node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.less']
})
export class NodeComponent {
  @Input()
  public searchTerm: string;

  @Input()
  public node: CategoryWithChildren;

  @Output()
  public load: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public nodeClick: EventEmitter<string> = new EventEmitter<string>();

  public show = true;
  
  toggle() {
    this.node.displayChildren = !this.node.displayChildren;
    if (this.node.displayChildren) {
      this.load.emit(this.node);
    }
  }

  loadChildren(node: CategoryWithChildren) {
    this.load.emit(node);
  }

  nodeDetail(id: string) {
    this.nodeClick.emit(id);
  }

}
