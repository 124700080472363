<div class="container content-container ">
    <div class="row login">
        <div class="col">
            <h2>Login</h2>
            <form  #loginForm="ngForm" (ngSubmit)="login(username.value,password.value)">
                <div class="form-group">
                    <label for="user">{{ 'label.login.user' | translate }}</label>
                    <input type="text" class="form-control" id="user" [placeholder]=" 'placeholder.login.user' | translate " name="user" #username required>
                </div>
                <div class="form-group">
                    <label for="pwd">{{ 'label.login.password' | translate }}</label>
                    <input type="password" class="form-control" id="pwd" [placeholder]=" 'placeholder.login.password' | translate " name="pwd" #password>
                </div>
                <div class="form-group" >
                    <div class="row">
                        <div class="col-10"></div>
                        <div class="col-2 login-button-container" >
                                <button type="submit" class="btn btn-primary" id="btnLogin" translate [disabled]="formLoginDisabled">button.login.login</button>
                        </div>
                    </div>
                </div>
            </form>
            <a href="#" (click)="samlLogin()">Federated Login</a>
        </div>
    </div>
</div>
