import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, convertToParamMap, ParamMap, Params, Router } from '@angular/router';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import InjectIsAdmin from '../../../decorator/inject-is-admin.decorator';
import Required from '../../../decorator/required.decorator';
import { FacetSelection } from '../../../model/facet-selection';
import { Highlighted } from '../../../model/overview-view/highlighted';
import { Pageable } from '../../../model/pageable';
import { UrlParameter } from '../../../model/parameter/url-parameter';
import { FacetMapEntry } from '../../../model/pim-response/facet-map-entry';
import { FacetPage } from '../../../model/pim-response/facet-page';
import { HighlightedMapEntry } from '../../../model/pim-response/highlighted-map-entry';
import { SortType } from '../../../model/sort-type';
import { TableColumn } from '../../../model/table-column';
import { UserFilterResponse } from '../../../model/user-filter/user-filter-response';
import { ParamsFromStringPipe } from "../../../pipes/params-from-string.pipe";
import { OverviewFacetService } from '../../../service/overview/overview-facet.service';
import { OverviewUserFilterService } from '../../../service/overview/overview-user-filter.service';
import { OverviewService } from '../../../service/overview/overview.service';
import { UrlParameterService } from '../../../service/url-parameter.service';
import { UserFilterInterfaceService } from '../../../service/user-filter/user-filter-interface.service';
import { SearchbarComponent } from '../../searchbar/searchbar.component';
import { TableColumnComponent } from '../../table/column/table-column.component';
import { TableComponent } from '../../table/table.component';

@Component({
  selector: 'app-overview-view',
  styleUrls: ['./overview-view.component.less'],
  templateUrl: './overview-view.component.html'
})
export class OverviewViewComponent<T extends Highlighted> implements OnInit, OnDestroy {

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly urlParameterService: UrlParameterService,
    private readonly paramsFromStringPipe: ParamsFromStringPipe,
    private readonly userFilterInterfaceService: UserFilterInterfaceService,
    private readonly overviewUserFilterService: OverviewUserFilterService,
    private readonly overviewFacetService: OverviewFacetService,
    private overviewService: OverviewService) {
  }

  private allParameters: Params;
  private facetFieldsParameter: UrlParameter;
  private facetSearchParameters: UrlParameter[] = [];
  private searchParameters: UrlParameter[] = [];
  private sortParameter: UrlParameter = new UrlParameter('', []);
  private searchFields: string[];
  private _subscriptions: Subscription[] = [];

  public pageable: Pageable = new Pageable();

  @ViewChild(TableComponent, { static: true })
  public tableComponent: TableComponent;

  @ViewChild(SearchbarComponent, { static: true })
  public searchbarComponent: SearchbarComponent<Document>;

  @Input('searchFields')
  searchFieldString: string;

  @Input()
  public tableColumns: TableColumn<T>[] = [];

  @Input()
  public placeholder: string;

  @Input()
  public addButtonSuffix: string = '';

  @Input()
  public title: string;

  @Input()
  public addAllowed: boolean;

  @Input()
  public editComponentUrl: string;

  @Input()
  public customParameters: string;

  @Input()
  public facetFields: string;

  @Required @Input()
  public typeaheadFieldLabelPrefix: string;

  @Required @Input()
  public overviewValueTemplate: TemplateRef<ResultTemplateContext>;

  @Input()
  public customButtons: TemplateRef<any>;

  @Input()
  public leftCustomButtons: TemplateRef<any>;

  @Input()
  public searchFunction: (queryParameters: Params) => Observable<FacetPage<T>>;

  @Input()
  public rowClicked: (item: any) => void;

  @Input()
  public addFunction: () => void;

  @Input()
  public filterType: string;

  public _userFilterResponse: UserFilterResponse;

  @InjectIsAdmin
  public isAdmin: Observable<boolean>;

  public typeAheadSearchFunction: (values: string[]) => Observable<T[]> = (values: string[]) => this.searchTypeahead(values);

  public typeAheadInputFormatFunction: (value: T) => string = (value: T) => this.formatTypeaheadInput(value);

  public get page(): FacetPage<T> {
    return this.overviewService.page as FacetPage<T>;
  }

  ngOnDestroy(): void {
    this.overviewService.initSearchObject = '';
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this.resetView();
    this.overviewFacetService.resetFacets();
  }

  ngOnInit(): void {
    this.validateInputs();
    this.initialize();
  }

  private initialize() {
    this.initializeTable();
    this.initializeFilter()
  }

  private initializeTable(): void {
    this._subscriptions.push(this.route.queryParamMap.subscribe((params: ParamMap) => {
      const isParamFacetsEqual: boolean = this.urlParameterService.equalsParamFacets(params, this.facetSearchParameters, this.facetFieldsParameter);
      const isParamSortEqual: boolean = this.urlParameterService.equalsParamSort(params, this.sortParameter);
      const isParamSearchFieldEqual: boolean = this.urlParameterService.equalsParamSearchField(params, this.allParameters, this.searchFieldString);
      const isParamPageEqual: boolean = this.urlParameterService.equalsParamPage(params, this.pageable);

      if (!isParamFacetsEqual || !isParamSortEqual || !isParamSearchFieldEqual || !isParamPageEqual) {
        this.overviewService.initSearchObject = params.get(this.searchFieldString);
        this.convertRouteParamsToParameters(params);
      }

    }));
  }

  private initializeFilter(): void {
    this.userFilterInterfaceService.clearUserFiltersInitialized();
    this._subscriptions.push(this.userFilterInterfaceService.userFilterBehaviourSubject.subscribe(userFilterResponse => this.onUserFilterChanged(userFilterResponse)));
    this._subscriptions.push(this.userFilterInterfaceService.userFiltersInitializedBehaviourSubject.subscribe(() => this.onUserFiltersInitialized()));
    this.userFilterInterfaceService.changeFilterType(this.filterType);
  }

  private onUserFilterChanged(userFilterResponse: UserFilterResponse): void {

    if(userFilterResponse?.filterType !== this.filterType){
      return;
    }

    this._userFilterResponse = userFilterResponse;
    
    this.convertUserFiltersToParameters();

    this.loadData();
  }

  // This method avoids the double request to the backend when the user filters are initialized
  private onUserFiltersInitialized(){
    if (!!this.userFilterInterfaceService.userFiltersInitializedBehaviourSubject.getValue() && !this.userFilterInterfaceService.userFilterBehaviourSubject.getValue()) {
      // No default filter selected, so load the defaultQueryParams
      this.loadDefaultQueryParams();
    }
  }


  private loadDefaultQueryParams(){
    if(this.overviewService.defaultQueryParams.has(this.editComponentUrl)){
      this.convertRouteParamsToParameters(convertToParamMap(this.overviewService.defaultQueryParams.get(this.editComponentUrl)));
    }
    this.loadData();
  }

  private updateUserFilter(): void {
    this.userFilterInterfaceService.updateParameters(this.searchParameters, this.sortParameter, this.facetSearchParameters);
    this.loadData();
  }

  convertRouteParamsToParameters(params: ParamMap) {
    this.searchParameters = this.urlParameterService.convertRouteParamsToSearchParameter(params, this.searchFieldString);

    this.overviewFacetService.resetFacetSelection();

    this.facetSearchParameters = this.urlParameterService.convertRouteParamsToFacetParameters(params, this.facetFieldsParameter, (facetFieldName, facetValue) => this.overviewFacetService.setFacet(facetFieldName, facetValue));

    this.resetSorting();

    this.sortParameter = this.urlParameterService.convertRouteParamsToSortParameter(params, this.setSorting);

    if (!!params.get('page')) {
      this.pageable.page = Number(params.get('page'));
    }

  }

  convertUserFiltersToParameters(): void{
    this.overviewFacetService.resetFacetSelection();
    this.facetSearchParameters = this.overviewFacetService.updateFacetSearchParameters(this._userFilterResponse);

    this.searchParameters = this.overviewUserFilterService.updateSearchParameters(this._userFilterResponse, this.searchFields);
    this.overviewService.initSearchObject = this.searchParameters.map((param: UrlParameter) => param.value).join(',');
    
    this.resetSorting();
    this.sortParameter = this.overviewUserFilterService.updateSortParameter(this._userFilterResponse, this.searchFields);
    this.overviewService.sortDirection = this.overviewUserFilterService.updateSortDirection(this._userFilterResponse);

    this.pageable.page = 0; // Force to be the first page always to avoid errors trying to access a page that does not exist
  }

  resetSorting() {
    this.sortParameter = null;
    this.overviewService.resetSorting();
  }

  setSorting: (field: string, sortDirection: SortType) => void = (field: string, sortDirection: SortType) => {
    this.overviewService.sortDirection.set(field, sortDirection);
  }

  public switchPage(pageable: Pageable) {
    this.pageable = pageable;
    this.updateUserFilter();
  }

  private loadData(): void {
    this.updateUrl();
    this.searchFunction(this.allParameters).subscribe((page: FacetPage<T>) => {
      this.updatePage(page);
    });
  }

  private updatePage(page: FacetPage<T>): void {
    this.overviewService.page = _.cloneDeep(page);

    if (!!page.facets) {
      this.overviewService.page.facets = new Map<string, FacetMapEntry[]>(Object.entries(page.facets));
    }

    if (!!page.highlights) {
      this.overviewService.page.content
        .filter((element: T) => !!page.highlights[element.id])
        .forEach((element: T) => {
          element.highlights = page.highlights[element.id];
        });
    }
  }

  public onSelect(item: T) {
    if (this.rowClicked) {
      this.rowClicked(item);
    } else {
      this.router.navigate([`/${this.editComponentUrl}/${item.id}`], { queryParams: this.paramsFromStringPipe.transform(this.customParameters) });
    }
  }

  public newItem() {
    if (this.addFunction) {
      this.addFunction();
    } else if (this.rowClicked) {
      this.rowClicked('add');
    } else {
      this.router.navigate([`/${this.editComponentUrl}/add`], { queryParams: this.paramsFromStringPipe.transform(this.customParameters) });
    }
  }

  public createUrlParameters(searchParameters: UrlParameter[], sortParameter: UrlParameter, facetParameters: UrlParameter[], facetFields: UrlParameter, page: number): Map<string, string> {

    const documentOverviewUrlParameter: any = {};

    searchParameters
      .concat(facetParameters)
      .filter((searchParameter: UrlParameter) => !this.urlParameterService.isEmpty(searchParameter))
      .forEach((searchParameter: UrlParameter) => {
        if (!documentOverviewUrlParameter[searchParameter.field]) {
          documentOverviewUrlParameter[searchParameter.field] = [];
        }

        documentOverviewUrlParameter[searchParameter.field].push(searchParameter.value);
      });

    if (!!sortParameter) {
      const parameterName = sortParameter.value === SortType.ASC ? this.urlParameterService.SORT_ASC : this.urlParameterService.SORT_DESC;
      documentOverviewUrlParameter[parameterName] = sortParameter.field;
    }

    documentOverviewUrlParameter[facetFields.field] = facetFields.value;

    documentOverviewUrlParameter.page = page;
    return documentOverviewUrlParameter;
  }

  private updateUrl(): void {
    this.updateAllParameters();
    this.router.navigate([], { queryParams: this.allParameters });
  }

  public facetsChange(facets: FacetSelection[]): void {
    this.pageable = new Pageable();
    const urlParameters: UrlParameter[] = this.urlParameterService.convertFacetsToUrlParameters(facets);
    const selectedUrlParameters: UrlParameter[] = this.urlParameterService
      .convertFacetsToUrlParameters(facets.filter(facetSelection => !!facetSelection.selected));
    const urlParameterFields: string[] = urlParameters.map(urlParameter => urlParameter.field);

    this.facetSearchParameters = this.facetSearchParameters
      .filter(facetParameter => !urlParameterFields.includes(facetParameter.field))
      .concat(selectedUrlParameters);

    this.updateUserFilter();
  }

  public clearFiltersAndSortAndSearch(): void {
    this.resetView();
    this.updateUserFilter();

  }

  public onSearchButtonClick(searchTokens: string[]): void {
    this.pageable = new Pageable();
    this.searchParameters = this.urlParameterService.createSearchParameters(this.searchFields, searchTokens);
    this.updateUserFilter();
  }

  private updateHighlights(page: FacetPage<Highlighted>): void {
    page.content
      .filter((result: Highlighted) => !!page.highlights[result.id])
      .forEach((result: Highlighted) => {
        result.highlights = page.highlights[result.id];
      });
  }

  public searchTypeahead(value: string[]): Observable<T[]> {
    let queryParams: Params = this.searchFields.reduce((cumulatedMap: {}, searchParameter: string) =>
      Object.assign(cumulatedMap, { [searchParameter]: value }), {});

    queryParams = this.facetSearchParameters.reduce((params: Params, urlParameter: UrlParameter) =>
      Object.assign(params, { [urlParameter.field]: urlParameter.value }), queryParams);

    return this.searchFunction(queryParams).pipe(
      tap(this.updateHighlights),
      map((page: FacetPage<T>) => page.content)
    );
  }

  public formatTypeaheadInput(highlighted: any): string {
    if (!highlighted.highlights) {
      if (typeof (highlighted) === 'undefined') {
        return '';
      }
      return String(highlighted);
    }

    return highlighted.highlights.map((value: HighlightedMapEntry) => value.value).join(' ');
  }

  private resetView(): void {
    this.pageable = new Pageable();
    this.searchParameters = [];
    this.sortParameter = null;
    this.facetSearchParameters = [];
    this.overviewService.page = new FacetPage<T>();
    this.updateAllParameters();
    this.resetSorting();
    this.overviewFacetService.resetFacetSelection();
    this.searchbarComponent.resetSearchInput();
    this.resetUserFilter();
  }

  private resetUserFilter(): void {
    if(!!this._userFilterResponse){
      this._userFilterResponse = null;
    }
  }

  private updateAllParameters(): void {
    this.allParameters = this.urlParameterService.createOverviewUrlParameters(this.searchParameters, this.sortParameter, this.facetSearchParameters, this.facetFieldsParameter, this.pageable.page);
  }

  public sortChange(column: TableColumnComponent): void {
    this.pageable = new Pageable();
    this.sortParameter = new UrlParameter(column.field, [column.sortDirection]);
    this.updateUserFilter();
  }

  onTypeaheadResultSelected(selectedResult: T): void {
    this.onSelect(selectedResult);
  }

  private validateInputs(): void {
    if (!this.searchFieldString) {
      throw new Error('The search fields must be defined!');
    } else {
      this.searchFields = this.searchFieldString.split(',');
    }

    if (!this.searchFunction) {
      throw new Error('The search function must be defined!');
    }

    if (!this.editComponentUrl && !this.rowClicked) {
      throw new Error('The url to the edit component or the edit function must be defined!');
    }

    if (!!this.facetFields) {
      this.facetFieldsParameter = new UrlParameter('facets', this.facetFields);
    }
  }
}
