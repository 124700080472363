import { Component, OnInit, Input } from '@angular/core';
import { AttributeValue } from '../../../../model/attribute-value';
import { Attribute } from '../../../../model/attribute';
import { SingleChoiceListValue } from "../../../../model/single-choice-list-value";

@Component({
  selector: 'single-choice-list-attribute-edit',
  templateUrl: './single-choice-list-attribute-edit.component.html'
})
export class SingleChoiceListAttributeEditComponent implements OnInit {

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public readonly: boolean = false;

  ngOnInit() {
    this.attribute.singleChoiceListValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
  }

  public setSelectedValue(value: SingleChoiceListValue) {
    this.attributeValue.singleChoiceListValue = value;
  }

  public getSelectedValue(): SingleChoiceListValue {
    return this.attributeValue?.singleChoiceListValue;
  }

  public compareFn = (a, b) => a && b && a.id === b.id;

}
