import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../service/authentication/authentication.service";

export const logoutGuard: CanActivateFn  = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const authenticationService = inject(AuthenticationService);
  authenticationService.logout();
  return true;
};

