import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'tab-documents',
  templateUrl: './tab-documents.component.html',
  styleUrls: ['./tab-documents.component.less']
})
export class MaterialTabDocumentsComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;
  
  public materialId: string;

  public customParameters: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly materialEditDataService: MaterialEditDataService
    ) { }

  ngOnInit(): void {
    this.materialId = this.materialEditDataService.material.id;
    this.customParameters = `cancelUrl=/material/${this.materialId}/documents&materialId=${this.materialId}`
  }

  public addDocument(): void {
    this.router.navigateByUrl('/document/add?' + this.customParameters);
  }

}
