import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { Attribute } from '../model/attribute';
import { Pageable } from '../model/pageable';
import { environment } from '../../environments/environment';
import { AttributeType } from '../model/attribute-type';
import { isEqual, orderBy } from 'lodash';
import { LocalizedStringService } from './localized-string.service';
import { AttributeCluster } from '../model/attribute-cluster';
import { BackendValidationService } from './form-validation/backend-validation.service';

@Injectable({
    providedIn: 'root'
})
export class AttributeClusterService {

    public selectedAttributeTypes: AttributeType[] = [];

    constructor(
        private httpClient: HttpClient,
        private localizedStringService: LocalizedStringService,
        private backendValidationService: BackendValidationService) {
    }

    public load(id: string): Observable<AttributeCluster> {
        return this.httpClient.get<AttributeCluster>(environment.restUrl + '/attribute/cluster/' + id);
    }

    public search(searchString: string, pageable: Pageable): Observable<Page<AttributeCluster>> {
      return this.httpClient.get<Page<AttributeCluster>>(environment.restUrl + '/attribute/cluster/search/' + btoa(searchString) + '?' + pageable.toUrlString());
    }

    public save(attributeCluster: AttributeCluster): Observable<AttributeCluster> {
        return this.httpClient.post<AttributeCluster>(environment.restUrl + '/attribute/cluster/save', attributeCluster)
          .pipe(this.backendValidationService.renderErrorMessages());
    }

    public getOrderedAttributeIds(attributes: Attribute[]): any[] {
      return orderBy(attributes, ['id']).map(attribute => attribute.id);
    }

    public areEqual(attributeClusterOrig: AttributeCluster, attributeCluster: AttributeCluster): boolean {
      if (attributeClusterOrig != null && attributeCluster != null) {
        let clusterOrigObj = this.getAttributeClusterObj(attributeClusterOrig);
        let clusterObj = this.getAttributeClusterObj(attributeCluster);
        return isEqual(clusterOrigObj, clusterObj);
      }
      return false;
    }

    private getAttributeClusterObj(attributeCluster: AttributeCluster): any {
      let attributeName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(attributeCluster.name);
      return {
        id: attributeCluster.id,
        name: attributeName,
        parent: attributeCluster.parent,
        description: attributeCluster.description
      }
    }
}
