import { Component, OnInit } from "@angular/core";
import { CategoryMaterial } from "../../../../model/category-material";
import { environment } from '../../../../../environments/environment';
import { Attribute } from "../../../../model/attribute";
import * as _ from "lodash";
import InjectIsReadonlyUser from "../../../../decorator/inject-is-readonly-user.decorator";
import { Observable } from "rxjs";

@Component({
  selector: 'material-tab-basis.component',
  templateUrl: './material-tab-basis.component.html'
})
export class MaterialTabBasisComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  ngOnInit(): void {
  }

  public filterBasisCategoryMaterials = (categoryMaterial: CategoryMaterial): boolean => categoryMaterial.category.id === environment.basisCategoryId;

  public preFilterAttributes = (categoryMaterials: CategoryMaterial[]): Attribute[] => {
    return categoryMaterials.at(0).attributeValues.filter(av => av.active).map(av => av.attribute);
  }

}