import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractTypeaheadService } from '../abstract-typeahead.service';
import { Slice } from '../../../model/pim-response/slice';
import { ProductHierarchyObject } from '../../../model/product-hierarchy-object/product-hierarchy-object';
import { Pageable } from '../../../model/pageable';
import { TypeaheadSelfAndExcludeIdRequest } from '../../../model/request/typeahead-self-and-exclude-id-request';
import { BaseTypeaheadService } from '../base-typeahead.service';

@Injectable({
  providedIn: 'root'
})
export class PhoChildrenTypeaheadService implements AbstractTypeaheadService<ProductHierarchyObject> {

  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, productHierarchyObject: ProductHierarchyObject): Observable<Slice<ProductHierarchyObject>> {

    let exclude: string[] = productHierarchyObject.children.map(m => m.id);
    if (productHierarchyObject.parent) {
      exclude.push(productHierarchyObject.parent.id);
    }

    let body = new TypeaheadSelfAndExcludeIdRequest<string>(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      !!productHierarchyObject.id ? productHierarchyObject.id : null,
      exclude
    );

    return this.baseTypeaheadService.createCall('/producthierarchyobject/typeahead/v2/children', body, pageable);
  }

  public typeaheadFormatter(model: ProductHierarchyObject): string {
    return model.internalName;
  }
}
