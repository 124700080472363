import { Component, Input, OnInit } from '@angular/core';
import { LocalizedString } from '../../../../model/localized-string';
import { Attribute } from '../../../../model/attribute';
import { AttributeValue } from '../../../../model/attribute-value';

@Component({
  selector: 'localized-string-list-attribute-edit',
  templateUrl: './localized-string-list-attribute-edit.component.html',
  styleUrls: ['./localized-string-list-attribute-edit.component.less']
})
export class LocalizedStringListAttributeEditComponent implements OnInit {

  @Input()
  public inputType = 'input';

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public readonly: boolean = false;

  public set localizedStrings(localizedStrings: LocalizedString[]) {

    if(!!this.attributeValue) {
      this.attributeValue.localizedString = localizedStrings;
    }
  }

  public get localizedStrings(): LocalizedString[] {
    return this.attributeValue.localizedString || [];
  }

  public ngOnInit(): void {
    this.initializeLocalizedStrings();
  }

  public updateAttributeValue(localizedString: LocalizedString[]) {
    this.localizedStrings = localizedString;
  }

  private initializeLocalizedStrings() {
    if (!!this.attribute) {
      if (!!this.attributeValue && !!this.attributeValue.localizedString && this.attributeValue.localizedString.length > 0) {
            this.localizedStrings = this.attributeValue.localizedString;
      }
    }
  }
}
