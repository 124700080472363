import { Component, EventEmitter, Input, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorSubject } from 'rxjs';
import { EditorService } from '../../../service/editor.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.less']
})
export class EditorComponent implements OnInit {

  @ViewChild(AngularEditorComponent) editor: AngularEditorComponent;

  @Input()
  public set readonly(newValue: boolean) {
    this._readonly = newValue;
    this.editorConfig.editable = !this._readonly;
    this.editorConfigBehaviorSubject.next(this.editorConfig);
  }

  public get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  public htmlContent: string = '';

  @Input()
  public maxTextLength: number = environment.localizedStringMaxLength;

  @Output()
  public htmlContentChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public set toolbarButtonsToShow(newToolBarButtonsToShow: string[]) {
    this._toolbarButtonsToShow = newToolBarButtonsToShow;
    this.editorConfig.toolbarHiddenButtons = [
      this.getDifferenceBetweenTwoArraysOfStrings(this.toolbarButtons, this._toolbarButtonsToShow),
      []
    ]
    this.editorConfigBehaviorSubject.next(this.editorConfig);
  }

  private _showToolbar = false;
  private _readonly = true;
  private _htmlContentOriginal = '';
  private _toolbarButtonsToShow: string[] = [];

  private readonly toolbarButtons = [
    'undo',
    'redo',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'indent',
    'outdent',
    'insertUnorderedList',
    'insertOrderedList',
    'heading',
    'fontName',
    'fontSize',
    'textColor',
    'backgroundColor',
    'customClasses',
    'link',
    'unlink',
    'insertImage',
    'insertVideo',
    'insertHorizontalRule',
    'removeFormat',
    'toggleEditorMode'
    ]

  editorConfig: AngularEditorConfig = {
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    editable: !this._readonly, // intentionally inverted (bug?)
    enableToolbar: !this._readonly,
    // fonts: [
    //   {class: 'arial', name: 'Arial'},
    //   {class: 'times-new-roman', name: 'Times New Roman'},
    //   {class: 'calibri', name: 'Calibri'},
    //   {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    // ],
    height: 'auto',
    minHeight: 'fit-content',
    maxHeight: 'auto',
    minWidth: '400px',
    outline: false,
    placeholder: 'Enter text here...',
    showToolbar: false,
    spellcheck: false,
    sanitize: true,
    translate: 'now',
    toolbarPosition: 'bottom',
    width: '100%',
    toolbarHiddenButtons: [
      this.getDifferenceBetweenTwoArraysOfStrings(this.toolbarButtons, this._toolbarButtonsToShow),
      []
    ]
  };
  editorConfigBehaviorSubject: BehaviorSubject<AngularEditorConfig> = new BehaviorSubject<AngularEditorConfig>(null);

  constructor(
    private readonly editorService: EditorService,
    private readonly sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.editorConfigBehaviorSubject.next(this.editorConfig);
  }

  // function to get the difference between two arrays of strings
  private getDifferenceBetweenTwoArraysOfStrings(array1: string[], array2: string[]): string[] {
    return array1.filter(x => !array2.includes(x));
  }

  onUnFocused(event: any): void {
    // Avoid onUnFocused when clicking on any child element of the editor div
    if (!event.currentTarget.contains(event.relatedTarget)){
      this._showToolbar = false;
      setTimeout(() => {
        this.editorConfig.showToolbar = this._showToolbar;
        this.editorConfigBehaviorSubject.next(this.editorConfig);
        this.changeEditorModeToViewMode();
      } , 800);
    }
  }

  onFocused(event: any): void {
    if (!this._readonly) {
      this._showToolbar = true;
      this.editorConfig.showToolbar = true;
      this.editorConfigBehaviorSubject.next(this.editorConfig);
    }
  }

  changeEditorModeToViewMode() {
    if (!this.editor.modeVisual) {
      this.editor.toggleEditorMode(false);
      this.editor.editorToolbar.setEditorMode(false);
    }
  }

  setHtmlContent: (event: string) => void = (event: string) => {
    this.htmlContent = event;
  }

  onModelChange: ($event: any) => void = ($event: any) => {
    setTimeout(() => { // I assume a bug in the angular(-editor), as the htmlContent is not updated without this timeout
      this.checkLength($event)
      if(this.editor.modeVisual) {
        this.sanitizeHtmlContentIfNeeded($event);
        this.startAndCloseHtmlContentProperlyIfNeeded($event);
        this.htmlContentChange.emit(this.htmlContent)
        this._htmlContentOriginal = this.htmlContent;
      }
    } , 10);
  }

  private readonly checkLength: ($event: any) => void = ($event: any) => {
    if(this.editorService.getUnformattedText($event).length > this.maxTextLength) {
      this.setHtmlContent(this._htmlContentOriginal);
    }
  }

  private readonly sanitizeHtmlContentIfNeeded: (html: string) => void = (html: string) => {
    const sanitizedHtml = this.editorService.sanitizeHtml(html);
    const softSanitizedHtml = this.editorService.softSanitizeHtmlContent(html);
    const sanitizedHtmlLength = this.sanitizer.sanitize(SecurityContext.HTML, sanitizedHtml).length;
    const softSanitizedHtmlLength = this.sanitizer.sanitize(SecurityContext.HTML, softSanitizedHtml).length;
    if(sanitizedHtmlLength !== softSanitizedHtmlLength) {
      this.setHtmlContent(sanitizedHtml);
    }
  }

  private readonly startAndCloseHtmlContentProperlyIfNeeded: (html: string) => void = (html: string) => {
    const surroundedHtml = this.editorService.startAndCloseHtmlContentProperlyIfNeeded(html);
    if(html.length !== surroundedHtml.length) {
      this.setHtmlContent(surroundedHtml);
    }
  }
}
