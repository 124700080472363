import { CustomView } from '../../custom-view/custom-view';
import { Material } from '../../material';

export class TemplateMaterialSearchRequest {
  public constructor(
    public templateViewMaterialId: string,
    public material: Material,
    public templateView: CustomView
  ) {}
}
