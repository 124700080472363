import { Component, Input } from '@angular/core';
import { AttributeValue } from '../../../../model/attribute-value';
import { AttributeParameterNumberValue } from '../../../../model/attributeparametervalue/attribute-parameter-number-value';

@Component({
  selector: 'number-input-edit',
  templateUrl: './number-input-edit.component.html'
})
export class NumberInputEditComponent {

  @Input()
  public label: string;

  @Input()
  public readonly: boolean;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public parameterValue: AttributeParameterNumberValue;

  public get value(): number {
    return !!this.attributeValue ? this.attributeValue.number : this.parameterValue?.numberValue;
  }

  public set value(value: number) {
    if(!!this.attributeValue) {
      this.attributeValue.number = value;
    } else {
      this.parameterValue.numberValue = value;
    }
  }

  public getId = () => !!this.attributeValue ? this.attributeValue.id : this.parameterValue?.id;
}
