import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlParameterService } from './url-parameter.service';
import { CustomView } from '../model/custom-view/custom-view';
import { CustomViewCategoryAttribute } from '../model/custom-view/custom-view-category-attribute';
import { Page } from '../model/pim-response/page';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomViewSearchService implements OnInit {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlParameterService: UrlParameterService
  ) { }

  ngOnInit(): void {
  }

  public findCustomViewsByCategoryAttributeIds(ids: string[]): Observable<CustomView[]> {
    return this.httpClient.post<CustomView[]>(`${environment.restUrl}/customview/byCategoryAttributeIds`, ids);
  }

  public findCustomViewCategoryAttributesByCategoryAttributeIds(ids: string[]): Observable<CustomViewCategoryAttribute[]> {
    return this.httpClient.post<CustomViewCategoryAttribute[]>(`${environment.restUrl}/customviewcategoryattribute/findByCategoryAttributeIds`, ids);
  }

  public search(queryParameters: Params): Observable<Page<Document>> {
    const queryParameterString: string = this.urlParameterService.convertQueryParametersToString(queryParameters);
    const url: string = `${environment.restUrl}/customview/searchV2${queryParameterString}`;
    return this.httpClient.get<Page<Document>>(url);
  }
}
