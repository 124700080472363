<form #f="ngForm">

  <div class="row">

    <div class="form-group col-4">

      <typeahead
          [inputLabel]="'label.document.type' | translate"
          [inputName]="'document_type'"
          [inputRequired]="true"
          [inputDisabled]="(isReadOnlyUser | async) || isReadonly"
          [(model)]="getDocument().type"
          [typeaheadFunction]="documentTypeTypeahead"
          [resultFormatter]="documentTypeTypeaheadFormatter"
          [inputFormatter]="documentTypeTypeaheadFormatter">
      </typeahead>

    </div>

    <div class="form-group col-4">
      <label for="security_classification">{{ 'label.document.security_classification' | translate }}</label>
      <select [disabled]="(isReadOnlyUser | async) || isReadonly" id="security_classification" class="form-select" #securityClassification="ngModel"
        (change)="securityClassChanged()"
        [(ngModel)]="getDocument().securityClass"
        [compareWith]='securityClassificationComparator' required name="document_security_class">
        <option *ngFor="let item of securityClassifications; let i = index" [ngValue]="item">{{item.name | localizedStringsFilter}}</option>
      </select>
    </div>

  </div>
  <div class="row">

    <div class="form-group col-4">

      <typeahead
          [inputLabel]="'label.document.realm' | translate"
          [inputName]="'realm'"
          [inputRequired]="true"
          [inputDisabled]="(isReadOnlyUser | async) || isReadonly"
          [(model)]="getDocument().realm"
          [typeaheadFunction]="realmTypeahead"
          [resultFormatter]="realmTypeaheadFormatter"
          [inputFormatter]="realmTypeaheadFormatter">
      </typeahead>

    </div>
    <div class="form-group col-4">

     <enumeration-edit [label]="'label.document.realm_restriction.enumeration.type' | translate"
        [convertEmptyStringToNull]="false"
        [readonly]="(isReadOnlyUser | async) || isReadonly"
        [localizationPrefix]="'option.document.realm_restriction.enumeration.type.'"
        [enumerationValues]="realmRestrictionTypes"
        [enumerationTooltips]="realmRestrictionTooltips"
        [(selectedValue)]="getDocument().realmRestriction">
      </enumeration-edit>

    </div>

    <div class="form-group col-4">

      <enumeration-edit [label]="'label.document.metadata_visibility.enumeration.type' | translate"
         [convertEmptyStringToNull]="false"
         [readonly]="(isReadOnlyUser | async) || isReadonly"
         [localizationPrefix]="'option.document.metadata_visibility.enumeration.type.'"
         [enumerationValues]="metadataVisibilityTypes"
         [enumerationTooltips]="metadataVisibilityTooltips"
         [style.visibility]="isMetadataVisibilityVisible() ? 'visible' : 'hidden'"
         [(selectedValue)]="getDocument().metadataVisibility">
       </enumeration-edit>

     </div>

  </div>

  <div class="row">

    <div class="form-group col-4">
      <localized-string-edit [readonly]="(isReadOnlyUser | async) || isReadonly" id="document-name" [localizedStrings]="getDocument().name"
        [label]="'label.document.name' | translate"></localized-string-edit>
    </div>

    <div class="form-group col-4">
      <localized-string-edit [readonly]="(isReadOnlyUser | async) || isReadonly" id="document-description" [localizedStrings]="getDocument().description"
      inputType="textarea" [label]="'label.document.description' | translate"></localized-string-edit>
    </div>

  </div>

  <div class="row">
    <div class="form-group col-4">
      <string-input-edit label="{{'label.document.created.by'|translate}}" [inputValue]="generateUserText(getDocument().createdBy?.email, getDocument().createdBy?.cn)" readonly="true"></string-input-edit>
    </div>
    <div class="form-group col-4">
      <string-input-edit id="modified-at-{{ getDocument()?.createdDate }}" label="{{'label.document.created.date'|translate}}" [inputValue]="generateDateText(getDocument().createdDate)" readonly="true"></string-input-edit>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-4">
      <string-input-edit label="{{'label.document.modified.by'|translate}}" [inputValue]="generateUserText(getDocument().modifiedBy?.email, getDocument().modifiedBy?.cn)" readonly="true"></string-input-edit>
    </div>
    <div class="form-group col-4">
      <string-input-edit id="modified-at-{{ getDocument()?.modifiedDate }}" label="{{'label.document.modified.date'|translate}}" [inputValue]="generateDateText(getDocument().modifiedDate)" readonly="true"></string-input-edit>
    </div>
  </div>
  <div class="row">

  </div>


</form>
