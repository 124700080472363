import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable()
export class ClipboardService {

  constructor(
    private notificationService: NotificationService
  ) { }

  public copy(text: string) {
    if (!navigator.clipboard){
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (text));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
      this.notificationService.addSuccessNotification('copy-clipboard.success');
    } else {
      navigator.clipboard.writeText(text).then(() => {
        this.notificationService.addSuccessNotification('copy-clipboard.success');
      } , () => {
        this.notificationService.addErrorNotification('copy-clipboard.error');
      });
    }
  }
}
