<div class="container-boundary" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="singleChoiceListValuesView">
  <label *ngIf="!!label" class="localized-string-edit-label">{{label}}</label>
  <div *ngFor="let singleChoiceListValue of singleChoiceListValuesView" class="d-flex mb-2" cdkDrag cdkDragBoundary=".container-boundary">
    <div class="btn btn-primary iconlist" cdkDragHandle>
      <fa-icon icon="up-down"></fa-icon>
    </div>
    <input type="text" class="form-control" spellcheck="false" [id]="'enum_' + singleChoiceListValue.value" [ngModel]="singleChoiceListValue.value"
          (ngModelChange)="onSingleChoiceListValueChange($event, singleChoiceListValue)">   
    <icon-button buttonType="delete" (buttonClick)="removeSingleChoiceListValue(singleChoiceListValue)" buttonHideLabel="true"></icon-button>
  </div>
  <icon-button buttonType="add" (buttonClick)="addSingleChoiceListValue()"></icon-button>
</div>
