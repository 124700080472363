import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Pageable} from '../../../../model/pageable';
import {ConfirmService} from '../../../../service/confirm.service';
import {AttributeCluster} from '../../../../model/attribute-cluster';
import {LocalizedString} from '../../../../model/localized-string';
import {AttributeClusterService} from '../../../../service/attribute-cluster.service';
import {AttributeClusterTypeaheadService} from '../../../../service/typeahead/attribute-cluster-typeahead.service';
import {NotificationService} from '../../../../service/notification.service';
import {LocalizedStringService} from '../../../../service/localized-string.service';
import {BackendValidationService} from '../../../../service/form-validation/backend-validation.service';
import {cloneDeep} from 'lodash';
import {HasUnsavedChangesGuard} from "../../../../guards/has-unsaved-changes.guard";

@Component({
  selector: 'attributecluster-administration-edit',
  templateUrl: './attributecluster-administration-edit.component.html',
  styleUrls: ['./attributecluster-administration-edit.component.less']
})
export class AttributeClusterAdministrationEditComponent implements HasUnsavedChangesGuard {
  public attributeCluster: AttributeCluster = new AttributeCluster();
  public attributeClusterOrig: AttributeCluster = null;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly attributeClusterService: AttributeClusterService,
    private readonly attributeClusterTypeaheadService: AttributeClusterTypeaheadService,
    private readonly confirmService: ConfirmService,
    private readonly notificationService: NotificationService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly backendValidationService: BackendValidationService,
  ) {
    this.route.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'newAttributeCluster') {
            this.attributeCluster = new AttributeCluster();
        } else {
          this.attributeClusterService.load(params.id)
          .pipe(this.backendValidationService.renderErrorMessages())
          .subscribe((attributeCluster: AttributeCluster)=> {
            this.attributeCluster = attributeCluster;
            this.attributeClusterOrig = cloneDeep(this.attributeCluster);
          });
        }
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.attributeClusterChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public getLocalizedString: (ls: LocalizedString[]) => (string) = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  public clusterTypeahead = (searchTerm: string, pageable: Pageable) => this.attributeClusterTypeaheadService.typeaheadSearch(searchTerm, pageable, this.selfToExcludeList());

  public clusterTypeaheadFormatter = (model: AttributeCluster) => this.attributeClusterTypeaheadService.typeaheadFormatter(model);

  public save() {
    this.attributeClusterService.save(this.attributeCluster).subscribe((attributeCluster: AttributeCluster) => {
      this.notificationService.addSuccessNotification('label.successfully.saved');
      this.attributeCluster = attributeCluster;
      this.attributeClusterOrig = cloneDeep(this.attributeCluster);
      this.reloadItem();
    });
  }

  public cancel() {
    this.router.navigateByUrl('adminstration/attribute');
  }

  public onNodeClick(attributeId: string): void{
    this.router.navigateByUrl('adminstration/attribute/' + attributeId);
  }

  public getAttributes(attributeCluster: AttributeCluster) {
    if (attributeCluster && attributeCluster.attributes) {
      return attributeCluster.attributes;
    }
    return [];
  }

  private reloadItem() {
    // Forcing the reload
    this.router.navigateByUrl('adminstration/attribute/cluster/' + this.attributeCluster.id)
  }

  private attributeClusterChanges(): boolean {
    if (!this.attributeClusterService.areEqual(this.attributeClusterOrig, this.attributeCluster)) {
      return true;
    } else {
      return false;
    }
  }

  public selfToExcludeList() {
    return this.attributeCluster.id ? [this.attributeCluster.id] : [];
  }
}
