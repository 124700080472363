import { Component, Input } from '@angular/core';

@Component({
  selector: 'rendering-option-template',
  templateUrl: './rendering-option-template.component.html',
  styleUrls: ['./rendering-option-template.component.less']
})
export class RenderingOptionTemplateComponent {
  @Input()
  public model: any;

  @Input()
  public isReadonly: boolean = true;
}
