import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from '../../model/pageable';
import { Slice } from '../../model/pim-response/slice';
import { CustomView } from '../../model/custom-view/custom-view';
import { MapTypeaheadService } from './map-typeahead.service';
import { LocalizedStringService } from '../localized-string.service';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomViewMapTypeaheadService implements AbstractTypeaheadService<CustomView>{

  constructor(
    private readonly mapTypeaheadService: MapTypeaheadService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly baseTypeaheadService: BaseTypeaheadService
  ) {}
  
  public typeaheadSearch(searchTerm: string, pageable: Pageable, totalList: CustomView[]): Observable<Slice<CustomView>> {
    return this.mapTypeaheadService.createSearchedInSlice(searchTerm, pageable, this.createTotalListMap(totalList));
  }

  public typeaheadFormatter(model: CustomView) {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }

  private createTotalListMap(totalList: CustomView[]): Map<string, CustomView> {
    const totalListMap: Map<string, CustomView> = new Map();

    totalList.forEach(customView => totalListMap.set(
      _.toLower(this.localizedStringService.getLocalizedStringsValue(customView.name))
          + this.mapTypeaheadService.getDelimiter()
          + customView.id, customView))
    return totalListMap;
  }
}
