<div class="container-fluid pb-3" *ngIf="!edit">

  <h2 class="display-6 fw-normal">{{'headline.administration.language'|translate}}</h2>

  <div class="row">
    <div class="col-6">
      <div class="input-group">
        <input
            type="text"
            class="form-control"
            [placeholder]="'placeholder.language.search' | translate"
            [(ngModel)]="searchString"
            name="languageSearchInput"
            (keyup.enter)="search()">

        <button type="button" class="btn btn-primary" (click)="search()" >
          <fa-icon icon="search"></fa-icon>
          <span class="button-label">{{'button.search'|translate}}</span>
        </button>
      </div>
    </div>
    <div class="col-6 align-right">
      <button (click)="newLanguage()" class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{'button.add.language'|translate}}
      </button>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col">
      <app-table [page]="languages" (rowClick)="editItem($event)" (pageableHandler)="switchPage($event)">
        <app-table-column name="{{ 'label.administration.languageIsoCode' | translate }}" field="isoCode">
        </app-table-column>
        <app-table-column name="{{ 'label.administration.name' | translate }}" field="name" [valueFunction]="getLocalizedStringsValue"></app-table-column>
      </app-table>
    </div>
  </div>

  <div class="row">
    <div class="col-12 align-right">
      <button (click)="newLanguage()" class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{'button.add.language'|translate}}
      </button>
    </div>
  </div>

</div>

<div class="container-fluid pb-3" *ngIf="edit">

  <h2 class="display-6 fw-normal" *ngIf="language.id; else addHeadline">{{ 'headline.administration.maintainLanguage' | translate}}</h2>

  <ng-template #addHeadline>
    <h2 class="display-6 fw-normal">{{'headline.administration.addLanguage'|translate}}</h2>
  </ng-template>

  <form>

    <div class="form-row">
      <div class="col-4 form-group">
        <localized-string-edit [englishRequired]="true" [localizedStrings]="language.name" [label]="'label.administration.name' | translate"></localized-string-edit>
      </div>

      <div class="col-4 form-group">
        <label for="isoCode">{{ 'label.administration.languageIsoCode' |translate }}</label>
        <input type="text" required class="form-control" [(ngModel)]="language.isoCode" name="isoCode" id="isoCode" placeholder="">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 align-right">
        <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
        <icon-button *ngIf="language.id" buttonType="save" (buttonClick)="save()"></icon-button>
        <icon-button *ngIf="!language.id" buttonType="add" (buttonClick)="save()"></icon-button>
      </div>
    </div>

  </form>

</div>
