import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Category } from '../../model/category';
import { Pageable } from '../../model/pageable';
import { CategoryType } from '../../model/category-type';
import { TypeaheadTypeAndExcludeIdRequest } from '../../model/request/typeahead-type-and-exclude-id-request';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';

@Injectable({
  providedIn: 'root', deps: [HttpClient]
})
export class CategoryByTypeTypeaheadService implements AbstractTypeaheadService<Category> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, types: CategoryType[], exclude: string[]): Observable<Slice<Category>> {

    let body = new TypeaheadTypeAndExcludeIdRequest<CategoryType>(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      types,
      exclude
    );

    return this.baseTypeaheadService.createCall('/category/typeahead/type', body, pageable);
  }

  public typeaheadFormatter(model: Category): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }

  public typeaheadFormatterParentPath(model: Category): string {
    if (model.parent) {
      return this.typeaheadFormatterParentPath(model.parent) + ' > ' + this.baseTypeaheadService.localizedStringFormatter(model.name);
    }
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }
}
