export enum MaterialType {
  ART = 'ART',
  PRD = 'PRD',
  PBG = 'PBG',
  ZKIT = 'ZKIT',
  ZPAR = 'ZPAR',
  VERP = 'VERP',
  THIRD_PARTY_PRODUCT = 'THIRD_PARTY_PRODUCT',
  BASE_INGREDIENT = 'BASE_INGREDIENT',
  
  // New types for EC_S4
  ZARA = 'ZARA',
  ZARC = 'ZARC',
  ZARM = 'ZARM',
  ZARN = 'ZARN',
  ZARR = 'ZARR',
  ZINT = 'ZINT',
  ZLIC = 'ZLIC',
  ZMCS = 'ZMCS',
  ZMER = 'ZMER',
  ZNSP = 'ZNSP',
  ZNST = 'ZNST',
  ZNVM = 'ZNVM',
  ZOMP = 'ZOMP',
  ZPAC = 'ZPAC',
  ZPRV = 'ZPRV',
  ZQUA = 'ZQUA',
  ZRAW = 'ZRAW',
  ZRES = 'ZRES',
  ZRET = 'ZRET',
  ZRNC = 'ZRNC',
  ZSER = 'ZSER',
  ZSET = 'ZSET',
  ZSMA = 'ZSMA',
  ZTEC = 'ZTEC'
}
