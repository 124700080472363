import { Component, OnInit } from '@angular/core';
import InjectIsNotAdvancedUser from '../../../../../decorator/inject-is-not-advanced-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tab-document-properties',
  templateUrl: './tab-document-properties.component.html',
  styleUrls: ['./tab-document-properties.component.less']
})
export class TabDocumentPropertiesComponent implements OnInit {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  ngOnInit(): void {
    // for decorator
  }

}
