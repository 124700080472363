import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStringsFilterPipe } from '../pipes/localized-strings-filter.pipe';
import {LocalizedString} from "../model/localized-string";

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(
    private readonly localizedStringsFilterPipe: LocalizedStringsFilterPipe,
    private readonly translateService: TranslateService,
  ) { }

  public propertyValueFunction(object: any, propertyName: string) {
    if (!object || !object.hasOwnProperty(propertyName)) {
      return '';
    }
    return object[propertyName];
  }
  public localizedStringPropertyValueFunction(object: any, propertyName: string) {
    return !!object && object.hasOwnProperty(propertyName) ? this.localizedStringsFilterPipe.transform(object[propertyName]) : "";
  }
  public localizedStringValueFunction(localizedStrings: LocalizedString[]) {
    return this.localizedStringsFilterPipe.transform(localizedStrings);
  }


  public stringValueFunction(value: string) {
    return !!value ? value : '';
  }

  public enumToTranslationStringFunction(value: string, translation: string) {
    let translatedString = this.translateService.instant(translation + "." + value);
    return !!translatedString ? translatedString : '';
  }
}
