import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Document } from '../../model/document/document';
import { DocumentVersion } from '../../model/document-version/document-version';
import { DocumentVersionStatus } from '../../model/document-version/document-version-status';
import { DocumentBackendService } from '../document-backend.service';
import { environment } from '../../../environments/environment';
import  * as _ from 'lodash';
import { CommonComparisonService } from '../comparison/common-comparison.service';
import { DocumentComparisonService } from '../comparison/document-comparison.service';
import { DocumentVersionFormValidationService } from '../form-validation/document-version-form-validation.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentEditDataService {

  private readonly _addUrlPath = 'add';
  public documentBehaviorSubject: BehaviorSubject<Document> = new BehaviorSubject(null);
  public docBridgeBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loaded = false;
  public documentOrig: Document = null;
  private _expiredDocumentVersions: DocumentVersion[];

  constructor(private readonly documentBackendService: DocumentBackendService,
              private readonly commonComparisonService: CommonComparisonService,
              private readonly versionFormValidation: DocumentVersionFormValidationService,
              private readonly documentComparisonService: DocumentComparisonService,) { }

  public get expiredDocumentVersions(): DocumentVersion[] {
    return this._expiredDocumentVersions;
  }

  public save(): Observable<Document> {
    return this.documentBackendService.save(this.documentBehaviorSubject.value)
        .pipe(tap((savedDocument: Document) => {
          this.nextDocument(savedDocument);
        }));
  }

  public nextDocument(savedDocument: Document) {
    this.documentBehaviorSubject.next(savedDocument);
    this.documentOrig = _.cloneDeep(this.documentBehaviorSubject.value);
  }

  public resetDocument(): void {
    this.loaded = true;
    this._expiredDocumentVersions = [];
    this.documentBehaviorSubject.next(new Document());
    this.documentOrig = this.documentBehaviorSubject.value;
  }

  public checkDocBridgeExists(id: string) {
    if(_.isEmpty(id) || id === this._addUrlPath) {
      this.docBridgeBehaviorSubject.next(false);
    }
    else {
      this.documentBackendService.isDocBridgeDocument(id).subscribe(result => this.docBridgeBehaviorSubject.next(result));
    }
  }

  public loadDocument(id: string): Observable<boolean> {
    const documentProcessing: any = pipe(
      tap((document: Document): void => {
        this.documentOrig = _.cloneDeep(document);
        this.loaded = true;
        this.documentBehaviorSubject.next(document);
        this._expiredDocumentVersions = [];
      }),
      map((document: Document): boolean => {
        return !!document;
      })
    );

    return this.documentBackendService.load(id).pipe(documentProcessing);
  }

  public expireVersion(documentVersion: DocumentVersion): void {
    this.documentBehaviorSubject.value.versions
      .filter(vers => vers.id === documentVersion.id)
      .forEach(found => {
        found.validUntil = new Date();
        found.validUntil.setUTCHours(0, 0, 0, 0);
      });
    this.expiredDocumentVersions.push(documentVersion);
  }

  public deleteVersion(documentVersion: DocumentVersion) {
    for (let i = 0; i < this.documentBehaviorSubject.value.versions.length; i++) {
      const row = this.documentBehaviorSubject.value.versions[i];
      if (this.versionFormValidation.areVersionsEqual(row, documentVersion)) {
        this.documentBehaviorSubject.value.versions.splice(i, 1);
        this.documentBehaviorSubject.next(this.documentBehaviorSubject.value);
        break;
      }
    }
  }

  public hasPublishedVersions(){
    const now = new Date();
    return this.documentBehaviorSubject.value.versions
      .some(vers => vers.status === DocumentVersionStatus.PUBLISHED
                      && new Date(vers.validFrom) <= now && new Date(vers.validUntil) > now );
  }

  public cleanUp(): void {
    this.documentBehaviorSubject.next(new Document());
    this.documentOrig = this.documentBehaviorSubject.value;
    this.loaded = false;
  }

  public isSecurityClassPublic(): boolean {
    return this.documentBehaviorSubject.value.securityClass?.id === environment.publicSecurityClassCategoryId;
  }

  public updateDocumentOrig() {
    this.documentOrig = _.cloneDeep(this.documentBehaviorSubject.value);
  }

  public hasChanges() {
    const hasChanges: boolean = this.commonComparisonService.areEqual(
      () => this.documentComparisonService.createComparisonObject(this.documentOrig),
      () => this.documentComparisonService.createComparisonObject(this.documentBehaviorSubject.value));
    return hasChanges;
  }
}
