import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CategoryWithChildren } from '../../model/category-with-children';
import { Pageable } from '../../model/pageable';
import { Category } from '../../model/category';
import { CategoryTypeaheadService } from '../../service/typeahead/category-typeahead.service';
import { CategoryService } from '../../service/category.service';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html'
})
export class CategoryTreeComponent implements OnInit {

  @Input()
  searchTerm: string;

  @Output()
  nodeClick: EventEmitter<string> = new EventEmitter<string>();

  roots: CategoryWithChildren[] = [];
  searchNodes: CategoryWithChildren[] = [];
  nodes: any = {};

  constructor(
    private categoryService: CategoryService,
    private categoryTypeaheadService: CategoryTypeaheadService
  ) {}

  ngOnInit() {
    this.loadRoots();
  }

  nodeDetail(id: string) {
    this.nodeClick.emit(id);
  }

  private loadRoots() {
    this.categoryService.loadRootCategories().subscribe(roots => {
      this.roots = roots;
      this.insertNodes(roots);
      this.insertNodesIntoTree(roots);
    });
  }

  search() {
    if (!this.searchTerm) {
      this.loadRoots();
    } else {

      let pageable: Pageable = new Pageable(0, 9999)
      this.categoryTypeaheadService.typeaheadSearch(this.searchTerm, pageable, false).subscribe(
        slice => {
          this.nodes = {};
          this.roots = [];
          this.insertNodes(slice.content as CategoryWithChildren[]);
          this.insertNodesIntoTree(slice.content as CategoryWithChildren[]);
          for (const currentNode of slice.content) {
            this.displayParents(currentNode as CategoryWithChildren);
            let node: Category = currentNode;
            while (node.parent) {
              node = node.parent;
            }
            if (this.roots.findIndex((existingNode) => existingNode.id === node.id) < 0) {
              this.roots.push(node as CategoryWithChildren);
            }
          }
        }
      );
    }
  }

  displayParents(node: Category) {
    if (node.parent) {
      this.nodes[node.parent.id].displayChildren = true;
      this.displayParents(node.parent);
    }
  }

  public loadChildren(category: CategoryWithChildren) {
    const tempCategory = category;
    if (!tempCategory.children) {
      if (tempCategory.parent) {
        tempCategory.parent = null;
      }
      this.categoryService.loadChildren(tempCategory).subscribe(categories => {
        this.insertNodes(categories);
        this.insertNodesIntoTree(categories);
        if (category.children) {
          category.displayChildren = true;
        }
      });
    }
  }

  insertNodes(categories: CategoryWithChildren[]) {
    for (const category of categories) {
      if (!this.nodes[category.id]) {
        this.nodes[category.id] = category;
      }
      if (category.parent) {
        this.insertNodes([category.parent as CategoryWithChildren]);
      }
    }
  }

  insertNodesIntoTree(categories: CategoryWithChildren[]) {
    for (const category of categories) {
      this.insertNodeIntoTree(this.nodes[category.id]);
    }
  }

  insertNodeIntoTree(node: CategoryWithChildren) {
    if (node.parent) {
      if (!this.nodes[node.parent.id].children) {
        this.nodes[node.parent.id].children = [];
      }
      if (!this.exists(this.nodes[node.parent.id].children, this.nodes[node.id])) {
        this.nodes[node.parent.id].children.push(this.nodes[node.id] as CategoryWithChildren);
      }
      this.insertNodeIntoTree(node.parent as CategoryWithChildren);
    }
  }

  private exists(nodes: CategoryWithChildren[], node: CategoryWithChildren): boolean {
    for (let index = 0; index < nodes.length; index++) {
      if (nodes[index] === node) {
        return true;
      }
    }
    return false;
  }
}
