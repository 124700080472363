<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal db">{{ "headline.administration.categoryView" | translate }}
    <span class="right-span">
      <button (click)="newCategory()" class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{ 'button.add.category' | translate }}
      </button>
    </span>
  </h2>

  <app-tree-view
    class="col-12"
    (nodeClick)="onNodeClick($event)"
    (nodeNewTabClick)="onNodeNewTabClick($event)"
    [treeViewLineTemplate]="categoryTreeViewLineTemplate"
    [treeViewFacetTemplate]="categoryTreeViewFilterTemplate"
    [areNodesClickable]="!!onNodeClick"
    [formatNodeLabel]="getCategoryTreeviewService().formatNodeLabel"
    [treeviewService]="getCategoryTreeviewService()"
    [showSearchbar]="true"
    [showCurrentNodeOnly]="false">
  </app-tree-view>

</div>

<ng-template #categoryTreeViewLineTemplate let-result="result">
  <app-category-line [model]="result"></app-category-line>
</ng-template>

<ng-template #categoryTreeViewFilterTemplate let-result="result">
  <app-category-filter-line [filter]="result"></app-category-filter-line>
</ng-template>
