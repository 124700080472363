<div *ngIf="renderingOptionsBehaviourSubject | async" class="rendering-options">
  <mat-accordion [ngClass]="'accordion'">
    <mat-expansion-panel [ngClass]="'accordion-item ng-star-inserted'" [expanded]="true">
      <mat-expansion-panel-header [ngClass]="'accordion-header'">
        <mat-panel-title><fa-icon class="grip-vertical" icon="grip-vertical"></fa-icon>
          {{ 'label.administration.documentcreation.renderingoptions.material_attributes' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="accordion-body">
        <div *ngFor="let renderingOption of (renderingMaterialAttributesOptions | async)"
            title="{{ !renderingOption.matching ? ('rendering.option.tooltip' | translate) : '' }}">
          <ng-container *ngTemplateOutlet="renderingOptionTemplate, context: { 'result': renderingOption }"></ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="(someROsInLists | async) && !( templateViewListsBehaviourSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>

  <mat-accordion *ngIf="(someROsInLists | async) && !!( templateViewListsBehaviourSubject | async )" [ngClass]="'accordion'">
    <mat-expansion-panel [ngClass]="{'accordion-item ng-star-inserted': true, 'mat-expansion-panel-overflow' : !this.selectedTemplateViewList}" [expanded]="true" >
      <mat-expansion-panel-header [ngClass]="'accordion-header'">
        <mat-panel-title><fa-icon class="grip-vertical" icon="grip-vertical"></fa-icon>
          {{ 'label.administration.documentcreation.renderingoptions.list_of_template_view' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="accordion-body">
        <div class="col-12">
          <typeahead
            [typeaheadFunction]="templateViewListsTypeahead"
            [inputPlaceholder]="'label.select.templateview-list' | translate"
            [resultTemplate]="templateViewListsTypeaheadResultTemplate"
            [inputFormatter]="templateViewListsTypeaheadFormatter"
            [(model)]="selectedTemplateViewList"
            (modelChange)="onTemplateViewListChange($event)"
            [additionalDebounceTime]="10"
            >
          </typeahead>
        </div>
        <div class="col-12"><hr></div>

        <div *ngIf="!!this.selectedTemplateViewList" class="col-12 mb-3 form-group">
          <pim-grey-bar-stack
            [inputLabel]="'label.administration.customview.list-of-template-view.material-attributes' | translate"
            [inputName]="'category-attribute'"
            id="pim-grey-bar-stack-list-of-template-view.attribute-category-attribute"
            [inputName]="'attribute'"
            [valueTemplate]="categoryAttributeValueTemplate"
            [dropMethod]= "drop"
            [activateSorting]="true"
            [readonly]="true"
            [(values)]="renderingListOptions"
            [enabledField]="'enabled'"
            [matchingField]="'matching'"
            >
          </pim-grey-bar-stack>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #templateViewListsTypeaheadResultTemplate let-result="result" let-term="term">
  <typeahead-template-view-list [model]="result" [term]="term"></typeahead-template-view-list>
</ng-template>

<ng-template #renderingOptionTemplate let-result="result">
  <rendering-option-template [isReadonly]="(isNotAdvancedUser | async)" [model]="result"></rendering-option-template>
</ng-template>

<ng-template #categoryAttributeValueTemplate let-result="result">
  <typeahead-template-category-attribute-ordered [model]="result" class="category-attribute-template"
      [executeOnEnableChange]="onEnableChange" [title]="!result.matching ? ('rendering.option.tooltip' | translate) : ''"
      [enableField]="'enabled'" [orderField]="'order'" [matchingField]="'matching'"></typeahead-template-category-attribute-ordered>
</ng-template>
