import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  private readonly allowedTags = ['p', 'b', 'i', 'u', 'sup', 'sub', 'ol', 'ul', 'li', 'a', 'br', 'div'];
  private readonly parser = new DOMParser();

  constructor(private sanitizer: DomSanitizer) { }

  public getUnformattedText(htmlContent: string): string {
    const unformattedText = this.getTextWithoutHtmlTags(!htmlContent ? '' : htmlContent);
    return this.transformUnicodesInTheProvidedTextToTheirStringRepresentation(unformattedText);
  }

  private getTextWithoutHtmlTags(htmlContent: string) {
    const replaceLineBreaksWithSpaces = htmlContent
      .replace(/<br>/g, ' ')
      .replace(/<div>/g, ' ')
      .replace(/<p>/g, ' ')
      .replace(/<li>/g, ' ')
      .replace(/<a>/g, ' ');

    const doc = this.parser.parseFromString(replaceLineBreaksWithSpaces, 'text/html');
    return doc.body.textContent || '';
  }

  private transformUnicodesInTheProvidedTextToTheirStringRepresentation(text: string): string {
    return text.replace(/&#\d+;/g, (match) => {
      return String.fromCharCode(Number(match.replace(/&#(\d+);/, '$1')));
    });
  }

  public sanitizeHtml(input: string): string {

    // Use Angular's DomSanitizer to sanitize the HTML
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, input);

    // Use a DOMParser to parse the sanitized HTML. Sanitation is necessary to prevent parsing of tags written by the customer for example
    const doc = this.parser.parseFromString(sanitized, 'text/html');
    const cleanedDoc = this.parseDocument(doc.body);

    // Serialize the cleaned DOM tree back into an HTML string and replace <body> and empty div tags
    const cleaned = cleanedDoc.innerHTML
      .replaceAll(/<body>|<\/body>/g, '')
      .replaceAll(/<div>|<\/div>/g, '')
      .replaceAll(/\n/g, '<br/>')
      .replaceAll(/\t/g, '&nbsp;')
      .replaceAll(/[\t\b\f\r]/g, '')
      .replaceAll(/\\\\/g, "&bsol;")
      .replaceAll(/\'/g, "&apos;")
      .replaceAll(/\"/g, "&quot;");

    return cleaned;
  }

  private parseDocument(doc: HTMLElement) {
    // Traverse the DOM tree and remove any unwanted tags and attributes
    Array.from(doc.getElementsByTagName('*')).forEach(element => {

      // If the tag is not in the list of allowed tags, replace it with its content
      if (!this.allowedTags.includes(element.tagName.toLowerCase())) {
        const replacement = this.parseDocument(this.parser.parseFromString(element.innerHTML, 'text/html').body);
        element.parentNode.replaceChild(replacement, element);
      }

      // <a href="...">...</a> is allowed, but remove any other attributes
      Array.from(element.attributes).forEach(attribute => {
        if (!(attribute.name.toLowerCase() === 'href' && element.tagName.toLowerCase() === 'a')) {
          element.removeAttributeNode(attribute);
        }
      });
    });
    return doc;
  }

  /**
   * Soft sanitizes the HTML content by removing the <body> and </body> tags, empty <div> and <span> tags and replacing newlines with <br/>
   * The intention is to only do sanitizations which do not make visible changes to the content
   * @param html
   */
  softSanitizeHtmlContent(html: string): string {
    return html.replaceAll(/<body>|<\/body>/g, '')
      .replaceAll(/<div>|<\/div>/g, '')
      .replaceAll(/<span>|<\/span>/g, '')
      .replaceAll(/\n/g, '<br/>')
      .replaceAll(/\t/g, '&nbsp;')
      .replaceAll(/[\t\b\f\r]/g, '')
      .replaceAll(/\\\\/g, "&bsol;")
      .replaceAll(/\'/g, "&apos;")
      .replaceAll(/\"/g, "&quot;");
  }

  public startAndCloseHtmlContentProperlyIfNeeded(html: string): string {
    if(html.indexOf('<p>') !== -1) {
      // It's sanitized, so we assume that every <p> has also </p>

      // Look ahead positive <p> (without removing it)
      let paragraphSplit = html.split(/(?=<p>)/);
      // add first <p> if not present
      if(paragraphSplit.length > 1) {
        let firstLine = this.surroundLineWithParagraph(paragraphSplit[0]);
        let lastLine = paragraphSplit[paragraphSplit.length - 1];
        if (!lastLine.endsWith("</p>")){
          // Look behind positive </p> (without removing it)
          let lastLineSplit = lastLine.split(/(?<=<\/p>)/);
          lastLineSplit[lastLineSplit.length - 1] = this.surroundLineWithParagraph(lastLineSplit[lastLineSplit.length - 1]);
          lastLine = lastLineSplit.join('');
        }
        if (firstLine !== paragraphSplit[0] || lastLine !== paragraphSplit[paragraphSplit.length - 1]) {
          paragraphSplit[0] = firstLine;
          paragraphSplit[paragraphSplit.length - 1] = lastLine;
          return paragraphSplit.join('');
        }
      }
    }
    return html;
  }

  private readonly surroundLineWithParagraph: (line: string) => string = (line: string) =>  {
    // It's sanitized, so we assume that if <p> is NOT present, also </p> is NOT present
    if (!line.startsWith('<p>')) {
      return `<p>${line}</p>`;
    }
    return line;
  }
}
