import { CategoryAttribute } from '../../category-attribute';

export class TemplateViewListCategoryAttribute {
  constructor(
    public id: string,
    public categoryAttribute: CategoryAttribute,
    public defaultOrder: number,
    public defaultEnabled: boolean,
  ) {
  }
}
