import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HttpFileService {

  constructor(private httpClient: HttpClient) {
  }

  public uploadAndDownloadWithoutLoadingIndicator(file: File, url: string, hideLoadingIndicator: string = 'true'): Observable<HttpResponse<Blob>> {

    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(url, formData, {
      headers: new HttpHeaders({'hideLoadingIndicator': hideLoadingIndicator}),
      responseType: 'blob',
      observe: 'response'
    });
  }

  public uploadAndDownloadMultipleFilesWithoutLoadingIndicator(fileMap: Map<string, File>, url: string, hideLoadingIndicator: string = 'true'): Observable<HttpResponse<Blob>> {

    const formData: FormData = new FormData();
    for (let [key, value] of fileMap) {
      formData.append(key, value);
    }
    return this.httpClient.post(url, formData, {
      headers: new HttpHeaders({'hideLoadingIndicator': hideLoadingIndicator}),
      responseType: 'blob',
      observe: 'response'
    });
  }

  public downloadWithoutLoadingIndicator(url: string, hideLoadingIndicator: string = 'true'): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({'hideLoadingIndicator': hideLoadingIndicator}),
      responseType: 'blob',
      observe: 'response'
    });
  }

  public prepareBlobDownload(response: HttpResponse<Blob>) {

    const dataType: string = response.type.toString();
    const filename = response.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '')
    const binaryData = [];
    const downloadLink = document.createElement('a');

    binaryData.push(response.body);
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
    downloadLink.setAttribute('download', filename);

    document.body.appendChild(downloadLink);

    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }
}
