import { Component, Input, TemplateRef } from '@angular/core';
import { AttributeParameter } from '../../../model/attribute-parameter';
import { AttributeValue } from '../../../model/attribute-value';
import { AttributeParameterValue } from '../../../model/attributeparametervalue/attribute-parameter-value';
import { Category } from '../../../model/category';
import * as _ from 'lodash';

@Component({
  selector: 'app-parameter-value',
  templateUrl: './parameter-value.component.html',
  styleUrls: ['./parameter-value.component.less']
})
export class ParameterValueComponent {

  @Input() 
  public parameterValue: AttributeParameterValue;

  @Input() 
  public parameter: AttributeParameter;

  @Input() 
  public parameterValueTemplate: TemplateRef<any>;

  @Input()
  public leafCategoryWithPotentialParents: Category;

  @Input()
  public showInfoButton: boolean = false;

  @Input() 
  public readonly: boolean = false;

  @Input()
  public attributeValue: AttributeValue;

  public infoButtonClicked: boolean = false;

  public isparameterValueContainerActive = false;
}
