import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Page} from '../model/pim-response/page';
import {ProductHierarchyObject} from '../model/product-hierarchy-object/product-hierarchy-object';
import {EditMenuService} from './edit-menu-service';
import {EditMenuTreeviewService} from './edit-menu/edit-menu-treeview.service';
import {UrlParameterService} from './url-parameter.service';
import {BackendValidationService} from './form-validation/backend-validation.service';
import {environment} from '../../environments/environment';
import {CategoryProductHierarchyObject} from '../model/category-product-hierarchy-object';
import {CustomViewCategoryAttribute} from '../model/custom-view/custom-view-category-attribute';
import {CategoryAttribute} from '../model/category-attribute';
import * as _ from 'lodash';
import {CategoryProductHierarchyObjectService} from './category-product-hierarchy-object.service';
import { CustomViewSearchService } from './customview-search.service';
import { Category } from '../model/category';
import {ProductHierarchyObjectComparisonService} from './comparison/product-hierarchy-object-comparison.service';
import {CategoryService} from './category.service';

@Injectable({
  providedIn: 'root'
})
export class ProductHierarchyObjectService implements EditMenuTreeviewService<ProductHierarchyObject>, EditMenuService<ProductHierarchyObject> {

  public currentProductHierarchyObject?: ProductHierarchyObject = new ProductHierarchyObject();
  private searchString: string = '';
  public categoryPhos: CategoryProductHierarchyObject[] = [];
  public leafCategories = new Map();
  public categoryPhosOrig: CategoryProductHierarchyObject[] = [];
  public customViewCategoryAttributesObservable: Observable<CustomViewCategoryAttribute[]>;
  public categoryPhoLoaded: boolean = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly backendValidationService: BackendValidationService,
    private readonly urlParameterService: UrlParameterService,
    private readonly categoryPhoService: CategoryProductHierarchyObjectService,
    private readonly customViewSearchService: CustomViewSearchService,
    private readonly phoComparisonService: ProductHierarchyObjectComparisonService,
    private readonly categoryService: CategoryService

  ) { }

  loadFilters(): Observable<Map<string, ProductHierarchyObject[]>> {
    return of(null);
  }

  public isSearched(): boolean {
    return !!this.searchString && this.searchString.length > 0;
  }

  public getSearchString(){
    return this.searchString;
  }

  sortFunction: (model: ProductHierarchyObject) => string = null;
  groupFunction: (model: ProductHierarchyObject) => string = null;

  loadCurrentNode(element: ProductHierarchyObject): Observable<ProductHierarchyObject> {
    this.searchString = '';
    return this.httpClient.get<ProductHierarchyObject>(`${environment.restUrl}/producthierarchyobject/treeview/current/${element.id}`);
  }

  searchInTreeview(searchString: string, filters: Map<string, ProductHierarchyObject[]>): Observable<ProductHierarchyObject[]> {
    this.searchString = searchString;
    return this.httpClient.get<ProductHierarchyObject[]>(`${environment.restUrl}/producthierarchyobject/treeview/search/${searchString}`);
  }

  loadRootNodes(filters: Map<string, ProductHierarchyObject[]>): Observable<ProductHierarchyObject[]> {
    this.searchString = '';
    return this.httpClient.get<ProductHierarchyObject[]>(environment.restUrl + '/producthierarchyobject/treeview/roots');
  }

  loadChildNodes(parent: ProductHierarchyObject): Observable<ProductHierarchyObject[]> {
    return this.httpClient.post<ProductHierarchyObject[]>(environment.restUrl + '/producthierarchyobject/treeview/children', parent);
  }

  public treeviewFormatter(model: ProductHierarchyObject): string {
    return model.type ? `${model.internalName} (${model.type})` : model.internalName;
  }

  public save(productHierarchyObject: ProductHierarchyObject): Observable<ProductHierarchyObject> {
    return this.httpClient.post<ProductHierarchyObject>(environment.restUrl + '/producthierarchyobject/save', productHierarchyObject)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public load(id: string): Observable<ProductHierarchyObject> {
    return this.httpClient.get<ProductHierarchyObject>(environment.restUrl + '/producthierarchyobject/' + id)
      .pipe(tap(pho => this.currentProductHierarchyObject = pho));
  }

  public search(queryParameters: Params): Observable<Page<ProductHierarchyObject>> {
    const queryParameterString: string = this.urlParameterService.convertQueryParametersToString(queryParameters);
    const url: string = `${environment.restUrl}/producthierarchyobject/search/v2${queryParameterString}`;
    return this.httpClient.get<Page<ProductHierarchyObject>>(url);
  }

  public createParentList(productHierarchyObject: ProductHierarchyObject, nodeList: ProductHierarchyObject[] = []): ProductHierarchyObject[] {
    if (productHierarchyObject.parent) {
      nodeList = this.createParentList(productHierarchyObject.parent, nodeList);
    }
    nodeList.push(productHierarchyObject);
    return nodeList;
  }

  public loadCategoryPhos(id: string) {
    this.categoryPhoService.loadByPhoIdAndCreateAttributeValuesIfNotExist(id).subscribe((categoryPhos: CategoryProductHierarchyObject[]) => {
      this.categoryPhos = categoryPhos;
      const categoryAttributes: CategoryAttribute [] = [];
      this.categoryPhos
        .map(categoryPho => categoryPho.category.categoryAttributes)
        .forEach(categoryAttributeArray => categoryAttributes.push(...categoryAttributeArray));
      this.customViewCategoryAttributesObservable = this.customViewSearchService
        .findCustomViewCategoryAttributesByCategoryAttributeIds(categoryAttributes.map(categoryAttribute => categoryAttribute.id));
      this.categoryPhosOrig = _.cloneDeep(categoryPhos);
      this.categoryPhoLoaded = true;
      this.initiateCategoryLeafMap();
    });
  }

  public findProductHierarchyObject = () : ProductHierarchyObject => this.currentProductHierarchyObject;

  public findCategoryPhos = (filterFunction?: (categoryPho: CategoryProductHierarchyObject) => boolean): CategoryProductHierarchyObject[] => {
    return !!filterFunction ? this.categoryPhos.filter(cPho => filterFunction(cPho)) : this.categoryPhos;
  }

  public initiateCategoryLeafMap() {
    this.leafCategories.clear();
    this.categoryPhos.forEach(cp => {
      this.addToLeafCategory(cp.category, cp.manuallyMaintained);
    });
  }

  public addToLeafCategory(category: Category, isLeaf: boolean = false) {
    if (isLeaf && (!this.leafCategories.has(category.id) || this.leafCategories.has(category.id) && this.leafCategories.get(category.id) == false)) {
      this.leafCategories.set(category.id, true);
    } else {
      this.leafCategories.set(category.id, false);
    }
    if (category.parent) {
      this.addToLeafCategory(category.parent);
    }
  }

  public isProductHierarchyObjectChanged(productHierarchyObjectOrig: ProductHierarchyObject) : boolean {
    if(!productHierarchyObjectOrig || !this.currentProductHierarchyObject) {
      return false;
    }

    let originalProductHierarchyObject = this.phoComparisonService.createComparisonObject(productHierarchyObjectOrig);
    let currentProductHierarchyObject = this.phoComparisonService.createComparisonObject(this.currentProductHierarchyObject);
    let phoEquals = _.isEqual(originalProductHierarchyObject, currentProductHierarchyObject);
    let categoryPhosEquals = this.categoryPhoService.isEqual(this.categoryPhosOrig, this.categoryPhos);
    return !(phoEquals && categoryPhosEquals);
  }

  public addCategoryAndAttributes() {
    this.categoryService.load(environment.phoCategoryId).subscribe((fullCategory: Category) => {
      this.categoryPhoService.createAndAddCategoryPhosWithAttributeValues(
          this.currentProductHierarchyObject, fullCategory, this.categoryPhos, false);
      this.addToLeafCategory(fullCategory, true);
    });
  }
}
