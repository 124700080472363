import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { DocumentVersion } from '../model/document-version/document-version';
import { LanguageService } from './language.service';
import { LocalizedStringService } from './localized-string.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentProcessingService {

  constructor(
    private readonly languageService: LanguageService,
    private readonly localizedStringService: LocalizedStringService,
 ) { }

  private getDocumentVersionObj(documentVersion: DocumentVersion): any {
    const documentversionLanguages = this.languageService.getOrderedLanguages(documentVersion.languages);
    const changeComments = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(documentVersion.changeComment);
    return {
      id: documentVersion.id,
      file: documentVersion.file,
      version: documentVersion.version,
      changeReason: documentVersion.changeReason,
      changeComment: changeComments,
      validFrom: documentVersion.validFrom,
      validUntil: documentVersion.validUntil,
      languages: documentversionLanguages
    };
  }

  public areVersionsEqual(versionNew: DocumentVersion, versionOrig: DocumentVersion): boolean {
    if (versionNew && versionOrig != null) {
      const documentVersionOrigObj = this.getDocumentVersionObj(versionOrig);
      const documentVersionNewObj = this.getDocumentVersionObj(versionNew);
      return isEqual(documentVersionOrigObj, documentVersionNewObj);
    }
    return true;
  }
}
