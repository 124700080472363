<div id="custom-view-tab-template-view-lists-of-attributes">
  <div *ngIf="!!(templateView | async) && isCustomViewTypeTemplateView()">

    <div *ngIf="!!( templateViewListsSubject | async )" class="row">
      <div class="col-12">
        <label>{{ 'label.administration.customview.list-of-template-view' | translate }}</label>
        <typeahead
          [typeaheadFunction]="templateViewListsTypeahead"
          [inputPlaceholder]="'label.select.templateview-list' | translate"
          [resultTemplate]="templateViewListsTypeaheadResultTemplate"
          [inputFormatter]="templateViewListsTypeaheadFormatter"
          [(model)]="selectedTemplateViewList"
          [additionalDebounceTime]="0">
        </typeahead>
      </div>
      <div class="col-12"><hr></div>

      <div *ngIf="!!this.selectedTemplateViewList" class="col-12 mb-3 form-group">
        <multiple-value-selector
          id="administration-templateview-list-category-attributes"
          [(values)]="selectedTemplateViewList.templateViewListCategoryAttributes"
          [inputLabel]="'label.administration.customview.list-of-template-view.material-attributes' | translate"
          [inputName]="'category-attribute'"
          [typeahead]="categoryAttributeTypeahead"
          [resultTemplate]="categoryAttributeResultTemplate"
          [valueTemplate]="categoryAttributeValueTemplate"
          [executeBeforeAdd]="addTemplateViewListCategoryAttributes"
          [executeAfterAdd]="reorderAfterAdd"
          [executeAfterDelete]="recalculateTemplateViewListCategoryAttributesOrder"
          [activateSorting] = "true"
          [dropMethod]= "drop"
          [enabledField] = "'defaultEnabled'">
        </multiple-value-selector>
      </div>
    </div>

  </div>
</div>

<ng-template #templateViewListsTypeaheadResultTemplate let-result="result" let-term="term">
  <typeahead-template-view-list [model]="result" [term]="term"></typeahead-template-view-list>
</ng-template>

<ng-template #categoryAttributeValueTemplate let-result="result">
  <typeahead-template-category-attribute-ordered [model]="result" class="category-attribute-template"
      [executeOnEnableChange]="onEnableChange"
      [enableField]="'defaultEnabled'" [orderField]="'defaultOrder'"></typeahead-template-category-attribute-ordered>
</ng-template>

<ng-template #categoryAttributeResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-attribute-relatives [model]="result" [term]="term?.trim().split(' ')" [showAttributeLink]="false"></typeahead-template-category-attribute-relatives>
</ng-template>
