
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pageable } from './../../../../model/pageable';
import { CategoryUsageService } from '../../../../service/category-usage.service';
import { Category } from './../../../../model/category';
import { Material } from './../../../../model/material';
import { Page } from '../../../../model/pim-response/page';
import { LocalizedStringService } from './../../../../service/localized-string.service';
import { Formulation } from './../../../../model/formulation';
import { Document } from '../../../../model/document/document';
import { LocalizedString } from '../../../../model/localized-string';
import { ProductHierarchyObject } from '../../../../model/product-hierarchy-object/product-hierarchy-object';

@Component({
  selector: 'category-usage-administration',
  templateUrl: './category-usage-administration.component.html',
  styleUrls: ['./category-usage-administration.component.less']
})
export class CategoryUsageAdministrationComponent implements OnInit {

  @Input() public category: Category;

  public materials: Page<Material> = new Page();
  public documents: Page<Document> = new Page();
  public formulations: Page<Formulation> = new Page();
  public categories: Page<Category> = new Page();
  public phos: Page<ProductHierarchyObject> = new Page();
  public getLocalizedStringsValue: (value: LocalizedString[]) => string = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  constructor(
    private router: Router,
    private categoryUsageService: CategoryUsageService,
    private localizedStringService: LocalizedStringService
  ) { }

  ngOnInit() {
    this.switchMaterialPage(new Pageable());
    this.switchFormulationPage(new Pageable());
    this.switchDocumentPage(new Pageable());
    this.switchCategoryPage(new Pageable());
    this.switchPHOPage(new Pageable());
  }

  public switchMaterialPage(pageable: Pageable) {
    this.categoryUsageService.findAssignedMaterials(this.category, pageable)
      .subscribe((materials: Page<Material>) => this.materials = materials);
  }

  public navigateToMaterial(material: Material) {
    this.router.navigateByUrl('material/' + material.id);
  }

  public switchDocumentPage(pageable: Pageable) {
    this.categoryUsageService.findAssignedDocuments(this.category, pageable)
      .subscribe((documents: Page<Document>) => this.documents = documents);
  }

  public navigateToDocument(document: Document) {
    this.router.navigateByUrl('document/' + document.id);
  }

  public switchFormulationPage(pageable: Pageable) {
    this.categoryUsageService.findAssignedFormulations(this.category, pageable)
      .subscribe((formulations: Page<Formulation>) => this.formulations = formulations);
  }

  public navigateToFormulation(formulation: Formulation) {
    this.router.navigateByUrl('formulations/' + formulation.id);
  }

  public switchCategoryPage(pageable: Pageable) {
    this.categoryUsageService.findAssignedCategories(this.category, pageable)
      .subscribe((categories: Page<Category>) => this.categories = categories);
  }

  public navigateToCategory(category: Category) {
    this.router.navigateByUrl('adminstration/category/' + category.id);
  }

  public switchPHOPage(pageable: Pageable) {
    this.categoryUsageService.findAssignedPHOs(this.category, pageable)
      .subscribe((phos: Page<ProductHierarchyObject>) => this.phos = phos);
  }

  public navigateToPHO(pho: ProductHierarchyObject) {
    this.router.navigateByUrl('product-hierarchy-object/' + pho.id);
  }
}