import { Component, Input, OnInit } from '@angular/core';
import { Attribute } from '../../../model/attribute';
import { AttributeValue } from '../../../model/attribute-value';
import { CategoryMaterial } from '../../../model/category-material';
import { CategoryFormulation } from '../../../model/category-formulation';
import { AttributeParameterType } from '../../../model/attribute-parameter-type';
import { AttributeType } from '../../../model/attribute-type';
import { CategoryProductHierarchyObject } from '../../../model/category-product-hierarchy-object';
import { AttributeValueService } from '../../../service/attribute-value.service';
import { AttributeParameterValue } from '../../../model/attributeparametervalue/attribute-parameter-value';
import { AttributeParameter } from '../../../model/attribute-parameter';
import { AttributeAttributeParameter } from '../../../model/attribute-attribute-parameter';
import { CategoryAttributeAttributeValue } from '../../../model/category-attribute-attribute-value';

@Component({
  selector: 'app-attribute-values-parameterized',
  templateUrl: './attribute-values-parameterized.component.html',
  styleUrls: ['./attribute-values-parameterized.component.less']
})
export class AttributeValuesParameterizedComponent implements OnInit {

  @Input()
  public attribute: Attribute;

  @Input()
  public attributeValues: AttributeValue[];

  @Input()
  public categoryRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue;

  @Input()
  public showInfoButton: boolean = false;

  @Input()
  public readonly: boolean = false;

  public parameterSizeMap = new Map<AttributeParameterType, number>();
  public attributeSizeMap = new Map<AttributeType, number>();

  public sortedAttributeAttributePatameters: AttributeAttributeParameter[];

  constructor(private readonly attributeValueService: AttributeValueService) {
    // Size of Parameter Value Component
    this.parameterSizeMap.set(AttributeParameterType.NUMBER, 2);
    this.parameterSizeMap.set(AttributeParameterType.RANGE, 3);
    this.parameterSizeMap.set(AttributeParameterType.STRING, 2);
    this.parameterSizeMap.set(AttributeParameterType.LOCALIZED_ENUMERATION, 2);
    // Size of Attribute Value Component
    this.attributeSizeMap.set(AttributeType.NUMBER, 2);
    this.attributeSizeMap.set(AttributeType.RANGE, 4);
    this.attributeSizeMap.set(AttributeType.BOOL, 2);
    this.attributeSizeMap.set(AttributeType.STAR_RATING, 2);
    this.attributeSizeMap.set(AttributeType.ENUMERATION, 4);
    this.attributeSizeMap.set(AttributeType.LOCALIZED_ENUMERATION, 2);
  }

  ngOnInit() {
     this.sortAttributeAttributeParameters();
  }

  public getParameterValuesSorted(attributeParameterValues: AttributeParameterValue[]): AttributeParameterValue[] {

    // Remove parameter Value if Parameter is not available anymore
    attributeParameterValues.forEach((apv: AttributeParameterValue, index) => {
      const foundValue = this.sortedAttributeAttributePatameters.find((aap: AttributeAttributeParameter) => aap.attributeParameter.id === apv.attributeParameter.id);
      if(!foundValue) {
        attributeParameterValues.splice(index,1);
      }
    });

    // Create not existing parameter value
    this.sortedAttributeAttributePatameters.forEach((aap: AttributeAttributeParameter) => {
      const foundValue = attributeParameterValues.find((apv: AttributeParameterValue) => apv.attributeParameter.id === aap.attributeParameter.id);
      if(!foundValue) {
        attributeParameterValues.push(this.attributeValueService.createParameterValue(aap.attributeParameter));
      }
    });

    return attributeParameterValues.sort((a, b) => this.getParameterOrder(a.attributeParameter) - this.getParameterOrder(b.attributeParameter));
  }

  private sortAttributeAttributeParameters(): void {
    this.sortedAttributeAttributePatameters = this.attribute.attributeParameters.sort((a, b) => a.order - b.order);
  }

  private getParameterOrder(ap: AttributeParameter): number {

    const app: AttributeAttributeParameter = this.sortedAttributeAttributePatameters.filter(a => a.attributeParameter.id === ap.id)[0]

    if(app == null) {
      return -1;
    }

    return app.order;
  }

  public addAttributeValue() {
    this.categoryRelation.attributeValues.push(this.attributeValueService.createNewAttributeValue(this.attribute));
  }

  public removeAttributeValue(attributeValue: AttributeValue) {
    const i = this.categoryRelation.attributeValues.indexOf(attributeValue);
    this.categoryRelation.attributeValues.splice(i, 1);
  }

}
