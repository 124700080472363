import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../model/user';
import { environment } from '../../../environments/environment';
import { UserRole } from '../../model/userRole';
import { BehaviorSubject, empty, Observable, of, skipWhile, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { OAuthAuthenticationService } from './oauth-authentication.service';
import { AuthenticationStatusService } from './authentication-status.service';

@Injectable()
export class AuthenticationService {
  public currentUser: User;
  public isLoggedInBehaviourSubject = new BehaviorSubject<boolean>(false);
  public isReadOnlyBehaviourSubject = new BehaviorSubject<boolean>(false);
  public isNotAdvancedBehaviourSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private authenticationStatusService: AuthenticationStatusService,
    private oauthAuthenticationService: OAuthAuthenticationService
  ) {

    this.oauthAuthenticationService.loggedIn.subscribe(loginResponse => {
      if (authenticationStatusService.isAuthenticationTypeOAuth() && !!loginResponse?.isAuthenticated) {
        authenticationStatusService.setAuthenticationStatusLoggedIn();
      }
    });
  }

  public retryLogin(err: any): Observable<any> {
    if ((!(err instanceof HttpErrorResponse) || err.status !== 403)) {
      return throwError(err);
    }

    if (this.authenticationStatusService.isAuthenticationStatusLoggedOut() || this.authenticationStatusService.isAuthenticationStatusLoggingOut()) {
      return of();
    }

    this.userService.unsetCurrentUser();
    if (this.authenticationStatusService.isAuthenticationTypeSaml()) {
      window.location.replace(environment.samlLoginUrl + '?redirectUrl=' + window.btoa(this.router.url));

    } else if (environment.currentUserUrl !== err.url) {
      location.reload();
    }

    return of();
  }

  public isLoggedIn(): Observable<boolean> {
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      const filteredRoles: string[] = this.currentUser.roles?.length >= 1 && this.currentUser.roles.map(r => r.name)
        .filter(n => !n.match(/[a-z]/));

      if (!!this.currentUser?.roles &&
        (filteredRoles.length < 1 || (filteredRoles.length === 1 && filteredRoles[0] === 'AUTHENTICATED'))) {
        this.logout('https://confluence.basf.net/display/PIM/Roles+and+how+to+get+access+to+PIM');
        return;
      }
      this.isLoggedInBehaviourSubject.next(!!this.currentUser);
    });
    return this.isLoggedInBehaviourSubject.asObservable();
  }

  public refreshPage(redirectUrl?: string): void {
    const currentLocation: string = window.location.pathname;
    if (currentLocation === redirectUrl || !redirectUrl) {
      if (window.location.hash === '#/logout') {
        window.location.replace('/');
      } else {
        window.location.reload();
      }
    } else {
      this.router.navigate([redirectUrl]);
    }
  }

  public login(username: string, password: string, paramRedirectUrl?: string): void {
    this.authenticationStatusService.setAuthenticationStatusLoggingIn();
    const body: FormData = new FormData();
    body.append('username', username);
    body.append('password', password);

    const httpOptions = {
      withCredentials: true,
      responseType: 'text' as 'text',
      observe: 'response' as 'response' // needed to get the response status code
    };

    this.http.post(environment.loginUrl, body, httpOptions)
      .subscribe({
        next: response => {
          if (response.status === 200) {
            this.authenticationStatusService.setAuthenticationStatusLoggedIn();
            this.currentUser = JSON.parse(response.body);
            this.authenticationStatusService.setAuthenticationTypeForms();
            this.refreshPage(paramRedirectUrl);
          } else {
            console.log('not 200');
          }
        }, error: (err: any) => {
          console.log(err);
        }
      });
  }

  public oauthLogin(): void {
    this.oauthAuthenticationService.login();
  }

  public logout(redirect?: string): void {
    this.authenticationStatusService.setAuthenticationStatusLoggingOut();
    let logoutUrl = '';
    this.currentUser = null;

    if (this.authenticationStatusService.isAuthenticationTypeForms()) {
      logoutUrl = environment.logoutUrl;
      this.http.get(logoutUrl, { responseType: 'text', observe: 'response' })
        .subscribe(response => {
          if (response.status === 200) {
            this.authenticationStatusService.setAuthenticationStatusLoggedOut();
            this.redirectAfterLogout(redirect);
          }
        });

    } else if (this.authenticationStatusService.isAuthenticationTypeOAuth()) {
      localStorage.removeItem('login');
      this.oauthAuthenticationService.logout().subscribe(result => {
        this.authenticationStatusService.setAuthenticationStatusLoggedOut();
        this.redirectAfterLogout(redirect)
      });
    }

    else {
      logoutUrl = environment.samlLogoutUrl;
      if (environment.formsLogin) {
        logoutUrl = environment.logoutUrl;
      }
      this.http.post(logoutUrl, { responseType: 'text', observe: 'response' })
        .subscribe(response => {
          this.authenticationStatusService.setAuthenticationStatusLoggedOut();
          this.redirectAfterLogout(redirect);
        });
    }
  }

  private redirectAfterLogout(redirect?: string) {
    if (!!redirect) {
      localStorage.removeItem('formLogin');
      localStorage.removeItem('login');
      window.location.href = redirect;
    } else {
      (environment.environment !== 'prod' ? this.refreshPage() : window.location.href = 'https://www.basf.net');
    }
  }

  private hasRole(role: UserRole): Observable<boolean> {
    return this.isLoggedIn().pipe(skipWhile(u => !u), map((isLoggedIn: boolean) => {
      if (isLoggedIn && !!this.currentUser.roles && this.currentUser.roles.length > 0) {
        return this.currentUser.roles.some(userRole => role.name === userRole.name)
      }
    }));
  }

  public isAdmin(): Observable<boolean> {
    return this.hasRole(new UserRole('ADMIN'));
  }

  public isUser(): Observable<boolean> {
    return this.hasRole(new UserRole('USER'));
  }

  public isReader(): Observable<boolean> {
    return this.hasRole(new UserRole('READER'));
  }

  public isAdvanced(): Observable<boolean> {
    return this.hasRole(new UserRole('ADVANCED'));
  }

  public isReadOnlyUser(): Observable<boolean> {
    this.isLoggedInBehaviourSubject.asObservable().subscribe(isLoggedIn => {
      this.isReadOnlyBehaviourSubject.next(this.currentUser?.roles.some(userRole => userRole.name === 'READER') &&
        this.currentUser?.roles.every(userRole => (userRole.name !== 'USER') && (userRole.name !== 'ADMIN') && (userRole.name !== 'ADVANCED')))
    });
    return this.isReadOnlyBehaviourSubject.asObservable();
  }

  public isNotAdvancedUser(): Observable<boolean> {
    this.isLoggedInBehaviourSubject.asObservable().subscribe(isLoggedIn => {
      this.isNotAdvancedBehaviourSubject.next(this.currentUser?.roles.some(userRole => ((userRole.name === 'USER') || (userRole.name === 'READER'))) &&
        this.currentUser?.roles.every(userRole => (userRole.name !== 'ADMIN') && (userRole.name !== 'ADVANCED')))
    });
    return this.isNotAdvancedBehaviourSubject.asObservable();
  }
}
