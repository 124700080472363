<div class="container-fluid pb-3">
  <div *ngIf="material">
    <h2 class="display-6 fw-normal" *ngIf="(material.id && material?.type?.toString() == 'THIRD_PARTY_PRODUCT'); else addThirdPartyHeadline">{{ 'headline.administration.maintainThirdPartyProduct' | translate }}</h2>
    <h2 class="display-6 fw-normal" *ngIf="(material.id && material?.type?.toString() == 'BASE_INGREDIENT'); else addBaseIngredientHeadline">{{ 'headline.administration.maintainBaseIngredient' | translate }}</h2>

    <ng-template #addThirdPartyHeadline>
      <h2 class="display-6 fw-normal" *ngIf="material?.type?.toString() == 'THIRD_PARTY_PRODUCT'">{{ 'headline.administration.addThirdPartyProduct' | translate }}</h2>
    </ng-template>
    <ng-template #addBaseIngredientHeadline>
      <h2 class="display-6 fw-normal" *ngIf="material?.type?.toString() == 'BASE_INGREDIENT'">{{ 'headline.administration.addBaseIngredient' | translate }}</h2>
    </ng-template>
  </div>

  <form *ngIf="material">
    <div class="row">
      <div class="col-4 form-group">
        <localized-string-edit [localizedStrings]="material.name" [label]="'label.administration.name' | translate"></localized-string-edit>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="form-group col-12" *ngIf="material.type?.toString() == 'THIRD_PARTY_PRODUCT'">
            <label for="supplier">{{'label.administration.supplier'|translate}}</label>
            <input type="text" class="form-control" id="supplier" name="supplier" [(ngModel)]="material.supplier">
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row mb-3">
    <div class="col-12 align-right">
      <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button *ngIf="material?.id" buttonType="save" (buttonClick)="save()"></icon-button>
      <icon-button *ngIf="!material?.id" buttonType="add" (buttonClick)="save()"></icon-button>
    </div>
  </div>
</div>
