import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MaterialEditDataService} from '../../../service/data-service/material-edit-data.service';
import {ConfirmService} from '../../../service/confirm.service';
import InjectIsReadonlyUser from '../../../decorator/inject-is-readonly-user.decorator';
import {CopyMaterialAssignmentsService} from '../../../service/copy-assignments/copy-material-assignments.service';
import InjectIsAdmin from '../../../decorator/inject-is-admin.decorator';
import {environment} from '../../../../environments/environment';
import InjectIsUser from '../../../decorator/inject-is-user.decorator';
import {HasUnsavedChangesGuard} from "../../../guards/has-unsaved-changes.guard";
import { NotificationService } from '../../../service/notification.service';

@Component({
  selector: 'material-edit-2',
  templateUrl: './material-edit.component.html',
  styleUrls: ['./material-edit.component.less']
})
export class MaterialEditComponent implements OnInit, AfterContentChecked, HasUnsavedChangesGuard {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @InjectIsAdmin
  public isAdmin: Observable<boolean>;

  @InjectIsUser
  public isUser: Observable<boolean>;

  public selectedTab = 'categorization';
  private noCanDeactivateCheck: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly confirmService: ConfirmService,
    private readonly notificationService: NotificationService,
    private readonly materialEditDataService: MaterialEditDataService,
    private readonly copyMaterialAssignmentsService: CopyMaterialAssignmentsService
  ) {}

  ngOnInit(): void {
    this.isReadOnlyUser.subscribe(isReadOnlyUser => this.noCanDeactivateCheck = isReadOnlyUser)
    this.copyMaterialAssignmentsService.init();
    this.route.params.subscribe(params => {
      this.materialEditDataService.init(params.id);
    });
  }

  ngOnDestroy(): void {
    this.materialEditDataService.cleanUp();
  }

  ngAfterContentChecked(): void{
    this.route.firstChild?.url.subscribe((url) => {
      if (url[url.length - 1].path !== this.selectedTab){
        this.selectedTab = url[url.length - 1].path;
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.materialEditDataService.categoryMaterialsChanged() && !this.noCanDeactivateCheck) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public getIsCopyFromDisabled() {
    return !environment.disableCopyFromMaterial;
  }

  public cancel() {
    this.router.navigate(['/materials']);
  }

  public save = () => {
    return this.materialEditDataService.saveCategoryMaterials(this.materialEditDataService.material.id)
      .subscribe({next: ((data) => this.notificationService.addSuccessNotification('label.material.successfully.saved'))});
  };

  public categoryMaterialBS = () => this.materialEditDataService.categoryMaterialsBehaviourSubject;
  public hasCustomViews = () => this.materialEditDataService.hasCustomViews();
  public findMaterial = () => this.materialEditDataService.findMaterial();
  public getCopyAssignmentService = () => this.copyMaterialAssignmentsService;
}
