
<div *ngIf="true" class="row">
<!--<div *ngIf="!!(findCustomViewSubject().asObservable() | async)" class="row">-->
  <div class="form-group col-12">
    <label>{{'label.administration.customview.assigned_users' | translate}}</label>
    <multiple-value-selector
      [(values)]="this.findCustomView().users"
      [inputName]="'userAddInput'"
      [inputPlaceholder]="'label.administration.customview.user' | translate"
      [identifierField]="'cn'"
      [typeahead]="userTypeahead"
      [resultTemplate]="userResultTemplate"
      [valueTemplate]="userValueTemplate">
    </multiple-value-selector>
  </div>
</div>

<ng-template #userValueTemplate let-result="result">
  <typeahead-template-user [model]="result"></typeahead-template-user>
</ng-template>

<ng-template #userResultTemplate let-result="result" let-term="term">
  <typeahead-template-user [model]="result" [term]="term?.trim().split(' ')"></typeahead-template-user>
</ng-template>
