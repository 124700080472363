import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class CommonComparisonService {

  constructor(
    private notificationService: NotificationService
  ) { }

  public areEqual<T>(getOldObject: () => T, getNewObject: () => T): boolean {
    const oldObject: any = _.omitBy(getOldObject(), _.isNil);
    const newObject: any = _.omitBy(getNewObject(), _.isNil);
    return !_.isEqual(oldObject, newObject);
  }

  public validateComparisonObject(model: any, comparisonModel: any, fieldsToExclude: string[] = []): void {
    const modelFields: string[] = Object.keys(model);
    const comparisonObjectFields: string[] = fieldsToExclude.concat(Object.keys(comparisonModel));
    const missingFields: string[] = [];

    modelFields.forEach(field => {
      if (!comparisonObjectFields.includes(field)) {
        missingFields.push(field);
      }
    });

    if (missingFields.length > 0) {
      this.notificationService.addErrorNotification('label.notification.error.comparison');
      throw new Error(`The model comparison object misses the fields [${missingFields}].`);
    }
  }
}
