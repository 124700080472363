<div>
  <app-material [editComponentUrl]="'adminstration/material'"  [customButtons]="customButtons" [title]="'title.administration.material' | translate"></app-material>
  <div class="row col-mb-3">
    <div class="col-12 align-right">
      <ng-container *ngTemplateOutlet="customButtons"></ng-container>
    </div>
  </div>
</div>

<ng-template #customButtons>
    <button (click)="createNewThirdPartyProduct()" class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{'button.add.thirdPartyProduct' | translate}}
    </button>
    <button (click)="createNewBaseIngredient()" class="btn btn-primary create-new-base-ingredient-button">
        <fa-icon icon="plus"></fa-icon> {{'button.add.baseIngredient' | translate}}
    </button>
</ng-template>

