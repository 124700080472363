import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from '../../model/pageable';
import { Slice } from '../../model/pim-response/slice';
import { TemplateViewList } from '../../model/custom-view/template-view-list/template-view-list';
import { MapTypeaheadService } from './map-typeahead.service';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class TemplateViewListsMapTypeaheadService implements AbstractTypeaheadService<TemplateViewList>{

  constructor(
    private readonly mapTypeaheadService: MapTypeaheadService
  ) {}
  
  public typeaheadSearch(searchTerm: string, pageable: Pageable, totalList: TemplateViewList[]): Observable<Slice<TemplateViewList>> {
    return this.mapTypeaheadService.createSearchedInSlice(searchTerm, pageable, this.createTotalListMap(totalList));
  }

  public typeaheadFormatter(model: TemplateViewList) {
    return model.name;
  }

  private createTotalListMap(totalList: TemplateViewList[]): Map<string, TemplateViewList> {
    const totalListMap: Map<string, TemplateViewList> = new Map();

    totalList.forEach(templateViewList => totalListMap.set(
      _.toLower(templateViewList.name)
          + this.mapTypeaheadService.getDelimiter()
          + templateViewList.id, templateViewList))
    return totalListMap;
  }
}
