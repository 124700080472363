import { TemplateViewMaterial } from '../../document-creation/template-view-material';
import { RenderingOption } from '../../document-creation/rendering-option';
import { Document } from '../../document/document';

export class TemplateMaterialSaveRequest {
  public constructor(
    public templateViewMaterial: TemplateViewMaterial,
    public document: Document = null,
    public renderingOptions: RenderingOption[]
  ) {}
}
