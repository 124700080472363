import { Component, ViewChild } from '@angular/core';
import { Page } from '../../model/pim-response/page';
import { Category } from '../../model/category';
import { CategoryTreeComponent } from '../category-tree/category-tree.component';
import { CategoryTreeviewService } from '../../service/treeview/category-treeview.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.less']
})
export class CategoryComponent {

  public categories: Page<Category>;

  @ViewChild(CategoryTreeComponent, { static: false })
  private categoryTree: CategoryTreeComponent;

  public searchString: string;

  public onNodeClick = (id: string): void => {
    this.router.navigateByUrl('/categories/' + id);
  }

  public onNodeNewTabClick = (id: string): void => {
    window.open('#/categories/' + id);
  }

  constructor(
    private readonly router: Router,
    private readonly categoryTreeviewService: CategoryTreeviewService) { }

  public search() {
    this.categoryTree.search();
  }

  getCategoryTreeviewService(): CategoryTreeviewService {
    return this.categoryTreeviewService;
  }
}
