<div *ngIf="!!model" class="rendering-option-row mb-2">

  
  <div class="rendering-option-category-attribute" [class.non-matching]="!model.matching">
    <div class="form-check form-switch" [style.visibility]="model.matching ? 'visible' : 'hidden'">
      <input type="checkbox"
             class="form-check-input"
             id="rendering-options-switch-{{ model.customViewCategoryAttributeId }}"
             [(ngModel)]="model.enabled"
             [disabled]="isReadonly">
    </div>
    <typeahead-template-category-attribute-relatives [model]="model.categoryAttribute"></typeahead-template-category-attribute-relatives>
  </div>
</div>
