<div>
  <div *ngFor="let attributeValue of filteredAttributeValues; index as i">
    
    <div>
      <label class="boolean-attribute-edit-label">{{attribute.relationshipTypeLabel | localizedStringsFilter}}</label>
      <div *ngFor="let enumerationValue of attribute.enumerationValues">
        <div>
          <input [disabled]="readonly" type="radio" [name]="attributeValue?.id + '_' + i + '_enum_' + enumerationValue" [id]="attributeValue?.id + '_' + i + '_enum_' + enumerationValue"
            [ngModel]="getSelectedValue(attributeValue)" (ngModelChange)="enumerationChanged($event, attributeValue)"
            [value]="enumerationValue">
          <label class="option-label" [for]="attributeValue?.id + '_' + i + '_enum_' + enumerationValue">{{ enumerationValue }}</label>
        </div>

      </div>
    </div>

    <typeahead
        [inputLabel]="'label.typeahead.material' | translate"
        [inputName]="'document_type'"
        [inputDisabled]="readonly"
        [typeaheadFunction]="materialTypeahead"
        [resultFormatter]="materialTypeaheadFormatter"
        [inputFormatter]="materialTypeaheadFormatter"
        [model]="getMaterialForTypeahead(attributeValue)"
        (modelChange)="setMaterialFromTypeahead($event, attributeValue)">
    </typeahead>

    <icon-button *ngIf="!readonly" buttonType="delete" (buttonClick)="removeAttributeValue(attributeValue)" buttonHideLabel="true">
    </icon-button>
  </div>

  <icon-button *ngIf="!readonly" buttonType="add" (buttonClick)="addAttributeValue();$event.stopPropagation()"></icon-button>

</div>
