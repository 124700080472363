<div id="localized-string-edit-{{ !!label ? label : '' }}">
  <label *ngIf="!!label" class="localized-string-edit-label">{{ label }}</label>

  <div *ngIf="!findAvaliableLanguages().length" class="spinner-border spinner-border-sm ms-lg-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>

  <div *ngFor="let localizedString of localizedStringMap | keyvalue; let lastItem = last">

    <div class="input-group mb-2 custom-input-group">
      <div class="input-group-text" ngbTooltip="{{ localizedString.key.name | localizedStringsFilter }}" placement="top">
        {{ localizedString.key.name | localizedStringsFilter | truncateText }}
      </div>

      <input id="{{ localizedString?.key?.isoCode }}-{{ localizedStringEditComponentId }}localized-string-edit-input" *ngIf="inputType==='input'"
        title="Localized edit input"
        type="text"
        class="form-control"
        placeholder=""
        maxlength="{{ maxStringLength }}"
        [disabled]="readonly"
        [required]="(englishRequired && localizedString.key.isoCode == 'en') || atLeastOneRequiredAndNotFilled"
        [(ngModel)]="localizedString.value.value"
        (ngModelChange)="changeLocalizedStrings()">

      <textarea id="{{ localizedString?.key?.isoCode }}-{{ localizedStringEditComponentId }}localized-string-edit-input-textarea" *ngIf="inputType==='textarea'"
        title="Localized edit textarea"
        type="text"
        class="form-control"
        placeholder=""
        autosize
        maxlength="{{ maxStringLength }}"
        [minRows]="1"
        [maxRows]="10"
        [disabled]="readonly"
        [required]="(englishRequired && localizedString.key.isoCode == 'en') || atLeastOneRequiredAndNotFilled"
        [(ngModel)]="localizedString.value.value"
        (ngModelChange)="changeLocalizedStrings()">
      </textarea>

      <app-editor class="ls-editor-class" id="{{ localizedString?.key?.isoCode }}-{{ localizedStringEditComponentId }}localized-string-edit-input-textarea" *ngIf="inputType==='editor'"
                  title="Localized edit textarea"
                  [maxTextLength]="maxStringLength"
                  [readonly]="readonly"
                  [toolbarButtonsToShow]="['undo',
                             'redo',
                             'bold',
                             'italic',
                             'underline',
                             'subscript',
                             'superscript',
                             'insertUnorderedList',
                             'insertOrderedList',
                             'link',
                             'unlink',
                             'removeFormat',
                             'toggleEditorMode']"
                  [htmlContent]="localizedString.value.value"
                  (htmlContentChange)="changeLocalizedFormattedStrings(localizedString.value, $event)">
      </app-editor>

      <button *ngIf="!readonly" type="button" class="btn " id="{{ localizedString?.key?.isoCode }}-{{ localizedStringEditComponentId }}localized-string-edit-button-removeTranslation"
          title="{{ removeButtonText | translate }}"
          [class.btn-primary]="!isEnglishAndEnglishIsFixed(localizedString?.key?.isoCode)"
          [class.btn-secondary]="isEnglishAndEnglishIsFixed(localizedString?.key?.isoCode)"
          (click)="removeTranslation(localizedString.key)"
          [disabled]="isEnglishAndEnglishIsFixed(localizedString?.key?.isoCode)">
        <fa-icon icon="trash-can"></fa-icon>
      </button>
    </div>

    <div
      class="characters-left"
      id="{{ localizedString?.key?.isoCode }}-{{ localizedStringEditComponentId }}localized-string-edit-input-characters-left"
      *ngIf="showCharactersLeft">{{ calculateValueLength(localizedString) }}/{{ maxStringLength }}
    </div>

  </div>

  <div *ngIf="!readonly">
    <div class="input-group" *ngIf="findAvaliableLanguages().length">
      <select *ngIf="!readonly" id="{{ localizedStringEditComponentId }}localized-string-edit-languages-select"
        title="Select language"
        [(ngModel)]="selectedLanguageForAddTranslation"
        class="form-select">
        <option [selected]="selectedLanguageForAddTranslation?.id == item.id" *ngFor="let item of findAvaliableLanguages()" [ngValue]="item">{{ item.name | localizedStringsFilter }}</option>
      </select>
      <button *ngIf="!readonly" (click)="addTranslation()" class="btn btn-primary" id="{{ localizedStringEditComponentId }}localized-string-edit-button-addTranslation"
        title="{{ addButtonText | translate }}">
        <fa-icon icon="plus"></fa-icon> {{ addButtonText | translate }}
      </button>
    </div>
  </div>

</div>
