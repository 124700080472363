import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TableColumn } from '../../model/table-column';
import { LocalizedString } from '../../model/localized-string';
import { Page } from '../../model/pim-response/page';
import { LocalizedStringService } from '../../service/localized-string.service';
import { MaterialSearchService } from '../../service/material-search.service';
import { TableService } from '../../service/table.service';
import { Material } from '../../model/material';
import { MaterialType } from '../../model/material-type';
import { HighlightedMapEntry } from '../../model/pim-response/highlighted-map-entry';
import { OverviewService } from '../../service/overview.service';
import { MaterialStatusConversion } from '../../model/material-status-conversion';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.less']
})
export class MaterialComponent implements OnInit, OnDestroy {

  constructor(
    private readonly translateService: TranslateService,
    private readonly tableService: TableService,
    private readonly overviewService: OverviewService,
    private readonly localizedStringsService: LocalizedStringService,
    private readonly materialSearchService: MaterialSearchService,
    private readonly router: Router,
  ) {}

  @ViewChild('materialIdTemplate', { static: true })
  private readonly materialIdTemplate: TemplateRef<any>;

  @Input()
  public customButtons: TemplateRef<any>;

  @Input()
  public title = '';

  @Input()
  public editComponentUrl = '';


  public initSearchObject: Material;
  public searchInput = '';
  public selectedMaterialTypes: string[] = ['PRD'];
  public facetFields: string;
  public readonly typeaheadFieldLabelPrefix : string = 'label.material.overview.typeahead.';

  public tableColumns: TableColumn<any>[] = [
  ];
  public searchFunction: (queryParameters: Params) => Observable<Page<Material>> = (queryParameters: Params) => this.executeSearch(queryParameters);

  ngOnInit(): void {
    this.tableColumns = [
      new TableColumn('id', this.translateService.get('label.material.number'), null, false, true, null, false, this.materialIdTemplate),
      new TableColumn('type', this.translateService.get('label.material.type'), (type: MaterialType) => this.tableService.stringValueFunction(type), true, true),
      new TableColumn('name', this.translateService.get('label.material.name'),
        (localizedStrings: LocalizedString[]) => this.localizedStringsService.getLocalizedStringsValue(localizedStrings), false, true),
      new TableColumn('status', this.translateService.get('label.material.spass_status'),
        (status: string) => this.formatSpassStatus(status), true, true, (status: string) => this.formatSpassStatus(status), true),
      new TableColumn('goo', this.translateService.get('label.material.goo'), (goo: string) => this.tableService.stringValueFunction(goo), true, true),
      new TableColumn('productType', this.translateService.get('label.material.product_type'),
        (productType: string) => this.tableService.stringValueFunction(productType), true, true),
      new TableColumn('sdsVisibility', this.translateService.get('label.material.sds_visibility'),
        (sdsVisibility: string) => this.tableService.stringValueFunction(sdsVisibility), true, true),
      new TableColumn('categoriesAssigned', this.translateService.get('label.material.categories_assigned'),
        (categoriesAssigned: string) => this.tableService.stringValueFunction(categoriesAssigned), true, true,
        (categoriesAssigned: string) => this.tableService.stringValueFunction(categoriesAssigned), true),
      new TableColumn('documentsAssigned', this.translateService.get('label.material.documents_assigned'),
        (documentsAssigned: string) => this.tableService.stringValueFunction(documentsAssigned), true, true,
        (documentsAssigned: string) => this.tableService.stringValueFunction(documentsAssigned), true),
      new TableColumn('operatingDivision', this.translateService.get('label.material.od'), (od: string) => this.tableService.stringValueFunction(od), true, true),
    ]
    this.facetFields = this.tableColumns.filter(c => c.isFilterable).map(c => c.field).join(',');
  }

  ngOnDestroy(): void {
    this.overviewService.resetPage();
  }

  private formatSpassStatus(status: string): string {
    let returnValue: string = '';
    if(!!status && typeof status === 'string') {
      if (!MaterialStatusConversion[status]) {
        console.log(new Error(`formatSpassStatus: MaterialStatus for ${status} is not defined`));
        return 'UNKNOWN_STATUS';
      }
      returnValue = MaterialStatusConversion[status].getText();
      if (MaterialStatusConversion[status].getType() !== '') {
        returnValue = returnValue + ' (' + MaterialStatusConversion[status].getType() + ')';
      }
    }
    return returnValue;
  }

  public executeSearch(queryParameters: Params): Observable<Page<Material>> {
    return this.materialSearchService.overviewSearch(queryParameters);
  }

  public isIdOrName(h: HighlightedMapEntry[]) {
    if (!h) return false;
    return h.some((highlightedMapEntry) => highlightedMapEntry.field === 'name' || highlightedMapEntry.field === 'id')
  }

  public createNewThirdPartyProduct(): void {
    this.router.navigateByUrl('adminstration/material/newThirdPartyProduct');
  }

  public createNewBaseIngredient(): void {
      this.router.navigateByUrl('adminstration/material/newBaseIngredient');
  }

}
