import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UrlParameter } from '../../model/parameter/url-parameter';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';
import { UserFilterDataService } from './user-filter-data.service';


@Injectable({
  providedIn: 'root'
})
export class UserFilterInterfaceService {

  constructor(
    private userFilterDataService: UserFilterDataService) {
  }

  public get userFiltersInitializedBehaviourSubject(): BehaviorSubject<Boolean> {
    return this.userFilterDataService.userFiltersInitializedBehaviourSubject;
  }

  public get userFilterBehaviourSubject(): BehaviorSubject<UserFilterResponse> {
    return this.userFilterDataService.userFilterBehaviourSubject;
  }

  public clearUserFiltersInitialized(){
    this.userFilterDataService.clearUserFiltersInitializedBehaviourSubject();
  }

  public changeFilterType(filterType: string): void {
    this.userFilterDataService.changeFilterType(filterType);
  }

  public clearFilter(): void {
    this.userFilterDataService.clearFilter();
  }

  public updateParameters(searchParameters: UrlParameter[], sortParameter: UrlParameter, facetSearchParameters: UrlParameter[]){
    this.userFilterDataService.updateParameters(searchParameters, sortParameter, facetSearchParameters);
    this.userFilterDataService.changeFilter(null);     // Reset the filter because it will not be the same filter than it was defined anymore
  }
  
}
