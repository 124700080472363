import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { Category } from '../model/category';
import { environment } from '../../environments/environment';
import { Material } from '../model/material';
import { Pageable } from '../model/pageable';
import { Formulation } from '../model/formulation';
import { Document } from '../model/document/document';
import { ProductHierarchyObject } from '../model/product-hierarchy-object/product-hierarchy-object';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class CategoryUsageService {

  constructor(private httpClient: HttpClient) { }

  public findOverallUsageCount(category: Category): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.restUrl + '/category/usage/overall-usage/' + category.id, Constants.httpOptionsHidingLoadingIndicator);
  }

  public findAssignedMaterials(category: Category, pageable: Pageable): Observable<Page<Material>> {
    return this.httpClient.get<Page<Material>>(environment.restUrl + '/category/usage/material/' + category.id + '?' + pageable.toUrlString());
  }

  public findAssignedDocuments(category: Category, pageable: Pageable): Observable<Page<Document>> {
    return this.httpClient.get<Page<Document>>(environment.restUrl + '/category/usage/document/' + category.id + '?' + pageable.toUrlString());
  }

  public findAssignedFormulations(category: Category, pageable: Pageable): Observable<Page<Formulation>> {
    return this.httpClient.get<Page<Formulation>>(environment.restUrl + '/category/usage/formulation/' + category.id + '?' + pageable.toUrlString());
  }

  public findAssignedCategories(category: Category, pageable: Pageable): Observable<Page<Category>> {
    return this.httpClient.get<Page<Category>>(environment.restUrl + '/category/usage/category/' + category.id + '?' + pageable.toUrlString());
  }

  public findAssignedPHOs(category: Category, pageable: Pageable): Observable<Page<ProductHierarchyObject>> {
    return this.httpClient.get<Page<ProductHierarchyObject>>(environment.restUrl + '/category/usage/pho/' + category.id + '?' + pageable.toUrlString());
  }
}
