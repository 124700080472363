import { Injectable } from '@angular/core';
import { FormValidationService } from '../form-validation.service';
import { NotificationService } from '../notification.service';
import { CategoryFormulation } from '../../model/category-formulation';
import { environment } from '../../../environments/environment';
import { AttributeType } from '../../model/attribute-type';


@Injectable()
export class CategoryFormulationFormValidationService extends FormValidationService {

  constructor(protected notificationService: NotificationService) {
    super(notificationService);

    this.addValidation(this.verifyLocalizedStringTooLong);
  }

  public verifyLocalizedStringTooLong(validation: CategoryFormulationFormValidationService, obj: CategoryFormulation[]): boolean {

    let valid = true;
    const LIMIT = environment.localizedStringMaxLength;
    for ( const categoryFormulation of obj ) {
      for (const attributeValue of categoryFormulation.attributeValues ) {
        if ( attributeValue.attribute.type === AttributeType.LOCALIZED_STRING ) {
          for ( const localizedString of attributeValue.localizedString ) {
            if ( localizedString.value?.length > LIMIT ) {
              valid = false;
              validation.addError('label.attribute.localized_string_is_too_long');
              break;
            }
          }
        }
      }
    }

    return valid;
  }

}

