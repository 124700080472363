import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import InjectIsNotAdvancedUser from '../../../../../decorator/inject-is-not-advanced-user.decorator';
import { RenderingOption } from '../../../../../model/document-creation/rendering-option';
import { TemplateViewList } from '../../../../../model/custom-view/template-view-list/template-view-list';
import { TemplateViewListCategoryAttribute } from '../../../../../model/custom-view/template-view-list/template-view-list-category-attribute';
import { Pageable } from '../../../../../model/pageable';
import { DocumentCreationDataService } from '../../../../../service/data-service/document-creation-data.service';
import { TemplateViewListsMapTypeaheadService } from '../../../../../service/typeahead/template-view-lists-map-typeahead.service';

@Component({
  selector: 'tab-rendering-options',
  templateUrl: './tab-rendering-options.component.html',
  styleUrls: ['./tab-rendering-options.component.less']
})
export class TabRenderingOptionsComponent implements OnInit {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  @Input()
  public selectedTemplateViewList: TemplateViewList = null;

  private allRenderingOptions: RenderingOption[];
  public renderingListOptions: RenderingOption[];

  public get renderingOptionsBehaviourSubject(): BehaviorSubject<RenderingOption[]> {
    return this.documentCreationDataService.renderingOptionsBehaviorSubject;
  }

  public get templateViewListsBehaviourSubject(): BehaviorSubject<TemplateViewList[]> {
    return this.documentCreationDataService.templateViewListsBehaviourSubject;
  }

  public get someROsInLists(): BehaviorSubject<boolean> {
    return this.documentCreationDataService.someROsInLists;
  }

  constructor(
    private readonly documentCreationDataService: DocumentCreationDataService,
    private readonly templateViewListsMapTypeaheadService: TemplateViewListsMapTypeaheadService
  ) { }


  ngOnInit(): void {
  }

  public get renderingMaterialAttributesOptions(): Observable<RenderingOption[]> {
    return this.renderingOptionsBehaviourSubject.pipe(map(renderingOptions => {
        // To avoid not matching options being modified in the list, we disable all of them
        this.allRenderingOptions = this.disableNotMatchingOptions(renderingOptions);
        return _.sortBy(renderingOptions.filter(ro => ro.templateViewListId == null), (ro: RenderingOption) => !ro.matching, (ro: RenderingOption) => !ro.enabled)
      }));
  }

  public templateViewListsTypeahead = (searchTerm: string, pageable: Pageable) =>
        this.templateViewListsMapTypeaheadService.typeaheadSearch(searchTerm, pageable, this.templateViewListsBehaviourSubject.value);
  public templateViewListsTypeaheadFormatter = (input: any): string => this.templateViewListsMapTypeaheadService.typeaheadFormatter(input);

  public onTemplateViewListChange(templateViewList: TemplateViewList){
    this.renderingListOptions = this.allRenderingOptions.filter(ro => ro.templateViewListId == templateViewList.id);
    this.recalculateDefaultOrderAndArrayOrder();
  }

  public drop = (event: CdkDragDrop<TemplateViewListCategoryAttribute[]>) => {
    // To avoid corrupted order we manipulate the defaultOrder and then recalculate it
    // If the item is moved up, we add 0.5 to the defaultOrder, if it is moved down, we add 1.5 to assure that the order is always correct
    this.renderingListOptions[event.previousIndex].order = event.currentIndex + (event.previousIndex > event.currentIndex ? 0.5 : 1.5);

    this.recalculateDefaultOrderAndArrayOrder();
  }

  public onEnableChange = (model: TemplateViewListCategoryAttribute) => {
    this.recalculateDefaultOrderAndArrayOrder();
  }

  private recalculateDefaultOrderAndArrayOrder() {
    const orderedArray = _.orderBy(this.renderingListOptions, ['matching','enabled', 'order'], ['desc', 'desc', 'asc']);
    orderedArray.forEach((step, index) => step.order = index + 1);
    // refilling the array without overwriting the reference
    this.renderingListOptions.splice(0, this.renderingListOptions.length, ...orderedArray);
  }

  private disableNotMatchingOptions(renderingOptions: RenderingOption[]): RenderingOption[]{
    renderingOptions.filter(ro => !ro.matching).forEach(ro => ro.enabled = false);
    return renderingOptions;
  }

}
