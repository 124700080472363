import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UrlParameter } from '../model/parameter/url-parameter';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { Document } from '../model/document/document';
import { Params } from '@angular/router';
import { UrlParameterService } from './url-parameter.service';
import { SortType } from '../model/sort-type';
import { SearchDownloadService } from './search-download.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentSearchService {
  private lastQueryParameterString: string = null;
  private lastTotalElements: number = 0;

  public get processingDownloadSearch(): BehaviorSubject<boolean> {
    return this.searchDownloadService.processingDownloadSearch
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly searchDownloadService: SearchDownloadService,
    private readonly urlParameterService: UrlParameterService) { }

  public search(queryParameters: Params): Observable<Page<Document>> {
    //TODO: encode search values as base64: btoa(searchString)
    this.lastQueryParameterString = this.urlParameterService.convertQueryParametersToString(queryParameters);
    const url: string = `${environment.restUrl}/document/search/v3${this.lastQueryParameterString}`;
    return this.httpClient.get<Page<Document>>(url).pipe(tap({
      next: (page: Page<Document>) => {
        this.lastTotalElements = page.totalElements;
      }
    }));
  }

  public downloadExcelResult() {
    const url: string = `${environment.restUrl}/document/downloadSearch${this.lastQueryParameterString}`;
    this.searchDownloadService.downloadExcelResult(url, this.lastQueryParameterString, this.lastTotalElements);
  }

}
