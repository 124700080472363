<document-version-edit [availableLanguages]="getLanguages()"
  [context]="documentEditContext.DOCUMENT_CREATION_BRIDGE"></document-version-edit>

<div class="row">
  <div
    *ngIf="!!(documentVersionBehaviorSubject | async) && (templateViewCategoryAttributes | async)?.length > 0 && getAttributesToBeShown(documentVersionBehaviorSubject | async).length > 0">
    <div>
      <div class="custom-border-top"></div>
    </div>
    <app-attributes
      [showInfoButton]="true"
      [categoryRelationAttributes]="getAttributesToBeShown(documentVersionBehaviorSubject | async)"
      [showVisibility]="false"
      [readonly]="(isNotAdvancedUser | async) || !!(documentVersionBehaviorSubject | async).id"></app-attributes>
  </div>
</div>

<div class="row">
  <div class="col-12 align-right">
    <icon-button id="version-cancel"
                 buttonType="cancel"
                 (buttonClick)="cancelVersion()"></icon-button>
    <icon-button id="create-draft"
                 *ngIf="!!(documentVersionBehaviorSubject | async) && !(isNotAdvancedUser | async) && !(documentVersionBehaviorSubject | async).id"
                 buttonType="add version as draft"
                 [buttonLabel]="'button.create.version.draft' | translate" [buttonTitle]="'button.create.version.draft.title' | translate"
                 (buttonClick)="createDraftVersion()"></icon-button>
    <icon-button id="create-version-from-draft"
                 *ngIf="!!(documentVersionBehaviorSubject | async) && !(isNotAdvancedUser | async) && !!(documentVersionBehaviorSubject | async).id && !isVersionExpired()
                    && isDraftVersion((documentVersionBehaviorSubject | async))"
                 buttonType="add"
                 [buttonLabel]="'button.create.version.from.draft' | translate" [buttonTitle]="'button.create.version.from.draft.title' | translate"
                 (buttonClick)="createVersion()"></icon-button>
    <icon-button id="create-version-from-draft-and-publish"
                 *ngIf="!!(documentVersionBehaviorSubject | async) && !(isNotAdvancedUser | async) && !!(documentVersionBehaviorSubject | async).id && !isVersionExpired()
                    && isDraftVersion((documentVersionBehaviorSubject | async))"
                 buttonType="add and publish"
                 [buttonLabel]="'button.create.version.from.draft.and.publish' | translate" [buttonTitle]="'button.create.version.from.draft.and.publish.title' | translate"
                 (buttonClick)="createAndPublish()"></icon-button>
    <icon-button id="create"
                 *ngIf="!!(documentVersionBehaviorSubject | async) && !(isNotAdvancedUser | async) && !(documentVersionBehaviorSubject | async).id"
                 buttonType="add"
                 [buttonLabel]="'button.create.version' | translate" [buttonTitle]="'button.create.version.title' | translate"
                 (buttonClick)="createVersion()"></icon-button>
    <icon-button id="create-and-publish"
                 *ngIf="!!(documentVersionBehaviorSubject | async) && !(isNotAdvancedUser | async) && !(documentVersionBehaviorSubject | async).id"
                 buttonType="add and publish"
                 [buttonLabel]="'button.create.publish' | translate" [buttonTitle]="'button.create.publish.title' | translate"
                 (buttonClick)="createAndPublish()"></icon-button>
    <icon-button id="save-version"
                 *ngIf="!!(documentVersionBehaviorSubject | async) && !(isNotAdvancedUser | async) && !!(documentVersionBehaviorSubject | async).id && !isVersionExpired()
                    && !isDraftVersion((documentVersionBehaviorSubject | async))"
                 buttonType="save"
                 [buttonLabel]="'button.save.version' | translate" [buttonTitle]="'button.save.version.title' | translate"
                 (buttonClick)="saveVersion()"></icon-button>
    <icon-button id="save-and-publish"
                 *ngIf="!!(documentVersionBehaviorSubject | async)
                    && !(isNotAdvancedUser | async)
                    && !!(documentVersionBehaviorSubject | async).id
                    && (documentVersionBehaviorSubject | async).status === documentVersionStatus.NOT_PUBLISHED"
                 buttonType="save and publish"
                 [buttonLabel]="'button.save.publish' | translate"  [buttonTitle]="'button.save.publish.title' | translate"
                 (buttonClick)="saveAndPublish()"></icon-button>
  </div>
</div>
