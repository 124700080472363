import {AfterContentInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Attribute } from '../../model/attribute';
import { AttributeValue } from '../../model/attribute-value';
import { CategoryMaterial } from '../../model/category-material';
import { CategoryFormulation } from '../../model/category-formulation';
import { CategoryProductHierarchyObject } from '../../model/category-product-hierarchy-object';
import { environment } from '../../../environments/environment';
import { AttributeValueService } from '../../service/attribute-value.service';
import * as _ from 'lodash';
import InjectIsReadonlyUser from '../../decorator/inject-is-readonly-user.decorator';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import { CategoryAttributeAttributeValue } from '../../model/category-attribute-attribute-value';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.less']
})
export class AttributesComponent implements OnInit, OnDestroy, AfterContentInit {
  get categoryRelationAttributes(): [(CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue), Attribute][] {
    return this._categoryRelationAttributes;
  }

  @Input()
  set categoryRelationAttributes(value: [(CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue), Attribute][]) {
    if(!(_.isEqual(JSON.stringify(this._categoryRelationAttributes), JSON.stringify(value)))) {
      this._categoryRelationAttributes = value;
      this.createAttributeRowsMap();
    }
  }

  private readonly _separator = '____';

  public attributeRowsMap = new BehaviorSubject(new Map<string, [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][]>());
  public allAttributesFlat: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][] = [];
  private subscriptions: Subscription = new Subscription();

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public readonly = false;

  @Input()
  public showInfoButton = false;

  private _categoryRelationAttributes: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][] = [];

  @Input()
  public readonlyVisibility = false;

  @Input()
  public showVisibility = true;

  @Input()
  public areAttributeValuesMandatory = false;

  @Input()
  public sortAttributesFunction: (categoryRelationAttributes: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][]) =>
    [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][];

  @Input()
  public preSortAttributesFunction: (categoryRelationAttributes: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]) =>
    number;

  @Input()
  public isDisabled = (categoryRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, attribute: Attribute): boolean => false;

  constructor(
    private readonly attributeValueService: AttributeValueService,
  ) { }

  ngOnInit(): void {
    // needed for decorator
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.createAttributeRowsMap();
  }

  public defaultSortAttributesFunction = (categoryRelationAttributes: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][]) => {
    return _.sortBy(categoryRelationAttributes, (categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]) => {
        return !!this.preSortAttributesFunction ? this.preSortAttributesFunction(categoryRelationAttribute) : true;
      },(categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]) => {
        return (categoryRelationAttribute[1].type);
      },(categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]) => {
        return this.isAttributeWithParameters(categoryRelationAttribute[1]);
      });
  }

  public createAttributeRowsMap() {
    this.attributeRowsMap.value.clear();
    this.attributeRowsMap.next(this.attributeRowsMap.value);
    this.allAttributesFlat = this.getSortedAttributesFlat();
    let i: number = 0;
    let attributeType = null;
    let preSortCondition: number = null;

    this.allAttributesFlat.forEach((categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]) => {
      if (this.newRowIndicated(attributeType, categoryRelationAttribute, preSortCondition)) {
        i = i + 1;
        attributeType = categoryRelationAttribute[1].type;
        preSortCondition = !!this.preSortAttributesFunction ? this.preSortAttributesFunction(categoryRelationAttribute) : null;
      }
      this.addToAttributeRowsMap(i.toString() + this._separator + attributeType, categoryRelationAttribute);
    });
    this.attributeRowsMap.next(this.attributeRowsMap.value);
  }

  private addToAttributeRowsMap(keyString: string,
                                categoryRelationAttribute: [(CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue), Attribute]) {
    if (!this.attributeRowsMap.value.has(keyString)) {
      this.attributeRowsMap.value.set(keyString, []);
    }
    this.attributeRowsMap.value.get(keyString).push(categoryRelationAttribute);
  }

  private getSortedAttributesFlat(): [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute][] {
    return !!this.sortAttributesFunction ? this.sortAttributesFunction(this._categoryRelationAttributes) : this.defaultSortAttributesFunction(this._categoryRelationAttributes);
  }

  private newRowIndicated(attributeType,
                          categoryRelationAttribute: [(CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue), Attribute],
                          preSortCondition: number) {

    return attributeType !== categoryRelationAttribute[1].type || !!this.preSortAttributesFunction && this.preSortAttributesFunction(categoryRelationAttribute) !== preSortCondition;
  }

  public keepOrderInMap = (a: KeyValue<string,any>, b: KeyValue<string,any>): number => {
    // otherwise the order is not kept. The default ordering for the ngFor directive for keyValues is alphabetical on the key.
    // e.g. per default 12STRING will be before 2ENUMERATION
    return this.getNumberFromMapKey(a.key) < this.getNumberFromMapKey(b.key) ? -1 : 1;
  }

  private getNumberFromMapKey(key: string): number {
    return parseInt(key.split(this._separator)[0], 10);
  }

  public getAttributeValueByAttribute = (
    categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]): AttributeValue => {

    if(!this.isMultivalueAttribute(categoryRelationAttribute[1])) {
      return categoryRelationAttribute[0].attributeValues.find(attributeValue => attributeValue.attribute.id === categoryRelationAttribute[1].id);
    }
  }

  public getAttributeValuesByAttribute =
    (categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]): AttributeValue[] => {

    if(this.isMultivalueAttribute(categoryRelationAttribute[1])) {
      return categoryRelationAttribute[0].attributeValues.filter(attributeValue => attributeValue.attribute.id === categoryRelationAttribute[1].id);
    }
  }

  public isShortAttribute(attribute: Attribute) {
    return this.attributeValueService.shortAttributeTypes.includes(attribute.type);
  }

  public isReadonly(categoryRelationAttribute: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]): boolean {
    return this.isCategoryRelationOfSpassOrBasis(categoryRelationAttribute[0]) || this.readonly;
  }

  public isCategoryRelationOfSpassOrBasis(attributeValueRelation: CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue): boolean {
    if (attributeValueRelation instanceof CategoryAttributeAttributeValue || !attributeValueRelation.category) {
      return false;
    }
    return attributeValueRelation.category.id === environment.basisCategoryId || attributeValueRelation.category.id === environment.spassCategoryId;
  }

  public isAttributeWithParameters(attribute: Attribute): boolean {
    return attribute.attributeParameters?.length > 0;
  }

  private isMultivalueAttribute(attribute: Attribute): boolean {
    return this.isAttributeWithParameters(attribute) || this.attributeValueService.isMultivalueAttribute(attribute);
  }

  // Because of an assumed bug in Angular, I need this layer of indirection to make the isDisabled function work.
  // Calling isDisabled(categoryRelationAttribute2[0], categoryRelationAttribute2[1]) directly in the template does not work.
  // It is for some reason calling this method then always with the same attribute while being in the ngFor loop.
  public isReallyDisabled = (categoryRelationAttribute2: [CategoryMaterial | CategoryFormulation | CategoryProductHierarchyObject | CategoryAttributeAttributeValue, Attribute]): boolean => {
    return this.isDisabled(categoryRelationAttribute2[0], categoryRelationAttribute2[1]);
  }
}
