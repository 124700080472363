<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal" *ngIf="attributeCluster.id; else addHeadline">{{ 'headline.administration.maintainAttributeCluster' | translate }}</h2>
  
    <ng-template #addHeadline>
      <h2 class="display-6 fw-normal">{{ 'headline.administration.addAttributeCluster' | translate }}</h2>
    </ng-template>
  
    <form>
      <div class="row">
        <div class="col-4 mb-3">
          <localized-string-edit id="attribute-cluster-name" [localizedStrings]="attributeCluster.name" englishRequired="true" [label]="'label.administration.name'|translate"></localized-string-edit>
        </div>
        <div class="col-4 mb-3 form-group typeahead-container" >
          <typeahead
              [inputLabel]="'label.administration.attribute.cluster.parent' | translate"
              [inputName]="'parent'"
              [typeaheadId]="'attribute-cluster-parent-id'"
              [(model)]="attributeCluster.parent"
              [typeaheadFunction]="clusterTypeahead"
              [resultFormatter]="clusterTypeaheadFormatter"
              [inputFormatter]="clusterTypeaheadFormatter">
          </typeahead>
        </div>
      </div>
      <div class="row">
        <div class="col-8 form-group">
          <label for="description">{{'label.administration.attribute.cluster.description' | translate}}</label>
          <textarea type="text" class="form-control" id="description" name="description" [(ngModel)]="attributeCluster.description"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-8 mb-3 form-group">

          <label>{{'attributes' | translate}}</label>
          <div class="value-list">
            <div *ngFor="let attribute of getAttributes(attributeCluster); let i = index">
              <div *ngIf="attribute" class="custom-input-group-text mb-2 value-template-background clickable" (click)=onNodeClick(attribute.id)>
                {{ getLocalizedString(attribute.name) }}
                <div class="clickable iconlist">
                  <fa-icon icon="link"></fa-icon>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 align-right">
          <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
          <icon-button *ngIf="attributeCluster.id" buttonType="save" (buttonClick)="save()"></icon-button>
          <icon-button *ngIf="!attributeCluster.id" buttonType="add" (buttonClick)="save()"></icon-button>
        </div>
      </div>
    </form>
  </div>