import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Pageable} from '../../../../model/pageable';
import {ConfirmService} from '../../../../service/confirm.service';
import {AttributeParameter} from '../../../../model/attribute-parameter';
import {LocalizedString} from '../../../../model/localized-string';
import {AttributeParameterType} from '../../../../model/attribute-parameter-type';
import {Unit} from '../../../../model/unit';
import {LocalizedEnumerationValue} from '../../../../model/localized-enumeration-value';
import {AttributeParameterLocalizedEnumeration} from '../../../../model/attribute-parameter-localized-enumeration';
import {UnitTypeaheadService} from '../../../../service/typeahead/unit-typeahead.service';
import {NotificationService} from '../../../../service/notification.service';
import {LocalizedStringService} from '../../../../service/localized-string.service';
import {AttributeParameterService} from '../../../../service/attribute-parameter.service';
import {BackendValidationService} from '../../../../service/form-validation/backend-validation.service';
import {AttributeParameterUsageService} from '../../../../service/attributeparameter-usage.service';
import {HasUnsavedChangesGuard} from '../../../../guards/has-unsaved-changes.guard';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'attributeparameter-administration-edit',
  templateUrl: './attributeparameter-administration-edit.component.html',
  styleUrls: ['./attributeparameter-administration-edit.component.less']
})
export class AttributeParameterAdministrationEditComponent implements HasUnsavedChangesGuard {
  public attributeParameter: any = new AttributeParameter();
  public attributeParameterOrig: any = null;
  public parameterTypes: string[] = [...Object.keys(AttributeParameterType).filter(v => v != AttributeParameterType.STRING) ]; // String is ans should not be used as of now
  public attributeParameterInUse: boolean = false;
  public showUsages = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly unitTypeaheadService: UnitTypeaheadService,
    private readonly attributeParameterService: AttributeParameterService,
    private readonly confirmService: ConfirmService,
    private readonly notificationService: NotificationService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly backendValidationService: BackendValidationService,
    private readonly attributeParameterUsageService: AttributeParameterUsageService
  ) {

    this.route.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'newAttributeParameter') {
            this.attributeParameter = new AttributeParameter();
        } else {
          this.showUsages = false;
          this.attributeParameterService.load(params.id)
            .pipe(this.backendValidationService.renderErrorMessages())
            .subscribe((attributeParameter: any)=> {
              if (attributeParameter.type == AttributeParameterType.LOCALIZED_ENUMERATION){
                let aple = attributeParameter as AttributeParameterLocalizedEnumeration;
                this.attributeParameter = aple;
              } else {
                let ap = attributeParameter as AttributeParameter;
                this.attributeParameter = ap;
              }
              this.attributeParameterOrig = cloneDeep(this.attributeParameter);
              this.attributeParameterUsageService.findOverallUsageCount(attributeParameter).subscribe(result => this.attributeParameterInUse = result);
            });
        }
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.attributeParameterChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public getLocalizedString: (ls: LocalizedString[]) => (string) = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  public unitTypeahead = (searchTerm: string, pageable: Pageable) => this.unitTypeaheadService.typeaheadSearch(searchTerm, pageable);

  public unitTypeaheadFormatter = (model: Unit) => this.unitTypeaheadService.typeaheadFormatter(model);

  public save = () => {
    this.attributeParameterService.save(this.attributeParameter).subscribe((attributeParameter: AttributeParameter) => {
      this.notificationService.addSuccessNotification('label.successfully.saved');
      this.attributeParameter = attributeParameter;
      this.attributeParameterOrig = cloneDeep(this.attributeParameter);
      this.reloadItem();
    });
  }

  public delete = () => {
    this.confirmService.confirm('title.confirm.delete', 'text.confirm.attribute.parameter.deletion', 'button.yes', 'button.no').then(confirmResult => {
      if (confirmResult) {
        this.attributeParameterService.delete(this.attributeParameter)
          .pipe(this.backendValidationService.renderErrorMessages())
          .subscribe(() => {
            this.notificationService.addSuccessNotification('label.successfully.deleted');
            this.navigateToAttributes();
          })
      }
    });
  };

  public isDeleteDisabled() {
    return !this.attributeParameter.id || this.attributeParameterInUse;
  }

  public cancel() {
    this.navigateToAttributes();
  }

  public localizedEnumerationValuesChange(enumerationValues: LocalizedEnumerationValue[], attributeParameter: AttributeParameterLocalizedEnumeration): void {
    attributeParameter.localizedEnumerationValues = enumerationValues;
  }

  public typeChange(): void{
    if (this.attributeParameter.type == AttributeParameterType.LOCALIZED_ENUMERATION && this.attributeParameter.type != typeof AttributeParameterLocalizedEnumeration){
      this.attributeParameter = this.attributeParameter as AttributeParameterLocalizedEnumeration;
      this.attributeParameter.localizedEnumerationValues = [];
    }
  }

  private navigateToAttributes() {
    this.router.navigateByUrl('adminstration/attribute');
  }

  private reloadItem() {
    // Forcing the reload
    this.router.navigateByUrl('adminstration/attribute/parameter/' + this.attributeParameter.id)
  }

  private attributeParameterChanges(): boolean {
    if (!this.attributeParameterService.areEqual(this.attributeParameterOrig, this.attributeParameter)) {
      return true;
    } else {
      return false;
    }
  }

}
