<div *ngIf="!!(document | async)" class="container-fluid pb-3">
  <div>
    <h2 class="display-6 fw-normal">{{ (!!(document | async) && !!(document | async).id) ? ('headline.documents.edit' | translate) : ('headline.documents.add' | translate) }}</h2>
    <div id="document-status" class="document-status" [ngClass]="getStatusClass(document | async)">
      {{ getDocumentTag(document | async) | lowercase | translate }}
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="selectedTab" >

    <li [ngbNavItem]="'properties'" [routerLink]="'properties'" [queryParams]="params" id="properties">
      <a ngbNavLink>{{ 'tab.document.properties' | translate }}</a>
    </li>

    <li [ngbNavItem]="'versions'" [routerLink]="'versions'" [queryParams]="params" id="versions">
      <a ngbNavLink>{{ 'tab.document.versions' | translate }}</a>
    </li>

    <li [ngbNavItem]="'relations'" [routerLink]="'relations'" [queryParams]="params" id="relations">
      <a ngbNavLink>{{ 'tab.document.relations' | translate }}</a>
    </li>

  </ul>

  <div class="border-router-outlet">
    <router-outlet></router-outlet>
  </div>

  <div class="row mb-3 mt-3" *ngIf="!editVersion">
    <div class="col-12 align-right">
      <icon-button id="document-cancel" *ngIf="!cancelUrl" buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button id="document-return" *ngIf="!!cancelUrl" buttonType="return" (buttonClick)="cancel()"></icon-button>
      <icon-button id="document-save" *ngIf="(document | async).id && isDocumentPublished(document | async) && !(isReadOnlyUser | async)" buttonType="save" (buttonClick)="onSaveButtonClicked()"></icon-button>
      <icon-button id="document-add-as-not-published" *ngIf="!(document | async).id && !(isReadOnlyUser | async)" buttonType="add doc as not published" (buttonClick)="onSaveButtonClicked()"></icon-button>
      <icon-button id="document-save-not-published" *ngIf="(document | async).id && !isDocumentPublished(document | async) && !(isReadOnlyUser | async)" buttonType="save not published" (buttonClick)="onSaveButtonClicked()"></icon-button>
      <icon-button id="document-save-and-publish" *ngIf="(document | async).id && !isDocumentPublished(document | async) && !(isReadOnlyUser | async)" buttonType="save and publish" (buttonClick)="saveAndPublish()"></icon-button>
      <icon-button id="document-add-and-publish" *ngIf="!(document | async).id && !(isReadOnlyUser | async)" buttonType="add and publish" (buttonClick)="saveAndPublish()"></icon-button>
    </div>
  </div>

</div>
