import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numeric]'
})

export class NumericInputDirective {

  @Input()
  numericType: string; // number | decimal

  private regex = {
    number: new RegExp(/^(-?)\d+$/),
    decimal: new RegExp(/^(-?)([0-9]+){0,1}([\.,][0-9]*){0,1}$/g)
  };

  private specialKeys = {
    number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Insert', 'Delete'],
    decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Insert', 'Delete'],
  };

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const position: number = this.el.nativeElement.selectionStart;
    // let next: string = current.concat(event.key);
    const next: string = [current.slice(0, position), event.key, current.slice(position)].join('');

    if (next && !String(next).match(this.regex[this.numericType])) {
      event.preventDefault();
    } else {
      if (event.key && event.key.indexOf(',') !== -1) {
        this.el.nativeElement.value = this.el.nativeElement.value + event.key.replace(',', '.');
        event.preventDefault();
      }
    }
  }
}
