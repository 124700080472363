import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Category } from '../../model/category';
import { Pageable } from '../../model/pageable';
import { TypeaheadCommonRequest } from '../../model/request/typeahead-common-reqest';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';

@Injectable({
  providedIn: 'root', deps: [HttpClient]
})
export class CategoryTypeaheadService implements AbstractTypeaheadService<Category> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, hideloadingindicator: boolean = true): Observable<Slice<Category>> {

    let body = new TypeaheadCommonRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage()
    );

    return this.baseTypeaheadService.createCall('/category/typeahead', body, pageable, hideloadingindicator);
  }

  public typeaheadFormatter(model: Category): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }

  public typeaheadFormatterParentPath(model: Category): string {
    if (model.parent) {
      return this.typeaheadFormatterParentPath(model.parent) + ' > ' + this.baseTypeaheadService.localizedStringFormatter(model.name);
    }
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }
}
