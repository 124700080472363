import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-advanced-administration',
  templateUrl: './advanced-administration.component.html',
  styleUrls: ['./advanced-administration.component.less']
})
export class AdvancedAdministrationComponent {

  protected readonly environment = environment;
}
