import {Component, OnInit} from '@angular/core';
import {Pageable} from '../../../../model/pageable';
import {Category} from '../../../../model/category';
import {CategoryType} from '../../../../model/category-type';
import {CategoryService} from '../../../../service/category.service';
import {CategoryOnLeafLevelTypeaheadService} from '../../../../service/typeahead/category-on-leaf-level-typeahead.service';
import {CategoryByTypeTypeaheadService} from '../../../../service/typeahead/category-by-type-typeahead.service';
import {CategoryProductHierarchyObject} from '../../../../model/category-product-hierarchy-object';
import {ProductHierarchyObjectService} from '../../../../service/product-hierarchy-object.service';
import {CategoryProductHierarchyObjectService} from '../../../../service/category-product-hierarchy-object.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'product-hierarchy-object-tab-categorization',
  templateUrl: './product-hierarchy-object-tab-categorization.component.html',
  styleUrls: ['./product-hierarchy-object-tab-categorization.component.less']
})
export class ProductHierarchyObjectTabCategorizationComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  constructor(
    private readonly categoryProductHierarchyObjectService: CategoryProductHierarchyObjectService,
    private readonly categoryOnLeafLevelTypeaheadService: CategoryOnLeafLevelTypeaheadService,
    private readonly categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private readonly categoryService: CategoryService,
    private readonly productHierarchyObjectService: ProductHierarchyObjectService
  ) {
  }

  ngOnInit(): void {
    this.productHierarchyObjectService.initiateCategoryLeafMap();
  }

  public categoryTypeaheadIsA = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.PRODUCT_GROUP], this.findProductGroups().map(cPho => cPho.category.id));

  public categoryTypeaheadCanBeUsedFor = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.APPLICATION], this.findApplications().map(cPho => cPho.category.id));

  public categoryTypeaheadRelevantForIndustries = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.INDUSTRY], this.findSelectedIndustries().map(cPho => cPho.category.id));

  public categoryTypeaheadMarketedTo = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.REGION], this.findRegions().map(cPho => cPho.category.id));

  public categoryTypeaheadCanSupportProcesses = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.PROCESS], this.findProcesses().map(cPho => cPho.category.id));

  public categoryTypeaheadCanOfferServices = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.SERVICE], this.findServices().map(cPho => cPho.category.id));

  public findProductGroups(): CategoryProductHierarchyObject[] {
    return this.findLeafCategoryPhos(CategoryType.PRODUCT_GROUP);
  }

  public findApplications(): CategoryProductHierarchyObject[] {
    return this.findLeafCategoryPhos(CategoryType.APPLICATION);
  }

  public findIndustries(): CategoryProductHierarchyObject[] {
    return this.findIndustryCategoryPhos();
  }

  public findRegions(): CategoryProductHierarchyObject[] {
    return this.findCategoryPhosByType(CategoryType.REGION);
  }

  public findProcesses(): CategoryProductHierarchyObject[] {
    return this.findLeafCategoryPhos(CategoryType.PROCESS);
  }

  public findServices(): CategoryProductHierarchyObject[] {
    return this.findLeafCategoryPhos(CategoryType.SERVICE);
  }

  public findSelectedIndustries(): CategoryProductHierarchyObject[] {
    return this.productHierarchyObjectService.findCategoryPhos().filter((categoryPho: CategoryProductHierarchyObject) =>
      categoryPho.category.type === CategoryType.INDUSTRY && categoryPho.manuallyMaintained
    );
  }

  private findCategoryPhosByType(categoryType: CategoryType): CategoryProductHierarchyObject[] {
    return this.productHierarchyObjectService.findCategoryPhos().filter((categoryPho: CategoryProductHierarchyObject) =>
      categoryPho.category.type === categoryType && categoryPho.manuallyMaintained
    );
  }

  private findLeafCategoryPhos(categoryType: CategoryType): CategoryProductHierarchyObject[] {
    return this.productHierarchyObjectService.findCategoryPhos().filter((categoryPho: CategoryProductHierarchyObject) =>
      categoryPho.category.type == categoryType && this.productHierarchyObjectService.leafCategories.get(categoryPho.category.id)
    );
  }

  private findIndustryCategoryPhos(): CategoryProductHierarchyObject[] {

    let categoryPhosToShow = new Set<CategoryProductHierarchyObject>();
    let leafCategoryPhos = this.findLeafCategoryPhos(CategoryType.INDUSTRY);

    leafCategoryPhos.forEach((cPho: CategoryProductHierarchyObject) => categoryPhosToShow.add(cPho));

    let nonIndustryLeafcategoryPhos: CategoryProductHierarchyObject[] = this.productHierarchyObjectService.findCategoryPhos()
      .filter((cPho: CategoryProductHierarchyObject) => cPho.category.type != CategoryType.INDUSTRY && this.productHierarchyObjectService.leafCategories.get(cPho.category.id));

    nonIndustryLeafcategoryPhos.forEach(cPho => {
      let category = this.firstAncestorOfType(CategoryType.INDUSTRY, cPho.category);
      if (!!category) {
        this.productHierarchyObjectService.findCategoryPhos().filter((cp: CategoryProductHierarchyObject) => cp.category.id == category.id)
          .forEach((cp: CategoryProductHierarchyObject) => {
              cp.isReadOnly = cp.manuallyMaintained ? false : true;
              categoryPhosToShow.add(cp);
            }
          );
      }
    });

    return Array.from(categoryPhosToShow);
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    return this.categoryService.createParentList(category, nodeList);
  }

  private firstAncestorOfType(categoryType: CategoryType, category: Category): Category {

    if (!category.parent) {
      return null;
    }

    if (category.parent.type == categoryType) {
      return category.parent
    }

    return this.firstAncestorOfType(categoryType, category.parent)
  }

  public addValue = (category: Category) => {
    this.categoryService.load(category.id).subscribe((fullCategory: Category) => {
      this.categoryProductHierarchyObjectService.createAndAddCategoryPhosWithAttributeValues(
        this.productHierarchyObjectService.findProductHierarchyObject(), fullCategory, this.productHierarchyObjectService.findCategoryPhos(), true);
        this.productHierarchyObjectService.addToLeafCategory(category, true);
    });
  }

  public deleteValue = (categoryPho: CategoryProductHierarchyObject) => {
    categoryPho.manuallyMaintained = false;
    this.categoryProductHierarchyObjectService.removeCategoryPhos(categoryPho.category, this.productHierarchyObjectService.findCategoryPhos());
    this.productHierarchyObjectService.initiateCategoryLeafMap();
  }

  public findProductHierarchyObject = () => this.productHierarchyObjectService.findProductHierarchyObject();
  public findCategoryPhos = () => this.productHierarchyObjectService.findCategoryPhos();
}
