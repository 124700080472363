import { LocalizedString } from './localized-string';
import { Attribute } from './attribute';

export class AttributeCluster {
    id: string;
    name: LocalizedString[] = [];
    parent: AttributeCluster;
    description: string;
    attributes: Attribute[] = [];
}
