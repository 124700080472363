import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomViewService } from '../../../../service/customview.service';
import { NotificationService } from '../../../../service/notification.service';
import { ConfirmService } from '../../../../service/confirm.service';

@Component({
  selector: 'typeahead-template-category-attribute-relatives',
  templateUrl: './category-attribute-relatives.html',
  styleUrls: ['./category-attribute-relatives.less']
})
export class TypeaheadCategoryAttributeRelativesTemplate implements OnInit {

  @Input()
  public model: any;

  @Input()
  public term: string = null;

  @Input()
  public showAttributeLink = true;

  @Input()
  public showParametername = false;

  public parameteranmeBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public editParametername: boolean = false;
  private _parameternameOrig: string = '';

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly customViewService: CustomViewService,
    private readonly notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    if (this.showParametername) {
      this.customViewService.findParameternameByCategoryAttribute(this.model.id).subscribe(parametername => {
        this._parameternameOrig = parametername;
        this.parameteranmeBehaviorSubject.next(parametername);
      }, error => console.log('parametername not found for category-attribute: ' + this.model.id));
    }
  }

  toggleEditParametername(): void {
    this.editParametername = !this.editParametername;
  }

  public createParentList(object: any, nodeList: any[] = []): any[] {
    if(!object) {
      return nodeList;
    }

    if (object.attribute){ // Category-attribute
      if (object.category.parent) {
        nodeList = this.createParentList(object.category.parent, nodeList);
      }
      nodeList.push(object.category);
      nodeList.push(object.attribute);
    }
    else { // Only Category
      if (object.parent) {
        nodeList = this.createParentList(object.parent, nodeList);
      }
      nodeList.push(object);
    }

    return nodeList;
  }

  newValue(value: string) {
    this.parameteranmeBehaviorSubject.next(value);
  }

  onSaveParametername() {
    if (!!this._parameternameOrig && this._parameternameOrig !== '') {
      this.confirmService.confirm('title.parametername.save', 'text.parametername.save', 'button.yes', 'button.no').then(result => {
        if (result) {
          this.saveParametername();
        } else {
          this.parameteranmeBehaviorSubject.next(this._parameternameOrig);
        }
      });
    } else {
      this.saveParametername();
    }
    this.toggleEditParametername()
  }

  saveParametername() {
    this.customViewService.saveParameternameByCategoryAttribute(this.model.id, this.parameteranmeBehaviorSubject.value).subscribe({
      next: (parametername) => {
        this._parameternameOrig = parametername;
        this.parameteranmeBehaviorSubject.next(parametername);
        this.notificationService.addSuccessNotification('Parametername successfully saved');
      }
    });
  }
}
