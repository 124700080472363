<div *ngIf="isCustomViewTypeTemplateView()">
  <div class="row">
    <div class="form-group col-12">
      <localized-string-edit [englishRequired]="false"
        [englishFixed]="false"
        [atLeastOneRequired]="true"
        [localizedStrings]="getTemplateFileNames()"
        [addButtonText]="'button.add.template.filename'"
        [removeButtonText]="'button.remove.template.filename'"
        [label]="'label.administration.customview.tab.template.filename' | translate"></localized-string-edit>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label>{{ 'label.administration.customview.tab.template.specific_attributes_for_template_view' | translate }}</label>
      <multiple-value-selector class="form-group col-12"
                               [values]="getTemplateCategoryAttributesTemplateViewSpecific()"
                               [typeahead]="attributesTypeaheadTemplateViewSpecific"
                               [inputName]="'attribute_service_TemplateViewSpecific'"
                               [inputRequired]="false"
                               [resultTemplate]="attributeResultTemplate"
                               [valueTemplate]="attributeValueTemplate"
                               [executeBeforeAdd]="addValueForTemplateView"
                               [executeBeforeDelete]="deleteValueForTemplateView"
                               deleteConfirmTitle="title.confirm.remove"
                               deleteConfirmText="text.relationship.category.attribute.deletion">
      </multiple-value-selector>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label>{{ 'label.administration.customview.tab.template.specific_attributes_for_document_version' | translate }}</label>
      <multiple-value-selector class="form-group col-12"
                               [values]="getTemplateCategoryAttributesDocumentVersionSpecific()"
                               [inputName]="'attribute_service_DocumentVersionSpecific'"
                               [typeahead]="attributesTypeaheadDocumentVersionSpecific"
                               [inputRequired]="false"
                               [resultTemplate]="attributeResultTemplate"
                               [valueTemplate]="attributeValueTemplate"
                               [executeBeforeAdd]="addValueForDocumentVersion"
                               [executeBeforeDelete]="deleteValueForDocumentVersion"
                               deleteConfirmTitle="title.confirm.remove"
                               deleteConfirmText="text.relationship.category.attribute.deletion">
      </multiple-value-selector>
    </div>
  </div>
  <div class="row">

    <div class="form-group col-12">
      <label>{{ 'label.administration.customview.tab.template.view.lists' | translate }}</label>

      <div *ngIf="!( templateViewListsSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <pim-grey-bar-stack
        id="pim-grey-bar-stack-customview-template-lists"
        [valueTemplate]="templateViewListValueTemplate"
        [values]="( templateViewListsSubject | async )"
        (valueRemoved)="deleteValueFortemplateViewList($event)"
        [idPrefix]="'customview-template-lists'">
      </pim-grey-bar-stack>

      <div *ngIf="!!( templateViewListsSubject | async )" class="template-view-list-input-group">
        <input id="template-view-list-input" [(ngModel)]="templateViewListInputString" type="text" class="form-control template-view-list-input">
        <icon-button class="template-view-list-input-button" buttonType="add" (buttonClick)="addValueForTemplateViewList(templateViewListInputString)"></icon-button>
      </div>

    </div>
  </div>
</div>

<ng-template #templateViewListValueTemplate
             let-result="result">
  <app-template-view-list-value-template class="category-attribute-template" [templateViewList]="result"></app-template-view-list-value-template>
</ng-template>

<ng-template #attributeValueTemplate
  let-result="result">
  <typeahead-template-category-attribute-relatives [showParametername]="true" [model]="result" class="category-attribute-template"></typeahead-template-category-attribute-relatives>
</ng-template>

<ng-template #attributeResultTemplate
  let-result="result"
  let-term="term">
  <typeahead-template-category-attribute-relatives [model]="result"
    [term]="term?.trim().split(' ')"
    [showAttributeLink]="false"></typeahead-template-category-attribute-relatives>
</ng-template>
