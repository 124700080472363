import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Notification } from '../model/notification';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class NotificationService {

  public notifications: Notification[] = [];
  private successLifetime = environment.notificationLifetime;

  constructor(private router: Router) {
    this.registerNavigationEndEventHandler();
  }

  public addSuccessNotification(message: string): Notification {
    // Close all others notifications (probably errors) before adding the success notification
    this.notifications = [];
    return this.addNotification(new Notification(message, 'success', true), this.successLifetime);
  }

  public addWarnNotification(message: string): Notification {
    return this.addNotification(new Notification(message, 'warning', true), this.successLifetime);
  }

  public addErrorNotification(message: string, keepAfterViewChange: boolean = false, lifetime?: number): Notification {
    return this.addNotification(new Notification(message, 'error', keepAfterViewChange), lifetime);
  }

  public addDynamicErrorNotification(message: string, keepAfterViewChange: boolean = false, dynamicContent: string[], lifetime?: number): Notification {
    return this.addNotification(new Notification(message, 'error', keepAfterViewChange, dynamicContent), lifetime);
  }

  public addInfoNotification(message: string): Notification {
    return this.addNotification(new Notification(message, 'info'));
  }

  public addNotification(notification: Notification, lifetime?: number): Notification {
    this.notifications.push(notification);
    if (lifetime) {
      setTimeout(() => this.removeNotification(notification), lifetime);
    }
    return notification;
  }

  public removeNotifications(notifications: Notification[]) {
    notifications.forEach((notification) => this.removeNotification(notification));
  }

  public removeNotification(notification: Notification) {
    const index = this.notifications.indexOf(notification, 0);
    if (index > -1) {
      this.notifications.splice(index, 1);
    }
  }

  private registerNavigationEndEventHandler() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(() => this.handelRoutingEvent());
  }

  private handelRoutingEvent() {
    this.notifications = this.notifications.filter(
      (notification: Notification) => notification.keepAfterViewChange
    );
  }

}
