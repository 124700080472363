import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FacetSelection } from '../model/facet-selection';
import { SortType } from '../model/sort-type';
import {FacetPage} from '../model/pim-response/facet-page';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {

  private _initSearchObject: any;

  public facets: Map<string, FacetSelection[]> = new Map<string, FacetSelection[]>();

  public sortDirection : Map<string, SortType> = new Map<string, SortType>();
  public page = new FacetPage();

    public get initSearchObject(): any {
      return this._initSearchObject;
    }

    public set initSearchObject(value: any) {
      if(!!value && ( typeof(value) === 'string' ) ) {
        value = _.replace(value, /,/g, ' ')
      }
      this._initSearchObject = value;
    }

    resetSorting() {
      this.sortDirection.forEach((sortType, key, map) => map.set(key, SortType.NONE))
    }

    resetFacets() {
      this.facets.forEach((facets: FacetSelection[]) => {
        facets.forEach((facet: FacetSelection) => {
          facet.selected = false
        })
      })

    }

    public resetPage() {
      this.page = new FacetPage();
    }
}
