import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { Attribute } from '../model/attribute';
import { Pageable } from '../model/pageable';
import { environment } from '../../environments/environment';
import { AttributeType } from '../model/attribute-type';
import { isEqual, orderBy } from 'lodash';
import { LocalizedStringService } from './localized-string.service';
import { AttributeParameter } from '../model/attribute-parameter';
import { BackendValidationService } from './form-validation/backend-validation.service';
import { AttributeParameterType } from '../model/attribute-parameter-type';
import { AttributeParameterLocalizedEnumeration } from '../model/attribute-parameter-localized-enumeration';
import { LocalizedEnumerationValue } from '../model/localized-enumeration-value'

@Injectable({
    providedIn: 'root'
})
export class AttributeParameterService {

    public selectedAttributeTypes: AttributeType[] = [];

    constructor(
      private httpClient: HttpClient,
      private localizedStringService: LocalizedStringService,
      private backendValidationService: BackendValidationService) {
    }

    public load(id: string): Observable<any> {
      return this.httpClient.get<any>(environment.restUrl + '/attribute/parameter/' + id);
    }

    public search(searchString: string, pageable: Pageable): Observable<Page<AttributeParameter>> {
      return this.httpClient.get<Page<AttributeParameter>>(environment.restUrl + '/attribute/parameter/search/' + btoa(searchString) + '?' + pageable.toUrlString());
    }

    public save(attributeParameter: any): Observable<any> {
      return this.httpClient.post<any>(environment.restUrl + '/attribute/parameter/save', attributeParameter)
        .pipe(this.backendValidationService.renderErrorMessages());
    }

    public delete(attributeParameter: AttributeParameter): Observable<AttributeParameter> {
      return this.httpClient.post<AttributeParameter>(environment.restUrl + '/attribute/parameter/delete', attributeParameter)
        .pipe(this.backendValidationService.renderErrorMessages());
    }

    public getOrderedAttributeIds(attributes: Attribute[]): any[] {
      return orderBy(attributes, ['id']).map(attribute => attribute.id);
    }

    public areEqual(attributeParameterOrig: any, attributeParameter: any): boolean {
      if (attributeParameterOrig != null && attributeParameter != null) {
        let paramOrigObj = this.getAttributeParameterObj(attributeParameterOrig);
        let paramObj = this.getAttributeParameterObj(attributeParameter);

        let equal = isEqual(paramOrigObj, paramObj);

        if (equal && attributeParameterOrig.type == AttributeParameterType.LOCALIZED_ENUMERATION){
          let apole = attributeParameterOrig as AttributeParameterLocalizedEnumeration;
          let aple = attributeParameter as AttributeParameterLocalizedEnumeration;
          let localizedEnumerationValuesOrig = this.getOrderedLocalizedEnumerationValue(apole.localizedEnumerationValues);
          let localizedEnumerationValues = this.getOrderedLocalizedEnumerationValue(aple.localizedEnumerationValues);
          equal = isEqual(localizedEnumerationValuesOrig, localizedEnumerationValues);
        }
        return equal;
      }
      return false;
    }

    private getAttributeParameterObj(attributeParameter: AttributeParameter): any {
      let attributeName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(attributeParameter.name);

      return {
        id: attributeParameter.id,
        name: attributeName,
        description: attributeParameter.internalDescription,
        attributeParameterUnitId: attributeParameter.unit?.id,
        type: attributeParameter.type
      }
    }

    public getOrderedLocalizedEnumerationValue(localizedEnumerationValues: LocalizedEnumerationValue[]): any[] {
      return orderBy(localizedEnumerationValues
        .map(localizedEnumerationValue => {
          let localizedEnumerationValueName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(localizedEnumerationValue.name);
          return {
            id: localizedEnumerationValue.id,
            order: localizedEnumerationValue.order,
            name: localizedEnumerationValueName
          }
        }), 'order');
    }
}
