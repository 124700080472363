import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, finalize, of } from 'rxjs';
import { Material } from '../model/material';
import { Page } from '../model/pim-response/page';
import { UrlParameterService } from './url-parameter.service';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { SearchDownloadService } from './search-download.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialSearchService {

  public get processingDownloadSearch(): BehaviorSubject<boolean> {
    return this.searchDownloadService.processingDownloadSearch;
  }

  private lastQueryParameterString: string = null;
  private lastTotalElements: number = 0;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly searchDownloadService: SearchDownloadService,
    private readonly urlParameterService: UrlParameterService,
    ) { }

  public overviewSearch(queryParameters: Params): Observable<Page<Material>> {
    this.lastQueryParameterString = this.urlParameterService.convertQueryParametersToString(queryParameters);
    const url: string = `${environment.restUrl}/material/v3/overviewSearch${this.lastQueryParameterString}`;
    return this.httpClient.get<Page<Material>>(url).pipe(tap({
      next: (response: Page<Material>) => {
        this.lastTotalElements = response.totalElements;
      }
    }));
  }

  public downloadExcelResult() {
    const url: string = `${environment.restUrl}/material/downloadSearch${this.lastQueryParameterString}`;
    this.searchDownloadService.downloadExcelResult(url, this.lastQueryParameterString, this.lastTotalElements);
  }
}
