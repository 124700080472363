export interface FileUploadInputs {
  field: string;
  label: string;
  mandatory: boolean;
}

export class MassMaintenanceModalConfig {

  constructor({
                modalTitle = undefined,
                templateDownloadHeader = 'massmaintenance.modal.generic.download.header',
                templateDownloadUpperText = 'massmaintenance.modal.generic.download.upper_text',
                templateDownloadLowerText = undefined,
                templateDownloadButton = 'massmaintenance.modal.generic.download.button',
                templateDownloadUrlPath = undefined,
                templateDownloadDisabled = false,
                exportHeader = 'massmaintenance.modal.generic.export.header',
                exportUpperText = 'massmaintenance.modal.generic.export.upper_text',
                exportLowerText = undefined,
                exportButton = 'massmaintenance.modal.generic.export.button',
                exportUrlPath = undefined,
                exportDisabled = false,
                importHeader = 'massmaintenance.modal.generic.import.header',
                importUpperText = 'massmaintenance.modal.generic.import.upper_text',
                importLowerText = 'massmaintenance-modal.generic.import.lower_text',
                importButton = 'massmaintenance.modal.generic.import.button',
                importFileInputs= [{ field: 'file', label: undefined, mandatory: true}],
                importUrlPath = undefined,
                importDisabled = false
              }) {

    this.modalTitle = modalTitle;
    this.templateDownloadHeader = templateDownloadHeader;
    this.templateDownloadUpperText = templateDownloadUpperText;
    this.templateDownloadLowerText = templateDownloadLowerText;
    this.templateDownloadButton = templateDownloadButton;
    this.templateDownloadUrlPath = templateDownloadUrlPath;
    this.templateDownloadDisabled = templateDownloadDisabled;
    this.exportHeader = exportHeader;
    this.exportUpperText = exportUpperText;
    this.exportLowerText = exportLowerText;
    this.exportButton = exportButton;
    this.exportUrlPath = exportUrlPath;
    this.exportDisabled = exportDisabled;
    this.importHeader = importHeader;
    this.importUpperText = importUpperText;
    this.importLowerText = importLowerText;
    this.importButton = importButton;
    this.importMultipleUploadFiles = new Map(importFileInputs.map((obj: FileUploadInputs, index) => [index, obj]));
    this.importUrlPath = importUrlPath;
    this.importDisabled = importDisabled;
  }

  public modalTitle: string;
  public templateDownloadHeader: string;
  public templateDownloadUpperText: string;
  public templateDownloadLowerText: string;
  public templateDownloadButton: string;
  public templateDownloadUrlPath: string;
  public templateDownloadDisabled: boolean;
  public exportHeader: string;
  public exportUpperText: string;
  public exportLowerText: string;
  public exportButton: string;
  public exportUrlPath: string;
  public exportDisabled: boolean;
  public importHeader: string;
  public importUpperText: string;
  public importLowerText: string;
  public importButton: string;
  public importMultipleUploadFiles: Map<number, FileUploadInputs>;
  public importUrlPath: string;
  public importDisabled: boolean;
}

