<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal">{{ 'headline.administration.advanced'| translate }}</h2>

  <div class="d-grid gap-3" style="grid-template-columns: 1fr 1fr;">
    <div class="list-group w-auto ">
      <a routerLink="/adminstration/mass-maintenance" class="list-group-item list-group-item-action p-0">
        <div class="text-center">
          <div class="m-4">
            <fa-icon [icon]="['fas', 'file-import']" size="6x"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'advanced.administration.label.mass.maintenance' | translate}}</h6>
        </div>
      </a>
    </div>
    <div class="list-group w-auto ">
      <a routerLink="/adminstration/report" class="list-group-item list-group-item-action p-0">
        <div class="text-center">
          <div class="m-4">
            <fa-icon [icon]="['fas', 'file-invoice']" size="6x"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'advanced.administration.label.report' | translate}}</h6>
        </div>
      </a>
    </div>
    <div class="list-group w-auto " *ngIf="environment.environment !== 'prod'">
      <a routerLink="/adminstration/document-creation/overview" class="list-group-item list-group-item-action p-0" [queryParams]="{docBridgeMaterialTypeFacet:'PRD'}">
        <div class="text-center">
          <div class="m-4">
            <fa-icon icon="industry" size="6x"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'advanced.administration.label.document.creation' | translate}}</h6>
        </div>
      </a>
    </div>
  </div>

</div>