import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryAttribute } from '../../model/category-attribute';
import { CustomView } from '../../model/custom-view/custom-view';
import { TemplateView } from '../../model/custom-view/template-view';
import { CustomViewType } from '../../model/custom-view/custom-view-type';
import { BasicView } from '../../model/custom-view/basic-view';
import { CategoryAttributeAttributeValue } from '../../model/category-attribute-attribute-value';
import { TemplateViewList } from '../../model/custom-view/template-view-list/template-view-list';
import { TemplateViewListCategoryAttribute } from '../../model/custom-view/template-view-list/template-view-list-category-attribute';
import { Attribute } from '../../model/attribute';
import { AttributeService } from '../attribute.service';
import { AttributeValueService } from '../attribute-value.service';
import { CustomViewUsageService } from '../../service/customview-usage.service';
import { CustomViewService } from '../customview.service';
import { IsAttributeMaintainedService } from '../is-attribute-maintained.service';
import * as _ from 'lodash';
import { DynamicTemplateView } from "../../model/custom-view/dynamic-template-view";
import { DynamicTemplateDocumentContent } from "../../model/custom-view/dynamic-template-document-content";


@Injectable({
  providedIn: 'root'
})
export class CustomViewEditDataService {

  public templateViewListsSubject: BehaviorSubject<TemplateViewList[]> = new BehaviorSubject<TemplateViewList[]>(null);
  public templateViewListsOrig: TemplateViewList[] = null;
  public dynamicTemplateDocumentContentSubject: BehaviorSubject<DynamicTemplateDocumentContent[]> = new BehaviorSubject<DynamicTemplateDocumentContent[]>(null);
  public dynamicTemplateDocumentContentOrig: DynamicTemplateDocumentContent[] = null;
  public customViewSubject: BehaviorSubject<CustomView> = new BehaviorSubject(null);
  public customViewOrig: CustomView = null;

  public canBeDeleted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly router: Router,
    private readonly attributeService: AttributeService,
    private readonly attributeValueService: AttributeValueService,
    private readonly customViewService: CustomViewService,
    private readonly customViewUsageService: CustomViewUsageService,
    private readonly isAttributeMaintainedService: IsAttributeMaintainedService
  ) {
  }

  public init(customViewId: string): void {
    if (customViewId === 'add') {
      this.newCustomView(new BasicView());
      return;
    }
    this.loadCustomView(customViewId).subscribe({
      error: () => {
        this.navigateToViews();
      }
    });
  }

  public deleteCustomView(): Observable<object> {
    return this.customViewService.delete(this.customViewSubject.value);
  }

  public saveCustomView(): Observable<CustomView> {
    if (!!this.customViewSubject.value && this.customViewSubject.value.type === CustomViewType.TEMPLATE_VIEW) {
      const templateView = this.customViewSubject.value as TemplateView;
      templateView.templateViewLists = this.templateViewListsSubject.value;
      this.cleanUpTemporalCategoryAttributesIdsInList(templateView.templateViewLists);
      this.reorderUnselectedCategoryAttributes(templateView.templateViewLists);
      templateView.categoryAttributeAttributeValues = templateView.categoryAttributeAttributeValues
        .filter(categoryAttributeAttributeValue => {
          return this.isAttributeMaintainedService.isMultiValueAttributeValueMaintained(categoryAttributeAttributeValue.attributeValues)
        });
    }

    if (!!this.customViewSubject.value && this.customViewSubject.value.type === CustomViewType.DYNAMIC_TEMPLATE_VIEW) {
      const dynamicTemplateView = this.customViewSubject.value as DynamicTemplateView;
      dynamicTemplateView.documentContent = this.dynamicTemplateDocumentContentSubject.value;
    }

    const customViewToBeSaved = this.customViewSubject.value;
    return this.customViewService.save(customViewToBeSaved)
      .pipe(tap({
        next: (customView: CustomView) => {
          this.newCustomView(customView);
          this.templateViewListsSubject.next(null);
          this.loadTemplateViewLists();
        }, error: () => {
        }
      }));
  }

  public cleanUpTemporalCategoryAttributesIdsInList(templateViewList: TemplateViewList[]): void {
    if (!!templateViewList && templateViewList.length > 0) {
      templateViewList.forEach(tvl => {
        if (!!tvl.templateViewListCategoryAttributes && tvl.templateViewListCategoryAttributes.length > 0) {
          tvl.templateViewListCategoryAttributes.forEach(tvlca => {
            this.cleanUpTemporalCategoryAttributesIds(tvlca, tvl.id);
          });
        }
      });
    }
  }

  public reorderUnselectedCategoryAttributes(templateViewList: TemplateViewList[]): void {
    if (!!templateViewList && templateViewList.length > 0) {
      templateViewList.forEach(tvl => {
        if (!!tvl.templateViewListCategoryAttributes && tvl.templateViewListCategoryAttributes.length > 0) {
          let maxEnabled = tvl.templateViewListCategoryAttributes.filter(obj => obj.defaultEnabled).length;
          if (maxEnabled !== tvl.templateViewListCategoryAttributes.length) {
            tvl.templateViewListCategoryAttributes.filter(obj => !obj.defaultEnabled)
              .forEach((step, index) => step.defaultOrder = maxEnabled + index + 1);
          }
        }
      });
    }
  }

  public cleanUpTemporalCategoryAttributesIds(tvlca: TemplateViewListCategoryAttribute, tvlId: string): void {
    if (tvlca.id.endsWith(`_${tvlId}`)) {
      tvlca.id = null;
    }
  }

  public reorderCategoryAttributesInLists(templateViewList: TemplateViewList[]): void {
    if (!!templateViewList && templateViewList.length > 0) {
      templateViewList.forEach(tvl => {
        if (!!tvl.templateViewListCategoryAttributes && tvl.templateViewListCategoryAttributes.length > 0) {
          tvl.templateViewListCategoryAttributes.sort((a, b) => a.defaultOrder - b.defaultOrder);
        }
      });
    }
  }

  public hasChanged(): boolean {
    const customViewChanged = !(this.customViewService.isEqual(this.customViewOrig, this.customViewSubject.value));
    const templateViewListsChanged = !(_.isEqual(this.templateViewListsSubject.value, this.templateViewListsOrig));
    return customViewChanged || templateViewListsChanged;
  }

  public addCategoryAttribute = (categoryAttribute: CategoryAttribute) => {
    if (!this.customViewSubject.value.customViewCategoryAttributes) {
      this.customViewSubject.value.customViewCategoryAttributes = [];
    }
    this.customViewSubject.value.customViewCategoryAttributes.push(categoryAttribute);
  }

  public removeCategoryAttribute = (categoryAttribute: CategoryAttribute) => {
    this.customViewSubject.value.customViewCategoryAttributes = this.customViewSubject.value.customViewCategoryAttributes.filter(element => element.id !== categoryAttribute.id);
  }

  public navigateToViews() {
    this.router.navigateByUrl('/adminstration/view');
  }

  public findCustomView = (): CustomView => this.customViewSubject.value;

  public getTemplateView = (): TemplateView => this.customViewSubject.value as TemplateView;

  public getDynamicTemplateView = (): DynamicTemplateView => this.customViewSubject.value as DynamicTemplateView;

  public findCustomViewSubject = (): BehaviorSubject<CustomView> => this.customViewSubject;

  public isTemplateView = (): boolean => {
    return !!this.customViewSubject.value && this.customViewSubject.value.type === CustomViewType.TEMPLATE_VIEW;
  }

  public isDynamicTemplateView = (): boolean => {
    return !!this.customViewSubject.value && this.customViewSubject.value.type === CustomViewType.DYNAMIC_TEMPLATE_VIEW;
  }

  public deleteTemplateViewCategoryAttributeDocumentVersionSpecific(value: CategoryAttribute): void {
    this.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific =
      this.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific.filter(element => element.id !== value.id);
  }

  public addTemplateViewCategoryAttributeDocumentVersionSpecific(value: CategoryAttribute): void {
    if (!this.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific) {
      this.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific = [];
    }
    this.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific.push(value);
  }

  public deleteTemplateViewCategoryAttributeTemplateViewSpecific(value: CategoryAttribute): void {
    this.getTemplateView().categoryAttributeAttributeValues =
      this.getTemplateView().categoryAttributeAttributeValues.filter(element => element.categoryAttribute.id !== value.id);
    this.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific =
      this.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific.filter(element => element.id !== value.id);
  }

  public deleteTemplateViewSelectionMappingSingleChoiceList(value: CategoryAttribute): void {
    this.getTemplateView().selectionMappingSingleChoiceList =
      this.getTemplateView().selectionMappingSingleChoiceList.filter(element => element.categoryAttributeId !== value.id);
    this.getTemplateView().selectionMappingSingleChoiceListLocalized =
      this.getTemplateView().selectionMappingSingleChoiceListLocalized.filter(element => element.categoryAttributeId !== value.id);
  }

  public addTemplateViewCategoryAttributeTemplateViewSpecific(value: CategoryAttribute): void {
    this.attributeService.load(value.attribute.id).subscribe(attribute => {
      Object.assign(value.attribute, attribute);
      if (!this.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific) {
        this.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific = [];
      }
      this.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific.push(value);
    });
  }

  public addTemplateViewCategoryAttributeAddedWithoutSaving(value: CategoryAttribute): void {
    this.attributeService.load(value.attribute.id).subscribe(attribute => {
      Object.assign(value.attribute, attribute);
    });
  }

  public cleanUp(): void {
    this.canBeDeleted.next(false);
    this.templateViewListsSubject.next(null);
    this.templateViewListsOrig = null;
    this.newCustomView(new BasicView());
  }

  getTemplateViewCategoryAttributeDocumentVersionSpecificIdsToExclude(): string[] {
    if (!this.getTemplateView()?.templateViewCategoryAttributesDocumentVersionSpecific) {
      return [];
    }
    return this.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific.map(tca => tca.id);
  }

  getTemplateViewCategoryAttributeTemplateViewSpecificIdsToExclude(): string[] {
    if (!this.getTemplateView()?.templateViewCategoryAttributesTemplateViewSpecific) {
      return [];
    }
    return this.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific.map(tca => tca.id);
  }

  changeCustomViewType($event: string) {
    if ($event == CustomViewType.TEMPLATE_VIEW) {
      this.templateViewListsSubject.next([]);
    } else if ($event == CustomViewType.DYNAMIC_TEMPLATE_VIEW) {
      this.dynamicTemplateDocumentContentSubject.next([]);
    }
    this.assignTypeAndPrototype($event);
  }

  addTemplateViewList(templateViewList: TemplateViewList) {
    this.templateViewListsSubject.value.push(templateViewList);
  }

  addDynamicTemplateDocumentContent(dynamicTemplateDocumentContent: DynamicTemplateDocumentContent) {
    this.dynamicTemplateDocumentContentSubject.value.push(dynamicTemplateDocumentContent);
  }

  deleteTemplateViewList(templateViewList: TemplateViewList) {
    this.templateViewListsSubject.next(this.templateViewListsSubject.value.filter(element => element !== templateViewList));
  }

  deleteDynamicTemplateViewList(dynamicTemplateDocumentContent: DynamicTemplateDocumentContent) {
    this.dynamicTemplateDocumentContentSubject.next(
      this.dynamicTemplateDocumentContentSubject.value.filter(element => element !== dynamicTemplateDocumentContent));
  }

  loadTemplateViewLists() {
    if (this.isTemplateView() && !!this.getTemplateView()?.id) {
      this.customViewService.loadTemplateViewLists(this.getTemplateView().id).subscribe(templateViewLists => {
        this.reorderCategoryAttributesInLists(templateViewLists);
        this.templateViewListsOrig = _.cloneDeep(templateViewLists);
        this.templateViewListsSubject.next(templateViewLists);
      });
    }
  }

  loadDynamicTemplateViewLists() {
    if (this.isDynamicTemplateView() && !!this.getDynamicTemplateView()?.id) {
      this.customViewService.loadDynamicTemplateDocumentContent(this.getDynamicTemplateView().id).subscribe(dynamicTemplateViewLists => {
        this.dynamicTemplateDocumentContentOrig = _.cloneDeep(dynamicTemplateViewLists);
        this.dynamicTemplateDocumentContentSubject.next(dynamicTemplateViewLists);
      });
    }
  }

  public getAttributesToBeShown = (templateViewLocal: TemplateView): [CategoryAttributeAttributeValue, Attribute][] => {
    const templateViewCategoryAttributeAttributeValues: [CategoryAttributeAttributeValue, Attribute][] = [];
    if (!!templateViewLocal) {
      const templateViewCategoryAttributes: CategoryAttribute[] = templateViewLocal.templateViewCategoryAttributesTemplateViewSpecific;
      const categoryAttributeAttributeValues: CategoryAttributeAttributeValue[] = templateViewLocal.categoryAttributeAttributeValues;

      templateViewCategoryAttributes.forEach(categoryAttribute => {

        if (!categoryAttributeAttributeValues.some(categoryAttributeAttributeValue => categoryAttributeAttributeValue.categoryAttribute.id === categoryAttribute.id)) {
          const categoryAttributeAttributeValue = new CategoryAttributeAttributeValue();
          categoryAttributeAttributeValue.categoryAttribute = categoryAttribute;
          categoryAttributeAttributeValue.attributeValues = [this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute)];
          categoryAttributeAttributeValues.push(categoryAttributeAttributeValue);
          // categoryAttributeAttributeValues are not loaded when the templateView is loaded, so we need to push them here
          (this.customViewOrig as TemplateView).categoryAttributeAttributeValues.push(_.cloneDeep(categoryAttributeAttributeValue));
        }

        const existingCategoryAttributeAttributeValue: CategoryAttributeAttributeValue =
          categoryAttributeAttributeValues.find(categoryAttributeAttributeValue => categoryAttributeAttributeValue.categoryAttribute.id === categoryAttribute.id);

        const templateViewCategoryAttributeAttributeValue: [CategoryAttributeAttributeValue, Attribute] =
          [existingCategoryAttributeAttributeValue, categoryAttribute.attribute]

        templateViewCategoryAttributeAttributeValues.push(templateViewCategoryAttributeAttributeValue);
      });
      return templateViewCategoryAttributes?.length > 0 ? templateViewCategoryAttributeAttributeValues : [];
    }
    return [];
  }

  private loadCustomView(customViewId: string): Observable<CustomView> {
    return this.customViewService.load(customViewId).pipe(tap(customView => {
      this.newCustomView(customView);
      this.loadTemplateViewLists();
      this.loadDynamicTemplateViewLists();
    }));
  }

  private newCustomView(customView: CustomView) {
    this.customViewSubject.next(customView);
    this.assignTypeAndPrototype(customView.type);
    this.customViewOrig = _.cloneDeep(this.customViewSubject.value);
    this.updateCanBeDeleted();
  }

  private updateCanBeDeleted() {
    if (this.customViewSubject.value.id === null) {
      this.canBeDeleted.next(false);
    } else {
      if (this.isTemplateView()) {
        this.customViewUsageService.isTemplateViewInUse(this.customViewSubject.value).subscribe(result => this.canBeDeleted.next(!result));
      } else {
        this.canBeDeleted.next(true);
      }
    }
  }

  private assignTypeAndPrototype($event: string) {
    delete this.customViewSubject.value.type; // even when declaring type as readonly, Object.assign() will be able to overwrite it.
    switch ($event) {
      case CustomViewType.TEMPLATE_VIEW: {
        this.assignTemplateViewTypeAndPrototype();
        break;
      }
      case CustomViewType.DYNAMIC_TEMPLATE_VIEW: {
        this.assignDynamicTemplateViewTypeAndPrototype();
        break;
      }
      default: { // Default points always to CustomViewType.BASIC_VIEW
        this.assignBasicViewTypeAndPrototype();
        break;
      }
    }
  }

  private assignBasicViewTypeAndPrototype() {
    const basicView = new BasicView();
    this.customViewSubject.next(Object.assign(basicView, this.customViewSubject.value));
  }

  private assignTemplateViewTypeAndPrototype() {
    const templateView = new TemplateView();
    this.customViewSubject.next(Object.assign(templateView, this.customViewSubject.value));
    const caavs = templateView.categoryAttributeAttributeValues;
    templateView.categoryAttributeAttributeValues = caavs.length === 0 ? [] : caavs.map(categoryAttributeAttributeValue =>
      categoryAttributeAttributeValue = Object.assign(new CategoryAttributeAttributeValue(), categoryAttributeAttributeValue)
    );
  }

  private assignDynamicTemplateViewTypeAndPrototype() {
    const dynamicTemplateView = new DynamicTemplateView();
    this.customViewSubject.next(Object.assign(dynamicTemplateView, this.customViewSubject.value));

  }
}
