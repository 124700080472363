import { LocalizedString } from '../localized-string';
import { Realm } from '../realm/realm';
import { CategoryAttribute } from '../category-attribute';
import { User } from '../user';
import { CustomView } from './custom-view';
import { CustomViewType } from "./custom-view-type";
import { DynamicTemplateDocumentContent } from "./dynamic-template-document-content";
import { Category } from '../category';
import { BusinessHierarchy } from '../business-hierarchy';
import { CustomViewDocumentProperties } from './custom-view-document-properties';

export class DynamicTemplateView implements CustomView, CustomViewDocumentProperties {
  // Tab: Maintenance
  public id: string = null;
  public name: LocalizedString[] = [];
  public description: string = null;
  public realm: Realm = null;
  public type: CustomViewType = CustomViewType.DYNAMIC_TEMPLATE_VIEW;
  public customViewCategoryAttributes: CategoryAttribute[] = [];

  // Tab: Assignments
  public users: User[] = [];

  // Tab: Template
  public templateFileNames: LocalizedString[] = [];
  public materialRelationMandatory: boolean = false;
  public documentContent: DynamicTemplateDocumentContent[] = [];

  // Tab: Document Properties
  public documentType: Category = null;
  public documentSecurityClass: Category = null;
  public documentRegions: Category[] = [];
  public documentOperatingDivisions: BusinessHierarchy[] = [];
}
