import {Component, Input, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Realm } from '../../../../model/realm/realm';
import { Document } from '../../../../model/document/document';
import { RealmRestrictionType } from '../../../../model/realm/realm-restriction-type';
import { MetadataVisibilityType } from '../../../../model/metadata-visibility/metadata-visibility-type';
import { Category } from '../../../../model/category';
import { CategoryType } from '../../../../model/category-type';
import { Pageable } from '../../../../model/pageable';
import { RealmTypeaheadService } from '../../../../service/typeahead/realm-typeahead.service';
import { CategoryService } from '../../../../service/category.service';
import { CategoryOnLeafLevelTypeaheadService } from '../../../../service/typeahead/category-on-leaf-level-typeahead.service';
import { DocumentEditDataService } from '../../../../service/data-service/document-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'document-tab-property',
  templateUrl: './document-tab-property.component.html',
  styleUrls: ['./document-tab-property.component.less']
})
export class DocumentTabPropertyComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public isReadonly: boolean = false;

  public securityClassifications: Category[];

  public realmRestrictionTypes: string[] = [];
  public realmRestrictionTooltips: string[] = [];

  public metadataVisibilityTypes: string[] = [];
  public metadataVisibilityTooltips: string[] = [];

  private previousSecurityClassId: string = null;

  constructor(
    private documentEditDataService: DocumentEditDataService,
    private categoryService: CategoryService,
    private categoryTypeaheadService: CategoryOnLeafLevelTypeaheadService,
    private realmTypeaheadService: RealmTypeaheadService,
    private datePipe: DatePipe,
    private readonly translateService: TranslateService
  ) {
    this.realmRestrictionTypes = [...Object.keys(RealmRestrictionType) ];
    this.realmRestrictionTooltips = [...Object.keys(RealmRestrictionType) ]
      .map(x=> this.translateService.instant(`tooltip.document.realm_restriction.enumeration.type.${x}`));

    this.metadataVisibilityTypes = [...Object.keys(MetadataVisibilityType) ];
    this.metadataVisibilityTooltips = [...Object.keys(MetadataVisibilityType) ]
      .map(x=> this.translateService.instant(`tooltip.document.metadata_visibility.enumeration.type.${x}`));
  }

  ngOnInit() {
    this.previousSecurityClassId = null;
    this.categoryService.findByTypeOnLeafLevel(CategoryType.SECURITY_CLASSIFICATION).subscribe((list: Category[]) => {
      this.securityClassifications = list;
    });
  }

  public getDocument(): Document {
    if (!this.previousSecurityClassId){
      this.refreshPreviousSecurityClassId();
    }
    return this.documentEditDataService.documentBehaviorSubject.value;
  }

  public isMetadataVisibilityVisible: () => boolean = () => !this.documentEditDataService.isSecurityClassPublic();

  public documentTypeTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryTypeaheadService.typeaheadSearch(searchTerm, pageable, [CategoryType.DOCUMENT_TYPE], []);
  public documentTypeTypeaheadFormatter = (model: Category) => this.categoryTypeaheadService.typeaheadFormatter(model);

  public realmTypeahead = (searchTerm: string, pageable: Pageable) => this.realmTypeaheadService.typeaheadSearch(searchTerm, pageable);
  public realmTypeaheadFormatter = (model: Realm) => this.realmTypeaheadService.typeaheadFormatter(model);

  /* Compare method for Security classification (default selection) */
  public securityClassificationComparator(object1: { id: string }, object2: { id: string }): boolean {
    if (!object1 || !object2) {
      return false;
    }
    return object1.id === object2.id;
  }

  public generateUserText(email, cn: string): string {
    let userInfo = '';
    if(typeof email !== 'undefined' && email) {
      userInfo = userInfo + email;
    }
    if(typeof cn !== 'undefined' && cn) {
      userInfo = userInfo + ' (' + cn + ')';
    }
    if (userInfo === '') {
      userInfo = 'NA';
    }
    return userInfo;
  }

  public generateDateText(date: Date): string {
    if(date !== null) {
      return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
    }

    return 'NA';
  }

  public securityClassChanged() {
    if (!!this.previousSecurityClassId && this.previousSecurityClassId === environment.publicSecurityClassCategoryId){
      this.getDocument().metadataVisibility = MetadataVisibilityType.NONE;
    }
    if (this.documentEditDataService.isSecurityClassPublic()){
      this.getDocument().metadataVisibility = MetadataVisibilityType.DISPLAYMETADATA;
    }
    this.refreshPreviousSecurityClassId();
  }

  private refreshPreviousSecurityClassId(){
    this.previousSecurityClassId = this.documentEditDataService.documentBehaviorSubject.value?.securityClass?.id;
  }
}
