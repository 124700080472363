import { CategoryAttribute } from '../category-attribute';

export class RenderingOption {

  constructor(
    public enabled: boolean,
    public matching: boolean,
    public customViewCategoryAttributeId: string,
    public categoryAttribute: CategoryAttribute,
    public templateViewListId: string,
    public order: number,
    public saveIsMandatory: boolean
  ) {}
}
