<label [for]="'inputEdit_'">{{ 'label.business.hierarchy' | translate }}</label>

<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for all nodes -->
  <cdk-tree-node *cdkTreeNodeDef="let node" cdkTreeNodePadding
                 [style.display]="shouldRender(node) ? 'flex' : 'none'"
                 class="example-tree-node">

    <fa-icon [icon]="['fas', (treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right')]"
    cdkTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
    (click)="node.isExpanded = !node.isExpanded"
    [style.visibility]="node.expandable ? 'visible' : 'hidden'"
    class="tree-node-icon"></fa-icon>

    {{node.name}}
  </cdk-tree-node>

</cdk-tree>
