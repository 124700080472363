<div class="form-group">
    <div class="row">
      <div class='col-12 form-group'>
        <business-hierarchy-tree [businessHierarchyNodeOberservable]="getBusinessHierarchy()"></business-hierarchy-tree>
      </div>
    </div>

    <div class="row">
      <div class='col-3 form-group'>
        <string-input-edit label="BEGRU" [inputValue]="getMaterial()?.begru" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-3 form-group'>
        
      </div>
      <div class='col-2 form-group'>
        <string-input-edit label="Status" [inputValue]="formatSpassStatus(getMaterial()?.status)" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-2 form-group'>
        <string-input-edit label="EAN" [inputValue]="getMaterial()?.europeanArticleNumber" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-2 form-group'>
        <string-input-edit label="Material group" [inputValue]="getMaterial()?.materialGroup" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-3 form-group'>
        <string-input-edit label="Total shelf life" [inputValue]="getMaterial()?.totalShelfLife" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-3 form-group'>
        <string-input-edit label="Minimum remaining shelf life" [inputValue]="getMaterial()?.minimumRemainingShelfLife" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-2 form-group'>
        <string-input-edit label="Base unit of measure" [inputValue]="getMaterial()?.baseUnitOfMeasure?.mabasCode" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-2 form-group' *ngIf="getMaterial()?.type == 'ART'">
        <string-input-edit label="Article Type" [inputValue]="getMaterial()?.articleType" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-2 form-group' *ngIf="getMaterial()?.type == 'ART'">
        <string-input-edit label="Size" [inputValue]="getMaterial()?.size" [readonly]="true"></string-input-edit>
      </div>
      <div class='col-2 form-group' *ngIf="getMaterial()?.type == 'ART'">
        <string-input-edit label="Packaging" [inputValue]="getMaterial()?.packagingType" [readonly]="true"></string-input-edit>
      </div>
    </div>
    <div class="row">
      <div class='col-6 form-group'>
        <localized-string-edit [label]="'Name'" [localizedStrings]="getMaterial()?.name" [readonly]="true"></localized-string-edit>
      </div>
      <div class='col-6 form-group'>
        <localized-string-edit [label]="'Marketing Name'" [localizedStrings]="getMaterial()?.marketingName" [readonly]="true"></localized-string-edit>
      </div>
    </div>
</div>