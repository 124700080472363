export default function Required(target: any, prop: string) {

  const NG_ON_ONIT_NAME = 'ngOnInit';

  const ngOnInitClone: Function|null = target[NG_ON_ONIT_NAME];

  if(!ngOnInitClone){
    throw new Error("The class using the '@Required' decorator must implement angular onInit.");
  }

  Object.defineProperty(target, NG_ON_ONIT_NAME, {
    value: function() {
      
      if ( this[prop] == null ) {
        throw new Error(`${target.constructor.name}: ${prop} is required, but was not provided`);
      }
      
      if( ngOnInitClone ) {
        ngOnInitClone.call(this);
      }
    }
  });
}