import { Component } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom, tap } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserFilterResponse } from '../../../model/user-filter/user-filter-response';
import { UserFilterRenameRequest } from '../../../model/user-filter/user-filter-rename-request';
import { UserFilterDataService } from '../../../service/user-filter/user-filter-data.service';
import { ConfirmService } from '../../../service/confirm.service';
import { NotificationService } from '../../../service/notification.service';


@Component({
  selector: 'app-user-filter-rename',
  templateUrl: './user-filter-rename.component.html',
  styleUrl: './user-filter-rename.component.less'
})
export class UserFilterRenameComponent {

  public currentName: string;
  public filterName: string;

  constructor(
    private modal: NgbActiveModal,
    private confirmService: ConfirmService,
    private notificationService: NotificationService,
    private userFilterDataService: UserFilterDataService
  ) { }

  async clickOk() {
    const filters: UserFilterResponse[] = await this.getFilters();
    const existingFilters: UserFilterResponse[] = _(filters).value();
    const existingFilterNames: string[] = _(existingFilters).map(f => f.filterName).value();
    const filterName: string = this.filterName;

    const matchingFilterCount: number = existingFilterNames.filter(f => filterName === f).length;
    if (matchingFilterCount >= 1) {
      const existingFilter = existingFilters.find(f => f.filterName === filterName);
      if (existingFilter.filterId !== this.userFilterDataService.userFilterBehaviourSubject.getValue().filterId) {
        this.notificationService.addErrorNotification('user.filter.rename.error.message');
      } else {
        // The filter already has that name, so skip it
        this.notificationService.addSuccessNotification('user.filter.rename.success.message');
        this.modal.close();
      }
      return;
    }

    const filterId: string = this.userFilterDataService.userFilterBehaviourSubject.getValue().filterId;
    const request: UserFilterRenameRequest = new UserFilterRenameRequest(filterId, filterName);

    lastValueFrom(this.userFilterDataService.renameFilter(request).pipe(tap(() => {
      this.modal.close();
    })));
  }

  clickCancel(): void {
    this.modal.close();
  }

  private async getFilters(): Promise<UserFilterResponse[]> {
    return this.userFilterDataService.userFiltersBehaviourSubject.getValue();
  }


  private async askToConfirmOverwriting() {
    return await this.confirmService.confirm('user.filter.rename.dialog.title', 'user.filter.rename.dialog.message', 'button.yes', 'button.cancel');
  }
}
