<div class="container-fluid pb-3" *ngIf="!edit">

  <h2 class="display-6 fw-normal">{{'h2.formulations.header'|translate}}</h2>

  <div class="row">
    <div class="col-6">
      <div class="input-group">
        <input type="text"
          class="form-control"
          id="formulationSearchInput"
          [placeholder]="'placeholder.formulation.search' | translate"
          [(ngModel)]="searchString"
          name="formulationSearchInput"
          (keyup.enter)="search(searchString)">
        <button type="button" class="btn btn-primary " (click)="search(searchString)" >
          <fa-icon icon="search"></fa-icon>
          <span class="button-label">{{'button.search'|translate}}</span>
        </button>
      </div>
    </div>
    <div class="col-6 align-right">
      <button *ngIf="!(isReadOnlyUser | async)" (click)="newFormulation()"
        class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{'button.formulations.add'|translate}}
      </button>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col">
      <app-table [page]="formulations" (rowClick)="editItem($event)"(pageableHandler)="switchPage($event)">
        <app-table-column name="{{'th.formulations.name'|translate}}" field="name" [valueFunction]="getLocalizedStringsValue"></app-table-column>
        <app-table-column name="{{'th.formulations.formulationReference'|translate}}" field="formulationReference"></app-table-column>
        <app-table-column name="{{'th.formulations.realm'|translate}}" field="realm" [valueFunction]="formatRealm"></app-table-column>
        <app-table-column name="{{'th.formulations.marketingType'|translate}}" field="marketingType"></app-table-column>
      </app-table>
    </div>
  </div>

  <div class="row  mb-3">
    <div class="col-12 align-right">
      <button *ngIf="!(isReadOnlyUser | async)" (click)="newFormulation()"
        class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{'button.formulations.add'|translate}}
      </button>
    </div>
  </div>

</div>

