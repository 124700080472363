import { AppModule } from '../app.module';
import { AuthenticationService } from '../service/authentication/authentication.service';

export default function InjectIsNotAdvancedUser(target: any, propertyName: string) {

  const NG_ON_ONIT_NAME = 'ngOnInit';
  const ngOnInitClone: () => void | null =  target[NG_ON_ONIT_NAME];

  if (!ngOnInitClone) {
    throw new Error(`The class '${target.prototype.constructor.name}' using the '@InjectIsNotAdvancedUser' decorator must implement angular onInit.`);
  }


  Object.defineProperty(target, NG_ON_ONIT_NAME, {
    value () {

      const service = AppModule.injector.get<AuthenticationService>(AuthenticationService);

      this[propertyName] = service.isNotAdvancedUser();

      if (ngOnInitClone) {
        ngOnInitClone.call(this);
      }
    }
  });

}
