<ng-template #categoryValueTemplate let-result="result">
  <typeahead-template-category-relatives [model]="result.category"></typeahead-template-category-relatives>
</ng-template>

<ng-template #categoryResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-relatives [model]="result" [term]="term?.trim().split(' ')" [showCategoryLink]="false"></typeahead-template-category-relatives>
</ng-template>

<div *ngIf="findCategoryMaterials()">

  <h4><span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.is.a' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findProductGroups()"
      [typeahead]="categoryTypeaheadIsA"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_pgs'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4><span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.can.be.used' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findApplications()"
      [typeahead]="categoryTypeaheadCanBeUsedFor"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_applications'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4><span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.can.support' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findProcesses()"
      [typeahead]="categoryTypeaheadCanSupportProcesses"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_processes'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4>{{ 'literal.for' | translate }} <span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span>{{ 'literal.basf.offers' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findServices()"
      [typeahead]="categoryTypeaheadCanOfferServices"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_services'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>
  
  <h4><span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.relevant.for.industries' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findIndustries()"
      [typeahead]="categoryTypeaheadRelevantForIndustries"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_industries'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4><span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.belongs.to.topic' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findTopics()"
      [typeahead]="categoryTypeaheadRelevantForTopics"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_topics'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4><span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.market.to' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findRegions()"
      [typeahead]="categoryTypeaheadMarketedTo"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_applications'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4 *ngIf="(isAdvanced | async) || (isAdmin | async)">
    <span class="material-name">{{ findMaterial()?.name | localizedStringsFilter }}</span> {{ 'literal.documents.are.created.using.attributes.of' | translate }}</h4>
  <div *ngIf="(isAdvanced | async) || (isAdmin | async)" class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findDocumentCreations()"
      [typeahead]="categoryTypeaheadAreCreatedUsingAttOf"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_documents'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>
</div>