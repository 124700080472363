import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isEqual, orderBy } from 'lodash';
import { Page } from '../model/pim-response/page';
import { Attribute } from '../model/attribute';
import { environment } from '../../environments/environment';
import { AttributeType } from '../model/attribute-type';
import { CategoryAttribute } from '../model/category-attribute';
import { LocalizedEnumerationValue } from '../model/localized-enumeration-value';
import { LocalizedString } from '../model/localized-string';
import { LocalizedStringService } from './localized-string.service';
import { BackendValidationService } from './form-validation/backend-validation.service';
import { UrlParameterService } from './url-parameter.service';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AttributeService {

    public selectedAttributeTypes: AttributeType[] = [];

    constructor(
        private httpClient: HttpClient,
        private localizedStringService: LocalizedStringService,
        private backendValidationService: BackendValidationService,
        private urlParameterService : UrlParameterService
    ) { }

    public load(id: string): Observable<Attribute> {
        return this.httpClient.get<Attribute>(environment.restUrl + '/attribute/' + id);
    }

    public searchEntities(queryParameters: Params): Observable<Page<Document>> {
      if (!!queryParameters.searchTerm){
        queryParameters.searchTerm = btoa(queryParameters.searchTerm);
      }
      const queryParameterString: string = this.urlParameterService.convertQueryParametersToString(queryParameters);
      const url: string = `${environment.restUrl}/attribute/search/v4/${queryParameterString}`;
      return this.httpClient.get<Page<Document>>(url);
    }

    public save(attribute: Attribute): Observable<Attribute> {
        return this.httpClient.post<Attribute>(environment.restUrl + '/attribute/save', attribute)
          .pipe(this.backendValidationService.renderErrorMessages());
    }

    public getOrderedAttributeIds(categoryAttributes: CategoryAttribute[]): any[] {
      return orderBy(categoryAttributes, ['attribute.id']).map(categoryAttribute => categoryAttribute.attribute.id);
    }

    private createLocalizedEnumerationValueComparisonObject(localizedEnumerationValue: LocalizedEnumerationValue): any{
      return {
         id :localizedEnumerationValue.id,
         order: localizedEnumerationValue.order,
         name: orderBy(localizedEnumerationValue.name.map(name => this.createNameComparisonObject(name)), ['id'])
      }
    }

    private createNameComparisonObject(name: LocalizedString): any{
      return  {
        id: name.id,
        language: name.language.isoCode,
        value: name.value
      }
    }

    private getAttributeObj(attribute: Attribute): any {
      let attributeName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(attribute.name);
      let attributeShortName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(attribute.shortName);
      let attributeRelationshipTypeLabel = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(attribute.relationshipTypeLabel);
      let attributeUnit = attribute.unit ? attribute.unit.id : null;
      let attributeRelevance = attribute.attributeRelevance ? attribute.attributeRelevance.sort() : null;
      let attributeEnumerationValues = attribute.enumerationValues ? attribute.enumerationValues.sort() : null;
      let attributeSingleChoiceListValues = orderBy(attribute.singleChoiceListValues, ['order']);
      let attributeLocalizedEnumerationValues = orderBy(attribute.localizedEnumerationValues.map(val => this.createLocalizedEnumerationValueComparisonObject(val)), ['order']);
      let attributeClusterId = !!attribute.attributeCluster ? attribute.attributeCluster.id : null;
      return {
        id: attribute.id,
        name: attributeName,
        shortName: attributeShortName,
        relationshipTypeLabel: attributeRelationshipTypeLabel,
        unit: attributeUnit,
        type: attribute.type,
        attributeRelevance: attributeRelevance,
        rangeMax: attribute.rangeMax,
        enumerationValues: attributeEnumerationValues,
        SingleChoiceListValues: attributeSingleChoiceListValues,
        localizedEnumerationValues: attributeLocalizedEnumerationValues,
        xpath: attribute.xpath,
        description: attribute.description,
        attributeClusterId: attributeClusterId
      }
    }

    public isEqual(attributeOrig: Attribute, attribute: Attribute): boolean {
      if (attributeOrig != null && attribute != null) {
        let attributeOrigObj = this.getAttributeObj(attributeOrig);
        let attributeObj = this.getAttributeObj(attribute);
        return isEqual(attributeOrigObj, attributeObj);
      }
      return false;
    }
}
