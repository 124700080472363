import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomView } from '../../../../model/custom-view/custom-view';
import { Location } from '@angular/common';
import { ConfirmService } from '../../../../service/confirm.service';
import { CustomViewEditDataService } from '../../../../service/data-service/customview-edit-data.service';
import { BackendValidationService } from '../../../../service/form-validation/backend-validation.service';
import { NotificationService } from '../../../../service/notification.service';
import { LocalizedStringService } from '../../../../service/localized-string.service';
import { HasUnsavedChangesGuard } from "../../../../guards/has-unsaved-changes.guard";
import { ConnectionService } from '../../../../service/connection.service';

@Component({
  selector: 'customview-administration-edit',
  templateUrl: './customview-administration-edit.component.html',
  styleUrls: ['./customview-administration-edit.component.less']
})
export class CustomViewAdministrationEditComponent implements OnInit, OnDestroy, HasUnsavedChangesGuard {

  public selectedTab = 'properties';

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly confirmService: ConfirmService,
    private readonly connectionService: ConnectionService,
    private readonly notificationService: NotificationService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly backendValidationService: BackendValidationService,
    private readonly customViewEditDataService: CustomViewEditDataService,
  ) {
  }

  public get customView(): CustomView {
    return this.customViewEditDataService.findCustomView();
  }

  public get customViewSubject(): BehaviorSubject<CustomView> {
    return this.customViewEditDataService.customViewSubject;
  }

  public get openConnections(): string[] {
    return this.connectionService.allConnections;
  }

  public get templateViewListsSubject() {
    return this.customViewEditDataService.templateViewListsSubject;
  }

  public get canBeDeleted(): BehaviorSubject<boolean> {
    return this.customViewEditDataService.canBeDeleted;
  }

  public getEnglishName(customView: CustomView): String {
    return this.localizedStringService.getLocalizedStringEnglishValue(customView.name);
  }

  public isCustomViewTypeTemplateView = (): boolean => this.customViewEditDataService.isTemplateView();

  public isCustomViewTypeDynamicTemplateView = (): boolean => this.customViewEditDataService.isDynamicTemplateView();

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.customViewEditDataService.init(params.id);
    });
    this.route.firstChild.url.subscribe((url) => {
      if (url[url.length - 1].path !== this.selectedTab) {
        this.selectedTab = url[url.length - 1].path;
      }
    });
  }

  ngOnDestroy(): void {
    this.customViewEditDataService.cleanUp();
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.customViewEditDataService.hasChanged()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public cancel = () => this.customViewEditDataService.navigateToViews();

  public getCustomViewId = (): string => this.customViewEditDataService.findCustomView().id || null;

  public save = () => {
    this.customViewEditDataService
      .saveCustomView()
      .subscribe((customView: CustomView) => {
        this.notificationService.addSuccessNotification('label.successfully.saved');
        this.location.replaceState(`adminstration/view/${customView.id}/${this.selectedTab}`);
      });

  };

  public delete = () => {
    this.confirmService.confirm('title.confirm.delete', 'text.confirm.customview.deletion', 'button.yes', 'button.no').then(confirmResult => {
      if (confirmResult) {
        this.customViewEditDataService.deleteCustomView()
          .pipe(this.backendValidationService.renderErrorMessages())
          .subscribe(() => {
            this.notificationService.addSuccessNotification('label.successfully.deleted');
            this.customViewEditDataService.navigateToViews();
          })
      }
    });
  };
}
