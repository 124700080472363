<app-overview-view [tableColumns]="tableColumns"
    [title]="'title.product_hierarchy_object.overview' | translate"
    [searchFunction]="searchFunction"
    [facetFields]="facetFields"
    [addAllowed]="!(isReadOnlyUser | async)"
    [overviewValueTemplate]="overviewValueTemplate"
    searchFields="internalName"
    editComponentUrl="product-hierarchy-object"
    typeaheadFieldLabelPrefix="typeaheadFieldLabelPrefix"
    [placeholder]="'placeholder.search.text' | translate">
</app-overview-view>

<ng-template #overviewValueTemplate let-r="result" let-t="term">
  <span *ngFor="let highlightedMapEntry of r.highlights; index as i;">
    <span *ngIf="i!==0">, </span>
    <ngb-highlight [result]="highlightedMapEntry.value" [term]="t"></ngb-highlight>
    <small>
      <i class="highlighted-result"> [{{typeaheadFieldLabelPrefix + highlightedMapEntry.field | translate}}]</i>
    </small>
  </span>
</ng-template>
