<app-overview-view [tableColumns]="tableColumns"
    [title]="'headline.administration.attribute' | translate"
    [searchFunction]="searchFunction"
    searchFields="attributeName"
    [facetFields]="facetFields"
    [addAllowed]="false"
    [overviewValueTemplate]="overviewValueTemplate"
    [customButtons]="customButtons"
    [rowClicked]="editEntity"
    typeaheadFieldLabelPrefix="typeaheadFieldLabelPrefix"
    [placeholder]="'placeholder.search.text' | translate">
</app-overview-view>

<div class="row col-mb-3">
  <div class="col-12 align-right">
    <ng-container *ngTemplateOutlet="customButtons"></ng-container>
  </div>
</div>

<ng-template #overviewValueTemplate let-r="result" let-t="term">
  <span >
    <ngb-highlight [result]="r.name | localizedStringsFilter" [term]="t"></ngb-highlight>
      <small>
        <i class="highlighted-result"> [{{typeaheadFieldLabelPrefix + 'name' | translate}}]</i>
    </small>
  </span>
</ng-template>

<ng-template #customButtons>
  <button id="newAttribute" (click)="newAttribute()" class="btn btn-primary">
    <fa-icon icon="plus"></fa-icon> {{ 'button.add.attribute' | translate }}
  </button>
  <button id="newAttributeCluster" (click)="newAttributeCluster()" class="btn btn-primary attribute-cluster-button">
    <fa-icon icon="plus"></fa-icon> {{ 'button.add.attributecluster' | translate }}
  </button>
  <button id="newAttributeParameter" (click)="newAttributeParameter()" class="btn btn-primary attribute-parameter-button">
    <fa-icon icon="plus"></fa-icon> {{ 'button.add.attributeparameter' | translate }}
  </button>
</ng-template>

<ng-template #relevanceIconTemplate let-row>
  <fa-icon *ngIf="row.attributeRelevance?.includes('MATERIAL')" title="Material" icon="box-open" class="me-2"></fa-icon>
  <fa-icon *ngIf="row.attributeRelevance?.includes('FORMULATION')" title="Formulation" icon="flask" class="me-2"></fa-icon>
  <fa-icon *ngIf="row.attributeRelevance?.includes('PRODUCT_HIERARCHY_OBJECT')" title="Product Hierarchy Object" icon="project-diagram" class="me-2"></fa-icon>
  <fa-icon *ngIf="row.attributeRelevance?.includes('TEMPLATE_SPECIFIC')" title="Template specific" icon="book" class="me-2"></fa-icon>
</ng-template>
