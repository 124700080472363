import { Component, Input } from '@angular/core';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Login } from './login';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { OAuthAuthenticationService } from '../../service/authentication/oauth-authentication.service';
import { AuthenticationStatusService } from '../../service/authentication/authentication-status.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {

  @Input() redirectUrl: string;

  public formLoginDisabled: boolean = environment.environment === 'prod';

  constructor(
    private authenticationService: AuthenticationService,
    private authenticationStatusService: AuthenticationStatusService,
    private router: Router
  ) { }

  public getCurrentLocation(): string {
    return window.location.href;
  }

  public showOAuthLogin(): boolean {
    return window.location.hostname === "localhost" || window.location.hostname === "dev.pim.basf.com" ;
  }

  public login(username: string, password: string): void {
    this.authenticationStatusService.setAuthenticationTypeForms();
    this.authenticationService.login(username, password, this.redirectUrl);
  }

  public oAuthlogin(): void {
    this.authenticationStatusService.setAuthenticationStatusLoggingIn();
    this.authenticationService.oauthLogin();
  }

  public samlLogin(): void {
    this.authenticationStatusService.setAuthenticationTypeSaml();
    const url: string[] = window.location.href.split('/#');
    if (url.length > 1) {
      window.location.replace(environment.samlLoginUrl + '?redirectUrl=' + window.btoa(url[1]));
    } else {
      window.location.replace(environment.samlLoginUrl + '?redirectUrl=' + window.btoa(this.router.url));
    }
  }



}
