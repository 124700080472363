import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { environment } from '../../environments/environment';

@Directive({
  selector: '[minAllowedNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinNumericInputValidatorDirective, multi: true}]
})
export class MinNumericInputValidatorDirective implements Validator {
  @Input('minAllowedNumber') minAllowedNumber = environment.localizedStringMaxLength;

  validate (control: AbstractControl): ValidationErrors | null {
    const value: number = parseInt(control.value);
    return value < this.minAllowedNumber ? {maxNumber: {value: control.value}} : null;
  }
  
}
