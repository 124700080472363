import { LocalizedString } from './localized-string';
import { Attribute } from './attribute';
import { RelationshipAttribute } from './custom-attribute/relationship-attribute';
import { LocalizedEnumerationValue } from './localized-enumeration-value';
import { Category } from './category';
import { AttributeParameterValue } from './attributeparametervalue/attribute-parameter-value';
import { SingleChoiceListValue } from "./single-choice-list-value";

export class AttributeValue {
    id: string;
    attribute: Attribute;
    string: string;
    localizedString: LocalizedString[] = [];
    number: number;
    bool: boolean;
    relationship: RelationshipAttribute = new RelationshipAttribute();
    upperValue: number;
    active: boolean;
    visibility: Category = null;
    localizedEnumerationValue: LocalizedEnumerationValue;
    singleChoiceListValue: SingleChoiceListValue;
    attributeParameterValues: AttributeParameterValue[] = [];
}
