
<span>
  <ngb-highlight [result]="model.name | localizedStringsFilter" [term]="getSearchString()"></ngb-highlight>
</span>
<span>
  <small *ngIf="!!model.type" class="lighter-font">
    <i>&nbsp;[{{ 'label.type.' + this.model.type | lowercase | camelCase | translate }}]</i>
  </small>
</span>
<span *ngIf="model.regionalValidity.length > 0">
  <fa-icon [icon]="['fas', 'globe']" ngbTooltip="{{ 'label.type.region' | translate }}"></fa-icon>
  <span *ngFor="let region of model.regionalValidity; let firstItem = first">
    <span *ngIf="!firstItem">&nbsp;&#124;&nbsp;</span> 
    <span>{{ region.name | localizedStringsFilter }}</span>
  </span>
</span>
<span *ngIf="model.catalogValidity.length > 0">
  <fa-icon [icon]="['fas', 'book']" ngbTooltip="{{ 'label.type.catalog' | translate }}"></fa-icon>
  <span *ngFor="let catalog of model.catalogValidity; let firstItem = first">
    <span *ngIf="!firstItem">&nbsp;&#124;&nbsp;</span> 
    <span>{{ catalog.name | localizedStringsFilter }}</span>
  </span>
</span>