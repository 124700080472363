<div>
  <localized-string-edit
    id="localized-string-attribute-edit-{{ localizedStringEditComponentId }}"
    [maxStringLength]="!!attribute?.textLength ? attribute.textLength : environment.localizedStringMaxLength"
    [showCharactersLeft]="true"
    [inputType]="inputType"
    [readonly]="readonly"
    [localizedStrings]="localizedStrings"
    (localizedStringsChanged)="updateAttributeValue($event)">
  </localized-string-edit>
</div>
