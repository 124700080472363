export class Page<T> {
  
  public totalElements: number;
  public hasNext: boolean;
  public hasContent: boolean;
  public last: boolean;
  public totalPages: number;
  public number: number;
  public currentPage: number;
  public content: T[] = [];

  public constructor();
  public constructor(params: Partial<Page<T>>);

  public constructor(...arr: any[]) {
    if(arr.length === 1) {
      Object.assign(this, arr[0]);
    }
  }
}