import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocalizedEnumerationValue } from '../../../../model/localized-enumeration-value';

@Component({
    selector: 'localized-enumeration-attribute-type-edit',
    templateUrl: './localized-enumeration-attribute-type-edit.component.html',
    styleUrls: ['./localized-enumeration-attribute-type-edit.component.less']
  })
  export class LocalizedEnumAttributeTypeEditComponent implements OnInit {

    @Input()
    public label: string;

    @Input()
    public enumValues: LocalizedEnumerationValue[] = [];

    @Output() public localizedEnumerationValuesChange = new EventEmitter<LocalizedEnumerationValue[]>();   
    
    public activePanel: number = 1;
    
    ngOnInit(): void {
      this.createInitialValue();
      this.openFirstPanel();     
    }

    public createInitialValue() {
      if(this.enumValues.length === 0) {
        this.pushLocalizedEnumerationValue(1);
      }
    }

    public addEnumerationValue() {
      this.pushLocalizedEnumerationValue(this.enumValues.length+1);
      // Open last accordion panel
      this.openLastPanel();
    }

    private pushLocalizedEnumerationValue(order: number) {
      this.enumValues.push(new LocalizedEnumerationValue(order));
      this.localizedEnumerationValuesChange.emit(this.enumValues);
    } 

    public deleteEnumerationValue(enumValue:LocalizedEnumerationValue) {
      this.enumValues = this.enumValues.filter(obj => obj !== enumValue);
      this.updateOrder();
      // Open last accordion panel
      this.openLastPanel();
    }

    public openPanelNumber(panelNumber: number){
      this.activePanel = panelNumber;
    }

    private openFirstPanel() {
      if(this.enumValues && this.enumValues.length > 0){
        this.openPanelNumber(1);
      }
    }

    private openLastPanel() {
      if(this.enumValues && this.enumValues.length > 0){
        this.openPanelNumber(this.enumValues.length);
      }
    }

    private updateOrder() {
      this.enumValues.forEach((elem, index) => {
        elem.order = index + 1;
      });
      this.localizedEnumerationValuesChange.emit(this.enumValues);
    }

    public getEnumerationValuesOrdered() {
      return this.enumValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
    }

    public drop(event: CdkDragDrop<LocalizedEnumerationValue[]>) {
      let movements = new Map<number, number>();
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      event.container.data.forEach((value: LocalizedEnumerationValue, index: number) => {
        movements.set(value.order, index + 1);
        value.order = index + 1;
      });

      this.activePanel = movements.get(this.activePanel);
    }
  }