import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { LocalizedEnumerationValue } from '../../../../model/localized-enumeration-value';
import { Attribute } from '../../../../model/attribute';
import { AttributeValue } from '../../../../model/attribute-value';
import { AttributeValueService } from '../../../../service/attribute-value.service';
import * as _ from 'lodash';


@Component({
  selector: 'localized-enumeration-multiple-attribute-edit',
  templateUrl: './localized-enumeration-multiple-attribute-edit.component.html'
})
export class LocalizedEnumMultipleAttributeEditComponent implements OnInit {
  get attributeValues(): AttributeValue[] {
    return this._attributeValues;
  }

  @Input()
  set attributeValues(value: AttributeValue[]) {
    if(!_.isEqual(this._attributeValues, value)) {
      this._attributeValues = value;
      if (!!this.attribute) {
        this.calculateData();
      }
    }
  }

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  @Input()
  public readonly: boolean = false;

  @Output()
  public updateAttributeValueChange = new EventEmitter<AttributeValue[]>();

  private _attributeValues: AttributeValue[];

  public filteredAttributeValues: AttributeValue[] = [];

  public enumerationValuesSelected: LocalizedEnumerationValue[] = [];
  private initialAttributeValues: AttributeValue[];

  constructor(public attributeValueService: AttributeValueService) {}

  ngOnInit() {
    this.calculateData();
  }

  private calculateData() {
    this.filteredAttributeValues = this.attributeValueService.getAttributeValues(this._attributeValues, this.attribute);
    this.initialAttributeValues = [...this.filteredAttributeValues];
    for (const initialAttributeValue of this.initialAttributeValues) {
      if (initialAttributeValue && initialAttributeValue.localizedEnumerationValue) {
        this.enumerationValuesSelected.push(initialAttributeValue.localizedEnumerationValue);
      }
    }
    this.attribute.localizedEnumerationValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
  }

  public updateSelectedValues(selectedValue: LocalizedEnumerationValue) {

    this.filteredAttributeValues = this.filteredAttributeValues.filter(attributeValue => {
      return this.enumerationValuesSelected.includes(attributeValue.localizedEnumerationValue);
    });

    this.enumerationValuesSelected.forEach(enumValue => {
      this.addAttributeValue(enumValue);
    });

    this.updateAttributeValueChange.emit(this.filteredAttributeValues);
  }

  private addAttributeValue(enumValue: LocalizedEnumerationValue): void {
    if (!this.filteredAttributeValues.some(av => av.localizedEnumerationValue === enumValue)) {
      const newAttributeValue: AttributeValue = this.attributeValueService.createNewAttributeValue(this.attribute);
      newAttributeValue.localizedEnumerationValue = enumValue;
      this.filteredAttributeValues.push(newAttributeValue);
    }
  }

  public compareFn = (a, b) => !!a && !!b && a.id === b.id;
}
