import { Component, Input } from '@angular/core';

@Component({
  selector: 'pim-popover',
  templateUrl: './pim-popover.component.html',
  styleUrls: ['./pim-popover.component.less']
})
export class PimPopoverComponent {

  @Input()
  public isActive = false;

}
