import { Component, Input } from '@angular/core';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Login } from './login';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {

  @Input() redirectUrl: string;

  private currentLogin: Login = new Login('', '');

  public formLoginDisabled: boolean = environment.environment === 'prod';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  public login(username: string, password: string): void {
    localStorage.setItem('login', 'forms');
    this.authenticationService.login(username, password, this.redirectUrl);
  }

  public samlLogin(): void {
    localStorage.setItem('login', 'saml');
    const url: string[] = window.location.href.split('/#');
    if(url.length > 1) {
      window.location.replace(environment.samlLoginUrl + '?redirectUrl=' + window.btoa(url[1]));
    } else {
      window.location.replace(environment.samlLoginUrl + '?redirectUrl=' + window.btoa(this.router.url));
    }
  }

}
