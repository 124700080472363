<div>
  <div id="copy-assignments-button" class="copy-assignments-button">
    <div [@horizontalFadeInOutToTheRight] *ngIf="!showTypeAhead"
       id="copy-assignments-button-text"
       class="copy-assignments-button-text"
       title="{{ showTypeAhead ? ('copy.assignments.start.copy' | translate) : ('copy.assignments.button.tooltip' | translate) }}"
       (click)="showTypeAhead = !showTypeAhead">{{ 'copy.assignments.button.text' | translate }}
    </div>
    <div id="copy-assignments-button-icon"
         class="copy-assignments-button-icon"
         [ngClass]="{ 'copy-assignments-button-icon-on-typeahead': showTypeAhead }"
         title="{{ showTypeAhead ? ('copy.assignments.start.copy' | translate) : ('copy.assignments.button.tooltip' | translate) }}">
      <fa-icon icon="{{!!model || !showTypeAhead ? 'arrow-right-from-bracket' : 'x'}}" (click)="copyButtonClicked()"></fa-icon>
    </div>
  </div>
  <div [@horizontalFadeInOutToTheLeft] *ngIf="showTypeAhead" class="copy-assignments-button-typeahead">
    <typeahead id="copy-assignments-button-typeahead"
      [typeaheadId]="'copy-assignments-button-typeahead'"
      [(model)]="model"
      [inputPlaceholder]="'copy.assignments.typeahead.placeholder' | translate"
      [typeaheadFunction]="getTypeAheadFunction"
      [resultFormatter]="getResultFormatter"
      [valueTemplate]="getValueTemplate()"
      [inputFormatter]="getResultFormatter">
    </typeahead>
  </div>
  <div [ngClass]="{'copy-assignments-background-on-typeahead': showTypeAhead, 'copy-assignments-background': !showTypeAhead }">
    <div id="copy-assignments-options" class="copy-assignments-options">
      <div id="copy-assignments-background-technical-category-text" class="copy-assignments-background-technical-category">
        {{ 'copy.assignments.technical.categories.label' | translate }}
      </div>
      <div class="copy-assignments-prd-checkbox"
           title="AttributeValues will be copied if activated on source and not activated on the target">
        <input id="copy-assignments-prd-checkbox"
               class="form-check-input"
               type="checkbox" [(ngModel)]="copyUnmaintainedPrdAttributeValues">
        <label class="form-check-label" for="copy-assignments-prd-checkbox">{{ 'copy.prd.attributes.value.checkbox' | translate }}</label>
      </div>
    </div>
  </div>
</div>
