import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { Slice } from '../../model/pim-response/slice';
import { Language } from '../../model/language';
import { Pageable } from '../../model/pageable';
import { TypeaheadExcludeIdRequest } from '../../model/request/typeahead-exclude-id-request';
import { BaseTypeaheadService } from './base-typeahead.service';
import { TypeaheadExcludeIncludeIdRequest } from '../../model/request/typeahead-exclude-and-include-id-request';

@Injectable({
  providedIn: 'root'
})
export class LanguageTypeaheadService implements AbstractTypeaheadService<Language> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[], onlyInclude: string[]): Observable<Slice<Language>> {
  
    if(onlyInclude.length > 0) {
      let body = new TypeaheadExcludeIncludeIdRequest(
        searchTerm,
        this.baseTypeaheadService.getRequestLanguage(),
        exclude,
        onlyInclude
      );

      return this.baseTypeaheadService.createCall('/language/typeahead/enriched', body, pageable);

    } else {
      let body = new TypeaheadExcludeIdRequest(
        searchTerm,
        this.baseTypeaheadService.getRequestLanguage(),
        exclude
      );
  
      return this.baseTypeaheadService.createCall('/language/typeahead', body, pageable);  
    }
    
  }

  public typeaheadFormatter(model: Language): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }
}
