import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Page} from '../../../model/pim-response/page';
import {Unit} from '../../../model/unit';
import {Pageable} from '../../../model/pageable';
import {UnitRelevance} from '../../../model/unit-relevance';
import {NotificationService} from '../../../service/notification.service';
import {LocalizedStringService} from '../../../service/localized-string.service';
import {UnitService} from '../../../service/unit.service';
import {ConfirmService} from '../../../service/confirm.service';
import {UnitTypeaheadService} from '../../../service/typeahead/unit-typeahead.service';
import {cloneDeep} from 'lodash';
import {LocalizedString} from '../../../model/localized-string';
import {HasUnsavedChangesGuard} from '../../../guards/has-unsaved-changes.guard';

@Component({
  selector: 'unit-administration',
  templateUrl: './unit-administration.component.html'
})
export class UnitAdministrationComponent implements OnInit, HasUnsavedChangesGuard {

  public unit: Unit = new Unit();
  public unitOrig: Unit = null;
  public units: Page<Unit>;
  public edit = false;
  public pageable: Pageable = new Pageable();
  public searchString = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly unitService: UnitService,
    private readonly unitTypeaheadService: UnitTypeaheadService,
    private readonly notificationService: NotificationService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly confirmService: ConfirmService
  ) {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.unitService.load(params.id).subscribe((unit: Unit) => {
          this.unit = unit;
          this.unitOrig = cloneDeep(this.unit);
          this.edit = true;
        });
      }
    });
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('page')) {
      this.pageable.page = Number(this.route.snapshot.queryParamMap.get('page'));
    }
    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchString = this.route.snapshot.queryParamMap.get('search');
    }
    this.loadData();
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.unitChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public visibleUnitRelevance(): string[] {
    const visibleUnitRelevance: string[] = [];
    visibleUnitRelevance.push(UnitRelevance.FORMULATION);
    return visibleUnitRelevance;
  }

  public getLocalizedString: (ls: LocalizedString[]) => (string) = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  public getUnitName: (unit: Unit) => (string) = (value: Unit) => this.localizedStringService.getLocalizedStringsValue(!!value ? value.name : null);

  public unitChanges(): boolean {
    if (this.edit && !this.unitService.isEqual(this.unitOrig, this.unit)) {
      return true;
    } else {
      return false;
    }
  }

  public refresh() {
    this.router.navigate([], { queryParams: { search: this.searchString, page: this.pageable.page } });
    this.loadData();
  }

  private loadData() {
    this.unitService.search(this.searchString, this.pageable).subscribe((page: Page<Unit>) => {
      this.units = page;
    });
  }

  public switchPage(pageable: Pageable) {
    this.pageable = pageable;
    this.refresh();
  }

  public search() {
    this.pageable = new Pageable();
    this.refresh();
  }

  public editItem(unit: Unit) {
    this.router.navigateByUrl('adminstration/unit/' + unit.id);
  }

  public unitTypeahead = (searchTerm: string, pageable: Pageable) => this.unitTypeaheadService.typeaheadSearch(searchTerm, pageable);
  public unitTypeaheadFormatter = (model: Unit) => this.unitTypeaheadService.typeaheadFormatter(model);

  public newUnit() {
    this.unit = new Unit();
    this.edit = true;
  }

  public save() {
    this.unitService.save(this.unit).subscribe((unit: Unit) => {
      this.notificationService.addSuccessNotification('label.successfully.saved');
      this.unit = unit;
      this.unitOrig = cloneDeep(this.unit);
      this.editItem(this.unit);
    });
  }

  public cancel() {
    if (!this.unit.id) {
      if(!this.unitService.isEqual(this.unitOrig, this.unit)) {
        this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no').then(confirmed => {
          if (confirmed) {
            this.edit = false;
          }
        });
      } else {
        this.edit = false;
      }
    }
    this.router.navigateByUrl('adminstration/unit');
  }

  public isEnumSelected(value) {
    if (this.unit.unitRelevance) {
      return this.unit.unitRelevance.find((item) => item === value);
    } else {
      return false;
    }

  }

  public onChangeRelevance(event, relevance: UnitRelevance) {
    if (event.target.checked) {
      this.unit.unitRelevance.push(relevance);
    } else {
      this.unit.unitRelevance.splice(this.unit.unitRelevance.indexOf(relevance), 1);
    }
  }
}
