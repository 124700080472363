import { AttributeRelevance } from './attribute-relevance';
import { AttributeValue } from './attribute-value';
import { Category } from './category';
import { Formulation } from './formulation';
import { AttributeValueRelation } from './attribute-value-relation';

export class CategoryFormulation implements AttributeValueRelation {
  id: string;
  formulation: Formulation;
  category: Category;
  attributeValues: AttributeValue[] = [];
  readonly relevance: AttributeRelevance = AttributeRelevance.FORMULATION;
}
