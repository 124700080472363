<div class="form-group">
  <label [for]="'inputEdit_' + guid">{{label}}</label>
  <input *ngIf="required"
    class="form-control"
    type="text"
    [id]="'inputEdit_' + guid"
    [name]="'inputEdit_'+ guid"
    [ngModel]="getinputValue()"
    (ngModelChange)="setinputValue($event)"
    required
    [disabled]="readonly">

  <input *ngIf="!required"
    class="form-control"
    type="text"
    [id]="'inputEdit_' + guid"
    [name]="'inputEdit_'+ guid"
    [ngModel]="getinputValue()"
    (ngModelChange)="setinputValue($event)"
    [disabled]="readonly">
</div>