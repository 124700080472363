import { Component, Input } from '@angular/core';

@Component({
  selector: 'typeahead-template-material',
  templateUrl: './material.html',
  styleUrls: ['./material.less']
})
export class MaterialTemplate {

  @Input() 
  public model: any;

  @Input()
  public term: string = null;

}
