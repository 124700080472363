export class DocumentVersionInputSettings {
  constructor(
    public disableLanguage: boolean = false,
    public disableVersion: boolean = false,
    public disablePublishDate: boolean = false,
    public disableExpirationDate: boolean = false,
    public disableFile: boolean = false,
    public disableChangeReason: boolean = false,
    public disableChangeComment: boolean = false) {
  }
}
