import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TableColumn } from '../../model/table-column';
import { Page } from '../../model/pim-response/page';
import { ProductHierarchyObject } from '../../model/product-hierarchy-object/product-hierarchy-object';
import { ProductHierarchyObjectService } from '../../service/product-hierarchy-object.service';
import { TableService } from '../../service/table.service';
import InjectIsReadonlyUser from '../../decorator/inject-is-readonly-user.decorator';
import { LocalizedString } from '../../model/localized-string';
import { LocalizedStringService } from '../../service/localized-string.service';
import { OverviewService } from "../../service/overview.service";

@Component({
  selector: 'app-product-hierarchy-object',
  templateUrl: './product-hierarchy-object.component.html',
  styleUrls: ['./product-hierarchy-object.component.less']
})
//TODO: make interface to force to include typeahead etc.
export class ProductHierarchyObjectComponent implements OnInit, OnDestroy {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public searchFunction: (queryParameters: Params) => Observable<Page<ProductHierarchyObject>> = (queryParameters: Params) => this.executeSearch(queryParameters);
  public facetFields: string;
  public readonly typeaheadFieldLabelPrefix : string = "label.product_hierarchy_object.overview.typeahead."

  public tableColumns: TableColumn<any>[];

  constructor(
    private readonly tableService: TableService,
    private readonly overviewService: OverviewService,
    private readonly translateService: TranslateService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly productHierarchyObjectService: ProductHierarchyObjectService,
  ) {}

  ngOnInit(): void {

    this.tableColumns = [
      new TableColumn("owner", this.translateService.get("label.product_hierarchy_object.column.owner"), (value: string) => this.tableService.stringValueFunction(value), true, false),
      new TableColumn("internalName", this.translateService.get("label.product_hierarchy_object.column.name"), (value: string) => this.tableService.stringValueFunction(value), false, true),
      new TableColumn("type", this.translateService.get("label.product_hierarchy_object.column.type"), (value: string) => this.tableService.stringValueFunction(value), true, false),
      new TableColumn("realm", this.translateService.get("label.product_hierarchy_object.column.realm"), (realm: LocalizedString[]) => this.getLocalizedStringsValue(realm), true, false),
      new TableColumn("createdBy", this.translateService.get("label.product_hierarchy_object.column.created_by"), (value: string) => this.tableService.stringValueFunction(value), true, false)
    ];

    this.facetFields = this.tableColumns
      .filter(c => c.isFilterable)
      .map(c => c.field).join(",");
  }

  ngOnDestroy(): void {
    this.overviewService.resetPage();
  }

  public executeSearch(queryParameters: Params): Observable<Page<ProductHierarchyObject>> {
    return this.productHierarchyObjectService.search(queryParameters);
  }

  public getLocalizedStringsValue = (localizedStrings: LocalizedString[], showNameNotFound: boolean = false) =>
    this.localizedStringService.getLocalizedStringsValue(localizedStrings, showNameNotFound);

}
