import { Injectable } from '@angular/core';
import { FormValidationService } from '../form-validation.service';
import { NotificationService } from '../notification.service';
import { Formulation } from '../../model/formulation';

@Injectable()
export class FormulationFormValidationService extends FormValidationService {

  constructor(protected notificationService: NotificationService) {
    super(notificationService);

    this.addValidation(this.verifyName);
    this.addValidation(this.verifyMarketingType);
    this.addValidation(this.verifyOwner);
  }

  public verifyName(validation: FormulationFormValidationService, formulation: Formulation): boolean {

    let valid = false;

    formulation.name.forEach(item => {
      if (item.language.isoCode === 'en' && item.value) {
        if (item.value.length > 0) {
          valid = true;
        }
        return;
      }
    });

    if (!valid) {
      validation.addError('label.formulation.name_is_required');
    }

    return valid;
  }

  public verifyMarketingType(validation: FormulationFormValidationService, formulation: Formulation): boolean {
    if (!formulation.marketingType) {
      validation.addError('label.formulation.marketing_type_is_required');
      return false;
    }
    return true;
  }

  public verifyOwner(validation: FormulationFormValidationService, formulation: Formulation): boolean {
    if (!formulation.realm) {
      validation.addError('label.formulation.realm_is_required');
      return false;
    }
    return true;
  }

}

