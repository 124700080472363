import { ProductHierarchyObject } from "../product-hierarchy-object/product-hierarchy-object";

export class ProductHierarchyObjectNode {

  constructor(
    public root: ProductHierarchyObject, 
    public pho: ProductHierarchyObject, 
    public level: number
  ) {}
}
