<div class="range-row">
  <input class="form-control range" type="text"
      [(ngModel)]="lowerValue"
      [disabled]="readonly"
      [name]="'range_lower_' + getId()"
      [id]="'range_lower_' + getId()"
      numeric
      numericType="decimal">
  <span class="to-sign">-</span>
  <input class="form-control range" type="text"
      [(ngModel)]="upperValue"
      [disabled]="readonly"
      [name]="'range_upper_' + getId()"
      [id]="'range_upper_' + getId()"
      numeric
      numericType="decimal">
</div>