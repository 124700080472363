import { Injectable } from '@angular/core';

@Injectable()
export class ConnectionService {

  private publicConnections: string[] = [];
  private hiddenConnections: string[] = [];

  public get allConnections(): string[] {
    return this.publicConnections.concat(this.hiddenConnections);
  }

  public displayLoadingIndicator(): boolean {
    return this.publicConnections.length > 0;
  }

  public newConnection(hideLoadingIndicator: boolean, label: string = null): void {
    if (!hideLoadingIndicator) {
      this.publicConnections.push(this.getConnectionKey(label));
    } else {
      this.hiddenConnections.push(this.getConnectionKey(label));
    }
  }

  public connectionClosed(hideLoadingIndicator: boolean, label: string = null): void {
    if (this.publicConnections.length > 0 && !hideLoadingIndicator) {
      if (this.publicConnections.some(con => con == this.getConnectionKey(label))) {
        this.publicConnections = this.publicConnections.filter(con => con != this.getConnectionKey(label));
      } else {
        this.stopLoadingIndicator();
      }
    }
    else if (this.hiddenConnections.length > 0 && hideLoadingIndicator) {
      if (this.hiddenConnections.some(con => con == this.getConnectionKey(label))) {
        this.hiddenConnections = this.hiddenConnections.filter(con => con != this.getConnectionKey(label));
      } else {
        this.hiddenConnections = [];
      }
    }
  }

  public stopLoadingIndicator(): void {
    this.publicConnections = [];
  }

  private readonly getConnectionKey = (label: string) => {
    return String(label);
  }

}
