import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Category } from './../../../model/category';
import { CategoryType } from './../../../model/category-type';

import { CategoryService } from '../../../service/category.service';
import { LocalizedStringService } from '../../../service/localized-string.service';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.less']
})
export class CategoryDetailComponent {

  public category: Category;
  public categoryType = CategoryType;
  public disabledInputs = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly categoryService: CategoryService,
    private readonly localizedStringService : LocalizedStringService
  ) {

    // initilize category to make sure objects attributes are available in initialization
    this.category = new Category();
    this.route.params.subscribe(params => {
      if (params.id) {
        this.categoryService.load(params.id).subscribe((category: Category) => this.category = category);
      }
    });
  }

  return() {
    this.router.navigateByUrl('/categories');
  }

  public createParentList(category: Category, nodeList: any = []): Category[] {

    if (category.parent) {
      nodeList =  this.createParentList(category.parent, nodeList);
    }

    nodeList.push(category);

    return nodeList;
  }

  public categoryFormatterInput = (category: Category = null) => {
    if (category) {
      let translatedName = this.localizedStringService.getLocalizedStringsValue(category.name);

      if (category.parent) {
        return this.categoryFormatterInput(category.parent) + ' > ' + translatedName;
      }
      return translatedName;

    } else {
      return '';
    }
  }
}
