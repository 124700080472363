import { Component, Input } from '@angular/core';

@Component({
  selector: 'typeahead-template-pho-internal-name-path',
  templateUrl: './pho-internal-name-path.html',
  styleUrls: ['./pho-internal-name-path.less']
})
export class TypeaheadPhoInternalNameWithPathTemplate {

  @Input() 
  public model: any;

  public createParentList(object: any, nodeList: any[] = []): any[] {
    if (object.parent) {
      nodeList = this.createParentList(object.parent, nodeList);
    }
    nodeList.push(object);
    return nodeList;
  }
}
