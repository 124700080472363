import { Component, Input, OnInit } from '@angular/core';
import { AttributeParameterLocalizedEnumerationValue } from '../../../../model/attributeparametervalue/attribute-parameter-localized-enumeration-value';
import { AttributeValue } from '../../../../model/attribute-value';
import { LocalizedEnumerationValue } from '../../../../model/localized-enumeration-value';

@Component({
  selector: 'localized-enumeration-attribute-edit',
  templateUrl: './localized-enumeration-attribute-edit.component.html',
  styleUrls: ['./localized-enumeration-attribute-edit.component.less']
})
export class LocalizedEnumAttributeEditComponent implements OnInit {

  @Input()
  public label: string;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public parameterValue: AttributeParameterLocalizedEnumerationValue;

  @Input()
  public readonly: boolean = false;

  public value: LocalizedEnumerationValue;

  ngOnInit(): void {
    this.value = this.getValue();
  }

  public getValue = () => !!this.attributeValue ? this.attributeValue.localizedEnumerationValue : this.parameterValue.localizedEnumerationValue;

  private getEnumerationValues()  {
    return !!this.attributeValue ? this.attributeValue.attribute.localizedEnumerationValues : this.parameterValue.attributeParameter.localizedEnumerationValues;
  }

  public compareFn = (a, b) => a && b && a.id === b.id;

  public getSortedEnumerationValues(): LocalizedEnumerationValue[] {
    return this.getEnumerationValues().sort((a, b) => (a.order < b.order) ? -1 : 1 );
  }

  public updateSelectedValues(selectedValue: LocalizedEnumerationValue) {
    if(!!this.attributeValue) {
      this.attributeValue.localizedEnumerationValue = selectedValue;
    }
    else {
      this.parameterValue.localizedEnumerationValue = selectedValue;
    }
  }
}
