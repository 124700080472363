import { LocalizedString } from './localized-string';
import { AttributeParameterType } from './attribute-parameter-type';
import { Unit } from './unit';

export class AttributeParameter {
    id: string;
    name: LocalizedString[] = [];
    internalDescription: string;
    unit: Unit;
    type: AttributeParameterType;
}