import { Document } from '../../model/document/document';
import { Injectable } from '@angular/core';
import { CategoryService } from '../category.service';
import { LocalizedStringService } from '../localized-string.service';
import { CommonComparisonService } from './common-comparison.service';
import { MaterialService } from '../material.service';
import { BusinessHierarchyService } from '../business-hierarchy.service';
import { DocumentVersion } from '../../model/document-version/document-version';
import { LanguageService } from '../language.service';
import { orderBy } from 'lodash';
import { ComparisonService } from './comparison-service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DocumentComparisonService implements ComparisonService<Document> {

  constructor(
    private readonly localizedStringService: LocalizedStringService,
    private readonly categoryService: CategoryService,
    private readonly materialService: MaterialService,
    private readonly commonComparisonService: CommonComparisonService,
    private readonly languageService: LanguageService,
    private readonly businessHierarchyService: BusinessHierarchyService
  ) { }

  public createComparisonObject(model: Document) {
    const documentName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(model?.name);
    const documentDescription = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(model?.description);
    const documentVersions = this.getOrderedVersions(model?.versions);
    const documentTypeId = model.type ? model.type?.id : null;
    const documentSecurityClassId = model.securityClass ? model.securityClass?.id : null;
    const documentRegions = this.categoryService.getOrderedCategoryIds(model?.regions);
    const documentRealmId = model.realm ? model.realm?.id : null;
    const documentMaterials = this.materialService.getOrderedMaterialIds(model?.materials);
    const documentCategories = this.categoryService.getOrderedCategoryIds(model?.categories);
    const documentIndustries = this.categoryService.getOrderedCategoryIds(model?.industries);
    const documentOperatingDivisions = this.businessHierarchyService.getOrderedBusinessHierarchyIds(model?.operatingDivisions);
    const phoIds: string[] = _(model.productHierarchyObjects).map("id").uniq().sort().value();
    const phoMaterialIds: string[] = _(model?.productHierarchyObjectMaterials).map("id").uniq().sort().value();
    const documentStatus = model?.status;
    const realmRestriction: string = model?.realmRestriction;
    const metadataVisibility: string = model?.metadataVisibility;

    const comparisonObject: any = {
      id: model.id,
      name: documentName,
      description: documentDescription,
      versions: documentVersions,
      type: documentTypeId,
      securityClass: documentSecurityClassId,
      regions: documentRegions,
      realm: documentRealmId,
      materials: documentMaterials,
      categories: documentCategories,
      industries: documentIndustries,
      operatingDivisions: documentOperatingDivisions,
      productHierarchyObjects: phoIds,
      productHierarchyObjectMaterials: phoMaterialIds,
      status: documentStatus,
      realmRestriction: realmRestriction,
      metadataVisibility: metadataVisibility
    };

    this.commonComparisonService.validateComparisonObject(model, comparisonObject, ["createdBy", "modifiedBy", "createdDate", "modifiedDate", "highlights"]);

    return comparisonObject;

  }

  private getOrderedVersions(versions: DocumentVersion[]): any[] {
    return orderBy(versions.map(version => this.getDocumentVersionObj(version)), ['id']);
  }

  private getDocumentVersionObj(documentVersion: DocumentVersion): any {
    const documentversionLanguages = this.languageService.getOrderedLanguages(documentVersion.languages);
    return {
      id: documentVersion.id,
      file: documentVersion.file,
      version: documentVersion.version,
      validFrom: documentVersion.validFrom,
      validUntil: documentVersion.validUntil,
      languages: documentversionLanguages
    };
  }
}
