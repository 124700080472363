import {Component, Input, TemplateRef} from '@angular/core';
import { CopyMaterialAssignmentsService } from '../../../service/copy-assignments/copy-material-assignments.service';
import {BasicAnimations} from '../../../animation/basic-animations';
import {Material} from '../../../model/material';
import {of} from 'rxjs';
import {Pageable} from '../../../model/pageable';

@Component({
  selector: 'app-copy-assignments',
  templateUrl: './copy-assignments.component.html',
  styleUrls: ['./copy-assignments.component.less'],
  animations: [
    BasicAnimations.horizontalFadeInOutToTheRightTrigger,
    BasicAnimations.horizontalFadeInOutToTheLeftTrigger
  ]
})
export class CopyAssignmentsComponent {

  @Input()
  private copyAssignmentService: CopyMaterialAssignmentsService; // elaborate like e.g. copyAssignmentService: CopyMaterialAssignmentsService | CopyFormulationAssignmentsService;

  @Input()
  private valueTemplate: TemplateRef<any>;

  public set copyUnmaintainedPrdAttributeValues(ticked: boolean) {
    this.copyAssignmentService.copyUnmaintainedPrdAttributeValues = ticked;
  }

  public get copyUnmaintainedPrdAttributeValues() {
    return this.copyAssignmentService.copyUnmaintainedPrdAttributeValues;
  }

  public get model(): Material { // elaborate like e.g. model(): Material | Formulation {
    return this.copyAssignmentService.model;
  }

  public set model(newModel: Material) { //  elaborate like .e.g. model(newModel: Material | Formulation) {
    this.copyAssignmentService.model = newModel;
  }

  public showTypeAhead: boolean = false;

  public getValueTemplate = () => {
    return this.valueTemplate;
  }

  public getResultFormatter = (model: Material) => {
    return this.copyAssignmentService.formatFunction(model);
  }

  public getTypeAheadFunction = (materialName: string, pageable: Pageable) => {
    return materialName.length < 2 ? of(null) : this.copyAssignmentService.searchFunction(materialName, pageable);
  }

  public copyButtonClicked() {
    if (!!this.copyAssignmentService.model?.id) {
      this.copyAssignmentService.startCopy().subscribe({next: cms => this.showTypeAhead = false});
    } else {
      this.showTypeAhead = !this.showTypeAhead;
    }
  }

}
