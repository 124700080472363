<ng-template #materialResultTemplate let-result="result" let-term="term">
  <div>
    <div>
      <span [innerHTML]="result.name | localizedStringsFilter | highlightTerm:term"></span>&nbsp;
      <span *ngIf="result.type === 'PRD'" [innerHTML]="'(' + result.id + ') ' | highlightTerm:term"></span>
    </div>
    <div *ngIf="result.type !== 'THIRD_PARTY_PRODUCT'">
      <small>{{'formulationstep.label.' + result.type  | lowercase | translate}}</small>
    </div>
    <div *ngIf="result.type === 'THIRD_PARTY_PRODUCT'">
      <small>{{'label.material.search.supplier' | translate}}: </small>
      <small [innerHTML]="result.supplier | highlightTerm:term"></small>
    </div>
  </div>
</ng-template>

<div>
  <div class="row">
    <div class="col-12 mb-3 align-left">
      <icon-button *ngIf="!(isReadOnlyUser | async)" buttonType="add" buttonLabel="{{ 'button.add.part' | translate}}" (buttonClick)="generateNewPart()"></icon-button>
    </div>
    <div class="col-12" *ngIf="showNewPart">
       <div class="form-group col-4">
         <localized-string-edit id="formulation-steps-new-part-name" [localizedStrings]="part.name" [label]="'label.part.name' | translate"></localized-string-edit>
       </div>
       <div class="form-group col-4">
         <input name="newPartRatio" [(ngModel)]="part.ratio" type="text" class="form-control"
         numeric numericType="decimal" placeholder="{{'formulationstep.label.ratio' | translate}}">
       </div>
      <div class="form-group col-4 align-right">
        <icon-button buttonType="remove" buttonHideLabel="true" (buttonClick)="cancelNewPart()"></icon-button>
        <icon-button buttonType="confirm" buttonHideLabel="true" (buttonClick)="confirmNewPart()"></icon-button>
      </div>
    </div>
  </div>

  <div *ngFor="let part of findFormulation().parts; let partIndex = index; let lastItem = last" class="card" [ngClass]="{'mb-3': !lastItem }">
    <div class="card-header pb-1">
      <div class="row">
        <div class="col-10" *ngIf="!isEditPart(partIndex)">
          <label>{{part.name | localizedStringsFilter}}</label><label *ngIf="getPartName(part) !== '' && part.ratio">&nbsp;-&nbsp;</label><label *ngIf="part.ratio">{{'formulationstep.label.ratio' | translate}}: {{part.ratio}}</label>
        </div>
        <div class="col-10" *ngIf="isEditPart(partIndex)">
          <div class="form-group col-4">
            <localized-string-edit id="formulation-steps-part-name" [localizedStrings]="part.name" [label]="'label.part.name' | translate">
            </localized-string-edit>
          </div>
          <div class="form-group col-4">
            <input name="newPartRatio" [(ngModel)]="part.ratio" type="text" class="form-control"
            numeric numericType="decimal" placeholder="{{'formulationstep.label.ratio' | translate}}">
          </div>
          <div class="form-group col-4 align-right">
            <icon-button buttonType="remove" buttonHideLabel="true" (buttonClick)="cancelEditPart(part)"></icon-button>
            <icon-button buttonType="confirm" buttonHideLabel="true" (buttonClick)="confirmEditPart()"></icon-button>
          </div>
        </div>
        <div class="col-2 align-right" *ngIf="!isEditPart(partIndex)">
          <icon-button *ngIf="!(isReadOnlyUser | async)" buttonType="edit" [buttonDisabled]="disablePartEdit" buttonHideLabel="true" (buttonClick)="editPart(part, partIndex)"></icon-button>
          <icon-button *ngIf="!(isReadOnlyUser | async)" buttonType="remove" [buttonDisabled]="disablePartEdit" buttonHideLabel="true" (click)="$event.preventDefault(); $event.stopPropagation(); openPartDeletionDialog(part)"></icon-button>
        </div>
    </div>
    </div>
    <div class="card-body" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="part.steps">
      <div class="col-12 mb-3 align-right pr-0">
        <icon-button *ngIf="!(isReadOnlyUser | async)" buttonType="add" buttonLabel="{{ 'button.add.step' | translate}}" (buttonClick)="newStep(partIndex)"></icon-button>
      </div>
      <div *ngFor="let step of part.steps; let stepIndex = index; let lastItem = last" class="row span-group-text mb-2" cdkDrag>
        <div class="col-4">
          <div *ngIf="!isEditStep(partIndex, stepIndex)"><span >{{step.material?.name | localizedStringsFilter}}&nbsp;</span>
            <span *ngIf="step.material?.type === 'PRD'">({{step.material?.id}})&nbsp;</span>
            <span>- {{'formulationstep.label.' + step.material?.type  | lowercase | translate}}&nbsp;</span>
            <small *ngIf="step.material?.type === 'THIRD_PARTY_PRODUCT'">- {{'label.material.search.supplier' | translate}}: </small>
            <small *ngIf="step.material?.type === 'THIRD_PARTY_PRODUCT'">{{step.material?.supplier}}</small>
          </div>

          <typeahead *ngIf="isEditStep(partIndex, stepIndex)"
              [inputPlaceholder]="'label.formulationstep.material.placeholder' | translate"
              [inputName]="'editStepMaterial'"
              [inputRequired]="true"
              [(model)]="step.material"
              [typeaheadFunction]="materialTypeahead"
              [resultTemplate]="materialResultTemplate"
              [inputFormatter]="materialTypeaheadFormatter">
          </typeahead>

        </div>
        <div class="col-2">
          <span *ngIf="!isEditStep(partIndex, stepIndex)">{{ step.quantity }}</span>
          <input *ngIf="isEditStep(partIndex, stepIndex)" name="editStepQuantity" [(ngModel)]="step.quantity" type="text" class="form-control"
          numeric required numericType="decimal" placeholder="{{'label.formulationstep.quantity.placeholder' | translate}}">
        </div>
        <div class="col-2">
          <span *ngIf="!isEditStep(partIndex, stepIndex) || !thereIsOnlyOneStep()">{{ step.unit?.name | localizedStringsFilter }}</span>
          <select *ngIf="thereIsOnlyOneStep() && isEditStep(partIndex, stepIndex)" class="form-select" required name="editStepUnit" [(ngModel)]="step.unit" [compareWith]="compareUnitById">
            <option *ngFor="let unit of formulationUnits" [ngValue]="unit">{{ unit.name | localizedStringsFilter }}</option>
          </select>
        </div>

        <div class="col-3">
          <span *ngIf="!isEditStep(partIndex, stepIndex)">{{ step.function?.name | localizedStringsFilter }}</span>

          <typeahead *ngIf="isEditStep(partIndex, stepIndex)"
              [inputPlaceholder]="'label.formulationstep.function.placeholder' | translate"
              [inputName]="'editStepFunction'"
              [(model)]="step.function"
              [typeaheadFunction]="productGroupTypeahead"
              [resultFormatter]="productGroupTypeaheadFormatter"
              [inputFormatter]="productGroupTypeaheadFormatter">
          </typeahead>

        </div>
        <div class="col-1 align-right">
          <icon-button *ngIf="!isEditStep(partIndex, stepIndex) && !(isReadOnlyUser | async)" [buttonDisabled]="disableEdit" buttonType="edit" buttonHideLabel="true" (buttonClick)="editStep(step, partIndex, stepIndex)"></icon-button>
          <icon-button *ngIf="!isEditStep(partIndex, stepIndex) && !(isReadOnlyUser | async)" [buttonDisabled]="disableEdit" buttonType="remove" buttonHideLabel="true" (buttonClick)="removeStep(part, step)"></icon-button>

          <icon-button *ngIf="isEditStep(partIndex, stepIndex) && !(isReadOnlyUser | async)" buttonType="remove" buttonHideLabel="true" (buttonClick)="cancelEditStep(part, step)"></icon-button>
          <icon-button *ngIf="isEditStep(partIndex, stepIndex) && !(isReadOnlyUser | async)" buttonType="confirm" buttonHideLabel="true" (buttonClick)="confirmEditStep(part, step)"></icon-button>
        </div>
      </div>

      <div *ngIf="isNewStep(partIndex)" class="row input-group-text">
        <div class="col-4">

          <typeahead
              [inputPlaceholder]="'label.formulationstep.material.placeholder' | translate"
              [inputName]="'newStepMaterial'"
              [inputRequired]="true"
              [(model)]="newFormulationStep.material"
              [typeaheadFunction]="materialTypeahead"
              [resultTemplate]="materialResultTemplate"
              [inputFormatter]="materialTypeaheadFormatter">
          </typeahead>

        </div>
        <div class="col-2">
          <input name="newStepQuantity" [(ngModel)]="newFormulationStep.quantity" type="text" class="form-control"
          numeric required numericType="decimal" placeholder="{{'label.formulationstep.quantity.placeholder' | translate}}">
        </div>
        <div class="col-2">
          <span *ngIf="isCurrentUnitDefined()">{{newFormulationStep.unit?.name | localizedStringsFilter}}</span>
          <select *ngIf="!isCurrentUnitDefined()" class="form-select" required name="newStepUnit" [(ngModel)]="newFormulationStep.unit">
            <option [ngValue]="null" [disabled]="true" >{{'label.formulationstep.unit.label' | translate}}</option>
            <option *ngFor="let unit of formulationUnits" [ngValue]="unit">{{ unit.name | localizedStringsFilter }}</option>
          </select>
        </div>
        <div class="col-3">

          <typeahead
              [inputPlaceholder]="'label.formulationstep.function.placeholder' | translate"
              [inputName]="'newStepFunction'"
              [(model)]="newFormulationStep.function"
              [typeaheadFunction]="productGroupTypeahead"
              [resultFormatter]="productGroupTypeaheadFormatter"
              [inputFormatter]="productGroupTypeaheadFormatter">
          </typeahead>

        </div>
        <div class="col-1 align-right">
          <icon-button *ngIf="!(isReadOnlyUser | async)" buttonType="remove" buttonHideLabel="true" (buttonClick)="cancelNewStep()"></icon-button>
          <icon-button *ngIf="!(isReadOnlyUser | async)" buttonType="confirm" buttonHideLabel="true" (buttonClick)="confirmNewStep(part)"></icon-button>
        </div>
      </div>
    </div>
  </div>
</div>

