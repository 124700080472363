import { Component, OnInit } from '@angular/core';
import { CustomView } from '../../../../../model/custom-view/custom-view';
import { LocalizedString } from '../../../../../model/localized-string';
import { Pageable } from '../../../../../model/pageable';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { LocalizedStringService } from '../../../../../service/localized-string.service';
import { UserTypeaheadService } from '../../../../../service/typeahead/user-typeahead.service';
import {BehaviorSubject, Subject} from "rxjs";


@Component({
  selector: 'customview-tab-assignments',
  templateUrl: './customview-tab-assignments.component.html'
})
export class CustomViewTabAssignmentsComponent implements OnInit {

  constructor(
    private readonly customViewEditDataService: CustomViewEditDataService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly userTypeaheadService: UserTypeaheadService
  ) { }

  ngOnInit() {
    if (!this.findCustomView().users) {
      this.findCustomView().users = [];
    }
  }

  public getLocalizedStringsValue = (localizedStrings: LocalizedString[], showNameNotFound: boolean = false) =>
    this.localizedStringService.getLocalizedStringsValue(localizedStrings, showNameNotFound);

  public findCustomView = (): CustomView => this.customViewEditDataService.findCustomView();
  public findCustomViewSubject = (): BehaviorSubject<CustomView> => this.customViewEditDataService.findCustomViewSubject();

  public userTypeahead = (searchTerm: string, pageable: Pageable) => this.userTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.findCustomView().users.map(m => m.cn));
}
