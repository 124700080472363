<div *ngIf="!!(findCustomViewSubject() | async)">
  <div class="row">
    <div class="form-group col-6">
      <localized-string-edit [englishRequired]="true"
          [localizedStrings]="findCustomView()?.name" [label]="'label.administration.customview.name' | translate"></localized-string-edit>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mb-3">
      <label for="description">{{'label.administration.customview.description' | translate}}</label>
      <textarea type="text" class="form-control" id="description" name="description" [(ngModel)]="findCustomView().description" required></textarea>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-6">
      <typeahead
          [inputLabel]="'label.administration.customview.realm' | translate"
          [inputName]="'realm'"
          [inputRequired]="true"
          [(model)]="findCustomView() && findCustomView().realm"
          [typeaheadFunction]="realmTypeahead"
          [resultFormatter]="realmTypeaheadFormatter"
          [inputFormatter]="realmTypeaheadFormatter">
      </typeahead>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-6">

      <enumeration-edit [label]="'label.administration.customview.view_type' | translate"
        [convertEmptyStringToNull]="false"
        [readonly]="!!((findCustomViewSubject() | async)?.id)"
        [localizationPrefix]="'option.administration.customview.view_type.'"
        [enumerationValues]="customViewTypes"
        [selectedValue]="findCustomView().type"
        (selectedValueChange)="onCustomViewTypeChange($event)">
      </enumeration-edit>

    </div>
  </div>

  <label>{{ 'label.administration.customview.material_attributes' | translate }}</label>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findCustomView()?.customViewCategoryAttributes"
      [typeahead]="attributesTypeahead"
      [inputName]="'attribute_service'"
      [inputRequired]="true"
      [resultTemplate]="attributeResultTemplate"
      [valueTemplate]="attributeValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.relationship.category.attribute.deletion">
    </multiple-value-selector>

  </div>
</div>
<ng-template #attributeValueTemplate let-result="result">
  <typeahead-template-category-attribute-relatives [showParametername]="isCustomViewTypeTemplateView()" [model]="result" class="category-attribute-template"></typeahead-template-category-attribute-relatives>
</ng-template>

<ng-template #attributeResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-attribute-relatives [model]="result" [term]="term?.trim().split(' ')" [showAttributeLink]="false"></typeahead-template-category-attribute-relatives>
</ng-template>
