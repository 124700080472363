import { Component, OnInit } from '@angular/core';
import { CategoryType } from '../../../../../model/category-type';
import { Category } from '../../../../../model/category';
import { CategoryService } from '../../../../../service/category.service';
import { DocumentTabPropertyComponent } from '../../../../document/edit/tab-property/document-tab-property.component';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { DocumentTabRelationComponent } from '../../../../document/edit/tab-relation/document-tab-relation.component';
import { CustomViewType } from '../../../../../model/custom-view/custom-view-type';
import { CustomViewDocumentProperties } from '../../../../../model/custom-view/custom-view-document-properties';
import { Pageable } from '../../../../../model/pageable';
import { BusinessHierarchy } from '../../../../../model/business-hierarchy';
import { CategoryOnLeafLevelTypeaheadService } from '../../../../../service/typeahead/category-on-leaf-level-typeahead.service';
import { CategoryTypeaheadService } from '../../../../../service/typeahead/category-typeahead.service';
import { BusinessHierarchyTypeaheadService } from '../../../../../service/typeahead/business-hierarchy-typeahead.service';
import { CategoryByTypeTypeaheadService } from '../../../../../service/typeahead/category-by-type-typeahead.service';

@Component({
  selector: 'app-tab-document-defaults',
  templateUrl: './tab-document-defaults.component.html',
  styleUrl: './tab-document-defaults.component.less'
})
export class TabDocumentDefaultsComponent implements OnInit {

  public securityClassifications: Category[];

  get customViewSubject() {
    return this.customViewEditDataService.customViewSubject;
  }

  get customViewDocumentDefaults() {
    if (this.customViewSubject.value.type === CustomViewType.TEMPLATE_VIEW || this.customViewSubject.value.type === CustomViewType.DYNAMIC_TEMPLATE_VIEW) {
      return this.customViewEditDataService.customViewSubject.value as unknown as CustomViewDocumentProperties;
    }
  }

  constructor(
    private readonly categoryOnLeafLevelTypeaheadService: CategoryOnLeafLevelTypeaheadService,
    private categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private readonly businessHierarchyTypeaheadService: BusinessHierarchyTypeaheadService,
    private readonly categoryService: CategoryService,
    private readonly customViewEditDataService: CustomViewEditDataService,
  ) {
  }

  ngOnInit(): void {
    this.categoryService.findByTypeOnLeafLevel(CategoryType.SECURITY_CLASSIFICATION).subscribe((list: Category[]) => {
      this.securityClassifications = list;
    });
  }

  public documentTypeTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable, [CategoryType.DOCUMENT_TYPE], []);
  public documentTypeTypeaheadFormatter = (model: Category) => this.categoryOnLeafLevelTypeaheadService.typeaheadFormatter(model);

  public regionTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.REGION], this.customViewDocumentDefaults.documentRegions.map(s => s.id));

  public odTypeahead = (searchTerm: string, pageable: Pageable) => this.businessHierarchyTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.customViewDocumentDefaults.documentOperatingDivisions.map(s => s.id));
  public odTypeaheadFormatter = (model: BusinessHierarchy) => this.businessHierarchyTypeaheadService.typeaheadFormatter(model);

  protected readonly CustomViewType = CustomViewType;

  /* Compare method for Security classification (default selection) */
  public securityClassificationComparator(object1: { id: string }, object2: { id: string }): boolean {
    if (!object1 || !object2) {
      return false;
    }
    return object1.id === object2.id;
  }
}
