import {ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";


export interface HasUnsavedChangesWithStateGuard {
  hasUnsavedChangesWithState: (currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot) => Observable<boolean> | Promise<boolean> | boolean;
}

export const hasUnsavedChangesWithStateGuard: CanDeactivateFn<HasUnsavedChangesWithStateGuard> = (
  component: HasUnsavedChangesWithStateGuard, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  return component.hasUnsavedChangesWithState(currentRoute, currentState, nextState);
};
