<div class="container-fluid p-0">
  <div class="row m-0">
    <div class="col-12 p-0">
      <app-appheader [multiLanguage]="isMultiLanguageOn"></app-appheader>
    </div>
  </div>

  <div *ngIf="!(isLoggedIn | async)" class="row m-0">
    <div class="col-12 p-0">
      <login [redirectUrl]="redirectUrl"></login>
    </div>
  </div>
  <div *ngIf="(hasPimRoles | async)" class="content-container">
    <div class="navigation">
      <app-appnavigation></app-appnavigation>
    </div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div *ngIf="((!hasPimRoles && isLoggedIn) | async)" class="custom-container">
  <div class="row mt-4">
    <div class="col align-self-center">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">{{ 'literal.unfortunately.no.access' | translate }}</h4>
        <p>{{ 'literal.please.request' | translate }}&nbsp;<a href="https://accessit.basf.net/">{{ 'literal.access.it' | translate }}</a></p>
        <hr>
        <p class="mb-0">{{ 'literal.further.instructions' | translate }}&nbsp;<a href="https://confluence.basf.net/display/PIMII/Roles+and+how+to+get+access+to+PIM">{{ 'literal.roles.and.how.to' | translate }}</a></p>
        <p class="mb-0">{{ 'literal.basf.network.required' | translate }}</p>
      </div>
    </div>
  </div>
</div>
<app-loading-indicator></app-loading-indicator>
<app-notification></app-notification>
