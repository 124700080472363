<div *ngFor="let categoryPho of getCategoryByPhoRelevance(); let i = index; let lastItem = last">
  <div *ngIf="categoryPho.category.categoryAttributes?.length" class="card" [ngClass]="{'mb-4': !lastItem }">
    <div class="card-header pb-0">
      <label for="marketingBenefits">
        <span *ngFor="let node of createParentList(categoryPho.category); let i = index; let lastItem = last">
          <span *ngIf="i > 0"> > </span>
          <span *ngIf="!lastItem" class="category-name">{{node.name | localizedStringsFilter}}</span>
          <span *ngIf="lastItem" class="category-name leaf">{{node.name | localizedStringsFilter}}</span>
          <small>
            <span *ngIf="node.regionalValidity.length > 0">
              <span *ngFor="let validity of node.regionalValidity; let isLast=last"> ({{validity.name | localizedStringsFilter}}{{isLast ? '' : ', '}})</span>
            </span>
            <span> [{{'label.type.' + node.type | lowercase | camelCase | translate }}]</span>
          </small>
        </span>
      </label>
    </div>

    <div class="mx-3 mt-3 mb-0">

      <app-attributes *ngIf="categoryPho.attributeValues.length > 0"
        [categoryRelationAttributes]="getAttributesToBeShown(categoryPho)"
        [readonlyVisibility]="visibilityReadonly"
        [readonly]="readonly">
      </app-attributes>

    </div>

  </div>
</div>
