import { Component, OnDestroy, OnInit } from "@angular/core";
import { CustomViewEditDataService } from "../../../../../service/data-service/customview-edit-data.service";
import { Subscription } from "rxjs";
import { LocalizedString } from "../../../../../model/localized-string";

@Component({
  selector: 'customview-tab-dynamic-template',
  templateUrl: './customview-tab-dynamic-template.component.html',
  styleUrls: ['./customview-tab-dynamic-template.component.less'],
})
export class CustomViewTabDynamicTemplateComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  constructor(private readonly customViewEditDataService: CustomViewEditDataService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isCustomViewTypeDynamicTemplateView = (): boolean => this.customViewEditDataService.isDynamicTemplateView();

  public getTemplateFileNames(): LocalizedString[] {
    if (!this.customViewEditDataService.getTemplateView().templateFileNames) {
      this.customViewEditDataService.getTemplateView().templateFileNames = [];
    }
    return this.customViewEditDataService.getTemplateView().templateFileNames;
  }

}
