import {Component, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-pim-tooltip',
  templateUrl: './pim-tooltip.component.html',
  styleUrls: ['./pim-tooltip.component.less']
})
export class PimTooltipComponent implements OnInit {

  tooltipTemplate: TemplateRef<any>= null;
  tooltip: string = '';
  left: number = 0;
  top: number = 0;

  constructor() {}

  ngOnInit(): void {}

}