import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCase'
})
export class CamelCasePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/_([a-z])/g, (g) =>  g[1].toUpperCase());
  }

}
