<div class="modal-header">
  <h4 class="modal-title">{{ 'user.filter.add.title' | translate }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="user-filter-save-input">{{ 'user.filter.name' | translate }}</label>
    <input id="user-filter-save-input" class="form-control" [(ngModel)]="filterName" />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="clickCancel()">{{ 'button.cancel' | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="clickOk()">{{ 'button.ok' | translate }}</button>
</div>
