import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { Slice } from '../../model/pim-response/slice';
import { BusinessHierarchy } from '../../model/business-hierarchy';
import { Pageable } from '../../model/pageable';
import { TypeaheadExcludeIdRequest } from '../../model/request/typeahead-exclude-id-request';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';

@Injectable({
  providedIn: 'root', deps: [HttpClient]
})
export class BusinessHierarchyTypeaheadService implements AbstractTypeaheadService<BusinessHierarchy> {

  constructor(
    private readonly baseTypeaheadService: BaseTypeaheadService,
    private readonly translate: TranslateService
  ) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<BusinessHierarchy>> {

    let body = new TypeaheadExcludeIdRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      exclude
    );

    return this.baseTypeaheadService.createCall('/hierarchy/typeahead', body, pageable);
  }

  public typeaheadFormatter(model: BusinessHierarchy): string {

    if (model) {
      return model.id;
    }
  }
}
