import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../model/pim-response/page';
import { Category } from '../../../model/category';
import { CategoryService } from '../../../service/category.service';
import { CategoryTreeComponent } from '../../category-tree/category-tree.component';
import { CategoryTreeviewService } from '../../../service/treeview/category-treeview.service';

@Component({
  selector: 'category-administration',
  templateUrl: './category-administration.component.html',
  styleUrls: [ './category-administration.component.less']
})
export class CategoryAdministrationComponent {

  public categories: Page<Category>;

  @ViewChild(CategoryTreeComponent, { static: false })
  private readonly categoryTree: CategoryTreeComponent;

  public searchString: string;
  constructor(
    private readonly categoryTreeviewService: CategoryTreeviewService,
    private readonly router: Router) { }

  public search() {
    this.categoryTree.search();
  }

  public editItem(id: string) {
    this.router.navigateByUrl('adminstration/category/' + id);
  }

  public newCategory() {
    this.router.navigateByUrl('adminstration/category/add');
  }

  getCategoryTreeviewService(): CategoryTreeviewService {
    return this.categoryTreeviewService;
  }

  onNodeClick(categoryId: string): void {
    this.router.navigateByUrl('adminstration/category/' + categoryId);
  }

  onNodeNewTabClick(categoryId: string) {
    window.open('#/adminstration/category/' + categoryId);
  }
}
