import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Material} from '../../model/material';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MaterialEditDataService} from '../data-service/material-edit-data.service';
import {CategoryMaterial} from '../../model/category-material';
import {CopyMaterialAssignmentRequest} from '../../model/request/copy-material-assignment-request';
import {tap} from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {CategoryAttribute} from '../../model/category-attribute';
import {EnrichedMaterialTypeaheadService} from '../typeahead/enriched-material-typeahead.service';
import {Pageable} from '../../model/pageable';
import {MaterialType} from '../../model/material-type';
import {Slice} from '../../model/pim-response/slice';
import {MaterialTypeaheadService} from '../typeahead/material-typeahead.service';

@Injectable({
  providedIn: 'root'
})
export class CopyMaterialAssignmentsService {

  public model: Material;
  public copyUnmaintainedPrdAttributeValues: boolean = true;

  public searchFunction: (materialName: string, pageable: Pageable) => Observable<Slice<Material>> = (materialName: string, pageable: Pageable) => this.copyTypeaheadSearch(materialName, pageable);
  public formatFunction: (model: Material) => string = (model: Material) => this.materialTypeaheadService.typeaheadFormatter(model);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly materialEditDataService: MaterialEditDataService,
    private readonly materialTypeaheadService: MaterialTypeaheadService,
    private readonly enrichedMaterialTypeaheadService: EnrichedMaterialTypeaheadService) {  }

  public init() {
    this.copyUnmaintainedPrdAttributeValues = true;
    this.model = null;
  }

    public copyTypeaheadSearch(materialName: string, pageable: Pageable): Observable<Slice<Material>> {
      return this.enrichedMaterialTypeaheadService.typeaheadSearch(materialName, pageable, [MaterialType.PRD], [this.materialEditDataService.material?.id])
    }

  public startCopy(): Observable<any> {
    if (!!this.model?.id && !!this.materialEditDataService.material?.id) {
      const copyRequest = new CopyMaterialAssignmentRequest();
      copyRequest.targetMaterial = this.materialEditDataService.material;
      copyRequest.sourceMaterial = this.model;
      copyRequest.excludeCategoryIds = this.materialEditDataService.catMats
        .filter( cm => !((cm.category.id === environment.prdCategoryId) && this.copyUnmaintainedPrdAttributeValues) )
        .map(cm => cm.category);
      copyRequest.excludeCategoryAttributes = this.getCategoryAttributesToBeExcluded(this.materialEditDataService.catMats);
      return this.httpClient
        .post<CategoryMaterial[]>(environment.restUrl + '/categorymaterialassignments/copy', copyRequest)
        .pipe(tap({next: categoryMaterials => {
            this.mergeCategoryMaterials(categoryMaterials);
          },
        complete: () => {
          this.model = null;
        }}));
    }
  }

  private mergeCategoryMaterials(categoryMaterials: CategoryMaterial[]) {
    if (categoryMaterials.length > 0) {
      categoryMaterials.forEach(cmSource => {
        if(cmSource.category.id === environment.prdCategoryId) {
          if (cmSource.attributeValues?.length > 0) {
            this.handlePrdAttributeValues(cmSource);
            this.notificationService.addSuccessNotification('Copied PRD Attribute Values');
          } else {
            if(!(categoryMaterials.length > 1)) this.notificationService.addWarnNotification('Nothing found to be copied');
          }
        } else {
          this.materialEditDataService.catMats.push(cmSource);
          this.materialEditDataService.createAttributeValueDummies(cmSource);
          this.notificationService.addSuccessNotification('copied: ' + cmSource.category.id)
        }
      })
    } else {
      this.notificationService.addWarnNotification('Nothing found to be copied');
    }
    this.materialEditDataService.updateCategoryMaterials(this.materialEditDataService.catMats);
  }

  private handlePrdAttributeValues(cmSource: CategoryMaterial) {
    const oldAttributeValues = this.materialEditDataService.catMats.find(cmTarget => cmTarget.category.id === environment.prdCategoryId).attributeValues;
    const filteredOldAttributeValues = oldAttributeValues.filter(oldAv => !cmSource.attributeValues.some(newAv => oldAv.attribute.id === newAv.attribute.id))
    filteredOldAttributeValues.push(...cmSource.attributeValues)
    this.materialEditDataService.catMats.find(cmTarget => cmTarget.category.id === environment.prdCategoryId).attributeValues = filteredOldAttributeValues;
  }

  public getCategoryAttributesToBeExcluded(categoryMaterials: CategoryMaterial[]): CategoryAttribute[] {
    const result: CategoryAttribute[] = [];
    categoryMaterials.forEach(categoryMaterial => {
      const activatedAttributeValues = categoryMaterial.attributeValues.filter(av => av.active);
      const categoryAttributes = categoryMaterial.category.categoryAttributes;
      result.push(...categoryAttributes.filter(categoryAttribute => {
        return activatedAttributeValues.map(av => av.attribute.id).some(attributeId => attributeId === categoryAttribute.attribute.id)
      }));
    })

    return result;
  }
}
