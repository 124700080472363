import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Language } from '../../../model/language';
import { LocalizedString } from '../../../model/localized-string';
import { LanguageService } from '../../../service/language.service';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {EditorService} from '../../../service/editor.service';
// import { atob } from 'buffer';

@Component({
  selector: 'localized-string-edit',
  templateUrl: './localized-string-edit.component.html',
  styleUrls: ['./localized-string-edit.component.less']
})
export class LocalizedStringEditComponent implements AfterContentInit, OnInit {

  @Output()
  public localizedStringsChanged: EventEmitter<LocalizedString[]> = new EventEmitter<LocalizedString[]>()

  @Input()
  public isEditableOnClick = true;

  @Input()
  public maxStringLength: number = environment.localizedStringMaxLength;

  @Input()
  public showCharactersLeft = false;

  @Input()
  public atLeastOneRequired = false;

  @Input()
  public englishRequired = false;

  @Input()
  public englishFixed = true;

  @Input()
  public addButtonText = 'button.add.translation';

  @Input()
  public removeButtonText = 'button.remove.translation';

  @Input()
  public readonly = false;

  @Input()
  public inputType = 'input';

  @Input()
  public label: string;

  public localizedStringEditComponentId: string = '';
  public selectedLanguageForAddTranslation: Language;
  public atLeastOneRequiredAndNotFilled: boolean = true;

  @Input()
  public set localizedStrings(ls: LocalizedString[]) {
    this._localizedStrings = ls || [];
    this.initiateEnglishInput();
  }

  public get localizedStrings(): LocalizedString[] {
    if(!this._localizedStrings) {
      return [];
    }
    return this._localizedStrings.sort((a, b) => (a.language.id < b.language.id) ? -1 : 1);
  }

  private allLanguages: Language[] = [];
  public addLanguageForm: FormGroup = new FormGroup({});
  private _localizedStrings: LocalizedString[] = [];
  public localizedStringMap = new Map<Language, LocalizedString>;

  constructor(
    private readonly editorService: EditorService,
    private readonly languageService: LanguageService,
    private readonly me: ElementRef
  ) {  }

  ngOnInit(): void {
    this.localizedStringEditComponentId = `${this.me.nativeElement.id}-`;
    this.isAtLeastOneRequiredAndNotFilled();
  }

  public ngAfterContentInit(): void {
    this.languageService.getAllLanguages().subscribe((languages: Language[]) => {
      this.init(languages);
      this.initiateEnglishInput();
    });
  }

  public changeLocalizedStrings = (): void => {
    this.localizedStringsChanged.emit(this._localizedStrings);
    this.isAtLeastOneRequiredAndNotFilled();
  }

  public changeLocalizedFormattedStrings(value: any, $event: string) {
    value.value = $event;
    this.changeLocalizedStrings();
  }

  private calculateLocalizedStringMap() {
    this.languageService.getAllLanguages().subscribe(languages => {
      this.localizedStringMap.clear();
      this.localizedStrings.forEach(ls => {
        const language = languages.find(lang => lang.id === ls.language.id);
        ls.language = language;
        this.localizedStringMap.set(language, ls);
      });

      this.selectedLanguageForAddTranslation = this.findAvaliableLanguages().at(0);
    });
    this.isAtLeastOneRequiredAndNotFilled();
  }

  getLocalizedStrings() {
    return Array.from(this.localizedStringMap.values());
  }

  private init(languages: Language[]): void {
    this.allLanguages = languages;
  }

  private initiateEnglishInput() {
    if (this.englishFixed){
      const language: Language = this.findEnglishLanguage();
      this.createEmptyString(language);
    }
    else{
      this.calculateLocalizedStringMap();
      this.selectedLanguageForAddTranslation = this.findEnglishLanguage();
    }
  }

  private findEnglishLanguage(): Language{
    return this.allLanguages.find(lang => this.isEnglish(lang));
  }

  public findAvaliableLanguages(): Language[] {
    return this.allLanguages.filter(lang => !this.isLanguageUsed(lang) && !this.isEnglishAndEnglishIsFixed(lang.isoCode));
  }

  private isEnglish(language: Language): boolean {
    return language.isoCode === 'en';
  }

  public isEnglishAndEnglishIsFixed(isoCode: string): boolean {
    return this.englishFixed && isoCode === 'en';
  }

  private isLanguageUsed(language: Language): boolean{
    return Array.from(this.localizedStringMap.keys()).some(usedLanguage => usedLanguage.id === language.id);
  }

  private createEmptyString(language: Language) {
    if (!!language && !this.doesLanguageExistOnLocalizedStrings(language)) {
      const localizedString = new LocalizedString();
      localizedString.id = null;
      localizedString.language = language;
      localizedString.value = '';
      this.localizedStrings.push(localizedString)
    }
    this.calculateLocalizedStringMap();
  }

  private doesLanguageExistOnLocalizedStrings(language: Language): boolean {

    if(!this.localizedStrings) {
      return false;
    }
    return this.localizedStrings.some(ls => ls.language.isoCode == language.isoCode);
  }

  public addTranslation(): void {
    const language = this.selectedLanguageForAddTranslation;
    this.createEmptyString(language);
  }

  public removeTranslation(language: Language): void {
    const localizedString: LocalizedString = this.localizedStrings.find(search => search.language.id === language.id);
    const i = this.localizedStrings.indexOf(localizedString);
    this.localizedStrings.splice(i, 1);
    this.calculateLocalizedStringMap();
  }

  public isAtLeastOneRequiredAndNotFilled(){
    if (this.atLeastOneRequired){
      this.atLeastOneRequiredAndNotFilled = this.localizedStrings.filter(localizedString => !!localizedString.value && localizedString.value != '' ).length === 0;
    } else {
      this.atLeastOneRequiredAndNotFilled = false;
    }
  }

  calculateValueLength(localizedStringMapEntry: any): number {
    if (!!localizedStringMapEntry?.value?.value) {
      return this.editorService.getUnformattedText(localizedStringMapEntry.value.value).length;
    }
    return 0;
  }
}
