<div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
</div>
<div class="modal-body">
    <p [innerHTML]="text | translate"></p>
</div>
<div class="modal-footer">
    <button (click)="copyTextToClipboard()" id="btnCopyToClipboard" class="btn btn-secondary copy-to-clipboard" 
        title="{{copyTitle | translate}}">
        <fa-icon icon="clipboard" class="fa-solid"></fa-icon>
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.close(false)">{{ no | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">{{ yes | translate }}</button>
</div>