<div>
  <pim-grey-bar-stack
    [inputLabel]="inputLabel"
    id="pim-grey-bar-stack-{{ inputLabel }}-{{ inputName }}"
    [inputName]="'attribute'"
    [valueTemplate]="valueTemplate"
    [valueFormatter]="valueFormatter"
    [dropMethod]="dropMethod"
    [activateSorting]="activateSorting"
    [deleteConfirmText]="deleteConfirmText"
    [deleteConfirmTitle]="deleteConfirmTitle"
    [(values)]="values"
    [validationError]="validationError"
    [executeAfterDelete]="executeAfterDelete"
    [executeBeforeDelete]="executeBeforeDelete"
    [itemVisibilityCriteria]="itemVisibilityCriteria"
    (valueRemoved)="valueRemoved.emit($event)"
    (valuesChange)="valuesChange.emit($event)"
    [enabledField]="enabledField"
    [cssClass]="cssClass"
    [idPrefix]="idPrefix"
    [readonly]="inputDisabled"
    >
  </pim-grey-bar-stack>

  <div *ngIf="!inputShown" class="top-spacer">
    <typeahead id="typeahead-{{ inputLabel }}-{{ inputName }}"
        (newValueEvent)="addValue($event)"
        [inputName]="inputName"
        [typeaheadId]="'typeahead-id-input' + '-' + inputLabel + '-' + inputName"
        [inputPlaceholder]="inputPlaceholder"
        [inputDisabled]="inputDisabled"
        [inputRequired]="inputRequired"
        [inputHideValue]="true"
        [typeaheadFunction]="typeahead"
        [resultFormatter]="resultFormatter"
        [resultTemplate]="resultTemplate">
    </typeahead>
  </div>
</div>
