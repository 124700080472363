export class Notification {


  constructor(
    public message: string,
    public type: string,
    public keepAfterViewChange: boolean = false,
    public dynamicContent: string[] = null,
    ) {
  }

}
