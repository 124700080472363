import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Formulation } from '../../model/formulation';
import { Page } from '../../model/pim-response/page';
import { Pageable } from '../../model/pageable';
import { Realm } from '../../model/realm/realm';
import { FormulationStep } from '../../model/formulation-step';
import { FormulationService } from '../../service/formulation.service';
import { LocalizedStringService } from '../../service/localized-string.service';
import { NotificationService } from '../../service/notification.service';
import InjectIsReadonlyUser from '../../decorator/inject-is-readonly-user.decorator';
import { RealmTypeaheadService } from '../../service/typeahead/realm-typeahead.service';
import { LocalizedString } from '../../model/localized-string';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-formulation',
  templateUrl: './formulation.component.html',
  styleUrls: ['./formulation.component.less']
})
export class FormulationComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public formulations: Page<Formulation> = new Page<Formulation>();
  public edit: boolean = false;
  public pageable: Pageable = new Pageable();
  public searchString: string = '';
  public formulationStep: string;

  public getLocalizedStringsValue: (value: LocalizedString[]) => string = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  constructor(
    private formulationsService: FormulationService,
    private realmTypeaheadService: RealmTypeaheadService,
    private localizedStringService: LocalizedStringService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {

    const id: string = route.snapshot.paramMap.get('id');

    this.formulationsService.load(id).subscribe(formulation => {
      if (formulation !== null) {
        this.formulationsService.currentFormulation = formulation;
      }
    })

    if (id) {
      this.edit = true;
    }
  }

  ngOnInit() {
    if (!this.edit) {
      this.loadData()
    }
  }

  public switchPage(pageable: Pageable) {
    this.pageable = pageable;
    this.refresh();
  }

  public search(input: string) {
    this.searchString = input;
    this.pageable = new Pageable();
    this.refresh();
  }

  refresh() {
    this.router.navigate([], { queryParams: { search: this.searchString, page: this.pageable.page } });
    this.loadData();
  }

  private loadData() {
    this.formulationsService.search(this.searchString, this.pageable).subscribe((page: Page<Formulation>) => {
      this.formulations = page;
    });
  }

  public cancel(): void {
    this.edit = false;
    this.router.navigateByUrl('formulations');
  }

  public save(): void {
    this.formulationsService.save(this.formulationsService.currentFormulation)
      .subscribe(formulation => {
        this.formulationsService.currentFormulation = formulation;
        this.notificationService.addSuccessNotification('label.successfully.saved');
      });
  }

  editItem(formulation: Formulation) {
    this.router.navigateByUrl('formulations/' + formulation.id);
  }

  editFormulationStep(formulationStep: FormulationStep) {
    this.router.navigateByUrl('formulationstep/' + formulationStep.id);
  }

  newFormulation() {
    this.router.navigateByUrl('formulations/add');
  }

  public formatRealm = (realm: Realm) => this.realmTypeaheadService.typeaheadFormatter(realm);
}
