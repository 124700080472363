<div class="mb-1">
  <label *ngIf="!!label">{{ label }}</label>
  <mat-accordion cdkDropList #unassignedList="cdkDropList" [cdkDropListData]="getEnumerationValuesOrdered()" (cdkDropListDropped)="drop($event)" [ngClass]="'accordion'">

    <mat-expansion-panel cdkDrag *ngFor="let enumValue of getEnumerationValuesOrdered(); let i = index;" [expanded]="activePanel === enumValue.order" 
        (opened)="openPanelNumber(enumValue.order)" id="localized-enumeration-attribute-type-edit-value-{{ i }}" [ngClass]="'accordion-item ng-star-inserted'">

      <mat-expansion-panel-header [ngClass]="'accordion-header'">
        <mat-panel-title><fa-icon class="grip-vertical" icon="grip-vertical"></fa-icon>{{ enumValue.name | localizedStringsFilter:true }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="accordion-body">
        <localized-string-edit id="localized-enumeration" [localizedStrings]="enumValue.name" englishRequired="true"></localized-string-edit>
        <icon-button buttonType="delete" (buttonClick)="deleteEnumerationValue(enumValue)" *ngIf="!enumValue.id"></icon-button>
      </div>

    </mat-expansion-panel>

  </mat-accordion>
</div>
<icon-button buttonType="add" (buttonClick)="addEnumerationValue()"></icon-button>