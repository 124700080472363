import { Component, Input } from '@angular/core';

@Component({
  selector: 'typeahead-material-document-relation',
  templateUrl: './material-document-relation.html',
  styleUrls: ['./material-document-relation.less']
})
export class MaterialDocumentRelationTemplate {

  @Input() 
  public model: any;

  @Input()
  public term: string = null;
  
}
