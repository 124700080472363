<div class="modal-header">
  <h1 class="modal-title fs-5">{{ config.modalTitle | translate }}</h1>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">

  <div class="d-flex">

    <!-- download template -->
    <div class="px-1 py-3 step-{{ stepSize }}" *ngIf="!config.templateDownloadDisabled">
      <h4>{{ config.templateDownloadHeader | translate }}</h4>
      <p [innerHtml]="config.templateDownloadUpperText | translate"></p>
      <button id="downloadTemplateButton" [disabled]="processingDownloadTemplate" (click)="downloadTemplate()" class="btn btn-primary attribute-cluster-button mb-3">
        <fa-icon *ngIf="!processingDownloadTemplate" icon="download"></fa-icon>
        <span *ngIf="processingDownloadTemplate" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ config.templateDownloadButton | translate }}
      </button>
      <p [innerHtml]="config.templateDownloadLowerText | translate"></p>
    </div>

    <!-- vertical divider -->
    <div class="vr mx-4" *ngIf="!config.templateDownloadDisabled && !config.exportDisabled"></div>

    <!-- upload template with defined materials and enrich by category-material relation -->
    <div class="px-1 py-3 step-{{ stepSize }}" *ngIf="!config.exportDisabled">
      <h4>{{ config.exportHeader | translate }}</h4>
      <p [innerHtml]="config.exportUpperText | translate"></p>
      <div>
        <input type="file" id="exportFile" class="form-control mb-3" (change)="selectExportFile($event)">
        <button class="btn btn-primary mb-3" [disabled]="!selectedExportFile || processingExportFile" (click)="startExport()">
          <span *ngIf="processingExportFile" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ config.exportButton | translate }}
        </button>
      </div>
      <p [innerHtml]="config.exportLowerText | translate"></p>
    </div>

    <!-- vertical divider -->
    <div class="vr mx-4" *ngIf="!config.exportDisabled && !config.importDisabled"></div>

    <!-- upload adjusted template with enriched category-material relations -->
    <div class="px-1 py-3 step-{{ stepSize }}" *ngIf="!config.importDisabled">
      <h4>{{ config.importHeader | translate }}</h4>
      <p [innerHtml]="config.importUpperText | translate"></p>
      <ng-container *ngIf="config.importMultipleUploadFiles.size==1">
        <ng-container *ngTemplateOutlet="importSingleFileTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="config.importMultipleUploadFiles.size>1">
        <ng-container *ngTemplateOutlet="importmultipleFileTemplate"></ng-container>
      </ng-container>
      <p [innerHtml]="config.importLowerText | translate"></p>
    </div>

  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">Close</button>
</div>

<ng-template #importSingleFileTemplate>
  <div>
    <input type="file" id="importFile" class="form-control mb-3" (change)="selectImportFile($event)">
    <button class="btn btn-primary mb-3" [disabled]="!allImportFilesSelected() || processingImportFile" (click)="startImport()">
      <span *ngIf="processingImportFile" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ config.importButton | translate }}
    </button>
  </div>
</ng-template>

<ng-template #importmultipleFileTemplate>
  <div *ngFor="let item of config.importMultipleUploadFiles | keyvalue:keepOriginalOrder">
    <p *ngIf="item.value.label" [innerHtml]="item.value.label | translate"></p>
    <div>
      <input type="file" id="{{item.value.field}}-id" class="form-control mb-3" (change)="selectImportFile($event, item.value.field)">
    </div>
  </div>
  <div>
    <button class="btn btn-primary mb-3" [disabled]="!allImportFilesSelected() || processingImportFile" (click)="startImport()">
      <span *ngIf="processingImportFile" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ config.importButton | translate }}
    </button>
  </div>
</ng-template>
