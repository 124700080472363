<div class="category-tree-wrapper" title="{{ title }}">
  <div class="category-tree-element form-check form-switch" *ngIf="isMatching">
    <input id="{{ 'checkbox_' + model.id }}" class="form-check-input" type="checkbox" name="enabledCheckbox" [(ngModel)]="isEnabled"
                              (change)="onChangeEnabled($event)">
  </div>
  <div class="category-tree-element">
    <span *ngIf="isEnabled" class="small-order-number">{{ getOrder }}.</span>
  </div>

  <div class="category-tree-element" *ngFor="let node of createParentList(model); let i = index; let firstItem = first; let lastItem = last">
    
    <div class="category-name">


      <div *ngIf="!firstItem" [class]="'category-seperator'">
        <fa-icon [icon]="['fas', 'angle-right']" size="1x"></fa-icon>
      </div>

      <span *ngIf="!lastItem">{{ node.name | localizedStringsFilter }}</span>
      <span *ngIf="lastItem">
        <ngb-highlight *ngIf="!!term" [result]="node.name | localizedStringsFilter" [term]="term"></ngb-highlight>
        <b *ngIf="!term">{{ node.name | localizedStringsFilter }}</b>
      </span>

      <small *ngIf="!!node.type" class="lighter-font">
        <i>&nbsp;[{{ 'label.type.' + node.type | lowercase | camelCase | translate }}]</i>
      </small>

      <div  *ngIf="node.regionalValidity?.length > 0" [class]="'category-validity'">
        <fa-icon [icon]="['fas', 'globe']" ngbTooltip="{{ 'label.type.region' | translate }}"></fa-icon>
        <span *ngFor="let region of node.regionalValidity; let firstItem = first">
          <small *ngIf="!firstItem">&nbsp;&#124;&nbsp;</small>
          <small>{{ region.name | localizedStringsFilter }}</small>
        </span>
      </div>

      <div  *ngIf="node.catalogValidity?.length > 0" [class]="'category-validity'">
        <fa-icon [icon]="['fas', 'book']" ngbTooltip="{{ 'label.type.catalog' | translate }}"></fa-icon>
        <span *ngFor="let catalog of node.catalogValidity; let firstItem = first">
          <small *ngIf="!firstItem">&nbsp;&#124;&nbsp;</small>
          <small>{{ catalog.name | localizedStringsFilter }}</small>
        </span>
      </div>

      <span *ngIf="lastItem && showAttributeLink">&nbsp;
        <a [routerLink]="'/adminstration/attribute/'+node.id">
          <fa-icon icon="external-link-alt" class="fa-sg"></fa-icon>
        </a>
      </span>

    </div>
  </div>

  <span class="horizontal-spacer"></span>
</div>


