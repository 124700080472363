<div *ngIf="!!categoryRelationAttributes">
  <div *ngFor="let rowOfAttributes of (attributeRowsMap | async) | keyvalue: keepOrderInMap" class="row">
    <div *ngFor="let categoryRelationAttribute of rowOfAttributes.value" class="attribute-value-block mb-3"
         [class.col-12]="isAttributeWithParameters(categoryRelationAttribute[1])"
         [class.col-3]="isShortAttribute(categoryRelationAttribute[1]) && !isAttributeWithParameters(categoryRelationAttribute[1])"
         [class.col-6]="!isShortAttribute(categoryRelationAttribute[1]) && !isAttributeWithParameters(categoryRelationAttribute[1])">
      <div id="attribute-value-container-{{ categoryRelationAttribute[1].name | localizedStringsFilter }}{{ !!categoryRelationAttribute[0].id ? '-' + categoryRelationAttribute[0].id + '-' : '' }}{{ categoryRelationAttribute[1].id }}" class="attribute-value-container">


        <app-attribute *ngIf="categoryRelationAttribute[1]?.type"
                       [showVisibility]="showVisibility"
                       [attribute]="categoryRelationAttribute[1]"
                       [attributeValueRelation]="categoryRelationAttribute[0]"
                       [attributeValue]="getAttributeValueByAttribute(categoryRelationAttribute)"
                       [attributeValues]="getAttributeValuesByAttribute(categoryRelationAttribute)"
                       [areAttributeValuesMandatory]="areAttributeValuesMandatory"
                       [isDisabled]="isDisabled"
                       [showInfoButton]="showInfoButton"
                       [readonly]="isReadonly(categoryRelationAttribute)"
                       [readonlyVisibility]="readonlyVisibility">
        </app-attribute>
      </div>
    </div>
  </div>
</div>
