import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'typeahead-template-view-list',
  templateUrl: './typeahead-template-view-list.component.html',
  styleUrls: ['./typeahead-template-view-list.component.less']
})
export class TypeaheadTemplateViewListComponent {

  @Input() 
  public model: any;

  @Input()
  public term: string = null;
  
  constructor() { }

}
