<div class="category-tree-wrapper">
  <div class="category-tree-element" *ngFor="let node of createParentList(model); let i = index; let firstItem = first; let lastItem = last">

    <div class="category-name">

      <div *ngIf="!firstItem" [class]="'category-seperator'">
        <fa-icon [icon]="['fas', 'angle-right']" size="1x"></fa-icon>
      </div>

      <span *ngIf="!lastItem">{{ node.name | localizedStringsFilter }}</span>
      <span *ngIf="lastItem">
        <ngb-highlight *ngIf="!!term" [result]="node.name | localizedStringsFilter" [term]="term"></ngb-highlight>
        <b *ngIf="!term">{{ node.name | localizedStringsFilter }}</b>
      </span>

      <small *ngIf="!!node.type" class="lighter-font">
        <i>&nbsp;[{{ 'label.type.' + node.type | lowercase | camelCase | translate }}]</i>
      </small>

      <div  *ngIf="node.regionalValidity?.length > 0" [class]="'category-validity'">
        <fa-icon [icon]="['fas', 'globe']" ngbTooltip="{{ 'label.type.region' | translate }}"></fa-icon>
        <span *ngFor="let region of node.regionalValidity; let firstItem = first">
          <small *ngIf="!firstItem">&nbsp;&#124;&nbsp;</small>
          <small>{{ region.name | localizedStringsFilter }}</small>
        </span>
      </div>

      <div  *ngIf="node.catalogValidity?.length > 0" [class]="'category-validity'">
        <fa-icon [icon]="['fas', 'book']" ngbTooltip="{{ 'label.type.catalog' | translate }}"></fa-icon>
        <span *ngFor="let catalog of node.catalogValidity; let firstItem = first">
          <small *ngIf="!firstItem">&nbsp;&#124;&nbsp;</small>
          <small>{{ catalog.name | localizedStringsFilter }}</small>
        </span>
      </div>

      <span *ngIf="lastItem && showAttributeLink">&nbsp;
        <a [routerLink]="'/adminstration/attribute/'+node.id">
          <fa-icon icon="external-link-alt" class="fa-sg"></fa-icon>
        </a>
      </span>

    </div>
  </div>

  <span class="horizontal-spacer"></span>

  <span (click)="toggleEditParametername()" *ngIf="showParametername" class="parameter-name-button">
    <fa-icon [icon]="['fas', 'signs-post']" [style.color]="!!(parameteranmeBehaviorSubject | async) ? '#006500aa' : '#650000aa'"></fa-icon>
  </span>
  <pim-popover (click)="$event.stopPropagation" [isActive]="editParametername" class="parametername-target-element">
    <div class="paramter-name-popover-wrapper">
      <span>{{ 'label.parametername' | translate }}: &#160;</span>
      <span>
        <input class="parameter-name-input form-control" [ngModel]="parameteranmeBehaviorSubject.value" (ngModelChange)="newValue($event)">
      </span>
      <span>
        <icon-button buttonType="save" (click)="onSaveParametername()" id="category-attribute-parametername-save-button-{{ model.id }}"></icon-button>
      </span>
    </div>
  </pim-popover>
</div>


