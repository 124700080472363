<div class="row">
  <div class="form-group col-6">
    <localized-string-edit [readonly]="(isReadOnlyUser | async)" id="formulation-properties-name" [englishRequired]="true" [localizedStrings]="findFormulation()?.name" [label]="'label.formulation.name'|translate"></localized-string-edit>
  </div>
  <div class="form-group col-6">
    <string-input-edit [readonly]="(isReadOnlyUser | async)" id="formulation-properties-reference" [label]="'label.formulation.formulation_reference' | translate" [(inputValue)]="findFormulation() && findFormulation().formulationReference"></string-input-edit>
  </div>
</div>

<div class="row">
  <div class="form-group col-6">
    <enumeration-edit [label]="'label.formulation.marketingType' | translate"
      [(selectedValue)]="findFormulation() && findFormulation().marketingType"
      [enumerationValues]="marketingTypes"
      [localizationPrefix]="'option.formulation.marketingType.'"
      [required]="true"
      [readonly]="(isReadOnlyUser | async)">
    </enumeration-edit>
  </div>
  <div class="form-group col-6">
    <typeahead
        [inputLabel]="'label.formulation.realm' | translate"
        [inputName]="'realm'"
        [inputRequired]="true"
        [inputDisabled]="(isReadOnlyUser | async)"
        [(model)]="findFormulation() && findFormulation().realm" 
        [typeaheadFunction]="realmTypeahead" 
        [resultFormatter]="realmTypeaheadFormatter"
        [inputFormatter]="realmTypeaheadFormatter">
    </typeahead>
</div>




