<div class="modal-header">
  <h4 class="modal-title">{{ 'user.filter.rename.title' | translate }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <string-input-edit label="{{ 'user.filter.current.name' | translate}}" [inputValue]="currentName" readonly="true"></string-input-edit>
  </div>
  <div class="form-group">
    <label for="user-filter-save-input">{{ 'user.filter.new.name' | translate}}</label>
    <input id="user-filter-save-input" [(ngModel)]="filterName" class="form-control" />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="clickCancel()">{{ 'button.cancel' | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="clickOk()">{{ 'button.ok' | translate }}</button>
</div>
