import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication/authentication.service';
import { ConnectionService } from './connection.service';
import { environment } from '../../environments/environment';
import {UserService} from './user.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(
      private readonly userService: UserService,
      private readonly authenticationService: AuthenticationService,
      private readonly router: Router,
      private readonly connectionService: ConnectionService
    ) { }

    intercept(request: HttpRequest<any>, http: HttpHandler): Observable<HttpEvent<any>> {

      const hideLoadingIndicator: boolean =
          request.headers.has('hideloadingindicator') ? (/true/i).test(request.headers.get('hideloadingindicator').toLowerCase()) : false;

      this.connectionService.newConnection(hideLoadingIndicator, request.urlWithParams);
      return http.handle(request).pipe(
          catchError(err => {
              if (err instanceof HttpErrorResponse) {
                  if (err.status === 403) {
                      this.userService.unsetCurrentUser();
                      if (!environment.formsLogin || localStorage.getItem('login') === 'saml') {
                        window.location.replace(environment.samlLoginUrl + '?redirectUrl=' + window.btoa(this.router.url));
                      } else if (environment.currentUserUrl !== err.url) {
                        location.reload();
                      }
                  }
              }
              return throwError(err);
          }),
          finalize(() => this.connectionService.connectionClosed(hideLoadingIndicator, request.urlWithParams))
      );
    }
}
