<div class="container-fluid pb-3">

  <div class="material-edit-header">
     <h2 class="display-6 fw-normal">{{ findMaterial()?.name | localizedStringsFilter }} <span>({{ findMaterial()?.type }} - No. : {{ findMaterial()?.id }})</span></h2>
    <span class="horizontal-spacer"></span>
    <app-copy-assignments *ngIf="((isUser | async) || (isAdmin | async)) && getIsCopyFromDisabled() && findMaterial()?.type === 'PRD'"
                          [valueTemplate]="materialCopyAssignmentsTypeaheadTemplate"
                          [copyAssignmentService]="getCopyAssignmentService()"></app-copy-assignments>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="selectedTab">

    <li [ngbNavItem]="'categorization'" [routerLink]="'categorization'" id="categorization">
      <a ngbNavLink>{{ 'label.material.tab.categorization' | translate }}</a>
    </li>

    <li [ngbNavItem]="'attributes'" [routerLink]="'attributes'" id="attributes">
      <a ngbNavLink>{{ 'label.material.tab.attributes' | translate }}</a>
    </li>

    <li [ngbNavItem]="'spass'" [routerLink]="'spass'" id="spass">
      <a ngbNavLink>{{ 'label.material.tab.spass' | translate }}</a>
    </li>

    <li [ngbNavItem]="'basis'" [routerLink]="'basis'" id="basis">
      <a ngbNavLink>{{ 'label.material.tab.basis' | translate }}</a>
    </li>

    <li *ngIf="hasCustomViews()" [ngbNavItem]="'views'" [routerLink]="'views'" id="tab-views">
      <a ngbNavLink>{{ 'label.material.tab.views' | translate }}</a>
    </li>

    <li [ngbNavItem]="'documents'" [routerLink]="'documents'" id="tab-documents">
      <a ngbNavLink>{{ 'label.material.tab.documents' | translate }}</a>
    </li>

  </ul>

  <div class="border-router-outlet">
    <router-outlet *ngIf="!!( categoryMaterialBS() | async)"></router-outlet>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-12 align-right">
      <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button buttonType="save" *ngIf="!(isReadOnlyUser | async)" (buttonClick)="save()"></icon-button>
    </div>
  </div>

</div>

<ng-template #materialCopyAssignmentsTypeaheadTemplate let-result="result">
  <typeahead-material-document-relation [model]="result"></typeahead-material-document-relation>
</ng-template>
