<div>

  <h4>{{ 'label.formulation.industry' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [(values)]="findFormulation() && findFormulation().industries"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate" 
      [typeahead]="industryTypeahead"
      [inputDisabled]="(isReadOnlyUser | async)">
    </multiple-value-selector>

  </div>

</div>  

<ng-template #categoryValueTemplate let-result="result">
  <typeahead-template-category-relatives [model]="result"></typeahead-template-category-relatives>
</ng-template>

<ng-template #categoryResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-relatives [model]="result" [term]="term" [showCategoryLink]="false"></typeahead-template-category-relatives>
</ng-template>