<div class="row" *ngIf="!loading && getCustomViews().length == 0">
  <h3 >No Views Maintained</h3>
</div>

<div *ngIf="getCustomViews().length > 0 && showTypeahead" class="row">
  <div class="col-12">
    <typeahead
      [typeaheadFunction]="customViewTypeahead"
      [inputPlaceholder]="'label.select.view' | translate"
      [resultTemplate]="customViewTypeaheadResultTemplate"
      [inputFormatter]="customViewTypeaheadFormatter"
      [(model)]="selectedCustomView"
      [additionalDebounceTime]="0"
      (modelChange)="calculateAttributeToBeShown()">
    </typeahead>
  </div>
  <div class="col-12"><hr></div>
</div>

<app-attributes *ngIf="attributesToBeShownByCategoryRelation?.length > 0"
  [categoryRelationAttributes]="attributesToBeShownByCategoryRelation"
  [areAttributeValuesMandatory]="areAttributeValuesMandatory"
  [preSortAttributesFunction]="preSortAttributesFunction"
  [showInfoButton]="true"
  [isDisabled]="isDisabled"
  [readonly]="(isReadOnlyUser | async) || isReadonly"
  [readonlyVisibility]="(isReadOnlyUser | async) || isReadonly">
</app-attributes>

<ng-template #customViewTypeaheadResultTemplate let-result="result" let-term="term">
  <typeahead-template-view [model]="result" [term]="term"></typeahead-template-view>
</ng-template>
