import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightTerm'
})
export class HighlightTermPipe implements PipeTransform {

  transform(value: string, term: string, suffix: string = ''): any {
    if (!value) {
      return '';
    }
    if(!term || term.length === 0) {
      return value + suffix;
    }
    let pattern = term.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = pattern.split(' ').filter((t) => {
      return t.length > 0;
    }).join('|');
    const regex = new RegExp(pattern, 'gi');
    return value.replace(regex, (match) => `<b>${match}</b>`) + suffix;
  }
}
