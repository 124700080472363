import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SingleChoiceListValue } from '../../../../model/single-choice-list-value';

@Component({
  selector: 'single-choice-list-attribute-type-edit',
  templateUrl: './single-choice-list-attribute-type-edit.component.html',
  styleUrls: ['./single-choice-list-attribute-type-edit.component.less']
})
export class SingleChoiceListAttributeTypeEditComponent implements OnInit {

  @Input()
  public label: string;

  private _singleChoiceListValuesView: SingleChoiceListValue[] = [];

  @Output() singleChoiceListValuesChange = new EventEmitter<SingleChoiceListValue[]>();

  @Input()
  get singleChoiceListValues(): SingleChoiceListValue[] {
    return this._singleChoiceListValuesView;
  }

  set singleChoiceListValues(val) {
    if (val) {
      this._singleChoiceListValuesView = val;
      if (!this._singleChoiceListValuesView || this._singleChoiceListValuesView.length === 0) {
        this._singleChoiceListValuesView = [new SingleChoiceListValue(null, '', 1)];
        return;
      }
      this._singleChoiceListValuesView.sort((a, b) => (a.order < b.order) ? -1 : 1);
      this.singleChoiceListValuesChange.emit(this._singleChoiceListValuesView);
    }
  }

  public get singleChoiceListValuesView(): SingleChoiceListValue[] {
    return this._singleChoiceListValuesView;
  }

  public set singleChoiceListValuesView(values: SingleChoiceListValue[]) {
    this._singleChoiceListValuesView = values;
  }

  public addSingleChoiceListValue(): void {
    if (!this._singleChoiceListValuesView) {
      return;
    }
    const totalSingleChoiceListValues = this.singleChoiceListValuesView.length;
    this.singleChoiceListValuesView =  [...this.singleChoiceListValuesView, new SingleChoiceListValue(null,  '', totalSingleChoiceListValues + 1)];
  }

  public removeSingleChoiceListValue(singleChoiceListValue: SingleChoiceListValue): void {
    this.singleChoiceListValuesView = this.singleChoiceListValuesView.filter(singleChoiceList => {
      return singleChoiceList.value !== singleChoiceListValue.value;
    });
    const reorderedSingleChoiceListValues: SingleChoiceListValue[] = [];
    let ordering = 1;
    this.singleChoiceListValuesView.forEach( (singleChoiceList) => {
      if (singleChoiceList.order > ordering) {
        singleChoiceList.order = ordering;
      }
      ordering = ordering + 1;
      reorderedSingleChoiceListValues.push(singleChoiceList);
    });
    this.singleChoiceListValuesView = [...reorderedSingleChoiceListValues];
    this.singleChoiceListValuesChange.emit(this._singleChoiceListValuesView);
  }

  ngOnInit() {
    if (!this.singleChoiceListValues || this.singleChoiceListValues.length === 0) {
      this._singleChoiceListValuesView = [new SingleChoiceListValue(null, '', 1)];
      return;
    }
    this.singleChoiceListValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
  }

  public onSingleChoiceListValueChange(input: string, singleChoiceListValues: SingleChoiceListValue){
    singleChoiceListValues.value = input;
    this.singleChoiceListValuesView = [...this.singleChoiceListValuesView];
    this.singleChoiceListValuesChange.emit(this._singleChoiceListValuesView);
  }

  public drop = (event: CdkDragDrop<SingleChoiceListValue[]>) => {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.singleChoiceListValuesView.forEach((step, index) => step.order = index + 1);
  }

}
