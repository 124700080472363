<div class="input-group">

  <input *ngIf="!disableTypeAhead"
    id="searchBarInputWithTypeaheadAndFormatter"
    type="text"
    #ngbTypeaheadRef="ngbTypeahead"
    class="form-control"
    [placeholder]="placeholder"
    [ngbTypeahead]="typeahead"
    [focusFirst]="false"
    [(ngModel)]="searchObject"
    (keydown.enter)="this.closeTypeaheadResults($event, ngbTypeaheadRef); searchButtonClick();"
    [resultTemplate]="!!valueTemplate ? valueTemplate : undefined"
    [resultFormatter]="!valueTemplate ? typeAheadOutputFormatFunction : undefined"
    [inputFormatter]="typeAheadInputFormatFunction"
    (selectItem)="selectItem($event)">

  <input *ngIf="disableTypeAhead"
    id="searchBarInputWithoutTypeahead"
    type="text"
    class="form-control"
    [placeholder]="placeholder"
    [(ngModel)]="searchObject"
    (keydown.enter)="searchButtonClick()">

  <button type="button" class="btn btn-primary" (click)="searchButtonClick()" id="searchButton">
    <fa-icon icon="search"></fa-icon>
    <span class="button-label">{{'button.search'|translate}}</span>
  </button>

</div>
