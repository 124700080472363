<app-overview-view [tableColumns]="tableColumns"
    [title]="'headline.documents.list' | translate"
    [searchFunction]="searchFunction"
    [facetFields]="facetFields"
    [overviewValueTemplate]="overviewValueTemplate"
    [customParameters]="customParameters"
    [rowClicked]="rowClicked"
    [addAllowed]="addAllowed && !(isReadOnlyUser | async)"
    [addFunction]="addFunction"
    [leftCustomButtons]="leftCustomButtons"
    searchFields="documentName"
    typeaheadFieldLabelPrefix="typeaheadFieldLabelPrefix"
    predefinedValueTemplate="documentOverviewValueTemplate"
    [placeholder]="'placeholder.search.text' | translate">
</app-overview-view>


<ng-template #lockIconTemplate let-row>
  <fa-icon *ngIf="isRestricted(row.realmRestriction)" title="{{'label.document.overview.realm.restricted.icon.title' | translate}}" icon="lock" ></fa-icon>
</ng-template>

<ng-template #docBridgeIconTemplate let-row>
  <fa-icon *ngIf="row.docBridgeDocument" title="{{'label.document.overview.doc.bridge.icon.title' | translate}}" icon="industry" ></fa-icon>
</ng-template>

<ng-template #overviewValueTemplate
  let-r="result"
  let-t="term">
  <span *ngFor="let highlightedMapEntry of r.highlights; index as i;">
    <span *ngIf="i!==0">, </span>
    <ngb-highlight [result]="highlightedMapEntry.value" [term]="t"></ngb-highlight>
    <small>
      <i class="highlighted-result"> [{{typeaheadFieldLabelPrefix + highlightedMapEntry.field | translate}}]</i>
    </small>
  </span>
</ng-template>

<ng-template #leftCustomButtons>
  <div class="custom-button-array">
    <button (click)="copySearchFunction()" id="btnCopyUrl" class="btn btn-primary copy-search"
      title="{{'button.copySearchLink' | translate}}">
      <fa-icon icon="clipboard" class="fa-solid"></fa-icon>
    </button>
    <button (click)="downloadExcelResult()" class="btn btn-primary copy-search" [disabled]="processingDownloadSearch | async">
      <fa-icon icon="file-excel"></fa-icon> {{ 'button.download.searchResult' | translate}}
    </button>
  </div>
</ng-template>
