<div *ngIf="isCustomViewTypeDynamicTemplateView()">
  <div class="row">
    <div class="form-group col-12">
      <localized-string-edit [addButtonText]="'button.add.template.filename'"
                             [atLeastOneRequired]="true"
                             [englishFixed]="false"
                             [englishRequired]="false"
                             [label]="'label.administration.customview.tab.template.filename' | translate"
                             [localizedStrings]="getTemplateFileNames()"
                             [removeButtonText]="'button.remove.template.filename'"></localized-string-edit>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-12">
      <label>{{ 'label.administration.customview.tab.dynamictemplate.material_relation' | translate }}</label>

      <select [(ngModel)]="getDynamicTemplateView().materialRelationMandatory" class="form-select" id="material-relation" name="material-relation" required>
        <option [ngValue]="false">{{ 'label.administration.customview.tab.dynamictemplate.not_mandatory' | translate }}</option>
        <option [ngValue]="true">{{ 'label.administration.customview.tab.dynamictemplate.mandatory' | translate }}</option>
      </select>

    </div>
  </div>
  <div class="row">

    <div class="form-group col-12">
      <label>{{ 'label.administration.customview.tab.dynamictemplate.dynamic_document_content' | translate }}</label>

      <div *ngIf="!( dynamicTemplateDocumentContentSubject | async )" class="spinner-border spinner-border-sm ms-lg-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <pim-grey-bar-stack
        (valueRemoved)="deleteValueFortemplateViewList($event)"
        [idPrefix]="'customview--dynamic-template-document-contents'"
        [valueTemplate]="templateViewListValueTemplate"
        [values]="( dynamicTemplateDocumentContentSubject | async )"
        id="pim-grey-bar-stack-customview-dynamic-template-lists">
      </pim-grey-bar-stack>

      <div *ngIf="!!( dynamicTemplateDocumentContentSubject | async )" class="dynamic-template-document-content-input-group">
        <input [(ngModel)]="dynamicTtemplateDocumentContentInputString" class="form-control dynamic-template-document-content-input"
               id="dynamic-template-document-content-input"
               type="text">
        <icon-button (buttonClick)="addDynamicTemplateDocumentContent(dynamicTtemplateDocumentContentInputString)" buttonType="add"
                     class="dynamic-template-document-content-input-button"></icon-button>
      </div>

    </div>
  </div>
</div>

<ng-template #templateViewListValueTemplate
             let-result="result">
  <app-template-view-list-value-template [templateViewList]="result" class="category-attribute-template"></app-template-view-list-value-template>
</ng-template>
