import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GuidService } from '../../../service/tools/guid.service';

@Component({
  selector: 'string-input-edit',
  templateUrl: './string-input-edit.component.html',
  styleUrls: []
})
export class StringInputEditComponent {
  @Input() 
  public label: string;

  @Input() 
  public readonly: boolean;

  @Input() 
  public required: boolean = false;

  @Input() 
  public inputValue: string;

  @Output() 
  public inputValueChange: EventEmitter<string> = new EventEmitter();

  public guid: string;

  constructor(private guidService: GuidService) {
    this.guid = this.guidService.getGuid();
  }

  public setinputValue(value: string) {
    this.inputValue = value;
    this.inputValueChange.emit(this.inputValue);
  }

  public getinputValue(): string {
    return this.inputValue;
  }

}
