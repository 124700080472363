<div *ngIf="!!(editorConfigBehaviorSubject | async)"
  (focusin)="onFocused($event)"
  (focusout)="onUnFocused($event)"
  class="editor-wrapper">

  <div [ngClass]="readonly ? 'editor-wrapper readonly' : 'editor-wrapper'">
    <angular-editor [config]="(editorConfigBehaviorSubject | async) || {}"
                    [placeholder]="readonly ? 'no value' : 'Enter text here...'"
                    (ngModelChange)="onModelChange($event)"
                    [(ngModel)]="htmlContent"></angular-editor>
  </div>
</div>
