import { Injectable } from '@angular/core';

const KEY_AUTHENTICATION_TYPE = "auth-type";
const KEY_AUTHENTICATION_STATUS = "auth-status";

@Injectable()
export class AuthenticationStatusService {


  constructor() { }

  public isAuthenticationTypeSaml(): boolean {
    return localStorage.getItem(KEY_AUTHENTICATION_TYPE) === "saml";
  }

  public isAuthenticationTypeForms(): boolean {
    return localStorage.getItem(KEY_AUTHENTICATION_TYPE) === "forms";
  }

  public isAuthenticationTypeOAuth(): boolean {
    return localStorage.getItem(KEY_AUTHENTICATION_TYPE) === "oauth";
  }

  public setAuthenticationTypeSaml(): void {
    localStorage.setItem(KEY_AUTHENTICATION_TYPE, "saml");
  }

  public setAuthenticationTypeForms(): void {
    localStorage.setItem(KEY_AUTHENTICATION_TYPE, "forms");
  }

  public setAuthenticationTypeOAuth(): void {
    localStorage.setItem(KEY_AUTHENTICATION_TYPE, "oauth");
  }

  public getAuthenticationType(): string {
    return localStorage.getItemItem(KEY_AUTHENTICATION_TYPE);
  }

  public setAuthenticationStatusLoggedIn(): void {
    localStorage.setItem(KEY_AUTHENTICATION_STATUS, "loggedIn");
  }

  public setAuthenticationStatusLoggingIn(): void {
    localStorage.setItem(KEY_AUTHENTICATION_STATUS, "loggingIn");
  }

  public setAuthenticationStatusLoggedOut(): void {
    localStorage.setItem(KEY_AUTHENTICATION_STATUS, "loggedOut");
  }
  public setAuthenticationStatusLoggingOut(): void {
    localStorage.setItem(KEY_AUTHENTICATION_STATUS, "loggingOut");
  }

  public isAuthenticationStatusLoggedOut(): boolean {
    return localStorage.getItem(KEY_AUTHENTICATION_STATUS) === "loggedOut";
  }

  public isAuthenticationStatusLoggingOut(): boolean {
    return localStorage.getItem(KEY_AUTHENTICATION_STATUS) === "loggingOut";
  }
}
