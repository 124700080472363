<div class="row">
  <div class="col-6">
    <h4 class="mt-3">{{ 'headline.documentversion.future_version' | translate }}</h4>
  </div>
  <div class="col-6">
    <div class="align-right">
      <icon-button *ngIf="!(isReadOnlyUser | async) && !(isDocBridgeDocument | async) && showAddButton"
                   id="add-version1"
                   buttonType="add"
                   (buttonClick)="newVersion()"
                   buttonLabel="{{ 'button.add.documentversion' | translate }}"></icon-button>
    </div>
  </div>
</div>

<div class="future-version mb-5">
  <app-table id="future-versions-table" [(page)]="rowsFuture" (rowClick)="selectVersionFuture($event)" (pageableHandler)="setVersionPage($event)">
    <app-table-column name="{{ 'label.documentversion.language' | translate }}" [width]="'14%'" field="languages" [valueFunction]="versionLanguages"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.version' | translate }}" [width]="'8%'" field="version"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.publish' | translate }}" [width]="'14%'" field="validFrom" [valueFunction]="formatDate"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.expiration' | translate }}" [width]="'14%'" field="validUntil" [valueFunction]="formatDate"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.file' | translate }}" [width]="'20%'" field="file" [template]="link"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.change_reason' | translate }}" [width]="'12%'" field="changeReason" [valueFunction]="reasonLanguage"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.status' | translate }}" [width]="'8%'" field="status" [template]="status"></app-table-column>
    <app-table-column [template]="futureButton" [width]="'10%'">
      <ng-template #futureButton let-value>
        <div>
          <icon-button
            buttonType="remove"
            *ngIf="isVersionPublished(value) && !(isReadOnlyUser | async) && !isReadOnly"
            (click)="$event.preventDefault(); $event.stopPropagation(); openDocumentVersionDeletionDialog(value)">
          </icon-button>
          <icon-button
            buttonType="remove"
            buttonLabel="{{ 'button.remove.version' | translate }}"
            *ngIf="!isVersionPublished(value) && !(isReadOnlyUser | async) && !isReadOnly"
            (click)="$event.preventDefault(); $event.stopPropagation(); openDocumentVersionDeletionDialog(value)">
          </icon-button>
        </div>
      </ng-template>
    </app-table-column>
  </app-table>

</div>

<h4 class="mt-3">{{ 'headline.documentversion.current_version' | translate }}</h4>
<div class="current-version mb-5">
  <app-table id="active-versions-table" [(page)]="rowsCurrent" (rowClick)="selectVersionCurrent($event)" (pageableHandler)="setVersionPage($event)">
    <app-table-column name="{{ 'label.documentversion.language' | translate }}" [width]="'14%'" field="languages" [valueFunction]="versionLanguages"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.version' | translate }}" [width]="'8%'" field="version"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.publish' | translate }}" [width]="'14%'" field="validFrom" [valueFunction]="formatDate"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.expiration' | translate }}" [width]="'14%'" field="validUntil" [valueFunction]="formatDate"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.file' | translate }}" [width]="'20%'" field="file" [template]="link"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.change_reason' | translate }}" [width]="'12%'" field="changeReason" [valueFunction]="reasonLanguage"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.status' | translate }}" [width]="'8%'" field="status" [template]="status"></app-table-column>
    <app-table-column [template]="expire_button" [width]="'10%'">
      <ng-template #expire_button let-value>
        <icon-button
          *ngIf="isVersionSaved(value.id) && isVersionPublished(value) && !(isReadOnlyUser | async) && !isReadOnly"
          buttonType="remove"
          buttonLabel="{{ 'button.expirenow' | translate }}"
          (click)="$event.preventDefault(); $event.stopPropagation(); expireNow(value)">
        </icon-button>
        <icon-button
          *ngIf="!isVersionPublished(value) && !(isReadOnlyUser | async) && !isReadOnly"
          buttonType="remove"
          buttonLabel="{{ ('button.remove.version' | translate) }}"
          (click)="$event.preventDefault(); $event.stopPropagation(); openDocumentVersionDeletionDialog(value)">
        </icon-button>
        <icon-button
          *ngIf="!isVersionSaved(value.id) && isVersionPublished(value) && !(isReadOnlyUser | async) && !isReadOnly"
          buttonType="remove"
          (click)="$event.preventDefault(); $event.stopPropagation(); openDocumentVersionDeletionDialog(value)">
        </icon-button>
      </ng-template>
    </app-table-column>
  </app-table>

</div>

<h4 class="mt-3">{{ 'headline.documentversion.expired_version' | translate }}</h4>
<div class="expired-version">
  <app-table id="expired-versions-table" [(page)]="rowsExpired" (rowClick)="selectVersionExpired($event)" (pageableHandler)="setVersionPage($event)">
    <app-table-column name="{{ 'label.documentversion.language' | translate }}" [width]="'14%'" field="languages" [valueFunction]="versionLanguages"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.version' | translate }}" [width]="'8%'" field="version"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.publish' | translate }}" [width]="'14%'" field="validFrom" [valueFunction]="formatDate"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.expiration' | translate }}" [width]="'14%'" field="validUntil" [valueFunction]="formatDate"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.file' | translate }}" [width]="'20%'" field="file" [template]="link"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.change_reason' | translate }}" [width]="'12%'" field="changeReason" [valueFunction]="reasonLanguage"></app-table-column>
    <app-table-column name="{{ 'label.documentversion.status' | translate }}" [width]="'8%'" field="status" [template]="status"></app-table-column>
    <app-table-column [template]="button" [width]="'10%'">
      <ng-template #button let-value>
        <icon-button buttonType="remove" buttonLabel="{{ 'button.remove.version' | translate }}"
        *ngIf="!isVersionPublished(value) && !(isReadOnlyUser | async) && !isReadOnly"
        (click)="$event.preventDefault(); $event.stopPropagation(); openDocumentVersionDeletionDialog(value.id)"></icon-button>
      </ng-template>
    </app-table-column>
  </app-table>

</div>

<div class="row">
  <div class="col-12 mb-3">
    <div class="align-right">
      <icon-button *ngIf="!(isReadOnlyUser | async) && !(isDocBridgeDocument | async) && showAddButton"
          id="add-version2"
          buttonType="add"
          (buttonClick)="newVersion()"
          buttonLabel="{{ 'button.add.documentversion' | translate }}"></icon-button>
    </div>
  </div>
</div>


<ng-template #status let-value>
  <div>{{ ('document.version.' + value.status | lowercase | translate) }}</div>
</ng-template>

<ng-template #link let-value>
  <ng-container *ngIf="value.file">
    <a class="file-link" [href]="downloadLink(value)" (click)="$event.stopPropagation();">{{ value.file.name }}</a>
    <a [href]="previewLink(value)" (click)="$event.stopPropagation();" target="_blank">
      <fa-icon icon="eye" class="fa-solid fa-eye"></fa-icon>
    </a>
    <a [href]="downloadLink(value)" (click)="$event.stopPropagation();">
      <fa-icon icon="download" class="fa-solid fa-download"></fa-icon>
    </a>
  </ng-container>
</ng-template>
