import { Component, Input, OnInit } from '@angular/core';
import { LocalizedStringsFilterPipe } from '../../../../pipes/localized-strings-filter.pipe';
import { User } from '../../../../model/user';

@Component({
  selector: 'typeahead-template-user',
  templateUrl: './typeahead-template-user.component.html',
  styleUrls: ['./typeahead-template-user.component.less']
})
export class TypeaheadTemplateUserComponent {

  @Input() 
  public model: User;

  @Input()
  public term: string = null;
  
  constructor(
    private readonly localizedStringsFilterPipe: LocalizedStringsFilterPipe
  ) { }

  public realmsToString(): string {
    return this.model.realms.map( r => this.localizedStringsFilterPipe.transform(r.name)).join(", ");
  }
}
