import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, pairwise } from 'rxjs/operators';

@Injectable()
export class NavigateService {

    private _previousUrl: string;

    public get previousUrl(): string {
        return this._previousUrl;
    }

    public initialize(router: Router) {
        this.registerUpdatingOfPreviousUrl(router);
    }

    private registerUpdatingOfPreviousUrl(router: Router) {
        router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                pairwise(),
                map((events: [NavigationEnd, NavigationEnd], index: number) => events[0].urlAfterRedirects))
            .subscribe(previousUrl => this._previousUrl = previousUrl);
    }

}
