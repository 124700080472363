import { Component, Input, OnInit } from '@angular/core';
import { CategoryType } from '../../../../model/category-type';
import { Formulation } from '../../../../model/formulation';
import { Pageable } from '../../../../model/pageable';
import { CategoryByTypeTypeaheadService } from '../../../../service/typeahead/category-by-type-typeahead.service';
import { FormulationEditDataService } from '../../../../service/data-service/formulation-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'formulation-tab-relation',
  templateUrl: './formulation-tab-relation.component.html',
  styleUrls: ['./formulation-tab-relation.component.less']
})
export class FormulationTabRelationComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  constructor(
    private categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private readonly formulationEditDataService: FormulationEditDataService
  ) { }

  ngOnInit(): void {
  }

  public industryTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable, 
    [CategoryType.INDUSTRY], this.findFormulation().industries.map(c => c.id));

  public findFormulation = (): Formulation => this.formulationEditDataService.findFormulation();
}
