import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { ProductHierarchyObjectService } from '../../../../../service/product-hierarchy-object.service';
import { ProductHierarchyObject } from '../../../../../model/product-hierarchy-object/product-hierarchy-object';

@Component({
  selector: 'app-product-hierarchy-object-line',
  templateUrl: './product-hierarchy-object-line.component.html',
  styleUrls: ['./product-hierarchy-object-line.component.less']
})
export class ProductHierarchyObjectLineComponent {

  @Input() 
  public model: ProductHierarchyObject;

  constructor(
    private readonly productHierarchyObjectService: ProductHierarchyObjectService
  ) { }

  getType(){
    return !!this.model.type ? ` (${_.startCase(this.model.type)}) ` : '';
  }

  getHighlightString(){
    if(this.productHierarchyObjectService.isSearched()){
      return this.productHierarchyObjectService.getSearchString();
    } else {
      return this.productHierarchyObjectService.currentProductHierarchyObject.internalName;
    }
  }
}
