import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlParameterService} from './url-parameter.service';
import {Observable} from 'rxjs';
import {Page} from '../model/pim-response/page';
import {environment} from '../../environments/environment';
import {OverviewTemplateViewMaterial} from '../model/document-creation/overview-template-view-material';

@Injectable({
  providedIn: 'root'
})
export class TemplateViewMaterialSearchService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly urlParameterService: UrlParameterService) { }

  public search(queryParameters: Map<string, string>): Observable<Page<OverviewTemplateViewMaterial>> {
    // TODO: encode search values as base64: btoa(searchString)
    const queryParameterString: string = this.urlParameterService.convertQueryParametersToString(queryParameters);
    const url: string = `${environment.restUrl}/documentbridge/templatematerialoverview/search/v2${queryParameterString}`;
    return this.httpClient.get<Page<OverviewTemplateViewMaterial>>(url);
  }
}
