import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';

// Material for Angular
import { MatExpansionModule } from '@angular/material/expansion';

// Cdk Library
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Font Awesome Library
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// NGB Library
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// NGX Library
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { deLocale, enGbLocale, esLocale, frLocale, itLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { AutosizeModule } from 'ngx-autosize';
import { CollapseModule } from 'ngx-bootstrap/collapse';

// Guards
import { hasUnsavedChangesGuard } from './guards/has-unsaved-changes.guard';
import { hasUnsavedChangesWithStateGuard } from './guards/has-unsaved-changes-with-state.guard';
import { logoutGuard } from './guards/logout.guard';
import { isAdminGuard } from './guards/is-admin.guard';
import { isAdvancedUserGuard } from './guards/is-advanced-user.guard';

// Components
import { AppComponent } from './app.component';
import { LocalizedStringEditComponent } from './component/commons/localized-string-edit/localized-string-edit.component';
import { LocalizedStringListEditComponent } from './component/commons/localized-string-list-edit/localized-string-list-edit.component';
import { UnitAdministrationComponent } from './component/administration/unit-administration/unit-administration.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AppheaderComponent } from './component/appheader/appheader.component';
import { AppnavigationComponent } from './component/appnavigation/appnavigation.component';
import { PagingComponent } from './component/paging/paging.component';
import { LanguageAdministrationComponent } from './component/administration/language-administration/language-administration.component';
import { LoginComponent } from './component/login/login.component';
import { CategoryAdministrationComponent } from './component/administration/category-administration/category-administration.component';
import { CategoryAdministrationEditComponent } from './component/administration/category-administration/edit/category-administration-edit.component';
import { AttributeAdministrationComponent } from './component/administration/attribute-administration/attribute-administration.component';
import { FormulationComponent } from './component/formulation/formulation.component';
import { MaterialEditComponent } from './component/material/edit/material-edit.component';
import { MaterialTabCategorizationComponent } from './component/material/edit/tab-categorization/material-tab-categorization.component';
import { MaterialTabBasisComponent } from './component/material/edit/tab-basis/material-tab-basis.component';
import { MaterialTabAttributesComponent } from './component/material/edit/tab-attributes/material-tab-attributes.component';
import { MaterialTabSpassComponent } from './component/material/edit/tab-spass/material-tab-spass.component';
import { MaterialTabAttributeViewComponent } from './component/material/edit/attribute-view/material-tab-attribute-view.component';
import { IconButtonComponent } from './component/icon-button/icon-button.component';
import { DocumentComponent } from './component/document/document.component';
import { DocumentEditComponent } from './component/document/edit/document-edit.component';
import { MultipleValueSelectorComponent } from './component/multiple-value-selector/multiple-value-selector.component';
import { ConfirmComponent } from './component/commons/confirm/confirm.component';
import { ConfirmCopyComponent } from './component/commons/confirm-copy/confirm-copy.component';
import { MaterialAdministrationComponent } from './component/administration/material-administration/material-administration.component';
import { MaterialAdministrationEditComponent } from './component/administration/material-administration/edit/material-administration-edit.component';
import { TableComponent } from './component/table/table.component';
import { TableColumnComponent } from './component/table/column/table-column.component';
import { TableFacetComponent } from './component/table/facet/table-facet.component';
import { LoadingIndicatorComponent } from './component/loading-indicator/loading-indicator.component';
import { StringListEditComponent } from './component/attributes/value-types/string-list-edit/string-list-edit.component';
import { BooleanAttributeEditComponent } from './component/attributes/value-types/boolean-attribute-edit/boolean-attribute-edit.component';
import { StringEditComponent } from './component/attributes/value-types/string-edit/string-edit.component';
import { RangeEditComponent } from './component/attributes/value-types/range-edit/range-edit.component';
import { StarAttributeEditComponent } from './component/attributes/value-types/star-attribute-edit/star-attribute-edit.component';
import {
  SingleChoiceListAttributeEditComponent
} from './component/attributes/value-types/single-choice-list-attribute-edit/single-choice-list-attribute-edit.component';
import {
  MultipleChoiceListAttributeEditComponent
} from './component/attributes/value-types/multiple-choice-list-attribute-edit/multiple-choice-list-attribute-edit.component';
import {
  LocalizedEnumAttributeEditComponent
} from './component/attributes/value-types/localized-enumeration-attribute-edit/localized-enumeration-attribute-edit.component';
import {
  LocalizedEnumMultipleAttributeEditComponent
} from './component/attributes/value-types/localized-enumeration-multiple-attribute-edit/localized-enumeration-multiple-attribute-edit.component';
import {
  LocalizedStringAttributeEditComponent
} from './component/attributes/value-types/localized-string-attribute-edit/localized-string-attribute-edit.component';
import {
  LocalizedStringListAttributeEditComponent
} from './component/attributes/value-types/localized-string-list-attribute-edit/localized-string-list-attribute-edit.component';
import { NumberInputEditComponent } from './component/attributes/value-types/number-input-edit/number-input-edit.component';
import { RelationshipAttributeEditComponent } from './component/attributes/value-types/relationship-attribute-edit/relationship-attribute-edit.component';
import { CategoryDetailComponent } from './component/category/detail/category-detail.component';
import { NotificationComponent } from './component/notification/notification.component';
import { CategoryTreeComponent } from './component/category-tree/category-tree.component';
import { MassImportComponent } from './component/administration/mass-import/mass-import.component';
import {
  SingleChoiceListAttributeTypeEditComponent
} from './component/administration/attribute-administration/single-choice-list-attribute-type-edit/single-choice-list-attribute-type-edit.component';
import {
  EnumAttributeTypeEditComponent
} from './component/administration/attribute-administration/enumeration-attribute-type-edit/enumeration-attribute-type-edit.component';
import { DocumentTabPropertyComponent } from './component/document/edit/tab-property/document-tab-property.component';
import { DocumentTabVersionComponent } from './component/document/edit/tab-version/document-tab-version.component';
import { DocumentTabRelationComponent } from './component/document/edit/tab-relation/document-tab-relation.component';
import { DocumentVersionEditComponent } from './component/document/edit/tab-version/edit/document-version-edit.component';
import { DocumentTabVersionOverviewComponent } from './component/document/edit/tab-version/overview/document-tab-version-overview.component';
import { FormulationTabPropertyComponent } from './component/formulation/edit/tab-property/formulation-tab-property.component';
import { FormulationEditComponent } from './component/formulation/edit/formulation-edit.component';
import { EnumEditComponent } from './component/commons/enumeration-edit/enumeration-edit.component';
import { StringInputEditComponent } from './component/commons/string-input-edit/string-input-edit.component';
import { MaterialTabSpassViewComponent } from './component/material/edit/spass-view/material-tab-spass-view.component';
import { FormulationTabRelationComponent } from './component/formulation/edit/tab-relation/formulation-tab-relation.component';
import { FormulationTabCategorizationComponent } from './component/formulation/edit/tab-categorization/formulation-tab-categorization.component';
import { ProductHierarchyObjectComponent } from './component/product-hierarchy-object/product-hierarchy-object.component';
import { OverviewViewComponent } from './component/commons/overview-view/overview-view.component';
import {
  ProductHierarchyObjectTabPropertyComponent
} from './component/product-hierarchy-object/edit/tab-property/product-hierarchy-object-tab-property.component';
import { ProductHierarchyObjectEditComponent } from './component/product-hierarchy-object/edit/product-hierarchy-object-edit.component';
import { FormulationTabAttributeComponent } from './component/formulation/edit/tab-attribute/formulation-tab-attribute.component';
import { FormulationTabStepsComponent } from './component/formulation/edit/tab-steps/formulation-tab-steps.component';
import { CategoryUsageAdministrationComponent } from './component/administration/category-administration/usage/category-usage-administration.component';
import { PhoUsageComponent } from './component/product-hierarchy-object/edit/usage/pho-usage.component';
import { LogoutComponent } from './component/logout/logout.component';
import { SearchbarComponent } from './component/searchbar/searchbar.component';
import { TreeViewComponent } from './component/commons/tree-view/tree-view.component';
import { TreeNodeComponent } from './component/commons/tree-view-node/tree-view-node.component';
import { CategoryLineComponent } from './component/commons/tree-view/templates/category-line/category-line.component';
import { CategoryFilterLineComponent } from './component/commons/tree-view/templates/category-filter-line/category-filter-line.component';
import {
  ProductHierarchyObjectLineComponent
} from './component/commons/tree-view/templates/product-hierarchy-object-line/product-hierarchy-object-line.component';
import {
  LocalizedEnumAttributeTypeEditComponent
} from './component/administration/attribute-administration/localized-enumeration-attribute-type-edit/localized-enumeration-attribute-type-edit.component';
import { MaterialComponent } from './component/material/material.component';
import { MaterialOverviewComponent } from './component/material/overview/material-overview.component';
import {
  ThirdPartyAndBaseIngredientEditComponent
} from './component/administration/material-administration/edit/third-party-and-base-ingredient-edit/third-party-and-base-ingredient-edit.component';
import { VisibilityComponent } from './component/commons/visibility_selection/visibility.component';
import {
  AttributeAdministrationEditComponent
} from './component/administration/attribute-administration/attribute-edit/attribute-administration-edit.component';
import {
  AttributeClusterAdministrationEditComponent
} from './component/administration/attribute-administration/attributecluster-edit/attributecluster-administration-edit.component';
import {
  AttributeParameterAdministrationEditComponent
} from './component/administration/attribute-administration/attributeparameter-edit/attributeparameter-administration-edit.component';
import { AttributeComponent } from './component/attributes/attribute/attribute.component';
import { AttributesComponent } from './component/attributes/attributes.component';
import { AttributeValuesComponent } from './component/attributes/attribute-values/attribute-values.component';
import { AttributeValuesParameterizedComponent } from './component/attributes/attribute-values-parameterized/attribute-values-parameterized.component';
import { ParameterValueComponent } from './component/attributes/parameter-value/parameter-value.component';
import { CustomViewAdministrationComponent } from './component/administration/customview-administration/customview-administration.component';
import { CustomViewAdministrationEditComponent } from './component/administration/customview-administration/edit/customview-administration-edit.component';
import {
  CustomViewTabMaintenanceComponent
} from './component/administration/customview-administration/edit/tab-maintenance/customview-tab-maintenance.component';
import {
  CustomViewTabAssignmentsComponent
} from './component/administration/customview-administration/edit/tab-assignments/customview-tab-assignments.component';
import { CustomViewTabTemplateComponent } from './component/administration/customview-administration/edit/tab-template/customview-tab-template.component';
import {
  CustomViewTabDynamicTemplateComponent
} from './component/administration/customview-administration/edit/tab-dynamic-template/customview-tab-dynamic-template.component';
import { MaterialTabViewsComponent } from './component/material/edit/tab-views/tab-views.component';
import { TypeaheadTemplateViewComponent } from './component/typeahead/templates/typeahead-template-view/typeahead-template-view.component';
import { TypeaheadTemplateViewListComponent } from './component/typeahead/templates/typeahead-template-view-list/typeahead-template-view-list.component';
import { PimPopoverComponent } from './component/commons/pim-popover/pim-popover.component';
import {
  ProductHierarchyObjectTabCategorizationComponent
} from './component/product-hierarchy-object/edit/tab-categorization/product-hierarchy-object-tab-categorization.component';
import {
  ProductHierarchyObjectTabAttributesComponent
} from './component/product-hierarchy-object/edit/tab-attributes/product-hierarchy-object-tab-attributes.component';
import {
  ProductHierarchyObjectTabAttributeViewComponent
} from './component/product-hierarchy-object/edit/attribute-view/product-hierarchy-object-tab-attribute-view.component';
import { TypeaheadTemplateUserComponent } from './component/typeahead/templates/user/typeahead-template-user.component';
import {
  AttributeParameterUsageAdministrationComponent
} from './component/administration/attribute-administration/attributeparameter-usage/attributeparameter-usage-administration.component';
import { CopyAssignmentsComponent } from './component/commons/copy-assignments/copy-assignments.component';
import { MaterialTabDocumentsComponent } from './component/material/edit/tab-documents/tab-documents.component';
import { PimTooltipComponent } from './component/commons/pim-tooltip/pim-tooltip.component';
import { ReportAdministrationComponent } from './component/administration/report-administration/report-administration.component'
import { BusinessHierarchyTreeComponent } from './component/material/edit/spass-view/business-hierarchy-tree/business-hierarchy-tree.component';
import {
  DocumentCreationAdministrationComponent
} from './component/administration/document-creation-administration/select/document-creation-administration.component';
import { AdvancedAdministrationComponent } from './component/administration/advanced-administration/advanced-administration.component';
import {
  AttributeUsageAdministrationComponent
} from './component/administration/attribute-administration/attribute-usage-administration/attribute-usage-administration.component';
import {
  ProductHierarchyObjectTabRelationComponent
} from './component/product-hierarchy-object/edit/tab-relation/product-hierarchy-object-tab-relation.component';
import { TypeaheadComponent } from './component/typeahead/typeahead.component';
import {
  DocumentCreationAdministrationEditComponent
} from './component/administration/document-creation-administration/edit/document-creation-administration-edit.component';
import {
  TabMaterialAttributesComponent
} from './component/administration/document-creation-administration/edit/tab-material-attributes/tab-material-attributes.component';
import {
  TabRenderingOptionsComponent
} from './component/administration/document-creation-administration/edit/tab-rendering-options/tab-rendering-options.component';
import {
  RenderingOptionTemplateComponent
} from './component/administration/document-creation-administration/edit/tab-rendering-options/rendering-option-template/rendering-option-template.component';
import {
  TabDocumentRelationsComponent
} from './component/administration/document-creation-administration/edit/tab-document-relations/tab-document-relations.component';
import {
  TabDocumentVersionsComponent
} from './component/administration/document-creation-administration/edit/tab-document-versions/tab-document-versions.component';
import { DocumentCreationOverviewComponent } from './component/administration/document-creation-administration/overview/document-creation-overview.component';
import {
  DocumentVersionWrapperComponent
} from './component/administration/document-creation-administration/edit/tab-document-versions/document-version-wrapper/document-version-wrapper.component';
import { MassMaintenanceComponent } from './component/administration/mass-maintenance/mass-maintenance.component';
import { GenericMassMaintenanceModal } from './component/administration/mass-maintenance/modal/generic/generic-mass-maintenance.component';
import {
  CustomViewTabTemplateViewAttributesComponent
} from './component/administration/customview-administration/edit/tab-template-view-attributes/custom-view-tab-template-view-attributes.component';
import {
  CustomViewTabTemplateViewListsOfAttributesComponent
} from './component/administration/customview-administration/edit/tab-template-view-lists-of-attributes/custom-view-tab-template-view-lists-of-attributes.component';
import {
  TabDocumentPropertiesComponent
} from './component/administration/document-creation-administration/edit/tab-document-properties/tab-document-properties.component';
import { EditorComponent } from './component/commons/editor/editor.component';
import {
  CustomViewTabSelectionMappingComponent
} from './component/administration/customview-administration/edit/tab-selection-mapping/custom-view-tab-selection-mapping.component';
import { GreyBarStackComponent } from './component/multiple-value-selector/grey-bar-stack/grey-bar-stack.component';
import {
  TemplateViewListValueTemplateComponent
} from './component/administration/customview-administration/edit/template-view-list-value-template/template-view-list-value-template.component';
import { UserFilterComponent } from './component/user-filter/user-filter.component';
import { UserFilterSaveComponent } from './component/user-filter/user-filter-save/user-filter-save.component';
import { UserFilterRenameComponent } from './component/user-filter/user-filter-rename/user-filter-rename.component';

// Services
import { LanguageService } from './service/language.service';
import { MaterialService } from './service/material.service';
import { DocumentBackendService } from './service/document-backend.service';
import { AuthenticationService } from './service/authentication/authentication.service';
import { JsonHttpService } from './service/json-http.service';
import { PasswordService } from './service/password/password.service';
import { AttributeService } from './service/attribute.service';
import { CategoryService } from './service/category.service';
import { FormulationService } from './service/formulation.service';
import { CategoryComponent } from './component/category/category.component';
import { NodeComponent } from './component/node/node.component';
import { CategoryMaterialService } from './service/category-material.service';
import { FormulationPartService } from './service/formulation-part.service';
import { FormulationStepService } from './service/formulation-step.service';
import { UnitService } from './service/unit.service';
import { LocalizedStringService } from './service/localized-string.service';
import { ConnectionService } from './service/connection.service';
import { MaterialSearchService } from './service/material-search.service';
import { ConfirmService } from './service/confirm.service';
import { HttpInterceptorService } from './service/http-interceptor.service';
import { AuthenticationInterceptor } from './service/authentication/authentication-interceptor';
import { FormValidationService } from './service/form-validation.service';
import { NotificationService } from './service/notification.service';
import { AttributeValueService } from './service/attribute-value.service';
import { GuidService } from './service/tools/guid.service';
import { DocumentVersionFormValidationService } from './service/form-validation/document-version-form-validation.service';
import { AttributeFormValidationService } from './service/form-validation/attribute-form-validation.service';
import { EnumService } from './service/enum.service';
import { MassImportService } from './service/mass-import.service';
import { CategoryFormulationService } from './service/category-formulation.service';
import { FormulationFormValidationService } from './service/form-validation/formulation-form-validation.service';
import { CategoryFormulationFormValidationService } from './service/form-validation/category-formulation-form-validation.service';
import { ProductHierarchyObjectService } from './service/product-hierarchy-object.service';
import { BackendValidationService } from './service/form-validation/backend-validation.service';
import { CategoryUsageService } from './service/category-usage.service';
import { AttributeUsageService } from './service/attribute-usage.service';
import { DocumentSearchService } from './service/document-search.service';
import { UrlParameterService } from './service/url-parameter.service';
import { TableService } from './service/table.service';
import { BusinessHierarchyService } from './service/business-hierarchy.service';
import { FormulationStepFormValidationService } from './service/form-validation/formulation-step-form-validation.service';
import { CommonComparisonService } from './service/comparison/common-comparison.service';
import { DocumentComparisonService } from './service/comparison/document-comparison.service';
import { ProductHierarchyObjectComparisonService } from './service/comparison/product-hierarchy-object-comparison.service';
import { UserService } from './service/user.service';
import { NavigateService } from './service/navigate.service';
import { ReportService } from './service/report.service';
import { DashboardService } from './service/dashboard.service';
import { AttributeParameterUsageService } from './service/attributeparameter-usage.service';
import { HttpFileService } from './service/http-file.service';
import { ClipboardService } from './service/clipboard.service';
import { ProductHierarchyObjectUsageService } from './service/product-hierarchy-object-usage.service';
import { SearchDownloadService } from './service/search-download.service';

// Dataservices
import { DocumentEditDataService } from './service/data-service/document-edit-data.service';
import { DocumentCreationDataService } from './service/data-service/document-creation-data.service';
import { MaterialEditDataService } from './service/data-service/material-edit-data.service';
import { AttributeValueEditDataService } from './service/data-service/attribute-value-edit-data.service';
import { FormulationEditDataService } from './service/data-service/formulation-edit-data.service';
import { CustomViewEditDataService } from './service/data-service/customview-edit-data.service';
import { DocumentCreationHttpService } from './service/data-service/document-creation-http.service';

// Typeahead services
import { AttributeClusterTypeaheadService } from './service/typeahead/attribute-cluster-typeahead.service';
import { AttributeParameterTypeaheadService } from './service/typeahead/attribute-parameter-typeahead.service';
import { AttributeTypeaheadService } from './service/typeahead/attribute-typeahead.service';
import { BaseTypeaheadService } from './service/typeahead/base-typeahead.service';
import { BusinessHierarchyTypeaheadService } from './service/typeahead/business-hierarchy-typeahead.service';
import { CategoryAttributeByRelevanceTypeaheadService } from './service/typeahead/category-attribute-by-relevance.service';
import { CategoryAttributeTypeaheadService } from './service/typeahead/category-attribute-typeahead.service';
import { CategoryByTypeTypeaheadService } from './service/typeahead/category-by-type-typeahead.service';
import { CategoryOnLeafLevelTypeaheadService } from './service/typeahead/category-on-leaf-level-typeahead.service';
import { CategoryTypeaheadService } from './service/typeahead/category-typeahead.service';
import { CustomViewByTypeTypeaheadService } from './service/typeahead/custom-view-by-type-typeahead.service';
import { CustomViewMapTypeaheadService } from './service/typeahead/custom-view-map-typeahead.service';
import { EnrichedMaterialTypeaheadService } from './service/typeahead/enriched-material-typeahead.service';
import { LanguageTypeaheadService } from './service/typeahead/language-typeahead.service';
import { MapTypeaheadService } from './service/typeahead/map-typeahead.service';
import { MaterialTypeaheadService } from './service/typeahead/material-typeahead.service';
import { PhoChildrenTypeaheadService } from './service/typeahead/pho/pho-children-typeahead.service';
import { PhoTypeaheadService } from './service/typeahead/pho/pho-typeahead.service';
import { PhoWithoutDescendantsTypeaheadService } from './service/typeahead/pho/pho-without-descendants-typeahead.service';
import { RealmTypeaheadService } from './service/typeahead/realm-typeahead.service';
import { UnitTypeaheadService } from './service/typeahead/unit-typeahead.service';
import { UserTypeaheadService } from './service/typeahead/user-typeahead.service';

// Typeahead Templates
import { TypeaheadCategoryRelativesTemplate } from './component/typeahead/templates/category-relatives/category-relatives';
import { TypeaheadCategoryAttributeRelativesTemplate } from './component/typeahead/templates/category-attribute-relatives/category-attribute-relatives';
import { TypeaheadPhoInternalNameWithLinkTemplate } from './component/typeahead/templates/pho-internal-name-link/pho-internal-name-link';
import { TypeaheadPhoInternalNameWithPathTemplate } from './component/typeahead/templates/pho-internal-name-path/pho-internal-name-path';
import { MaterialDocumentRelationTemplate } from './component/typeahead/templates/material-document-relation/material-document-relation';
import { MaterialTemplate } from './component/typeahead/templates/material/material';
import { TypeaheadAttributeAttributeParameterTemplate } from './component/typeahead/templates/attribute-attribute-parameter/attribute-attribute-parameter';
import {
  TypeaheadTemplateCategoryAttributeOrdered
} from './component/typeahead/templates/typeahead-template-category-attribute-ordered/typeahead-template-category-attribute-ordered';

// Directives
import { SelectFirstOptionDirective } from './directive/select-first-option.directive';
import { NumericInputDirective } from './directive/numeric-input.directive';
import { MaxNumericInputValidatorDirective } from './directive/max-numeric-input-validator.directive';
import { MinNumericInputValidatorDirective } from './directive/min-numeric-input-validator.directive';
import { PimTooltipDirective } from './directive/pim-tooltip.directive';

// Pipes
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { EnumToArrayPipe } from './pipes/enumeration-to-array.pipe';
import { LocalizedStringsFilterPipe } from './pipes/localized-strings-filter.pipe';
import { HighlightTermPipe } from './pipes/highlight-term.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { ReplaceDynamicContentPipe } from './pipes/replace-dynamic-content.pipe';
import { ParamsFromStringPipe } from './pipes/params-from-string.pipe';

// Configurations
import { ApplicationInitializerFactory } from './translation.config';
import { AuthConfigModule } from './auth/auth-config.module';
import { OAuthAuthenticationService } from './service/authentication/oauth-authentication.service';
import { OAuthenticationInterceptor } from './service/authentication/oauthentication-interceptor';
import { AuthenticationStatusService } from './service/authentication/authentication-status.service';
import { TabDocumentDefaultsComponent } from './component/administration/customview-administration/edit/tab-document-defaults/tab-document-defaults.component';

const appRoutes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent, canActivate: [logoutGuard]},
  {path: 'materials', component: MaterialOverviewComponent},
  {path: 'documents', component: DocumentComponent},
  {path: 'product-hierarchy-objects', component: ProductHierarchyObjectComponent},
  {path: 'formulations', component: FormulationComponent},
  {path: 'categories', component: CategoryComponent},
  {path: 'adminstration/unit', component: UnitAdministrationComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [isAdminGuard]},
  {path: 'adminstration/language', component: LanguageAdministrationComponent, canActivate: [isAdminGuard]},
  {path: 'adminstration/category', component: CategoryAdministrationComponent, canActivate: [isAdminGuard]},
  {path: 'adminstration/attribute', component: AttributeAdministrationComponent, canActivate: [isAdminGuard]},
  {path: 'adminstration/material', component: MaterialAdministrationComponent, canActivate: [isAdminGuard]},
  {path: 'adminstration/mass-import', component: MassImportComponent, canActivate: [isAdminGuard]},
  {path: 'adminstration/mass-maintenance', component: MassMaintenanceComponent, canActivate: [isAdvancedUserGuard]},
  {path: 'adminstration/report', component: ReportAdministrationComponent, canActivate: [isAdvancedUserGuard]},
  {path: 'adminstration/document-creation/select', component: DocumentCreationAdministrationComponent, canActivate: [isAdvancedUserGuard]},
  {path: 'adminstration/document-creation/overview', component: DocumentCreationOverviewComponent, canActivate: [isAdvancedUserGuard]},
  {
    path: 'adminstration/document-creation/:action',
    component: DocumentCreationAdministrationEditComponent,
    canDeactivate: [hasUnsavedChangesWithStateGuard],
    canActivate: [isAdvancedUserGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'properties'
      },
      {
        path: 'properties',
        component: TabDocumentPropertiesComponent
      },
      {
        path: 'documentrelations',
        component: TabDocumentRelationsComponent
      },
      {
        path: 'renderingoptions',
        component: TabRenderingOptionsComponent
      },
      {
        path: 'materialattributes',
        component: TabMaterialAttributesComponent
      },
      {
        path: 'documentversions',
        canDeactivate: [hasUnsavedChangesGuard],
        component: TabDocumentVersionsComponent
      },
    ]
  },
  {path: 'adminstration/advanced', component: AdvancedAdministrationComponent, canActivate: [isAdvancedUserGuard]},
  {
    path: 'material/:id',
    component: MaterialEditComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'categorization'
      },
      {
        path: 'categorization',
        component: MaterialTabCategorizationComponent
      },
      {
        path: 'attributes',
        component: MaterialTabAttributesComponent
      },
      {
        path: 'spass',
        component: MaterialTabSpassComponent
      },
      {
        path: 'basis',
        component: MaterialTabBasisComponent
      },
      {
        path: 'views',
        component: MaterialTabViewsComponent
      },
      {
        path: 'documents',
        component: MaterialTabDocumentsComponent
      }
    ]
  },
  {
    path: 'document/:id',
    component: DocumentEditComponent,
    canDeactivate: [hasUnsavedChangesWithStateGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'properties'
      },
      {
        path: 'properties',
        component: DocumentTabPropertyComponent
      },
      {
        path: 'relations',
        component: DocumentTabRelationComponent
      },
      {
        path: 'versions',
        component: DocumentTabVersionComponent
      }
    ]
  },
  {
    path: 'document/document-creation/:action',
    component: DocumentCreationAdministrationEditComponent,
    canDeactivate: [hasUnsavedChangesWithStateGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'properties'
      },
      {
        path: 'properties',
        component: TabDocumentPropertiesComponent
      },
      {
        path: 'documentrelations',
        component: TabDocumentRelationsComponent
      },
      {
        path: 'renderingoptions',
        component: TabRenderingOptionsComponent
      },
      {
        path: 'materialattributes',
        component: TabMaterialAttributesComponent
      },
      {
        path: 'documentversions',
        canDeactivate: [hasUnsavedChangesGuard],
        component: TabDocumentVersionsComponent
      },
    ]
  },
  {
    path: 'product-hierarchy-object/:id',
    component: ProductHierarchyObjectEditComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'properties'
      },
      {
        path: 'properties',
        component: ProductHierarchyObjectTabPropertyComponent
      },
      {
        path: 'relations',
        component: ProductHierarchyObjectTabRelationComponent
      },
      {
        path: 'categorization',
        component: ProductHierarchyObjectTabCategorizationComponent
      },
      {
        path: 'attributes',
        component: ProductHierarchyObjectTabAttributesComponent
      }
    ]
  },
  {
    path: 'formulations/:id',
    component: FormulationEditComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'properties'
      },
      {
        path: 'properties',
        component: FormulationTabPropertyComponent
      },
      {
        path: 'categorization',
        component: FormulationTabCategorizationComponent
      },
      {
        path: 'attributes',
        component: FormulationTabAttributeComponent
      },
      {
        path: 'relations',
        component: FormulationTabRelationComponent
      },
      {
        path: 'steps',
        component: FormulationTabStepsComponent
      },
    ]
  },
  {path: 'categories/:id', component: CategoryDetailComponent},
  {path: 'adminstration/unit/:id', component: UnitAdministrationComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [isAdminGuard]},
  {path: 'adminstration/language/:id', component: LanguageAdministrationComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [isAdminGuard]},
  {path: 'adminstration/category/:id', component: CategoryAdministrationEditComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [isAdminGuard]},
  {path: 'adminstration/attribute/:id', component: AttributeAdministrationEditComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [isAdminGuard]},
  {
    path: 'adminstration/attribute/cluster/:id',
    component: AttributeClusterAdministrationEditComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [isAdminGuard]
  },
  {
    path: 'adminstration/attribute/parameter/:id',
    component: AttributeParameterAdministrationEditComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [isAdminGuard]
  },
  {path: 'adminstration/material/:id', component: MaterialAdministrationEditComponent, canDeactivate: [hasUnsavedChangesGuard], canActivate: [isAdminGuard]},
  {path: 'adminstration/VisibilityComponent', component: VisibilityComponent, canActivate: [isAdminGuard]},
  {path: 'adminstration/view', component: CustomViewAdministrationComponent, canActivate: [isAdminGuard]},
  {
    path: 'adminstration/view/:id',
    component: CustomViewAdministrationEditComponent,
    canDeactivate: [hasUnsavedChangesGuard],
    canActivate: [isAdminGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'maintenance'
      },
      {
        path: 'maintenance',
        component: CustomViewTabMaintenanceComponent
      },
      {
        path: 'assignments',
        component: CustomViewTabAssignmentsComponent
      },
      {
        path: 'dynamicTemplate',
        component: CustomViewTabDynamicTemplateComponent
      },
      {
        path: 'template',
        component: CustomViewTabTemplateComponent
      },
      {
        path: 'documentDefaults',
        component: TabDocumentDefaultsComponent
      },
      {
        path: 'templateViewAttributes',
        component: CustomViewTabTemplateViewAttributesComponent
      }
      ,
      {
        path: 'selectionMapping',
        component: CustomViewTabSelectionMappingComponent
      }
      ,
      {
        path: 'templateViewLists',
        component: CustomViewTabTemplateViewListsOfAttributesComponent
      }
    ]
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    UserFilterSaveComponent,
    UserFilterRenameComponent,
    UserFilterComponent,
    MultipleChoiceListAttributeEditComponent,
    MassImportComponent,
    SingleChoiceListAttributeTypeEditComponent,
    EnumAttributeTypeEditComponent,
    CategoryLineComponent,
    CategoryFilterLineComponent,
    AppComponent,
    SingleChoiceListAttributeEditComponent,
    EnumEditComponent,
    RelationshipAttributeEditComponent,
    AppheaderComponent,
    AppnavigationComponent,
    DashboardComponent,
    UnitAdministrationComponent,
    LanguageAdministrationComponent,
    MaterialOverviewComponent,
    StringEditComponent,
    LocalizedStringEditComponent,
    LocalizedStringListEditComponent,
    CategoryAdministrationComponent,
    CategoryUsageAdministrationComponent,
    PhoUsageComponent,
    CategoryAdministrationEditComponent,
    AttributeAdministrationComponent,
    AttributeAdministrationEditComponent,
    AttributeClusterAdministrationEditComponent,
    AttributeParameterAdministrationEditComponent,
    MaterialEditComponent,
    MaterialTabCategorizationComponent,
    MaterialTabBasisComponent,
    MaterialTabAttributesComponent,
    MaterialTabSpassComponent,
    FormulationTabRelationComponent,
    MaterialTabAttributeViewComponent,
    MaterialTabSpassViewComponent,
    CategoryComponent,
    CategoryDetailComponent,
    NodeComponent,
    PagingComponent,
    LoginComponent,
    DocumentComponent,
    DocumentEditComponent,
    FormulationComponent,
    BooleanAttributeEditComponent,
    IconButtonComponent,
    SelectFirstOptionDirective,
    MultipleValueSelectorComponent,
    NotificationComponent,
    LoadingIndicatorComponent,
    ConfirmComponent,
    ConfirmCopyComponent,
    MaterialAdministrationComponent,
    MaterialAdministrationEditComponent,
    CustomViewAdministrationComponent,
    CustomViewAdministrationEditComponent,
    DocumentCreationAdministrationComponent,
    CustomViewTabAssignmentsComponent,
    CustomViewTabMaintenanceComponent,
    CustomViewTabTemplateComponent,
    CustomViewTabDynamicTemplateComponent,
    LocalizedStringsFilterPipe,
    TableComponent,
    TableColumnComponent,
    TableFacetComponent,
    CamelCasePipe,
    EnumToArrayPipe,
    CategoryTreeComponent,
    StringListEditComponent,
    MassImportComponent,
    StarAttributeEditComponent,
    NumericInputDirective,
    MaxNumericInputValidatorDirective,
    MinNumericInputValidatorDirective,
    NumberInputEditComponent,
    DocumentTabPropertyComponent,
    DocumentTabVersionComponent,
    DocumentTabRelationComponent,
    RangeEditComponent,
    DocumentVersionEditComponent,
    DocumentTabVersionOverviewComponent,
    FormulationTabPropertyComponent,
    FormulationEditComponent,
    StringInputEditComponent,
    FormulationTabCategorizationComponent,
    FormulationTabAttributeComponent,
    FormulationTabStepsComponent,
    HighlightTermPipe,
    TruncateTextPipe,
    ReplaceDynamicContentPipe,
    LogoutComponent,
    SearchbarComponent,
    ProductHierarchyObjectTabPropertyComponent,
    ProductHierarchyObjectEditComponent,
    ProductHierarchyObjectTabRelationComponent,
    ProductHierarchyObjectComponent,
    OverviewViewComponent,
    TreeViewComponent,
    TreeNodeComponent,
    TypeaheadComponent,
    TypeaheadCategoryRelativesTemplate,
    TypeaheadCategoryAttributeRelativesTemplate,
    TypeaheadPhoInternalNameWithLinkTemplate,
    TypeaheadPhoInternalNameWithPathTemplate,
    MaterialDocumentRelationTemplate,
    MaterialTemplate,
    CategoryLineComponent,
    CategoryFilterLineComponent,
    ProductHierarchyObjectLineComponent,
    MaterialComponent,
    ThirdPartyAndBaseIngredientEditComponent,
    LocalizedEnumAttributeTypeEditComponent,
    LocalizedEnumAttributeEditComponent,
    LocalizedEnumMultipleAttributeEditComponent,
    VisibilityComponent,
    AttributeComponent,
    AttributesComponent,
    AttributeValuesComponent,
    AttributeValuesParameterizedComponent,
    ParameterValueComponent,
    MaterialTabViewsComponent,
    TypeaheadTemplateViewComponent,
    TypeaheadTemplateViewListComponent,
    LocalizedStringAttributeEditComponent,
    LocalizedStringListAttributeEditComponent,
    PimPopoverComponent,
    LocalizedStringListAttributeEditComponent,
    ProductHierarchyObjectTabCategorizationComponent,
    ProductHierarchyObjectTabAttributesComponent,
    ProductHierarchyObjectTabAttributeViewComponent,
    TypeaheadTemplateUserComponent,
    TypeaheadAttributeAttributeParameterTemplate,
    TypeaheadTemplateCategoryAttributeOrdered,
    MaterialTabDocumentsComponent,
    PimTooltipComponent,
    PimTooltipDirective,
    AttributeParameterUsageAdministrationComponent,
    CopyAssignmentsComponent,
    ReportAdministrationComponent,
    BusinessHierarchyTreeComponent,
    AdvancedAdministrationComponent,
    AttributeUsageAdministrationComponent,
    DocumentCreationAdministrationEditComponent,
    TabMaterialAttributesComponent,
    TabRenderingOptionsComponent,
    RenderingOptionTemplateComponent,
    TabDocumentRelationsComponent,
    TabDocumentVersionsComponent,
    DocumentCreationOverviewComponent,
    DocumentVersionWrapperComponent,
    MassMaintenanceComponent,
    GenericMassMaintenanceModal,
    TabDocumentPropertiesComponent,
    EditorComponent,
    CustomViewTabTemplateViewAttributesComponent,
    CustomViewTabSelectionMappingComponent,
    CustomViewTabTemplateViewListsOfAttributesComponent,
    GreyBarStackComponent,
    TemplateViewListValueTemplateComponent,
    TabDocumentDefaultsComponent,
    ParamsFromStringPipe,
  ],
  imports: [
    CollapseModule,
    BrowserAnimationsModule,
    BrowserModule,
    AngularEditorModule,
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        useHash: true,
        paramsInheritanceStrategy: 'always',
      },
    ),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    AutosizeModule,
    DragDropModule,
    MatExpansionModule,
    CdkTreeModule,
    CommonModule,
    AuthConfigModule
  ],
  providers: [
    EnumService,
    AttributeValueService,
    LanguageService,
    MaterialService,
    OAuthAuthenticationService,
    AuthenticationService,
    AuthenticationStatusService,
    PasswordService,
    JsonHttpService,
    AttributeService,
    CommonComparisonService,
    LocalizedStringService,
    ConnectionService,
    CategoryService,
    CategoryUsageService,
    ProductHierarchyObjectUsageService,
    AttributeUsageService,
    AttributeParameterUsageService,
    FormulationService,
    FormulationPartService,
    FormulationStepService,
    CategoryMaterialService,
    CategoryFormulationService,
    UnitService,
    DocumentBackendService,
    DocumentEditDataService,
    DocumentComparisonService,
    MaterialSearchService,
    SearchDownloadService,
    ConfirmService,
    GuidService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: OAuthenticationInterceptor, multi: true},
    NotificationService,
    NavigateService,
    FormValidationService,
    DocumentVersionFormValidationService,
    AttributeFormValidationService,
    FormulationFormValidationService,
    CategoryFormulationFormValidationService,
    MassImportService,
    DatePipe,
    FormulationStepFormValidationService,
    BusinessHierarchyService,
    DocumentSearchService,
    ProductHierarchyObjectService,
    ProductHierarchyObjectComparisonService,
    LocalizedStringsFilterPipe,
    BackendValidationService,
    UrlParameterService,
    TableService,
    UserService,
    BaseTypeaheadService,
    MapTypeaheadService,
    RealmTypeaheadService,
    UnitTypeaheadService,
    CustomViewMapTypeaheadService,
    AttributeClusterTypeaheadService,
    AttributeParameterTypeaheadService,
    CategoryAttributeTypeaheadService,
    CategoryOnLeafLevelTypeaheadService,
    BusinessHierarchyTypeaheadService,
    EnrichedMaterialTypeaheadService,
    CategoryByTypeTypeaheadService,
    AttributeTypeaheadService,
    MaterialTypeaheadService,
    PhoChildrenTypeaheadService,
    CategoryTypeaheadService,
    MaterialEditDataService,
    DashboardService,
    ReportService,
    LanguageTypeaheadService,
    CustomViewMapTypeaheadService,
    CustomViewByTypeTypeaheadService,
    PhoTypeaheadService,
    PhoWithoutDescendantsTypeaheadService,
    UserTypeaheadService,
    CategoryAttributeByRelevanceTypeaheadService,
    DocumentCreationDataService,
    FormulationEditDataService,
    AttributeValueEditDataService,
    CustomViewEditDataService,
    DocumentCreationHttpService,
    HttpFileService,
    ClipboardService,
    ParamsFromStringPipe,
    {provide: APP_INITIALIZER, useFactory: ApplicationInitializerFactory, deps: [TranslateService, Injector], multi: true},
  ],
  exports: [
    TypeaheadComponent,
    MultipleValueSelectorComponent,
    TypeaheadCategoryRelativesTemplate,
  ],
  bootstrap: [AppComponent],
})

export class AppModule {

  static injector: Injector;

  constructor(library: FaIconLibrary, injector: Injector) {
    AppModule.injector = injector;
    library.addIconPacks(far, fas);

    defineLocale('de', deLocale);
    defineLocale('en', enGbLocale);
    defineLocale('it', itLocale);
    defineLocale('es', esLocale);
    defineLocale('fr', frLocale);
    defineLocale('uk', enGbLocale);
  }
}
