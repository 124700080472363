import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceDynamicContent'
})
export class ReplaceDynamicContentPipe implements PipeTransform {

    readonly placeholder = '{{dynamicContent}}'

    transform(value: string, dynamicContent: string[], args?: any): string {

        if (!!dynamicContent && Array.isArray(dynamicContent) && dynamicContent.length > 0 && typeof(dynamicContent[0] === 'string') ) {
          const valueParts = value.split(this.placeholder);
          let result = '';
          valueParts.forEach((part, index) => {
            result += part;
            if (index < dynamicContent.length) {
              result += dynamicContent[index];
            }
          });
          return result;
        }
        return value;

    }
}
