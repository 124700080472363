import { Language } from '../language';
import { DocumentVersionFile } from './document-version-file';
import { LocalizedString } from '../localized-string';
import { CategoryAttributeAttributeValue } from '../category-attribute-attribute-value';
import { DocumentVersionStatus } from './document-version-status';
import { Category } from '../category';

export class DocumentVersion {
    id: string;
    file: DocumentVersionFile;
    version: string;
    validFrom: Date;
    validUntil: Date;
    languages: Language[] = [];
    status: DocumentVersionStatus = DocumentVersionStatus.NOT_PUBLISHED;
    changeReason: Category;
    changeComment: LocalizedString[] = [];
    fileName: string;
    categoryAttributeAttributeValues: CategoryAttributeAttributeValue[] = [];
    differences: string[] = [];
}
