import { Injectable } from '@angular/core';
import { AttributeValue } from '../model/attribute-value';
import { Attribute } from '../model/attribute';
import { AttributeType } from '../model/attribute-type';
import { EnumService } from './enum.service';
import { orderBy } from 'lodash';
import { LocalizedStringService } from './localized-string.service';
import { RelationshipAttribute } from '../model/custom-attribute/relationship-attribute';
import { AttributeAttributeParameter } from '../model/attribute-attribute-parameter';
import { AttributeParameterType } from '../model/attribute-parameter-type';
import { AttributeParameterStringValue } from '../model/attributeparametervalue/attribute-parameter-string-value';
import { AttributeParameterNumberValue } from '../model/attributeparametervalue/attribute-parameter-number-value';
import { AttributeParameterRangeValue } from '../model/attributeparametervalue/attribute-parameter-range-value';
import { AttributeParameterLocalizedEnumerationValue } from '../model/attributeparametervalue/attribute-parameter-localized-enumeration-value';
import { AttributeParameterValue } from '../model/attributeparametervalue/attribute-parameter-value';
import { AttributeParameter } from '../model/attribute-parameter';

@Injectable({
  providedIn: 'root'
})
export class AttributeValueService {

  public readonly multivalueAttributeTypes = [
    AttributeType.STRING_LIST,
    AttributeType.RELATIONSHIP,
    AttributeType.ENUMERATION_MULTIPLE,
    AttributeType.LOCALIZED_ENUMERATION_MULTIPLE];


  public readonly shortAttributeTypes = [
    AttributeType.BOOL,
    AttributeType.STAR_RATING,
    AttributeType.RANGE
  ];

  constructor(
    private enumService: EnumService,
    private localizedStringService: LocalizedStringService) { }

  public createNewAttributeValue(attribute: Attribute): AttributeValue {
    const attributeValue: AttributeValue = new AttributeValue();

    attributeValue.attribute = attribute;

    this.addParameterValues(attribute, attributeValue);

    return attributeValue;
  }

  private addParameterValues(attribute: Attribute, attributeValue: AttributeValue) {

    if (!attribute.attributeParameters || attribute.attributeParameters?.length === 0) {
      return;
    }

    attribute.attributeParameters.forEach((aap: AttributeAttributeParameter) => {
      attributeValue.attributeParameterValues.push(this.createParameterValue(aap.attributeParameter));
    });
  }

  public createParameterValue(ap: AttributeParameter): AttributeParameterValue {

    const obj = ({attributeParameter:ap});

    switch(ap.type) {
      case AttributeParameterType.STRING:
          return new AttributeParameterStringValue(obj);
      case AttributeParameterType.RANGE:
          return new AttributeParameterRangeValue(obj);
      case AttributeParameterType.NUMBER:
          return new AttributeParameterNumberValue(obj);
      case AttributeParameterType.LOCALIZED_ENUMERATION:
          return new AttributeParameterLocalizedEnumerationValue(obj);
      default:
        return null;
    }
  }

  public getAttributeValues(attributeValues: AttributeValue[], attribute: Attribute): AttributeValue[] {
    if (!attributeValues || attributeValues.length === 0) {
      return [];
    }
    return attributeValues.filter(attributeValue => this.matchAttributeAndAttributeValue(attribute, attributeValue));
  }

  private matchAttributeAndAttributeValue(attribute: Attribute, attributeValue: AttributeValue) {
    return !!attributeValue.attribute
      && attributeValue.attribute?.id === attribute?.id
      && this.enumService.isValue(AttributeType, attribute.type, attributeValue.attribute.type);
  }

  public getOrderedAttributeValues(attributeValues: AttributeValue[]): any[] {
    return orderBy(attributeValues.map(attributeValue => {
      const attributeValueLocalizedString = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(attributeValue.localizedString);
      const attributeValueRelationship = this.getRelationshipObj(attributeValue.relationship);
      return {id: attributeValue.id, attribute: attributeValue.attribute.id, string: attributeValue.string, localizedString: attributeValueLocalizedString,
              number: attributeValue.number, bool: attributeValue.bool, relationship: attributeValueRelationship, upperValue: attributeValue.upperValue,
              active: attributeValue.active, localizedEnumerationValue: attributeValue.localizedEnumerationValue, singleChoiceListValue: attributeValue.singleChoiceListValue};
    }), ['attribute']);
  }

  private getRelationshipObj(relationship: RelationshipAttribute): any {
    if (!!relationship && !!relationship.type && !!relationship.material) {
      return {id: relationship.id, type: relationship.type, material: relationship.material};
    } else {
      return null;
    }
  }

  public isMultivalueAttribute(attribute: Attribute): boolean {
    return this.multivalueAttributeTypes.includes(attribute.type);
  }
}

