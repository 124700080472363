import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentVersionDataService } from '../../../../../service/data-service/document-version-data.service';
import { DocumentEditDataService } from '../../../../../service/data-service/document-edit-data.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Document } from '../../../../../model/document/document';
import { DocumentVersion } from '../../../../../model/document-version/document-version';
import { DocumentCreationDataService } from '../../../../../service/data-service/document-creation-data.service';
import { ConfirmService } from '../../../../../service/confirm.service';
import InjectIsNotAdvancedUser from '../../../../../decorator/inject-is-not-advanced-user.decorator';
import { DocumentVersionStatus } from '../../../../../model/document-version/document-version-status';
import { HasUnsavedChangesGuard } from '../../../../../guards/has-unsaved-changes.guard';
import { Language } from '../../../../../model/language';

@Component({
  selector: 'app-tab-document-versions',
  templateUrl: './tab-document-versions.component.html',
  styleUrls: ['./tab-document-versions.component.less'],
})
export class TabDocumentVersionsComponent implements DoCheck, OnInit, OnDestroy, HasUnsavedChangesGuard {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  public documentVersionId: string = '';
  public templateFileNameLanguages: Language[] = [];
  private subscriptions: Subscription = new Subscription();
  private showOverviewFlip: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly confirmService: ConfirmService,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly documentCreationDataService: DocumentCreationDataService,
  ) {
  }

  public get document(): BehaviorSubject<Document> {
    return this.documentEditDataService.documentBehaviorSubject;
  }

  public get documentVersionBehaviorSubject(): BehaviorSubject<DocumentVersion> {
    return this.documentVersionDataService.documentVersionBehaviorSubject;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasUnsavedChanges: () => Observable<boolean> | Promise<boolean> | boolean = () => {
    if (this.documentVersionDataService.hasChanged()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.discard.changes', 'button.yes', 'button.no').then((result) => {
        if (result) {
          this.documentVersionDataService.cleanUp();
          this.showOverviewFlip = true;
          return true;
        }

        return false;
      });
    }
    return true;
  }

  ngDoCheck() {
    this.documentVersionId = this.route.snapshot.queryParamMap.get('version');
  }

  ngOnInit(): void {
    this.documentVersionDataService.cleanUp();
    this.documentVersionDataService.navigateToVersionOverview();
    this.subscriptions.add(this.documentVersionBehaviorSubject.subscribe(documentVersion => {
      if (!!documentVersion) {
        this.documentCreationDataService.initTemplateViewCategoryAttributes(documentVersion);
        this.showOverviewFlip = false;
      }
    }));
  }

  public showOverview() {

    if ((!this.documentVersionBehaviorSubject.getValue() || !this.documentVersionId) || this.showOverviewFlip) {
      return true;
    }

    return false;
  }

  public addDocBridgeVersion() {
    for (const fileName of this.documentCreationDataService.templateMaterialBehaviorSubject.value.templateView.templateFileNames) {
      this.templateFileNameLanguages.push(fileName.language);
    }

    const newDocumentVersion = () => {
      const today = new DocumentVersion();
      today.validFrom = new Date();
      this.documentVersionBehaviorSubject.next(today);
      this.showOverviewFlip = false;
      return this.router.navigate([],
        {queryParams: {version: this.documentVersionDataService._addUrlPath}, queryParamsHandling: 'merge'});
    }

    if (this.documentCreationDataService.templateMaterialChanged()) {
      this.confirmService.confirm('title.confirm.save.change.before.creation', 'text.confirm.save.before.creation', 'button.save', 'button.cancel').then((result) => {
        if (result) {
          this.documentCreationDataService.saveTemplateMaterial().subscribe({
            next: () => {
              return newDocumentVersion();
            }, error: () => {
              return;
            },
          });
        } else {
          return;
        }
      });
    } else {
      return newDocumentVersion();
    }
  }

  public currentVersionSelected($event: DocumentVersion) {
    const openCurrentVersionIfConfirmed = (documentVersion: DocumentVersion) => {
      this.showOverviewFlip = false;
      if (documentVersion.status === DocumentVersionStatus.DRAFT || documentVersion.status === DocumentVersionStatus.NOT_PUBLISHED) {
        this.documentVersionDataService.disableVersionInputs(true, true, false, false, true)
      } else {
        this.documentVersionDataService.disableVersionInputs(true, true, true, false, true);
      }
      this.documentCreationDataService.loadAttributeValuesForDocumentVersion(documentVersion);
    }
    this.callSaveConfirmationDialogBeforeEditing($event, openCurrentVersionIfConfirmed);
  }

  public expiredVersionSelected($event: DocumentVersion) {
    this.showOverviewFlip = false;
    this.documentVersionDataService.disableVersionInputs(true, true, true, true, true);
    this.documentCreationDataService.loadAttributeValuesForDocumentVersion($event);
  }


  public futureVersionSelected($event: DocumentVersion) {
    const openFutureVersionIfConfirmed = (documentVersion: DocumentVersion) => {
      this.showOverviewFlip = false;
      if (documentVersion.status === DocumentVersionStatus.DRAFT || documentVersion.status === DocumentVersionStatus.NOT_PUBLISHED) {
        this.documentVersionDataService.disableVersionInputs(true, true, false, false, true)
      } else {
        this.documentVersionDataService.disableVersionInputs(true, true, true, false, true);
      }
      this.documentCreationDataService.loadAttributeValuesForDocumentVersion(documentVersion);
    }
    this.callSaveConfirmationDialogBeforeEditing($event, openFutureVersionIfConfirmed);
  }

  private callSaveConfirmationDialogBeforeEditing($event: DocumentVersion, callFutureVersionSuccess: ($event: DocumentVersion) => void) {
    if ($event.status !== DocumentVersionStatus.PUBLISHED && this.documentCreationDataService.templateMaterialChanged()) {
      this.confirmService.confirm('title.confirm.save.change.before.editing', 'text.confirm.save.before.editing', 'button.save', 'button.cancel').then((result) => {
        if (result) {
          this.documentCreationDataService.saveTemplateMaterial().subscribe({
            next: () => {
              callFutureVersionSuccess($event);
            }, error: () => {
              return;
            },
          });
        } else {
          this.documentVersionDataService.cleanUp();
          this.documentVersionDataService.navigateToVersionOverview();
        }
      });
    } else {
      callFutureVersionSuccess($event);
    }
  }
}
