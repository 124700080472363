import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryService } from '../../../service/category.service';
import { Category } from '../../../model/category';
import { CategoryType } from '../../../model/category-type';
import * as _ from 'lodash';

@Component({
  selector: 'app-visibility',
  templateUrl: './visibility.component.html',
  styleUrls: ['./visibility.component.less']
})
export class VisibilityComponent implements OnInit {

  @Input()
  public visibility: Category;

  @Input()
  public defaultVisibility: Category;

  @Input()
  public noValuePersisted: boolean = false;

  @Input()
  public readonly: boolean = false;

  @Output()
  public visibilityChange = new EventEmitter<Category>();

  public visibilityOptions: Category[];

  constructor(private readonly categoryService: CategoryService) { }

  ngOnInit() {
    this.categoryService.findByTypeCached(CategoryType.VISIBILITY)
        .subscribe((categories: Category[]) => this.visibilityOptions = categories.filter(category => category.id != this.defaultVisibility?.id));
  }

  public compareFn = (a, b) => a && b ? a.id === b.id : a === b;

  public onChange(visibility) {
    this.visibility = visibility;
    this.visibilityChange.emit(visibility);

  }
}
