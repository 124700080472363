import { Injectable } from '@angular/core';

@Injectable()
export class PasswordService {

  passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#\\?!@\\$%\\^\\&\\*\\-_]).{8,}$/; 

  public isStrongEnough(password: string): boolean {
    return !!password.match(this.passwordPattern);
  }

}
