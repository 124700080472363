<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal" *ngIf="attributeParameter.id; else addHeadline">{{ 'headline.administration.maintainAttributeParameter' | translate }}</h2>
  
    <ng-template #addHeadline>
      <h2 class="display-6 fw-normal">{{ 'headline.administration.addAttributeParameter' | translate }}</h2>
    </ng-template>
  
    <form>
      <div class="row">
        <div class="col-4 mb-3">
          <div class="row">
            <div class="col-12 mb-3">
              <localized-string-edit id="attribute-parameter-name-edit" [localizedStrings]="attributeParameter.name" englishRequired="true" [label]="'label.administration.name'|translate"></localized-string-edit>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3 form-group">
              <label for="description">{{'label.administration.attribute.parameter.internal_description' | translate}}</label>
              <textarea type="text" class="form-control" id="description" name="description" [(ngModel)]="attributeParameter.internalDescription"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3 form-group">
              <typeahead
                  [inputLabel]="'label.unit' | translate"
                  [typeaheadId]="'attribute-parameter-unit-typeahead'"
                  [inputName]="'unit'"
                  [(model)]="attributeParameter.unit"
                  [typeaheadFunction]="unitTypeahead"
                  [resultFormatter]="unitTypeaheadFormatter"
                  [inputFormatter]="unitTypeaheadFormatter">
              </typeahead>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3 form-group">
              <label for="attributeParameterType">{{ 'label.administration.attribute.parameter.type.enumeration.type' | translate }}</label>
              <select class="form-select" id="attributeParameterType" required name="attributeParameterType" [(ngModel)]="attributeParameter.type" (ngModelChange)="typeChange()">
                <option *ngFor="let type of parameterTypes" value="{{type}}">{{ 'option.administration.attribute.parameter.type.enumeration.type.' + type | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-4 mb-3">
          <div class="row">
            <div class="col-12 mb-3 form-group" *ngIf="attributeParameter.type=='LOCALIZED_ENUMERATION'">              
              <localized-enumeration-attribute-type-edit [label]="'label.enumeration.localized_enumeration_values' | translate"
                [enumValues]="attributeParameter.localizedEnumerationValues"
                (localizedEnumerationValuesChange)="localizedEnumerationValuesChange($event, attributeParameter)">                
              </localized-enumeration-attribute-type-edit>
            </div>
          </div>
        </div>
      </div>

      <div class="row upper_space" *ngIf="attributeParameter && attributeParameter.id">
        <div class="col-12">
          <div >
            <button class="btn btn-secondary" [disabled]="!attributeParameterInUse" (click)="showUsages=!showUsages">
              {{( !attributeParameterInUse? 'button.administration.attribute.parameter.usage.nousagetoshow' : 
                    !showUsages? 'button.administration.attribute.parameter.usage.show':'button.administration.attribute.parameter.usage.hide') | translate}}
            </button>
          </div>
          <div>
            <attributeparameter-usage-administration *ngIf="showUsages" [attributeParameter]="attributeParameter"></attributeparameter-usage-administration>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 align-right">
          <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
          <icon-button buttonType="delete" (buttonClick)="delete()" [buttonDisabled]="isDeleteDisabled()"></icon-button>
          <icon-button *ngIf="attributeParameter.id" buttonType="save" (buttonClick)="save()"></icon-button>
          <icon-button *ngIf="!attributeParameter.id" buttonType="add" (buttonClick)="save()"></icon-button>
        </div>
      </div>
    </form>
  </div>
