<div *ngIf="isCustomViewTypeDynamicTemplateView()">
  <div class="row">
    <div class="form-group col-12">
      <localized-string-edit [addButtonText]="'button.add.template.filename'"
                             [atLeastOneRequired]="true"
                             [englishFixed]="false"
                             [englishRequired]="false"
                             [label]="'label.administration.customview.tab.template.filename' | translate"
                             [localizedStrings]="getTemplateFileNames()"
                             [removeButtonText]="'button.remove.template.filename'"></localized-string-edit>
    </div>
  </div>
</div>
