<div class="select">

  <div *ngIf="!visibilityOptions || !visibilityOptions[0]" class="spinner-border spinner-border-sm ms-lg-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>

	<select [disabled]="readonly" *ngIf="!!visibilityOptions && visibilityOptions[0]"
    id="visibility_selection_{{ !!visibility ? visibility.name[0].value : 'choose visibility' }}"
    class="form-select"
		[ngClass]="{ 'default-option': !visibility === true || (noValuePersisted && visibility?.id == defaultVisibility?.id)}"
    [compareWith]="compareFn"
    [ngModel]="visibility"
    (ngModelChange)="onChange($event)"
    [ngbTooltip]="!!defaultVisibility ? toolTipTemplate : ''"
    [openDelay]="1400">

		<option [selected]="!visibility || visibility == null" [ngValue]="null">{{ "choose.visibility" | translate }}</option>
		<option *ngIf="!!defaultVisibility" [selected]="visibility?.id == defaultVisibility?.id"
        [ngValue]="defaultVisibility">{{ defaultVisibility.name | localizedStringsFilter }} <span>*</span></option>
		<option *ngFor="let category of visibilityOptions; let i = index" [ngValue]="category"
        [selected]="!!visibility && visibility.id === category.id">{{ category.name | localizedStringsFilter }}</option>

	</select>
</div>
<ng-template #toolTipTemplate>
  <small>{{ 'tooltip.visibility.select' | translate }}</small>
</ng-template>
