import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AttributeValue } from '../../../../model/attribute-value';
import { Attribute } from '../../../../model/attribute';
import { AttributeValueService } from '../../../../service/attribute-value.service';

@Component({
  selector: 'string-list-edit',
  templateUrl: './string-list-edit.component.html'
})
export class StringListEditComponent implements OnInit {

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  @Output()
  public updateAttributeValueChange = new EventEmitter<AttributeValue[]>();

  @Input()
  public readonly: boolean = false;

  private _attributeValues: AttributeValue[];
  public filteredAttributeValues: AttributeValue[] = [];

  @Input()
  get attributeValues(): AttributeValue[] {
    return this._attributeValues;
  }

  set attributeValues(val: AttributeValue[]) {
    this.filteredAttributeValues = this.attributeValues;
    this._attributeValues = val;
  }

  constructor(
    public attributeValueService: AttributeValueService
  ) { }

  ngOnInit() {
    this.filteredAttributeValues = this.attributeValues;
  }

  public removeAttributeValue(attributeValue: AttributeValue): void {
    this.filteredAttributeValues = this.filteredAttributeValues.filter(paramAttributeValue => {
      return paramAttributeValue !== attributeValue;
    });
    if (this.filteredAttributeValues.length < 1) {
      this.addAttributeValue();
    } else {
      this.updateAttributeValueChange.emit(this.filteredAttributeValues);
    }
  }

  public addAttributeValue(): void {
    const newAttributeValue: AttributeValue = this.attributeValueService.createNewAttributeValue(this.attribute);
    this.filteredAttributeValues.push(newAttributeValue);
    this.updateAttributeValueChange.emit(this.filteredAttributeValues);
  }
}
