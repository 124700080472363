import { AttributeParameter } from "../attribute-parameter";
import { AttributeParameterType } from "../attribute-parameter-type";
import { AttributeParameterValue } from "./attribute-parameter-value";

export class AttributeParameterRangeValue implements AttributeParameterValue {
  public id: string = null;
  public parameterType: AttributeParameterType;
  public attributeParameter: AttributeParameter;
  public lowerValue: number = null;
  public upperValue: number = null;

  constructor(obj: any) {
    let apnv = obj as AttributeParameterRangeValue;

    this.id = apnv.id;
    this.parameterType = AttributeParameterType.RANGE;
    this.attributeParameter = apnv.attributeParameter;
    this.lowerValue = apnv.lowerValue;
    this.upperValue = apnv.upperValue;
  }
}