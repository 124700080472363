import {Component, OnInit} from '@angular/core';
import { CustomView } from '../../../../../model/custom-view/custom-view';
import { Pageable } from '../../../../../model/pageable';
import { Realm } from '../../../../../model/realm/realm';
import { CategoryAttribute } from '../../../../../model/category-attribute';
import { CustomViewType } from '../../../../../model/custom-view/custom-view-type';
import { AttributeRelevance } from '../../../../../model/attribute-relevance';
import { RealmTypeaheadService } from '../../../../../service/typeahead/realm-typeahead.service';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { CategoryAttributeByRelevanceTypeaheadService } from '../../../../../service/typeahead/category-attribute-by-relevance.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'customview-tab-maintenance',
  templateUrl: './customview-tab-maintenance.component.html',
  styleUrls: ['./customview-tab-maintenance.component.less']
})
export class CustomViewTabMaintenanceComponent implements OnInit {

  public leafAttributes = {};
  public customViewTypes: string[] = [...Object.keys(CustomViewType) ];

  constructor(
    private readonly realmTypeaheadService: RealmTypeaheadService,
    private readonly customViewEditDataService: CustomViewEditDataService,
    private readonly categoryAttributeByRelevanceTypeaheadService: CategoryAttributeByRelevanceTypeaheadService
  ) { }

  ngOnInit(): void {
    this.findCustomViewSubject().subscribe((customView: CustomView) => {
      this.initiateAttributeLeafMap();
    });
  }

  public isCustomViewTypeTemplateView = (): boolean => this.customViewEditDataService.isTemplateView();
  public realmTypeahead = (searchString: string, pageable: Pageable) => this.realmTypeaheadService.typeaheadSearch(searchString, pageable);
  public realmTypeaheadFormatter = (model: Realm) => this.realmTypeaheadService.typeaheadFormatter(model);
  public findCustomView = (): CustomView => this.customViewEditDataService.findCustomView();
  public findCustomViewSubject = (): BehaviorSubject<CustomView> => this.customViewEditDataService.findCustomViewSubject();
  public findAttributes = (): CategoryAttribute[] => this.customViewEditDataService.findCustomView()?.customViewCategoryAttributes;
  public isViewTypeReadOnly = (): boolean => !!this.customViewEditDataService.findCustomView()?.id;

  public attributesTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryAttributeByRelevanceTypeaheadService.typeaheadSearch(searchTerm, pageable,
          this.findAttributes()?.map(categoryAttribute => categoryAttribute.id), [AttributeRelevance.MATERIAL]);

  public addValue = (categoryAttribute: CategoryAttribute) => {
    this.customViewEditDataService.addCategoryAttribute(categoryAttribute);
    this.addToLeafAttribute(categoryAttribute, true);
  }

  public deleteValue = (categoryAttribute: CategoryAttribute) => {
    this.customViewEditDataService.removeCategoryAttribute(categoryAttribute);
    this.initiateAttributeLeafMap();
    if(this.isCustomViewTypeTemplateView()) {
      this.customViewEditDataService.deleteTemplateViewSelectionMappingSingleChoiceList(categoryAttribute);
    }
  }

  private initiateAttributeLeafMap() {
    this.leafAttributes = {};
    this.findAttributes()?.forEach(categoryAttribute => {
      this.addToLeafAttribute(categoryAttribute, true);
    });
  }

  private addToLeafAttribute(categoryAttribute: CategoryAttribute, possibleLeaf: boolean = false) {
    if (possibleLeaf && !this.leafAttributes.hasOwnProperty(categoryAttribute.id)) {
      this.leafAttributes[categoryAttribute.id] = true;
    } else {
      this.leafAttributes[categoryAttribute.id] = false;
    }
  }

  onCustomViewTypeChange($event: string) {
    this.customViewEditDataService.changeCustomViewType($event);
  }
}
