import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorMessage } from '../../model/pim-response/error-message';
import { NotificationService } from '../notification.service';

@Injectable()
export class BackendValidationService {

  private readonly errorMessageTimeout: number = 15_000;
  private skipNextErrorChecks: boolean = false;

  constructor(private readonly notificationService: NotificationService) {
  }

  public renderErrorNotifications(response: HttpErrorResponse, caught: Observable<any>): any {
    this.addErrorNotifications(response);
    return throwError(() => caught);
  }

  public renderMessages(successMessage: string): UnaryFunction<Observable<unknown>, Observable<any>> {

    return pipe(
      tap(unusedResult => this.notificationService.addSuccessNotification(successMessage)),
      catchError((response: HttpErrorResponse, caught: Observable<any>) => this.renderErrorNotifications(response, caught))
    );
  }

  public renderErrorMessages(): any {
    return catchError((response: HttpErrorResponse, caught: Observable<any>) => this.renderErrorNotifications(response, caught));
  }

  public async addErrorNotifications(response: HttpErrorResponse) {

    const errorMessages: string[] = [];

    this.skipNextErrorChecks = false;

    await this.handleBlobResponseError(response, errorMessages);
    this.handleHttpNotFound(response, errorMessages);
    this.handleRawError(response, errorMessages);
    this.handleInternalServerError(response, errorMessages);
    this.handleResponseErrorMessages(response, errorMessages);

    _(errorMessages).uniq().forEach(errorMessage => {
      const keepAfterViewChange: boolean = !this.isFormValidationErrorMessage(errorMessage);
      const lifeTime: number = !this.isFormValidationErrorMessage(errorMessage) ? this.errorMessageTimeout : undefined;
      this.notificationService.addErrorNotification(errorMessage, keepAfterViewChange, lifeTime);
    });
  }

  private async handleBlobResponseError(response: HttpErrorResponse, errorMessages: string[]) {

    if (this.skipNextErrorChecks) {
      return;
    }

    if (response.error instanceof Blob) {

      const errorArray: ErrorMessage[] = JSON.parse(await response.error.text());
      let blobResponse: HttpErrorResponse = new HttpErrorResponse({error: errorArray});
      this.renderValidationError(blobResponse, errorMessages);

      this.skipNextErrorChecks = true;
    }
  }

  private handleHttpNotFound(response: HttpErrorResponse, errorMessages: string[]) {

    if (this.skipNextErrorChecks) {
      return;
    }

    if (response.status === HttpStatusCode.NotFound) {
      errorMessages.push('backend.validation.error.998');
      this.skipNextErrorChecks = true;
    }
  }

  private handleInternalServerError(response: HttpErrorResponse, errorMessages: string[]) {

    if (this.skipNextErrorChecks) {
      return;
    }

    if (response.status === HttpStatusCode.InternalServerError || response.status === HttpStatusCode.BadRequest) {
      this.renderError(response, errorMessages, 'backend.validation.error.');
    }
  }

  private handleResponseErrorMessages(response: HttpErrorResponse, errorMessages: string[]) {

    if (this.skipNextErrorChecks) {
      return;
    }
    if (response.status === HttpStatusCode.ExpectationFailed) {
      this.renderValidationError(response, errorMessages);
    }
  }

  private isFormValidationErrorMessage(errorMessage: string) {
    return ![
      'backend.validation.error.516', // 516 is the error code for protected documents (but I don't get the meaning of this here)
      'backend.validation.error.998'
    ].includes(errorMessage);

  }

  private handleRawError(response: HttpErrorResponse, errorMessages: string[]) {

    if (this.skipNextErrorChecks) {
      return;
    }

    if (response.status === 420) {
      this.renderError(response, errorMessages);
    }
  }

  private renderValidationError(response: HttpErrorResponse, errorMessages: string[]) {

    if (Array.isArray(response.error)) {
      (response.error as ErrorMessage[]).forEach((errorMessage: ErrorMessage) =>
        this.notificationService.addDynamicErrorNotification(
          'backend.validation.error.' + errorMessage.code, true, errorMessage.dynamicContent, this.errorMessageTimeout));
    } else {
      errorMessages.push('backend.validation.error.999');
    }
  }

  private renderError(response: HttpErrorResponse, errorMessages: string[], prefix?: string) {
    if (Array.isArray(response.error)) {
      const errorMessagesFromResponse: ErrorMessage[] = response.error;
      errorMessagesFromResponse
        .map((errorMessage: ErrorMessage) => !!prefix ? prefix + errorMessage.code : errorMessage.message)
        .forEach(errorMessage => errorMessages.push(errorMessage));
    } else {
      errorMessages.push('backend.validation.error.999');
    }
  }
}
