import {AfterContentChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Formulation} from '../../../model/formulation';
import {Observable} from 'rxjs';
import {ConfirmService} from '../../../service/confirm.service';
import {FormulationEditDataService} from '../../../service/data-service/formulation-edit-data.service';
import InjectIsReadonlyUser from '../../../decorator/inject-is-readonly-user.decorator';
import {HasUnsavedChangesGuard} from "../../../guards/has-unsaved-changes.guard";

@Component({
  selector: 'app-formulation-edit',
  templateUrl: './formulation-edit.component.html',
  styleUrls: ['./formulation-edit.component.less']
})
export class FormulationEditComponent implements OnInit, AfterContentChecked, HasUnsavedChangesGuard {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;
  public formulation: Formulation = new Formulation();
  public selectedTab = 'properties';
  private noCanDeactivateCheck: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly confirmService: ConfirmService,
    private readonly formulationEditDataService: FormulationEditDataService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formulationEditDataService.init(params.id);
    });
    this.isReadOnlyUser.subscribe(isReadOnlyUser => this.noCanDeactivateCheck = isReadOnlyUser)
  }
  ngAfterContentChecked(): void{
    this.route.firstChild.url.subscribe((url) => {
      if (url[url.length - 1].path != this.selectedTab){
        this.selectedTab = url[url.length - 1].path;
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.formulationEditDataService.formulationChanged() && !this.noCanDeactivateCheck) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public editItem(formulation: Formulation) {
    this.router.navigateByUrl('formulations/' + formulation.id);
  }

  public cancel() {
    this.router.navigate(['/formulations']);
  }

  public save = () =>  this.formulationEditDataService.saveFormulation(this.selectedTab);
  public getFormulationId = (): string  => this.formulationEditDataService.findFormulation().id || null;
  public isFormulationLoaded = () => this.formulationEditDataService.isFormulationLoaded();
}
