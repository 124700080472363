import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { EnumerationValue } from '../../../../model/enumeration-value';

@Component({
  selector: 'enumeration-attribute-type-edit',
  templateUrl: './enumeration-attribute-type-edit.component.html',
  styleUrls: ['./enumeration-attribute-type-edit.component.less']
})
export class EnumAttributeTypeEditComponent implements OnInit {

  @Input()
  public label: string;

  private _enumerationValues: string[];

  @Output() enumerationValuesChange = new EventEmitter<string[]>();

  @Input()
  get enumerationValues(): string[] {
    return this._enumerationValues;
  }

  set enumerationValues(val) {
    this._enumerationValues = val;
    this.enumerationValuesChange.emit(this._enumerationValues);
  }

  private _enumerationValuesView: EnumerationValue[] = [];

  public get enumerationValuesView(): EnumerationValue[] {
    return this._enumerationValuesView;
  }

  public set enumerationValuesView(values: EnumerationValue[]) {
    this._enumerationValuesView = values;
    this.enumerationValues = values.map(ev => ev.name).filter(name => !!name);
  }

  public addEnumerationValue(): void {
    if (!this.enumerationValues) {
      return;
    }

    this.enumerationValuesView =  [...this.enumerationValuesView, new EnumerationValue("")];
  }

  public removeEnumerationValue(enumerationValue: EnumerationValue): void {
    this.enumerationValuesView = this.enumerationValuesView.filter(enumValue => {
      return enumValue.name !== enumerationValue.name;
    });
  }

  ngOnInit() {
    this.initializeEnumerationValuesView();
  }

  public onSingleChoiceListValueChange(input: string, enumerationValue: EnumerationValue){
    enumerationValue.name = input;
    this.enumerationValuesView = [...this.enumerationValuesView];
  }

  private initializeEnumerationValuesView(): void {
    if (!this.enumerationValues || this.enumerationValues.length === 0) {
      this._enumerationValuesView = [new EnumerationValue('')];
      return;
    }

    this.enumerationValues.forEach(value => {
      this._enumerationValuesView.push(new EnumerationValue(value));
    });
  }

}
