import { BusinessHierarchyNode } from "./business-hierarchy-node";

/**
 * Response from the business hierarchy service (proxied through PIM backend)
 */
export class BusinessHierarchyResponse {
    businessHierarchy: BusinessHierarchyNode[] = [];
    
    constructor(businessHierarchy = []) {
      this.businessHierarchy = businessHierarchy;
    }
}