<container-element [ngSwitch]="attribute.type">

  <string-edit *ngSwitchCase="'STRING'"
    [showCharactersLeft]="true"
    [attributeValue]="attributeValue"
    [readonly]="readonly">
  </string-edit>

  <star-attribute-edit *ngSwitchCase="'STAR_RATING'"
    [attributeValue]="attributeValue"
    [readonly]="readonly">
  </star-attribute-edit>

  <number-input-edit *ngSwitchCase="'NUMBER'"
    [attributeValue]="attributeValue"
    [readonly]="readonly">
  </number-input-edit>

  <single-choice-list-attribute-edit *ngSwitchCase="'ENUMERATION'"
    [attributeValue]="attributeValue"
    [attribute]="attribute"
    [readonly]="readonly">
  </single-choice-list-attribute-edit>

  <localized-enumeration-attribute-edit *ngSwitchCase="'LOCALIZED_ENUMERATION'"
    [attributeValue]="attributeValue"
    [readonly]="readonly">
  </localized-enumeration-attribute-edit>

  <boolean-attribute-edit *ngSwitchCase="'BOOL'"
    [attributeValue]="attributeValue"
    [readonly]="readonly">
  </boolean-attribute-edit>

  <range-edit *ngSwitchCase="'RANGE'"
    [attributeValue]="attributeValue"
    [readonly]="readonly">
  </range-edit>

  <localized-string-attribute-edit *ngSwitchCase="'LOCALIZED_STRING'"
    id="{{ getId() }}-{{ attribute?.id }}"
    [attributeValue]="attributeValue"
    [attribute]="attribute"
    [readonly]="readonly"
    inputType="editor">
  </localized-string-attribute-edit>

  <localized-string-list-attribute-edit *ngSwitchCase="'LOCALIZED_STRING_LIST'"
    [attributeValue]="attributeValue"
    [attribute]="attribute"
    [readonly]="readonly"
    inputType="textarea">
  </localized-string-list-attribute-edit>

  <relationship-attribute-edit *ngSwitchCase="'RELATIONSHIP'"
    [attributeValues]="attributeValues"
    [attribute]="attribute"
    [readonly]="readonly"
    (updateAttributeValueChange)="updateCategoryRelationAttributeValue($event, categoryRelation, attribute)">
  </relationship-attribute-edit>

  <string-list-edit *ngSwitchCase="'STRING_LIST'"
    [attributeValues]="attributeValues"
    [attribute]="attribute"
    [readonly]="readonly"
    (updateAttributeValueChange)="updateCategoryRelationAttributeValue($event, categoryRelation, attribute)">
  </string-list-edit>

  <multiple-choice-list-attribute-edit *ngSwitchCase="'ENUMERATION_MULTIPLE'"
    [attributeValues]="attributeValues"
    [attribute]="attribute"
    [readonly]="readonly"
    (updateAttributeValueChange)="updateCategoryRelationAttributeValue($event, categoryRelation, attribute)">
  </multiple-choice-list-attribute-edit>

  <localized-enumeration-multiple-attribute-edit *ngSwitchCase="'LOCALIZED_ENUMERATION_MULTIPLE'"
    [attributeValues]="attributeValues"
    [attribute]="attribute"
    [readonly]="readonly"
    (updateAttributeValueChange)="updateCategoryRelationAttributeValue($event, categoryRelation, attribute)">
  </localized-enumeration-multiple-attribute-edit>

</container-element>
