import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paramsFromString'
})
export class ParamsFromStringPipe implements PipeTransform {

  transform(value: string): any {
    const params = {};
    if (!!value) {
        value.split('&').forEach(param => params[param.split('=')[0]] = param.split('=')[1]);
    }
    return params;
  }  
}