<div>
  <label *ngIf="inputLabel">{{ inputLabel }}</label>
  <div id="{{ idPrefix }}-value-list" class="value-list">

    <div *ngIf="values && !activateSorting">
      <div id="{{ idPrefix }}-non-sorted-values" *ngFor="let value of values; let i = index let lastItem = last">
        <div *ngIf="value && itemVisibilityCriteria(value)" [class.non-matching]="!isMatching(value)">
          <div class="custom-input-group-text value-template-background mb-2">
            {{valueTemplate ? '' : this.valueFormatter(value) }}
            <ng-container *ngTemplateOutlet="valueTemplate, context: { 'result': value }"></ng-container>
            <div class="clickable iconlist">
              <fa-icon id="{{ idPrefix }}-delete-button" *ngIf="!readonly && !value.isReadOnly" icon="trash" aria-label="Close" (click)="removeValue(value, i)"></fa-icon>
              <fa-icon *ngIf="!!value.isReadOnly" icon="link"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="values && activateSorting" cdkDropList (cdkDropListDropped)="dropMethod($event)" [cdkDropListData]="values">
      <div id="{{ idPrefix }}-sorted-values" *ngFor="let value of values; let i = index let lastItem = last" cdkDrag [cdkDragDisabled]="isDragDisabled(value)">
        <div *ngIf="value && itemVisibilityCriteria(value) && activateSorting" class="custom-input-group-text value-template-background mb-2" [class.non-matching]="!isMatching(value)">
          {{valueTemplate ? '' : this.valueFormatter(value) }}
          <ng-container *ngTemplateOutlet="valueTemplate, context: { 'result': value }"></ng-container>
          <div class="clickable iconlist">
            <fa-icon *ngIf="!isDragDisabled(value)" icon="up-down"></fa-icon>
            <fa-icon id="{{ idPrefix }}-delete-button" *ngIf="!readonly && !value.isReadOnly" icon="trash" aria-label="Close" (click)="removeValue(value, i)"></fa-icon>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
