import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Pageable } from '../../model/pageable';
import { User } from '../../model/user';
import { TypeaheadExcludeIdRequest } from '../../model/request/typeahead-exclude-id-request';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';

@Injectable({
  providedIn: 'root'
})
export class UserTypeaheadService implements AbstractTypeaheadService<User> {
  
  constructor(
    private readonly baseTypeaheadService: BaseTypeaheadService
  ) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<User>> {

    let body = new TypeaheadExcludeIdRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      exclude
    );

    return this.baseTypeaheadService.createCall('/user/typeahead', body, pageable);
  }
  
  public typeaheadFormatter(model: User): string {
    if(!!model) {
      return `${model.cn} (${model.surname}, ${model.firstname}) [${model.email}] <${model.realms}>`
    }
    return '';
  }

}
