import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Pageable } from '../../model/pageable';
import { BaseTypeaheadService } from './base-typeahead.service';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { TypeaheadExcludeIdRequest } from '../../model/request/typeahead-exclude-id-request';
import { CategoryAttribute } from '../../model/category-attribute';
import { TypeaheadTypeAndExcludeIdRequest } from '../../model/request/typeahead-type-and-exclude-id-request';
import { AttributeRelevance } from '../../model/attribute-relevance';

@Injectable({
  providedIn: 'root'
})
export class CategoryAttributeTypeaheadService implements AbstractTypeaheadService<CategoryAttribute> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<CategoryAttribute>> {
    
    let body = new TypeaheadExcludeIdRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      exclude || []
    );

    return this.baseTypeaheadService.createCall('/category/attribute/typeahead', body, pageable);
  }

  public typeaheadFormatter(model: CategoryAttribute): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.attribute.name);
  }

}
