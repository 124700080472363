import { FormValidationService } from '../form-validation.service';
import { Injectable } from '@angular/core';
import { NotificationService } from '../notification.service';
import { FormulationStep } from '../../model/formulation-step';

@Injectable()
export class FormulationStepFormValidationService extends FormValidationService {
    constructor(protected notificationService: NotificationService) {
        super(notificationService);
        this.addValidation(this.validateMaterialPopulated);
        this.addValidation(this.validateQuantityPopulated);
        this.addValidation(this.validateUnit);
        this.addValidation(this.validateFunction);
    }

    public validateMaterialPopulated(validation: FormulationStepFormValidationService, obj: FormulationStep): boolean {
        if (!obj.material) {
            validation.addError('label.formulationstep.no_material_selected');
            return false;
        }
        if (typeof obj.material === 'string') {
            validation.addError('label.formulationstep.invalid_material_selected');
            return false;
        }
        return true;
    }

    public validateQuantityPopulated(validation: FormulationStepFormValidationService, obj: FormulationStep): boolean {
        if (!obj.quantity) {
            validation.addError('label.formulationstep.no_quantity_selected');
            return false;
        }

        if (obj.quantity <= 0) {
            validation.addError('label.formulationstep.too_low_quantity_selected');
            return false;
        }
        return true;
    }

    public validateUnit(validation: FormulationStepFormValidationService, obj: FormulationStep): boolean {
        if (!obj.unit) {
            validation.addError('label.formulationstep.no_unit_selected');
            return false;
        }
        return true;
    }

    public validateFunction(validation: FormulationStepFormValidationService, obj: FormulationStep): boolean {
        if (obj.function && typeof obj.function === 'string') {
            validation.addError('label.formulationstep.invalid_function_selected');
            return false;
        }
        return true;
    }
}
