<div *ngIf="!((categoryMaterials | async) && (templateViewMaterial | async) && (renderingOptionsBehaviourSubject | async))">
  ...Loading...
</div>
<div *ngIf="(categoryMaterials | async) && (categoryMaterials | async)?.length == 0">
  Nothing to show here <fa-icon icon="kitchen-set"></fa-icon>
</div>
<div *ngIf="(categoryMaterials | async) && (templateViewMaterial | async) && (renderingOptionsBehaviourSubject | async)">
  <mat-accordion [ngClass]="'accordion'" *ngIf="attributesToBeShownByCategoryRelation?.length > 0">
    <mat-expansion-panel [ngClass]="'accordion-item ng-star-inserted'" [expanded]="true">
      <mat-expansion-panel-header [ngClass]="'accordion-header'">
        <mat-panel-title><fa-icon class="grip-vertical" icon="grip-vertical"></fa-icon>
        {{ 'label.administration.documentcreation.materialattributes.material_attributes' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="accordion-body">
        
        <app-attributes
          [categoryRelationAttributes]="attributesToBeShownByCategoryRelation"
          [areAttributeValuesMandatory]="true"
          [preSortAttributesFunction]="preSortAttributes"
          [showInfoButton]="true"
          [isDisabled]="isDisabled"
          [readonly]="(isNotAdvancedUser | async)"
          [readonlyVisibility]="(isNotAdvancedUser | async)">
        </app-attributes>
  
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  
  <mat-accordion [ngClass]="'accordion'" *ngFor="let listIdWithCategoryRelation of getTemplateViewListKeys()">
    <mat-expansion-panel [ngClass]="'accordion-item ng-star-inserted'" [expanded]="true">
      <mat-expansion-panel-header [ngClass]="'accordion-header'">
        <mat-panel-title><fa-icon class="grip-vertical" icon="grip-vertical"></fa-icon>
        {{ getTemplateViewListNameFromId(listIdWithCategoryRelation) }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="accordion-body">
        
        <app-attributes 
          [categoryRelationAttributes]="getCategoryRelationFromTemplateViewListId(listIdWithCategoryRelation)"
          [areAttributeValuesMandatory]="true"
          
          [sortAttributesFunction]="sortAttributes(listIdWithCategoryRelation)"
          [showInfoButton]="true"
          [isDisabled]="isDisabled"
          [readonly]="(isNotAdvancedUser | async)"
          [readonlyVisibility]="(isNotAdvancedUser | async)">
        </app-attributes>
  
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>