import * as _ from 'lodash';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryMaterial } from '../../../../../model/category-material';
import { Attribute } from '../../../../../model/attribute';
import { CustomViewCategoryAttribute } from '../../../../../model/custom-view/custom-view-category-attribute';
import { CategoryAttribute } from '../../../../../model/category-attribute';
import { CustomView } from '../../../../../model/custom-view/custom-view';
import { RenderingOption } from '../../../../../model/document-creation/rendering-option';
import { TemplateViewList } from '../../../../../model/custom-view/template-view-list/template-view-list';
import { AttributeRelevance } from '../../../../../model/attribute-relevance';
import { DocumentCreationDataService } from '../../../../../service/data-service/document-creation-data.service';
import { MaterialEditDataService } from '../../../../../service/data-service/material-edit-data.service';
import InjectIsNotAdvancedUser from '../../../../../decorator/inject-is-not-advanced-user.decorator';

@Component({
  selector: 'app-tab-material-attributes',
  templateUrl: './tab-material-attributes.component.html',
  styleUrls: ['./tab-material-attributes.component.less']
})
export class TabMaterialAttributesComponent implements AfterContentInit, OnInit {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;


  public get renderingOptions() {
    return this.documentCreationDataService.renderingOptionsBehaviorSubject;
  }

  public get templateViewMaterial() {
    return this.documentCreationDataService.templateMaterialBehaviorSubject;
  }

  public get categoryMaterials() {
    return this.materialEditDataService.categoryMaterialsBehaviourSubject;
  }

  public get selectedCustomView() {
    return this.templateViewMaterial?.value?.templateView;
  }

  public customViewCategoryAttributesMap: Map<string, CategoryAttribute[]> = new Map();
  public attributesToBeShownByCategoryRelation: [CategoryMaterial, Attribute][] = [];
  public attributesToBeShownByCategoryRelationForListMap: Map<string, [CategoryMaterial, Attribute][]> = new Map();

  
  public get renderingOptionsBehaviourSubject(): BehaviorSubject<RenderingOption[]> {
    return this.documentCreationDataService.renderingOptionsBehaviorSubject;
  }
  
  public get templateViewListsBehaviourSubject(): BehaviorSubject<TemplateViewList[]> {
    return this.documentCreationDataService.templateViewListsBehaviourSubject;
  }

  constructor(
    private readonly materialEditDataService: MaterialEditDataService,
    private readonly documentCreationDataService: DocumentCreationDataService,
  ) { }

  ngAfterContentInit(): void {
    this.materialEditDataService.customViewCategoryAttributesBehaviorSubject.asObservable().subscribe(customViewCategoryAttributes => {
      if (customViewCategoryAttributes?.length > 0) {
        this.calculateCustomViewAndCategoryAttributeMaps(customViewCategoryAttributes);
        this.calculateAttributeToBeShown();
      }
    });
  }

  ngOnInit(): void {
  }

  public isDisabled = (categoryRelation: CategoryMaterial, attribute: Attribute): boolean => {
    return this.documentCreationDataService.isDisabled(categoryRelation, attribute);
  }

  public preSortAttributes = (categoryMaterialAttribute: [CategoryMaterial, Attribute]): number => {
    return this.isDisabled(categoryMaterialAttribute[0], categoryMaterialAttribute[1]) ? 1 : 0;
  }

  public sortAttributes = (templateViewListId: string) => {
    return (categoryMaterialAttribute: [CategoryMaterial, Attribute]) => {
      let sortedValues = this.attributesToBeShownByCategoryRelationForListMap.get(templateViewListId);
      return _.orderBy(categoryMaterialAttribute, [(item) => this.getIndexOf(sortedValues, [item[0], item[1]])]);
      
    }
  }

  private getIndexOf(sortedValues: [CategoryMaterial, Attribute][], categoryMaterialAttribute: [CategoryMaterial, Attribute]): number {
    return sortedValues.findIndex(value => value[0].category.id === categoryMaterialAttribute[0].category.id && value[1].id === categoryMaterialAttribute[1].id);
  }

  public calculateCustomViewAndCategoryAttributeMaps(customViewCategoryAttributes: CustomViewCategoryAttribute[]) {
    this.customViewCategoryAttributesMap.clear();

    customViewCategoryAttributes.forEach(customViewCategoryAttribute => {
      const customView = customViewCategoryAttribute.customView;
      this.addCategoryAttributeIfNotExists(customView, customViewCategoryAttribute.categoryAttribute);
    });
  }

  private addCategoryAttributeIfNotExists(customView: CustomView, categoryAttribute: CategoryAttribute) {
    if(!this.customViewCategoryAttributesMap.has(customView.id)) {
      this.customViewCategoryAttributesMap.set(customView.id, []);
    }
    this.customViewCategoryAttributesMap.get(customView.id).push(categoryAttribute);
  }

  public calculateAttributeToBeShown(): void {
    this.attributesToBeShownByCategoryRelation = [];
    this.attributesToBeShownByCategoryRelationForListMap.clear();

    if (!!this.selectedCustomView) {
      const categoryAttributes: CategoryAttribute[] = this.customViewCategoryAttributesMap.get(this.selectedCustomView.id);

      this.materialEditDataService.catMats.forEach(categoryMaterial => {
        if(categoryAttributes?.some(categoryAttribute => categoryAttribute.category.id === categoryMaterial.category.id)) {

          const uniqAttributes: Attribute[] = _.uniqBy(this.attributesByCategoryMaterial(categoryAttributes, categoryMaterial), 'id');

          if(uniqAttributes.length > 0) {
            uniqAttributes.forEach(attribute => {
              // We are allowing to have the same attribute in multiple lists
              let templateViewListIds = this.getRelatedLists(categoryMaterial, attribute);
              if (templateViewListIds.length > 0){
                this.addCategoryAttributeToListIfNotExists(categoryMaterial, attribute, templateViewListIds);
              }
              // This (categoryMaterial, attribute) could be in lists and not in lists
              if (this.isAttributeNotInLists(categoryMaterial, attribute)){
                this.attributesToBeShownByCategoryRelation.push([categoryMaterial, attribute]);
              }
            });
          }
        }
      });

      this.reorderAttributesInLists();
    }

  }

  private isAttributeNotInLists(categoryMaterial: CategoryMaterial, attribute: Attribute): boolean {
    if (this.renderingOptionsBehaviourSubject.value == null || this.renderingOptionsBehaviourSubject.value.length === 0){
      return null;
    }
    return this.renderingOptionsBehaviourSubject.value.some(ro => ro.templateViewListId === null 
        && ro.categoryAttribute.attribute.id === attribute.id 
        && ro.categoryAttribute.category.id === categoryMaterial.category.id
      );
  }

  private getRelatedLists(categoryMaterial: CategoryMaterial, attribute: Attribute): string[] {
    // Get the related list ids from the rendering options instead of the templateviewList themself because it could be deleted in templateviewList
    if (this.renderingOptionsBehaviourSubject.value == null || this.renderingOptionsBehaviourSubject.value.length === 0){
      return null;
    }
    let relatedList = this.renderingOptionsBehaviourSubject.value.filter(ro => ro.templateViewListId != null 
        && ro.categoryAttribute.attribute.id === attribute.id 
        && ro.categoryAttribute.category.id === categoryMaterial.category.id
      ).map(ro => ro.templateViewListId);
    
    return relatedList;
  }

  private addCategoryAttributeToListIfNotExists(categoryMaterial: CategoryMaterial, attribute: Attribute, templateViewListIds: string[]) {

    const uniqTemplateViewListIds: string[] = _.uniq(templateViewListIds);
    uniqTemplateViewListIds.forEach(templateViewListId => {
      if(!this.attributesToBeShownByCategoryRelationForListMap.has(templateViewListId)) {
        this.attributesToBeShownByCategoryRelationForListMap.set(templateViewListId, []);
      }
      this.attributesToBeShownByCategoryRelationForListMap.get(templateViewListId).push([categoryMaterial, attribute]);
    });
  }

  public getTemplateViewListKeys(){
    return Array.from(this.attributesToBeShownByCategoryRelationForListMap.keys());
  }

  public getTemplateViewListNameFromId(id: string): string {
    return this.templateViewListsBehaviourSubject.value.find(templateViewList => templateViewList.id === id).name;
  }

  public getCategoryRelationFromTemplateViewListId(id: string): [CategoryMaterial, Attribute][] {
    return this.attributesToBeShownByCategoryRelationForListMap.get(id);
  }

  private reorderAttributesInLists() {
    if (this.attributesToBeShownByCategoryRelationForListMap.size > 0) {
      let templateViewListIterator = this.attributesToBeShownByCategoryRelationForListMap.keys();
      Array.from(templateViewListIterator).forEach(templateViewListId => {
        this.reorderValues(this.attributesToBeShownByCategoryRelationForListMap.get(templateViewListId), templateViewListId);
      });
    }
  }

  private reorderValues(values: [CategoryMaterial, Attribute][], templateViewListId: string){
    let renderingOptions = this.renderingOptionsBehaviourSubject.value.filter(ro => ro.templateViewListId === templateViewListId && !!ro.matching);
    const orderedArray = _.orderBy(renderingOptions, ['matching','enabled', 'order'], ['desc', 'desc', 'asc']);
    const orderedValuesArray = _.orderBy(values, (value: [CategoryMaterial, Attribute]) => this.getOrder(value, orderedArray));
    values.splice(0, values.length, ...orderedValuesArray);
  }

  private getOrder(value: [CategoryMaterial, Attribute], orderedArray: RenderingOption[]) {
    const renderingOption = orderedArray.find(ro => ro.categoryAttribute.category.id === value[0].category.id && ro.categoryAttribute.attribute.id === value[1].id);
    return renderingOption?.order || 0;
  }

  private attributesByCategoryMaterial(categoryAttributes: CategoryAttribute[], categoryMaterial: CategoryMaterial): Attribute[] {
    return categoryAttributes
      .filter(categoryAttribute => this.categoryIdsAreEqual(categoryAttribute, categoryMaterial))
      .map(categoryAttribute => this.exchangeAttributeFromCategoryMaterialResponse(categoryMaterial, categoryAttribute.attribute))
      // Filter after exchange to get relevant attributes properly, 
      // because sometimes categoryAttribute.attribute.attributeRelevance is empty even when it should not be
      .filter(attribute => this.isAttributeRelevant(attribute)); 
  }

  private exchangeAttributeFromCategoryMaterialResponse(categoryMaterial: CategoryMaterial, attribute: Attribute): Attribute {
    return categoryMaterial.category.categoryAttributes
      .find(categoryAttribute => categoryAttribute.attribute.id === attribute.id)
      .attribute;
  }

  private categoryIdsAreEqual(categoryAttribute: CategoryAttribute, categoryMaterial: CategoryMaterial): boolean {
    return categoryAttribute.category.id === categoryMaterial.category.id;
  }

  private isAttributeRelevant(attribute: Attribute): boolean {
     return attribute.attributeRelevance.includes(AttributeRelevance.MATERIAL);
  }
}
