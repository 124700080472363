import { Injectable } from '@angular/core';
import { FormulationPart } from '../model/formulation-part';
import { orderBy } from 'lodash';
import { LocalizedStringService } from './localized-string.service';
import { FormulationStepService } from './formulation-step.service';

@Injectable()
export class FormulationPartService {
  constructor(
    private localizedStringService: LocalizedStringService,
    private formulationStepService: FormulationStepService
  ){}

  public getOrderedFormulationParts(formulationParts: FormulationPart[]): any[] {
    return orderBy(formulationParts.map(formulationPart => {
      let name = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(formulationPart.name);
      let steps = this.formulationStepService.getOrderedFormulationSteps(formulationPart.steps);
      return {order: formulationPart.order, ratio: formulationPart.ratio, name: name, steps: steps};
    }), ['order']);
  }
}
