import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import ConditionalRequired from '../../../decorator/conditional-required.decorator';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ConfirmService } from '../../../service/confirm.service';

@Component({
  selector: 'pim-grey-bar-stack',
  templateUrl: './grey-bar-stack.component.html',
  styleUrl: './grey-bar-stack.component.less'
})
export class GreyBarStackComponent  implements OnInit {

  @Input()
  public values: any[];

  @Input()
  public idPrefix: string = "multiple-value-selector";

  @Output()
  public valueRemoved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public valuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  public validationError: { required: boolean } = { required: false };

  @Input()
  public inputLabel: string = null;

  @Input()
  public inputName: string;

  @ConditionalRequired({ field: 'valueTemplate' })
  @Input()
  public valueFormatter: any;

  @ConditionalRequired({ field: 'valueFormatter' })
  @Input()
  public valueTemplate: TemplateRef<any>;

  @Input() // field to reflect the switch state
  public enabledField: string;

  @Input() // field to reflect the switch state
  public matchingField: string;

  @Input() public cssClass = 'mv-list';
  @Input() public deleteConfirmTitle: string;
  @Input() public deleteConfirmText: string;
  @Input() public readonly: boolean = false;

  @Input()
  public executeBeforeDelete: (obj: any) => any = (obj: any) => obj;

  @Input()
  public executeAfterDelete: (valueSet: any[], obj: any, id: string) => any = (obj: any) => obj;

  @Input()
  public itemVisibilityCriteria: (value: any) => boolean = (value: any) => (value ? true : false);

  @Input()
  public activateSorting: boolean = false;

  @Input()
  public dropMethod: (event: CdkDragDrop<any>) => any = (obj: any) => obj;

  constructor(private confirmService: ConfirmService) { }

  ngOnInit() {
    // Needed for @Required and @ConditionalRequired decorator
  }

  public removeValue(value: any, index: number) {
    if (this.deleteConfirmTitle && this.deleteConfirmText) {
      this.confirmService.confirm(this.deleteConfirmTitle, this.deleteConfirmText, 'button.yes', 'button.no').then((result) => {
        if (result) {
          this.deleteValue(value, index);
        }
      });
    } else {
      this.deleteValue(value, index);
    }
  }

  private deleteValue(value: any, index: number) {
    this.executeBeforeDelete(value);

    const valuesCount = this.values.length;
    this.values.splice(index, 1);
    if (valuesCount > this.values.length) {
      this.valueRemoved.emit(value);
    }

    this.executeAfterDelete(this.values, value, null);
    this.valuesChange.emit(this.values);
  }

  public isDragDisabled(value: any) {
    if (!!this.enabledField) {
      return !value[this.enabledField];
    }
    return false;
  }

  public isMatching(value: any) {
    if (!!this.matchingField) {
      return value[this.matchingField];
    }
    return true;
  }
}
