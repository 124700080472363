import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Attribute } from '../../../../model/attribute';
import { AttributeValue } from '../../../../model/attribute-value';
import { AttributeValueService } from '../../../../service/attribute-value.service';
import { SingleChoiceListValue } from "../../../../model/single-choice-list-value";
import * as _ from 'lodash';

@Component({
  selector: 'multiple-choice-list-attribute-edit',
  templateUrl: './multiple-choice-list-attribute-edit.component.html'
})
export class MultipleChoiceListAttributeEditComponent implements OnInit {

  get attributeValues(): AttributeValue[] {
    return this._attributeValues;
  }

  @Input()
  set attributeValues(value: AttributeValue[]) {
    if(!_.isEqual(this._attributeValues, value)) {
      this._attributeValues = value;
      if (!!this.attribute) {
        this.calculateData();
      }
    }
  }

  @Input()
  public label: string;

  @Input()
  public attribute: Attribute;

  private _attributeValues: AttributeValue[];

  @Input()
  public readonly: boolean = false;

  @Output()
  public updateAttributeValueChange = new EventEmitter<AttributeValue[]>();

  public filteredAttributeValues: AttributeValue[] = [];

  public singleChoiceListValuesSelected: SingleChoiceListValue[] = [];
  private initialAttributeValues: AttributeValue[];

  constructor(public attributeValueService: AttributeValueService) {}

  ngOnInit() {
    this.calculateData();
  }

  private calculateData() {
    this.filteredAttributeValues = this.attributeValueService.getAttributeValues(this._attributeValues, this.attribute);
    this.initialAttributeValues = [...this.filteredAttributeValues];
    for (const initialAttribute of this.initialAttributeValues) {
      if (initialAttribute && initialAttribute.singleChoiceListValue) {
        this.singleChoiceListValuesSelected.push(initialAttribute.singleChoiceListValue);
      }
    }
    this.attribute.singleChoiceListValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
  }

  public updateSelectedValues(selectedValue: SingleChoiceListValue) {

    this.filteredAttributeValues = this.filteredAttributeValues.filter(attributeValue => {
      return this.singleChoiceListValuesSelected.includes(attributeValue.singleChoiceListValue);
    });
    this.singleChoiceListValuesSelected.forEach(singleChoiceListValue => {
      this.addAttributeValue(singleChoiceListValue);
    });
    this.updateAttributeValueChange.emit(this.filteredAttributeValues);
  }

  private addAttributeValue(singleChoiceListValue: SingleChoiceListValue): void {
    if (!this.filteredAttributeValues.some(av => av.singleChoiceListValue === singleChoiceListValue)) {
      const newAttributeValue: AttributeValue = this.attributeValueService.createNewAttributeValue(this.attribute);
      newAttributeValue.singleChoiceListValue = singleChoiceListValue;
      this.filteredAttributeValues.push(newAttributeValue);
    }
  }

  public compareFn = (a, b) => a && b && a.id === b.id;
}
