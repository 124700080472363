<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal">{{'headline.administration.mass.import'|translate}}</h2>

  <div class="row">
    <div class="col-6">
      <input type="file" id="importFile" (change)="fillExportFile($event.target.files[0])">
      <button class="btn btn-primary" (click)="exportCategoryMaterials()">{{'button.mass-import.export.category.materials' | translate}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <input type="file" id="updateFile" (change)="fillUpdateFile($event.target.files[0])">
      <button class="btn btn-primary"
              (click)="updateCategoryMaterials()">{{'button.mass-import.update.category.materials' | translate}}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <input type="file" id="updateFile" (change)="fillExpireDocumentFile($event.target.files[0])">
      <button class="btn btn-primary"
              (click)="updateExpireDateForDocument()">{{'button.mass-import.update.expire.date.for.documents' | translate}}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <input type="file" id="updateCategoryFile" (change)="fillCategoryFile($event.target.files[0])">
      <button class="btn btn-primary" (click)="createCategories()">{{'button.mass-import.create.new.categories'| translate}}</button>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <input type="file" id="exportMaterialFile" (change)="exportMaterialFile($event.target.files[0])">
      <button class="btn btn-primary" (click)="exportMaterials()"> {{"button.mass-import.export.attribute.values" | translate}}
      </button>
    </div>

    <div class="col-3">
      <button class="btn btn-link" (click)="exportMaterialsTemplate()">{{ "button.mass-import.download.empty.template" | translate}}</button>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <input type="file" id="patchMaterialFile" (change)="patchMaterialFile($event.target.files[0])">
      <button class="btn btn-primary" (click)="patchMaterials()"> {{"button.mass-import.patch.attribute.values" |
        translate}}
      </button>
    </div>

    <div class="col-3">
      <button class="btn btn-link"
              (click)="patchMaterialsTemplate()">{{ "button.mass-import.download.empty.template" | translate}}</button>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div *ngFor="let result of results" class="row">
        Error in line {{result.line}}: <span *ngFor="let error of result.errors">
          {{error}}
        </span>
      </div>
    </div>
  </div>

  </div>