import { TemplateRef } from "@angular/core";
import { Observable } from "rxjs";
import { SortType } from "./sort-type";

export class TableColumn<T> {

  public sortDirection: SortType;

  constructor(
    public field: string,
    public name: Observable<string>,
    public valueFunction: (item: T) => string,
    public isFilterable: boolean,
    public isSortable: boolean,
    public facetFormattingFunction: (item: T) => string = null,
    public facetFormatting: boolean = false,
    public template: TemplateRef<any> = null
  ) {
    if ((!!valueFunction && !!template)) {
      throw new Error('Only one value must be set: TableColumn.valueFunction() or TableColumn.template!');
    }
  }

}
