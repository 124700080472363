<div *ngIf="!!(document | async)" class="container-fluid pb-3">
  <div>
    <h2 class="display-6 fw-normal">
      {{ (!!(document | async) && !!(document | async).id) ? getDocumentHeader() : ('headline.documents.add' | translate) }}
    </h2>
    <div [ngClass]="getStatusClass(document | async)" class="document-status" id="document-status">
      {{ getDocumentTag(document | async) | lowercase | translate }}
    </div>
  </div>

  <ul #nav="ngbNav" [activeId]="selectedTab" class="nav-tabs" ngbNav>

    <li [ngbNavItem]="'properties'" [queryParams]="params" [routerLink]="'properties'" id="properties">
      <a ngbNavLink>{{ 'tab.document.properties' | translate }}</a>
    </li>

    <li [ngbNavItem]="'relations'" [queryParams]="params" [routerLink]="'relations'" id="relations">
      <a ngbNavLink>{{ 'tab.document.relations' | translate }}</a>
    </li>

    <li [ngbNavItem]="'versions'" [queryParams]="params" [routerLink]="'versions'" id="versions">
      <a ngbNavLink>{{ 'tab.document.versions' | translate }}</a>
    </li>


  </ul>

  <div class="border-router-outlet">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="!editVersion" class="row mb-3 mt-3">
    <div class="col-12 align-right">
      <icon-button (buttonClick)="cancel()" *ngIf="!cancelUrl" buttonType="cancel" id="document-cancel"></icon-button>
      <icon-button (buttonClick)="cancel()" *ngIf="!!cancelUrl" buttonType="return" id="document-return"></icon-button>
      <icon-button (buttonClick)="onSaveButtonClicked()" *ngIf="(document | async).id && isDocumentPublished(document | async) && !(isReadOnlyUser | async)"
                   buttonType="save"
                   id="document-save"></icon-button>
      <icon-button (buttonClick)="onSaveButtonClicked()" *ngIf="!(document | async).id && !(isReadOnlyUser | async)" buttonType="add doc as not published"
                   id="document-add-as-not-published"></icon-button>
      <icon-button (buttonClick)="onSaveButtonClicked()" *ngIf="(document | async).id && !isDocumentPublished(document | async) && !(isReadOnlyUser | async)"
                   buttonType="save not published" id="document-save-not-published"></icon-button>
      <icon-button (buttonClick)="saveAndPublish()" *ngIf="(document | async).id && !isDocumentPublished(document | async) && !(isReadOnlyUser | async)"
                   buttonType="save and publish" id="document-save-and-publish"></icon-button>
      <icon-button (buttonClick)="saveAndPublish()" *ngIf="!(document | async).id && !(isReadOnlyUser | async)" buttonType="add and publish"
                   id="document-add-and-publish"></icon-button>
    </div>
  </div>

</div>
