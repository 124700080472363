<label *ngIf="inputLabel" for="{{typeaheadId}}">{{ inputLabel }}</label>
<div class="loading-container" [ngClass]="{ 'input-group': buttonPrepend || buttonAppend }">

  <button  *ngIf="buttonPrepend" type="button" class="btn btn-primary" (click)="buttonPrependClickEvent()" [disabled]="buttonPrependDisabled">
    <fa-icon [icon]="buttonPrependType"></fa-icon>
  </button>

  <input #typeaheadInput id="{{ typeaheadId }}" class="form-control" name="{{inputName}}" placeholder="{{inputPlaceholder}}" #typeaheadVar="ngModel" [required]="inputRequired"
      [disabled]="inputDisabled" [(ngModel)]="model" [ngbTypeahead]="typeaheadSliceFunction" [resultTemplate]="rt" [resultFormatter]="resultFormatter" 
      [inputFormatter]="inputFormatter" (selectItem)="addValue($event)" (blur)="validateField()" typeahead-loading="isLoading"  (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)" [popupClass]="'typeahead-popup'" #instance="ngbTypeahead">

  <button *ngIf="buttonAppend" type="button" class="btn btn-primary" (click)="buttonAppendClickEvent()"  [disabled]="buttonAppendDisabled">
    <fa-icon [icon]="buttonAppendType"></fa-icon>
  </button>

  <div class="spinner-border input-spinner" [style.left.px]="typeaheadInput.offsetWidth-30" *ngIf="inputLoadingIndicator">
    <span class="sr-only">{{ 'label.typeahead.loading' | translate }}</span>
  </div>

  <div class="clear-selected-value" [style.left.px]="typeaheadInput.offsetWidth-23" *ngIf="showClearSelectedValue()">
    <fa-icon id="{{ typeaheadId }}-clear-selected-value-fa-icon" icon="x" (click)="clearSelectedModel()"></fa-icon>
  </div>

</div>

<ng-template #rt let-r="result" let-t="term">
  <div #popupElement>
    <div *ngIf="init()"></div>
  </div>
  <div *ngIf="!!resultTemplate">
    <div *ngIf="!searchFailed"><ng-container *ngTemplateOutlet="resultTemplate; context:{'result':r, 'term':t}"></ng-container></div>
  </div>
  <div *ngIf="!resultTemplate">
    <ngb-highlight *ngIf="!searchFailed" [result]="resultFormatter(r)" [term]="t?.trim().split(' ')"></ngb-highlight>
  </div>
  <button *ngIf="searchFailed" class="d-button " disabled>{{ 'label.typeahead.no_record_found' | translate }}</button>
</ng-template>
