import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { OAuthAuthenticationService } from './oauth-authentication.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { concat, concatAll, concatMap, lastValueFrom, map, merge, mergeAll, of, tap, Observable } from 'rxjs';
import { AuthenticationStatusService } from './authentication-status.service';

@Injectable()
export class OAuthenticationInterceptor implements HttpInterceptor {

  constructor(private oauthAuthenticationService: OAuthAuthenticationService,
    private authenticationStatusService: AuthenticationStatusService,
    private oidcSecurityService: OidcSecurityService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let result: Observable<HttpEvent<any>>;

    if (!this.oauthAuthenticationService.isOAuthentication(request.url)) {
      if (localStorage.getItem("debug") == "true") {
        console.log(request);
      }
      result = next.handle(request);
    }

    else if (this.oauthAuthenticationService.isOAuthenticationMetaDataRequest(request.url)) {
      const requestToSend: HttpRequest<any> = request.clone({ withCredentials: false });
      if (localStorage.getItem("debug") == "true") {
        console.log(requestToSend);
      }
      result = next.handle(requestToSend);
    }

    else if (this.authenticationStatusService.isAuthenticationStatusLoggedOut() || this.authenticationStatusService.isAuthenticationStatusLoggingOut()) {
      if (localStorage.getItem("debug") == "true") {
        console.log(request);
      }
      result = next.handle(request);

    } else {
      result = this.oidcSecurityService.checkAuth().pipe(
        tap(loginResponse => this.oauthAuthenticationService.changeLogin(loginResponse)),
        map(loginResponse => loginResponse.idToken),
        map(idToken => request.clone({ headers: request.headers.append("Authorization", `Bearer ${idToken}`), withCredentials: false })),
        tap(r => {
          if (localStorage.getItem("debug") == "true") {
            console.log(r);
          }
        }),
        map(req => next.handle(req))
      ).pipe(concatAll())
    }

    return result;
  }



}