import { Component, Input } from '@angular/core';
import { AttributeAttributeParameter } from '../../../../model/attribute-attribute-parameter';

@Component({
  selector: 'typeahead-template-attribute-attribute-parameter',
  templateUrl: './attribute-attribute-parameter.html',
  styleUrls: ['./attribute-attribute-parameter.less']
})
export class TypeaheadAttributeAttributeParameterTemplate {

  @Input()
  public model: AttributeAttributeParameter;

  @Input()
  public term: string = null;
}
