import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MaterialSearchService } from '../../../service/material-search.service';
import { ClipboardService } from '../../../service/clipboard.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-material-overview',
  templateUrl: './material-overview.component.html',
  styleUrls: ['./material-overview.component.less']
})
export class MaterialOverviewComponent { 

  constructor(
    private readonly materialSearchService: MaterialSearchService,
    private readonly clipboardService: ClipboardService,
    private readonly router: Router) {
  }

  public copySearchFunction: () => void = () => this.clipboardService.copy(window.location.origin + "/#" + this.router.url);

  public get processingDownloadSearch(): BehaviorSubject<boolean> {
    return this.materialSearchService.processingDownloadSearch;
  }

  public downloadExcelResult(){
    this.materialSearchService.downloadExcelResult();
  }

}
