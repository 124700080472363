import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomView } from '../model/custom-view/custom-view';
import { TemplateView } from '../model/custom-view/template-view';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { cloneDeep, isEqual, omit, orderBy } from 'lodash';
import { CategoryAttribute } from '../model/category-attribute';
import { map } from 'rxjs/operators';
import { BackendValidationService } from './form-validation/backend-validation.service';
import { TemplateViewList } from '../model/custom-view/template-view-list/template-view-list';
import { Constants } from '../constants/constants';
import { LocalizedStringService } from './localized-string.service';

@Injectable({
  providedIn: 'root',
})
export class CustomViewService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly backendValidationService: BackendValidationService,
    private localizedStringService: LocalizedStringService
  ) {
  }

  public load(id: string): Observable<CustomView> {
    return this.httpClient.get<CustomView>(`${environment.restUrl}/customview/${id}`);
  }

  public delete(customView: CustomView) {
    return this.httpClient.post(environment.restUrl + '/customview/delete', customView);
  }

  public save(customViewSaveRequest: CustomView): Observable<CustomView> {
    return this.httpClient.post<CustomView>(environment.restUrl + '/customview/save', customViewSaveRequest)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public loadTemplateViewCategoryAttributes(templateViewId: string): Observable<CategoryAttribute[]> {
    return this.httpClient.get<CategoryAttribute[]>(`${environment.restUrl}/customviewcategoryattribute/loadTemplateViewCategoryAttributes/${templateViewId}`, Constants.httpOptionsHidingLoadingIndicator);
  }

  public isEqual(customViewOrig: CustomView, customView: CustomView): boolean {
    if (!!customViewOrig && !!customView) {
      return isEqual(this.removeCachedData(customViewOrig), this.removeCachedData(customView));
    }
    return false;
  }

  private removeCachedData(customView: CustomView): CustomView {
    const newCustomView = cloneDeep(customView);
    newCustomView.name = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(newCustomView.name);
    newCustomView.name?.forEach(localizedString => {
      localizedString.language.name = null;
    });
    if ('templateFileNames' in customView) {
      (newCustomView as TemplateView).templateFileNames?.forEach(localizedString => {
        localizedString.language.name = null;
      });
      // TemplateFileNames are sorted when show, so it could be different than the original one
      (newCustomView as TemplateView).templateFileNames?.sort((a, b) => (a.language.id < b.language.id) ? -1 : 1);
      // Content of Category Attribute should not be changed, but we found that some orders were changed, so do not compare the content
      (newCustomView as TemplateView).templateViewCategoryAttributesTemplateViewSpecific?.forEach(categoryAttribute => {
        categoryAttribute.category = null;  // Should not be modified
        categoryAttribute.attribute = null;  // Should not be modified
      });
      (newCustomView as TemplateView).categoryAttributeAttributeValues?.forEach(categoryAttributeAttributeValue => {
        categoryAttributeAttributeValue.categoryAttribute.category = null;
        categoryAttributeAttributeValue.categoryAttribute.attribute = null;
        categoryAttributeAttributeValue.attributeValues.forEach(attributeValue => {
          attributeValue.localizedString.forEach(localizedString => {
            localizedString.language.name = null;
          });
          attributeValue.attribute= null; // Should not be modified
        });
      });
    }
    return newCustomView;
  }

  public findParameternameByCategoryAttribute(categoryAttributeId: string): Observable<string> {
    return this.httpClient.get<{ id: string, value: string }>(`${environment.restUrl}/documentbridge/parametername/find/${categoryAttributeId}`, Constants.httpOptionsHidingLoadingIndicator)
      .pipe( map(parametername => parametername.value) );
  }

  public saveParameternameByCategoryAttribute(id, value: string): Observable<string> {
    return this.httpClient.post<{ id: string, value: string }>(environment.restUrl + '/documentbridge/parametername/createmapping', {
        categoryAttributeId: id,
        parameterName: value,
      })
      .pipe(map(parametername => parametername.value))
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public loadTemplateViewLists(id: string): Observable<TemplateViewList[]> {
    return this.httpClient.get<TemplateViewList[]>(`${environment.restUrl}/customview/templateviewlist/${id}`, Constants.httpOptionsHidingLoadingIndicator)
      .pipe(this.backendValidationService.renderErrorMessages());
  }
}
