<div class="parameter">
  <span class="small-order-number">{{ model.order }}.</span>
  <span class="parameter-name">
    <a [routerLink]="['/adminstration/attribute/parameter', model.attributeParameter.id]">{{ model.attributeParameter?.name | localizedStringsFilter }}
      <fa-icon icon="external-link-alt" class="fa-sg"></fa-icon>
    </a>
  </span>
  <span class="type">{{ model.attributeParameter?.type }}</span>
  <span class="unit" *ngIf="model.attributeParameter.unit"><fa-icon icon="scale-balanced" ngbTooltip="{{ 'label.unit' | translate}}"></fa-icon> {{ model.attributeParameter?.unit?.symbol }}</span>
</div>



