import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GenericMassMaintenanceModal } from "./modal/generic/generic-mass-maintenance.component";
import { MassMaintenanceModalConfig } from "../../../model/config/mass-maintenance-modal.config";

@Component({
  selector: 'mass-maintenance',
  templateUrl: './mass-maintenance.component.html',
  styleUrls: ['./mass-maintenance.component.less']
})
export class MassMaintenanceComponent {

  private sectionVisibilityStatus: string[] = ["templateDownloadDisabled", "exportDisabled", "importDisabled"];

  constructor(private modalService: NgbModal) {}

  private getPropertyValuesFromConfig(config: MassMaintenanceModalConfig) {
    const activatedSections = [];
    this.sectionVisibilityStatus.forEach((key: string) => {
      activatedSections.push(config[key]);
    })
    return activatedSections;
  }

  private calculateModalSize(config: MassMaintenanceModalConfig): string {
    const modalSizes = ["sm", "md", "lg", "xl"];
    const sections = this.getPropertyValuesFromConfig(config);
    const visibleSections = sections.length - sections.filter(Boolean).length;
    return modalSizes[visibleSections];
  }

  private calculateVisibleSections(config: MassMaintenanceModalConfig): number {
    const sections = this.getPropertyValuesFromConfig(config);
    return sections.length - sections.filter(Boolean).length;
  }

  public openUpdateCategoryMaterial() {

    const config = new MassMaintenanceModalConfig({
      modalTitle: 'massmaintenance.modal.materials_categories.download.title',
      templateDownloadLowerText: 'massmaintenance.modal.materials_categories.download.lower_text',
      templateDownloadUrlPath: '/mass-maintenance/v2/template/category-material/download',
      exportUpperText: 'massmaintenance.modal.materials_categories.export.upper_text',
      exportLowerText: 'massmaintenance.modal.materials_categories.export.lower_text',
      exportUrlPath: '/mass-maintenance/v2/category-material/export',
      importUpperText: 'massmaintenance.modal.materials_categories.import.upper_text',
      importUrlPath: '/mass-maintenance/v2/category-material/import'
    });

    const modalRef = this.modalService.open(GenericMassMaintenanceModal, {size: this.calculateModalSize(config), backdrop: 'static'});
    modalRef.componentInstance.config = config;
    modalRef.componentInstance.visibleModalSections = this.calculateVisibleSections(config);
  }

  openAttributeValueMaterial() {

    const config = new MassMaintenanceModalConfig({
      modalTitle: 'massmaintenance.modal.materials_attribute_values.download.title',
      templateDownloadLowerText: 'massmaintenance.modal.materials_attribute_values.download.lower_text',
      templateDownloadUrlPath: '/mass-maintenance/v2/template/attribute-value/download',
      exportUpperText: 'massmaintenance.modal.materials_attribute_values.export.upper_text',
      exportLowerText: 'massmaintenance.modal.materials_attribute_values.export.lower_text',
      exportUrlPath: '/mass-maintenance/v2/attribute-value/export',
      importUpperText: 'massmaintenance.modal.materials_attribute_values.import.upper_text',
      importUrlPath: '/mass-maintenance/v2/attribute-value/import'
    });

    const modalRef = this.modalService.open(GenericMassMaintenanceModal, {size: this.calculateModalSize(config), backdrop: 'static'});
    modalRef.componentInstance.config = config;
    modalRef.componentInstance.visibleModalSections = this.calculateVisibleSections(config);
  }

  openUpdateDocumentAssignments() {

    const config = new MassMaintenanceModalConfig({
      modalTitle: 'massmaintenance.modal.documents_relations.download.title',
      templateDownloadLowerText: 'massmaintenance.modal.documents_relations.download.lower_text',
      templateDownloadUrlPath: '/mass-maintenance/v2/template/document-assignments/download',
      templateDownloadDisabled: true,
      exportUpperText: 'massmaintenance.modal.documents_relations.export.upper_text',
      exportLowerText: 'massmaintenance.modal.documents_relations.export.lower_text',
      exportUrlPath: '/mass-maintenance/v2/document-assignments/export',
      importUpperText: 'massmaintenance.modal.documents_relations.import.upper_text',

      importUrlPath: '/mass-maintenance/v2/document-assignments/import'
    });

    const modalRef = this.modalService.open(GenericMassMaintenanceModal, {size: this.calculateModalSize(config), backdrop: 'static'});
    modalRef.componentInstance.config = config;
    modalRef.componentInstance.visibleModalSections = this.calculateVisibleSections(config);
  }

  openUpdateDocumentContainers() {

    const config = new MassMaintenanceModalConfig({
      modalTitle: 'massmaintenance.modal.documents_containers.download.title',
      templateDownloadLowerText: 'massmaintenance.modal.documents_containers.download.lower_text',
      templateDownloadUrlPath: '',
      templateDownloadDisabled: true,
      exportUpperText: 'massmaintenance.modal.documents_containers.export.upper_text',
      exportLowerText: 'massmaintenance.modal.documents_containers.export.lower_text',
      exportDisabled: true,
      importUpperText: 'massmaintenance.modal.documents_containers.import.upper_text',
      importButton: 'massmaintenance.modal.documents_containers.import.button',
      importFileInputs: [
        { field: 'file', label:  'massmaintenance.modal.document_containers.input.label.file', mandatory: true },
        { field: 'docBinaries', label: 'massmaintenance.modal.document_containers.input.label.doc_binaries', mandatory: false }
      ],
      importUrlPath: '/mass-maintenance/v2/document-container/import'
    });

    const modalRef = this.modalService.open(GenericMassMaintenanceModal, {size: this.calculateModalSize(config), backdrop: 'static'});
    modalRef.componentInstance.config = config;
    modalRef.componentInstance.visibleModalSections = this.calculateVisibleSections(config);
  }

}
