import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './service/authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from './service/user.service';
import { NavigateService } from './service/navigate.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { OAuthAuthenticationService } from './service/authentication/oauth-authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  public isLoggedIn: Observable<boolean>;
  public isMultiLanguageOn: boolean;
  public redirectUrl: string;
  public hasPimRoles: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private oauthAuthenticationService: OAuthAuthenticationService,
    private userService: UserService,
    private navigateService: NavigateService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use(translate.getBrowserLang());
    navigateService.initialize(router);
  }

  ngOnInit(): void {
    this.oauthAuthenticationService.init();

    this.userService.getCurrentUserRoles().subscribe(roleNames => {
      this.isMultiLanguageOn = false;
      const totalRoles = roleNames.length;
      if (totalRoles === 0 || !roleNames.some(roleName => roleName !== 'AUTHENTICATED')) {
        this.hasPimRoles = of(false);
      } else {
        this.hasPimRoles = of(true);
      }
      for (let i = 0; i < totalRoles; i++) {
        if (roleNames[i] === 'MULTI_LANGUAGE') {
          this.isMultiLanguageOn = true;
        }
      }
    });

    this.isLoggedIn = this.authenticationService.isLoggedIn().pipe(share());
    this.isLoggedIn.subscribe(loggedIn => {
      if (loggedIn && this.isMultiLanguageOn) {
        this.userService.getCurrentUserLanguageIsocode().subscribe(language => {
          this.translate.use(language);
        });
      } else {
        this.setDefaultLanguage();
      }
    });
  }

  private setDefaultLanguage() {
    this.userService.getCurrentUserLanguageIsocode().subscribe(language => {
      if (language !== 'en') {
        this.userService.saveCurrentUserLanguageByIsoCode('en').subscribe(
          user => {
            this.translate.use(user.language.isoCode);
          },
          err => {
            console.log('Error occured: ' + err);
          }
        );
      }
    });
  }

}
