import { Injectable } from '@angular/core';

@Injectable()
export class EnumService {
    public isValue(enumeration: any, enumerationValue: any, value2Check: any): boolean {
        if (value2Check.toString() === enumeration[enumerationValue].toString()) {
            return true;
        }
        if (value2Check === enumeration[enumerationValue]) {
            return true;
        }
        if (value2Check === enumerationValue) {
            return true;
        }
        return false;
    }

    public getValuesForEnum(enumeration: any) {
        return Object.keys(enumeration);
    }
}
