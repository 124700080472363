import { FacetMapEntry } from "./facet-map-entry";
import { HighlightedMapEntry } from "./highlighted-map-entry";
import { Page } from "./page";

export class FacetPage<T> extends Page<T> {
  
  public facets: Map<string, FacetMapEntry[]>;
  public highlights: Map<string, HighlightedMapEntry[]>;

  public constructor();
  public constructor(params: Partial<FacetPage<T>> | Partial<Page<T>>);

  public constructor(...arr: any[]) {
    super();
    if(arr.length === 1) {
      Object.assign(this, arr[0]);
    }
  }
}