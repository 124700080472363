import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {cloneDeep} from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {Language} from '../../../model/language';
import {Page} from '../../../model/pim-response/page';
import {Pageable} from '../../../model/pageable';
import {LocalizedString} from '../../../model/localized-string';
import {NotificationService} from '../../../service/notification.service';
import {LanguageService} from '../../../service/language.service';
import {LocalizedStringService} from '../../../service/localized-string.service';
import {ConfirmService} from '../../../service/confirm.service';
import {HasUnsavedChangesGuard} from '../../../guards/has-unsaved-changes.guard';

@Component({
  selector: 'language-administration',
  templateUrl: './language-administration.component.html'
})
export class LanguageAdministrationComponent implements OnInit, HasUnsavedChangesGuard {

  private languageOrig: Language = null;
  private pageable: Pageable = new Pageable();

  public language: Language = new Language();
  public languages: Page<Language>;
  public searchString: string = '';
  public edit: boolean = false;

  public getLocalizedStringsValue = (localizedStrings: LocalizedString[], showNameNotFound: boolean = false) =>
      this.localizedStringService.getLocalizedStringsValue(localizedStrings, showNameNotFound);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private localizedStringService: LocalizedStringService,
    private notificationService: NotificationService,
    private confirmService: ConfirmService
  ) {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.languageService.load(params.id).subscribe((language: Language) => {
          this.language = language;
          this.languageOrig = cloneDeep(this.language);
          this.edit = true;
        });
      }
    })
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('page')) {
      this.pageable.page = Number(this.route.snapshot.queryParamMap.get('page'));
    }
    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchString = this.route.snapshot.queryParamMap.get('search');
    }
    this.loadData();
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.languageChanges()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public languageChanges(): boolean {
    if (this.edit && !this.languageService.isEqual(this.languageOrig, this.language)) {
      return true;
    } else {
      return false;
    }
  }

  public refresh() {
    this.router.navigate([], { queryParams: { search: this.searchString, page: this.pageable.page } });
    this.loadData();
  }

  private loadData() {
    this.languageService.search(this.searchString, this.pageable).subscribe((page: Page<Language>) => {
      this.languages = page;
    });
  }

  public switchPage(pageable: Pageable) {
    this.pageable = pageable;
    this.refresh();
  }

  public search() {
    this.pageable = new Pageable();
    this.refresh();
  }

  public editItem(language: Language) {
    this.router.navigateByUrl('adminstration/language/' + language.id);
  }

  public newLanguage() {
    this.language = new Language();
    this.edit = true;
  }

  public save() {
    this.languageService.save(this.language).subscribe((language: Language) => {
      this.notificationService.addSuccessNotification('label.successfully.saved');
      this.language = language;
      this.languageOrig = cloneDeep(this.language);
      this.editItem(this.language);
    });
  }

  public cancel() {
    if (!this.language.id) {
      if (!this.languageService.isEqual(this.languageOrig, this.language)) {
        this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no').then(confirmed => {
          if (confirmed) {
            this.edit = false;
          }
        });
      } else {
        this.edit = false;
      }
    }
    this.router.navigateByUrl('adminstration/language');
  }
}
