import { LocalizedString } from './localized-string';

export class Language {
    id: string;
    isoCode: string;
    name: LocalizedString[] = [];

    toUrlString(): string {
      return 'lang=' + this.isoCode;
    }
}