import { AppModule } from "../app.module";
import { AuthenticationService } from "../service/authentication/authentication.service";

export default function InjectIsReadonlyUser(target: any, propertyName: string) {

  const NG_ON_ONIT_NAME = 'ngOnInit';
  const ngOnInitClone: Function | null =  target[NG_ON_ONIT_NAME];

  if (!ngOnInitClone) {
    throw new Error(`The class '${target.prototype.constructor.name}' using the '@InjectIsReadonlyUser' decorator must implement angular onInit.`);
  }


  Object.defineProperty(target, NG_ON_ONIT_NAME, {
    value: function () {

      const service = AppModule.injector.get<AuthenticationService>(AuthenticationService);

      this[propertyName] = service.isReadOnlyUser();

      if (ngOnInitClone) {
        ngOnInitClone.call(this);
      }
    }
  });

}
