import { Component, Input } from '@angular/core';
import { CategoryTreeviewService } from '../../../../../service/treeview/category-treeview.service';
import { Category } from '../../../../../model/category';
import * as _ from 'lodash';

@Component({
  selector: 'app-category-line',
  templateUrl: './category-line.component.html',
  styleUrls: ['./category-line.component.less']
})
export class CategoryLineComponent {

  @Input() 
  public model: Category;

  constructor(
    private readonly categoryTreeViewService: CategoryTreeviewService
  ) { }

  getDocumentType() {
    return this.model.type.toString();
  }

  getSearchString() {
    return this.categoryTreeViewService.getSearchString();
  }

}
