import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Pageable } from '../../../../model/pageable';
import { Attribute } from '../../../../model/attribute';
import { AttributeRelevance } from '../../../../model/attribute-relevance';
import { CategoryAttribute } from '../../../../model/category-attribute';
import { CategoryMaterial } from '../../../../model/category-material';
import { CustomView } from '../../../../model/custom-view/custom-view';
import { CustomViewCategoryAttribute } from '../../../../model/custom-view/custom-view-category-attribute';
import { CustomViewMapTypeaheadService } from '../../../../service/typeahead/custom-view-map-typeahead.service';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';

@Component({
  selector: 'app-tab-views',
  templateUrl: './tab-views.component.html',
  styleUrls: ['./tab-views.component.less']
})
export class MaterialTabViewsComponent implements AfterContentInit, OnInit {

  public loading: boolean = false;
  public customViewMap: Map<string, CustomView> = new Map();
  public customViewCategoryAttributesMap: Map<string, CategoryAttribute[]> = new Map();
  public attributesToBeShownByCategoryRelation: [CategoryMaterial, Attribute][] = [];
  
  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public showTypeahead = true;

  @Input()
  public areAttributeValuesMandatory = false;

  @Input()
  public selectedCustomView: CustomView = null;

  @Input()
  public isReadonly = false;

  @Input()
  public preSortAttributesFunction: (categoryRelationAttributes: [CategoryMaterial, Attribute]) => number;

  @Input()
  public isDisabled = (categoryRelation: CategoryMaterial, attribute: Attribute): boolean => false;

  constructor(
    private readonly materialEditDataService: MaterialEditDataService,
    private readonly customViewMapTypeaheadService: CustomViewMapTypeaheadService
  ) { }

  ngAfterContentInit(): void {
    this.loading = true;

    this.materialEditDataService.customViewCategoryAttributesBehaviorSubject.asObservable().subscribe(customViewCategoryAttributes => {
      if (customViewCategoryAttributes?.length > 0) {
        this.calculateCustomViewAndCategoryAttributeMaps(customViewCategoryAttributes)
        this.calculateAttributeToBeShown();
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
  }

  public customViewTypeahead = (searchTerm: string, pageable: Pageable) => this.customViewMapTypeaheadService.typeaheadSearch(searchTerm, pageable, this.getCustomViews());
  public customViewTypeaheadFormatter = (input: any): string => this.customViewMapTypeaheadService.typeaheadFormatter(input);

  public getCustomViews(): CustomView[] {
    return Array.from(this.customViewMap.values());
  }

  public selectCustomView(view: CustomView) {
    this.selectedCustomView = view;
  }

  public calculateCustomViewAndCategoryAttributeMaps(customViewCategoryAttributes: CustomViewCategoryAttribute[]) {
    this.customViewCategoryAttributesMap.clear();
    this.customViewMap.clear();

    customViewCategoryAttributes.forEach(customViewCategoryAttribute => {
      const customView = customViewCategoryAttribute.customView;
      this.addCustomViewIfNotExists(customView);
      this.addCategoryAttributeIfNotExists(customView, customViewCategoryAttribute.categoryAttribute);
    });
  }

  private addCustomViewIfNotExists(customView: CustomView): void {
    if(!this.customViewMap.has(customView.id)) {
      this.customViewMap.set(customView.id, customView);
    }
  }

  private addCategoryAttributeIfNotExists(customView: CustomView, categoryAttribute: CategoryAttribute) {
    if(!this.customViewCategoryAttributesMap.has(customView.id)) {
      this.customViewCategoryAttributesMap.set(customView.id, []);
    }
    this.customViewCategoryAttributesMap.get(customView.id).push(categoryAttribute);
  }

  public calculateAttributeToBeShown(): void {
    this.attributesToBeShownByCategoryRelation = [];

    if (!!this.selectedCustomView) {
      const categoryAttributes: CategoryAttribute[] = this.customViewCategoryAttributesMap.get(this.selectedCustomView.id);

      this.materialEditDataService.catMats.forEach(categoryMaterial => {
        if(categoryAttributes?.some(categoryAttribute => categoryAttribute.category.id === categoryMaterial.category.id)) {

          const uniqAttributes: Attribute[] = _.uniqBy(this.attributesByCategoryMaterial(categoryAttributes, categoryMaterial), 'id');

          if(uniqAttributes.length > 0) {
            uniqAttributes.forEach(attribute => {
              this.attributesToBeShownByCategoryRelation.push([categoryMaterial, attribute]);
            });
          }
        }
      });
    }

  }

  private attributesByCategoryMaterial(categoryAttributes: CategoryAttribute[], categoryMaterial: CategoryMaterial): Attribute[] {
    return categoryAttributes
      .filter(categoryAttribute => this.categoryIdsAreEqual(categoryAttribute, categoryMaterial) && this.isAttributeRelevant(categoryAttribute))
      .map(categoryAttribute => this.exchangeAttributeFromCategoryMaterialResponse(categoryMaterial, categoryAttribute.attribute));
  }

  private exchangeAttributeFromCategoryMaterialResponse(categoryMaterial: CategoryMaterial, attribute: Attribute): Attribute {
    return categoryMaterial.category.categoryAttributes
      .find(categoryAttribute => categoryAttribute.attribute.id === attribute.id)
      .attribute;
  }

  private categoryIdsAreEqual(categoryAttribute: CategoryAttribute, categoryMaterial: CategoryMaterial): boolean {
    return categoryAttribute.category.id === categoryMaterial.category.id;
  }

  private isAttributeRelevant(categoryAttribute: CategoryAttribute): boolean {
     return categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.MATERIAL);
  }

}
