import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Required from '../../../decorator/required.decorator';
import { TreeViewNode } from '../../../model/tree-view/tree-view-node';

@Component({
  selector: 'app-tree-view-node',
  templateUrl: './tree-view-node.component.html',
  styleUrls: ['./tree-view-node.component.less']
})
export class TreeNodeComponent<T> implements OnInit {

  @Required @Input() 
  public node: TreeViewNode<T>;

  @Required @Input() 
  public treeViewLineTemplate: any;

  @Required @Input() 
  public formatNodeLabel: (model: T) => string;

  @Input() 
  public isNodeClickable: boolean;

  @Output() 
  public loadChildren: EventEmitter<any> = new EventEmitter<any>();

  @Output() 
  public nodeClick: EventEmitter<string> = new EventEmitter<string>();

  @Output() 
  public nodeNewTabClick: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    // Needed for @Required decorator
  }

  toggle() {
    if(this.hasChildren()){
      this.node.displayChildren = !this.node.displayChildren;
      if (this.node.displayChildren) {
        this.loadChildren.emit(this.node);
      }
    }
  }

  onLoadChildren(node: TreeViewNode<T>) {
    this.loadChildren.emit(node);
  }

  onNodeClick(id: string) {
    this.nodeClick.emit(id);
  }

  onNodeNewTabClick = (id: string) => {
    this.nodeNewTabClick.emit(id);
  }

  hasChildren(): boolean{
    return !!this.node.children;
  }

  hasShownChildren(): boolean{
    return (!!this.node.children && this.node.children.some((c) => !!c.display))
  }

  hasUnshownChildren(): boolean{
    return !!this.node.parentIdOfFurtherChildren;
  }
}
