import {Component} from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {CategoryProductHierarchyObject} from '../../../../model/category-product-hierarchy-object';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';

@Component({
  selector: 'product-hierarchy-object-tab-attributes.component',
  templateUrl: './product-hierarchy-object-tab-attributes.component.html'
})
export class ProductHierarchyObjectTabAttributesComponent {
  
  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  ngOnInit(): void {
  }

  public filterNonBasisCategoryPhos = (categoryPho: CategoryProductHierarchyObject): boolean =>
    categoryPho.category.id !== environment.basisCategoryId && categoryPho.category.id !== environment.spassCategoryId;

}
