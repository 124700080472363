import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { environment } from '../../environments/environment';
import { Pageable } from '../model/pageable';
import { AttributeParameter } from '../model/attribute-parameter';
import { Attribute } from '../model/attribute';


@Injectable({
  providedIn: 'root'
})
export class AttributeParameterUsageService {

  constructor(private httpClient: HttpClient) { }

  public findOverallUsageCount(attributeparameter: AttributeParameter): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.restUrl + '/attribute/parameter/usage/overall-usage/' + attributeparameter.id);
  }

  public findAssignedAttributes(attributeparameter: AttributeParameter, pageable: Pageable): Observable<Page<Attribute>> {
    return this.httpClient.get<Page<Attribute>>(environment.restUrl + '/attribute/parameter/usage/' + attributeparameter.id + '?' + pageable.toUrlString());
  }

}
