import { LocalizedString } from './localized-string';
import { Unit } from './unit';
import { Material } from './material';
import { Category } from './category';

export class FormulationStep {
    id: string;
    quantity: number;
    unit: Unit;
    order: number;
    instruction: LocalizedString[] = [];
    function: Category;
    material: Material;
}
