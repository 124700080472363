
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AttributeParameterUsageService } from '../../../../service/attributeparameter-usage.service';
import { AttributeParameter } from './../../../../model/attribute-parameter';
import { Attribute } from './../../../../model/attribute';
import { LocalizedStringService } from './../../../../service/localized-string.service';
import { LocalizedString } from '../../../../model/localized-string';
import { Page } from '../../../../model/pim-response/page';
import { Pageable } from './../../../../model/pageable';

@Component({
  selector: 'attributeparameter-usage-administration',
  templateUrl: './attributeparameter-usage-administration.component.html',
  styleUrls: ['./attributeparameter-usage-administration.component.less']
})
export class AttributeParameterUsageAdministrationComponent implements OnInit {

  @Input() public attributeParameter: AttributeParameter;

  public attributes: Page<Attribute> = new Page();
  public getLocalizedStringsValue: (value: LocalizedString[]) => string = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  constructor(
    private router: Router,
    private attributeParameterUsageService: AttributeParameterUsageService,
    private localizedStringService: LocalizedStringService
  ) { }

  ngOnInit() {
    this.switchAttributePage(new Pageable()); 
  }

  public switchAttributePage(pageable: Pageable) {
    this.attributeParameterUsageService.findAssignedAttributes(this.attributeParameter, pageable)
      .subscribe((attributes: Page<Attribute>) => this.attributes = attributes);
  }

  public navigateToAttribute(attribute: Attribute) {
    this.router.navigateByUrl('adminstration/attribute/' + attribute.id);
  }
}
