import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../service/report.service';
import { Pageable } from '../../../model/pageable';
import { Page } from '../../../model/pim-response/page';
import { Report } from '../../../model/report';

@Component({
  selector: 'report-administration',
  templateUrl: './report-administration.component.html',
  styleUrls: ['./report-administration.component.less']
})
export class ReportAdministrationComponent implements OnInit {

  public reportList: Page<Report> = new Page();

  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.switchReportPage(new Pageable()); 
  }

  public switchReportPage = (pageable: Pageable) => this.reportService.findAll(pageable).subscribe((reports: Page<Report>) => this.reportList = reports);

  public downloadReport = (report: Report): string => {
    return this.reportService.createDownloadUrl(report);
  }
}