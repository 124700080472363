<div *ngIf="(customViewSubject | async)?.type == CustomViewType.TEMPLATE_VIEW || (customViewSubject | async)?.type == CustomViewType.DYNAMIC_TEMPLATE_VIEW">
  <div class="row">
    <h6>{{ 'label.administration.customview.tab.document.defaults.properties' | translate }}</h6>

    <div class="form-group col-4">

      <typeahead
        [inputLabel]="'label.document.type' | translate"
        [inputName]="'document_type'"
        [inputRequired]="true"
        [inputDisabled]="false"
        [(model)]="customViewDocumentDefaults.documentType"
        [typeaheadFunction]="documentTypeTypeahead"
        [resultFormatter]="documentTypeTypeaheadFormatter"
        [inputFormatter]="documentTypeTypeaheadFormatter">
      </typeahead>

    </div>

    <div class="form-group col-4">
      <label for="security_classification">{{ 'label.document.security_classification' | translate }}</label>
      <select id="security_classification" class="form-select" #securityClassification="ngModel"
              [(ngModel)]="customViewDocumentDefaults.documentSecurityClass"
              [compareWith]='securityClassificationComparator' required name="document_security_class">
        <option *ngFor="let item of securityClassifications; let i = index" [ngValue]="item">{{item.name | localizedStringsFilter}}</option>
      </select>
    </div>

  </div>
  <h4>{{ 'label.document.relation.region_assignment' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-region_assignment" class="col-12"
                             [idPrefix]="'multiple-value-selector-region_assignment'"
                             [(values)]="customViewDocumentDefaults.documentRegions"
                             [inputName]="'region_assignment'"
                             [typeahead]="regionTypeahead"
                             [resultTemplate]="categoryRelativesTemplate"
                             [valueTemplate]="categoryRelativesTemplate">
    </multiple-value-selector>

  </div>


  <h4>{{ 'label.document.relation.operating_devision' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-operating_division" class="col-12"
                             [idPrefix]="'multiple-value-selector-operating_division'"
                             [(values)]="customViewDocumentDefaults.documentOperatingDivisions"
                             [inputName]="'operating_division'"
                             [typeahead]="odTypeahead"
                             [resultFormatter]="odTypeaheadFormatter"
                             [valueFormatter]="odTypeaheadFormatter">
    </multiple-value-selector>

  </div>
</div>

<ng-template #categoryRelativesTemplate let-result="result" let-term="term">
  <typeahead-template-category-relatives [model]="result" [term]="term?.trim().split(' ')"></typeahead-template-category-relatives>
</ng-template>


