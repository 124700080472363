
import { Component, Input} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BehaviorSubject } from 'rxjs';
import { TemplateView } from '../../../../../model/custom-view/template-view';
import { TemplateViewListCategoryAttribute } from '../../../../../model/custom-view/template-view-list/template-view-list-category-attribute';
import { TemplateViewList } from '../../../../../model/custom-view/template-view-list/template-view-list';
import { Pageable } from '../../../../../model/pageable';
import { AttributeRelevance } from '../../../../../model/attribute-relevance';
import { CategoryAttribute } from '../../../../../model/category-attribute';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { TemplateViewListsMapTypeaheadService } from '../../../../../service/typeahead/template-view-lists-map-typeahead.service';
import { CategoryAttributeByRelevanceTypeaheadService } from '../../../../../service/typeahead/category-attribute-by-relevance.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-tab-template-view-lists-of-attributes',
  templateUrl: './custom-view-tab-template-view-lists-of-attributes.component.html',
  styleUrls: ['./custom-view-tab-template-view-lists-of-attributes.component.less']
})
export class CustomViewTabTemplateViewListsOfAttributesComponent {
  @Input()
  public selectedTemplateViewList: TemplateViewList = null;

  public get templateView(): BehaviorSubject<TemplateView> {
    return this.customViewEditDataService.customViewSubject as BehaviorSubject<TemplateView>;
  }

  public get templateViewListsSubject() {
    return this.customViewEditDataService.templateViewListsSubject;
  }

  constructor(
     private readonly customViewEditDataService: CustomViewEditDataService,
     private readonly categoryAttributeByRelevanceTypeaheadService: CategoryAttributeByRelevanceTypeaheadService,
     private readonly templateViewListsMapTypeaheadService: TemplateViewListsMapTypeaheadService
     ) { }


  public isCustomViewTypeTemplateView = (): boolean => this.customViewEditDataService.isTemplateView();

  public templateViewListsTypeahead = (searchTerm: string, pageable: Pageable) =>
            this.templateViewListsMapTypeaheadService.typeaheadSearch(searchTerm, pageable, this.templateViewListsSubject.value);
  public templateViewListsTypeaheadFormatter = (input: any): string => this.templateViewListsMapTypeaheadService.typeaheadFormatter(input);

  public categoryAttributeTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryAttributeByRelevanceTypeaheadService.typeaheadSearch(searchTerm, pageable,
      this.selectedTemplateViewList.templateViewListCategoryAttributes.map(tvlca => tvlca.categoryAttribute.id), [AttributeRelevance.MATERIAL]);

  public addTemplateViewListCategoryAttributes = (value: CategoryAttribute) => {
     let tvlca = new TemplateViewListCategoryAttribute(
      `${value.id}_${this.selectedTemplateViewList.id}`,
       value,
       Math.max(this.selectedTemplateViewList.templateViewListCategoryAttributes.length + 1, 1),
       true);

    return tvlca;
  }

  public reorderAfterAdd  = (templateViewListCategoryAttributeToBeAdded: any) => {
    templateViewListCategoryAttributeToBeAdded.defaultOrder = this.selectedTemplateViewList.templateViewListCategoryAttributes.length;
    templateViewListCategoryAttributeToBeAdded.defaultEnabled = true;
    this.recalculateDefaultOrderAndArrayOrder();
  }

  public recalculateTemplateViewListCategoryAttributesOrder = (valueSet: any[], obj: any, id: string) => {
    valueSet.forEach((step, index) => step.defaultOrder = index + 1);
  }

  public drop = (event: CdkDragDrop<TemplateViewListCategoryAttribute[]>) => {
    // To avoid corrupted order we manipulate the defaultOrder and then recalculate it
    // If the item is moved up, we add 0.5 to the defaultOrder, if it is moved down, we add 1.5 to assure that the order is always correct
    this.selectedTemplateViewList.templateViewListCategoryAttributes[event.previousIndex].defaultOrder =
      event.currentIndex + (event.previousIndex > event.currentIndex ? 0.5 : 1.5);

    this.recalculateDefaultOrderAndArrayOrder();
  }

  public onEnableChange = (model: TemplateViewListCategoryAttribute) => {
    this.recalculateDefaultOrderAndArrayOrder();
  }

  private recalculateDefaultOrderAndArrayOrder() {
    const orderedArray = _.orderBy(this.selectedTemplateViewList.templateViewListCategoryAttributes, ['defaultEnabled', 'defaultOrder'], ['desc', 'asc'])
    orderedArray.forEach((step, index) => step.defaultOrder = index + 1);

    // refilling the array without overwriting the reference
    this.selectedTemplateViewList.templateViewListCategoryAttributes
      .splice(0, this.selectedTemplateViewList.templateViewListCategoryAttributes.length, ...orderedArray);
  }

}
