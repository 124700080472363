export enum AttributeType {
  STRING = 'STRING',
  LOCALIZED_STRING = 'LOCALIZED_STRING',
  NUMBER = 'NUMBER',
  BOOL = 'BOOL',
  STRING_LIST = 'STRING_LIST',
  STAR_RATING = 'STAR_RATING',
  ENUMERATION = 'ENUMERATION',
  ENUMERATION_MULTIPLE = 'ENUMERATION_MULTIPLE',
  RELATIONSHIP = 'RELATIONSHIP',
  CUSTOM = 'CUSTOM',
  RANGE = 'RANGE',
  LOCALIZED_STRING_LIST = 'LOCALIZED_STRING_LIST',
  LOCALIZED_ENUMERATION = 'LOCALIZED_ENUMERATION',
  LOCALIZED_ENUMERATION_MULTIPLE = 'LOCALIZED_ENUMERATION_MULTIPLE'
}