<div *ngIf="!!attributeValue">
  <input type="radio"
    [name]="'radio_' + identifier"
    [id]="'radio_true_' + identifier"
    [(ngModel)]="attributeValue.bool"
    [disabled]="readonly"
    [value]="true"
    [checked]="attributeValue.bool === true">
  <label class="option-label" [for]="'radio_true_' + identifier">{{ 'radio.true' | translate}}</label>
  <input type="radio"
    [name]="'radio_' + identifier"
    [disabled]="readonly"
    [id]="'radio_false_' + identifier"
    [(ngModel)]="attributeValue.bool"
    [value]="false"
    [checked]="attributeValue.bool === false">
  <label class="option-label" [for]="'radio_false_' + identifier">{{ 'radio.false' | translate}}</label>
</div>
