import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AttributeRelevance } from '../model/attribute-relevance';
import { Category } from '../model/category';
import { CategoryFormulation } from '../model/category-formulation';
import { Formulation } from '../model/formulation';
import { Attribute } from '../model/attribute';
import { AttributeValueService } from './attribute-value.service';
import { map } from 'rxjs/operators';
import { AttributeValue } from '../model/attribute-value';
import { isEqual, orderBy } from 'lodash';
import { CategoryAttribute } from '../model/category-attribute';

@Injectable({
  providedIn: 'root'
})
export class CategoryFormulationService {

  constructor(
    private httpClient: HttpClient,
    private attributeValueService: AttributeValueService
  ) { }

  public save(id: string, categoryFormulations: CategoryFormulation[]): Observable<CategoryFormulation[]> {
    return this.httpClient.post<CategoryFormulation[]>(environment.restUrl + '/categoryformulation/save/' + id, categoryFormulations);
  }

  public loadByFormulationIdAndCreateAttributeValuesIfNotExist(id: string): Observable<CategoryFormulation[]> {
    return this.loadByFormulationId(id).pipe(map((categoryFormulations: CategoryFormulation[]) => {
      categoryFormulations.forEach((categoryFormulation: CategoryFormulation) => {
        this.createMissingAttributeValues(categoryFormulation);
      });
      return categoryFormulations;
    }));
  }

  private loadByFormulationId(id: string): Observable<CategoryFormulation[]> {
    return this.httpClient.get<CategoryFormulation[]>(environment.restUrl + '/categoryformulation/' + id);
  }

  public createAndAddCategoryFormulationsWithAttributeValues(formulation: Formulation, category: Category, categoryFormulations: CategoryFormulation[]) {
    if (!this.categoryFormulationExistsForCategory(categoryFormulations, category)) {
      categoryFormulations.push(this.createCategoryFormulationsWithAttributeValues(formulation, category));
    }
    if (category.parent) {
      this.createAndAddCategoryFormulationsWithAttributeValues(formulation, category.parent, categoryFormulations);
    }
  }

  private createMissingAttributeValues(categoryFormulation: CategoryFormulation) {
    categoryFormulation.category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
      if (!this.attributeValueExist(categoryFormulation, categoryAttribute.attribute)
        && categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.FORMULATION)) {
        categoryFormulation.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
      }
    }
    );
  }

  private attributeValueExist(categoryFormulation: CategoryFormulation, attribute: Attribute): boolean {
    return !!categoryFormulation.attributeValues.find((attributeValue: AttributeValue) => attributeValue.attribute.id === attribute.id);
  }

  private createCategoryFormulationsWithAttributeValues(formulation: Formulation, category: Category): CategoryFormulation {
    const categoryFormulation = new CategoryFormulation();
    categoryFormulation.formulation = formulation;
    categoryFormulation.category = category;
    category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
      if (categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.FORMULATION)) {
        categoryFormulation.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
      }
    });
    return categoryFormulation;
  }

  private categoryFormulationExistsForCategory(categoryFormulations: CategoryFormulation[], category: Category): boolean {
    for (let i = 0, max = categoryFormulations.length; i < max; i++) {
      if (categoryFormulations[i].category.id === category.id) {
        return true;
      }
    }
    return false;
  }

  private getOrderedCategoryFormulationsWithAttributes(categoryFormulations: CategoryFormulation[]): any[] {
    return orderBy(categoryFormulations.map(categoryFormulation => {
      let attributeValues = this.attributeValueService.getOrderedAttributeValues(categoryFormulation.attributeValues);
      return {category: categoryFormulation.category.id, formulation: categoryFormulation.formulation.id, attributeValues: attributeValues};
    }), ['category']);
  }

  public isEqual(categoryFormulationsOrig: CategoryFormulation[], categoryFormulations: CategoryFormulation[]): boolean {
    let categoryFormulationsOrigWithAttributes = this.getOrderedCategoryFormulationsWithAttributes(categoryFormulationsOrig);
    let categoryFormulationsWithAttributes = this.getOrderedCategoryFormulationsWithAttributes(categoryFormulations);
    return isEqual(categoryFormulationsOrigWithAttributes, categoryFormulationsWithAttributes);
  }

}
