import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Pageable } from "../../model/pageable";
import { Slice } from "../../model/pim-response/slice";
import { LocalizedString } from '../../model/localized-string';
import { BaseTypeaheadService } from "./base-typeahead.service";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapTypeaheadService {

  private readonly delimiter: string = ';;;;';

  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) { }

    /** 
   * The totalListMap should contain key value pairs that should as a key contain the string that is searched in. 
   * The value is the model that the string is mapped to. 
   * To make sure that duplicated strings are mapped to the correct model you can add an identifier to the key-string via the defined delemeter. Every string after the delemeter is ignored by the search logic.
   * Example: delemeter:";;;;", models: {id: 123, name:"test"}, {id: 456, name:"test"} -> map:[ { key:"test;;;;123", value:{id: 123, name:"test"} }, { key:"test;;;;456", value:{id: 456, name:"test"} } ]
   */

  public createSearchedInSlice<T>(searchTerm: string, pageable: Pageable, totalListMap: Map<string, T> = new Map()): Observable<Slice<any>> {

    const totalListOfNameValues: string[] = _.sortBy(Array.from(totalListMap.keys()), "ASC")
    let results: T[] = this.searchInMapKeys<T>(searchTerm, totalListOfNameValues, totalListMap);

    results = _.chunk(results, pageable.size)[pageable.page];
    
    return of(new Slice(results, results?.length > 0, results?.length >= pageable.size, results?.length - 1));
  }

  private searchInMapKeys<T>(searchTerm: string, totalListOfNameValues: string[], totalListMap: Map<string, T>) {

    let results: T[] = [];

    if (searchTerm.length == 0) {
      return totalListOfNameValues.map(string => totalListMap.get(string));
    }

    const escapedSearchTerm: string = _.escapeRegExp(searchTerm);
    const startsWithRegExp: RegExp = new RegExp('^' + _.toLower(escapedSearchTerm) + '.*' + this.delimiter + '.*$', 'i');
    const containsRegExp: RegExp[] = [];

    _.compact(escapedSearchTerm.split(' '))?.map(string => {
      containsRegExp.push(new RegExp(_.toLower(escapedSearchTerm) + '.*' + this.delimiter + '.*$', 'i'));
    });
    
    totalListOfNameValues.forEach(value => {
      if (startsWithRegExp.test(value) || containsRegExp.every(regex => regex.test(value))) {
        results.push(totalListMap.get(value));
      }
    });
    
    return results;
  }

  public getDelimiter(): string{
    return this.delimiter;
  }

}