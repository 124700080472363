<div class="filter" ngbDropdown>
  <div class="input-group">
    <select id="user-filter-dropdown" class="form-select" [ngModel]="userSelectedFilterBehaviourSubject | async"
            (ngModelChange)="onSelectedFilterChanged($event)" [disabled]="(userFiltersBehaviourSubject | async)?.length == 0">
     <option [id]="'user-filter-dropdownoption-' + userFilter.filterName" *ngFor="let userFilter of userFiltersBehaviourSubject | async"
             [ngValue]="userFilter" [ngClass]="{'selected': (userSelectedFilterBehaviourSubject | async)?.filterId == userFilter.filterId}">
       {{ userFilter.filterName }}<span *ngIf="!!userFilter.defaultFilter">&nbsp;&starf;</span>
      </option>
    </select>
    <button type="button" class="btn btn-primary" ngbDropdownToggle>
      <fa-icon [icon]="menuIcon"></fa-icon>
    </button>
  </div>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button ngbDropdownItem id="add-user-filter" (click)="addFilter()">
      {{ 'filter.button.add' | translate }}
    </button>
    <li><hr class="dropdown-divider"></li>
    <button ngbDropdownItem id="rename-user-filter dropdown-item" (click)="renameFilter()" [disabled]="disableRenameFilter()">
      {{ 'filter.button.rename' | translate }}
    </button>
    <button ngbDropdownItem id="make-default-user-filter" (click)="setAsDefaultFilter()" [disabled]="disableDefaultFilter()">
      {{ 'filter.button.default' | translate }}
    </button>
    <button ngbDropdownItem id="remove-user-filter dropdown-item" (click)="removeFilter()" [disabled]="disableRemoveFilter()">
      {{ 'filter.button.remove' | translate }}
    </button>
  </div>
</div>
