import { BusinessHierarchy } from "../business-hierarchy";
import { LocalizedString } from "../localized-string";
import { Material } from "../material";
import { MultipleValueSelectorElement } from "../multiple-value-selector/multiple-value-selector-element";
import { Highlighted } from "../overview-view/highlighted";
import { HighlightedMapEntry } from "../pim-response/highlighted-map-entry";
import { TreeNode } from "../pim-response/tree-node";
import { Realm } from "../realm/realm";
import { ProductHierarchyObjectType } from "./product-hierarchy-object-type";

export class ProductHierarchyObject implements Highlighted, TreeNode<ProductHierarchyObject>, MultipleValueSelectorElement {
    isReadOnly: boolean = false;
    id: string;
    internalName: string;
    marketingName: LocalizedString[] = [];
    synonyms: LocalizedString[] = [];
    type: ProductHierarchyObjectType;
    materials: Material[] = [];
    children: ProductHierarchyObject[] = [];
    highlights: HighlightedMapEntry[] = [];
    realm: Realm;
    owner: BusinessHierarchy;
    parent: ProductHierarchyObject;
}
