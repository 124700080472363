import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryAttribute } from '../../../../../model/category-attribute';
import { LocalizedString } from '../../../../../model/localized-string';
import { Pageable } from '../../../../../model/pageable';
import { AttributeRelevance } from '../../../../../model/attribute-relevance';
import { CategoryAttributeByRelevanceTypeaheadService } from '../../../../../service/typeahead/category-attribute-by-relevance.service';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { TemplateViewList } from '../../../../../model/custom-view/template-view-list/template-view-list';
import { Subscription } from 'rxjs';

@Component({
  selector: 'customview-tab-template',
  templateUrl: './customview-tab-template.component.html',
  styleUrls: ['./customview-tab-template.component.less'],
})
export class CustomViewTabTemplateComponent implements OnInit, OnDestroy {

  get customViewSubject() {
    return this.customViewEditDataService.customViewSubject;
  }

  get templateViewListsSubject() {
    return this.customViewEditDataService.templateViewListsSubject;
  }

  subscription: Subscription = new Subscription();
  templateViewListInputString: string = '';
  showParametername: boolean[] = [];

  constructor(
    private readonly customViewEditDataService: CustomViewEditDataService,
    private readonly categoryAttributeByRelevanceTypeaheadService: CategoryAttributeByRelevanceTypeaheadService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(this.templateViewListsSubject.subscribe({
      next: (templateViewLists) => {
        if (!!templateViewLists) {
          for (let i = 0; i < templateViewLists.length; i++) {
            this.showParametername[i] = false;
          }
        }
      },
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isCustomViewTypeTemplateView = (): boolean => this.customViewEditDataService.isTemplateView();

  public getTemplateFileNames(): LocalizedString[] {
    if (!this.customViewEditDataService.getTemplateView().templateFileNames) {
      this.customViewEditDataService.getTemplateView().templateFileNames = [];
    }
    return this.customViewEditDataService.getTemplateView().templateFileNames;
  }

  public getTemplateCategoryAttributesDocumentVersionSpecific(): CategoryAttribute[] {
    return this.customViewEditDataService.getTemplateView().templateViewCategoryAttributesDocumentVersionSpecific;
  }

  public getTemplateCategoryAttributesTemplateViewSpecific(): CategoryAttribute[] {
    return this.customViewEditDataService.getTemplateView().templateViewCategoryAttributesTemplateViewSpecific;
  }

  public attributesTypeaheadDocumentVersionSpecific = (searchTerm: string, pageable: Pageable) => this.categoryAttributeByRelevanceTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.customViewEditDataService.getTemplateViewCategoryAttributeDocumentVersionSpecificIdsToExclude(), [AttributeRelevance.TEMPLATE_SPECIFIC]);

  public attributesTypeaheadTemplateViewSpecific = (searchTerm: string, pageable: Pageable) => this.categoryAttributeByRelevanceTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.customViewEditDataService.getTemplateViewCategoryAttributeTemplateViewSpecificIdsToExclude(), [AttributeRelevance.TEMPLATE_SPECIFIC]);

  public addValueForTemplateView = (categoryAttribute: CategoryAttribute) => {
    this.customViewEditDataService.addTemplateViewCategoryAttributeTemplateViewSpecific(categoryAttribute);
  }

  public deleteValueForTemplateView = (categoryAttribute: CategoryAttribute) => {
    this.customViewEditDataService.deleteTemplateViewCategoryAttributeTemplateViewSpecific(categoryAttribute);
  }

  public addValueForDocumentVersion = (categoryAttribute: CategoryAttribute) => {
    this.customViewEditDataService.addTemplateViewCategoryAttributeDocumentVersionSpecific(categoryAttribute);
  }

  public deleteValueForDocumentVersion = (categoryAttribute: CategoryAttribute) => {
    this.customViewEditDataService.deleteTemplateViewCategoryAttributeDocumentVersionSpecific(categoryAttribute);
  }

  addValueForTemplateViewList = (input: string) => {
    if (input.trim() !== '') {
      const templateViewList = new TemplateViewList();
      templateViewList.name = input;
      this.customViewEditDataService.addTemplateViewList(templateViewList);
    }
    this.templateViewListInputString = '';
  }

  deleteValueFortemplateViewList = (templateViewList) => {
    this.customViewEditDataService.deleteTemplateViewList(templateViewList);
  }

}
