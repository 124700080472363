import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { Attribute } from '../model/attribute';
import { Category } from '../model/category';
import { environment } from '../../environments/environment';
import { Pageable } from '../model/pageable';
import { CategoryType } from '../model/category-type';

@Injectable({
  providedIn: 'root'
})
export class AttributeUsageService {

  constructor(private httpClient: HttpClient) { }

  public isAttributeInUse(attribute: Attribute): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.restUrl + '/attribute/usage/overall-usage/' + attribute.id);
  }

  public findCategoriesWithAssignedAttributeByType(categoryType: string, attribute: Attribute, pageable: Pageable): Observable<Page<Category>> {
    return this.httpClient.get<Page<Category>>(environment.restUrl + '/attribute/usage/' + attribute.id + '?categoryType=' + categoryType + '&' + pageable.toUrlString());
  }

}
