<ng-template #basicTooltipTemplate let-tooltip="tooltip">
  <span [innerHtml]="tooltip"></span>
</ng-template>
<div class="pimtooltip" [style.left]="left + 'px'" [style.top]="top + 'px'" *ngIf="!tooltipTemplate && !!tooltip">
  <ng-container *ngTemplateOutlet="basicTooltipTemplate, context: { 'tooltip': tooltip }"></ng-container>
</div>
<div class="pimtooltip" [style.left]="left + 'px'" [style.top]="top + 'px'" *ngIf="!!tooltipTemplate && !!tooltip">
  <ng-container *ngTemplateOutlet="tooltipTemplate, context: { 'tooltip': tooltip }"></ng-container>
</div>

