import {combineLatest, Observable} from "rxjs";
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../service/authentication/authentication.service";
import {map} from "rxjs/operators";

export const isAdvancedUserGuard: CanActivateFn  = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const authenticationService = inject(AuthenticationService);

  return combineLatest([authenticationService.isAdvanced(), authenticationService.isAdmin()])
    .pipe(map(results => {
      // results[0] isAdvanced
      // results[1] isAdmin
      return results[0] || results[1];
    }));
};

