import { Component } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import { Notification } from '../../model/notification';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.less']
})
export class NotificationComponent {

    constructor(public notificationService: NotificationService) {}

    getClassName(notification: Notification): string {
      switch (notification.type) {
        case 'info':
          return 'alert-primary';
        case 'success':
          return 'alert-success';
        case 'error':
          return 'alert-danger';
        case 'warning':
          return 'alert-warning';
      }
      return 'alert-secondary';
    }

    closeMessage(notification: Notification) {
      this.notificationService.removeNotification(notification);
    }
}
