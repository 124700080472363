<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal" *ngIf="attribute.id; else addHeadline">{{ 'headline.administration.maintainAttribute' | translate }}</h2>

  <ng-template #addHeadline>
    <h2 class="display-6 fw-normal">{{ 'headline.administration.addAttribute' | translate }}</h2>
  </ng-template>

  <form>
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div class="col-6 mb-3">
            <localized-string-edit id="attribute-administration-name" [localizedStrings]="attribute.name" englishRequired="true" [label]="'label.administration.name' | translate"></localized-string-edit>
          </div>
          <div class="col-6 mb-3">
            <localized-string-edit id="attribute-administration-shortname" [localizedStrings]="attribute.shortName" [label]="'label.administration.attribute.short_name' | translate"></localized-string-edit>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            <localized-string-edit id="attribute-administration-tooltip" [localizedStrings]="attribute.tooltip" [label]="'label.administration.attribute.tooltip' | translate"></localized-string-edit>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            <label for="descriptionAttribute">{{'label.administration.attribute.description' | translate}}</label>
            <textarea required="false" maxlength="{{ environment.localizedStringMaxLength }}" type="text" class="form-control" id="descriptionAttribute" name="description" [(ngModel)]="attribute.description"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-3 form-group">

            <typeahead
                [inputLabel]="'label.unit' | translate"
                [inputName]="'unit'"
                [inputDisabled]="disableUnit()"
                [(model)]="attribute.unit"
                [typeaheadFunction]="unitTypeahead"
                [resultFormatter]="unitTypeaheadFormatter"
                [inputFormatter]="unitTypeaheadFormatter">
            </typeahead>

          </div>
          <div class="col-6 mb-3 form-group typeahead-container">

            <typeahead
                [inputLabel]="'label.attributecluster' | translate"
                [inputName]="'attributeCluster'"
                [(model)]="attribute.attributeCluster"
                [typeaheadFunction]="clusterTypeahead"
                [resultFormatter]="clusterTypeaheadFormatter"
                [inputFormatter]="clusterTypeaheadFormatter">
            </typeahead>

          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3 form-group">
            <div class="mb-3">
              <label for="attributeType">{{ 'label.administration.attributeType' | translate }}</label>
              <select class="form-select" id="attributeType" required name="attributeType" [(ngModel)]="attribute.type" >
                <option *ngFor="let type of attributeTypes | enumToArray" value="{{type}}">{{ 'label.type.' + type | lowercase | camelCase | translate }}</option>
              </select>
            </div>


            <div *ngIf="attribute.type=='STAR_RATING'">
              <label for="attributeStarRange">{{ 'label.administration.attribute.star_range' | translate }}</label>
              <div class="slider">
                <input id="attributeStarRange" name="attributeStarRange" class="form-range"
                    numeric
                    numericType="number"
                    type="range"
                    min="1"
                    max="10"
                    [(ngModel)]="attributeRangeMax">
                <div class="range-value">{{ attributeRangeMax }}</div>
              </div>
            </div>
            <div *ngIf="attribute.type=='RELATIONSHIP'">
              <localized-string-edit id="attribute-administration-relationship" [localizedStrings]="attribute.relationshipTypeLabel" [label]="'label.relationship.type.label'|translate"></localized-string-edit>
              <enumeration-attribute-type-edit [label]="'label.enumeration.enumeration_values'|translate"
                [enumerationValues]="attribute.enumerationValues"
                (enumerationValuesChange)="enumerationValuesChange($event, attribute)">
              </enumeration-attribute-type-edit>
            </div>
            <div *ngIf="attribute.type=='ENUMERATION_MULTIPLE' || attribute.type=='ENUMERATION'">
              <single-choice-list-attribute-type-edit [label]="'label.single_choice_list_values' |translate"
                [singleChoiceListValues]="attribute.singleChoiceListValues"
                (singleChoiceListValuesChange)="singleChoiceListValueChange($event, attribute)">
              </single-choice-list-attribute-type-edit>
            </div>
            <div *ngIf="attribute.type=='LOCALIZED_ENUMERATION' || attribute.type=='LOCALIZED_ENUMERATION_MULTIPLE'">
              <localized-enumeration-attribute-type-edit [label]="'label.enumeration.localized_enumeration_values'|translate"
                [enumValues]="attribute.localizedEnumerationValues"
                (localizedEnumerationValuesChange)="localizedEnumerationValuesChange($event, attribute)">
              </localized-enumeration-attribute-type-edit>
            </div>
            <div *ngIf="attribute.type=='LOCALIZED_STRING' || attribute.type=='STRING'">
              <label for="attributeTextLengthSlider">{{ 'label.administration.attribute.textLength' | translate }}</label>
              <div class="slider" >
                <input id="attributeTextLengthSlider" name="attributeTextLength" class="form-range slider-text-length"
                    numeric
                    numericType="number"
                    type="range"
                    min="{{ 1 }}"
                    max="{{ environment.localizedStringMaxLength }}"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="attributeTextLength">
                <div>
                  <input  id="attributeTextLengthNumericInput" class="range-value-input form-control"
                    numeric
                    numericType="number"
                    required
                    maxAllowedNumber="{{ environment.localizedStringMaxLength }}"
                    minAllowedNumber="{{ 1 }}"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="attributeTextLength">
                </div>
              </div>
            </div>
            <div *ngIf="attribute.type=='RANGE' || attribute.type=='STRING'">
              <label for="attributeXpath">{{ 'label.administration.attribute.xpath' | translate }}</label>
              <input id="attributeXpath" type="text" name="attributeXpath" class="form-control" [(ngModel)]="attribute.xpath" >
            </div>
          </div>

          <div class="col-6 mb-3 form-group">
            <div class="form-group">
              <label id="label.administration.attributeRelevance" for="attributeType">{{ 'label.administration.attributeRelevance' | translate }}</label>
              <div *ngFor="let relevance of attributeRelevance | enumToArray;" class="form-check form-switch">
                <input id="{{ 'checkbox_' + relevance }}" class="form-check-input" type="checkbox" name="relevance" value="{{relevance}}" [checked]="isEnumSelected(relevance)" (change)="onChangeRelevance($event, relevance)">
                <label class="form-check-label" for="{{ 'checkbox_' + relevance }}">{{ 'label.administration.attribute.relevance_' + relevance | lowercase | camelCase | translate }}</label>
              </div>
            </div>
            <div>
              <label id="label.administration.default.visibility" for="{{ attribute?.id }}-visibility-select">{{ 'label.administration.default.visibility' | translate }}</label>
              <select
                id="{{ attribute?.id }}-visibility-select"
                name="defaultVisibility"
                class="form-select"
                (ngModelChange)="setDefaultVisibilityId($event)"
                [ngModel]="getDefaultVisibilityId()">
                <option
                value="">{{ "choose.visibility" | translate }}</option>
                <option
                *ngFor="let defaultVisibilityId of defaultVisibilityIds"
                  [value]="defaultVisibilityId" #visibilityOption>{{ idToDefaultVisibilityMapping.get(visibilityOption.value)?.name | localizedStringsFilter }}
                </option>
              </select>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="enableAttributeParameter()" class="col-4 mb-3 form-group">
          <multiple-value-selector
            id="administration-mvs-attribute-parameter"
            [(values)]="attribute.attributeParameters"
            [inputLabel]="'label.administration.attribute.attribute_parameter' | translate"
            [inputName]="'attribute-parameter'"
            [typeahead]="parameterTypeahead"
            [resultFormatter]="parameterTypeaheadFormatter"
            [valueTemplate]="attributeAttributeParameter"
            [executeBeforeAdd]="addAttributeParameter"
            [executeAfterDelete]="recalculateAttributeParametersOrder"
            [identifierField] = "'attributeParameter'"
            [activateSorting] = "true"
            [dropMethod]= "drop">
        </multiple-value-selector>
      </div>
    </div>

    <div class="row upper_space" *ngIf="attribute && attribute.id">
      <div class="col-12">
        <div >
          <button class="btn btn-secondary" [disabled]="!attributeInUse" (click)="showUsages=!showUsages">
            {{( !attributeInUse? 'button.administration.attribute.usage.nousagetoshow' :
                  !showUsages? 'button.administration.attribute.usage.show':'button.administration.attribute.usage.hide')|translate}}
          </button>
        </div>
        <div>
          <attribute-usage-administration *ngIf="showUsages" [attribute]="attribute"></attribute-usage-administration>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 align-right">
        <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
        <icon-button *ngIf="attribute.id" buttonType="save" (buttonClick)="save()"></icon-button>
        <icon-button *ngIf="!attribute.id" buttonType="add" (buttonClick)="save()"></icon-button>
      </div>
    </div>
  </form>
</div>

<ng-template #attributeAttributeParameter let-result="result">
  <typeahead-template-attribute-attribute-parameter [model]="result"></typeahead-template-attribute-attribute-parameter>
</ng-template>
