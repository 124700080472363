import { Component, DoCheck, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { TreeNode } from '../../../model/pim-response/tree-node';
import { TreeViewNode } from '../../../model/tree-view/tree-view-node';
import Required from '../../../decorator/required.decorator';
import { Identifiable } from '../../../model/tree-view/identifiable';
import { EditMenuTreeviewService } from '../../../service/edit-menu/edit-menu-treeview.service';
import { TreeViewNodeGroup } from '../../../model/tree-view/tree-view-node-group';
import { Filter } from '../../../model/filter';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.less']
})
export class TreeViewComponent<T extends Identifiable & TreeNode<T>, ServiceType extends EditMenuTreeviewService<T>> implements OnInit, DoCheck {

  private isCurrentNodeInitialized: boolean = false;
  private areRootNodesInitialized: boolean = false;
  private readonly nodeRegistry: Map<string, TreeViewNode<T>> = new Map();
  public renderedRootNodes: TreeViewNode<T>[] = [];
  public renderedRootNodeGroups: TreeViewNodeGroup<T>[] = [];
  public isGrouped: boolean = false;
  public checkableFilters: Map<string, Filter<T>[]> = new Map();

  @Required @Input() 
  public formatNodeLabel: (model: T) => string;

  @Required @Input() 
  public treeviewService: ServiceType;

  @Required @Input() 
  public showSearchbar: boolean;

  @Required @Input() 
  public showCurrentNodeOnly: boolean;

  @Required @Input() 
  public treeViewLineTemplate: TemplateRef<any>;

  @Input()
  public treeViewFacetTemplate: TemplateRef<any>;

  @Input() 
  public areNodesClickable:boolean;

  @Input() 
  public selectedModel: T;

  @Output() 
  public nodeClick: EventEmitter<string> = new EventEmitter<string>();

  @Output() 
  public nodeNewTabClick: EventEmitter<string> = new EventEmitter<string>();

  ngDoCheck(): void {
    this.initializeCurrentNode(this.selectedModel);
    // TODO: check if this.initializeRootNodes(); can be moved to another lifecycle hook
    this.initializeRootNodes();
  }

  ngOnInit() {
    this.isGrouped = !!this.treeviewService.groupFunction;
    this.initializeFilters();
  }

  onNodeClick(id: string) {
    this.nodeClick.emit(id);
  }

  onNodeNewTabClick(id: string) {
    this.nodeNewTabClick.emit(id);
  }

  private initializeRootNodes(): void {
    if (this.areRootNodesInitialized || this.showCurrentNodeOnly) {
      return;
    }

    this.loadRoots();
  }

  private initializeFilters(): void {
    this.treeviewService.loadFilters().subscribe(filters => {
      this.checkableFilters.clear();
      if (filters != null){
        Object.entries(filters).forEach(([key, value]) => {
          const filterArray = [];
          value.forEach(item => filterArray.push(new Filter(item)));
          this.checkableFilters.set(key, filterArray );
        });
      }
    });
  }

  public clearFilter(): void{
    this.checkableFilters.forEach((value, key) => {
      value.filter(item => item.checked).forEach(item => item.checked = false);
    });
  }

  public applyFilter(): void{
    let searchButton = document.getElementById('searchButton');
    searchButton.click();
  }

  public keepOriginalOrder = (a, b) => a.key;

  private initializeCurrentNode(selectedModel: T): void {
    if (!selectedModel) {
      return;
    }

    if (this.isCurrentNodeInitialized || !this.showCurrentNodeOnly) {
      return;
    }

    if (!selectedModel.id || !this.showCurrentNodeOnly) {
      return;
    }

    this.isCurrentNodeInitialized = true;
    this.treeviewService.loadCurrentNode(this.selectedModel).pipe(
      map((model: T) => this.toTreeViewNode(model, true, selectedModel.id))
    ).subscribe((rootForCurrentNode: TreeViewNode<T>) => {
      this.foldNonCurrentNodesNew(rootForCurrentNode, selectedModel.id);
      this.updateNodes([rootForCurrentNode]);
    });
  }

  private loadRoots() {
    this.areRootNodesInitialized = true;

    if (this.isGrouped) {
      this.treeviewService.loadRootNodes(this.getCheckedFilters()).pipe(
        map(model => this.toTreeViewNodeGroups(model, true))
      ).subscribe(roots => {
        this.updateNodeGroups(roots);
      });

    } else {
      this.treeviewService.loadRootNodes(this.getCheckedFilters()).pipe(
        map(model => this.toTreeViewNodes(model, true))
      ).subscribe(roots => {
        this.updateNodes(roots);
      });
    }

  }

  private toTreeViewNodeGroups(elements: T[], display: boolean): TreeViewNodeGroup<T>[] {
    return this.treeViewNodesToTreeViewNodeGroups(elements.map(v => this.toTreeViewNode(v, display)));
  }

  private toTreeViewNodes(elements: T[], display: boolean): TreeViewNode<T>[] {
    return elements.map(v => this.toTreeViewNode(v, display));
  }

  private treeViewNodesToTreeViewNodeGroups(treeViewNodes: TreeViewNode<T>[]): TreeViewNodeGroup<T>[] {
    const nodes: TreeViewNode<T>[] = _.orderBy(treeViewNodes, [
      (treeViewNode) => treeViewNode.groupId.toLowerCase(),
      (treeViewNode) => this.treeviewService.sortFunction(treeViewNode.model)
    ], ["asc", "asc"]);

    let oldGroupId: string = null;
    let currentNodeGroup: TreeViewNodeGroup<T> = null;
    const nodeGroups: TreeViewNodeGroup<T>[] = [];

    nodes.forEach(treeviewNode => {
      const currentGroupId: string = treeviewNode.groupId;
      if (oldGroupId !== currentGroupId) {
        currentNodeGroup = new TreeViewNodeGroup(currentGroupId, []);
        nodeGroups.push(currentNodeGroup);
        oldGroupId = currentGroupId;
      }
      currentNodeGroup.nodes.push(treeviewNode);
    });

    return nodeGroups;
  }

  public onSearchButtonClick(searchTokens: string[]): void {
    if (searchTokens.length === 0) {
      this.loadRoots();

    } else {

      if (this.isGrouped) {
        this.treeviewService.searchInTreeview(searchTokens.join(" "), this.getCheckedFilters())
          .pipe(map((searchResult: T[]) => this.toTreeViewNodeGroups(searchResult, true)))
          .subscribe(
            (foundNodes: TreeViewNodeGroup<T>[]) => {
              this.foldAllNodes();
              this.updateNodeGroups(foundNodes);
            }
          );

      } else {

        this.treeviewService.searchInTreeview(searchTokens.join(" "), this.getCheckedFilters())
          .pipe(map((searchResult: T[]) => this.toTreeViewNodes(searchResult, true)))
          .subscribe(
            (foundNodes: TreeViewNode<T>[]) => {
              this.foldAllNodes();
              this.updateNodes(foundNodes);
            }
          );
      }
    }
  }

  private getCheckedFilters(): Map<string, T[]> {
    let filteredMap = new Map();
    this.checkableFilters.forEach((value, key) => {
      const filterArray = [];
      value.filter(item => item.checked).forEach(item => filterArray.push(item.model));
      if (filterArray.length > 0){
        filteredMap.set(key, filterArray);
      }
    });
    return filteredMap;
  }

  private foldAllNodes(): void {
    Array.from(this.nodeRegistry.values())
      .forEach((treeviewNode: TreeViewNode<T>) => {
        treeviewNode.displayChildren = false;
        treeviewNode.display = false;
      });
  }

  private foldNonCurrentNodesNew(rootViewNode: TreeViewNode<T>, currentNodeId: string): void {
    let nodes: TreeViewNode<T>[] = [rootViewNode];
    let children: TreeViewNode<T>[] = [];
    let display: boolean = true;

    while (nodes.length > 0) {
      nodes.forEach(parent => {
        let isParentOfCurrentNode: boolean = false;

        if (!!parent.children) {
          isParentOfCurrentNode = parent.children.some((child: TreeViewNode<T>) => child.model.id === currentNodeId);

          if (parent.isCurrent) {
            parent.children.forEach((child: TreeViewNode<T>) => {
              child.isCurrent = true;
            });
          }
        }
        parent.isCurrent = parent.model.id === currentNodeId;

        if (isParentOfCurrentNode) {
          display = false;
        }
      });
      nodes = children;
      children = [];
    }
  }

  private updateVisibility(child: TreeViewNode<T>, currentNodeId: string, display: boolean, children: TreeViewNode<T>[], isParentOfCurrentNode: boolean) {
    if (child.model.id === currentNodeId) {
      child.isCurrent = true;
    }
    child.display = display || child.isCurrent;
    child.displayChildren = display || (child.isCurrent && !!child.children && child.children.length > 0);
    children.push(child);

    if (isParentOfCurrentNode) {
      child.displayChildren = child.isCurrent;
    }
  }

  public onLoadChildren(parent: TreeViewNode<T>) {
    if (!parent.children || parent.children.length === 0 || !!parent.parentIdOfFurtherChildren) {

      if (this.isGrouped) {
        this.loadGroupedChildren(this.treeviewService.loadChildNodes(parent.model), parent);
      } else {
        this.loadUngroupedChildren(this.treeviewService.loadChildNodes(parent.model), parent);
      }
      parent.parentIdOfFurtherChildren = null;
    }
  }

  private loadGroupedChildren(result: Observable<T[]>, parent: TreeViewNode<T>){
    result.pipe(map((model: T[]) => this.toTreeViewNodeGroups(model, true)))
    .subscribe((nodeGroups: TreeViewNodeGroup<T>[]) => {
      for (const nodeGroup of nodeGroups) {
        const children: TreeViewNode<T>[] = nodeGroup.nodes;
        this.updateChildNodes(parent, children);
      }
      this.updateNodes(parent.children);
    });
  }

  private loadUngroupedChildren(result: Observable<T[]>, parent: TreeViewNode<T>){
    result.pipe(map((model: T[]) => this.toTreeViewNodes(model, true)))
    .subscribe((children: TreeViewNode<T>[]) => {
      this.updateChildNodes(parent, children);
      this.updateNodes(children);
    });
  }

  private updateChildNodes(parent: TreeViewNode<T>, children: TreeViewNode<T>[]){
    children.forEach((child: TreeViewNode<T>) => {
      child.parent = parent;
      if(!parent.children.find(c => c.model.id === child.model.id)){
        parent.children.push(child);
      }
    });
    if (!!parent.children && parent.children.length > 0) {
      parent.displayChildren = true;
      parent.children.forEach(child => {
        child.display = true;
      });
    }
  }

  private updateNodes(treeNodes: TreeViewNode<T>[]) {
    for (const treeNode of treeNodes) {
      this.nodeRegistry.set(treeNode.model.id, treeNode);
    }

    this.renderedRootNodes = Array.from(this.nodeRegistry.values())
      .filter((treeviewNode: TreeViewNode<T>) => !treeviewNode.parent);
  }

  private updateNodeGroups(nodeGroups: TreeViewNodeGroup<T>[]) {
    this.renderedRootNodeGroups = nodeGroups;
    const nodes: TreeViewNode<T>[] = nodeGroups
      .map(nodeGroup => nodeGroup.nodes)
      .reduce((allNodes, currentNodes) => allNodes.concat(currentNodes), []);

    this.updateNodes(nodes)
  }

  private getGroupId(model: T): string {
    if (!this.treeviewService.groupFunction) {
      return null;
    }
    return this.treeviewService.groupFunction(model);
  }

  private toTreeViewNode(element: T, display: boolean, currentNodeId: string = null): TreeViewNode<T> {
    let treeviewNodeParent: TreeViewNode<T> = null;
    const parents: T[] = [element];
    const treeRegistry: Map<string, TreeViewNode<T>> = new Map()

    const groupId = this.getGroupId(element);

    treeviewNodeParent = new TreeViewNode<T>(
      element,
      element.children ? [] : null,
      null,
      display,
      !!element.children ? element.children.length > 0 : false,
      element.id === currentNodeId,
      groupId,
      !!element.parentIdOfFurtherChildren ? element.parentIdOfFurtherChildren : null
    );

    treeRegistry.set(element.id, treeviewNodeParent)

    this.buildDescendantsTree(parents, display, currentNodeId, treeRegistry);

    return treeviewNodeParent;
  }


  private buildDescendantsTree(parents: T[], display: boolean, currentNodeId: string, treeRegistry: Map<string, TreeViewNode<T>>) {
    while (!!parents && parents.length !== 0) {
      parents.forEach((parent: T) => {

        if (!!parent.children) {
          parent.children.forEach(child => {
            this.buildChildTreeViewNode(child, display, parent, currentNodeId, treeRegistry);
          });
        }
      });

      if (!!parents) {
        const tmpParents = [];
        parents.forEach(p => {
          if (!!p.children) {
            p.children.forEach(c => (!!c && tmpParents.push(c)));
          }
        });
        parents = tmpParents;
      }
    }
  }

  private buildChildTreeViewNode(child: T, display: boolean, parent: T, currentNodeId: string, treeRegistry: Map<string, TreeViewNode<T>>) {
    const treeviewNodeChild: TreeViewNode<T> = new TreeViewNode<T>(
      child,
      !!child.children ? [] : null,
      null,
      display,
      !!child.children ? child.children.length > 0 : false,
      parent.id === currentNodeId,
      null,
      !!child.parentIdOfFurtherChildren ? child.parentIdOfFurtherChildren : null
    );
    treeRegistry.set(child.id, treeviewNodeChild);
    treeviewNodeChild.parent = treeRegistry.get(parent.id);
    treeviewNodeChild.parent.children.push(treeviewNodeChild);
  }
}