import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BusinessHierarchyNode } from '../../../../../model/business-hierarchy/business-hierarchy-node';
import { FlatTreeControl } from '@angular/cdk/tree';
import { LocalizedStringService } from '../../../../../service/localized-string.service';

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}


@Component({
  selector: 'business-hierarchy-tree',
  templateUrl: './business-hierarchy-tree.component.html',
  styleUrls: ['./business-hierarchy-tree.component.less']
})
export class BusinessHierarchyTreeComponent implements OnInit, OnDestroy {

  @Input()
  public businessHierarchyNodeOberservable: Observable<BusinessHierarchyNode>;
  private businessHierarchyNodeSubscription: Subscription;

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
    );

  dataSource: FlatNode[] = [];

  
  constructor(private readonly localizedStringService: LocalizedStringService) {}

  ngOnDestroy(): void {
    this.businessHierarchyNodeSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.businessHierarchyNodeSubscription = this.businessHierarchyNodeOberservable.subscribe((businessHierarchyNode: BusinessHierarchyNode) => {
      const flatTreeNodeArray: FlatNode[] = [];      
      this.convertToFlatTreeRecursively(0, businessHierarchyNode, flatTreeNodeArray);
      this.dataSource = flatTreeNodeArray;
      this.treeControl.dataNodes = flatTreeNodeArray;
      this.treeControl.expandAll();
    });
  }

  getParentNode(node: FlatNode) {
    
    const nodeIndex = this.dataSource.indexOf(node);

    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (this.dataSource[i].level === node.level - 1) {
        return this.dataSource[i];
      }
    }

    return null;
  }

  shouldRender(node: FlatNode) {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!this.treeControl.isExpanded(parent)) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }
  
  private convertToFlatTreeRecursively(level: number, businessHierarchyNode: BusinessHierarchyNode, flatTreeNodeArray: FlatNode[]): void {
    if(businessHierarchyNode) {
      const expandable: boolean = (businessHierarchyNode.children.length > 0) ? true : false;
      const englishName: string = this.localizedStringService.getEnglishValue(businessHierarchyNode.name);
      const nodeName: string = englishName ? businessHierarchyNode.id + ' - ' + englishName : businessHierarchyNode.id;
      const node: FlatNode = {"name":nodeName, "level":level, "expandable":expandable};
      flatTreeNodeArray.push(node);
      if(businessHierarchyNode.children.length > 0) {
        businessHierarchyNode.children.forEach(businessHierarchyChildNode => {
          this.convertToFlatTreeRecursively(level+1, businessHierarchyChildNode, flatTreeNodeArray);
        })
      }
    }
  }

}
