import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { LocalizedString } from '../model/localized-string';
import { UserService } from '../service/user.service';

@Pipe({
  name: 'localizedStringsFilter',
  pure: false
})
export class LocalizedStringsFilterPipe implements PipeTransform {

  private currentUserLanguage: string = 'en';
  private nameNotFoundText: string;

  constructor(
    private readonly translate: TranslateService,
    private readonly userService: UserService
  ) {
    if(!!this.userService) {
      this.userService.getCurrentUserLanguageIsocode().subscribe(code => this.currentUserLanguage = code);
    }
    this.translate.get('label.localized_string.no_name_found').subscribe((res: string) => this.nameNotFoundText = res);
  }

  transform(localizedStrings: LocalizedString[], showNameNotFound: boolean = false): any {

    if(localizedStrings == null || localizedStrings.length == 0 ) {
      return showNameNotFound ? this.nameNotFoundText : "";
    }

    if (!!localizedStrings && localizedStrings.length > 0) {

      const localizedStringsForCurrentUserLanguage: LocalizedString[] = localizedStrings.filter(
        localizedString => localizedString.language && localizedString.language.isoCode === this.currentUserLanguage
      );

      if (localizedStringsForCurrentUserLanguage.length > 0) {
        
        if(showNameNotFound && localizedStringsForCurrentUserLanguage[0].value === "") {
          return this.nameNotFoundText;
        }

        return localizedStringsForCurrentUserLanguage[0].value;
      }
    }

    return this.nameNotFoundText;
  }

  transformEnglishOrFirst(localizedStrings: LocalizedString[], showNameNotFound: boolean = false): any {
    let fileNameText = showNameNotFound ? this.nameNotFoundText : "";
    if(localizedStrings == null || localizedStrings.length == 0 ) {
      return fileNameText;
    }

    if (!!localizedStrings && localizedStrings.length > 0) {

      const localizedStringsForEnglishLanguage: LocalizedString[] = localizedStrings.filter(
        localizedString => localizedString.language && localizedString.language.isoCode === "en"
      );

      if (localizedStringsForEnglishLanguage.length > 0 && localizedStringsForEnglishLanguage[0].value != "") {
        fileNameText = localizedStringsForEnglishLanguage[0].value;
      } else if (localizedStrings[0].value != ""){
        fileNameText = localizedStrings[0].value;
      } 
    }

    return fileNameText;
  }
}
