import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Material} from '../../../../model/material';
import {MaterialType} from '../../../../model/material-type';
import {MaterialService} from '../../../../service/material.service';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import {ConfirmService} from '../../../../service/confirm.service';
import {Observable} from 'rxjs';
import {HasUnsavedChangesGuard} from "../../../../guards/has-unsaved-changes.guard";

@Component({
  selector: 'material-administration-edit',
  templateUrl: './material-administration-edit.component.html'
})
export class MaterialAdministrationEditComponent implements OnInit, HasUnsavedChangesGuard {

  public material: Material = new Material();
  public materialType = MaterialType;

  constructor(
    private route: ActivatedRoute,
    private materialService: MaterialService,
    private materialEditDataService: MaterialEditDataService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'newBaseIngredient') {
          this.material = this.materialService.newBaseIngredient();
        } else if (params.id === 'newThirdPartyProduct') {
          this.material = this.materialService.newThirdPartyProduct();
        } else {
          this.materialService.load(params.id).subscribe((material: Material) => {
            this.material = material;
          });
        }
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.materialChanged()) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  public materialChanged(): boolean {
    return !this.materialService.isEqual(this.materialEditDataService.materialOrig, this.materialEditDataService.material);
  }

}

