<div class="container-fluid pb-3" *ngIf="!edit">

  <h2 class="display-6 fw-normal">{{ 'headline.administration.baseUnit'| translate }}</h2>

  <div class="row">
    <div class="col-6 ">
      <div class="input-group">
        <input type="text" class="form-control" [placeholder]="'placeholder.unit.search' | translate" [(ngModel)]="searchString" name="languageSearchInput" (keyup.enter)="search()">
        <button type="button" class="btn btn-primary" (click)="search()" >
          <fa-icon icon="search"></fa-icon>
          <span class="button-label">{{'button.search'|translate}}</span>
        </button>
      </div>
    </div>
    <div class="col-6 align-right">
      <button (click)="newUnit()" class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{ 'button.add.unit' | translate }}
      </button>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col">
      <app-table [page]="units" (rowClick)="editItem($event)" (pageableHandler)="switchPage($event)">
        <app-table-column name="#" field="mabasCode"></app-table-column>
        <app-table-column name="{{'label.administration.name'|translate}}" field="name" [valueFunction]="getLocalizedString"></app-table-column>
        <app-table-column name="{{'label.administration.baseUnit'|translate}}" field="baseUnit" [valueFunction]="getUnitName"></app-table-column>
      </app-table>
    </div>
  </div>
  <div class="row">
    <div class="col-12 align-right">
      <button (click)="newUnit()" class="btn btn-primary">
        <fa-icon icon="plus"></fa-icon> {{ 'button.add.unit' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="container-fluid pb-3" *ngIf="edit">

  <h2 class="display-6 fw-normal" *ngIf="unit.id; else addHeadline">{{ 'headline.administration.maintainUnit' | translate }}</h2>

  <ng-template #addHeadline>
    <h2 class="display-6 fw-normal">{{ 'headline.administration.addUnit' | translate }}</h2>
  </ng-template>

  <form>
    <div class="row">

      <div class="col-4">
        <div class="row">
          <div class="col-12 form-group">
            <localized-string-edit id="unit-administration-name" [localizedStrings]="unit.name" [label]="'label.administration.name'|translate"></localized-string-edit>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label for="symbol">{{ 'label.administration.symbol' | translate }}</label>
            <input class="form-control" type="text" [(ngModel)]="unit.symbol" name="symbol" id="symbol" placeholder="" >
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-12 form-group">

            <typeahead
                [inputLabel]="'label.administration.baseUnit' | translate"
                [inputName]="'base'"
                [(model)]="unit.baseUnit"
                [typeaheadFunction]="unitTypeahead"
                [resultFormatter]="unitTypeaheadFormatter"
                [inputFormatter]="unitTypeaheadFormatter">
            </typeahead>

          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label for="factor">{{ 'label.administration.numerator' | translate }}</label>
            <input type="text" class="form-control" [(ngModel)]="unit.numerator" name="numerator" id="numerator" placeholder="">
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label for="factor">{{ 'label.administration.denominator' | translate }}</label>
            <input type="text" class="form-control" [(ngModel)]="unit.denominator" name="denominator" id="denomindenominatorator"
              placeholder="">
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label for="factor">{{ 'label.administration.exp10' | translate }}</label>
            <input type="text" class="form-control" [(ngModel)]="unit.exp10" name="exp10" id="exp10" placeholder="">
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label for="factor">{{ 'label.administration.mabasCode' | translate }}</label>
            <input type="text" class="form-control" [(ngModel)]="unit.mabasCode" name="mabasCode" id="mabasCode" placeholder="">
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="isBaseUnit">
              <label class="form-check-label" for="isBaseUnit">{{ 'label.administration.isBaseUnit' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row" *ngFor="let relevance of visibleUnitRelevance()">
          <div class="col-12 form-group">
            <div class="form-check form-switch">
              <input type="checkbox" class="form-check-input" id="{{relevance}}" value="{{relevance}}" [checked]="isEnumSelected(relevance)" (change)="onChangeRelevance($event, relevance)">
              <label class="form-check-label" for="{{relevance}}">{{ 'label.administration.unit.relevance_' + relevance | lowercase | camelCase | translate }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 align-right">
        <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
        <icon-button *ngIf="unit.id" buttonType="save" (buttonClick)="save()"></icon-button>
        <icon-button *ngIf="!unit.id" buttonType="add" (buttonClick)="save()"></icon-button>
      </div>
    </div>
  </form>
</div>
