<div class="row paddig-top" >
  <div class="col-6">
    <h3>{{'label.administration.attribute.parameter.usage.attributes' | translate}}</h3>
    <app-table [page]="attributes" (rowClick)="navigateToAttribute($event)" (pageableHandler)="switchAttributePage($event)">
      <app-table-column name="{{'label.administration.attribute.parameter.usage.id'|translate}}" field="id">
      </app-table-column>
      <app-table-column name="{{'label.administration.attribute.parameter.usage.name'|translate}}" field="name"
        displayLinkIcon="true" [valueFunction]="getLocalizedStringsValue"></app-table-column>
    </app-table>
  </div>
</div>