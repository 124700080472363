import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

    readonly ellipses = '...'

    transform(value: string, args?: any): string {

        if (typeof value === 'undefined') {
            return value;
        }

        const splittedString: string[] = value.split(' ');

        return splittedString.length > 1 ? splittedString[0].concat(this.ellipses).replace(',', '') : value;
    }
}
