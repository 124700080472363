import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, concatMap, forkJoin, mergeMap, Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Material } from '../../model/material';
import { CustomView } from '../../model/custom-view/custom-view';
import { TemplateViewMaterialResponseComposition } from '../../model/document-creation/template-material-composition';
import { TemplateMaterialSaveRequest } from '../../model/web/request/template-material-save-request';
import { CategoryMaterial } from '../../model/category-material';
import { TemplateViewMaterial } from '../../model/document-creation/template-view-material';
import { RenderingOption } from '../../model/document-creation/rendering-option';
import { Attribute } from '../../model/attribute';
import { CategoryAttribute } from '../../model/category-attribute';
import { CategoryAttributeAttributeValue } from '../../model/category-attribute-attribute-value';
import { DocumentVersion } from '../../model/document-version/document-version';
import { DocumentVersionStatus } from '../../model/document-version/document-version-status';
import { DocumentStatus } from '../../model/document/document-status';
import { TemplateViewList } from '../../model/custom-view/template-view-list/template-view-list';
import { DocumentCreationHttpService } from './document-creation-http.service';
import { MaterialEditDataService } from './material-edit-data.service';
import { DocumentEditDataService } from '../data-service/document-edit-data.service';
import { CommonComparisonService } from '../comparison/common-comparison.service';
import { DocumentCreationComparisonService } from '../comparison/document-creation-comparison.service';
import { NotificationService } from '../notification.service';
import { DocumentVersionDataService } from './document-version-data.service';
import { CustomViewService } from '../customview.service';
import { ConfirmService } from '../confirm.service';
import { AttributeValueService } from '../attribute-value.service';
import { DocumentVersionFormValidationService } from '../form-validation/document-version-form-validation.service';
import { DocumentBackendService } from '../document-backend.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentCreationDataService {

  private subscriptions: Subscription = new Subscription();

  public templateMaterialBehaviorSubject = new BehaviorSubject<TemplateViewMaterial>(null);
  public renderingOptionsBehaviorSubject = new BehaviorSubject<RenderingOption[]>(null);
  public templateViewListsBehaviourSubject = new BehaviorSubject<TemplateViewList[]>(null);
  public someROsInLists = new BehaviorSubject<boolean>(false);
  public documentLoadedBehaviorSubject = new BehaviorSubject<boolean>(false);
  public templateViewCategoryAttributesBehaviorSubject = new BehaviorSubject<CategoryAttribute[]>(null);
  public templateMaterialOrig: TemplateViewMaterial = null;
  public renderingOptionsOrig: RenderingOption[] = null;

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly documentCreationHttpService: DocumentCreationHttpService,
    private readonly materialEditDataService: MaterialEditDataService,
    private readonly attributeValueService: AttributeValueService,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly documentBackendService: DocumentBackendService,
    private readonly commonComparisonService: CommonComparisonService,
    private readonly customViewService: CustomViewService,
    private readonly versionFormValidation: DocumentVersionFormValidationService,
    private readonly documentCreationComparisonService: DocumentCreationComparisonService,
    private readonly notificationService: NotificationService
  ) {}

  public init(materialId: string, templateViewId: string) {
    const tm = this.templateMaterialBehaviorSubject.getValue();
    if (!!tm && tm.material.id === materialId && tm.templateView.id === templateViewId && !!this.renderingOptionsBehaviorSubject.value) {
      return;
    }

    this.subscriptions.add(this.templateMaterialBehaviorSubject.asObservable().pipe(concatMap((templateViewMaterial: TemplateViewMaterialResponseComposition) => {
      if (!!templateViewMaterial) {
        return this.initRenderingOptions(templateViewMaterial);
      }
      return of(null);
    })).subscribe({
      next: (renderingOptions: RenderingOption[]) => {
        if (!!renderingOptions) {
          this.initCategoryMaterials(renderingOptions);
          this.initTemplateViewLists(renderingOptions);
        }
      },
    }));
    this.subscriptions.add(this.documentCreationHttpService.loadTemplateMaterialByMaterialIdAndTemplateViewId(materialId, templateViewId)
      .subscribe({
        next: (templateViewMaterial: TemplateViewMaterialResponseComposition) => {
          this.publishTemplateMaterialData(templateViewMaterial);
        },
      }));
  }

  public initCategoryMaterials(renderingOptions: RenderingOption[]) {

    const templateViewMaterial = this.templateMaterialBehaviorSubject.getValue();

    if (renderingOptions?.length > 0) {
      if (this.materialEditDataService.categoryMaterialsBehaviourSubject.getValue()?.length > 0) {
        return;
      }
      this.fillCategoryAttributesOnTemplateViewFromRenderingOptions(templateViewMaterial, renderingOptions);
      this.materialEditDataService.initCategoryMaterials(templateViewMaterial.material.id, templateViewMaterial.templateView as CustomView, true);
    }
  }

  private initRenderingOptions(templateViewMaterial: TemplateViewMaterialResponseComposition) {
    return this.documentCreationHttpService.loadRenderingOptions(templateViewMaterial.material.id, templateViewMaterial.templateView.id, templateViewMaterial.id)
      .pipe(tap((data: RenderingOption[]) => {
        this.renderingOptionsOrig = _.cloneDeep(data);
        this.renderingOptionsBehaviorSubject.next(data);
      }));
  }

  private initTemplateViewLists(renderingOptions: RenderingOption[]) {
    if (renderingOptions.some(renderingOption => renderingOption.templateViewListId != null)) {
      this.someROsInLists.next(true);
      const templateViewMaterial = this.templateMaterialBehaviorSubject.getValue();

      this.customViewService.loadTemplateViewLists(templateViewMaterial.templateView.id).subscribe((templateViewLists: TemplateViewList[]) => {
        this.templateViewListsBehaviourSubject.next(templateViewLists);
      });
    }
    else {
      this.someROsInLists.next(false);
    }

  }

  public initTemplateViewCategoryAttributes(documentVersion: DocumentVersion) {
    const templateViewId = this.templateMaterialBehaviorSubject.getValue().templateView.id;
    if (!(this.templateViewCategoryAttributesBehaviorSubject.getValue()?.length > 0)) {
      this.customViewService.loadTemplateViewCategoryAttributes(templateViewId).pipe(tap((data: CategoryAttribute[]) => {
        this.updateAttributeValuesForDocumentVersion(data, documentVersion);
      })).subscribe((data2: CategoryAttribute[]) => {
        this.templateViewCategoryAttributesBehaviorSubject.next(data2);
      });
    } else {
      this.updateAttributeValuesForDocumentVersion(this.templateViewCategoryAttributesBehaviorSubject.getValue(), documentVersion);
    }
  }

  private updateAttributeValuesForDocumentVersion(categoryAttributes: CategoryAttribute[], documentVersion: DocumentVersion) {
    categoryAttributes
      .filter(categoryAttribute => documentVersion.categoryAttributeAttributeValues
        .every(categoryAttributeAttributeValue => categoryAttributeAttributeValue.attributeValues[0].attribute.id !== categoryAttribute.attribute.id))
      .forEach((categoryAttribute: CategoryAttribute) => {
        documentVersion.categoryAttributeAttributeValues.push(new CategoryAttributeAttributeValue(
          [this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute)],
          categoryAttribute))
      });
  }

  private fillCategoryAttributesOnTemplateViewFromRenderingOptions(templateViewMaterial: TemplateViewMaterial, renderingOptions: RenderingOption[]) {
    templateViewMaterial.templateView.customViewCategoryAttributes =
      renderingOptions.map((renderingOption: RenderingOption) => renderingOption.categoryAttribute);
  }

  public searchTemplateMaterialByMaterialAndTemplateView(material: Material, templateView: CustomView): void {
    this.documentCreationHttpService.searchTemplateMaterialByMaterialAndTemplateView(material, templateView)
        .subscribe((templateViewMaterial: TemplateViewMaterialResponseComposition) => {
          this.publishTemplateMaterialData(templateViewMaterial);
        });
  }

  public saveTemplateMaterial() {
    let templateViewMaterialSaveData: Observable<any>;
    if (!this.documentEditDataService.documentBehaviorSubject.value.id && !this.templateMaterialBehaviorSubject.value.id) {
      templateViewMaterialSaveData = this.saveNewTemplateMaterial();
    } else {
      templateViewMaterialSaveData = this.saveExistingTemplateViewMaterial();
    }
    return templateViewMaterialSaveData.pipe(tap(templateViewMaterial => {
      if (!!templateViewMaterial) {
        this.publishTemplateMaterialData(templateViewMaterial);
      }
      return templateViewMaterial;
    }));
  }

  saveExistingTemplateViewMaterial() {

    return forkJoin([
        this.documentEditDataService.save()
          .pipe( catchError(err => this.saveDocumentFailed()) ),
        this.materialEditDataService.saveCategoryMaterials(this.templateMaterialBehaviorSubject.value.material.id)
          .pipe(catchError(err => this.saveCatMatsFailed())),
        this.documentCreationHttpService.saveTemplateMaterial(this.createExistingTemplateMaterialSaveRequest())
          .pipe( catchError(err => this.saveTemplateViewMaterialFailed()) )])
      .pipe(map(value => {
        if (!!value[0] && !!value[1] && !!value[2]) {
          this.notificationService.addSuccessNotification('label.successfully.saved');
          return value[2]; // return templateViewMaterial
        } return null;
    }));
  }

  saveNewTemplateMaterial() {
    const saveDocumentFirstThenSaveTemplateViewMaterial$= this.documentCreationHttpService.saveTemplateMaterial(this.createNewTemplateMaterialSaveRequest())
        .pipe( catchError(err => this.saveTemplateViewMaterialFailed()) )

    const saveCategoryMaterials$ = this.materialEditDataService.saveCategoryMaterials(this.templateMaterialBehaviorSubject.value.material.id)
      .pipe( catchError(err => this.saveCatMatsFailed()) );

    return forkJoin([saveDocumentFirstThenSaveTemplateViewMaterial$, saveCategoryMaterials$]).pipe(map(value => {
      if (!!value[0] && !!value[1]) {
        this.notificationService.addSuccessNotification('label.successfully.saved');
        return value[0]; // return templateViewMaterial
      } return null;
    }));
  }

  saveTemplateViewMaterialFailed() {
    if (!this.documentEditDataService.documentBehaviorSubject.value.id && !this.templateMaterialBehaviorSubject.value.id) {
      this.notificationService.addErrorNotification('label.error.saving.templateViewMaterial');
    } else {
      this.notificationService.addErrorNotification('label.error.saving.rendering.options');
    }
    return of(null);
  }

  saveCatMatsFailed() {
    this.notificationService.addErrorNotification('label.error.saving.catmats');
    return of(null);
  }

  saveDocumentFailed() {
    this.notificationService.addErrorNotification('label.error.saving.document');
    return of(null);
  }

  public navigateToOverviewUrlWithError = (response?: HttpErrorResponse): void => this.documentCreationHttpService.navigateToOverviewUrlWithError(response);

  public cleanUp(): void {
    this.renderingOptionsBehaviorSubject.next(null);
    this.templateMaterialBehaviorSubject.next(null);
    this.templateViewListsBehaviourSubject.next(null);
    this.documentLoadedBehaviorSubject.next(false);
    this.someROsInLists.next(false);
    this.templateViewCategoryAttributesBehaviorSubject.next(null);
    this.templateMaterialOrig = null;
    this.renderingOptionsOrig = null;

    this.documentEditDataService.cleanUp();
    this.documentVersionDataService.cleanUp();
    this.materialEditDataService.cleanUp();

    this.clearSubscriptions();
  }

  public clearSubscriptions(): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  public isNewTemplateMaterial(): boolean {
    return this.templateMaterialOrig.id == null;
  }

  public templateMaterialChanged(): boolean {
    const documentChanged = this.documentEditDataService.hasChanges();
    // console.log('documentChanged: '+JSON.stringify(documentChanged))
    const templateViewMaterialChanged = this.commonComparisonService.areEqual(
        () => this.documentCreationComparisonService.createComparisonObject(this.templateMaterialOrig),
        () => this.documentCreationComparisonService.createComparisonObject(this.templateMaterialBehaviorSubject.value)
    );
    // console.log('templateViewMaterialChanged: '+JSON.stringify(templateViewMaterialChanged))
    const categoryMaterialsChanged = this.materialEditDataService.categoryMaterialsChanged();
    // console.log('categoryMaterialsChanged: '+JSON.stringify(categoryMaterialsChanged))
    const renderingOptionsChanged = this.commonComparisonService.areEqual(
      () => this.removeAttributesAndCategoriesFromRenderingOptions(!!this.renderingOptionsOrig ? this.renderingOptionsOrig : []),
      () => this.removeAttributesAndCategoriesFromRenderingOptions(!!this.renderingOptionsBehaviorSubject.getValue() ? this.renderingOptionsBehaviorSubject.getValue() : [])
    );
    // Any rendering option is mandatory to save
    const saveIsMandatory = this.renderingOptionsBehaviorSubject.getValue()?.some(ro => ro.saveIsMandatory && ro.enabled && ro.matching);
    // console.log('renderingOptionsChanged: '+JSON.stringify(renderingOptionsChanged))
    return templateViewMaterialChanged || categoryMaterialsChanged || renderingOptionsChanged || documentChanged || saveIsMandatory;
  }

  private readonly removeAttributesAndCategoriesFromRenderingOptions = (renderingOptions: RenderingOption[]): RenderingOption[] => {
    return renderingOptions.map((renderingOption: RenderingOption) => {
      const idOnlyCategoryAttribute = new CategoryAttribute(renderingOption.categoryAttribute.id, null, null);
      return new RenderingOption(
        renderingOption.enabled,
        null,
        null,
        idOnlyCategoryAttribute,
        null,
        0,
        false
      );
    }).sort();
  }

  private publishTemplateMaterialData(templateViewMaterial: TemplateViewMaterialResponseComposition): void {
    this.templateMaterialOrig = _.cloneDeep(templateViewMaterial as TemplateViewMaterial);
    this.renderingOptionsBehaviorSubject.next(null);
    this.materialEditDataService.cleanUp();
    this.documentEditDataService.cleanUp();

    this.templateMaterialBehaviorSubject.next(templateViewMaterial);

    if(templateViewMaterial != null) {
      if(templateViewMaterial.document != null) {
        this.documentEditDataService.loadDocument(templateViewMaterial.document.id)
          .subscribe(result => this.documentLoadedBehaviorSubject.next(result));
      }
      else {
        this.documentEditDataService.resetDocument();
        this.documentEditDataService.inheritValuesFromTemplateView(templateViewMaterial.templateView);
        this.documentLoadedBehaviorSubject.next(true);
      }
    }
  }

  private createNewTemplateMaterialSaveRequest(): TemplateMaterialSaveRequest {

    const templateViewMaterial: TemplateViewMaterial = this.templateMaterialBehaviorSubject.getValue();
    const document = this.documentEditDataService.documentBehaviorSubject.getValue();
    const renderingOptions: RenderingOption[] = this.renderingOptionsBehaviorSubject.getValue();
    const renderingOptionsSaveRequest = renderingOptions?.length > 0 ? renderingOptions : null;

    return new TemplateMaterialSaveRequest(templateViewMaterial, document, renderingOptionsSaveRequest);
  }

  private createExistingTemplateMaterialSaveRequest(): TemplateMaterialSaveRequest {

    const templateViewMaterial: TemplateViewMaterial = this.templateMaterialBehaviorSubject.getValue();
    const renderingOptions: RenderingOption[] = this.renderingOptionsBehaviorSubject.getValue();
    const renderingOptionsSaveRequest = renderingOptions?.length > 0 ? renderingOptions : null;

    return new TemplateMaterialSaveRequest(templateViewMaterial, null, renderingOptionsSaveRequest);
  }

  public isDisabled(categoryRelation: CategoryMaterial , attribute: Attribute): boolean {
    const renderingOptions = this.renderingOptionsBehaviorSubject.getValue();
    const value = renderingOptions.some(renderingOption =>
      renderingOption.categoryAttribute.attribute.id === attribute.id
      && renderingOption.categoryAttribute.category.id === categoryRelation.category.id
      && renderingOption.enabled);
    return !value;
  }

  public createDraftVersion() {
    this.createVersionStep1(DocumentVersionStatus.DRAFT);
  }

  public createVersion() {
    this.createVersionStep1(DocumentVersionStatus.NOT_PUBLISHED);
  }

  private createVersionStep1(versionStatus: DocumentVersionStatus) {
    this.versionFormValidation.clearErrorList();
    this.validatePublishDate().then((confirmed) => {
      if(confirmed) {
        this.createVersionStep2(versionStatus);
      }
    });
  }

  private createVersionStep2(versionStatus: DocumentVersionStatus) {
    this.validateUntilDate().then((confirmed) => {
      if(confirmed) {
        this.documentVersionDataService.documentVersionBehaviorSubject.value.status = versionStatus;
        this.createNewVersion();
      }
    });
  }

  private createNewVersion() {

    const draftVersionId = this.documentVersionDataService.getDraftVersionId();

    const {version, isValid} = this.prepareVersionSave();

    if (isValid) {
      this.documentCreationHttpService.createDocumentVersion(version,
        this.documentEditDataService.documentBehaviorSubject.value.id,
        draftVersionId).subscribe({
          next:(data : DocumentVersion) => this.handleSaveSuccess(data, draftVersionId),
          error: (error: HttpErrorResponse) => this.documentVersionDataService.handleSaveErrorIfDraftId(draftVersionId)
        });
    } else {
      this.documentVersionDataService.handleSaveErrorIfDraftId(draftVersionId);
    }
    this.versionFormValidation.renderErrors();
  }

  public createAndPublish() {
    this.validatePublishDate().then((confirmed) => {
      if(confirmed) {
        this.createAndPublishStep2();
      }
    });
  }

  private createAndPublishStep2() {
    this.askToConfirmPublishingVersion().then((confirmed) => {
      if (confirmed) {
        this.createVersionStep2(DocumentVersionStatus.PUBLISHED)
      }
    });
  }

  public saveAndPublish() {
    this.validatePublishDate().then((confirmed) => {
      if(confirmed) {
        this.saveAndPublishStep2();
      }
    });
  }

  public saveAndPublishStep2() {
    this.askToConfirmPublishingVersion().then((confirmed) => {
      if (confirmed) {
        this.documentVersionDataService.documentVersionBehaviorSubject.value.status = DocumentVersionStatus.PUBLISHED;
        this.saveVersionStep2()
      }
    });
  }

  private async askToConfirmPublishingVersion() {
    const confirmed = await this.confirmService.confirm('title.caution', 'message.confirm.publish.document.version', 'button.yes', 'button.no');
    return confirmed;
  }

  public saveVersion() {
    this.validatePublishDate().then((confirmed) => {
      if(confirmed) {
        this.saveVersionStep2();
      }
    });
  }

  private saveVersionStep2() {
    const {version, isValid} = this.prepareVersionSave();
    if (isValid) {
      this.documentCreationHttpService.saveVersion(version, this.documentEditDataService.documentBehaviorSubject.value.id)
        .subscribe({
          next:(data : DocumentVersion) => {
              this.handleSaveSuccess(data, null);
            },
          error: (error: HttpErrorResponse) => {
              this.documentVersionDataService.documentVersionBehaviorSubject.value.status = this.documentVersionDataService.versionOrig.status;
            }
        });
    } else {
      this.documentVersionDataService.documentVersionBehaviorSubject.value.status = this.documentVersionDataService.versionOrig.status;
    }
    this.versionFormValidation.renderErrors();
  }

  private handleSaveSuccess = (data: DocumentVersion, draftVersionId: string) => {
    this.documentVersionDataService.documentVersionBehaviorSubject.next(data);
    this.notificationService.addSuccessNotification('label.successfully.created');
    this.documentBackendService.getDocumentStatus(this.documentEditDataService.documentBehaviorSubject.value.id).subscribe((status: DocumentStatus) => {
      this.documentEditDataService.documentBehaviorSubject.value.status = DocumentStatus[status] as DocumentStatus;
      this.documentEditDataService.updateDocumentOrig();
    } );
    this.documentVersionDataService.mergeVersionIntoDocument(!this.documentVersionDataService.versionOrig?.file).then(() => {
      this.documentEditDataService.updateDocumentOrig();
    });
    this.documentVersionDataService.handleSaveSuccessIfDraftId(data, draftVersionId);
  }

  private prepareVersionSave() {
    this.documentVersionDataService.prepareVersionSaveIfDraft();
    const version: DocumentVersion = this.documentVersionDataService.documentVersionBehaviorSubject.getValue();
    this.documentVersionDataService.updateExistingVersions(this.documentEditDataService.documentBehaviorSubject.value, version);

    const isValid = this.versionFormValidation.validate(
      {
        document: this.documentEditDataService.documentBehaviorSubject.value,
        documentVersion: version,
        fileToUpload: null,
        disabledFields: this.documentVersionDataService.inputSettings.value
      }, false);
    return {version, isValid};
  }

  loadAttributeValuesForDocumentVersion($event: DocumentVersion) {
    this.documentCreationHttpService.getAttributeValuesForDocumentVersion($event.id).subscribe((data: DocumentVersion) => {
      this.documentVersionDataService.documentVersionBehaviorSubject.value.categoryAttributeAttributeValues = data.categoryAttributeAttributeValues
        .map((categoryAttributeAttributeValue: CategoryAttributeAttributeValue) => Object.assign(new CategoryAttributeAttributeValue(), categoryAttributeAttributeValue));
      this.documentVersionDataService.documentVersionBehaviorSubject.next(this.documentVersionDataService.documentVersionBehaviorSubject.value);
      this.documentVersionDataService.versionOrig = _.cloneDeep(this.documentVersionDataService.documentVersionBehaviorSubject.value);
      this.documentVersionDataService.versionOrigBehaviorSubject.next(this.documentVersionDataService.versionOrig);
    } );
  }

  private validatePublishDate(): Promise<boolean> {
    if(!this.isPublishDateValid()) {
      return this.askToSetDefaultPublishDate();
    }
    return Promise.resolve(true);
  }

  private validateUntilDate(): Promise<boolean> {
    if(!this.isUntilDateValid()) {
      return this.askToSetDefaultUntilDate();
    }
    return Promise.resolve(true);
  }

  private isPublishDateValid() {
    if (!this.documentVersionDataService.documentVersionBehaviorSubject.value?.validFrom) {
      return false;
    }
    // Check contains Valid From and is valid
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const validFrom = new Date(this.documentVersionDataService.documentVersionBehaviorSubject.value?.validFrom);
    validFrom.setHours(0, 0, 0, 0);
    return validFrom.getTime() >= now.getTime();
  }

  private isUntilDateValid() {
    if (!this.documentVersionDataService.documentVersionBehaviorSubject.value?.validUntil) {
      return false;
    }
    // Check contains Valid Until and is valid
    const validFrom = new Date(this.documentVersionDataService.documentVersionBehaviorSubject.value?.validFrom);
    validFrom.setHours(0, 0, 0, 0);
    const validUntil = new Date(this.documentVersionDataService.documentVersionBehaviorSubject.value?.validUntil);
    validUntil.setHours(0, 0, 0, 0);
    return validUntil.getTime() >= validFrom.getTime();
  }

  private async askToSetDefaultPublishDate() {
    const changePublishDateToNow = await this.confirmService.confirm('title.publishdate.atleast.today', 'text.publishdate.atleast.today.message', 'button.yes', 'button.no');
    if (changePublishDateToNow) {
      this.documentVersionDataService.documentVersionBehaviorSubject.value.validFrom = new Date(new Date().setHours(0, 0, 0, 0));
    } else {
      this.versionFormValidation.addError('title.publishdate.atleast.today');
      this.versionFormValidation.renderErrors();
    }
    return changePublishDateToNow;
  }

  private async askToSetDefaultUntilDate() {
    const changeExpireDateToMax = await this.confirmService.confirm('title.expiredate.empty', 'text.expiredate.empty.message', 'button.yes', 'button.no');
    if (changeExpireDateToMax) {
      this.documentVersionDataService.documentVersionBehaviorSubject.value.validUntil = new Date('9999-12-31');
    } else {
      this.versionFormValidation.addError('backend.validation.error.509');
      this.versionFormValidation.renderErrors();
    }
    return changeExpireDateToMax;
  }

}
