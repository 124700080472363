import { Component } from '@angular/core';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent {

  constructor(private readonly dashboardService: DashboardService) { }

  public getDashboard = () => this.dashboardService.dashboard;
}