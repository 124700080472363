<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal">{{'h2.formulation.header'|translate}}</h2>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="selectedTab">
    <li [ngbNavItem]="'properties'" [routerLink]="'properties'" id="properties" >
      <a ngbNavLink>{{ 'label.formulation.tab.property' | translate }}</a>
    </li>
    <li [ngbNavItem]="'categorization'" [routerLink]="'categorization'" id="categorization">
      <a ngbNavLink>{{ 'label.formulation.tab.categorization' | translate }}</a>
    </li>
    <li [ngbNavItem]="'attributes'" [routerLink]="'attributes'" id="attributes">
      <a ngbNavLink>{{ 'label.formulation.tab.attributes' | translate }}</a>
    </li>
    <li [ngbNavItem]="'relations'" [routerLink]="'relations'" id="relations">
      <a ngbNavLink>{{ 'label.formulation.tab.relation' | translate }}</a>
    </li>
    <li [ngbNavItem]="'steps'" [routerLink]="'steps'" id="steps">
      <a ngbNavLink>{{ 'label.formulation.tab.steps' | translate }}</a>
    </li>
  </ul>

  <div class="border-router-outlet">
    <router-outlet *ngIf="isFormulationLoaded()" ></router-outlet>
  </div>
  <div class="row mb-3 mt-3">
    <div class="col-12 align-right">
      <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button *ngIf="getFormulationId() && !(isReadOnlyUser | async)" buttonType="save" (buttonClick)="save()"></icon-button>
      <icon-button *ngIf="!getFormulationId() && !(isReadOnlyUser | async)" buttonType="add" (buttonClick)="save()"></icon-button>
    </div>
  </div>
</div>
