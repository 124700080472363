<form #f="ngForm">

  <h4>{{ 'label.document.relation.material_assignment' | translate }}</h4>

  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-material" class="col-12"
      [idPrefix]="'multiple-value-selector-material'"
      [values]="getMaterials()"
      (valueAdded)="onMaterialMultipleValueSelectorValueAdded($event)"
      [allowDuplicateEntries]="true"
      (valueRemoved)="onMaterialMultipleValueSelectorValueRemoved($event)"
      [inputName]="'material'"
      [typeahead]="materialTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultFormatter]="materialTypeaheadFormatter"
      [valueTemplate]="materialForDocumentRelationsValueTemplate">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.document.relation.product_hierarchy_object_assignment' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-pho_assignment" class="col-12"
      [idPrefix]="'multiple-value-selector-pho_assignment'"
      (valuesChange)="onProductHierarchyObjectsChange($event)"
      [values]="document.productHierarchyObjects"
      [inputName]="'pho_assignment'"
      [typeahead]="productHierarchyObjectTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultTemplate]="phoResultTemplate"
      [valueTemplate]="phoValueTemplate">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.document.relation.product_hierarchy_object_materials_assignment' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-pho_material_assignment" class="col-12"
      [idPrefix]="'multiple-value-selector-pho_material_assignment'"
      (valuesChange)="onProductHierarchyObjectMaterialsChange($event)"
      [values]="getProductHierarchyObjectMaterials()"
      [inputName]="'pho_material_assignment'"
      [typeahead]="productHierarchyObjectMaterialsTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultTemplate]="phoResultTemplate"
      [valueTemplate]="phoValueTemplate">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.document.relation.category_assignment' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-category_assignment" class="col-12"
      [idPrefix]="'multiple-value-selector-category_assignment'"
      (valuesChange)="onDocumentCategoryChange($event)"
      [values]="getLeafCategories()"
      [inputName]="'category_assignment'"
      [typeahead]="categoriesTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultTemplate]="categoryRelativesTemplate"
      [valueTemplate]="categoryRelativesTemplate"
      [executeAfterAdd]="addWholeCategoryTree"
      [executeAfterDelete]="deleteWholeCategoryTree">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.document.relation.industry_assignment' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-industry_assignment" class="col-12"
      [idPrefix]="'multiple-value-selector-industry_assignment'"
      [(values)]="document.industries"
      [inputName]="'industry_assignment'"
      [typeahead]="industryTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultTemplate]="categoryRelativesTemplate"
      [valueTemplate]="categoryRelativesTemplate">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.document.relation.region_assignment' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-region_assignment" class="col-12"
      [idPrefix]="'multiple-value-selector-region_assignment'"
      [(values)]="document.regions"
      [inputName]="'region_assignment'"
      [typeahead]="regionTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultTemplate]="categoryRelativesTemplate"
      [valueTemplate]="categoryRelativesTemplate">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.document.relation.operating_devision' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector id="multiple-value-selector-operating_division" class="col-12"
      [idPrefix]="'multiple-value-selector-operating_division'"
      [(values)]="document.operatingDivisions"
      [inputName]="'operating_division'"
      [typeahead]="odTypeahead"
      [inputDisabled]="(isReadOnlyUser | async) || readonly"
      [resultFormatter]="odTypeaheadFormatter"
      [valueFormatter]="odTypeaheadFormatter">
    </multiple-value-selector>

  </div>

</form>

<ng-template #categoryRelativesTemplate let-result="result" let-term="term">
  <typeahead-template-category-relatives [model]="result" [term]="term?.trim().split(' ')"></typeahead-template-category-relatives>
</ng-template>

<ng-template #phoValueTemplate let-result="result">
  <typeahead-template-pho-internal-name-link [model]="result" ></typeahead-template-pho-internal-name-link>
</ng-template>

<ng-template #phoResultTemplate let-result="result">
  <typeahead-template-pho-internal-name-path [model]="result" ></typeahead-template-pho-internal-name-path>
</ng-template>

<ng-template #materialForDocumentRelationsValueTemplate let-result="result">
  <typeahead-material-document-relation [model]="result"></typeahead-material-document-relation>
</ng-template>
