import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class JsonHttpService {

  readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    responseType: "text" as "text",
    observe: "response" as "response"//needed to get the response status code
  };

  readonly httpOptions_rsp = {
    responseType: "text" as "text",
    observe: "response" as "response"//needed to get the response status code
  };

  constructor(private http: HttpClient) { }

  get(url: string): Observable<any> {
    return this.http.get(url, { observe: 'response', withCredentials: true });
  }

  postJson(url: string, payload: any): Observable<HttpResponse<string>> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    const httpOptions = {
      headers: headers,
      withCredentials: true,
      responseType: "text" as "text",
      observe: "response" as "response"//needed to get the response status code
    };

    return this.http.post(url, JSON.stringify(payload), httpOptions);
  }

  downloadPdf(url: string, payload: any):  Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf' });

    const httpOptions = {
      headers: headers,
      withCredentials: true,
      responseType: "arraybuffer" as "arraybuffer",
      observe: "response" as "response"//needed to get the response status code
    };

    return this.http.post(url, JSON.stringify(payload), httpOptions);

  }

  delete(url: string): Observable<any> {
    return this.http.delete(url);
  }
}
