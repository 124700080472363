import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from '../model/language';
import { Observable, of } from 'rxjs';
import { Page } from '../model/pim-response/page';
import { share, tap} from 'rxjs/operators';
import { Pageable } from '../model/pageable';
import { environment } from '../../environments/environment';
import { isEqual, omit, orderBy } from 'lodash';
import { LocalizedStringService } from './localized-string.service';
import { LocalizedStringsFilterPipe } from '../pipes/localized-strings-filter.pipe';
import { Constants } from '../constants/constants';
import { BackendValidationService } from "./form-validation/backend-validation.service";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

  private allLanguages: Language[];
  private allLanguagesById: Map<string, Language>;
  private allLanguagesLoaded: Observable<any>;
  private loadStarted = false;

  constructor(
    private httpClient: HttpClient,
    private localizedStringService: LocalizedStringService,
    private readonly localizedStringsFilterPipe: LocalizedStringsFilterPipe,
    private readonly backendValidationService: BackendValidationService
  ) {}

  public loadAllLanguages(): Observable<Language[]> {
    this.loadStarted = true;
    this.allLanguagesLoaded = this.httpClient.get<Language[]>(environment.restUrl + '/language/findAllRaw', Constants.httpOptionsHidingLoadingIndicator).pipe(
        tap((languages: Language[]) => {
            this.allLanguages = languages.sort((a, b) => {
              const aName = this.localizedStringsFilterPipe.transform(a.name);
              const bName = this.localizedStringsFilterPipe.transform(b.name);
              return aName.localeCompare(bName);
            });
            this.init();
            this.loadStarted = false;
        }),
        share()
    );

    return this.allLanguagesLoaded;
  }

  private init(): void {
      const allLanguagesById: Map<string, Language> = new Map();
      for (const language of this.allLanguages) {
        allLanguagesById.set(language.id, language);
      }
      this.allLanguagesById = allLanguagesById;
  }

  public getLanguageById(langId: string): Language {
    return this.allLanguagesById.get(langId) || null;
  }

  public load(id: string): Observable<Language> {
      return this.httpClient.get<Language>(environment.restUrl + '/language/' + id);
  }

  public search(searchString: string, pageable: Pageable): Observable<Page<Language>> {
      return this.httpClient.get<Page<Language>>(
        environment.restUrl
        + '/language/search'
        + ((!!searchString && searchString.length > 0) ? '/' + searchString : '')
        + '?'
        + pageable.toUrlString());
  }

  public getAllLanguages(): Observable<Language[]> {
      if(!!this.allLanguages) {
        return of(this.allLanguages);
      }
      if(!!this.loadStarted) {
        return this.allLanguagesLoaded;
      }
      return this.loadAllLanguages();
  }

  public save(language: Language) {
      return this.httpClient.post(environment.restUrl + '/language/save', language)
        .pipe(this.backendValidationService.renderErrorMessages());
  }

  public getOrderedLanguages(languages: Language[]): any[] {
    return orderBy(languages.map(language => omit(language, ['name'])), ['id']);
  }

  private getLanguageObj(language: Language): any {
    let languageName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(language.name);
    return {
      id: language.id,
      isoCode: language.isoCode,
      name: languageName
    }
  }

  public isEqual(languageOrig: Language, language: Language): boolean {
    if (languageOrig != null && language != null) {
      let languageOrigObj = this.getLanguageObj(languageOrig);
      let languageObj = this.getLanguageObj(language);
      return isEqual(languageOrigObj, languageObj);
    }
    return false;
  }
}
