import { AttributeParameter } from "../attribute-parameter";
import { AttributeParameterType } from "../attribute-parameter-type";
import { AttributeParameterValue } from "./attribute-parameter-value";

export class AttributeParameterStringValue implements AttributeParameterValue {
  public id: string = null;
  public parameterType: AttributeParameterType;
  public attributeParameter: AttributeParameter;
  public stringValue: string = null;

  constructor(obj: any) {
    let apnv = obj as AttributeParameterStringValue;

    this.id = apnv.id;
    this.parameterType = AttributeParameterType.STRING;
    this.attributeParameter = apnv.attributeParameter;
    this.stringValue = apnv.stringValue;
  }
}