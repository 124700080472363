import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';

@Injectable()
export class GuidService {

  public getGuid(): string {
    return 'GUID_' + Guid.create().toString();
  }

  public isGuid(id: string): boolean {
    return id === null ? false : id.substring(0, 4) === 'GUID';
  }

  public removeTemporaryId(item: any): any {
    if (item.hasOwnProperty('id')) {
      if (item.id !== null && this.isGuid(item.id)) {
        item.id = null;
      }
    }
    return item;
  }

  public removeTemporaryIds(elements: any[]): any {

    if (elements) {
      const copyOfElements: any[] = elements.slice();
      copyOfElements.forEach(element => {
        if (element.hasOwnProperty('id')) {
          if (this.isGuid(element.id)) {
            element.id = null;
          }
        }
      });
      return copyOfElements;
    }
    return elements;
  }
}
