<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal">{{ "categories" | translate }}</h2>

  <app-tree-view
      class="col-12"
      [treeViewLineTemplate]="categoryTreeViewLineTemplate"
      [treeViewFacetTemplate]="categoryTreeViewFilterTemplate"
      [areNodesClickable]="!!onNodeClick"
      (nodeClick)="onNodeClick($event)"
      (nodeNewTabClick)="onNodeNewTabClick($event)"
      [formatNodeLabel]="getCategoryTreeviewService().formatNodeLabel"
      [treeviewService]="getCategoryTreeviewService()"
      [showSearchbar]="true"
      [showCurrentNodeOnly]="false">
  </app-tree-view>

</div>

<ng-template #categoryTreeViewLineTemplate let-result="result">
  <app-category-line [model]="result"></app-category-line>
</ng-template>

<ng-template #categoryTreeViewFilterTemplate let-result="result">
  <app-category-filter-line [filter]="result"></app-category-filter-line>
</ng-template>