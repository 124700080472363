import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Report } from '../model/report';
import { Page } from '../model/pim-response/page';
import { Pageable } from '../model/pageable';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private readonly httpClient: HttpClient) { }

  public findAll(pageable: Pageable): Observable<Page<Report>> {
    return this.httpClient.get<Page<Report>>(environment.restUrl + '/reports?' + pageable.toUrlString());
  }

  public createDownloadUrl(report: Report): string {
    return environment.restUrl + '/reports/' + report.name;
  }
  
}