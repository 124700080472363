import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faSave, IconDefinition, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faStop, faCheck, faUpload, faPersonWalkingArrowLoopLeft, faEraser, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.less']
})
export class IconButtonComponent implements OnInit {

  @Input() 
  public buttonType: string;

  @Input() 
  public buttonLabel: string;

  @Input() 
  public buttonAction: any;

  @Input() 
  public buttonDisabled: boolean;

  @Input() 
  public buttonHideLabel = false;

  @Input()
  public buttonTitle: string;

  @Output() 
  public buttonClick = new EventEmitter();

  public buttonIcon: IconDefinition;

  constructor(private translateService: TranslateService) {}

  public buttonClicked(): void {
    this.buttonClick.emit();
  }

  ngOnInit() {
    this.configButton();
  }

  private configButton(): void {
    let labelName: string;
    switch (this.buttonType) {
      case 'save':
        this.buttonIcon = faSave;
        labelName = 'button.save';
        break;
      case 'cancel':
        this.buttonIcon = faStop;
        labelName = 'button.cancel';
        break;
      case 'return':
        this.buttonIcon = faPersonWalkingArrowLoopLeft;
        labelName = 'button.return';
        break;
      case 'search':
        this.buttonIcon = faSearch;
        labelName = 'button.search';
        break;
      case 'clear filter tree':
        this.buttonIcon = faEraser;
        labelName = 'button.clearFilterTree';
        break;
      case 'apply filter tree':
        this.buttonIcon = faFilter;
        labelName = 'button.applyFilterTree';
        break;
      case 'add':
        this.buttonIcon = faPlus;
        labelName = 'button.add';
        break;
      case 'delete':
        this.buttonIcon = faTrashAlt;
        labelName = 'button.delete';
        break;
      case 'remove':
        this.buttonIcon = faTrashAlt;
        labelName = 'button.remove';
        break;
      case 'confirm':
        this.buttonIcon = faCheck;
        labelName = 'button.confirm';
        break;
      case 'edit':
        this.buttonIcon = faEdit;
        labelName = 'button.edit';
        break;
      case 'save and publish':
        this.buttonIcon = faUpload;
        labelName = 'button.save.publish';
        break;
      case 'add and publish':
        this.buttonIcon = faUpload;
        labelName = 'button.add.publish';
        break;
      case 'save version':
        this.buttonIcon = faSave;
        labelName = 'button.save.version';
        break;
      case 'add version as draft':
        this.buttonIcon = faPlus;
        labelName = 'button.add.version.draft';
        break;
      case 'save draft version':
        this.buttonIcon = faSave;
        labelName = 'button.save.version.draft';
        break; 
      case 'save not published':
        this.buttonIcon = faSave;
        labelName = 'button.save.not_published';
        break;
      case 'add doc as not published':
        this.buttonIcon = faPlus;
        labelName = 'button.add.doc.not_published';
        break;
      case 'add as not published':
        this.buttonIcon = faPlus;
        labelName = 'button.add.not_published';
        break;
  
      case 'proceed with selection':
        this.buttonIcon = faSave;
        labelName = 'button.proceed.with.selection';
        break;
    }
    
    if (!this.buttonLabel) {
      this.buttonLabel = this.translateService.instant(labelName);
    }
      
  }
}
