import { Attribute } from './../../model/attribute';
import { Injectable } from '@angular/core';
import { FormValidationService } from '../form-validation.service';
import { NotificationService } from '../notification.service';
import { LocalizedString } from './../../model/localized-string';

@Injectable()
export class AttributeFormValidationService extends FormValidationService {

  constructor(protected notificationService: NotificationService) {
    super(notificationService);

    this.addValidation(this.nameInEnglishFilled);
    this.addValidation(this.attributeTypeSelected);
    this.addValidation(this.relevanceFlagSelected);
    this.addValidation(this.localizedEnumerationValuesEnglishFilled);
  }

  // Validation: is name in english set
  public nameInEnglishFilled: (validation: AttributeFormValidationService, obj: Attribute) => boolean = (validation: AttributeFormValidationService, obj: Attribute) => {

    if (!this.localizedStringInEnglishFilled(obj.name)) {
      validation.addError('label.administration.attribute.name_is_required');
      return false;
    }

    return true;
  }

  // Validation: does all localized enumeration values have an english name
  public localizedEnumerationValuesEnglishFilled: (validation: AttributeFormValidationService, obj: Attribute) => boolean = (validation: AttributeFormValidationService, obj: Attribute) => {
    let valid = true;
    if(this.attributeHasLocalizedEnumerationValues(obj) && obj.localizedEnumerationValues && obj.localizedEnumerationValues.length > 0) {
      obj.localizedEnumerationValues.forEach(enumValue => {
        if (!this.localizedStringInEnglishFilled(enumValue.name)) {
          validation.addError('label.administration.attribute.enumerationvalue.name_is_required');
          valid = false;
          return;
        }
      });
    }

    return valid;
  }

  private attributeHasLocalizedEnumerationValues(obj: Attribute){
    return obj.type === 'LOCALIZED_ENUMERATION_MULTIPLE' || obj.type === 'LOCALIZED_ENUMERATION';
  }

  // Validation: Is type set?
  public attributeTypeSelected(validation: AttributeFormValidationService, obj: Attribute): boolean {
    if (!obj.type) {
      validation.addError('label.administration.attribute.type_is_required');
      return false;
    }
    return true;
  }

  public relevanceFlagSelected(validation: AttributeFormValidationService, obj: Attribute): boolean {
    if (obj.attributeRelevance.length === 0) {
      validation.addError('label.administration.attribute.relevance_is_required');
      return false;
    }
    return true;
  }

  // validates a list of localized string if english is set and filled
  public localizedStringInEnglishFilled(localizedStrings: LocalizedString[]): boolean {
    let valid = false;

    localizedStrings.forEach(item => {
      if (item.language.isoCode === 'en' && item.value) {
        if (item.value.length > 0) {
          valid = true;
        }
        return;
      }
    });

    return valid;
  }

}
