import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Material } from '../../../model/material';
import { Page } from '../../../model/pim-response/page';
import { Pageable } from '../../../model/pageable';
import { MaterialSearchService } from '../../../service/material-search.service';

@Component({
    selector: 'material-administration',
    templateUrl: './material-administration.component.html',
    styleUrls: ['./material-administration.component.less']
})
export class MaterialAdministrationComponent implements OnInit {

    public searchString = '';
    public page: Page<Material>;
    public pageable: Pageable = new Pageable();

    constructor(
        private route: ActivatedRoute,
        private router: Router,) {
    }

    ngOnInit() {
        this.handleRouteParams();
        // this.loadData();
    }

    private handleRouteParams() {
        if (this.route.snapshot.queryParamMap.get('page')) {
            this.pageable.page = +this.route.snapshot.queryParamMap.get('page');
        }
        if (this.route.snapshot.queryParamMap.get('search')) {
            this.searchString = this.route.snapshot.queryParamMap.get('search');
        }
    }

    public createNewThirdPartyProduct(): void {
        this.router.navigateByUrl('adminstration/material/newThirdPartyProduct');
    }

    public createNewBaseIngredient(): void {
        this.router.navigateByUrl('adminstration/material/newBaseIngredient');
    }
}
