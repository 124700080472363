import { Injectable } from '@angular/core';
import { Notification } from '../model/notification';
import { NotificationService } from './notification.service';

@Injectable()
export class FormValidationService {

    protected validationErrorList: Array<string> = [];
    protected formValidationChecks: Array<any> = [];
    protected notifications: Notification[] = [];

    constructor(protected notificationService: NotificationService) {}

    public clearNotifications(): void {
        this.notificationService.removeNotifications(this.notifications);
        this.notifications = [];
    }

    public addError(errorCode: string) {
        this.validationErrorList.push(errorCode);
    }

    public getErrorList(): Array<string> {
        return this.validationErrorList;
    }

    public clearErrorList() {
        this.validationErrorList = [];
    }

    public renderErrors(): void {
        this.validationErrorList.filter((v, i) =>
            this.validationErrorList.findIndex(item => item == v) === i
        ).forEach(function (value) {
            this.notifications.push(this.notificationService.addErrorNotification(value));
        }, this);
    }

    public addValidation(callbackfn: (formvalidationService: FormValidationService, objectToCheck: any) => boolean): void {
        this.formValidationChecks.push(callbackfn);
    }

    public addPropertyIsSetValidation(propertyName: string, errorLabel: string): void {
        const callback = (formvalidationService: FormValidationService, objectToCheck: any) => this.validatePropertySet(formvalidationService, objectToCheck, propertyName, errorLabel);
        this.addValidation(callback);
    }

    public validate(object: any): boolean {
        let valid: boolean = true;

        this.clearNotifications();
        this.clearErrorList();
        this.formValidationChecks.forEach(element => {
            if (!element(this, object)) {
                valid = false;
            }
        }, this);

        return valid;
    }

    private validatePropertySet(validation: FormValidationService, obj: any, propertyName: string, errorLabel: string): boolean {
        if (!obj[propertyName]) {
            validation.addError(errorLabel);
            return false;
        }
        return true;
    }
}
