import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {User} from '../../model/user';
import {environment} from '../../../environments/environment';
import {UserRole} from '../../model/userRole';
import {BehaviorSubject, Observable, skipWhile} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../user.service';

@Injectable()
export class AuthenticationService {
    public currentUser: User;
    public isLoggedInBehaviourSubject = new BehaviorSubject<boolean>(false);
    public isReadOnlyBehaviourSubject = new BehaviorSubject<boolean>(false);
    public isNotAdvancedBehaviourSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private router: Router,
        private http: HttpClient,
        private userService: UserService
    ) { }

    public isLoggedIn(): Observable<boolean> {
      this.userService.getCurrentUser().subscribe(user => {
        this.currentUser = user;
        if (!!this.currentUser?.roles &&
          (this.currentUser?.roles?.length < 1 || (this.currentUser.roles?.length === 1 && this.currentUser.roles[0].name === 'AUTHENTICATED'))) {
          this.logout('https://confluence.basf.net/display/PIM/Roles+and+how+to+get+access+to+PIM');
          return;
        }
        this.isLoggedInBehaviourSubject.next(!!this.currentUser);
      });
      return this.isLoggedInBehaviourSubject.asObservable();
    }

    public refreshPage(redirectUrl?: string): void {
        const currentLocation: string = window.location.pathname;
        if (currentLocation === redirectUrl || !redirectUrl) {
          if (window.location.hash === '#/logout'){
            window.location.replace('/');
          } else {
            window.location.reload();
          }
        } else {
            this.router.navigate([redirectUrl]);
        }
    }

    public login(username: string, password: string, paramRedirectUrl?: string): void {
        const body: FormData = new FormData();
        body.append('username', username);
        body.append('password', password);

        const httpOptions = {
          withCredentials: true,
          responseType: 'text' as 'text',
          observe: 'response' as 'response' // needed to get the response status code
        };

        this.http.post(environment.loginUrl, body, httpOptions)
            .subscribe({
              next: response => {
                if (response.status === 200) {
                  this.currentUser = JSON.parse(response.body);
                  localStorage.setItem('formLogin', JSON.stringify(true));
                  this.refreshPage(paramRedirectUrl);
                } else {
                  console.log('not 200');
                }
              }, error: (err: any) => {
                console.log(err);
              }
            });
    }

    public logout(redirect?: string): void {
      const formLogin = JSON.parse(localStorage.getItem('formLogin'));
      let logoutUrl = '';
      this.currentUser = null;
      if (formLogin) {
        logoutUrl = environment.logoutUrl;
        this.http.get(logoutUrl, { responseType: 'text', observe: 'response' })
        .subscribe(response => {
          if (response.status === 200) {
            localStorage.removeItem('formLogin');
            this.redirectAfterLogout(redirect);
          }
      });
      } else {
        logoutUrl = environment.samlLogoutUrl;
        if(environment.formsLogin) {
          logoutUrl = environment.logoutUrl;
        }
        this.http.post(logoutUrl, { responseType: 'text', observe: 'response' })
        .subscribe(response => {
          this.redirectAfterLogout(redirect);
          });
      }
    }

  private redirectAfterLogout(redirect?: string) {
    if (!!redirect) {
      localStorage.removeItem('formLogin');
      localStorage.removeItem('login');
      window.location.href = redirect;
    } else {
      (environment.environment !== 'prod' ? this.refreshPage() : window.location.href = 'https://www.basf.net');
    }
  }

  private hasRole(role: UserRole): Observable<boolean> {
      return this.isLoggedIn().pipe(skipWhile(u => !u), map((isLoggedIn: boolean) => {
          if (isLoggedIn && !!this.currentUser.roles && this.currentUser.roles.length > 0) {
            return this.currentUser.roles.some(userRole => role.name === userRole.name)
          }
      }));
    }

    public isAdmin(): Observable<boolean> {
      return this.hasRole(new UserRole('ADMIN'));
    }

    public isUser(): Observable<boolean> {
      return this.hasRole(new UserRole('USER'));
    }

    public isReader(): Observable<boolean> {
      return this.hasRole(new UserRole('READER'));
    }

  public isAdvanced(): Observable<boolean> {
    return this.hasRole(new UserRole('ADVANCED'));
  }

  public isReadOnlyUser(): Observable<boolean> {
    this.isLoggedInBehaviourSubject.asObservable().subscribe(isLoggedIn => {
      this.isReadOnlyBehaviourSubject.next(this.currentUser?.roles.some(userRole => userRole.name === 'READER') &&
        this.currentUser?.roles.every(userRole => (userRole.name !== 'USER') && (userRole.name !== 'ADMIN') && (userRole.name !== 'ADVANCED')))
    });
    return this.isReadOnlyBehaviourSubject.asObservable();
  }

  public isNotAdvancedUser(): Observable<boolean> {
    this.isLoggedInBehaviourSubject.asObservable().subscribe(isLoggedIn => {
      this.isNotAdvancedBehaviourSubject.next(this.currentUser?.roles.some(userRole => ((userRole.name === 'USER') || (userRole.name === 'READER'))) &&
        this.currentUser?.roles.every(userRole => (userRole.name !== 'ADMIN') && (userRole.name !== 'ADVANCED')))
    });
    return this.isNotAdvancedBehaviourSubject.asObservable();
  }
}
