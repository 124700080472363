import { Component, Input, TemplateRef, EventEmitter, Output, ViewChild, ElementRef, ViewEncapsulation, OnInit } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Pageable } from '../../model/pageable';
import { Language } from '../../model/language';
import { Slice } from '../../model/pim-response/slice';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TypeaheadComponent implements OnInit {

  @ViewChild('popupElement', { static: false })
  private popupTemplate: ElementRef;

  @Output()
  public newValueEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public modelChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public buttonPrependClick = new EventEmitter<any>();

  @Output()
  public buttonAppendClick = new EventEmitter<any>();

  @Input()
  public validationError: { required: boolean } = { required: false };

  @Input()
  public typeaheadId: string = "typeahead-id";

  @Input()
  public model: any;

  @Input()
  public inputLabel: string = null;

  @Input()
  public inputPlaceholder: string = "";

  @Input()
  public inputName: string = "";

  @Input()
  public additionalDebounceTime: number = 100;

  @Input()
  public inputRequired = false;

  @Input()
  public inputDisabled = false;

  @Input()
  public inputHideValue = false;

  @Input()
  public typeaheadFunction: any;

  @Input()
  public inputFormatter: any;

  @Input()
  public resultFormatter: any;

  @Input()
  public resultTemplate: TemplateRef<any>;

  @Input()
  public valueTemplate: TemplateRef<any>;

  @Input()
  public buttonPrepend = false;

  @Input()
  public buttonPrependType: string;

  @Input()
  public buttonPrependDisabled = false ;

  @Input()
  public buttonAppend = false;

  @Input()
  public buttonAppendType: string;

  @Input()
  public buttonAppendDisabled= false ;

  public slice: Slice<any>;
  public pageable: Pageable = new Pageable();
  public language: Language;
  public term: string;
  public objects: any[];
  private registered = false;
  public inputLoadingIndicator = false;
  public searchFailed = false;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
	focus$ = new Subject<string>();
	click$ = new Subject<string>();

  @Input()
  public validateField() {
    if(typeof this.model !== 'object') {
      this.selectItem(null);
      this.modelChange.emit(this.model);
      this.term = '';
    }
  }

  @Input() public selectItem = (newValue: any) => this.model = newValue && newValue.item ? newValue.item : null;

  ngOnInit(): void {
    if (this.typeaheadId === 'typeahead-id'){
      this.typeaheadId += this.inputName;
    }
  }

  init(): void {
    if (!this.registered && this.popupTemplate) {
      this.registerScrollToEndListener(this.popupTemplate.nativeElement.parentElement.parentElement);
      this.registered = true;
    }
  }

  registerScrollToEndListener(htmlElement) {
    htmlElement.addEventListener('scroll', (event) => {
      if (Math.ceil(event.target.scrollHeight - event.target.scrollTop) <= (event.target.clientHeight + 45)) {
        this.loadNextSlice();
      }
    });
  }

  public buttonPrependClickEvent(): void {
    this.buttonPrependClick.emit();
  }

  public buttonAppendClickEvent(): void {
    this.buttonAppendClick.emit();
  }

  private showIndicator(): boolean {
    this.inputLoadingIndicator = true;
    return true;
  }

  private hideIndicator(): boolean {
    this.inputLoadingIndicator = false;
    return true;
  }

  public showClearSelectedValue(): boolean {
    return (this.model != null && typeof this.model == 'object' && !this.inputLoadingIndicator) && !this.inputDisabled;
  }

  public loadNextSlice() {
    if(!!this.slice.hasNext) {
      this.showIndicator()
      this.slice.hasNext = false; // Prevent multiple calls. Will get overwritten by the response
      this.pageable.page++;
      this.typeaheadFunction(this.term, this.pageable).subscribe(
        (res: Slice<any>) => {
          this.slice = res;
          res.content.forEach(object => this.objects.push(object));
        }
      ).add(() => {
        this.hideIndicator()
      });
    }
  }

  public typeaheadSliceFunction = (searchString$: Observable<string>) => {
    const debouncedText$ = searchString$.pipe(debounceTime(200 + this.additionalDebounceTime), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
		const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(data => data.length > 0 && this.showIndicator() && (this.pageable = new Pageable())),
      switchMap(term => this.typeaheadFunction(term, this.pageable).pipe(
        map((slice: Slice<any>) => {
          this.objects = [];
          this.term = term;
          this.registered = false;
          this.slice = slice;
          this.objects = slice.content;
          this.searchFailed = slice.content.length == 0;
          if (slice.content.length == 0){
            return [null];
          }
          return this.objects;
        }),
        catchError(() => {
          return of([]);
        }),
        finalize(() => {
          this.hideIndicator()
        })
      )
      )
    );
  }

  public addValue($event: any): void {
    this.selectItem(!this.inputHideValue ? $event : null);
    this.modelChange.emit(this.model);
    this.newValueEvent.emit($event);
  }

  public clearSelectedModel() {
    this.addValue(null);
  }
}
