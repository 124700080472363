import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {AttributeRelevance} from '../model/attribute-relevance';
import {Category} from '../model/category';
import {Attribute} from './../model/attribute';
import {AttributeValueService} from './attribute-value.service';
import {map} from 'rxjs/operators';
import {AttributeValue} from '../model/attribute-value';
import {isEqual, orderBy} from 'lodash';
import {CategoryAttribute} from '../model/category-attribute';
import {CategoryProductHierarchyObject} from '../model/category-product-hierarchy-object';
import {ProductHierarchyObject} from '../model/product-hierarchy-object/product-hierarchy-object';
import {BackendValidationService} from './form-validation/backend-validation.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryProductHierarchyObjectService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly attributeValueService: AttributeValueService,
    private readonly backendValidationService: BackendValidationService
  ) {
  }

  public save(id: string, categoryPho: CategoryProductHierarchyObject[]): Observable<CategoryProductHierarchyObject[]> {
    return this.httpClient.post<CategoryProductHierarchyObject[]>(environment.restUrl + '/categorypho/save/' + id, categoryPho)
        .pipe(this.backendValidationService.renderErrorMessages());
  }

  public loadByPhoIdAndCreateAttributeValuesIfNotExist(id: string): Observable<CategoryProductHierarchyObject[]> {
    return this.loadByPhoId(id).pipe(map((categoryPhos: CategoryProductHierarchyObject[]) => {
      categoryPhos.forEach((categoryPho: CategoryProductHierarchyObject) => {
        this.createMissingAttributeValues(categoryPho);
      });
      return categoryPhos;
    }));
  }

  private loadByPhoId(id: string): Observable<CategoryProductHierarchyObject[]> {
    return this.httpClient.get<CategoryProductHierarchyObject[]>(environment.restUrl + '/categorypho/' + id);
  }

  public createAndAddCategoryPhosWithAttributeValues(pho: ProductHierarchyObject, category: Category, categoryPhos: CategoryProductHierarchyObject[], manuallyMaintained: boolean = false) {
    if (!this.categoryPhoExistsForCategory(categoryPhos, category)) {
      categoryPhos.push(this.createCategoryPhosWithAttributeValues(pho, category, manuallyMaintained));
    } else {
      categoryPhos.forEach(cPho => {
        if (cPho.category.id === category.id && !cPho.manuallyMaintained) {
          cPho.manuallyMaintained = manuallyMaintained;
        }
      });
    }
    if (category.parent) {
      this.createAndAddCategoryPhosWithAttributeValues(pho, category.parent, categoryPhos, false);
    }
  }

  public removeCategoryPhos(category: Category, categoryPhos: CategoryProductHierarchyObject[]) {
    this.removeCategoryPhoIfNotInUse(category, category, categoryPhos);
  }

  public createMissingAttributeValues(categoryPho: CategoryProductHierarchyObject) {
    categoryPho.category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
        if (!this.attributeValueExist(categoryPho, categoryAttribute.attribute) &&
          categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.PRODUCT_HIERARCHY_OBJECT)) {
          categoryPho.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
        }
      }
    );
  }

  private attributeValueExist(categoryPho: CategoryProductHierarchyObject, attribute: Attribute): boolean {
    return !!categoryPho.attributeValues.find((attributeValue: AttributeValue) => attributeValue.attribute.id === attribute.id);
  }

  private removeCategoryPhoIfNotInUse(childCategory: Category, categoryToRemove: Category, categoryPhos: CategoryProductHierarchyObject[]) {
    if (!this.isCategoryPhoNeedByAnotherCategory(childCategory, categoryToRemove, categoryPhos)) {
      const indexToRemove = categoryPhos.findIndex((categoryPho: CategoryProductHierarchyObject) => {
        return categoryPho.category.id === categoryToRemove.id;
      });
      categoryPhos.splice(indexToRemove, 1);
      if (categoryToRemove.parent) {
        this.removeCategoryPhoIfNotInUse(categoryToRemove, categoryToRemove.parent, categoryPhos);
      }
    }
  }

  private isParentCategoryManuallyMaintained(parentCategory: Category, categoryPhos: CategoryProductHierarchyObject[] = []): boolean {
    let categoryPhosFound = categoryPhos.filter(cPho => cPho.category.id === parentCategory.id);

    for (let i = 0, max = categoryPhosFound.length; i < max; i++) {
      if (categoryPhosFound[i].manuallyMaintained) {
        return true;
      }
    }
    return false;
  }

  private isCategoryPhoNeedByAnotherCategory(childCategory: Category, categoryToRemove: Category, categoryPhos: CategoryProductHierarchyObject[]) {
    for (let i = 0, max = categoryPhos.length; i < max; i++) {
      if ((categoryPhos[i].category.id !== childCategory.id
          && categoryPhos[i].category.parent
          && categoryPhos[i].category.parent.id === categoryToRemove.id)
        || this.isParentCategoryManuallyMaintained(categoryToRemove, categoryPhos)) {
        return true;
      }
    }
    return false;
  }

  private createCategoryPhosWithAttributeValues(pho: ProductHierarchyObject, category: Category, manuallyMaintained: boolean = false) {
    const categoryPho = new CategoryProductHierarchyObject();
    categoryPho.productHierarchyObject = pho;
    categoryPho.category = category;
    categoryPho.manuallyMaintained = manuallyMaintained;
    category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
      if (categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.PRODUCT_HIERARCHY_OBJECT)) {
        categoryPho.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
      }
    });
    return categoryPho;
  }

  private categoryPhoExistsForCategory(categoryPhos: CategoryProductHierarchyObject[], category: Category): boolean {
    for (let i = 0, max = categoryPhos.length; i < max; i++) {
      if (categoryPhos[i].category.id === category.id) {
        return true;
      }
    }
    return false;
  }

  private getOrderedCategoryPhosWithAttributes(categoryPhos: CategoryProductHierarchyObject[]): any[] {
    return orderBy(categoryPhos.map(categoryPho => {
      let attributeValues = this.attributeValueService.getOrderedAttributeValues(categoryPho.attributeValues);
      return {category: categoryPho.category.id, productHierarchyObject: categoryPho.productHierarchyObject.id, attributeValues: attributeValues};
    }), ['category']);
  }

  public isEqual(categoryPhosOrig: CategoryProductHierarchyObject[], categoryPhos: CategoryProductHierarchyObject[]): boolean {
    let categoryPhosOrigWithAttributes = this.getOrderedCategoryPhosWithAttributes(categoryPhosOrig);
    let categoryPhosWithAttributes = this.getOrderedCategoryPhosWithAttributes(categoryPhos);
    return isEqual(categoryPhosOrigWithAttributes, categoryPhosWithAttributes);
  }
}
