import {Component, Input, OnInit} from '@angular/core';
import {AttributeParameterStringValue} from '../../../../model/attributeparametervalue/attribute-parameter-string-value';
import {AttributeValue} from '../../../../model/attribute-value';
import {environment} from '../../../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {EditorService} from '../../../../service/editor.service';

@Component({
  selector: 'string-edit',
  templateUrl: './string-edit.component.html',
  styleUrls: ['./string-edit.component.less']
})
export class StringEditComponent implements OnInit{

  @Input()
  public readonly: boolean;

  @Input()
  public attributeValue: AttributeValue;

  @Input()
  public showCharactersLeft: boolean = false;

  @Input()
  public parameterValue: AttributeParameterStringValue;

  public maxStringLength: BehaviorSubject<number> = new BehaviorSubject<number>(environment.localizedStringMaxLength);
  textLength: number = 0;

  constructor(private readonly editorService: EditorService) {
  }

  public get value(): string {
    return !!this.attributeValue ? this.attributeValue.string : this.parameterValue?.stringValue;
  }

  public set value(value: string) {
    if(!!this.attributeValue) {
      this.attributeValue.string = value;
    } else {
      this.parameterValue.stringValue = value;
    }

    this.textLength = this.editorService.getUnformattedText(value).length;
  }

  public getId = () => !!this.attributeValue?.id ? this.attributeValue.id : this.parameterValue?.id;

  ngOnInit(): void {
    this.maxStringLength.next(!!this.attributeValue?.attribute?.textLength ? this.attributeValue.attribute.textLength : environment.localizedStringMaxLength)
    this.textLength = this.editorService.getUnformattedText(this.value).length;
  }

  onHtmlContentChange($event: string) {
    this.value = $event;
  }
}
