<div class="template-view-list-name">{{ templateViewList.name }}</div>

<div class="horizontal-spacer"></div>

<div (click)="showParametername = !showParametername" class="parameter-name-button">
  <fa-icon [icon]="['fas', 'signs-post']" [style.color]="templateViewList.parametername?.length > 0 ? '#006500aa' : '#650000aa'"></fa-icon>
</div>
<pim-popover (click)="$event.stopPropagation" [isActive]="showParametername" class="parametername-target-element">
  <div class="paramter-name-popover-wrapper">
    <span>{{ 'label.parametername' | translate }}: &#160;</span>
    <span>
      <input class="parameter-name-input form-control" [(ngModel)]="templateViewList.parametername">
    </span>
    <span>
      <icon-button buttonType="save" (click)="changeParametername()"
                   id="template-view-list-parametername-save-button-{{ templateViewList.name }}"></icon-button>
    </span>
  </div>
</pim-popover>
