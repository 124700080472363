import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MassImportService {

  constructor(private httpClient: HttpClient) { }


  public exportCategoryMaterials(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.restUrl + '/mass-maintenance/category-material/export', formData, { headers: {}, responseType: 'blob' });
  }

  public createCategories(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.restUrl + '/mass-maintenance/category/create', formData, { headers: {}, responseType: 'blob' });
  }

  public updateCategoryMaterials(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.restUrl + '/mass-maintenance/category-material/update', formData, { headers: {}, responseType: 'blob' });
  }

  public updateExpireDateForDocuments(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.restUrl + '/mass-maintenance/document/set-expire-date', formData, { headers: {}, responseType: 'blob' });
  }

  public patchMaterials(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.restUrl + '/mass-maintenance/material-attribute-values/patch', formData, { headers: {}, responseType: 'blob', observe: 'response' });
  }

  public exportMaterials(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.restUrl + '/mass-maintenance/material-attribute-values/export', formData, { headers: {}, responseType: 'blob', observe: 'response' });
  }

  public patchMaterialsTemplate(): Observable<any> {
    return this.httpClient.get(environment.restUrl + '/mass-maintenance/material-attribute-values/template', { headers: {}, responseType: 'blob' });
  }

  public exportMaterialsTemplate(): Observable<any> {
    return this.httpClient.get(environment.restUrl + '/mass-maintenance/material-attribute-values/export-template', { headers: {}, responseType: 'blob' });
  }


}
