import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Pageable } from '../../model/pageable';
import { BaseTypeaheadService } from './base-typeahead.service';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { AttributeCluster } from '../../model/attribute-cluster';
import { TypeaheadExcludeIdRequest } from '../../model/request/typeahead-exclude-id-request';

@Injectable({
  providedIn: 'root'
})
export class AttributeClusterTypeaheadService implements AbstractTypeaheadService<AttributeCluster> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<AttributeCluster>> {

    let body = new TypeaheadExcludeIdRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      exclude
    );

    return this.baseTypeaheadService.createCall('/attribute/cluster/typeahead/v2', body, pageable);
  }

  public typeaheadFormatter(model: AttributeCluster): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }

}
