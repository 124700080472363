import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { UrlParameter } from '../../model/parameter/url-parameter';
import { SortType } from '../../model/sort-type';
import { UserFilterParameter } from '../../model/user-filter/user-filter-parameter';
import { UrlParameterService } from '../url-parameter.service';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';

@Injectable({
  providedIn: 'root'
})
export class OverviewUserFilterService {

  constructor(
    private urlParameterService: UrlParameterService) {
  }

  public updateSortDirection(userFilterResponse: UserFilterResponse): Map<string, SortType> {
    let sortDirection: Map<string, SortType> = new Map<string, SortType>();

    if (!userFilterResponse) {
      return sortDirection;
    }

    const filterParameter: UserFilterParameter = _(userFilterResponse.filters)
      .filter(p => p.filterParameterValue === '["DESC"]' || p.filterParameterValue === '["ASC"]')
      .first();


    if (!filterParameter) {
      return sortDirection;
    }

    const sortField: string = filterParameter.filterParameterName;
    let sortType: SortType;
    if (filterParameter.filterParameterValue === '["ASC"]') {
      sortType = SortType.ASC;

    } else if (filterParameter.filterParameterValue === '["DESC"]')
      sortType = SortType.DESC;

    else {
      sortType = SortType.NONE;

    }

    sortDirection.set(sortField, sortType);
    return sortDirection;
  }

  public updateSortParameter(userFilterResponse: UserFilterResponse, searchFields: string[]): UrlParameter {

    if (!userFilterResponse) {
      return null;
    }

    const userFilterSortParameter: UserFilterParameter = !!userFilterResponse.filters ? _(userFilterResponse.filters)
      .find(fp => !fp.filterParameterName.endsWith('Facet') && !searchFields.includes(fp.filterParameterName)) : null;

    if (!userFilterSortParameter) {
      return null;
    }

    const key: string = userFilterSortParameter.filterParameterName;
    let value: string = userFilterSortParameter.filterParameterValue;
    value = JSON.parse(value);
    return new UrlParameter(key, value);
  }

  public updateFacetSearchParameters(userFilterResponse: UserFilterResponse): UrlParameter[] {

    if (!userFilterResponse) {
      return null;
    }

    const userFilterParameters: UserFilterParameter[] = !!userFilterResponse.filters ? _(userFilterResponse.filters)
      .filter(fp => fp.filterParameterName.endsWith('Facet')).value() : [];

    return this.updateParameters(userFilterParameters);
  }

  public updateSearchParameters(userFilterResponse: UserFilterResponse, searchFields: string[]): UrlParameter[] {

    if (!userFilterResponse) {
      return null;
    }

    //foreach search field, get the corresponding filter parameter
    const userFilterParameters: UserFilterParameter[] = !!userFilterResponse.filters ? _(userFilterResponse.filters)
      .filter(fp => searchFields.includes(fp.filterParameterName)).value() : [];

    return this.updateParameters(userFilterParameters);
  }
 
  private updateParameters(userFilterParameters: UserFilterParameter[]): UrlParameter[] {
    return _(userFilterParameters).map(p => this.urlParameterService.convertUserFilterParamToUrlParam(p)).value();
  }


}
