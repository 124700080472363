<div *ngIf="findCategoryFormulations()">
  <h4>{{ findFormulation()?.name | localizedStringsFilter }} {{ 'literal.is.based.on' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findCategoryFormulations()"
      [typeahead]="categoryTypeaheadIsBasedOn"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_pgs'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      [itemVisibilityCriteria]="isProductGroupValid"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>

  <h4>{{ findFormulation()?.name | localizedStringsFilter }} {{ 'literal.results.in' | translate }}</h4>
  <div class="row">

    <multiple-value-selector class="form-group col-12"
      [values]="findCategoryFormulations()"
      [typeahead]="categoryTypeaheadResultsIt"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'material_application'"
      [resultTemplate]="categoryResultTemplate"
      [valueTemplate]="categoryValueTemplate"
      [executeBeforeAdd]="addValue"
      [executeBeforeDelete]="deleteValue"
      [itemVisibilityCriteria]="isApplicationValid"
      deleteConfirmTitle="title.confirm.remove"
      deleteConfirmText="text.material.category.deletion">
    </multiple-value-selector>

  </div>
</div>

<ng-template #categoryValueTemplate let-result="result">
  <typeahead-template-category-relatives [model]="result.category"></typeahead-template-category-relatives>
</ng-template>

<ng-template #categoryResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-relatives [model]="result" [term]="term" [showCategoryLink]="false"></typeahead-template-category-relatives>
</ng-template>
