import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import InjectIsAdmin from '../../decorator/inject-is-admin.decorator';
import InjectIsAdvanced from '../../decorator/inject-is-advanced.decorator';

@Component({
  selector: 'app-appnavigation',
  templateUrl: './appnavigation.component.html',
  styleUrls: ['./appnavigation.component.less']
})
export class AppnavigationComponent implements OnInit {

  @InjectIsAdmin
  public isAdmin: Observable<boolean>;

  @InjectIsAdvanced
  public isAdvanced: Observable<boolean>;

  public materialsActive: boolean = false;
  public materialsAdministrationActive: boolean = false;
  public documentsActive: boolean = false;
  public documentsAdministrationActive: boolean = false;
  public phoActive: boolean = false;
  public formulationsActive: boolean = false;
  public advancedToolsActive: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe((q: Params) => {
      this.setActiveRouterLink(this.router.url);
    });
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        this.setActiveRouterLink(val.url);
      }
    });
  }

  // Those options that have different links for the search and each item need to use this method
  // Depending on how the link is used it will be called from router or route (or both)
  public setActiveRouterLink(url: string): void{
      this.materialsActive = url.startsWith('/material') || url.includes('cancelUrl=%2Fmaterial');
      this.materialsAdministrationActive = url.startsWith('/adminstration/material');
      this.documentsActive = url.startsWith('/document') && !url.includes('cancelUrl=%2Fmaterial');
      this.documentsAdministrationActive = url.startsWith('/adminstration/document');
      this.phoActive = url.startsWith('/product-hierarchy-object');
      this.advancedToolsActive = this.isAdvancedUrl(url);
  }

  private isAdvancedUrl(url: string): boolean {
    return url.startsWith('/adminstration/advanced') || 
        url.startsWith('/adminstration/mass-import') || 
        url.startsWith('/adminstration/report') ||
        url.startsWith('/adminstration/document-creation');
  }
}
