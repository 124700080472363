import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'typeahead-template-pho-internal-name-link',
  templateUrl: './pho-internal-name-link.html',
  styleUrls: ['./pho-internal-name-link.less']
})
export class TypeaheadPhoInternalNameWithLinkTemplate {

  @Input() 
  public model: any;

  constructor(private router: Router) {}
  
  navigateToLink(link: string): void{
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([link]);
    });
}
}
