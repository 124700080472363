import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { BusinessHierarchy } from '../../../../model/business-hierarchy';
import { CategoryType } from '../../../../model/category-type';
import { Document } from '../../../../model/document/document';
import { Material } from '../../../../model/material';
import { ProductHierarchyObject } from '../../../../model/product-hierarchy-object/product-hierarchy-object';
import { ProductHierarchyObjectNode } from '../../../../model/document-tab-relation/product-hierarchy-object-node';
import { Pageable } from '../../../../model/pageable';
import { Category } from '../../../../model/category';
import { BusinessHierarchyTypeaheadService } from '../../../../service/typeahead/business-hierarchy-typeahead.service';
import { CategoryByTypeTypeaheadService } from '../../../../service/typeahead/category-by-type-typeahead.service';
import { PhoWithoutDescendantsTypeaheadService } from '../../../../service/typeahead/pho/pho-without-descendants-typeahead.service';
import { PhoTypeaheadService } from '../../../../service/typeahead/pho/pho-typeahead.service';
import { MaterialTypeaheadService } from '../../../../service/typeahead/material-typeahead.service';
import { MaterialService } from '../../../../service/material.service';
import { CategoryOnLeafLevelTypeaheadService } from '../../../../service/typeahead/category-on-leaf-level-typeahead.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import {DocumentEditDataService} from '../../../../service/data-service/document-edit-data.service';

@Component({
  selector: 'document-tab-relation',
  templateUrl: './document-tab-relation.component.html',
  styleUrls: ['./document-tab-relation.component.less']
})
export class DocumentTabRelationComponent implements OnInit, OnDestroy {

  private materialsOfProductHierarchyObjects: Material[];
  private leafCategories = new Map();
  private documentLoadedSubscription: Subscription = null;

  public get document(): Document {
    return this.documentEditDataService.documentBehaviorSubject.value;
  }

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public readonly = false;

  @Input()
  private linkedReadOnlyMaterials: Material[] = [];

  @Output()
  public productHierarchyObjectMaterialsChange: EventEmitter<ProductHierarchyObject[]> = new EventEmitter<any[]>();

  @Output()
  public productHierarchyObjectsChange: EventEmitter<ProductHierarchyObject[]> = new EventEmitter<any[]>();

  constructor(
    private readonly documentEditDataService: DocumentEditDataService,
    private materialTypeaheadService: MaterialTypeaheadService,
    private businessHierarchyTypeaheadService: BusinessHierarchyTypeaheadService,
    private categoryOnLeafLevelTypeaheadService: CategoryOnLeafLevelTypeaheadService,
    private categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private phoWithoutDescendantsTypeaheadService: PhoWithoutDescendantsTypeaheadService,
    private phoTypeaheadService: PhoTypeaheadService,
    private materialService: MaterialService
  ) {}

  ngOnInit() {
    this.documentLoadedSubscription = this.documentEditDataService.documentBehaviorSubject.asObservable().subscribe(d => {
      this.initializeMaterialsOfProductHierarchyObjects();
      this.initiateCategoryLeafMap();
    });
  }

  ngOnDestroy() {
    this.documentLoadedSubscription.unsubscribe();
  }

  onDocumentCategoryChange(categories: Category[]): void {}

  public getLeafCategories(): Category[] {
    const result = [];

    if (this.document.categories.length <= 1) {
      return this.document.categories;
    }

    this.document.categories.forEach(c => {
        if (this.leafCategories.has(c.id) && this.leafCategories.get(c.id)) {
          result.push(c);
        }
      });

    return result;
  }

  addWholeCategoryTree = (category: Category) => {
    this.createAndAddCategory(category, this.document.categories);
    this.addToLeafCategory(category, true);
  };

  private createAndAddCategory(category: Category, categories: Category[]) {
    if (!this.categoryExistsForCategory(categories, category)) {
      categories.push(category);
    }
    if (category.parent) {
      this.createAndAddCategory(category.parent, categories);
    }
  }

  private categoryExistsForCategory(categories: Category[], category: Category): boolean {
    for (let i = 0, max = categories.length; i < max; i++) {
      if (categories[i].id === category.id) {
        return true;
      }
    }
    return false;
  }

  private getCategoryHierarchy(category: Category, categoryList : Category[])  {
    categoryList.push(category)
    if (!!category.parent) {
      this.getCategoryHierarchy(category.parent, categoryList);
    }
  }

  private presentInCategories(currentCategory: Category, categoryToFind: Category) : boolean {
    let result = false;
    this.document.categories.forEach(c => {
      if (this.leafCategories.get(c.id) && c !== currentCategory) {
        const categoriesList = [];
        this.getCategoryHierarchy(c,categoriesList);
        const indexToRemove = categoriesList.findIndex((category: Category) => {
          return category.id === categoryToFind.id;
        });
        if (indexToRemove !== -1) {
          result = true;
        }
      }
    });
    return result;
  }

  public deleteWholeCategoryTree = (categories: Category[], category: Category) => {
    this.removeCategoryIfNotInUse(category, category, this.document.categories);
    this.initiateCategoryLeafMap();
  }

  private removeCategoryIfNotInUse(childCategory: Category, categoryToRemove: Category, categories: Category[]) {
    if (!this.presentInCategories(childCategory, categoryToRemove)) {
      const indexToRemove = categories.findIndex((category: Category) => {
        return category.id === categoryToRemove.id;
      });
      categories.splice(indexToRemove, 1);
      if (categoryToRemove.parent) {
        this.removeCategoryIfNotInUse(childCategory, categoryToRemove.parent, categories);
      }
    }
  }

  public regionTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
      [CategoryType.REGION], this.document.regions.map(s => s.id));

  public industryTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
      [CategoryType.INDUSTRY], this.document.industries.map(s => s.id));

  public categoriesTypeahead = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
      [CategoryType.APPLICATION, CategoryType.PRODUCT_GROUP, CategoryType.PROCESS, CategoryType.SOLUTION, CategoryType.SERVICE],
      this.document.categories.map(s => s.id));

  public odTypeahead = (searchTerm: string, pageable: Pageable) => this.businessHierarchyTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.document.operatingDivisions.map(s => s.id));
  public odTypeaheadFormatter = (model: BusinessHierarchy) => this.businessHierarchyTypeaheadService.typeaheadFormatter(model);

  public productHierarchyObjectMaterialsTypeahead = (searchTerm: string, pageable: Pageable) => this.phoWithoutDescendantsTypeaheadService.typeaheadSearch(
    searchTerm, pageable, this.document.productHierarchyObjectMaterials.map(s => s.id));

  public productHierarchyObjectTypeahead = (searchTerm: string, pageable: Pageable) => this.phoTypeaheadService.typeaheadSearch(
    searchTerm, pageable, this.document.productHierarchyObjects.map(s => s.id));

  public materialTypeahead = (searchTerm: string, pageable: Pageable) => this.materialTypeaheadService.typeaheadSearch(searchTerm, pageable,
    this.document.materials.map(s => s.id));
  public materialTypeaheadFormatter = (model: Material) => this.materialTypeaheadService.typeaheadFormatter(model);

  onProductHierarchyObjectsChange(productHierarchyObjects: ProductHierarchyObject[]): void {
    this.documentEditDataService.documentBehaviorSubject.value.productHierarchyObjects = productHierarchyObjects;
    this.productHierarchyObjectsChange.emit(productHierarchyObjects);
  }

  onProductHierarchyObjectMaterialsChange(productHierarchyObjects: ProductHierarchyObject[]): void {
    this.updateMaterialsOfProductHierarchyObjects(productHierarchyObjects)
    this.documentEditDataService.documentBehaviorSubject.value.productHierarchyObjectMaterials = productHierarchyObjects;
    this.productHierarchyObjectMaterialsChange.emit(productHierarchyObjects);
  }

  private initializeMaterialsOfProductHierarchyObjects(): void {
    this.updateMaterialsOfProductHierarchyObjects(this.document.productHierarchyObjectMaterials);
  }

  private updateMaterialsOfProductHierarchyObjects(phos: ProductHierarchyObject[]): void {
    this.materialService.loadByProductHierarchyObjects(phos)
      .subscribe((materials: Material[]) => this.materialsOfProductHierarchyObjects = materials);
  }

  getProductHierarchyObjectMaterials(): ProductHierarchyObject[] {
    return _(this.documentEditDataService.documentBehaviorSubject.value.productHierarchyObjectMaterials)
        .map(this.createPhoNode)
        .orderBy(['level'], ['asc'])
        .uniqBy('pho.internalName')
        .map('pho')
        .value();
  }

  private createPhoNode(pho: ProductHierarchyObject): ProductHierarchyObjectNode {
    let root: ProductHierarchyObject = pho;
    let level: number = 0;
    while (!!root.parent) {
      level++;
      root = root.parent;
    }
    return new ProductHierarchyObjectNode(root, pho, level);
  }

  getMaterials(): Material[] {
    const documentMaterials: Material[] = !!this.document.materials ? this.document.materials : [];
    const materialsOfPhos: Material[] = !!this.materialsOfProductHierarchyObjects ? this.materialsOfProductHierarchyObjects : [];
    materialsOfPhos.forEach(material => material.isReadOnly = true);
    this.linkedReadOnlyMaterials.forEach(material => material.isReadOnly = true);

    const allMaterials = _(materialsOfPhos.concat(documentMaterials))
      .orderBy(['isReadOnly'], ['desc'])
      .value();

    return this.linkedReadOnlyMaterials.concat(allMaterials);
  }

  public materialMultipleValueSelectorChange(materials: Material[]): void {
    this.document.materials = materials;
  }

  onMaterialMultipleValueSelectorValueAdded(material: Material): void {
    this.document.materials.push(material);
  }

  onMaterialMultipleValueSelectorValueRemoved(material: Material): void {
    this.document.materials = this.document.materials.filter(m => m.id !== material.id);
  }

  private initiateCategoryLeafMap() {
    this.leafCategories.clear();
    this.document.categories.forEach(c => {
      this.addToLeafCategory(c, true);
    });
  }

  private addToLeafCategory(category: Category, possibleLeaf: boolean = false) {
    if (!possibleLeaf && this.leafCategories.has(category.id) && this.leafCategories.get(category.id)) {
      this.leafCategories.set(category.id, false);
    }
    if(possibleLeaf && (!this.leafCategories.has(category.id) || this.leafCategories.has(category.id) && this.leafCategories.get(category.id))) {
      this.leafCategories.set(category.id, true);
    } else {
      this.leafCategories.set(category.id, false);
    }
    if (category.parent) {
      this.addToLeafCategory(category.parent);
    }
  }
}

