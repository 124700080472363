import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractTypeaheadService } from '../abstract-typeahead.service';
import { Slice } from '../../../model/pim-response/slice';
import { ProductHierarchyObject } from '../../../model/product-hierarchy-object/product-hierarchy-object';
import { Pageable } from '../../../model/pageable';
import { TypeaheadExcludeIdRequest } from '../../../model/request/typeahead-exclude-id-request';
import { BaseTypeaheadService } from '../base-typeahead.service';

@Injectable({
  providedIn: 'root'
})
export class PhoTypeaheadService implements AbstractTypeaheadService<ProductHierarchyObject> {

  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<ProductHierarchyObject>> {

    let body = new TypeaheadExcludeIdRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      exclude
    );

    return this.baseTypeaheadService.createCall('/producthierarchyobject/typeahead/v2', body, pageable);
  }

  public typeaheadFormatter(model: ProductHierarchyObject): string {
    return model.internalName;
  }
}
