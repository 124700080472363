import { Realm } from '../../../../model/realm/realm';
import { Component, OnInit} from '@angular/core';
import { Pageable } from '../../../../model/pageable';
import { Formulation } from '../../../../model/formulation';
import { FormulationMarketingType } from '../../../../model/formulation-marketing-type';
import { RealmTypeaheadService } from '../../../../service/typeahead/realm-typeahead.service';
import { FormulationEditDataService } from '../../../../service/data-service/formulation-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'formulation-tab-property',
  templateUrl: './formulation-tab-property.component.html',
  styleUrls: ['./formulation-tab-property.component.less']
})
export class FormulationTabPropertyComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public marketingTypes: string[] = Object.keys(FormulationMarketingType);
  public formulationRealm: Realm;

  constructor(
    private readonly realmTypeaheadService: RealmTypeaheadService,
    private readonly formulationEditDataService: FormulationEditDataService
  ) { }

  ngOnInit(): void {
  }

  public realmTypeahead = (searchString: string, pageable: Pageable) => this.realmTypeaheadService.typeaheadSearch(searchString, pageable);
  public realmTypeaheadFormatter = (model: Realm) => this.realmTypeaheadService.typeaheadFormatter(model);
  public findFormulation = (): Formulation => this.formulationEditDataService.findFormulation();
}
