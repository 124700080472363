import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentEditDataService } from '../../../../service/data-service/document-edit-data.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { Document } from '../../../../model/document/document';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import {DocumentVersionDataService} from '../../../../service/data-service/document-version-data.service';
import {DocumentVersion} from '../../../../model/document-version/document-version';
import { DocumentVersionStatus } from '../../../../model/document-version/document-version-status';

@Component({
  selector: 'document-tab-version',
  templateUrl: './document-tab-version.component.html'
})
export class DocumentTabVersionComponent implements DoCheck, OnInit, OnDestroy {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public documentVersionId: string = '';
  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly documentEditDataService: DocumentEditDataService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get document(): BehaviorSubject<Document> {
    return this.documentEditDataService.documentBehaviorSubject;
  }

  // Using versionOrigBehaviourSubject to avoid that, when deleting files, the add buttons could change the name
  public get versionOrigBehaviorSubject(): BehaviorSubject<DocumentVersion> {
    return this.documentVersionDataService.versionOrigBehaviorSubject;
  }

  ngOnInit(): void {
    if (!this.versionOrigBehaviorSubject.getValue()) {
      this.checkForDeepLinkNavigation();
    }
  }

  private checkForDeepLinkNavigation() {
    this.documentVersionId = this.route.snapshot.queryParamMap.get('version');
    if (!!this.documentVersionId) {
      this.subscriptions.add(this.document.subscribe(document => {
        if (!!document) {
          this.documentVersionDataService.initDocumentVersionWithExistingId(this.documentVersionId);
        }
      }))
    }
  }

  ngDoCheck() {
    this.documentVersionId = this.route.snapshot.queryParamMap.get('version');
  }

  public showOverview() {

    if(!this.documentVersionDataService.documentVersionBehaviorSubject.getValue() && !this.documentVersionId ) {
      return true;
    }

    return false;
  }

  public saveVersion() {
    this.documentVersionDataService.saveVersion();
  }

  public saveAndPublish() {
    this.documentVersionDataService.saveAndPublish();
  }

  public cancelVersion() {
    return this.documentVersionDataService.cancelVersion();
  }

  public isDocumentVersionPublished(documentVersion: DocumentVersion): boolean{
    return documentVersion.status === DocumentVersionStatus.PUBLISHED;
  }
}
