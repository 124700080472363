<div class="container-fluid pb-3">
  <h2 class="display-6 fw-normal">{{ 'label.administration.report.headline' | translate }}</h2>

  <app-table [page]="reportList" (rowClick)="downloadReport($event)" (pageableHandler)="switchReportPage($event)">
    <app-table-column name="{{ 'label.administration.report.name' | translate }}" field="name" [template]="link"></app-table-column>
    <app-table-column name="{{ 'label.administration.report.generation_date' | translate }}" field="completed"></app-table-column>
  </app-table>

</div>

<ng-template #link let-value>
  <ng-container *ngIf="value.name">
    <a class="file-link" [href]="downloadReport(value)" (click)="$event.stopPropagation();">{{ value.name }}</a>
    <span>&nbsp;</span>
    <a [href]="downloadReport(value)" (click)="$event.stopPropagation();">
      <fa-icon icon="download" class="fa-solid fa-download"></fa-icon>
    </a>
  </ng-container>
</ng-template>
