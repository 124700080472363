import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, Observable, tap, throwError } from 'rxjs';
import { UrlParameter } from '../../model/parameter/url-parameter';
import { UserFilterParameter } from '../../model/user-filter/user-filter-parameter';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';
import { UserFilterSaveRequest } from '../../model/user-filter/user-filter-save-request';
import { UserFilterRenameRequest } from '../..//model/user-filter/user-filter-rename-request';
import { UserFilterBackendService } from './user-filter-backend.service';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserFilterDataService {

  private _userFiltersInitializedBehaviourSubject = new BehaviorSubject<Boolean>(false);
  private _userFiltersBehaviourSubject = new BehaviorSubject<UserFilterResponse[]>(null);
  private _filterBehaviourSubject: BehaviorSubject<UserFilterResponse> = new BehaviorSubject(null);
  private _filterTypeBehaviourSubject: BehaviorSubject<string> = new BehaviorSubject("");
  
  private _searchParameters: UrlParameter[];
  private _sortParameter: UrlParameter;
  private _facetSearchParameters: UrlParameter[];

  constructor(
    private userFilterBackendService: UserFilterBackendService) {
  }

  public get userFiltersInitializedBehaviourSubject(): BehaviorSubject<Boolean> {
    return this._userFiltersInitializedBehaviourSubject;
  }

  public get userFiltersBehaviourSubject(): BehaviorSubject<UserFilterResponse[]> {
    return this._userFiltersBehaviourSubject;
  }

  public get userFilterBehaviourSubject(): BehaviorSubject<UserFilterResponse> {
    return this._filterBehaviourSubject;
  }

  public get filterTypeBehaviourSubject(): BehaviorSubject<string> {
    return this._filterTypeBehaviourSubject;
  }

  public clearUserFiltersInitializedBehaviourSubject(){
    this._userFiltersInitializedBehaviourSubject.next(false);
  }

  public deleteFilter(userFilterResponse: UserFilterResponse): Observable<UserFilterResponse> {
    return this.userFilterBackendService.deleteFilterRequest(userFilterResponse).pipe(tap((userFilterResponse) => {
      lastValueFrom(this.listFiltersAndSetDefaultFilter());
    }));
  }

  public setDefaultFilter(userFilterResponse: UserFilterResponse): Observable<UserFilterResponse> {
    return this.userFilterBackendService.setAsDefaultFilterRequest(userFilterResponse.filterId).pipe(tap((userFilterResponse) => {
      lastValueFrom(this.listFiltersAndSetDefaultFilter());
    }));
  }

  public renameFilter(userFilterRenameRequest: UserFilterRenameRequest): Observable<UserFilterResponse> {
    return this.userFilterBackendService.renameFilterRequest(userFilterRenameRequest).pipe(tap((userFilterResponse) => {
      const userFilter: UserFilterResponse = this._userFiltersBehaviourSubject.getValue().find(filter => filter.filterId === userFilterResponse.filterId);
      // default filter was locally lost after renaming (that info is not caming back in the response of the renaming request)
      userFilterResponse.defaultFilter = userFilter.defaultFilter;
      this._userFiltersBehaviourSubject.next(this._userFiltersBehaviourSubject.getValue().map(filter => filter.filterId === userFilterResponse.filterId ? userFilterResponse : filter));
      this._filterBehaviourSubject.next(userFilterResponse);
    }));
  }

  public saveFilter(userFilterSaveRequest: UserFilterSaveRequest): Observable<UserFilterResponse> {
    return this.userFilterBackendService.saveFilterRequest(userFilterSaveRequest).pipe(tap((userFilterResponse) => {
      lastValueFrom(this.listFiltersAndSetFilter(userFilterResponse));
    }));
  }
  
  public listFiltersAndSetDefaultFilter(): Observable<UserFilterResponse[]> {
    // Initialize filter list to null to refresh the list between materials and administration/materials and avoid the first one to be selected
    this._userFiltersBehaviourSubject.next(null);
    return this.userFilterBackendService.listFiltersRequest(this._filterTypeBehaviourSubject.getValue())
      .pipe(tap((filters : UserFilterResponse[]) => {
        this._userFiltersBehaviourSubject.next(filters);
        let userFilterResponse : UserFilterResponse = !!filters
          ? filters.find(filter => filter !== null && filter.defaultFilter)
          : null;
        this._filterBehaviourSubject.next(userFilterResponse);
        this._userFiltersInitializedBehaviourSubject.next(true);
      }),
      catchError((response: HttpErrorResponse, caught: Observable<any>) => this.setDefaultEmptyFilters(response, caught)));
      
  }

  public listFiltersAndSetFilter(userFilterResponse: UserFilterResponse): Observable<UserFilterResponse[]> {
    return this.userFilterBackendService.listFiltersRequest(this._filterTypeBehaviourSubject.getValue())
        .pipe(tap((filters : UserFilterResponse[]) => {
          this._userFiltersBehaviourSubject.next(filters);
          const userFilterResponseFromFilter = filters.find(filter => filter !== null && filter.filterId === userFilterResponse.filterId);
          this._filterBehaviourSubject.next(userFilterResponseFromFilter);
          this._userFiltersInitializedBehaviourSubject.next(true);
        }));
  }

  public changeFilterType(filterType: string): void {
    if (filterType !== this._filterTypeBehaviourSubject.getValue()) {
      this._filterTypeBehaviourSubject.next(filterType);
      this._userFiltersBehaviourSubject.next(null);
      this._filterBehaviourSubject.next(null);
    }
  }

  public changeFilter(userFilterResponse: UserFilterResponse): void {
    this._filterBehaviourSubject.next(userFilterResponse);
  }

  public changeToDefaultFilter(): void {
    // use the default filter from _userFiltersBehaviourSubject or else null
    const userFilterResponse = !!this._userFiltersBehaviourSubject.getValue() 
          ? this._userFiltersBehaviourSubject.getValue().find(filter => filter !== null && filter.defaultFilter)
          : null;

    return this.changeFilter(!!userFilterResponse? userFilterResponse : null);
  }

  public clearFilter(): void {
    this._filterBehaviourSubject.next(null);
  }

  public updateParameters(searchParameters: UrlParameter[], sortParameter: UrlParameter, facetSearchParameters: UrlParameter[]){
    this._searchParameters = searchParameters;
    this._sortParameter = sortParameter;
    this._facetSearchParameters = facetSearchParameters;
  }

  public getUserFilterParameters(): UserFilterParameter[] {
    const merged: UrlParameter[] = (this._facetSearchParameters || []).concat((this._sortParameter || [])).concat(this._searchParameters || []);
    const filterParameters: UserFilterParameter[] = this.toUserFilterParameters(merged);
    return filterParameters;
  }

  private toUserFilterParameters(parameters: UrlParameter[]): UserFilterParameter[] {
      return (parameters || []).map(p => this.toUserFilterParameter(p));
    }

  private toUserFilterParameter(parameter: UrlParameter): UserFilterParameter {
    const filterParameterName: string = parameter.field;
    const filterParameterValue: string = JSON.stringify(parameter.value);
    return new UserFilterParameter(filterParameterName, filterParameterValue);
  }

  private setDefaultEmptyFilters(response: HttpErrorResponse, caught: Observable<any>): any {
    this._userFiltersBehaviourSubject.next([]);
    this.changeFilter(null);
    this._userFiltersInitializedBehaviourSubject.next(true);
    return throwError(() => caught);
  }
  
}
