import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { formatDate } from '@angular/common';
import { Pageable } from '../../../../../model/pageable';
import { Page } from '../../../../../model/pim-response/page';
import { DocumentVersion } from '../../../../../model/document-version/document-version';
import { Category } from '../../../../../model/category';
import { Language } from '../../../../../model/language';
import { ConfirmService } from '../../../../../service/confirm.service';
import { GuidService } from '../../../../../service/tools/guid.service';
import { LocalizedStringService } from '../../../../../service/localized-string.service';
import InjectIsReadonlyUser from '../../../../../decorator/inject-is-readonly-user.decorator';
import { environment } from '../../../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentEditDataService } from '../../../../../service/data-service/document-edit-data.service';
import { Document } from '../../../../../model/document/document';
import { DocumentVersionDataService } from '../../../../../service/data-service/document-version-data.service';
import { DocumentVersionStatus } from '../../../../../model/document-version/document-version-status';

@Component({
  selector: 'document-tab-version-overview',
  templateUrl: './document-tab-version-overview.component.html',
  styleUrls: ['./document-tab-version-overview.component.less']
})
export class DocumentTabVersionOverviewComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public showAddButton: boolean = true;

  @Input()
  public isReadOnly: boolean = false;

  @Output()
  public afterSelectVersionExpired = new EventEmitter<DocumentVersion>();

  @Output()
  public afterSelectVersionFuture = new EventEmitter<DocumentVersion>();

  @Output()
  public afterSelectVersionCurrent = new EventEmitter<DocumentVersion>();

  public rowsFuture: Page<DocumentVersion> = new Page<DocumentVersion>();
  public rowsCurrent: Page<DocumentVersion> = new Page<DocumentVersion>();
  public rowsExpired: Page<DocumentVersion> = new Page<DocumentVersion>();
  public pageable: Pageable = new Pageable();
  public params: Params;
  public cancelUrl: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly confirmService: ConfirmService,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly guid: GuidService,
    private readonly localizedStringService: LocalizedStringService
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      this.cancelUrl = params?.cancelUrl;
    });
  }

  public get isDocBridgeDocument(): BehaviorSubject<boolean> {
    return this.documentEditDataService.docBridgeBehaviorSubject;
  }

  public get document(): BehaviorSubject<Document> {
    return this.documentEditDataService.documentBehaviorSubject;
  }

  ngOnInit() {
    this.documentEditDataService.documentBehaviorSubject.asObservable().subscribe(result => this.setVersionPage());
    this.updateDatatable();
  }

  /* setup document version data table */
  public setVersionPage(event: any = null) {

    const now = new Date();

    this.rowsFuture = new Page<DocumentVersion>();
    this.rowsCurrent = new Page<DocumentVersion>();
    this.rowsExpired = new Page<DocumentVersion>();

    // Fixed sort by validUntil and validFrom
    this.documentEditDataService.documentBehaviorSubject.value.versions.sort((a, b) => {
      return new Date(b.validUntil).getTime() - new Date(a.validUntil).getTime()
          || new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime();
    });
    this.documentEditDataService.documentBehaviorSubject.value.versions.forEach(function (value) {
      const validFrom = new Date(value.validFrom);
      const validUntil = new Date(value.validUntil);
      if (validFrom < now && validUntil <= now) {
        this.rowsExpired.content.push(value);
      } else if (validFrom < now && validUntil > now) {
        this.rowsCurrent.content.push(value);
      } else {
        this.rowsFuture.content.push(value);
      }
    }, this);

    this.calculatePageValues(this.rowsFuture);
    this.calculatePageValues(this.rowsCurrent);
    this.calculatePageValues(this.rowsExpired);
  }

  private calculatePageValues(rows: Page<any>) {
    rows.hasContent = rows.content.length > 0;
    rows.totalElements = rows.content.length;
  }

  private deleteVersion(documentVersion: DocumentVersion): void {
    this.documentEditDataService.deleteVersion(documentVersion);
    this.setVersionPage();
    this.updateDatatable();
  }

  private updateDatatable(): void {
    this.rowsFuture.content = [...this.rowsFuture.content];
    this.rowsCurrent.content = [...this.rowsCurrent.content];
    this.rowsExpired.content = [...this.rowsExpired.content];
  }

  public selectVersionExpired = (selected: DocumentVersion) => {
    this.selectVersion(selected).then(() => {
      this.afterSelectVersionExpired.emit(selected);
    });
  }

  public selectVersionFuture = (selected: DocumentVersion) => {
    this.selectVersion(selected).then(() => {
      this.afterSelectVersionFuture.emit(selected);
    });
  }

  public selectVersionCurrent = (selected: DocumentVersion) => {
    this.selectVersion(selected).then(() => {
      this.afterSelectVersionCurrent.emit(selected);
    });
  }

  public newVersion() {
    const today = new DocumentVersion();
    today.validFrom = new Date();
    this.documentVersionDataService.documentVersionBehaviorSubject.next(today);
    return this.navigateToEditView('add');
  }

  public selectVersion(selected: DocumentVersion) {
    this.documentVersionDataService.documentVersionBehaviorSubject.next(selected);
    return this.navigateToEditView(!selected.id ? 'editNew' : selected.id);
  }

  private navigateToEditView(versionParam: string) {
    return this.router.navigate( [], { queryParams: { version: versionParam }, queryParamsHandling: 'merge' });
  }

  // Format languages for version lists
  public versionLanguages = (versionLang: Language[]): string => {
    const languages = [];
    if (versionLang) {
      versionLang.forEach(language => {
        languages[this.localizedStringService.getLocalizedStringsValue(language.name)] = true;
      });
      return Object.keys(languages).join(', ');
    }
    return '';
  }

  // Format language for change reason
  public reasonLanguage = (changeReason: Category): string => {
    if (changeReason) {
      return this.localizedStringService.getLocalizedStringsValue(changeReason.name);
    }
    return '';
  }

  // Format date for version lists
  public formatDate = (value: Date): string => {
    return formatDate(value, 'yyyy-MM-dd', 'en-US'); // ToDo: Locale should not be hardcoded
  }

  public openDocumentVersionDeletionDialog(documentVersion: DocumentVersion): void {
    this.confirmService.confirm('title.document.version.remove', 'text.document.version.remove', 'button.yes', 'button.no').then(deleteDocumentVersion => {
      if (deleteDocumentVersion) {
        this.deleteVersion(documentVersion);
      }
    });
  }

  public expireNow(documentVersion: DocumentVersion): void {
    this.documentEditDataService.expireVersion(documentVersion);
    this.setVersionPage();
    this.updateDatatable();
  }

  public downloadLink(documentVersion: DocumentVersion): string {
    return environment.restUrl + '/document/download/' + documentVersion.file.id;
  }

  public previewLink(documentVersion: DocumentVersion) {
    return environment.restUrl + '/document/preview/' + documentVersion.file.id;
  }

  public isVersionSaved(versionId: string) {
    if(versionId && !this.guid.isGuid(versionId)) {
      return true;
    }
    return false
  }

  public isVersionPublished(documentVersion: DocumentVersion): boolean{
    return documentVersion.status === DocumentVersionStatus.PUBLISHED;
  }
}
