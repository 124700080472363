import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomView } from '../model/custom-view/custom-view';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constants } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class CustomViewUsageService {


  constructor(
    private httpClient: HttpClient
  ) {}

  public isTemplateViewInUse(customView: CustomView): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.restUrl}/customview/usage/${customView.id}`, Constants.httpOptionsHidingLoadingIndicator);
  }
}
