<ng-template #materialIdTemplate let-row>
    <span class="material-id-template" (click)="$event.stopPropagation()">
      <a class="link" href="#" [routerLink]="['/'+editComponentUrl+'/'+row.id]">{{row.id}}&nbsp;</a>
      <a class="link" href="#" [routerLink]="['/'+editComponentUrl+'/'+row.id]" target="_blank">
        <fa-icon [styles]="" icon="external-link-alt" class="fa-sg"></fa-icon>
      </a>
    </span>
</ng-template>

<app-overview-view [tableColumns]="tableColumns"
  [title]="title"
  [searchFunction]="searchFunction"
  [facetFields]="facetFields"
  [addAllowed]="false"
  [overviewValueTemplate]="overviewValueTemplate"
  [customButtons]="customButtons"
  [editComponentUrl]="editComponentUrl"
  searchFields="materialName"
  typeaheadFieldLabelPrefix="typeaheadFieldLabelPrefix"
  predefinedValueTemplate="materialOverviewValueTemplate"
  [placeholder]="'placeholder.search.text' | translate">
</app-overview-view>


<ng-template #overviewValueTemplate let-r="result" let-t="term">
  <span *ngIf="!(isIdOrName(r.highlights))">
    <span>{{ r.name | localizedStringsFilter }}</span>
    <span *ngFor="let highlightedMapEntry of r.highlights; index as i;">
      <span>, </span>
      <ngb-highlight [result]="highlightedMapEntry.value" [term]="t"></ngb-highlight>
      <small>
        <i class="highlighted-result"> [{{typeaheadFieldLabelPrefix + highlightedMapEntry.field | translate}}]</i>
      </small>
    </span>
  </span>
  <span *ngIf="isIdOrName(r.highlights)" >
    <ngb-highlight [result]="r.name | localizedStringsFilter" [term]="t"></ngb-highlight>
    <small>
      <i class="highlighted-result"> [{{typeaheadFieldLabelPrefix + 'name' | translate}}]</i> (<ngb-highlight [result]="r.id" [term]="t"></ngb-highlight>)
    </small>
  </span>
</ng-template>
