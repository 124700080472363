import { LocalizedString } from './localized-string';
import { Realm } from './realm/realm';
import { Category } from './category';
import { FormulationMarketingType } from './formulation-marketing-type';
import { FormulationPart } from './formulation-part';

export class Formulation {
    id: string;
    formulationReference: string;
    name: LocalizedString[] = [];
    industries: Category[] = [];
    marketingType: FormulationMarketingType;
    description: LocalizedString[] = [];
    parts: FormulationPart[] = [];
    realm: Realm;
}
