import { Component } from '@angular/core';
import { Material } from '../../../../model/material';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import { Observable } from 'rxjs';
import { BusinessHierarchyNode } from '../../../../model/business-hierarchy/business-hierarchy-node';
import { MaterialStatusConversion } from '../../../../model/material-status-conversion';

@Component({
  selector: 'material-tab-spass-view',
  templateUrl: './material-tab-spass-view.component.html',
  styleUrls: ['./material-tab-spass-view.component.less']
})
export class MaterialTabSpassViewComponent {

  constructor(private readonly materialEditDataService: MaterialEditDataService) {}

  public getMaterial = (): Material => this.materialEditDataService.findMaterial();

  public getBusinessHierarchy = (): Observable<BusinessHierarchyNode> => this.materialEditDataService.getBusinessHierarchy();

  public formatSpassStatus(status: string): string {
    let returnValue: string = MaterialStatusConversion.CONSISTENT.getText();
    if(!!status && typeof status === 'string') {
      if (!MaterialStatusConversion[status]) {
        console.log(new Error(`formatSpassStatus: MaterialStatus for ${status} is not defined`));
        return 'UNKNOWN_STATUS';
      }
      returnValue = MaterialStatusConversion[status].getText();
      if (MaterialStatusConversion[status].getType() !== '') {
        returnValue = returnValue + ' (' + MaterialStatusConversion[status].getType() + ')';
      }
    }
    return returnValue;
  }

}
