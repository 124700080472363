import { Attribute } from './attribute';
import { Category } from './category';

export class CategoryAttribute {

    constructor(
      public id: string,
      public category: Category,
      public attribute: Attribute) {}

}
