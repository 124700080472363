import { AttributeRelevance } from './attribute-relevance';
import { AttributeType } from './attribute-type';
import { LocalizedString } from './localized-string';
import { Unit } from './unit';
import { LocalizedEnumerationValue } from './localized-enumeration-value';
import { Category } from './category';
import { AttributeCluster } from './attribute-cluster';
import { AttributeAttributeParameter } from './attribute-attribute-parameter';
import { SingleChoiceListValue } from "./single-choice-list-value";

export class Attribute {
    id: string;
    name: LocalizedString[] = [];
    shortName: LocalizedString[] = [];
    relationshipTypeLabel: LocalizedString[] = [];
    unit: Unit;
    type: AttributeType;
    attributeRelevance: AttributeRelevance[] = [];
    rangeMax: number;
    enumerationValues: string[] = [];
    singleChoiceListValues: SingleChoiceListValue[] = [];
    xpath: string;
    localizedEnumerationValues: LocalizedEnumerationValue[] = [];
    description: string;
    attributeCluster: AttributeCluster;
    attributeParameters: AttributeAttributeParameter[] = [];
    defaultVisibility: Category = null;
    tooltip: LocalizedString[] = [];
    textLength: number;
}
