<app-overview-view [tableColumns]="tableColumns"
    [title]="'headline.administration.view' | translate"
    [searchFunction]="searchFunction"
    [facetFields]="facetFields"
    [addAllowed]="false"
    [customButtons]="customButtons"
    [overviewValueTemplate]="overviewValueTemplate"
    searchFields="name"
    editComponentUrl="adminstration/view"
    typeaheadFieldLabelPrefix="typeaheadFieldLabelPrefix"
    predefinedValueTemplate="customViewOverviewValueTemplate"
    [placeholder]="'placeholder.search.text' | translate">
</app-overview-view>
    
<div class="row col-mb-3">
  <div class="col-12 align-right">
    <ng-container *ngTemplateOutlet="customButtons"></ng-container>
  </div>
</div>


<ng-template #overviewValueTemplate
  let-r="result"
  let-t="term">
  <span *ngFor="let highlightedMapEntry of r.highlights; index as i;">
    <span *ngIf="i!==0">, </span>
    <ngb-highlight [result]="highlightedMapEntry.value"
      [term]="t"></ngb-highlight>
    <small>
      <i class="highlighted-result"> [{{typeaheadFieldLabelPrefix + highlightedMapEntry.field | translate}}]</i>
    </small>
  </span>
</ng-template>

<ng-template #customButtons>
  <button id="newCustomView" (click)="addCustomView()" class="btn btn-primary">
      <fa-icon icon="plus"></fa-icon> {{'button.add.customview' | translate}}
  </button>
</ng-template>