import { Category } from '../category';
import { LocalizedString } from '../localized-string';
import { DocumentVersion } from '../document-version/document-version';
import { DocumentStatus } from './document-status';
import { Realm } from '../realm/realm';
import { Material } from '../material';
import { User } from '../user';
import { BusinessHierarchy } from '../business-hierarchy';
import { HighlightedMapEntry } from '../pim-response/highlighted-map-entry';
import { ProductHierarchyObject } from '../product-hierarchy-object/product-hierarchy-object';
import { RealmRestrictionType } from '../realm/realm-restriction-type';
import { MetadataVisibilityType } from '../metadata-visibility/metadata-visibility-type';

export class Document {
    id: string;
    name: LocalizedString[] = [];
    description: LocalizedString[] = [];
    versions: DocumentVersion[] = [];
    type: Category;
    securityClass: Category;
    regions: Category[] = [];
    realm: Realm;
    materials: Material[] = [];
    productHierarchyObjects: ProductHierarchyObject[] = [];
    productHierarchyObjectMaterials: ProductHierarchyObject[] = [];
    industries: Category[] = [];
    operatingDivisions:BusinessHierarchy [] = [];
    createdBy: User;
    modifiedBy: User;
    createdDate: Date;
    modifiedDate: Date;
    highlights: HighlightedMapEntry[];
    categories: Category[] = [];
    status: DocumentStatus = DocumentStatus.NOT_PUBLISHED;
    realmRestriction: RealmRestrictionType = RealmRestrictionType.NONE;
    metadataVisibility: MetadataVisibilityType = MetadataVisibilityType.NONE;
}
