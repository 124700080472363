import {Component, Input} from '@angular/core';
import {Category} from '../../../../model/category';
import {AttributeRelevance} from '../../../../model/attribute-relevance';
import {CategoryService} from '../../../../service/category.service';
import {ProductHierarchyObjectService} from '../../../../service/product-hierarchy-object.service';
import {CategoryProductHierarchyObject} from '../../../../model/category-product-hierarchy-object';
import {Attribute} from '../../../../model/attribute';
import * as _ from 'lodash';

@Component({
  selector: 'product-hierarchy-object-tab-attribute-view',
  templateUrl: './product-hierarchy-object-tab-attribute-view.component.html',
  styleUrls: ['./product-hierarchy-object-tab-attribute-view.component.less']
})
export class ProductHierarchyObjectTabAttributeViewComponent {

  @Input()
  public readonly: boolean;

  @Input()
  public visibilityReadonly: boolean;

  @Input()
  public preFilterCategoryPhos = () => true;

  constructor(
    private readonly categoryService: CategoryService,
    private readonly productHierarchyObjectService: ProductHierarchyObjectService
  ) { }

  public findCategoryPhos(): CategoryProductHierarchyObject[] {
    return this.productHierarchyObjectService.findCategoryPhos(this.preFilterCategoryPhos);
  }

  public getCategoryByPhoRelevance(): CategoryProductHierarchyObject[] {
    return this.findCategoryPhos().filter(categoryPho =>
      this.categoryService.hasCategoryAttributesByRelevance(categoryPho.category, AttributeRelevance.PRODUCT_HIERARCHY_OBJECT)
    );
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    if (category.parent) {
      nodeList = this.createParentList(category.parent, nodeList);
    }
    nodeList.push(category);
    return nodeList;
  }

  public getAttributesToBeShown(categoryPho: CategoryProductHierarchyObject): [CategoryProductHierarchyObject, Attribute][] {
    return _.uniqBy(categoryPho.category.categoryAttributes.map(categoryAttribute => categoryAttribute.attribute), 'id')
      .filter(attribute => attribute.attributeRelevance.includes(AttributeRelevance.PRODUCT_HIERARCHY_OBJECT))
      .map(attribute => [categoryPho, attribute]);
  }

}
