
export class TreeViewNode<T> {
    constructor(
        public model: T,
        public children: TreeViewNode<T>[] = [],
        public parent: TreeViewNode<T> = null,
        public display: boolean = true,
        public displayChildren: boolean = false,
        public isCurrent = false,
        public groupId: string = null,
        public parentIdOfFurtherChildren: string = null
      ) { }
}
