import { Component } from '@angular/core';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { BehaviorSubject } from 'rxjs';
import { TemplateView } from '../../../../../model/custom-view/template-view';
import { CategoryAttributeAttributeValue } from '../../../../../model/category-attribute-attribute-value';
import { Attribute } from '../../../../../model/attribute';

@Component({
  selector: 'app-tab-template-view-attributes',
  templateUrl: './custom-view-tab-template-view-attributes.component.html',
  styleUrls: ['./custom-view-tab-template-view-attributes.component.less']
})
export class CustomViewTabTemplateViewAttributesComponent {

  public get templateView(): BehaviorSubject<TemplateView> {
    return this.customViewEditDataService.customViewSubject as BehaviorSubject<TemplateView>;
  }

   constructor(
     private readonly customViewEditDataService: CustomViewEditDataService
     ) { }

  public isCustomViewTypeTemplateView = (): boolean => this.customViewEditDataService.isTemplateView();

  public getAttributesToBeShown = (templateViewLocal: TemplateView): [CategoryAttributeAttributeValue, Attribute][] => {
    return this.customViewEditDataService.getAttributesToBeShown(templateViewLocal);
  }
}
