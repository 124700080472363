<div class="pim-header">
  <div *ngIf="showDebug()" id="appheader-debug">status: {{debugLoginStatus()}}<br>oauth-login: {{ debugLogin | async | json }}</div>
  <nav [ngClass]="{'environment-prod' : environment.environment === 'prod'}" class="pim-nav navbar navbar-dark flex-md-nowrap p-0 shadow">
    <div class="logo-image" ></div>
    <a class="navbar-brand col-4 col-3" href="#">Product Information Management</a>
    <ul class="right-menu">
      <li class="nav-item text-nowrap appheader-icon">
        <button type="button" class="btn appheader-icon">
          <fa-icon icon="user-circle"></fa-icon>
        </button>
      </li>

      <li ngbDropdown *ngIf="multiLanguage && isLoggedIn | async" display="dynamic" class="nav-item text-nowrap appheader-icon">
        <button ngbDropdownToggle class="btn appheader-icon" type="button" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false">
          <fa-icon icon="globe"></fa-icon>
        </button>

        <div ngbDropdownMenu aria-labelledby="dropdownMenuLink">
          <div *ngFor="let language of languages | async">
            <button ngbDropdownItem *ngIf="language.isoCode == 'en' || language.isoCode == 'de'" type="button" [class.active]="(currentUserLanguage | async) == language.isoCode" (click)="setLanguage(language)">{{ language.name | localizedStringsFilter }}</button>
          </div>
        </div>
      </li>

      <li class="nav-item text-nowrap appheader-icon">
        <button type="button" class="btn appheader-icon"  (click)="logout()">
          <fa-icon icon="sign-out-alt" *ngIf="isLoggedIn | async"></fa-icon>
        </button>
      </li>
    </ul>
  </nav>
</div>
<div id="not-editable-banner" *ngIf="((this.bannerVisible | async) && (this.isReadOnlyUser | async))" class="not-editable-banner">
  <div id="not-editable-banner-text" class="banner-text">{{ 'not.editable.due.to.role' | translate }}</div>
  <div id="not-editable-banner-button" (click)="disableBanner()" class="x-icon"><fa-icon icon="x"></fa-icon></div>
</div>
