import { AttributeParameter } from "../attribute-parameter";
import { AttributeParameterType } from "../attribute-parameter-type";
import { AttributeParameterValue } from "./attribute-parameter-value";

export class AttributeParameterNumberValue implements AttributeParameterValue {

  public id: string = null;
  public parameterType: AttributeParameterType;
  public attributeParameter: AttributeParameter;
  public numberValue: number = null;

  constructor(obj: any) {
    let apnv = obj as AttributeParameterNumberValue;

    this.id = apnv.id;
    this.parameterType = AttributeParameterType.NUMBER;
    this.attributeParameter = apnv.attributeParameter;
    this.numberValue = apnv.numberValue;
  }
}