import { AttributeRelevance } from './attribute-relevance';
import { AttributeValue } from './attribute-value';
import { Category } from './category';
import { Material } from './material';
import { MultipleValueSelectorElement } from './multiple-value-selector/multiple-value-selector-element';
import { AttributeValueRelation } from './attribute-value-relation';

export class CategoryMaterial implements AttributeValueRelation, MultipleValueSelectorElement {
  id: string;
  material: Material;
  category: Category;
  attributeValues: AttributeValue[] = [];
  isReadOnly: boolean;
  manuallyMaintained: boolean;
  readonly relevance: AttributeRelevance = AttributeRelevance.MATERIAL;
}
