import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Slice} from '../../model/pim-response/slice';
import {Material} from '../../model/material';
import {Pageable} from '../../model/pageable';
import {TypeaheadTypeAndExcludeIdRequest} from '../../model/request/typeahead-type-and-exclude-id-request';
import {AbstractTypeaheadService} from './abstract-typeahead.service';
import {BaseTypeaheadService} from './base-typeahead.service';
import {MaterialType} from '../../model/material-type';

@Injectable({
  providedIn: 'root'
})
export class MaterialTypeaheadService implements AbstractTypeaheadService<Material> {

  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[]): Observable<Slice<Material>> {

    const body = new TypeaheadTypeAndExcludeIdRequest<MaterialType>(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      [],
      exclude
    );

    return this.baseTypeaheadService.createCall('/material/typeahead/v2', body, pageable);
  }

  public typeaheadFormatter(model: Material): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name) + ` (${model.id})`;
  }
}
