// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  formsLogin: true,
  production: false,
  loginUrl: 'https://dev-backend.pim.basf.com/doLogin',
  samlLoginUrl: 'https://dev-backend.pim.basf.com/saml2/authenticate/pim',
  currentUserUrl: 'https://dev-backend.pim.basf.com/rest/user/current',
  logoutUrl: 'https://dev-backend.pim.basf.com/doLogout',
  samlLogoutUrl: 'https://dev-backend.pim.basf.com/logout',
  s3BucketLink: 'https://pim21-documents.s3.eu-central-1.amazonaws.com',
  restUrl: 'https://dev-backend.pim.basf.com/rest',
  basisCategoryId: '8a8082dc6e36cbbb016e3aee6a9c0005',
  spassCategoryId: '8a8081e96fccf142016fcd674004006a',
  phoCategoryId: '8a8081c1847b213201847e4d4ab6040a',
  prdCategoryId: '8a80cb816e3b1dba016e3c0e576a0000',
  publicSecurityClassCategoryId: '8a80cb816cdcf10f016cdcfc554f0000',
  localizedStringMaxLength: 2000,
  environment: 'dev',
  notificationLifetime: 20_000, // please do not reduce for the sake of smoketests
  disableCopyFromMaterial: false,
  maxTableItems: 50_000,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
