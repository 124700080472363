<div class="container-fluid pb-3">
  <h2 class="display-6 fw-normal">{{ 'headline.administration.mass-maintenance' | translate }}</h2>

  <div class="d-grid gap-3" style="grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;">

    <div class="list-group w-auto">

      <a class="list-group-item list-group-item-action p-0" (click)="openUpdateCategoryMaterial()">
        <div class="text-center">
          <div class="m-4 update-category-material">
            <fa-icon [icon]="['fas', 'box-open']" size="6x"></fa-icon>
            <fa-icon [icon]="['fas', 'sitemap']" size="3x" class="pos-small-icon"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'massmaintenance.title.label.materials_categories' | translate }}</h6>
        </div>
      </a>
      <a class="list-group-item list-group-item-action p-0" (click)="openAttributeValueMaterial()">
        <div class="text-center">
          <div class="m-4 update-category-material">
            <fa-icon [icon]="['fas', 'box-open']" size="6x"></fa-icon>
            <fa-icon [icon]="['fas', 'sliders-h']" size="3x" class="pos-small-icon"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'massmaintenance.title.label.materials_attribute_values' | translate }}</h6>
        </div>
      </a>
      <a class="list-group-item list-group-item-action p-0" (click)="openUpdateDocumentAssignments()">
        <div class="text-center">
          <div class="m-4 update-category-material">
            <fa-icon [icon]="['fas', 'box-open']" size="6x"></fa-icon>
            <fa-icon [icon]="['fas', 'sitemap']" size="3x" class="pos-small-icon"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'massmaintenance.title.label.documents_relations' | translate }}</h6>
        </div>
      </a>

      <a class="list-group-item list-group-item-action p-0" (click)="openUpdateDocumentContainers()">
        <div class="text-center">
          <div class="m-4 update-category-material">
            <fa-icon [icon]="['fas', 'box-open']" size="6x"></fa-icon>
            <fa-icon [icon]="['fas', 'file']" size="3x" class="pos-small-icon"></fa-icon>
          </div>
          <h6 class="mb-0 p-3 pt-0">{{ 'massmaintenance.title.label.documents_containers' | translate }}</h6>
        </div>
      </a>

    </div>

  </div>

</div>
