import { Injectable } from '@angular/core';
import { ComparisonService } from './comparison-service';
import * as _ from 'lodash';
import { TemplateViewMaterial } from '../../model/document-creation/template-view-material';
import { Material } from '../../model/material';

@Injectable({
  providedIn: 'root'
})
export class DocumentCreationComparisonService implements ComparisonService<TemplateViewMaterial> {

  constructor(
  ) { }

  public createComparisonObject(templateMaterial: TemplateViewMaterial) {

    const tm = _.cloneDeep(templateMaterial);

    if(!!tm?.templateView) {
      tm.templateView.customViewCategoryAttributes = null;
    }

    if(!!tm?.material) {
      const material = new Material()
      material.id = tm.material.id;
      tm.material = material;
    }

    return tm;
  }
}
