import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoryMaterial } from '../../model/category-material';
import { Material } from '../../model/material';
import { CategoryAttribute } from '../../model/category-attribute';
import { CustomViewCategoryAttribute } from '../../model/custom-view/custom-view-category-attribute';
import { Category } from '../../model/category';
import { AttributeRelevance } from '../../model/attribute-relevance';
import { BusinessHierarchyResponse } from '../../model/business-hierarchy/business-hierarchy-response';
import { BusinessHierarchyNode } from '../../model/business-hierarchy/business-hierarchy-node';
import { CustomView } from '../../model/custom-view/custom-view';
import { MaterialStatus } from '../../model/material-status';
import { CategoryMaterialService } from '../category-material.service';
import { MaterialService } from '../material.service';
import { CustomViewSearchService } from '../customview-search.service';
import { AttributeValueService } from '../attribute-value.service';
import { BusinessHierarchyService } from '../business-hierarchy.service';



@Injectable({
    providedIn: 'root'
})
export class MaterialEditDataService {

  public categoryMaterialsBehaviourSubject: BehaviorSubject<CategoryMaterial[]> = new BehaviorSubject<CategoryMaterial[]>(null);
  private businessHierarchyBehaviourSubject: BehaviorSubject<BusinessHierarchyNode> = new BehaviorSubject<BusinessHierarchyNode>(null);

  public initialCategoryMaterialsByCategoryAndMaterialId: Map<string, CategoryMaterial> = new Map()

  public customViewCategoryAttributesBehaviorSubject: BehaviorSubject<CustomViewCategoryAttribute[]> = new BehaviorSubject(null);

  private hasViews: boolean = false;

  private categoryMaterialsOrig: CategoryMaterial[] = [];
  public material: Material;
  public materialOrig: Material;

  public get catMats(): CategoryMaterial[] {
    return this.categoryMaterialsBehaviourSubject.getValue();
  }

  constructor(
    private readonly materialService: MaterialService,
    private readonly attributeValueService: AttributeValueService,
    private readonly categoryMaterialService: CategoryMaterialService,
    private readonly customViewSearchService: CustomViewSearchService,
    private readonly businessHierarchyService: BusinessHierarchyService
  ) { }

  public init(id: string) {
    this.cleanUp();
    if (id) {
      this.materialService.load(id).subscribe((material: Material) => {
        this.material = material;
        if(!!this.material.alphaCode) {
          this.loadBusinessHierarchy(this.material.alphaCode);
        }
      });
      this.initCategoryMaterials(id);
    }
  }

  public initCategoryMaterials(materialId: string, customView?: CustomView, hideLoadingIndicator = false) {
    this.categoryMaterialService.loadByMaterialIdAndCreateAttributeValuesIfNotExist(materialId, hideLoadingIndicator).subscribe(({
      next: (categoryMaterials: CategoryMaterial[]) => {
        this.updateCategoryMaterials(categoryMaterials);
        this.catMats.forEach(cm => this.initialCategoryMaterialsByCategoryAndMaterialId.set(cm.category.id + cm.material.id, cm));
        this.reloadCustomViewCategoryAttributes(customView);
        this.categoryMaterialsOrig = cloneDeep(categoryMaterials);
      }
    }));
  }

  public updateCategoryMaterials(categoryMaterials: CategoryMaterial[]) {
    this.categoryMaterialsBehaviourSubject.next(categoryMaterials);
  }

  public cleanUp() {
    this.businessHierarchyBehaviourSubject.next(null);
    this.categoryMaterialsBehaviourSubject.next(null);
    this.initialCategoryMaterialsByCategoryAndMaterialId = new Map()

    this.customViewCategoryAttributesBehaviorSubject.next(null);

    this.hasViews = false;

    this.categoryMaterialsOrig = [];
    this.material = null;
  }

  /**
   * @param customView optional parameter
   * @description
   * loads customViewCategoryAttributes for the categoryAttributes of the categoryMaterials.
   * If no customView is given, all customViewCategoryAttributes are loaded.
   * If a customView is given, only the customViewCategoryAttributes of the given customView are considered.
   */
  public reloadCustomViewCategoryAttributes(customView?: CustomView) {
    if(!customView) {
      const categoryAttributes: CategoryAttribute [] = [];

      this.catMats.map(categoryMaterial => categoryMaterial.category.categoryAttributes)
          .forEach(categoryAttributeArray => categoryAttributes.push(...categoryAttributeArray));
      this.customViewSearchService.findCustomViewCategoryAttributesByCategoryAttributeIds(
        categoryAttributes.map(categoryAttribute => categoryAttribute.id)).subscribe(customViewCategoryAttributes => {
        this.customViewCategoryAttributesBehaviorSubject.next(customViewCategoryAttributes);
      })
    } else {
      this.customViewCategoryAttributesBehaviorSubject.next(this.getCustomViewCategoryAttributesFromCustomView(customView))
    }

    this.customViewCategoryAttributesBehaviorSubject.asObservable().subscribe(customViewCategoryAttributes => {
      this.hasViews = customViewCategoryAttributes?.length > 0;
    });
  }

  private getCustomViewCategoryAttributesFromCustomView(customView: CustomView): CustomViewCategoryAttribute[] {
    return customView.customViewCategoryAttributes
      .map(categoryAttribute => new CustomViewCategoryAttribute('', customView, categoryAttribute));
  }

  public saveCategoryMaterials(materialId: string) {
    return this.categoryMaterialService.save(materialId, this.catMats).pipe(tap(savedCategoryMaterials => {
      savedCategoryMaterials.forEach(cm => this.categoryMaterialService.createMissingAttributeValues(cm));
      this.updateCategoryMaterials(savedCategoryMaterials);
      this.categoryMaterialsOrig = cloneDeep(this.catMats);
    }))
  }

  public categoryMaterialsChanged(): boolean {
    return !(this.categoryMaterialService.isEqual(this.categoryMaterialsOrig, this.catMats));
  }

  public hasCustomViews = (): boolean => this.hasViews;
  public findMaterial = () : Material => this.material;

  public findCategoryMaterials = (filterFunction?: (categoryMaterial: CategoryMaterial) => boolean): CategoryMaterial[] => {
    if (!!this.catMats) {
      return !!filterFunction ? this.catMats.filter(cm => filterFunction(cm)) : this.catMats;
    } else {
      return [];
    }
  }

  public createAndAddCategoryMaterialsWithAttributeValues = (material: Material, category: Category, categoryMaterials: CategoryMaterial[], manuallyMaintained: boolean = false) => {
    if (!this.categoryMaterialExistsForCategory(categoryMaterials, category)) {
      if(this.initialCategoryMaterialsByCategoryAndMaterialId.has(category.id + material.id)) {
        const categoryMaterial: CategoryMaterial = this.initialCategoryMaterialsByCategoryAndMaterialId.get(category.id + material.id);
        categoryMaterials.push(categoryMaterial);
      } else {
        categoryMaterials.push(this.createCategoryMaterialsWithAttributeValues(material, category, manuallyMaintained));
      }
    } else {
      categoryMaterials.forEach(cm => {
        if (cm.category.id === category.id && !cm.manuallyMaintained) {
          cm.manuallyMaintained = manuallyMaintained;
        }
      });
    }
    if (category.parent) {
      this.createAndAddCategoryMaterialsWithAttributeValues(material, category.parent, categoryMaterials, false);
    }
  }

  private createCategoryMaterialsWithAttributeValues(material: Material, category: Category, manuallyMaintained: boolean = false) {
    const categoryMaterial = new CategoryMaterial();
    categoryMaterial.material = material;
    categoryMaterial.category = category;
    categoryMaterial.manuallyMaintained = manuallyMaintained;
    category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
      if (categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.MATERIAL)) {
        categoryMaterial.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
      }
    });
    return categoryMaterial;
  }

  // create AttributeValue dummies for non maintained attributes for a copied CategoryMaterial
  public createAttributeValueDummies(categoryMaterial: CategoryMaterial) {
    categoryMaterial.category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
      if (categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.MATERIAL)) {
        if(!categoryMaterial.attributeValues.some(attributeValue => attributeValue.attribute.id === categoryAttribute.attribute.id)) {
          categoryMaterial.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
        }
      }
    });
  }

  private categoryMaterialExistsForCategory(categoryMaterials: CategoryMaterial[], category: Category): boolean {
    return categoryMaterials.some(cm => cm.category.id === category.id);
  }

  private loadBusinessHierarchy(alphaCode: string): void {
    this.businessHierarchyService.getByAlphaCode(alphaCode).pipe(catchError(this.handleError)).subscribe(response => {
      if(response.businessHierarchy.length === 1) {
        this.businessHierarchyBehaviourSubject.next(response.businessHierarchy[0]);
      }
    });
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    const errorNode: BusinessHierarchyNode = new BusinessHierarchyNode('Business Hierarchy Data could not be retrieved at the moment.');
    const errorResponse: BusinessHierarchyResponse = new BusinessHierarchyResponse([errorNode]);
    return of(errorResponse);
  }

  public getBusinessHierarchy(): Observable<BusinessHierarchyNode> {
    return this.businessHierarchyBehaviourSubject.asObservable();
  }
}
