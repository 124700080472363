import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';
import { AuthenticationStatusService } from './authentication-status.service';

const OAUTH_REDIRECT_URL_KEY = "oauth-redirect-url";

@Injectable()
export class OAuthAuthenticationService {

  private _loginChanged: BehaviorSubject<LoginResponse> = new BehaviorSubject(null);

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private authenticationStatusService: AuthenticationStatusService,
    private router: Router) {

    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  public changeLogin(loginResponse: LoginResponse): void{
    this._loginChanged.next(loginResponse);
  }

  public get loggedIn(): BehaviorSubject<LoginResponse> {
    return this._loginChanged;
  }

  private onBeforeUnload(event: any): void {
    const redirectUrl: string = window.location.href;
    localStorage.setItem(OAUTH_REDIRECT_URL_KEY, redirectUrl);
  }

  public isOAuthenticationMetaDataRequest(url: string): boolean {
    return url.includes("federation-qa.basf.com") || url.includes("login.microsoftonline.com");
  }

  public isOAuthentication(url: string): boolean {
    return this.isOAuthenticationMetaDataRequest(url) || this.authenticationStatusService.isAuthenticationTypeOAuth();
  }

  public init() {

    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        this.changeLogin(loginResponse);
        this.redirect(loginResponse);
      });

    if (!this.oidcSecurityService.authenticated().isAuthenticated
      && !this.authenticationStatusService.isAuthenticationStatusLoggedOut()
      && !this.authenticationStatusService.isAuthenticationStatusLoggingOut()
      && this.authenticationStatusService.isAuthenticationTypeOAuth()) {
      this.oidcSecurityService.authorize();
    }
  }

  private redirect(loginResponse: LoginResponse): void {
    let redirectUrl: string = localStorage.getItem(OAUTH_REDIRECT_URL_KEY);
    if (!!loginResponse?.isAuthenticated && !!redirectUrl) {
      redirectUrl = new URL(redirectUrl).hash.replace(/#\//gi, "");
      this.router.navigateByUrl(redirectUrl);
    }
  }

  public login(): void {
    this.authenticationStatusService.setAuthenticationTypeOAuth();
    this.oidcSecurityService.authorize();

  }

  public logout(): Observable<any> {
    return this.oidcSecurityService.logoff();
  }

}
