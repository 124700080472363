import { Input, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from '../../../service/clipboard.service';

@Component({
  selector: 'confirm-copy-component',
  templateUrl: './confirm-copy.component.html',
  styleUrls: ['./confirm-copy.component.less']
})
export class ConfirmCopyComponent {
    @Input() 
    public title;

    @Input() 
    public text;

    @Input() 
    public yes;

    @Input() 
    public no;

    @Input()
    public copyTitle;

    @Input()
    public copyText;

    constructor(public modal: NgbActiveModal,
        private readonly clipboardService: ClipboardService
    ) { }

    copyTextToClipboard() {
        this.clipboardService.copy(this.copyText);
    }
}