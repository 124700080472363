import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { LanguageService } from '../../service/language.service';
import { Language } from '../../model/language';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.less']
})
export class AppheaderComponent implements OnInit {

  public isLoggedIn: Observable<boolean>;
  public languages: Observable<Language[]>;
  public currentUserLanguage: Observable<string>;
  public isReadOnlyUser: Observable<boolean>;
  public bannerVisible: Observable<boolean> = of(true);
  @Input() multiLanguage: boolean;

  ngOnInit(): void {
    this.isReadOnlyUser = this.authenticationService.isReadOnlyUser();
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.languages = this.languageService.getAllLanguages();
    this.currentUserLanguage = this.userService.getCurrentUserLanguageIsocode();
  }

  constructor(
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router
  ) { }

  public logout(): void {
    localStorage.setItem('login', 'logout');
    this.router.navigate(['/logout']);
  }

  public setLanguage(language: Language) {
    this.userService.saveCurrentUserLanguage(language).subscribe(
      user => {
        this.translate.use(user.language.isoCode);
        this.currentUserLanguage = new Observable(observer => {
          observer.next(user.language.isoCode);
        });
        this.languages = this.languageService.getAllLanguages();
      },
      err => {
        console.log('Error occured: ' + err);
      }
    );
  }

  public disableBanner() {
    this.bannerVisible = of(false);
  }

  protected readonly environment = environment;
}
