import { Component, Input, OnInit } from '@angular/core';
import { Realm } from '../../../../model/realm/realm';
import { Pageable } from '../../../../model/pageable';
import { BusinessHierarchy } from '../../../../model/business-hierarchy';
import { ProductHierarchyObject } from '../../../../model/product-hierarchy-object/product-hierarchy-object';
import { ProductHierarchyObjectType } from '../../../../model/product-hierarchy-object/product-hierarchy-object-type';
import { BusinessHierarchyTypeaheadService } from '../../../../service/typeahead/business-hierarchy-typeahead.service';
import { RealmTypeaheadService } from '../../../../service/typeahead/realm-typeahead.service';
import { ProductHierarchyObjectService } from '../../../../service/product-hierarchy-object.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'product-hierarchy-object-tab-property',
  templateUrl: './product-hierarchy-object-tab-property.component.html',
  styleUrls: ['./product-hierarchy-object-tab-property.component.less']
})
export class ProductHierarchyObjectTabPropertyComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @Input()
  public productHierarchyObject: ProductHierarchyObject;

  public productHierarchyObjectTypes: string[] = ["", ...Object.keys(ProductHierarchyObjectType)]; // TODO: Upgrade tslib?

  constructor(
    private realmTypeaheadService: RealmTypeaheadService,
    private readonly businessHierarchyTypeaheadService: BusinessHierarchyTypeaheadService,
    readonly productHierarchyObjectService: ProductHierarchyObjectService
  ) {}
  
  ngOnInit(): void {
  }

  public realmTypeahead = (searchString: string, pageable: Pageable) => this.realmTypeaheadService.typeaheadSearch(searchString, pageable);
  public realmTypeaheadFormatter = (model: Realm) => this.realmTypeaheadService.typeaheadFormatter(model);

  public ownerTypeAhead = (searchString: string, pageable: Pageable) => this.businessHierarchyTypeaheadService.typeaheadSearch(searchString, pageable, []);
  public ownerTypeAheadFormatter = (model: BusinessHierarchy) => this.businessHierarchyTypeaheadService.typeaheadFormatter(model);

}
