<div *ngIf="(document | async)">
  <div class="row">
    <div class="create-version-button">
      <icon-button id="create-version-button"
                   *ngIf="showOverview() && !(isNotAdvancedUser | async)"
                   buttonType="add"
                   [buttonLabel]="'button.create.version' | translate"
                   (buttonClick)="addDocBridgeVersion()"></icon-button></div>
    <app-document-version-wrapper *ngIf="!showOverview()" [availableLanguages]="this.templateFileNameLanguages"></app-document-version-wrapper>
    <document-tab-version-overview *ngIf="showOverview()" [showAddButton]="false" [isReadOnly]="(isNotAdvancedUser | async)"
      (afterSelectVersionCurrent)="currentVersionSelected($event)"
      (afterSelectVersionFuture)="futureVersionSelected($event)"
      (afterSelectVersionExpired)="expiredVersionSelected($event)"></document-tab-version-overview>
  </div>
</div>
