import { LocalizedString } from './localized-string';
import { FormulationStep } from './formulation-step';

export class FormulationPart {
    id: string;
    name: LocalizedString[] = [];
    steps: FormulationStep[] = [];
    order: number;
    ratio: number;
}
