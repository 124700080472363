import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Realm } from '../../model/realm/realm';
import { Pageable } from '../../model/pageable';
import { TypeaheadCommonRequest } from '../../model/request/typeahead-common-reqest';
import { AbstractTypeaheadService } from './abstract-typeahead.service';
import { BaseTypeaheadService } from './base-typeahead.service';

@Injectable({
  providedIn: 'root'
})
export class RealmTypeaheadService implements AbstractTypeaheadService<Realm> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable): Observable<Slice<Realm>> {

    let body = new TypeaheadCommonRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage()
    );

    return this.baseTypeaheadService.createCall('/realm/typeahead', body, pageable);
  }
  
  public typeaheadFormatter(model: Realm): string {
    return this.baseTypeaheadService.localizedStringFormatter(model?.name);
  }
}
