import { Component, OnInit, Input } from '@angular/core';
import { AttributeValue } from '../../../../model/attribute-value';

@Component({
  selector: 'star-attribute-edit',
  templateUrl: './star-attribute-edit.component.html'
})
export class StarAttributeEditComponent implements OnInit {
  get attributeValue(): AttributeValue {
    return this._attributeValue;
  }

  @Input()
  set attributeValue(value: AttributeValue) {
    this._attributeValue = value;
    this.initStarArray();
  }

  private _attributeValue: AttributeValue;

  @Input()
  public readonly: boolean = false;

  public starArray: boolean[] = [];

  ngOnInit() {
    this.initStarArray();
  }

  initStarArray() {
    this.starArray = [];
    for (let i = 0; i < this._attributeValue.attribute.rangeMax; i++) {
      this.starArray[i] = this._attributeValue.number > i;
    }
  }

  setTo(starCount: number) {
    if (!this.readonly) {
      if (this._attributeValue.number === starCount) {
        this._attributeValue.number = 0;
      } else {
        this._attributeValue.number = starCount;
      }
      this.initStarArray();
    }
  }
}
