import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'typeahead-template-category-attribute-ordered',
  templateUrl: './typeahead-template-category-attribute-ordered.html',
  styleUrls: ['./typeahead-template-category-attribute-ordered.less']
})
export class TypeaheadTemplateCategoryAttributeOrdered implements OnInit {

  @Input()
  public model: any;

  @Input()
  public term: string = null;

  @Input()
  public showAttributeLink = true;

  @Input()
  public executeOnEnableChange: (model: any) => any = (value: any) => value;

  @Input()
  public enableField: string = null;

  @Input()
  public orderField: string = null;

  @Input()
  public matchingField: string = null;

  @Input()
  public title: string = null;

  public isEnabled: string = null;


  constructor(
  ) { }

  ngOnInit(): void {
    this.isEnabled = this.model[this.enableField];
  }

  public get getOrder() {
    return this.model[this.orderField];
  }

  public get isMatching() {
    if (!!this.matchingField) {
      return this.model[this.matchingField];
    }
    return true;
  }


  public createParentList(object: any, nodeList: any[] = []): any[] {
    if(!object) {
      return nodeList;
    }

    if (object.categoryAttribute){
      this.createParentList(object.categoryAttribute, nodeList);
    }
    else if (object.attribute){ // Category-attribute
      if (object.category.parent) {
        nodeList = this.createParentList(object.category.parent, nodeList);
      }
      nodeList.push(object.category);
      nodeList.push(object.attribute);
    }
    else { // Only Category
      if (object.parent) {
        nodeList = this.createParentList(object.parent, nodeList);
      }
      nodeList.push(object);
    }

    return nodeList;
  }

  public onChangeEnabled(event) {
    this.model[this.enableField] = this.isEnabled;
    this.executeOnEnableChange(this.model);
  }

}
