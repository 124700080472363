import {Component, DoCheck, OnDestroy, OnInit, Input} from '@angular/core';
import {DocumentEditContext} from '../../../../../../model/document/document-edit-context';
import {DocumentVersionStatus} from '../../../../../../model/document-version/document-version-status';
import InjectIsNotAdvancedUser from '../../../../../../decorator/inject-is-not-advanced-user.decorator';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentEditDataService} from '../../../../../../service/data-service/document-edit-data.service';
import {DocumentVersionDataService} from '../../../../../../service/data-service/document-version-data.service';
import {DocumentCreationDataService} from '../../../../../../service/data-service/document-creation-data.service';
import {Document} from '../../../../../../model/document/document';
import {DocumentVersion} from '../../../../../../model/document-version/document-version';
import {CategoryAttribute} from '../../../../../../model/category-attribute';
import {CategoryAttributeAttributeValue} from '../../../../../../model/category-attribute-attribute-value';
import {Attribute} from '../../../../../../model/attribute';
import {Language} from '../../../../../../model/language';

@Component({
  selector: 'app-document-version-wrapper',
  templateUrl: './document-version-wrapper.component.html',
  styleUrls: ['./document-version-wrapper.component.less']
})
export class DocumentVersionWrapperComponent implements DoCheck, OnInit, OnDestroy {

  protected readonly documentEditContext = DocumentEditContext;

  protected readonly documentVersionStatus = DocumentVersionStatus;

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  @Input()
  public availableLanguages: Language[] = [];

  public documentVersionId: string = '';
  private subscriptions: Subscription = new Subscription();
  private showOverviewFlip: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly documentEditDataService: DocumentEditDataService,
    private readonly documentVersionDataService: DocumentVersionDataService,
    private readonly documentCreationDataService: DocumentCreationDataService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get document(): BehaviorSubject<Document> {
    return this.documentEditDataService.documentBehaviorSubject;
  }

  public get documentVersionBehaviorSubject(): BehaviorSubject<DocumentVersion> {
    return this.documentVersionDataService.documentVersionBehaviorSubject;
  }

  public get templateViewCategoryAttributes(): BehaviorSubject<CategoryAttribute[]> {
    return this.documentCreationDataService.templateViewCategoryAttributesBehaviorSubject;
  }

  public getLanguages(): Language[] {
    return this.availableLanguages;
  }  

  ngDoCheck() {
    this.documentVersionId = this.route.snapshot.queryParamMap.get('version');
  }

  ngOnInit(): void {
  }

  public createDraftVersion() {
    this.documentCreationDataService.createDraftVersion();
  }

  public createVersion() {
    this.documentCreationDataService.createVersion();
  }

  public saveVersion() {
    this.documentCreationDataService.saveVersion();
  }

  public createAndPublish() {
    this.documentCreationDataService.createAndPublish();
  }

  public saveAndPublish() {
    this.documentCreationDataService.saveAndPublish();
  }

  public cancelVersion() {
    this.documentVersionDataService.cancelVersion();
    if (!this.documentVersionBehaviorSubject.getValue()) {
      this.showOverviewFlip = true;
    }
  }

  public getAttributesToBeShown(documentVersion: DocumentVersion): [CategoryAttributeAttributeValue, Attribute][] {
    const templateViewCategoryAttributeAttributeValues: [CategoryAttributeAttributeValue, Attribute][] = documentVersion.categoryAttributeAttributeValues
      .map(categoryAttributeAttributeValue => [categoryAttributeAttributeValue, categoryAttributeAttributeValue.categoryAttribute.attribute]);
    return documentVersion.categoryAttributeAttributeValues?.length > 0 ? templateViewCategoryAttributeAttributeValues : [];
  }

  public isVersionExpired() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const validUntilDate = new Date(this.documentVersionBehaviorSubject.value.validUntil);
    validUntilDate.setHours(0, 0, 0, 0);

    return validUntilDate <= now;
  }

  public isDraftVersion(documentVersion: DocumentVersion): boolean{
    return documentVersion.status === DocumentVersionStatus.DRAFT;
  }

}
