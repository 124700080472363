import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomView } from "../../model/custom-view/custom-view";
import { TypeaheadTypeAndExcludeIdRequest } from "../../model/request/typeahead-type-and-exclude-id-request";
import { Pageable } from "../../model/pageable";
import { CustomViewType } from "../../model/custom-view/custom-view-type";
import { Slice } from "../../model/pim-response/slice";
import { AbstractTypeaheadService } from "./abstract-typeahead.service";
import { BaseTypeaheadService } from "./base-typeahead.service";

@Injectable({
  providedIn: 'root'
})
export class CustomViewByTypeTypeaheadService implements AbstractTypeaheadService<CustomView>{

  constructor(
    private readonly baseTypeaheadService: BaseTypeaheadService
  ) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable, exclude: string[], types: CustomViewType[] = null): Observable<Slice<CustomView>> {

    const body = new TypeaheadTypeAndExcludeIdRequest<CustomViewType>(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage(),
      types,
      exclude
    );
    return this.baseTypeaheadService.createCall('/customview/typeahead', body, pageable);
  }

  public typeaheadFormatter(model: CustomView): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }
}
