import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators'
import { Category } from '../model/category';
import { AttributeRelevance } from './../model/attribute-relevance';
import { CategoryType } from '../model/category-type';
import { CategoryWithChildren } from '../model/category-with-children';
import { LocalizedStringService } from './localized-string.service';
import { AttributeService } from './attribute.service';
import { environment } from '../../environments/environment';
import { isEqual, orderBy } from 'lodash';
import { BackendValidationService } from './form-validation/backend-validation.service';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private cacheMap: Map<string, BehaviorSubject<Category[]>> = new Map();

  someDataObservable: Observable<Category[]>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localizedStringService: LocalizedStringService,
    private readonly backendValidationService: BackendValidationService,
    private readonly attributeService: AttributeService) {
  }

  public load(id: string): Observable<Category> {
    return this.httpClient.get<Category>(environment.restUrl + '/category/' + id);
  }

  public save(category: Category): Observable<Category> {
    return this.httpClient.post<Category>(environment.restUrl + '/category/save', category)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public delete(category: Category): Observable<any> {
    return this.httpClient.post<Category>(environment.restUrl + '/category/delete', category)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public findByTypeOnLeafLevel(categoryType: CategoryType): Observable<Category[]> {
    return this.findCategoriesCached(categoryType, '/category/leaf');
  }

  public findByTypeCached(categoryType: CategoryType): BehaviorSubject<Category[]> {
    return this.findCategoriesCached(categoryType, '/category/type');
  }

  private findCategoriesCached(categoryType: CategoryType, endpoint: string): BehaviorSubject<Category[]> {
    const categoryString: string = categoryType + endpoint;
    if(!this.cacheMap.get(categoryString)) {
      const categoriesOfTypeBehaviorSubject: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
      this.httpClient.post<Category[]>(environment.restUrl + endpoint, [categoryType], Constants.httpOptionsHidingLoadingIndicator).pipe(share()).subscribe(categories => {
        categoriesOfTypeBehaviorSubject.next(categories);
      });
      this.cacheMap.set(categoryString, categoriesOfTypeBehaviorSubject);
    }
    return this.cacheMap.get(categoryString);
  }

  public findByType(categoryTypes: CategoryType[]): Observable<Category[]> {
    return this.httpClient.post<Category[]>(environment.restUrl + '/category/type', categoryTypes);
  }

  public loadRootCategories(): Observable<CategoryWithChildren[]> {
    return this.httpClient.get<CategoryWithChildren[]>(environment.restUrl + '/category/roots');
  }

  public loadChildren(category: Category): Observable<CategoryWithChildren[]> {
    return this.httpClient.post<CategoryWithChildren[]>(environment.restUrl + '/category/children', category);
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    if (category.parent) {
      nodeList = this.createParentList(category.parent, nodeList);
    }
    nodeList.push(category);
    return nodeList;
  }

  public hasCategoryAttributesByRelevance(category: Category, relevance: AttributeRelevance): boolean {
    return category.categoryAttributes.map(categoryAttribute => categoryAttribute.attribute).some(
      attribute => attribute.attributeRelevance.includes(relevance)
    );
  }


  public getOrderedCategoryIds(categories: Category[]): any[] {
    return orderBy(categories, ['id']).map(category => category.id);
  }

  private getCategoryObj(category: Category): any {
    let categoryName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(category.name);
    let categoryShortName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(category.shortName);
    let categoryParent = category.parent ? category.parent.id : null;
    let categoryAttributes = this.attributeService.getOrderedAttributeIds(category.categoryAttributes);
    let categoryRegionalValidity = this.getOrderedCategoryIds(category.regionalValidity);
    return {
      id: category.id,
      name: categoryName,
      shortName: categoryShortName,
      parent: categoryParent,
      type: category.type,
      description: category.description,
      attributes: categoryAttributes,
      regionalValidity: categoryRegionalValidity,
      readonly: category.readonly
    }
  }

  public isEqual(categoryOrig: Category, category: Category): boolean {
    if (categoryOrig != null && category != null) {
      let categoryOrigObj = this.getCategoryObj(categoryOrig);
      let categoryObj = this.getCategoryObj(category);
      return isEqual(categoryOrigObj, categoryObj);
    }
    return false;
  }
}
