<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal" *ngIf="!!getProductHierarchyObject()?.id && !getProductHierarchyObject().type">{{ getProductHierarchyObject().internalName }}</h2>
  <h2 class="display-6 fw-normal" *ngIf="!!getProductHierarchyObject()?.id && getProductHierarchyObject().type">{{ getProductHierarchyObject().internalName }} ({{ getProductHierarchyObject().type }})</h2>
  <h2 class="display-6 fw-normal" *ngIf="!getProductHierarchyObject()?.id">{{ 'h2.product_hierarchy_object.header.add' | translate}}</h2>
  
  <ul ngbNav  #nav="ngbNav" [(activeId)]="selectedTab" class="nav-tabs" >
    <li [ngbNavItem]="'properties'">
      <a ngbNavLink [routerLink]="'properties'">{{ 'label.product_hierarchy_object.tab.property' | translate}}</a>
    </li>

    <li [ngbNavItem]="'relations'">
      <a ngbNavLink [routerLink]="'relations'">{{ 'label.product_hierarchy_object.tab.relation' | translate}}</a>
    </li>

    <li [ngbNavItem]="'categorization'" [routerLink]="'categorization'" id="categorization">
      <a ngbNavLink>{{ 'label.material.tab.categorization' | translate }}</a>
    </li>

    <li [ngbNavItem]="'attributes'" [routerLink]="'attributes'" id="attributes">
      <a ngbNavLink>{{ 'label.material.tab.attributes' | translate }}</a>
    </li>

  </ul>

  <div class="border-router-outlet">
    <router-outlet></router-outlet>
  </div>

  <div class="row mb-3 mt-3">
    <div class="col-12 align-right">
      <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button *ngIf="!!getProductHierarchyObject()?.id && !(isReadOnlyUser | async)" buttonType="save" (buttonClick)="save()"></icon-button>
      <icon-button *ngIf="!getProductHierarchyObject()?.id && !(isReadOnlyUser | async)" buttonType="add" (buttonClick)="save()"></icon-button>
    </div>
  </div>
  
</div>
