import { LocalizedString } from './localized-string';

export class LocalizedEnumerationValue {
    public id: string;
    public order: number;
    public name: LocalizedString[];

    constructor(order: number = null){
      this.id = null;
      this.order = order;
      this.name = [];
    }

}
