import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Pageable } from '../../model/pageable';
import { Unit } from '../../model/unit';
import { TypeaheadCommonRequest } from '../../model/request/typeahead-common-reqest';
import { BaseTypeaheadService } from './base-typeahead.service';
import { AbstractTypeaheadService } from './abstract-typeahead.service';

@Injectable({
  providedIn: 'root'
})
export class UnitTypeaheadService implements AbstractTypeaheadService<Unit> {
  
  constructor(private readonly baseTypeaheadService: BaseTypeaheadService) {}

  public typeaheadSearch(searchTerm: string, pageable: Pageable): Observable<Slice<Unit>> {

    let body = new TypeaheadCommonRequest(
      searchTerm,
      this.baseTypeaheadService.getRequestLanguage()
    );

    return this.baseTypeaheadService.createCall('/unit/typeahead', body, pageable);
  }

  public typeaheadFormatter(model: Unit): string {
    return this.baseTypeaheadService.localizedStringFormatter(model.name);
  }

}
