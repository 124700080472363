<div>
  <div>
    <app-searchbar *ngIf="showSearchbar"
      [disableTypeAhead]="true"
      (buttonClick)="onSearchButtonClick($event)"></app-searchbar>
  </div>

  <div>
    <div *ngIf="showSearchbar && !!checkableFilters && checkableFilters.size > 0" class="filters">
      <div class="filtersBox"  *ngFor="let filter of checkableFilters | keyvalue:keepOriginalOrder">
        <span class="filterHeader"><b>{{"label.category.treeview.01" + filter.key | translate}}</b></span>
        <div class="filterOption" *ngFor="let filterOption of filter.value"> <!-- list of filterOptions rendered -->
          <div>
            <ng-container *ngTemplateOutlet="treeViewFacetTemplate, context: { 'result': filterOption }"></ng-container>
          </div>
        </div>
      </div>
      <div class="filterButtons">
        <div class="applyFilterButton"><icon-button buttonType="apply filter tree" (buttonClick)="applyFilter()"></icon-button></div>
        <div class="clearFilterButton"><icon-button buttonType="clear filter tree" (buttonClick)="clearFilter()"></icon-button></div>
      </div>
    </div>
  </div>

  <div *ngIf="!isGrouped">
    <ng-container *ngTemplateOutlet="nodeView;context:{nodes:renderedRootNodes}">
    </ng-container>
  </div>
  <div *ngIf="!!isGrouped">
    <ng-container *ngTemplateOutlet="groupNodeView;context:{nodeGroups:renderedRootNodeGroups}">
    </ng-container>
  </div>
</div>

<ng-template #nodeView
  let-nodes="nodes" >
  <div *ngFor="let node of nodes">
    <app-tree-view-node [formatNodeLabel]="formatNodeLabel"
      [node]="node"
      [treeViewLineTemplate]="treeViewLineTemplate"
      [isNodeClickable]="!!areNodesClickable"
      (loadChildren)="onLoadChildren($event)"
      (nodeClick)="onNodeClick($event)"
      (nodeNewTabClick)="onNodeNewTabClick($event)"></app-tree-view-node>
  </div>
</ng-template>

<ng-template #groupNodeView
  let-nodeGroups="nodeGroups">
  <div *ngFor="let nodeGroup of nodeGroups">
    <h3 class="h3-top-spacer">{{ 'label.category.treeview.' + nodeGroup.groupId | translate }}</h3>
    <div *ngFor="let node of nodeGroup.nodes">
      <app-tree-view-node [formatNodeLabel]="formatNodeLabel"
      [treeViewLineTemplate]="treeViewLineTemplate"
      [node]="node"
      [isNodeClickable]="areNodesClickable"
      (loadChildren)="onLoadChildren($event)"
      (nodeClick)="onNodeClick($event)"
      (nodeNewTabClick)="onNodeNewTabClick($event)"></app-tree-view-node>
    </div>
  </div>
</ng-template>
