export class Pageable {

    constructor(
      public page: number = 0, 
      public size: number = 20, 
    ) {}

    toUrlString(): string {
        return 'page=' + this.page + '&size=' + this.size;
    }
}
