<div>
  <label *ngIf="!!label">{{label}}</label>
  <select selectFirstOption class="form-select"
    [disabled]="readonly"
    [ngModel]="getSelectedValue()"
    [required]="required"
    (ngModelChange)="setSelectedValue($event)">
    <option *ngFor="let enumerationValue of enumerationValues; let i=index"
      value="{{enumerationValue}}" title="{{getEnumerationTooltip(i)}}">
      <div *ngIf="!enumerationValueTemplate">{{ localizationPrefix + enumerationValue | translate }}</div>
      <div *ngIf="enumerationValueTemplate">
        <ng-container *ngTemplateOutlet="enumerationValueTemplate;context:{enumerationValue: localizationPrefix + enumerationValue | translate}"></ng-container>
      </div>
    </option>
  </select>
</div>