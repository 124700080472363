import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AttributeRelevance } from '../model/attribute-relevance';
import { Category } from '../model/category';
import { CategoryMaterial } from '../model/category-material';
import { Attribute } from './../model/attribute';
import { AttributeValueService } from './attribute-value.service';
import { map } from 'rxjs/operators';
import { AttributeValue } from '../model/attribute-value';
import * as _ from 'lodash';
import { CategoryAttribute } from '../model/category-attribute';
import { AttributeValueEditDataService } from './data-service/attribute-value-edit-data.service';
import { BackendValidationService } from './form-validation/backend-validation.service';
import { IsAttributeMaintainedService } from './is-attribute-maintained.service';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class CategoryMaterialService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly attributeValueService: AttributeValueService,
    private readonly backendValidationService: BackendValidationService,
    private readonly isAttributeMaintainedService: IsAttributeMaintainedService,
    private readonly attributeValueEditDataService: AttributeValueEditDataService,
  ) { }

  public save(id: string, categoryMaterials: CategoryMaterial[]): Observable<CategoryMaterial[]> {
    this.attributeValueEditDataService.isAttributeValueVisibilityCalculatedMap.clear();
    return this.httpClient.post<CategoryMaterial[]>(environment.restUrl + '/categorymaterial/save/' + id, categoryMaterials)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public loadByMaterialIdAndCreateAttributeValuesIfNotExist(id: string, hideLoadingIndicator = false): Observable<CategoryMaterial[]> {

    return this.loadByMaterialId(id, hideLoadingIndicator).pipe(map((categoryMaterials: CategoryMaterial[]) => {
      categoryMaterials.forEach((categoryMaterial: CategoryMaterial) => {
        this.createMissingAttributeValues(categoryMaterial);
      });
      return categoryMaterials;
    }));
  }

  private loadByMaterialId(id: string, hideLoadingIndicator = false): Observable<CategoryMaterial[]> {
    return this.httpClient.get<CategoryMaterial[]>(`${environment.restUrl}/categorymaterial/${id}`,
      hideLoadingIndicator ? Constants.httpOptionsHidingLoadingIndicator : {});
  }

  public removeCategoryMaterials(category: Category, categoryMaterials: CategoryMaterial[]) {
    this.removeCategoryMaterialIfNotInUse(category, category, categoryMaterials);
  }

  public createMissingAttributeValues(categoryMaterial: CategoryMaterial) {
    categoryMaterial.category.categoryAttributes.forEach((categoryAttribute: CategoryAttribute) => {
      if (!this.attributeValueExist(categoryMaterial, categoryAttribute.attribute) &&
        categoryAttribute.attribute.attributeRelevance.includes(AttributeRelevance.MATERIAL)) {
        categoryMaterial.attributeValues.push(this.attributeValueService.createNewAttributeValue(categoryAttribute.attribute));
      }
    });
  }

  private attributeValueExist(categoryMaterial: CategoryMaterial, attribute: Attribute): boolean {
    return !!categoryMaterial.attributeValues.find((attributeValue: AttributeValue) => attributeValue.attribute.id === attribute.id);
  }

  private removeCategoryMaterialIfNotInUse(childCategory: Category, categoryToRemove: Category,
                                           categoryMaterials: CategoryMaterial[], removeEvenIfManuallyMaintained: boolean = true) {

    const indexToRemove = categoryMaterials.findIndex((categoryMaterial: CategoryMaterial) => {
      if(categoryMaterial.category.id === categoryToRemove.id) {
        if (removeEvenIfManuallyMaintained) {
          categoryMaterial.manuallyMaintained = false;
        }
        return true;
      }
      return false;
    });

    if (!this.isCategoryMaterialNeedByAnotherCategory(childCategory, categoryToRemove, categoryMaterials, removeEvenIfManuallyMaintained)) {
      categoryMaterials.splice(indexToRemove, 1);
      if (!!categoryToRemove.parent) {
        this.removeCategoryMaterialIfNotInUse(categoryToRemove, categoryToRemove.parent, categoryMaterials, false);
      }
    }
  }

  private isCategoryMaterialNeedByAnotherCategory(childCategory: Category, categoryToRemove: Category, categoryMaterials: CategoryMaterial[], ignoreManuallyMaintained: boolean) {
    const isStillParentAndNotManuallyMaintained: boolean = categoryMaterials.some(categoryMaterial =>
      (categoryMaterial.category.id !== childCategory.id &&
        categoryMaterial.category.parent?.id === categoryToRemove.id) ||
      (childCategory.id !== categoryToRemove.id && categoryMaterial.category.id === categoryToRemove.id && !ignoreManuallyMaintained && categoryMaterial.manuallyMaintained))

    return isStillParentAndNotManuallyMaintained;
  }

  private getOrderedCategoryMaterialsWithAttributes(categoryMaterials: CategoryMaterial[]): any[] {
    return _.orderBy(categoryMaterials.map(categoryMaterial => {
      const maintainedAttributeValues = categoryMaterial.attributeValues.filter(
        attributeValue => this.isAttributeMaintainedService.isSingleAttributeValueMaintained(attributeValue));
      const attributeValues = this.attributeValueService.getOrderedAttributeValues(maintainedAttributeValues);
      return {category: categoryMaterial.category.id, material: categoryMaterial.material.id, attributeValues};
    }), ['category']);
  }

  public isEqual(categoryMaterialsOrig: CategoryMaterial[], categoryMaterials: CategoryMaterial[]): boolean {
    if(!categoryMaterials) {
      return true;
    }
    const categoryMaterialsOrigWithAttributes = this.getOrderedCategoryMaterialsWithAttributes(categoryMaterialsOrig);
    const categoryMaterialsWithAttributes = this.getOrderedCategoryMaterialsWithAttributes(categoryMaterials);
    return _.isEqual(categoryMaterialsOrigWithAttributes, categoryMaterialsWithAttributes);
  }
}
