import {Component, OnDestroy} from '@angular/core';
import { Params, Router} from '@angular/router';
import { Material } from '../../../../model/material';
import { CustomView } from '../../../../model/custom-view/custom-view';
import { Pageable } from '../../../../model/pageable';
import { CustomViewType } from '../../../../model/custom-view/custom-view-type';
import { MaterialTypeaheadService } from '../../../../service/typeahead/material-typeahead.service';
import { CustomViewByTypeTypeaheadService } from '../../../../service/typeahead/custom-view-by-type-typeahead.service';
import { DocumentCreationDataService } from '../../../../service/data-service/document-creation-data.service';
import { Subscription } from 'rxjs';
import { TemplateViewMaterial } from '../../../../model/document-creation/template-view-material';

@Component({
  selector: 'document-creation-administration',
  templateUrl: './document-creation-administration.component.html'
})
export class DocumentCreationAdministrationComponent implements OnDestroy {

  public material: Material;
  public templateView: CustomView;
  private templateViewMaterialSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly materialTypeaheadService: MaterialTypeaheadService,
    private readonly customViewByTypeTypeaheadService: CustomViewByTypeTypeaheadService,
    private readonly documentCreationDataService: DocumentCreationDataService
  ) {
  }

  ngOnDestroy(): void {
    this.templateViewMaterialSubscription?.unsubscribe();
  }

  public materialTypeahead = (searchTerm: string, pageable: Pageable) => this.materialTypeaheadService.typeaheadSearch(searchTerm, pageable, []);
  public materialTypeaheadFormatter = (model: Material) => this.materialTypeaheadService.typeaheadFormatter(model);

  public customViewTypeahead = (searchTerm: string, pageable: Pageable) => this.customViewByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable, [], [CustomViewType.TEMPLATE_VIEW]);
  public customViewTypeaheadFormatter = (input: CustomView): string => this.customViewByTypeTypeaheadService.typeaheadFormatter(input);

  public search() {
    if (!!this.material?.id && !!this.templateView?.id) {
      this.documentCreationDataService.searchTemplateMaterialByMaterialAndTemplateView(this.material, this.templateView);
      this.templateViewMaterialSubscription = this.documentCreationDataService.templateMaterialBehaviorSubject.subscribe((tvm: TemplateViewMaterial) => {
        if (tvm !== null) {
          const action: string = !!tvm.document?.id ? 'edit' : 'add';
          const params: Params = {material: this.material.id, templateView: this.templateView.id, context: 'bridge'};
          this.router.navigate(['adminstration/document-creation/' + action], {queryParams: params})
        }
      })
    }
  }
}
