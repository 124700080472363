import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';
import { UserFilterDataService } from '../../service/user-filter/user-filter-data.service';
import { ConfirmService } from '../../service/confirm.service';
import { UserFilterSaveComponent } from './user-filter-save/user-filter-save.component';
import { UserFilterRenameComponent } from './user-filter-rename/user-filter-rename.component';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrl: './user-filter.component.less',
})
export class UserFilterComponent implements OnInit {
  menuIcon = faBars;

  constructor(
    private userFilterDataService: UserFilterDataService,
    private confirmService: ConfirmService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.loadFiltersAndSetDefaultFilter();
  }

  public get userFiltersBehaviourSubject(): BehaviorSubject<UserFilterResponse[]> {
    return this.userFilterDataService.userFiltersBehaviourSubject;
  }

  public get userSelectedFilterBehaviourSubject(): BehaviorSubject<UserFilterResponse> {
    return this.userFilterDataService.userFilterBehaviourSubject;
  }

  public onSelectedFilterChanged(userFilterResponse: UserFilterResponse): void {
    this.userFilterDataService.changeFilter(userFilterResponse);
  }

  public addFilter(): void {
    const modalRef: NgbModalRef = this.modalService.open(UserFilterSaveComponent, { windowClass: 'modal-style-class' });
  }

  public renameFilter(): void {
    const modalRef: NgbModalRef = this.modalService.open(UserFilterRenameComponent, { windowClass: 'modal-style-class' });
    modalRef.componentInstance.currentName = this.userSelectedFilterBehaviourSubject.getValue().filterName;
  }

  public disableRenameFilter = (): boolean => this.userSelectedFilterBehaviourSubject.getValue() == null;
  public disableDefaultFilter = (): boolean => this.userSelectedFilterBehaviourSubject.getValue() == null || this.userSelectedFilterBehaviourSubject.getValue().defaultFilter;
  public disableRemoveFilter = (): boolean => this.userSelectedFilterBehaviourSubject.getValue() == null;

  public setAsDefaultFilter(): void {
    const userFilterResponse: UserFilterResponse = this.userSelectedFilterBehaviourSubject.getValue();
    if (!userFilterResponse) {
      return;
    }
    lastValueFrom(this.userFilterDataService.setDefaultFilter(userFilterResponse));
  }

  public removeFilter(): Promise<void> {
    const userFilterResponse: UserFilterResponse = this.userSelectedFilterBehaviourSubject.getValue();
    if (!userFilterResponse) {
      return;
    }
    this.askToConfirmDeleting().then((confirmed) => {
      if (confirmed) {
        lastValueFrom(this.userFilterDataService.deleteFilter(userFilterResponse));
      }
    });
  }

  public compareFn(a, b): boolean {
    if (!a && !b) {
      return true;
    }

    return !!a && !!b && a.filterId === b.filterId;
  }

  private loadFiltersAndSetDefaultFilter(): void {
    lastValueFrom(this.userFilterDataService.listFiltersAndSetDefaultFilter());
  }

  private async askToConfirmDeleting() {
    const confirmed = await this.confirmService.confirm('user.filter.deletion.dialog.title', 'user.filter.deletion.dialog.message', 'button.yes', 'button.cancel');
    return confirmed;
  }
}
