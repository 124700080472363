import { Injectable } from '@angular/core';
import { AttributeValue } from '../../model/attribute-value';

@Injectable({
  providedIn: 'root'
})
export class AttributeValueEditDataService {

  public isAttributeValueVisibilityCalculatedMap: Map<AttributeValue, boolean> = new Map();

  constructor() { }

  public isAttributeValueVisibilityCalculated = (allAttributeValues: AttributeValue[]): boolean =>  {
    if(allAttributeValues.length> 0 && allAttributeValues.some(av => this.isAttributeValueVisibilityCalculatedMap.has(av))) {
      return this.isAttributeValueVisibilityCalculatedMap.get(allAttributeValues.find(av => this.isAttributeValueVisibilityCalculatedMap.has(av)));
    }
    return false;
  }

  public setAttributeValueVisibilityCalculated = (attribueValue: AttributeValue, attribueValues: AttributeValue[]) => {
    this.isAttributeValueVisibilityCalculatedMap
        .set(attribueValue, this.isAttributeValueVisibilityCalculatedMap
            .get(attribueValues.find(av => this.isAttributeValueVisibilityCalculatedMap.has(av))))
  }
}
