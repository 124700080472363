import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Material} from '../../../../../model/material';
import {MaterialService} from '../../../../../service/material.service';
import {NotificationService} from '../../../../../service/notification.service';
import { MaterialEditDataService } from '../../../../../service/data-service/material-edit-data.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-third-party-and-base-ingredient-edit',
  templateUrl: './third-party-and-base-ingredient-edit.component.html',
  styleUrls: ['./third-party-and-base-ingredient-edit.component.less']
})
export class ThirdPartyAndBaseIngredientEditComponent implements OnInit {

  public material: Material = new Material();

  constructor(
    private route: ActivatedRoute,
    private materialService: MaterialService,
    private materialEditDataService: MaterialEditDataService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'newBaseIngredient') {
          this.material = this.materialService.newBaseIngredient();
          this.materialEditDataService.material = this.material;
          this.materialEditDataService.materialOrig = cloneDeep(this.material);
        } else if (params.id === 'newThirdPartyProduct') {
          this.material = this.materialService.newThirdPartyProduct();
          this.materialEditDataService.material = this.material;
          this.materialEditDataService.materialOrig = cloneDeep(this.material);
        } else {
          this.materialService.load(params.id).subscribe((material: Material) => {
            this.material = material;
            this.materialEditDataService.material = this.material;
            this.materialEditDataService.materialOrig = cloneDeep(this.material);
          });
        }
      }
    });
  }

  public save() {
    this.materialService.saveThirdPartyProductOrBaseIngredient(this.material).subscribe((material) => {
      this.notificationService.addSuccessNotification('label.successfully.saved');
      this.material = material;
      this.editItem(this.material);
    });
  }

  editItem(material: Material) {
    this.router.navigateByUrl('adminstration/material/' + material.id);
  }

  public cancel() {
    this.router.navigate(['adminstration/material'], { queryParams: { typeFacet: 'THIRD_PARTY_PRODUCT,BASE_INGREDIENT' } });
  }

}

