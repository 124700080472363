import { Injectable } from "@angular/core";
import { BusinessHierarchy } from "../model/business-hierarchy";
import { orderBy } from 'lodash'
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { BusinessHierarchyResponse } from "../model/business-hierarchy/business-hierarchy-response";


@Injectable({
    providedIn: 'root'
})
export class BusinessHierarchyService {

  constructor(
    private httpClient: HttpClient) {
}

    public getOrderedBusinessHierarchyIds(businesHierarchies: BusinessHierarchy[]): any[] {
        return orderBy(businesHierarchies, ['id']).map(businesHierarchy => businesHierarchy.id);
    }

    public getByAlphaCode(alphacode: string): Observable<BusinessHierarchyResponse> {
      // return this.httpClient.get(environment.restUrl + '/proxy/businesshierarchy/' + alphacode, {observe: 'response',  headers: new HttpHeaders({'hideloadingindicator': 'true'})} );
      return this.httpClient.get<BusinessHierarchyResponse>(environment.restUrl + '/proxy/businesshierarchy/' + alphacode, { headers: new HttpHeaders({'hideloadingindicator': 'true'})} );
    }

}
