import { TemplateViewListCategoryAttribute } from './template-view-list-category-attribute';

export class TemplateViewList {
  constructor(
    public id: string = null,
    public parametername: string = null,
    public name: string = null,
    public templateViewListCategoryAttributes: TemplateViewListCategoryAttribute[] = [],
  ) {
  }
}
