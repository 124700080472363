import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MassImportService } from '../../../service/mass-import.service';
import { NotificationService } from "../../../service/notification.service";

@Component({
  selector: 'mass-import',
  templateUrl: './mass-import.component.html'
})
export class MassImportComponent {

  public results = [];
  private exportFile: File;
  private updateFile: File;
  private patchFile: File;
  private exportMaterialAttributeValuesFile: File;
  private expireDocumentFile: File;
  private categoryFile: File;

  constructor(private massImportService: MassImportService, protected notificationService: NotificationService) { }

  fillExportFile(file: File) {
    this.exportFile = file;
  }

  fillUpdateFile(file: File) {
    this.updateFile = file;
  }

  fillExpireDocumentFile(file: File) {
    this.expireDocumentFile = file;
  }

  fillCategoryFile(file: File) {
    this.categoryFile = file;
  }

  exportMaterialFile(file: File) {
    this.exportMaterialAttributeValuesFile = file
  }

  patchMaterialFile(file: File) {
    this.patchFile = file;
  }

  createCategories() {
    this.massImportService.createCategories(this.categoryFile).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', "export.xlsx");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      }
    );
  }

  exportCategoryMaterials() {
    this.massImportService.exportCategoryMaterials(this.exportFile).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', "export");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      }
    );
  }

  updateCategoryMaterials() {
    this.massImportService.updateCategoryMaterials(this.updateFile).subscribe(
      (response: any) => {
        this.notificationService.addSuccessNotification('Updated finished')
      }
    );
  }
  
  patchMaterialsTemplate() {
    this.massImportService.patchMaterialsTemplate().subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        const filename = 'material-attribute-values(Template)'; // fallback filename placeholder
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      }
    );
  }

  exportMaterialsTemplate() {
    this.massImportService.exportMaterialsTemplate().subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        const filename = 'material-attribute-values(Template)'; // fallback filename placeholder
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      }
    );
  }

  patchMaterials() {
    this.massImportService.patchMaterials(this.patchFile).subscribe(
      (response: any) => {
        this.notificationService.addSuccessNotification('Patch complete, please keep the incoming file download for '+
          'future use, the original file uploaded can be archived')

        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        const filename = this.patchFile.name.replace(/.xlsx$/, '')
          .replace(/\(patched_.+\)/, '') + `(patched_${new Date().toISOString()}).xlsx`;
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      (err: HttpErrorResponse) => {

        const fr = new FileReader();

        fr.addEventListener('load', e => {
            const errormessage = JSON.parse(fr.result.toString())[0]?.message || 'An unknown error occurred. Please try again.';
            this.notificationService.addErrorNotification(errormessage)
        });

        fr.readAsText(err.error);
      }
    );
  }


  exportMaterials() {
    this.massImportService.exportMaterials(this.exportMaterialAttributeValuesFile).subscribe(
      (response: any) => {
        this.notificationService.addSuccessNotification('Export complete, please keep the incoming file download ' +
          'for future use, the original file uploaded can be archived')

        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

        const filename = this.exportMaterialAttributeValuesFile.name.replace(/.xlsx$/, '')
          .replace(/\(exported_.+\)/, '') + `(exported_${new Date().toISOString()}).xlsx`;
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      (err: HttpErrorResponse) => {

        const fr = new FileReader();

        fr.addEventListener('load', e => {
          const errormessage = JSON.parse(fr.result.toString())[0]?.message || 'An unknown error occurred. Please try again.';
          this.notificationService.addErrorNotification(errormessage)
        });

        fr.readAsText(err.error);
      }
    );
  }

  updateExpireDateForDocument() {
    this.massImportService.updateExpireDateForDocuments(this.expireDocumentFile).subscribe(
      (response: any) => {
        this.notificationService.addSuccessNotification("Updated Documents")
      }
    );
  }

}
