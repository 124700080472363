import { Component, Input } from '@angular/core';
import { Category } from '../../../../../model/category';
import { Filter } from '../../../../../model/filter';
import * as _ from 'lodash';

@Component({
  selector: 'app-category-filter-line',
  templateUrl: './category-filter-line.component.html',
  styleUrls: ['./category-filter-line.component.less']
})
export class CategoryFilterLineComponent {

  @Input() 
  public filter: Filter<Category>;

  constructor(
  ) { }

}
