import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { Material } from '../../model/material';
import { CustomView } from '../../model/custom-view/custom-view';
import { TemplateViewMaterialResponseComposition } from '../../model/document-creation/template-material-composition';
import { TemplateMaterialSearchRequest } from '../../model/web/request/template-material-search-request';
import { TemplateMaterialSaveRequest } from '../../model/web/request/template-material-save-request';
import { BackendValidationService } from '../form-validation/backend-validation.service';
import { environment } from '../../../environments/environment';
import { RenderingOption } from '../../model/document-creation/rendering-option';
import { DocumentVersion } from '../../model/document-version/document-version';
import { Constants } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class DocumentCreationHttpService {

  private readonly OVERVIEW_URL = '/adminstration/document-creation/overview';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly httpClient: HttpClient,
    private readonly backendValidationService: BackendValidationService,
  ) {}

  public searchTemplateMaterialByMaterialAndTemplateView(material: Material, templateView: CustomView): Observable<TemplateViewMaterialResponseComposition> {
    const requestBody: TemplateMaterialSearchRequest = this.getTemplateMaterialSearchRequest(material.id, templateView.id);

    return this.httpClient.post<TemplateViewMaterialResponseComposition>(environment.restUrl + '/documentbridge/templatematerial/search', requestBody)
        .pipe(catchError((response: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
          if(response.status === HttpStatusCode.NotFound) {
            this.navigateToOverviewUrlWithError(response)
          }
          return of();
        }))
  }

  public loadTemplateMaterialByMaterialIdAndTemplateViewId(materialId: string, templateViewId: string): Observable<TemplateViewMaterialResponseComposition> {
    return this.httpClient.post<TemplateViewMaterialResponseComposition>(environment.restUrl + '/documentbridge/templatematerial/search',
      this.getTemplateMaterialSearchRequest(materialId, templateViewId) )
      .pipe(catchError((response: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
        if(response.status === HttpStatusCode.NotFound) {
          this.navigateToOverviewUrlWithError(response);
        }
        return of();
      }))
  }

  public saveTemplateMaterial(templateMaterialSaveRequest: TemplateMaterialSaveRequest): Observable<TemplateViewMaterialResponseComposition> {
    return this.httpClient.post<TemplateViewMaterialResponseComposition>(
        environment.restUrl + '/documentbridge/templatematerial/save', templateMaterialSaveRequest)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public createDocumentVersion(documentVersion: DocumentVersion, documentId: string, draftVersionId: string): Observable<DocumentVersion> {
    let restUrl = environment.restUrl + `/documentbridge/documentversion/create/${documentId}`;
    if (draftVersionId != null){
      restUrl += `/${draftVersionId}`;
    }
    return this.httpClient.post<DocumentVersion>(restUrl, documentVersion)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public navigateToOverviewUrlWithError(response?: HttpErrorResponse): void {
    const params: Params = {
      docBridgeMaterialTypeFacet: 'PRD'
    }
    const context = this.route.snapshot.queryParamMap.get('context');
    let result: Promise<boolean>
    if(!context || context === 'document') {
      result = this.router.navigate(['documents']);
    } else {
      result = this.router.navigate([this.OVERVIEW_URL], { queryParams: params });
    }
    if(!!response) {
      result.finally(() => this.backendValidationService.addErrorNotifications(response));
    }
  }

  public loadRenderingOptions(materialId: string, templateViewId: string, id?: string): Observable<RenderingOption[]> {
    return this.httpClient.post<RenderingOption[]>(
        environment.restUrl + '/documentbridge/renderingoptions/load',
        this.getTemplateMaterialSearchRequest(materialId, templateViewId, id),
        Constants.httpOptionsHidingLoadingIndicator,
      )
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  private getTemplateMaterialSearchRequest(materialId: string, templateViewId: string, id?: string) {
    return new TemplateMaterialSearchRequest(
      id || null,
      {id: materialId} as Material,
      {id: templateViewId} as CustomView
    );
  }

  saveVersion(documentVersion: DocumentVersion, documentId: string): Observable<DocumentVersion> {
    return this.httpClient.post<DocumentVersion>(environment.restUrl + `/documentversion/update/${documentId}`, documentVersion)
      .pipe(this.backendValidationService.renderErrorMessages());
  }

  public getAttributeValuesForDocumentVersion(documentVersionId: string): Observable<DocumentVersion> {
    return this.httpClient.get<DocumentVersion>(`${environment.restUrl}/documentversion/attributevalues/${documentVersionId}`).pipe(
      catchError((response: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
        this.backendValidationService.renderErrorMessages()
        return of();
      })
    );
  }
}
