import { Component, OnInit } from '@angular/core';
import { Category } from '../../../../model/category';
import { Formulation } from '../../../../model/formulation';
import { CategoryFormulation } from '../../../../model/category-formulation';
import { Pageable } from '../../../../model/pageable';
import { CategoryType } from '../../../../model/category-type';
import { CategoryService } from '../../../../service/category.service';
import { CategoryFormulationService } from '../../../../service/category-formulation.service';
import { CategoryByTypeTypeaheadService } from '../../../../service/typeahead/category-by-type-typeahead.service';
import { FormulationEditDataService } from '../../../../service/data-service/formulation-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'formulation-tab-categorization',
  templateUrl: './formulation-tab-categorization.component.html'
})
export class FormulationTabCategorizationComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  public leafCategories = {};

  constructor(
    private readonly categoryFormulationService: CategoryFormulationService,
    private readonly categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private readonly categoryService: CategoryService,
    private readonly formulationEditDataService: FormulationEditDataService
  ) { }

  ngOnInit() {
    this.initiateCategoryLeafMap();
  }

  public categoryTypeaheadIsBasedOn = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable, 
    [CategoryType.PRODUCT_GROUP], this.excludeProductGroupIds());

  public categoryTypeaheadResultsIt = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable, 
    [CategoryType.APPLICATION], this.excludeApplicationIds());

  public findCategoryFormulations = (): CategoryFormulation[] => {
    return this.formulationEditDataService.findCategoryFormulations();
  };

  public findFormulation = (): Formulation => this.formulationEditDataService.findFormulation();

  public excludeProductGroupIds() {
    return this.findLeafCategoryFormulations(CategoryType.PRODUCT_GROUP).map(cm => cm.category.id);
  }

  public excludeApplicationIds() {
    return this.findLeafCategoryFormulations(CategoryType.APPLICATION).map(cm => cm.category.id);
  }

  private findLeafCategoryFormulations(categoryType: CategoryType): CategoryFormulation[] {
    return this.findCategoryFormulations().filter((categoryFormulation: CategoryFormulation) => 
      categoryFormulation.category.type === categoryType && this.leafCategories[categoryFormulation.category.id]
    );
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    return this.categoryService.createParentList(category, nodeList);
  }

  public addValue = (category: Category) => {
    this.categoryService.load(category.id).subscribe((fullCategory: Category) => {
      this.categoryFormulationService.createAndAddCategoryFormulationsWithAttributeValues(this.findFormulation(), fullCategory, this.findCategoryFormulations());
      this.addToLeafCategory(category, true);
    });
  }

  public deleteValue = (categoryFormulation: CategoryFormulation) => {
    this.formulationEditDataService.removeCategoryFormulations(categoryFormulation.category);
    this.initiateCategoryLeafMap();
  }

  public isProductGroupValid = (categoryFormulation: CategoryFormulation) => {
    return (categoryFormulation.category.type === CategoryType.PRODUCT_GROUP && this.leafCategories[categoryFormulation.category.id]);
  }

  public isApplicationValid = (categoryFormulation: CategoryFormulation) => {
    return (categoryFormulation.category.type === CategoryType.APPLICATION && this.leafCategories[categoryFormulation.category.id]);
  }

  private initiateCategoryLeafMap() {
    this.leafCategories = {};
    this.findCategoryFormulations().forEach(categoryFormulation => {
      this.addToLeafCategory(categoryFormulation.category, true);
    });
  }

  private addToLeafCategory(category: Category, possibleLeaf: boolean = false) {
    if (possibleLeaf && !this.leafCategories.hasOwnProperty(category.id)) {
      this.leafCategories[category.id] = true;
    } else {
      this.leafCategories[category.id] = false;
    }
    if (category.parent) {
      this.addToLeafCategory(category.parent);
    }
  }
}
