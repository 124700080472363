import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Slice } from '../../model/pim-response/slice';
import { Pageable } from '../../model/pageable';
import { LocalizedString } from '../../model/localized-string';
import { TypeaheadRequest } from '../../model/request/typeahead-request';
import { environment } from '../../../environments/environment';
import { LocalizedStringService } from '../localized-string.service';
import * as _ from 'lodash';
import { Constants } from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class BaseTypeaheadService {

  private readonly requestLanguage: string = 'en';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localizedStringService: LocalizedStringService
  ) { }

  private createRestUrl(restUrl: string, pageable: Pageable): string {
    return environment.restUrl + restUrl +  '?' + pageable.toUrlString();
  }

  public createCall(url: string, body: TypeaheadRequest, pageable: Pageable, hideloadingindicator: boolean = true): Observable<any> {

    if(body.searchTerm.length > 0) {
      body.searchLanguage = this.requestLanguage;
      return this.httpClient.post<Slice<any>>(
        this.createRestUrl(url, pageable),
        body,
        Constants.httpOptionsHidingLoadingIndicator
      );
    }
    return of(null);
  }

  public localizedStringFormatter(value: LocalizedString[], showNameNotFound: boolean = false): string {
    return this.localizedStringService.getLocalizedStringsValue(value, showNameNotFound);
  }

  public getRequestLanguage(): string {
    return this.requestLanguage;
  }
}
