import { LocalizedString } from './localized-string';
import { CategoryType } from './category-type';
import { MultipleValueSelectorElement } from './multiple-value-selector/multiple-value-selector-element';
import { CategoryAttribute } from './category-attribute';

export class Category implements MultipleValueSelectorElement {
    id: string;
    name: LocalizedString[] = [];
    shortName: LocalizedString[] = [];
    synonyms: LocalizedString[] = [];
    parent: Category;
    type: CategoryType;
    description: string;
    marketingDescription: LocalizedString[] = [];
    categoryAttributes: CategoryAttribute[] = [];
    regionalValidity: Category[] = [];
    catalogValidity: Category[] = [];
    readonly: boolean;
    pimOneMigrated: boolean;
    pimOneIdentifier: string;
    isReadOnly: boolean;
}
