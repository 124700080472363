<div class="container-fluid pb-3">
  <h2 class="display-6 fw-normal">{{ 'label.administration.document_creation.select.headline' | translate }}</h2>

  <div class="row">
    <div class="col-4 mb-3 form-group">
      <typeahead
          [inputLabel]="'label.administration.document_creation.select.material' | translate"
          [inputName]="'material'"
          [(model)]="material"
          [typeaheadFunction]="materialTypeahead"
          [resultFormatter]="materialTypeaheadFormatter"
          [inputFormatter]="materialTypeaheadFormatter">
      </typeahead>
    </div>
  </div>
  
  <div class="row">
    <div class="col-4 mb-3 form-group">
      <typeahead
          [inputLabel]="'label.administration.document_creation.select.templateview' | translate"
          [inputName]="'templateview'"
          [(model)]="templateView"
          [typeaheadFunction]="customViewTypeahead"
          [resultFormatter]="customViewTypeaheadFormatter"
          [inputFormatter]="customViewTypeaheadFormatter">
      </typeahead>
    </div>
  </div>

  <div class="row">
    <div class="col-4 mb-3 align-right">
      <icon-button buttonType="proceed with selection" (buttonClick)="search()"></icon-button>
    </div>
  </div>

  </div>