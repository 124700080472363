<div>
  <app-editor
    [readonly]="readonly"
    [htmlContent]="!!value ? value : ''"
    [toolbarButtonsToShow]="['undo',
                             'redo',
                             'bold',
                             'italic',
                             'underline',
                             'subscript',
                             'superscript',
                             'insertUnorderedList',
                             'insertOrderedList',
                             'link',
                             'unlink',
                             'removeFormat',
                             'toggleEditorMode']"
    (htmlContentChange)="onHtmlContentChange($event)"
    [maxTextLength]="(maxStringLength | async)"></app-editor>
</div>
<div
  *ngIf="showCharactersLeft"
  class="characters-left">{{ !!value ? textLength : 0 }}/{{ (maxStringLength| async) }}
</div>

