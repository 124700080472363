import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from './../../../../service/category.service';
import { AttributeRelevance } from './../../../../model/attribute-relevance';
import { Category } from '../../../../model/category';
import { CategoryFormulation } from '../../../../model/category-formulation';
import { FormulationEditDataService } from '../../../../service/data-service/formulation-edit-data.service';
import { Attribute } from '../../../../model/attribute';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import {CategoryMaterial} from "../../../../model/category-material";

@Component({
  selector: 'formulation-tab-attribute',
  templateUrl: './formulation-tab-attribute.component.html',
  styleUrls: ['./formulation-tab-attribute.component.less']
})
export class FormulationTabAttributeComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  private categoryFormulations: CategoryFormulation [] = []

  constructor(
    private readonly categoryService: CategoryService,
    private readonly formulationEditDataService: FormulationEditDataService
  ) { }

  ngOnInit(): void {
    this.categoryFormulations = this.formulationEditDataService.findCategoryFormulations()
        .filter(categoryFormulation => categoryFormulation.attributeValues.some(attributeValue => attributeValue.attribute.attributeRelevance.includes(AttributeRelevance.FORMULATION)));
  }

  public findCategoryFormulations(): CategoryFormulation[] {
    return this.categoryFormulations;
  }

  public getCategoryByFormulationRelevance(): any {
    return this.findCategoryFormulations().filter(categoryFormulation =>
      this.categoryService.hasCategoryAttributesByRelevance(categoryFormulation.category, AttributeRelevance.FORMULATION)
    );
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    if (category.parent) {
      nodeList = this.createParentList(category.parent, nodeList);
    }
    nodeList.push(category);
    return nodeList;
  }

  public getAttributesToBeShown(categoryFormulation: CategoryFormulation): [CategoryFormulation ,Attribute][] {
    return _.uniqBy(categoryFormulation.category.categoryAttributes.map(categoryAttribute => categoryAttribute.attribute), 'id')
      .filter(attribute => attribute.attributeRelevance.includes(AttributeRelevance.FORMULATION))
      .map(attribute => [categoryFormulation, attribute]);
  }
}
