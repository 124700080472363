<div *ngIf="!!(findCustomViewSubject() | async)">
  <div class="row">
    <div class="form-group col-6">
      <localized-string-edit [englishRequired]="true"
                             [label]="'label.administration.customview.name' | translate" [localizedStrings]="findCustomView()?.name"></localized-string-edit>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mb-3">
      <label for="description">{{ 'label.administration.customview.description' | translate }}</label>
      <textarea [(ngModel)]="findCustomView().description" class="form-control" id="description" name="description" required type="text"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-6">
      <typeahead
        [(model)]="findCustomView() && findCustomView().realm"
        [inputFormatter]="realmTypeaheadFormatter"
        [inputLabel]="'label.administration.customview.realm' | translate"
        [inputName]="'realm'"
        [inputRequired]="true"
        [resultFormatter]="realmTypeaheadFormatter"
        [typeaheadFunction]="realmTypeahead">
      </typeahead>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-6">

      <enumeration-edit (selectedValueChange)="onCustomViewTypeChange($event)"
                        [convertEmptyStringToNull]="false"
                        [enumerationValues]="customViewTypes"
                        [label]="'label.administration.customview.view_type' | translate"
                        [localizationPrefix]="'option.administration.customview.view_type.'"
                        [readonly]="!!((findCustomViewSubject() | async)?.id)"
                        [selectedValue]="findCustomView().type">
      </enumeration-edit>

    </div>
  </div>

  <label>{{ 'label.administration.customview.material_attributes' | translate }}</label>
  <div class="row">

    <multiple-value-selector [executeBeforeAdd]="addValue"
                             [executeBeforeDelete]="deleteValue"
                             [inputName]="'attribute_service'"
                             [inputRequired]="true"
                             [resultTemplate]="attributeResultTemplate"
                             [typeahead]="attributesTypeahead"
                             [valueTemplate]="attributeValueTemplate"
                             [values]="findCustomView()?.customViewCategoryAttributes"
                             class="form-group col-12"
                             deleteConfirmText="text.relationship.category.attribute.deletion"
                             deleteConfirmTitle="title.confirm.remove">
    </multiple-value-selector>

  </div>
</div>
<ng-template #attributeValueTemplate let-result="result">
  <typeahead-template-category-attribute-relatives [model]="result"
                                                   [showParametername]="isCustomViewTypeTemplateView() || isCustomViewTypeDynamicTemplateView()" class="category-attribute-template"></typeahead-template-category-attribute-relatives>
</ng-template>

<ng-template #attributeResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-attribute-relatives [model]="result" [showAttributeLink]="false"
                                                   [term]="term?.trim().split(' ')"></typeahead-template-category-attribute-relatives>
</ng-template>
