import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Material } from '../model/material';
import { ProductHierarchyObject } from '../model/product-hierarchy-object/product-hierarchy-object';
import { MaterialType } from '../model/material-type';
import { LocalizedStringService } from './localized-string.service';
import { environment } from '../../environments/environment';
import { isEqual, orderBy } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(
    private httpClient: HttpClient,
    private localizedStringService: LocalizedStringService) { }

  public save(material: Material) {
    return this.httpClient.post<Material>(environment.restUrl + '/material/save', material);
  }

  public saveThirdPartyProductOrBaseIngredient(material: Material) {
    return this.httpClient.post<Material>(environment.restUrl + '/material/saveThridPartyProductOrBaseIngredient', material);
  }

  public load(id: string): Observable<Material> {
    return this.httpClient.get<Material>(environment.restUrl + '/material/' + id);
  }

  public newBaseIngredient(): Material {
    const material = new Material();
    material.type = MaterialType.BASE_INGREDIENT;
    return material;
  }

  public newThirdPartyProduct(): Material {
    const material = new Material();
    material.type = MaterialType.THIRD_PARTY_PRODUCT;
    return material;
  }

  public loadByProductHierarchyObjects(productHierarchyObjects: ProductHierarchyObject[]): Observable<Material[]>{
    if(!productHierarchyObjects||productHierarchyObjects.length === 0){
      return of(new Array<Material>());
    }
    const phoIds: string = productHierarchyObjects.map(pho => pho.id).join(",");
    return this.httpClient.get<Material[]>(environment.restUrl + '/material/byProductHierarchyObjects/' + phoIds);
  }

  public getOrderedMaterialIds(materials: Material[]): any[] {
    return orderBy(materials, ['id']).map(material => material.id);
  }

  private getMaterialObj(material: Material): any {
    let materialName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(material.name);
    let materialMarketingName = this.localizedStringService.getOrderedLocalizedStringsWithoutLanguageName(material.marketingName);
    let materialParent = material.parent ? material.parent.id : null;
    let materialRealm = material.realm ? material.realm.id : null;
    let materialBaseUnitOfMeasure = material.baseUnitOfMeasure ? material.baseUnitOfMeasure.id : null;
    return {
      id: material.id,
      name: materialName,
      marketingName: materialMarketingName,
      parent: materialParent,
      type: material.type,
      realm: materialRealm,
      europeanArticleNumber: material.europeanArticleNumber,
      supplier: material.supplier,
      status: material.status,
      substanceAccessGroup: material.substanceAccessGroup,
      begru: material.begru,
      od: material.od,
      materialGroup: material.materialGroup,
      totalShelfLife: material.totalShelfLife,
      minimumRemainingShelfLife: material.minimumRemainingShelfLife,
      baseUnitOfMeasure: materialBaseUnitOfMeasure,
      size: material.size,
      articleType: material.articleType
    };
  }

  public isEqual(materialOrig: Material, material: Material): boolean {
    if (materialOrig != null && material != null) {
      let materialOrigObj = this.getMaterialObj(materialOrig);
      let materialObj = this.getMaterialObj(material);
      return isEqual(materialOrigObj, materialObj);
    }
    return false;
  }
}
