<div>
  <app-material [editComponentUrl]="'material'" [customButtons]="customButtons" [title]="'headline.materials.list' | translate"></app-material>
</div>

<ng-template #customButtons>
  <button (click)="copySearchFunction()" id="btnCopyUrl" class="btn btn-primary copy-search" 
    title="{{'button.copySearchLink' | translate}}">
    <fa-icon icon="clipboard" class="fa-solid"></fa-icon>
  </button>
  <button (click)="downloadExcelResult()" class="btn btn-primary" [disabled]="processingDownloadSearch | async">
      <fa-icon icon="file-excel"></fa-icon> {{ 'button.download.searchResult' | translate}}
  </button>
</ng-template>