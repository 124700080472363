import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { Category } from '../../model/category';
import { EditMenuTreeviewService } from '../edit-menu/edit-menu-treeview.service';
import { CategoryType } from '../../model/category-type';
import { LocalizedStringService } from '../localized-string.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CategoryTreeviewService implements EditMenuTreeviewService<Category> {

  private searchString: string;

  constructor(
    private readonly localizedStringService: LocalizedStringService,
    private readonly translate: TranslateService,
    private readonly httpClient: HttpClient) { }

  public loadCurrentNode(element: Category): Observable<Category> {
    throw new Error('Method not implemented.');
  }

  public groupFunction: (category: Category) => string = (category: Category) => this.getSortGroup(category);
  public sortFunction: (model: Category) => string = (category: Category) => this.localizedStringService.getLocalizedStringsValue(category.name).toLowerCase();

  private getSortGroup(category: Category): string {
    switch (category.type) {
      case CategoryType.APPLICATION:
      case CategoryType.SOLUTION:
        return `02${category.type}`;
    }
    return `01${category.type}`;
  }

  public loadRootNodes(filters: Map<string, Category[]>): Observable<Category[]> {
    this.searchString = "";
    const queryString = this.getFilterString(filters)
    return this.httpClient.get<Category[]>(`${environment.restUrl}/category/treeview/roots${queryString}`);
  }

  public loadFilters(): Observable<Map<string, Category[]>> {
    return this.httpClient.get<Map<string, Category[]>>(`${environment.restUrl}/category/treeview/filters`);
  }

  public loadChildNodes(parent: Category): Observable<Category[]> {
    return this.httpClient.post<Category[]>(`${environment.restUrl}/category/treeview/children`, parent);
  }

  public searchInTreeview(searchString: string, filters: Map<string, Category[]>): Observable<Category[]> {
    this.searchString = searchString; // Used to highlight nodes
    const queryString = btoa(searchString) + this.getFilterString(filters);
    return this.httpClient.get<Category[]>(`${environment.restUrl}/category/treeview/search/${queryString}`);
  }

  public formatNodeLabel: (category: Category) => string = (category: Category) => {
    if (this.searchString.length > 0){
      return this.formatSearchHitNodeLabel(category);
    }else {
      return this.formatOverViewNodeLabel(category);
    }
  }

  public formatOverViewNodeLabel: (category: Category) => string = (category: Category) => {
      return (!!category.name[0] ? category.name[0].value : "<small><i>nameless Category</i></small>") + this.addNodeLabelAppendix(category);
  }

  public formatSearchHitNodeLabel: (category: Category) => string = (category: Category) => {
    let pattern = this.searchString.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = pattern.split(' ').filter((t) => {
      return t.length > 0;
    }).join('|');
    const regex = new RegExp(pattern, 'gi');
    if (!category.name[0]){
      return "<small><i>nameless Category</i></small>"
    }
    return category.name[0].value.replace(regex, (match) => `<b>${match}</b>`) + this.addNodeLabelAppendix(category);
  }

  public addNodeLabelAppendix(category: Category): string {
    const categoryValidities = category.regionalValidity;
    let categoryType:string;
    let appendix:string = '<small> ';
    this.translate.get(`label.type.${_.camelCase(category.type.toLowerCase())}`)
        .subscribe((res: string) => categoryType = _.startCase(res));
    if (categoryValidities.length > 0){
      const lastValidCategory = categoryValidities[categoryValidities.length - 1].name[0].value
      appendix += `(<b>${lastValidCategory}</b>)`;
    }
    return `${appendix} <i>[${categoryType}]</i></small>`
  }

  public isSearched(): boolean{
    return !!this.searchString && this.searchString !== ""
  }

  public getSearchString(){
    return this.searchString;
  }

  private getFilterString(filters: Map<string, Category[]>) : string {
    let filterStrings = [];
    filters.forEach((value, key) => {
      filterStrings.push(`${key.toLocaleLowerCase()}=${value.map(item => item.id).join(',')}`);
    });
    if (filterStrings.length > 0){
      return '?' + filterStrings.join('&')
    }
    return ""; 
  }
}
