import { trigger, style, transition, animate } from '@angular/animations';

export const BasicAnimations = {
  horizontalFadeInOutToTheRightTrigger: trigger(
  'horizontalFadeInOutToTheRight',
    [
      transition(
        ':enter',
        [
          style({ 'padding-right': 0, width: '0%', opacity: 0.5 }),
          animate('.8s ease-out',
            style({ 'padding-right': '18px', width: '92%', opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ 'padding-right': '18px', width: '92%', opacity: 1 }),
          animate('.8s ease-in',
            style({ 'padding-right': 0, width: '0%', opacity: 0.5 }))
        ]
      )
    ]
  ),

  horizontalFadeInOutToTheLeftTrigger: trigger(
    'horizontalFadeInOutToTheLeft',
    [
      transition(
        ':enter',
        [
          style({ transform: 'translateX(-420px)', width: '100px', opacity: 0 }),
          animate('.8s ease-out',
            style({ transform: 'translateX(0)', width: '520px', opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ transform: 'translateX(0)', width: '520px', opacity: 1 }),
          animate('.6s ease-in',
            style({ transform: 'translateX(-420px)', width: '100px', opacity: 0 }))
        ]
      )
    ]
  )
}
