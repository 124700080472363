import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardWebResponse } from '../model/dashboard/DashboardWebResponse';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _dashboard: DashboardWebResponse = null;

  public get dashboard() {
    return this._dashboard;
  }

  public set dashboard(dashboard: DashboardWebResponse) {
    this._dashboard = dashboard;
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService) {
    this.loadDashboard();
   }

  private loadDashboard(): void {
    this.httpClient.get<DashboardWebResponse>(environment.restUrl + '/dashboard').subscribe({
        next: (dashboard: DashboardWebResponse) => {
          dashboard.news.sort((a, b) => ( new Date(a.publishDate) < new Date(b.publishDate)) ? 1 : -1);
          this._dashboard = dashboard;
         }
      });
  }
}